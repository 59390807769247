// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  CurrencyUSD: { input: CurrencyUSD; output: CurrencyUSD; }
  /**
   * Define a Relay Cursor type:
   * https://relay.dev/graphql/connections.htm#sec-Cursor
   */
  Cursor: { input: any; output: any; }
  Decimal: { input: GraphQLDecimal; output: GraphQLDecimal; }
  Percent: { input: Percent; output: Percent; }
  Time: { input: Time; output: Time; }
};

export type AiSuggestion = Node & {
  __typename?: 'AISuggestion';
  /** Whether the suggestion was accepted or rejected */
  acceptanceStatus?: Maybe<AiSuggestionAcceptanceStatus>;
  /** The suggested beneficial owner. This should only be set if the kind is BENEFICIAL_OWNER */
  beneficialOwner?: Maybe<InferredBeneficialOwnerDetails>;
  /** The suggested date the beneficial owners were last updated. This should only be set if the kind is BENEFICIAL_OWNERS_AS_OF_DATE */
  beneficialOwnersAsOfDate?: Maybe<Scalars['Time']['output']>;
  /** The suggested beneficiary. This should only be set if the kind is BENEFICIARY. */
  beneficiary?: Maybe<InferredBeneficiaryDetails>;
  /** The suggested tax status for the business entity. This should only be set if the kind is BUSINESS_ENTITY_TAX_STATUS */
  businessEntityTaxStatus?: Maybe<AiSuggestionBusinessEntityTaxStatus>;
  /** If this is a suggestion for a trustee/trustAdvisor/beneficiary/keyPerson, this is the associated client organization */
  clientOrganization?: Maybe<ClientOrganization>;
  /** If this is a suggestion for a grantor/owner/trustee/trustAdvisor/beneficiary/keyPerson, this is the associated client profile */
  clientProfile?: Maybe<ClientProfile>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The suggested Crummey withdrawal period (in days). This should only be set if the kind is CRUMMEY_WITHDRAWAL_PERIOD_DAYS. */
  crummeyWithdrawalPeriodDays?: Maybe<Scalars['Int']['output']>;
  /** The suggested DBA name. This should only be set if the kind is DOING_BUSINESS_AS_NAME */
  doingBusinessAsName?: Maybe<Scalars['String']['output']>;
  /** The suggested effective date of the entity. This should only be set if the kind is EFFECTIVE_DATE. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  /** If this is a suggestion for a owner/beneficiary/keyPerson, this is the associated entity */
  entity?: Maybe<Entity>;
  /** The evidence (bounding regions) that supports this suggestion. This is only relevant if the suggestion was generated from the DocumentInferenceWorkflow. */
  evidence?: Maybe<Array<AiSuggestionEvidence>>;
  /** The search string and document to find evidence for this suggestion. This is only relevant if the suggestion was generated from a KGRoot. */
  evidenceSearch?: Maybe<DocumentEvidenceSearch>;
  /** The file that this suggestion is from */
  file?: Maybe<File>;
  /** The suggested FinCEN ID for the business entity. This should only be set if the kind is FINCEN_ID */
  fincenID?: Maybe<Scalars['String']['output']>;
  /** The suggested GST status of the entity. This should only be set if the kind is GST_STATUS. */
  gstStatus?: Maybe<AiSuggestionEntityGstStatus>;
  /**
   * Only relevant if the suggestion is for a grantor/trustee/trustAdvisor/beneficiary. This indicates if the user has gone through the process to attempt to match the suggestion with a client profile/organization row.
   * @deprecated This is not needed, because we use the value of `acceptance_status` and the edges to determine if the suggestion has been matched.
   */
  hasMatched?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** The suggested jurisdiction (state code) of the entity. This should only be set if the kind is JURISDICTION_STATE_CODE. */
  jurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  /** The suggested key person. This should only be set if the kind is KEY_PERSON */
  keyPerson?: Maybe<InferredKeyPersonDetails>;
  /** The KGRoot that created this suggestion */
  kgRoot?: Maybe<KgRoot>;
  /** Indicates the field (or group of fields) the suggestion is for. */
  kind: AiSuggestionKind;
  /** The suggested legal name of the entity. This should only be set if the kind is LEGAL_NAME. */
  legalName?: Maybe<Scalars['String']['output']>;
  /** The suggested entity to onboard. This should only be set if the kind is ONBOARDING_ENTITY */
  onboardingEntity?: Maybe<OnboardingEntitySuggestion>;
  /** The suggested person to onboard. This should only be set if the kind is ONBOARDING_INDIVIDUAL */
  onboardingIndividual?: Maybe<OnboardingIndividualSuggestion>;
  /** The suggested organization to onboard. This should only be set if the kind is ONBOARDING_ORGANIZATION */
  onboardingOrganization?: Maybe<OnboardingOrganizationSuggestion>;
  /** The suggested testamentary entity to onboard. This should only be set if the kind is ONBOARDING_TESTAMENTARY_ENTITY */
  onboardingTestamentaryEntity?: Maybe<OnboardingTestamentaryEntitySuggestion>;
  /** The suggested principal (grantor/owner/donor). This should only be set if the kind is PRINCIPAL. */
  principal?: Maybe<InferredGrantorDetails>;
  /** The suggested tax ID (EIN) for the business entity. This should only be set if the kind is TAX_ID */
  taxID?: Maybe<Scalars['String']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** The suggested trust advisor. This should only be set if the kind is TRUST_ADVISOR. */
  trustAdvisor?: Maybe<InferredTrustAdvisorDetails>;
  /** The suggested trustee. This should only be set if the kind is TRUSTEE. */
  trustee?: Maybe<InferredTrusteeDetails>;
  updateUser: User;
  updatedAt: Scalars['Time']['output'];
  updatedBy: Scalars['ID']['output'];
  user: User;
};

/** AISuggestionAcceptanceStatus is enum for the field acceptance_status */
export enum AiSuggestionAcceptanceStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

/** AISuggestionBusinessEntityTaxStatus is enum for the field business_entity_tax_status */
export enum AiSuggestionBusinessEntityTaxStatus {
  Corporation = 'CORPORATION',
  DisregardedEntity = 'DISREGARDED_ENTITY',
  NonTaxableEntity = 'NON_TAXABLE_ENTITY',
  Partnership = 'PARTNERSHIP'
}

/** A connection to a list of items. */
export type AiSuggestionConnection = {
  __typename?: 'AISuggestionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AiSuggestionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AiSuggestionEdge = {
  __typename?: 'AISuggestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AiSuggestion>;
};

/** AISuggestionEntityGSTStatus is enum for the field gst_status */
export enum AiSuggestionEntityGstStatus {
  GstExempt = 'GST_EXEMPT',
  GstNonExempt = 'GST_NON_EXEMPT',
  MixedGst = 'MIXED_GST'
}

export type AiSuggestionEvidence = Node & {
  __typename?: 'AISuggestionEvidence';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** The page number in the document */
  pageNumber: Scalars['Int']['output'];
  /** The coordinates of the bounding region around the paragraph */
  polygon: Array<Coordinate>;
  /** The suggestion that this evidence is for */
  suggestion?: Maybe<AiSuggestion>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type AiSuggestionEvidenceConnection = {
  __typename?: 'AISuggestionEvidenceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AiSuggestionEvidenceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AiSuggestionEvidenceEdge = {
  __typename?: 'AISuggestionEvidenceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AiSuggestionEvidence>;
};

/** Ordering options for AISuggestionEvidence connections */
export type AiSuggestionEvidenceOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AISuggestionEvidences. */
  field: AiSuggestionEvidenceOrderField;
};

/** Properties by which AISuggestionEvidence connections can be ordered. */
export enum AiSuggestionEvidenceOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * AISuggestionEvidenceWhereInput is used for filtering AISuggestionEvidence objects.
 * Input was generated by ent.
 */
export type AiSuggestionEvidenceWhereInput = {
  and?: InputMaybe<Array<AiSuggestionEvidenceWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** suggestion edge predicates */
  hasSuggestion?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuggestionWith?: InputMaybe<Array<AiSuggestionWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AiSuggestionEvidenceWhereInput>;
  or?: InputMaybe<Array<AiSuggestionEvidenceWhereInput>>;
  /** page_number field predicates */
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageNumberGT?: InputMaybe<Scalars['Int']['input']>;
  pageNumberGTE?: InputMaybe<Scalars['Int']['input']>;
  pageNumberIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  pageNumberLT?: InputMaybe<Scalars['Int']['input']>;
  pageNumberLTE?: InputMaybe<Scalars['Int']['input']>;
  pageNumberNEQ?: InputMaybe<Scalars['Int']['input']>;
  pageNumberNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** AISuggestionKind is enum for the field kind */
export enum AiSuggestionKind {
  BeneficialOwner = 'BENEFICIAL_OWNER',
  BeneficialOwnersAsOfDate = 'BENEFICIAL_OWNERS_AS_OF_DATE',
  Beneficiary = 'BENEFICIARY',
  BusinessEntityTaxStatus = 'BUSINESS_ENTITY_TAX_STATUS',
  CrummeyWithdrawalPeriodDays = 'CRUMMEY_WITHDRAWAL_PERIOD_DAYS',
  DoingBusinessAsName = 'DOING_BUSINESS_AS_NAME',
  EffectiveDate = 'EFFECTIVE_DATE',
  FincenId = 'FINCEN_ID',
  GstStatus = 'GST_STATUS',
  JurisdictionStateCode = 'JURISDICTION_STATE_CODE',
  KeyPerson = 'KEY_PERSON',
  LegalName = 'LEGAL_NAME',
  OnboardingEntity = 'ONBOARDING_ENTITY',
  OnboardingIndividual = 'ONBOARDING_INDIVIDUAL',
  OnboardingOrganization = 'ONBOARDING_ORGANIZATION',
  OnboardingTestamentaryEntity = 'ONBOARDING_TESTAMENTARY_ENTITY',
  Principal = 'PRINCIPAL',
  TaxId = 'TAX_ID',
  Trustee = 'TRUSTEE',
  TrustAdvisor = 'TRUST_ADVISOR'
}

/** Ordering options for AISuggestion connections */
export type AiSuggestionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AISuggestions. */
  field: AiSuggestionOrderField;
};

/** Properties by which AISuggestion connections can be ordered. */
export enum AiSuggestionOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * AISuggestionWhereInput is used for filtering AISuggestion objects.
 * Input was generated by ent.
 */
export type AiSuggestionWhereInput = {
  /** acceptance_status field predicates */
  acceptanceStatus?: InputMaybe<AiSuggestionAcceptanceStatus>;
  acceptanceStatusIn?: InputMaybe<Array<AiSuggestionAcceptanceStatus>>;
  acceptanceStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  acceptanceStatusNEQ?: InputMaybe<AiSuggestionAcceptanceStatus>;
  acceptanceStatusNotIn?: InputMaybe<Array<AiSuggestionAcceptanceStatus>>;
  acceptanceStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<AiSuggestionWhereInput>>;
  /** beneficial_owners_as_of_date field predicates */
  beneficialOwnersAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  beneficialOwnersAsOfDateGT?: InputMaybe<Scalars['Time']['input']>;
  beneficialOwnersAsOfDateGTE?: InputMaybe<Scalars['Time']['input']>;
  beneficialOwnersAsOfDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  beneficialOwnersAsOfDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  beneficialOwnersAsOfDateLT?: InputMaybe<Scalars['Time']['input']>;
  beneficialOwnersAsOfDateLTE?: InputMaybe<Scalars['Time']['input']>;
  beneficialOwnersAsOfDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  beneficialOwnersAsOfDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  beneficialOwnersAsOfDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** business_entity_tax_status field predicates */
  businessEntityTaxStatus?: InputMaybe<AiSuggestionBusinessEntityTaxStatus>;
  businessEntityTaxStatusIn?: InputMaybe<Array<AiSuggestionBusinessEntityTaxStatus>>;
  businessEntityTaxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  businessEntityTaxStatusNEQ?: InputMaybe<AiSuggestionBusinessEntityTaxStatus>;
  businessEntityTaxStatusNotIn?: InputMaybe<Array<AiSuggestionBusinessEntityTaxStatus>>;
  businessEntityTaxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** crummey_withdrawal_period_days field predicates */
  crummeyWithdrawalPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysGT?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysGTE?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  crummeyWithdrawalPeriodDaysIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  crummeyWithdrawalPeriodDaysLT?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysLTE?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysNEQ?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  crummeyWithdrawalPeriodDaysNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** doing_business_as_name field predicates */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContains?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  doingBusinessAsNameLT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameLTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNEQ?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** fincen_id field predicates */
  fincenID?: InputMaybe<Scalars['String']['input']>;
  fincenIDContains?: InputMaybe<Scalars['String']['input']>;
  fincenIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  fincenIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  fincenIDGT?: InputMaybe<Scalars['String']['input']>;
  fincenIDGTE?: InputMaybe<Scalars['String']['input']>;
  fincenIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fincenIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fincenIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fincenIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  fincenIDLT?: InputMaybe<Scalars['String']['input']>;
  fincenIDLTE?: InputMaybe<Scalars['String']['input']>;
  fincenIDNEQ?: InputMaybe<Scalars['String']['input']>;
  fincenIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fincenIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<AiSuggestionEntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<AiSuggestionEntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<AiSuggestionEntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<AiSuggestionEntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** client_organization edge predicates */
  hasClientOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasClientOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** client_profile edge predicates */
  hasClientProfile?: InputMaybe<Scalars['Boolean']['input']>;
  hasClientProfileWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** evidence edge predicates */
  hasEvidence?: InputMaybe<Scalars['Boolean']['input']>;
  hasEvidenceWith?: InputMaybe<Array<AiSuggestionEvidenceWhereInput>>;
  /** file edge predicates */
  hasFile?: InputMaybe<Scalars['Boolean']['input']>;
  hasFileWith?: InputMaybe<Array<FileWhereInput>>;
  /** kg_root edge predicates */
  hasKgRoot?: InputMaybe<Scalars['Boolean']['input']>;
  hasKgRootWith?: InputMaybe<Array<KgRootWhereInput>>;
  /** has_matched field predicates */
  hasMatched?: InputMaybe<Scalars['Boolean']['input']>;
  hasMatchedIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  hasMatchedNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  hasMatchedNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** update_user edge predicates */
  hasUpdateUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** jurisdiction_state_code field predicates */
  jurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  jurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  jurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  jurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  jurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  jurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  jurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  jurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  jurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  jurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  jurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  jurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  jurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  jurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  jurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** kind field predicates */
  kind?: InputMaybe<AiSuggestionKind>;
  kindIn?: InputMaybe<Array<AiSuggestionKind>>;
  kindNEQ?: InputMaybe<AiSuggestionKind>;
  kindNotIn?: InputMaybe<Array<AiSuggestionKind>>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<AiSuggestionWhereInput>;
  or?: InputMaybe<Array<AiSuggestionWhereInput>>;
  /** tax_id field predicates */
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxIDContains?: InputMaybe<Scalars['String']['input']>;
  taxIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  taxIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  taxIDGT?: InputMaybe<Scalars['String']['input']>;
  taxIDGTE?: InputMaybe<Scalars['String']['input']>;
  taxIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  taxIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  taxIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxIDLT?: InputMaybe<Scalars['String']['input']>;
  taxIDLTE?: InputMaybe<Scalars['String']['input']>;
  taxIDNEQ?: InputMaybe<Scalars['String']['input']>;
  taxIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['ID']['input']>;
  updatedByContains?: InputMaybe<Scalars['ID']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByGT?: InputMaybe<Scalars['ID']['input']>;
  updatedByGTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedByLT?: InputMaybe<Scalars['ID']['input']>;
  updatedByLTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['ID']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AccessAgeParameter = Node & {
  __typename?: 'AccessAgeParameter';
  /** the access parameter this access age parameter is for */
  accessParameter?: Maybe<AccessParameter>;
  /** The end of the age requirement for this distribution */
  ageRequirementEnd?: Maybe<Scalars['Int']['output']>;
  /** The start of the age requirement for this distribution */
  ageRequirementStart?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  household: Household;
  /** the household this object belongs to */
  householdID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** The notes for the age access parameter */
  notes?: Maybe<Scalars['String']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type AccessAgeParameterConnection = {
  __typename?: 'AccessAgeParameterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccessAgeParameterEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccessAgeParameterEdge = {
  __typename?: 'AccessAgeParameterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AccessAgeParameter>;
};

/** Ordering options for AccessAgeParameter connections */
export type AccessAgeParameterOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AccessAgeParameters. */
  field: AccessAgeParameterOrderField;
};

/** Properties by which AccessAgeParameter connections can be ordered. */
export enum AccessAgeParameterOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * AccessAgeParameterWhereInput is used for filtering AccessAgeParameter objects.
 * Input was generated by ent.
 */
export type AccessAgeParameterWhereInput = {
  /** age_requirement_end field predicates */
  ageRequirementEnd?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndGT?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndGTE?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  ageRequirementEndIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ageRequirementEndLT?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndLTE?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndNEQ?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  ageRequirementEndNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** age_requirement_start field predicates */
  ageRequirementStart?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartGT?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartGTE?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  ageRequirementStartIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ageRequirementStartLT?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartLTE?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartNEQ?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  ageRequirementStartNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<AccessAgeParameterWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** access_parameter edge predicates */
  hasAccessParameter?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccessParameterWith?: InputMaybe<Array<AccessParameterWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** household_id field predicates */
  householdID?: InputMaybe<Scalars['ID']['input']>;
  householdIDContains?: InputMaybe<Scalars['ID']['input']>;
  householdIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDGT?: InputMaybe<Scalars['ID']['input']>;
  householdIDGTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  householdIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDLT?: InputMaybe<Scalars['ID']['input']>;
  householdIDLTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  householdIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AccessAgeParameterWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<AccessAgeParameterWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type AccessParameter = Node & {
  __typename?: 'AccessParameter';
  accessAgeParameters: AccessAgeParameterConnection;
  /** The notes for the access parameter */
  accessParameterNotes?: Maybe<Scalars['String']['output']>;
  /** The AMOUNT kind of access. Only valid when kind is AMOUNT */
  amount?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** the beneficiary this access parameter is for */
  beneficiary?: Maybe<Beneficiary>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The frequency of the access */
  frequency?: Maybe<AccessParameterFrequency>;
  household: Household;
  /** the household this object belongs to */
  householdID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** The kind of access parameter */
  kind: AccessParameterKind;
  /** The percentage of the access. Only valid when kind is PERCENTAGE */
  percentage?: Maybe<Scalars['Percent']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type AccessParameterAccessAgeParametersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessAgeParameterOrder>>;
  where?: InputMaybe<AccessAgeParameterWhereInput>;
};

/** A connection to a list of items. */
export type AccessParameterConnection = {
  __typename?: 'AccessParameterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccessParameterEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccessParameterEdge = {
  __typename?: 'AccessParameterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AccessParameter>;
};

/** AccessParameterFrequency is enum for the field frequency */
export enum AccessParameterFrequency {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Semiannually = 'SEMIANNUALLY'
}

/** AccessParameterKind is enum for the field kind */
export enum AccessParameterKind {
  AllTrustIncome = 'ALL_TRUST_INCOME',
  Amount = 'AMOUNT',
  Full = 'FULL',
  FullDiscretion = 'FULL_DISCRETION',
  Hems = 'HEMS',
  Hms = 'HMS',
  NetIncome = 'NET_INCOME',
  NetIncomeWithMakeup = 'NET_INCOME_WITH_MAKEUP',
  Other = 'OTHER',
  Percentage = 'PERCENTAGE'
}

/** Ordering options for AccessParameter connections */
export type AccessParameterOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AccessParameters. */
  field: AccessParameterOrderField;
};

/** Properties by which AccessParameter connections can be ordered. */
export enum AccessParameterOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * AccessParameterWhereInput is used for filtering AccessParameter objects.
 * Input was generated by ent.
 */
export type AccessParameterWhereInput = {
  /** access_parameter_notes field predicates */
  accessParameterNotes?: InputMaybe<Scalars['String']['input']>;
  accessParameterNotesContains?: InputMaybe<Scalars['String']['input']>;
  accessParameterNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  accessParameterNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  accessParameterNotesGT?: InputMaybe<Scalars['String']['input']>;
  accessParameterNotesGTE?: InputMaybe<Scalars['String']['input']>;
  accessParameterNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  accessParameterNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  accessParameterNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accessParameterNotesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  accessParameterNotesLT?: InputMaybe<Scalars['String']['input']>;
  accessParameterNotesLTE?: InputMaybe<Scalars['String']['input']>;
  accessParameterNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  accessParameterNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accessParameterNotesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** amount field predicates */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  amountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  amountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  amountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<AccessParameterWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** frequency field predicates */
  frequency?: InputMaybe<AccessParameterFrequency>;
  frequencyIn?: InputMaybe<Array<AccessParameterFrequency>>;
  frequencyIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  frequencyNEQ?: InputMaybe<AccessParameterFrequency>;
  frequencyNotIn?: InputMaybe<Array<AccessParameterFrequency>>;
  frequencyNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** access_age_parameters edge predicates */
  hasAccessAgeParameters?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccessAgeParametersWith?: InputMaybe<Array<AccessAgeParameterWhereInput>>;
  /** beneficiary edge predicates */
  hasBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiaryWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** household_id field predicates */
  householdID?: InputMaybe<Scalars['ID']['input']>;
  householdIDContains?: InputMaybe<Scalars['ID']['input']>;
  householdIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDGT?: InputMaybe<Scalars['ID']['input']>;
  householdIDGTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  householdIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDLT?: InputMaybe<Scalars['ID']['input']>;
  householdIDLTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  householdIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<AccessParameterKind>;
  kindIn?: InputMaybe<Array<AccessParameterKind>>;
  kindNEQ?: InputMaybe<AccessParameterKind>;
  kindNotIn?: InputMaybe<Array<AccessParameterKind>>;
  not?: InputMaybe<AccessParameterWhereInput>;
  or?: InputMaybe<Array<AccessParameterWhereInput>>;
  /** percentage field predicates */
  percentage?: InputMaybe<Scalars['Percent']['input']>;
  percentageGT?: InputMaybe<Scalars['Percent']['input']>;
  percentageGTE?: InputMaybe<Scalars['Percent']['input']>;
  percentageIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  percentageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  percentageLT?: InputMaybe<Scalars['Percent']['input']>;
  percentageLTE?: InputMaybe<Scalars['Percent']['input']>;
  percentageNEQ?: InputMaybe<Scalars['Percent']['input']>;
  percentageNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  percentageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Account = Node & {
  __typename?: 'Account';
  addeparEntityID?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Initial valuation of the account */
  initialValuation?: Maybe<AssetValuationV2>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
  valuations: AssetValuationV2Connection;
};


export type AccountValuationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AssetValuationV2Order>>;
  where?: InputMaybe<AssetValuationV2WhereInput>;
};

/** A connection to a list of items. */
export type AccountConnection = {
  __typename?: 'AccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AccountEdge = {
  __typename?: 'AccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Account>;
};

/** Ordering options for Account connections */
export type AccountOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Accounts. */
  field: AccountOrderField;
};

/** Properties by which Account connections can be ordered. */
export enum AccountOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * AccountWhereInput is used for filtering Account objects.
 * Input was generated by ent.
 */
export type AccountWhereInput = {
  /** addepar_entity_id field predicates */
  addeparEntityID?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDGT?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDGTE?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  addeparEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparEntityIDLT?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDLTE?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDNEQ?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  addeparEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<AccountWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** initial_valuation edge predicates */
  hasInitialValuation?: InputMaybe<Scalars['Boolean']['input']>;
  hasInitialValuationWith?: InputMaybe<Array<AssetValuationV2WhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** valuations edge predicates */
  hasValuations?: InputMaybe<Scalars['Boolean']['input']>;
  hasValuationsWith?: InputMaybe<Array<AssetValuationV2WhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AccountWhereInput>;
  or?: InputMaybe<Array<AccountWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type AcknowledgeAiSuggestionInput = {
  clientOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  clientProfileID?: InputMaybe<Scalars['ID']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  status: AiSuggestionAcceptanceStatus;
  suggestionID: Scalars['ID']['input'];
};

export type Activity = Node & {
  __typename?: 'Activity';
  changes?: Maybe<Array<ActivityFieldChange>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  entities?: Maybe<Array<Entity>>;
  groupName?: Maybe<ActivityGroupName>;
  household?: Maybe<Household>;
  id: Scalars['ID']['output'];
  requestID?: Maybe<Scalars['String']['output']>;
  target: Node;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
  verb: ActivityVerb;
};

/** A connection to a list of items. */
export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ActivityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Activity>;
};

export type ActivityFieldChange = {
  __typename?: 'ActivityFieldChange';
  fieldDisplayName?: Maybe<Scalars['String']['output']>;
  fieldName: Scalars['String']['output'];
  newValue?: Maybe<Scalars['String']['output']>;
  newValueCleared?: Maybe<Scalars['Boolean']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
  /**
   * RemovedValue is typically only generated by edges
   * since they only have a notion of +/-
   */
  removedValue?: Maybe<Scalars['String']['output']>;
};

export type ActivityGroup = {
  __typename?: 'ActivityGroup';
  activities: Array<Activity>;
};

export type ActivityGroupConnection = {
  __typename?: 'ActivityGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ActivityGroupEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ActivityGroupEdge = {
  __typename?: 'ActivityGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ActivityGroup>;
};

/** ActivityGroupName is enum for the field group_name */
export enum ActivityGroupName {
  AssignEntityTasks = 'assign_entity_tasks',
  AssignStrategyTasks = 'assign_strategy_tasks',
  CreateEntity = 'create_entity',
  CreateStrategy = 'create_strategy',
  CreateTeam = 'create_team',
  DeleteEntity = 'delete_entity',
  DeleteStrategy = 'delete_strategy',
  DeleteTeam = 'delete_team',
  UpdateEntity = 'update_entity',
  UpdateEntityTask = 'update_entity_task',
  UpdateStrategy = 'update_strategy',
  UpdateTeam = 'update_team'
}

/** Ordering options for Activity connections */
export type ActivityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Activities. */
  field: ActivityOrderField;
};

/** Properties by which Activity connections can be ordered. */
export enum ActivityOrderField {
  CreatedAt = 'created_at',
  RequestId = 'request_id',
  UpdatedAt = 'updated_at'
}

/** ActivityVerb is enum for the field verb */
export enum ActivityVerb {
  Create = 'create',
  Delete = 'delete',
  Update = 'update'
}

/**
 * ActivityWhereInput is used for filtering Activity objects.
 * Input was generated by ent.
 */
export type ActivityWhereInput = {
  and?: InputMaybe<Array<ActivityWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** group_name field predicates */
  groupName?: InputMaybe<ActivityGroupName>;
  groupNameIn?: InputMaybe<Array<ActivityGroupName>>;
  groupNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  groupNameNEQ?: InputMaybe<ActivityGroupName>;
  groupNameNotIn?: InputMaybe<Array<ActivityGroupName>>;
  groupNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entities edge predicates */
  hasEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntitiesWith?: InputMaybe<Array<EntityWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ActivityWhereInput>;
  or?: InputMaybe<Array<ActivityWhereInput>>;
  /** request_id field predicates */
  requestID?: InputMaybe<Scalars['String']['input']>;
  requestIDContains?: InputMaybe<Scalars['String']['input']>;
  requestIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  requestIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  requestIDGT?: InputMaybe<Scalars['String']['input']>;
  requestIDGTE?: InputMaybe<Scalars['String']['input']>;
  requestIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  requestIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  requestIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  requestIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requestIDLT?: InputMaybe<Scalars['String']['input']>;
  requestIDLTE?: InputMaybe<Scalars['String']['input']>;
  requestIDNEQ?: InputMaybe<Scalars['String']['input']>;
  requestIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  requestIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** verb field predicates */
  verb?: InputMaybe<ActivityVerb>;
  verbIn?: InputMaybe<Array<ActivityVerb>>;
  verbNEQ?: InputMaybe<ActivityVerb>;
  verbNotIn?: InputMaybe<Array<ActivityVerb>>;
};

export type AddeparJobStatusAndResults = {
  __typename?: 'AddeparJobStatusAndResults';
  results?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Address = Node & {
  __typename?: 'Address';
  cityName?: Maybe<Scalars['String']['output']>;
  clientProfile?: Maybe<Array<ClientProfile>>;
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  stateCode: Scalars['String']['output'];
  streetLineOne?: Maybe<Scalars['String']['output']>;
  streetLineTwo?: Maybe<Scalars['String']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type AddressConnection = {
  __typename?: 'AddressConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AddressEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AddressEdge = {
  __typename?: 'AddressEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Address>;
};

/** Ordering options for Address connections */
export type AddressOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Addresses. */
  field: AddressOrderField;
};

/** Properties by which Address connections can be ordered. */
export enum AddressOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * AddressWhereInput is used for filtering Address objects.
 * Input was generated by ent.
 */
export type AddressWhereInput = {
  and?: InputMaybe<Array<AddressWhereInput>>;
  /** city_name field predicates */
  cityName?: InputMaybe<Scalars['String']['input']>;
  cityNameContains?: InputMaybe<Scalars['String']['input']>;
  cityNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  cityNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  cityNameGT?: InputMaybe<Scalars['String']['input']>;
  cityNameGTE?: InputMaybe<Scalars['String']['input']>;
  cityNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  cityNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  cityNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  cityNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  cityNameLT?: InputMaybe<Scalars['String']['input']>;
  cityNameLTE?: InputMaybe<Scalars['String']['input']>;
  cityNameNEQ?: InputMaybe<Scalars['String']['input']>;
  cityNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  cityNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** client_profile edge predicates */
  hasClientProfile?: InputMaybe<Scalars['Boolean']['input']>;
  hasClientProfileWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AddressWhereInput>;
  or?: InputMaybe<Array<AddressWhereInput>>;
  /** postal_code field predicates */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  postalCodeContains?: InputMaybe<Scalars['String']['input']>;
  postalCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  postalCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  postalCodeGT?: InputMaybe<Scalars['String']['input']>;
  postalCodeGTE?: InputMaybe<Scalars['String']['input']>;
  postalCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  postalCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  postalCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  postalCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  postalCodeLT?: InputMaybe<Scalars['String']['input']>;
  postalCodeLTE?: InputMaybe<Scalars['String']['input']>;
  postalCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  postalCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  postalCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** state_code field predicates */
  stateCode?: InputMaybe<Scalars['String']['input']>;
  stateCodeContains?: InputMaybe<Scalars['String']['input']>;
  stateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  stateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  stateCodeGT?: InputMaybe<Scalars['String']['input']>;
  stateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  stateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  stateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  stateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  stateCodeLT?: InputMaybe<Scalars['String']['input']>;
  stateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  stateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  stateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** street_line_one field predicates */
  streetLineOne?: InputMaybe<Scalars['String']['input']>;
  streetLineOneContains?: InputMaybe<Scalars['String']['input']>;
  streetLineOneContainsFold?: InputMaybe<Scalars['String']['input']>;
  streetLineOneEqualFold?: InputMaybe<Scalars['String']['input']>;
  streetLineOneGT?: InputMaybe<Scalars['String']['input']>;
  streetLineOneGTE?: InputMaybe<Scalars['String']['input']>;
  streetLineOneHasPrefix?: InputMaybe<Scalars['String']['input']>;
  streetLineOneHasSuffix?: InputMaybe<Scalars['String']['input']>;
  streetLineOneIn?: InputMaybe<Array<Scalars['String']['input']>>;
  streetLineOneIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  streetLineOneLT?: InputMaybe<Scalars['String']['input']>;
  streetLineOneLTE?: InputMaybe<Scalars['String']['input']>;
  streetLineOneNEQ?: InputMaybe<Scalars['String']['input']>;
  streetLineOneNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  streetLineOneNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** street_line_two field predicates */
  streetLineTwo?: InputMaybe<Scalars['String']['input']>;
  streetLineTwoContains?: InputMaybe<Scalars['String']['input']>;
  streetLineTwoContainsFold?: InputMaybe<Scalars['String']['input']>;
  streetLineTwoEqualFold?: InputMaybe<Scalars['String']['input']>;
  streetLineTwoGT?: InputMaybe<Scalars['String']['input']>;
  streetLineTwoGTE?: InputMaybe<Scalars['String']['input']>;
  streetLineTwoHasPrefix?: InputMaybe<Scalars['String']['input']>;
  streetLineTwoHasSuffix?: InputMaybe<Scalars['String']['input']>;
  streetLineTwoIn?: InputMaybe<Array<Scalars['String']['input']>>;
  streetLineTwoIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  streetLineTwoLT?: InputMaybe<Scalars['String']['input']>;
  streetLineTwoLTE?: InputMaybe<Scalars['String']['input']>;
  streetLineTwoNEQ?: InputMaybe<Scalars['String']['input']>;
  streetLineTwoNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  streetLineTwoNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export enum AfterDeath {
  First = 'First',
  None = 'None',
  Second = 'Second'
}

/**
 * ApplicationAuthorization is a summarization of the current user's level of access that governs how the application
 * should behave.
 */
export type ApplicationAuthorization = {
  __typename?: 'ApplicationAuthorization';
  /** branding describes the user's ability to update the branding of the tenant */
  branding: AuthorizationLevel;
  canActAsRelationshipOwner: Scalars['Boolean']['output'];
  /**
   *  canModifyGlobalCategories describes the user's ability to modify the global categories on the platform, e.g. by adding, removing
   * or reordering asset classes, associated integration asset classes, and balance sheet categories
   */
  canModifyGlobalCategories: Scalars['Boolean']['output'];
  /**
   * collaborators describes the user's ability to add, edit, and delete the list of collaborators on the platform or modify
   * the collaborators' association with households that the user has access to.
   */
  collaborators: AuthorizationLevel;
  hasAllClientsAccess: Scalars['Boolean']['output'];
  /** households describes the user's ability to add, edit, and delete households on the platform. */
  households: AuthorizationLevel;
  /**
   * internalEmployees describes the user's ability to exhaustively list and/or modify the employees on the tenant via
   * user management interface. It doesn't relate to their ability to e.g. view a list of assignees.
   */
  internalEmployees: AuthorizationLevel;
  userKind: UserKind;
};

export enum AssetCategoryGroup {
  CashAndCashEquivalents = 'CASH_AND_CASH_EQUIVALENTS',
  PrivateAsset = 'PRIVATE_ASSET',
  PublicAsset = 'PUBLIC_ASSET',
  UnknownAssetCategory = 'UNKNOWN_ASSET_CATEGORY'
}

export type AssetClass = Node & {
  __typename?: 'AssetClass';
  balanceSheetCategory?: Maybe<BalanceSheetCategory>;
  createdAt: Scalars['Time']['output'];
  displayName: Scalars['String']['output'];
  /** The defined growth rate for the asset class */
  growthRate?: Maybe<Scalars['Percent']['output']>;
  id: Scalars['ID']['output'];
  integrationAssetClasses: IntegrationAssetClassConnection;
  /** Whether this is a system class that cannot be deleted, renamed, or have liquidity status changed */
  isSystemClass: Scalars['Boolean']['output'];
  liquidityStatus: AssetClassLiquidityStatus;
  /** The order in which this asset class should be displayed in the UI */
  sortOrder: Scalars['Int']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};


export type AssetClassIntegrationAssetClassesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<IntegrationAssetClassOrder>>;
  where?: InputMaybe<IntegrationAssetClassWhereInput>;
};

/** A connection to a list of items. */
export type AssetClassConnection = {
  __typename?: 'AssetClassConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AssetClassEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AssetClassEdge = {
  __typename?: 'AssetClassEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AssetClass>;
};

/** AssetClassLiquidityStatus is enum for the field liquidity_status */
export enum AssetClassLiquidityStatus {
  Illiquid = 'ILLIQUID',
  Liquid = 'LIQUID'
}

/** Ordering options for AssetClass connections */
export type AssetClassOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AssetClasses. */
  field: AssetClassOrderField;
};

/** Properties by which AssetClass connections can be ordered. */
export enum AssetClassOrderField {
  CreatedAt = 'created_at',
  SortOrder = 'sort_order',
  UpdatedAt = 'updated_at'
}

/**
 * AssetClassWhereInput is used for filtering AssetClass objects.
 * Input was generated by ent.
 */
export type AssetClassWhereInput = {
  and?: InputMaybe<Array<AssetClassWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** growth_rate field predicates */
  growthRate?: InputMaybe<Scalars['Percent']['input']>;
  growthRateGT?: InputMaybe<Scalars['Percent']['input']>;
  growthRateGTE?: InputMaybe<Scalars['Percent']['input']>;
  growthRateIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  growthRateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  growthRateLT?: InputMaybe<Scalars['Percent']['input']>;
  growthRateLTE?: InputMaybe<Scalars['Percent']['input']>;
  growthRateNEQ?: InputMaybe<Scalars['Percent']['input']>;
  growthRateNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  growthRateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** balance_sheet_category edge predicates */
  hasBalanceSheetCategory?: InputMaybe<Scalars['Boolean']['input']>;
  hasBalanceSheetCategoryWith?: InputMaybe<Array<BalanceSheetCategoryWhereInput>>;
  /** integration_asset_classes edge predicates */
  hasIntegrationAssetClasses?: InputMaybe<Scalars['Boolean']['input']>;
  hasIntegrationAssetClassesWith?: InputMaybe<Array<IntegrationAssetClassWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_system_class field predicates */
  isSystemClass?: InputMaybe<Scalars['Boolean']['input']>;
  isSystemClassNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** liquidity_status field predicates */
  liquidityStatus?: InputMaybe<AssetClassLiquidityStatus>;
  liquidityStatusIn?: InputMaybe<Array<AssetClassLiquidityStatus>>;
  liquidityStatusNEQ?: InputMaybe<AssetClassLiquidityStatus>;
  liquidityStatusNotIn?: InputMaybe<Array<AssetClassLiquidityStatus>>;
  not?: InputMaybe<AssetClassWhereInput>;
  or?: InputMaybe<Array<AssetClassWhereInput>>;
  /** sort_order field predicates */
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  sortOrderGT?: InputMaybe<Scalars['Int']['input']>;
  sortOrderGTE?: InputMaybe<Scalars['Int']['input']>;
  sortOrderIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortOrderLT?: InputMaybe<Scalars['Int']['input']>;
  sortOrderLTE?: InputMaybe<Scalars['Int']['input']>;
  sortOrderNEQ?: InputMaybe<Scalars['Int']['input']>;
  sortOrderNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type AssetV2 = Node & {
  __typename?: 'AssetV2';
  /** The name of the account that this asset is part of. */
  accountName?: Maybe<Scalars['String']['output']>;
  assetValue: AssetValueV2;
  category: AssetV2Category;
  class?: Maybe<AssetClass>;
  createdAt: Scalars['Time']['output'];
  /** This will be set for any asset category value we get from a third-party ingested asset category. */
  customIntegrationAssetCategory?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The entity ID of the integrated account that this asset is part of. */
  integratedAccountID?: Maybe<Scalars['Int']['output']>;
  /** The integration this asset was ingested as part of. */
  integrationType?: Maybe<AssetV2IntegrationType>;
  qsbsEligibility: AssetV2QsbsEligibility;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  ticker?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Time']['output'];
  valuation?: Maybe<AssetValuationV2>;
};

/** AssetV2Category is enum for the field category */
export enum AssetV2Category {
  Cash = 'CASH',
  Cryptocurrency = 'CRYPTOCURRENCY',
  ExchangeTradedFund = 'EXCHANGE_TRADED_FUND',
  GeneralPartnership = 'GENERAL_PARTNERSHIP',
  HedgeFund = 'HEDGE_FUND',
  LimitedLiabilityCorporation = 'LIMITED_LIABILITY_CORPORATION',
  LimitedPartnership = 'LIMITED_PARTNERSHIP',
  MoneyMarket = 'MONEY_MARKET',
  MutualFund = 'MUTUAL_FUND',
  Other = 'OTHER',
  PersonalProperty = 'PERSONAL_PROPERTY',
  PrivateEquityFund = 'PRIVATE_EQUITY_FUND',
  PrivateRealEstateInvestmentTrust = 'PRIVATE_REAL_ESTATE_INVESTMENT_TRUST',
  PrivateStock = 'PRIVATE_STOCK',
  PubliclyTradedBond = 'PUBLICLY_TRADED_BOND',
  PublicRealEstateInvestmentTrust = 'PUBLIC_REAL_ESTATE_INVESTMENT_TRUST',
  PublicStockPortfolio = 'PUBLIC_STOCK_PORTFOLIO',
  PublicStockSingle = 'PUBLIC_STOCK_SINGLE',
  UnknownIntegrationCategory = 'UNKNOWN_INTEGRATION_CATEGORY',
  VentureCapitalFund = 'VENTURE_CAPITAL_FUND'
}

/** A connection to a list of items. */
export type AssetV2Connection = {
  __typename?: 'AssetV2Connection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AssetV2Edge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AssetV2Edge = {
  __typename?: 'AssetV2Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AssetV2>;
};

/** AssetV2IntegrationType is enum for the field integration_type */
export enum AssetV2IntegrationType {
  Addepar = 'ADDEPAR',
  BlackDiamond = 'BLACK_DIAMOND'
}

/** Ordering options for AssetV2 connections */
export type AssetV2Order = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AssetV2s. */
  field: AssetV2OrderField;
};

/** Properties by which AssetV2 connections can be ordered. */
export enum AssetV2OrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** AssetV2QsbsEligibility is enum for the field qsbs_eligibility */
export enum AssetV2QsbsEligibility {
  No = 'NO',
  NotConfirmed = 'NOT_CONFIRMED',
  Yes = 'YES'
}

/**
 * AssetV2WhereInput is used for filtering AssetV2 objects.
 * Input was generated by ent.
 */
export type AssetV2WhereInput = {
  /** account_name field predicates */
  accountName?: InputMaybe<Scalars['String']['input']>;
  accountNameContains?: InputMaybe<Scalars['String']['input']>;
  accountNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  accountNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  accountNameGT?: InputMaybe<Scalars['String']['input']>;
  accountNameGTE?: InputMaybe<Scalars['String']['input']>;
  accountNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  accountNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  accountNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accountNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  accountNameLT?: InputMaybe<Scalars['String']['input']>;
  accountNameLTE?: InputMaybe<Scalars['String']['input']>;
  accountNameNEQ?: InputMaybe<Scalars['String']['input']>;
  accountNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accountNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<AssetV2WhereInput>>;
  /** category field predicates */
  category?: InputMaybe<AssetV2Category>;
  categoryIn?: InputMaybe<Array<AssetV2Category>>;
  categoryNEQ?: InputMaybe<AssetV2Category>;
  categoryNotIn?: InputMaybe<Array<AssetV2Category>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** custom_integration_asset_category field predicates */
  customIntegrationAssetCategory?: InputMaybe<Scalars['String']['input']>;
  customIntegrationAssetCategoryContains?: InputMaybe<Scalars['String']['input']>;
  customIntegrationAssetCategoryContainsFold?: InputMaybe<Scalars['String']['input']>;
  customIntegrationAssetCategoryEqualFold?: InputMaybe<Scalars['String']['input']>;
  customIntegrationAssetCategoryGT?: InputMaybe<Scalars['String']['input']>;
  customIntegrationAssetCategoryGTE?: InputMaybe<Scalars['String']['input']>;
  customIntegrationAssetCategoryHasPrefix?: InputMaybe<Scalars['String']['input']>;
  customIntegrationAssetCategoryHasSuffix?: InputMaybe<Scalars['String']['input']>;
  customIntegrationAssetCategoryIn?: InputMaybe<Array<Scalars['String']['input']>>;
  customIntegrationAssetCategoryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  customIntegrationAssetCategoryLT?: InputMaybe<Scalars['String']['input']>;
  customIntegrationAssetCategoryLTE?: InputMaybe<Scalars['String']['input']>;
  customIntegrationAssetCategoryNEQ?: InputMaybe<Scalars['String']['input']>;
  customIntegrationAssetCategoryNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  customIntegrationAssetCategoryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** asset_value edge predicates */
  hasAssetValue?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetValueWith?: InputMaybe<Array<AssetValueV2WhereInput>>;
  /** class edge predicates */
  hasClass?: InputMaybe<Scalars['Boolean']['input']>;
  hasClassWith?: InputMaybe<Array<AssetClassWhereInput>>;
  /** valuation edge predicates */
  hasValuation?: InputMaybe<Scalars['Boolean']['input']>;
  hasValuationWith?: InputMaybe<Array<AssetValuationV2WhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** integrated_account_id field predicates */
  integratedAccountID?: InputMaybe<Scalars['Int']['input']>;
  integratedAccountIDGT?: InputMaybe<Scalars['Int']['input']>;
  integratedAccountIDGTE?: InputMaybe<Scalars['Int']['input']>;
  integratedAccountIDIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  integratedAccountIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  integratedAccountIDLT?: InputMaybe<Scalars['Int']['input']>;
  integratedAccountIDLTE?: InputMaybe<Scalars['Int']['input']>;
  integratedAccountIDNEQ?: InputMaybe<Scalars['Int']['input']>;
  integratedAccountIDNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  integratedAccountIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** integration_type field predicates */
  integrationType?: InputMaybe<AssetV2IntegrationType>;
  integrationTypeIn?: InputMaybe<Array<AssetV2IntegrationType>>;
  integrationTypeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  integrationTypeNEQ?: InputMaybe<AssetV2IntegrationType>;
  integrationTypeNotIn?: InputMaybe<Array<AssetV2IntegrationType>>;
  integrationTypeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<AssetV2WhereInput>;
  or?: InputMaybe<Array<AssetV2WhereInput>>;
  /** qsbs_eligibility field predicates */
  qsbsEligibility?: InputMaybe<AssetV2QsbsEligibility>;
  qsbsEligibilityIn?: InputMaybe<Array<AssetV2QsbsEligibility>>;
  qsbsEligibilityNEQ?: InputMaybe<AssetV2QsbsEligibility>;
  qsbsEligibilityNotIn?: InputMaybe<Array<AssetV2QsbsEligibility>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** ticker field predicates */
  ticker?: InputMaybe<Scalars['String']['input']>;
  tickerContains?: InputMaybe<Scalars['String']['input']>;
  tickerContainsFold?: InputMaybe<Scalars['String']['input']>;
  tickerEqualFold?: InputMaybe<Scalars['String']['input']>;
  tickerGT?: InputMaybe<Scalars['String']['input']>;
  tickerGTE?: InputMaybe<Scalars['String']['input']>;
  tickerHasPrefix?: InputMaybe<Scalars['String']['input']>;
  tickerHasSuffix?: InputMaybe<Scalars['String']['input']>;
  tickerIn?: InputMaybe<Array<Scalars['String']['input']>>;
  tickerIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  tickerLT?: InputMaybe<Scalars['String']['input']>;
  tickerLTE?: InputMaybe<Scalars['String']['input']>;
  tickerNEQ?: InputMaybe<Scalars['String']['input']>;
  tickerNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  tickerNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type AssetValuationV2 = Node & {
  __typename?: 'AssetValuationV2';
  account?: Maybe<Account>;
  assets: AssetV2Connection;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** Free-form notes */
  description?: Maybe<Scalars['String']['output']>;
  /** documents that might be associated with or support this valuation */
  documents?: Maybe<Array<Document>>;
  effectiveDate: Scalars['Time']['output'];
  /**
   * Valuations can work in two ways.
   * The first is manually specifying all the assets+values for a valuation; we tally them up.
   * The second is by specifying a valuation_amount.
   * If a valuation amount is present, we use this to compute the value of a valuation
   * and ignore the value of underlying assets, etc.
   */
  fixedValuationAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  id: Scalars['ID']['output'];
  /** The async job that is processing this valuation. A valuation can only be modified by one job. */
  ingestAsyncJob?: Maybe<AsyncJob>;
  previousValuation?: Maybe<AssetValuationV2>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
  valuationReason?: Maybe<AssetValuationV2ValuationReason>;
  valuationSource: AssetValuationV2ValuationSource;
  valuationValue?: Maybe<Scalars['CurrencyUSD']['output']>;
};


export type AssetValuationV2AssetsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AssetV2Order>>;
  where?: InputMaybe<AssetV2WhereInput>;
};

/** A connection to a list of items. */
export type AssetValuationV2Connection = {
  __typename?: 'AssetValuationV2Connection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AssetValuationV2Edge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AssetValuationV2Edge = {
  __typename?: 'AssetValuationV2Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AssetValuationV2>;
};

/** Ordering options for AssetValuationV2 connections */
export type AssetValuationV2Order = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AssetValuationV2s. */
  field: AssetValuationV2OrderField;
};

/** Properties by which AssetValuationV2 connections can be ordered. */
export enum AssetValuationV2OrderField {
  CreatedAt = 'created_at',
  EffectiveDate = 'effective_date',
  UpdatedAt = 'updated_at'
}

/** AssetValuationV2ValuationReason is enum for the field valuation_reason */
export enum AssetValuationV2ValuationReason {
  AssetRevaluation = 'ASSET_REVALUATION',
  GratAnnuityPayment = 'GRAT_ANNUITY_PAYMENT',
  GratAssetSubstitution = 'GRAT_ASSET_SUBSTITUTION',
  GratRemainderDistribution = 'GRAT_REMAINDER_DISTRIBUTION',
  InitialFunding = 'INITIAL_FUNDING'
}

/** AssetValuationV2ValuationSource is enum for the field valuation_source */
export enum AssetValuationV2ValuationSource {
  Addepar = 'ADDEPAR',
  BlackDiamond = 'BLACK_DIAMOND',
  CsvImport = 'CSV_IMPORT',
  Manual = 'MANUAL'
}

/**
 * AssetValuationV2WhereInput is used for filtering AssetValuationV2 objects.
 * Input was generated by ent.
 */
export type AssetValuationV2WhereInput = {
  and?: InputMaybe<Array<AssetValuationV2WhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** fixed_valuation_amount field predicates */
  fixedValuationAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  fixedValuationAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  fixedValuationAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  fixedValuationAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  fixedValuationAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  fixedValuationAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  fixedValuationAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  fixedValuationAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  fixedValuationAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  fixedValuationAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** account edge predicates */
  hasAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** assets edge predicates */
  hasAssets?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetsWith?: InputMaybe<Array<AssetV2WhereInput>>;
  /** documents edge predicates */
  hasDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentsWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** ingest_async_job edge predicates */
  hasIngestAsyncJob?: InputMaybe<Scalars['Boolean']['input']>;
  hasIngestAsyncJobWith?: InputMaybe<Array<AsyncJobWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AssetValuationV2WhereInput>;
  or?: InputMaybe<Array<AssetValuationV2WhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** valuation_reason field predicates */
  valuationReason?: InputMaybe<AssetValuationV2ValuationReason>;
  valuationReasonIn?: InputMaybe<Array<AssetValuationV2ValuationReason>>;
  valuationReasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  valuationReasonNEQ?: InputMaybe<AssetValuationV2ValuationReason>;
  valuationReasonNotIn?: InputMaybe<Array<AssetValuationV2ValuationReason>>;
  valuationReasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** valuation_source field predicates */
  valuationSource?: InputMaybe<AssetValuationV2ValuationSource>;
  valuationSourceIn?: InputMaybe<Array<AssetValuationV2ValuationSource>>;
  valuationSourceNEQ?: InputMaybe<AssetValuationV2ValuationSource>;
  valuationSourceNotIn?: InputMaybe<Array<AssetValuationV2ValuationSource>>;
};

export type AssetValueV2 = Node & {
  __typename?: 'AssetValueV2';
  asset?: Maybe<AssetV2>;
  createdAt: Scalars['Time']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ownedPercent?: Maybe<Scalars['Percent']['output']>;
  ownedValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  ownershipType: AssetValueV2OwnershipType;
  shareCount?: Maybe<Scalars['Decimal']['output']>;
  shareValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  totalValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  updatedAt: Scalars['Time']['output'];
  value: Scalars['CurrencyUSD']['output'];
};

/** Ordering options for AssetValueV2 connections */
export type AssetValueV2Order = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AssetValueV2s. */
  field: AssetValueV2OrderField;
};

/** Properties by which AssetValueV2 connections can be ordered. */
export enum AssetValueV2OrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** AssetValueV2OwnershipType is enum for the field ownership_type */
export enum AssetValueV2OwnershipType {
  PercentBased = 'PERCENT_BASED',
  ShareBased = 'SHARE_BASED',
  ValueBased = 'VALUE_BASED'
}

/**
 * AssetValueV2WhereInput is used for filtering AssetValueV2 objects.
 * Input was generated by ent.
 */
export type AssetValueV2WhereInput = {
  and?: InputMaybe<Array<AssetValueV2WhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** currency field predicates */
  currency?: InputMaybe<Scalars['String']['input']>;
  currencyContains?: InputMaybe<Scalars['String']['input']>;
  currencyContainsFold?: InputMaybe<Scalars['String']['input']>;
  currencyEqualFold?: InputMaybe<Scalars['String']['input']>;
  currencyGT?: InputMaybe<Scalars['String']['input']>;
  currencyGTE?: InputMaybe<Scalars['String']['input']>;
  currencyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  currencyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  currencyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  currencyIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  currencyLT?: InputMaybe<Scalars['String']['input']>;
  currencyLTE?: InputMaybe<Scalars['String']['input']>;
  currencyNEQ?: InputMaybe<Scalars['String']['input']>;
  currencyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  currencyNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** asset edge predicates */
  hasAsset?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetWith?: InputMaybe<Array<AssetV2WhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<AssetValueV2WhereInput>;
  or?: InputMaybe<Array<AssetValueV2WhereInput>>;
  /** owned_percent field predicates */
  ownedPercent?: InputMaybe<Scalars['Percent']['input']>;
  ownedPercentGT?: InputMaybe<Scalars['Percent']['input']>;
  ownedPercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  ownedPercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  ownedPercentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownedPercentLT?: InputMaybe<Scalars['Percent']['input']>;
  ownedPercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  ownedPercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  ownedPercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  ownedPercentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** owned_value field predicates */
  ownedValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  ownedValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  ownedValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  ownedValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  ownedValueIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownedValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  ownedValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  ownedValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  ownedValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  ownedValueNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** ownership_type field predicates */
  ownershipType?: InputMaybe<AssetValueV2OwnershipType>;
  ownershipTypeIn?: InputMaybe<Array<AssetValueV2OwnershipType>>;
  ownershipTypeNEQ?: InputMaybe<AssetValueV2OwnershipType>;
  ownershipTypeNotIn?: InputMaybe<Array<AssetValueV2OwnershipType>>;
  /** share_count field predicates */
  shareCount?: InputMaybe<Scalars['Decimal']['input']>;
  shareCountGT?: InputMaybe<Scalars['Decimal']['input']>;
  shareCountGTE?: InputMaybe<Scalars['Decimal']['input']>;
  shareCountIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  shareCountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  shareCountLT?: InputMaybe<Scalars['Decimal']['input']>;
  shareCountLTE?: InputMaybe<Scalars['Decimal']['input']>;
  shareCountNEQ?: InputMaybe<Scalars['Decimal']['input']>;
  shareCountNotIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  shareCountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** share_value field predicates */
  shareValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  shareValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  shareValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  shareValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  shareValueIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  shareValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  shareValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  shareValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  shareValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  shareValueNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** total_value field predicates */
  totalValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  totalValueIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  totalValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  totalValueNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type AsyncJob = Node & {
  __typename?: 'AsyncJob';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  document?: Maybe<Array<Document>>;
  documentInferenceEvaluationPrompt?: Maybe<Array<DocumentInferenceEvaluationPrompts>>;
  documentQuestion?: Maybe<Array<DocumentQuestion>>;
  id: Scalars['ID']['output'];
  jobID?: Maybe<Scalars['String']['output']>;
  kgRoot?: Maybe<Array<KgRoot>>;
  kind: AsyncJobKind;
  status: AsyncJobStatus;
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type AsyncJobConnection = {
  __typename?: 'AsyncJobConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AsyncJobEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AsyncJobEdge = {
  __typename?: 'AsyncJobEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<AsyncJob>;
};

/** AsyncJobKind is enum for the field kind */
export enum AsyncJobKind {
  AiOnboarding = 'AI_ONBOARDING',
  AssetIngest = 'ASSET_INGEST',
  ClientAndEntityFetch = 'CLIENT_AND_ENTITY_FETCH',
  DocumentInferenceAiSuggestion = 'DOCUMENT_INFERENCE_AI_SUGGESTION',
  DocumentInferenceCreateEntity = 'DOCUMENT_INFERENCE_CREATE_ENTITY',
  DocumentInferenceDryRun = 'DOCUMENT_INFERENCE_DRY_RUN',
  DocumentInferenceEvaluation = 'DOCUMENT_INFERENCE_EVALUATION',
  DocumentInferenceEvidence = 'DOCUMENT_INFERENCE_EVIDENCE',
  DocumentInferenceGroundTruth = 'DOCUMENT_INFERENCE_GROUND_TRUTH',
  DocumentInferenceMetadata = 'DOCUMENT_INFERENCE_METADATA',
  DocumentInferenceTestPrompt = 'DOCUMENT_INFERENCE_TEST_PROMPT',
  DocumentInferenceUpdateEntity = 'DOCUMENT_INFERENCE_UPDATE_ENTITY',
  DocumentOcrBackfill = 'DOCUMENT_OCR_BACKFILL',
  DocumentQueryResponse = 'DOCUMENT_QUERY_RESPONSE',
  DocumentSummary = 'DOCUMENT_SUMMARY'
}

/** Ordering options for AsyncJob connections */
export type AsyncJobOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order AsyncJobs. */
  field: AsyncJobOrderField;
};

/** Properties by which AsyncJob connections can be ordered. */
export enum AsyncJobOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** AsyncJobStatus is enum for the field status */
export enum AsyncJobStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Queued = 'QUEUED'
}

/**
 * AsyncJobWhereInput is used for filtering AsyncJob objects.
 * Input was generated by ent.
 */
export type AsyncJobWhereInput = {
  and?: InputMaybe<Array<AsyncJobWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** document edge predicates */
  hasDocument?: InputMaybe<Scalars['Boolean']['input']>;
  /** document_inference_evaluation_prompt edge predicates */
  hasDocumentInferenceEvaluationPrompt?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentInferenceEvaluationPromptWith?: InputMaybe<Array<DocumentInferenceEvaluationPromptsWhereInput>>;
  /** document_question edge predicates */
  hasDocumentQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentQuestionWith?: InputMaybe<Array<DocumentQuestionWhereInput>>;
  hasDocumentWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** kg_root edge predicates */
  hasKgRoot?: InputMaybe<Scalars['Boolean']['input']>;
  hasKgRootWith?: InputMaybe<Array<KgRootWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** job_id field predicates */
  jobID?: InputMaybe<Scalars['String']['input']>;
  jobIDContains?: InputMaybe<Scalars['String']['input']>;
  jobIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  jobIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  jobIDGT?: InputMaybe<Scalars['String']['input']>;
  jobIDGTE?: InputMaybe<Scalars['String']['input']>;
  jobIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  jobIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  jobIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  jobIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  jobIDLT?: InputMaybe<Scalars['String']['input']>;
  jobIDLTE?: InputMaybe<Scalars['String']['input']>;
  jobIDNEQ?: InputMaybe<Scalars['String']['input']>;
  jobIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  jobIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** kind field predicates */
  kind?: InputMaybe<AsyncJobKind>;
  kindIn?: InputMaybe<Array<AsyncJobKind>>;
  kindNEQ?: InputMaybe<AsyncJobKind>;
  kindNotIn?: InputMaybe<Array<AsyncJobKind>>;
  not?: InputMaybe<AsyncJobWhereInput>;
  or?: InputMaybe<Array<AsyncJobWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<AsyncJobStatus>;
  statusIn?: InputMaybe<Array<AsyncJobStatus>>;
  statusNEQ?: InputMaybe<AsyncJobStatus>;
  statusNotIn?: InputMaybe<Array<AsyncJobStatus>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * AugmentedCreateAISuggestionEvidenceInput is used for create AISuggestionEvidence object.
 * Input was generated by ent.
 */
export type AugmentedCreateAiSuggestionEvidenceInput = {
  create: CreateAiSuggestionEvidenceInput;
};

/**
 * AugmentedCreateAISuggestionInput is used for create AISuggestion object.
 * Input was generated by ent.
 */
export type AugmentedCreateAiSuggestionInput = {
  create: CreateAiSuggestionInput;
};

/**
 * AugmentedCreateAccessAgeParameterInput is used for create AccessAgeParameter object.
 * Input was generated by ent.
 */
export type AugmentedCreateAccessAgeParameterInput = {
  create: CreateAccessAgeParameterInput;
};

/**
 * AugmentedCreateAccessParameterInput is used for create AccessParameter object.
 * Input was generated by ent.
 */
export type AugmentedCreateAccessParameterInput = {
  create: CreateAccessParameterInput;
  withAccessAgeParameters?: InputMaybe<Array<AugmentedCreateAccessAgeParameterInput>>;
};

/**
 * AugmentedCreateAccountInput is used for create Account object.
 * Input was generated by ent.
 */
export type AugmentedCreateAccountInput = {
  create: CreateAccountInput;
  withInitialValuation?: InputMaybe<AugmentedCreateAssetValuationV2Input>;
  withValuations?: InputMaybe<Array<AugmentedCreateAssetValuationV2Input>>;
};

/**
 * AugmentedCreateAddressInput is used for create Address object.
 * Input was generated by ent.
 */
export type AugmentedCreateAddressInput = {
  create: CreateAddressInput;
};

/**
 * AugmentedCreateAssetClassInput is used for create AssetClass object.
 * Input was generated by ent.
 */
export type AugmentedCreateAssetClassInput = {
  create: CreateAssetClassInput;
  withBalanceSheetCategory?: InputMaybe<AugmentedCreateBalanceSheetCategoryInput>;
  withIntegrationAssetClasses?: InputMaybe<Array<AugmentedCreateIntegrationAssetClassInput>>;
};

/**
 * AugmentedCreateAssetV2Input is used for create AssetV2 object.
 * Input was generated by ent.
 */
export type AugmentedCreateAssetV2Input = {
  create: CreateAssetV2Input;
  withAssetValue: AugmentedCreateAssetValueV2Input;
};

/**
 * AugmentedCreateAssetValuationV2Input is used for create AssetValuationV2 object.
 * Input was generated by ent.
 */
export type AugmentedCreateAssetValuationV2Input = {
  create: CreateAssetValuationV2Input;
  withAssets?: InputMaybe<Array<AugmentedCreateAssetV2Input>>;
};

/**
 * AugmentedCreateAssetValueV2Input is used for create AssetValueV2 object.
 * Input was generated by ent.
 */
export type AugmentedCreateAssetValueV2Input = {
  create: CreateAssetValueV2Input;
};

/**
 * AugmentedCreateBalanceSheetCategoryInput is used for create BalanceSheetCategory object.
 * Input was generated by ent.
 */
export type AugmentedCreateBalanceSheetCategoryInput = {
  create: CreateBalanceSheetCategoryInput;
  withAssetClasses?: InputMaybe<Array<AugmentedCreateAssetClassInput>>;
};

/**
 * AugmentedCreateBeneficiaryAccessParameterInput is used for create BeneficiaryAccessParameter object.
 * Input was generated by ent.
 */
export type AugmentedCreateBeneficiaryAccessParameterInput = {
  create: CreateBeneficiaryAccessParameterInput;
};

/**
 * AugmentedCreateBeneficiaryDistributionInput is used for create BeneficiaryDistribution object.
 * Input was generated by ent.
 */
export type AugmentedCreateBeneficiaryDistributionInput = {
  create: CreateBeneficiaryDistributionInput;
  withDistributionParameters?: InputMaybe<Array<AugmentedCreateBeneficiaryDistributionParameterInput>>;
};

/**
 * AugmentedCreateBeneficiaryDistributionParameterInput is used for create BeneficiaryDistributionParameter object.
 * Input was generated by ent.
 */
export type AugmentedCreateBeneficiaryDistributionParameterInput = {
  create: CreateBeneficiaryDistributionParameterInput;
  withDistribution?: InputMaybe<AugmentedCreateBeneficiaryDistributionInput>;
};

/**
 * AugmentedCreateBeneficiaryInput is used for create Beneficiary object.
 * Input was generated by ent.
 */
export type AugmentedCreateBeneficiaryInput = {
  create: CreateBeneficiaryInput;
  withAccessParameters?: InputMaybe<Array<AugmentedCreateAccessParameterInput>>;
  withDistributions?: InputMaybe<Array<AugmentedCreateBeneficiaryDistributionInput>>;
  withEntity?: InputMaybe<AugmentedCreateEntityInput>;
  withIndividual?: InputMaybe<AugmentedCreateClientProfileInput>;
  withOrganization?: InputMaybe<AugmentedCreateClientOrganizationInput>;
  withPowerOfAppointment?: InputMaybe<AugmentedCreateBeneficiaryPowerOfAppointmentInput>;
  withScheduledDistributions?: InputMaybe<Array<AugmentedCreateScheduledDistributionInput>>;
};

/**
 * AugmentedCreateBeneficiaryPowerOfAppointmentInput is used for create BeneficiaryPowerOfAppointment object.
 * Input was generated by ent.
 */
export type AugmentedCreateBeneficiaryPowerOfAppointmentInput = {
  create: CreateBeneficiaryPowerOfAppointmentInput;
};

/**
 * AugmentedCreateCCorpBusinessEntityInput is used for create CCorpBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedCreateCCorpBusinessEntityInput = {
  create: CreateCCorpBusinessEntityInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedCreateCLTProposalInput is used for create CLTProposal object.
 * Input was generated by ent.
 */
export type AugmentedCreateCltProposalInput = {
  create: CreateCltProposalInput;
  withCharitableIncomeBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withProposal?: InputMaybe<AugmentedCreateProposalInput>;
  withRemainderBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
};

/**
 * AugmentedCreateCLTTrustInput is used for create CLTTrust object.
 * Input was generated by ent.
 */
export type AugmentedCreateCltTrustInput = {
  create: CreateCltTrustInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withLifetimeBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withRemainderBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedCreateCRTProposalInput is used for create CRTProposal object.
 * Input was generated by ent.
 */
export type AugmentedCreateCrtProposalInput = {
  create: CreateCrtProposalInput;
  withCharitableRemainderBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withIncomeBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withProposal?: InputMaybe<AugmentedCreateProposalInput>;
};

/**
 * AugmentedCreateCRTTrustInput is used for create CRTTrust object.
 * Input was generated by ent.
 */
export type AugmentedCreateCrtTrustInput = {
  create: CreateCrtTrustInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withLifetimeBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withRemainderBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedCreateCashflowInput is used for create Cashflow object.
 * Input was generated by ent.
 */
export type AugmentedCreateCashflowInput = {
  create: CreateCashflowInput;
};

/**
 * AugmentedCreateClientOrganizationInput is used for create ClientOrganization object.
 * Input was generated by ent.
 */
export type AugmentedCreateClientOrganizationInput = {
  create: CreateClientOrganizationInput;
  withAddress?: InputMaybe<AugmentedCreateAddressInput>;
};

/**
 * AugmentedCreateClientPresentationInput is used for create ClientPresentation object.
 * Input was generated by ent.
 */
export type AugmentedCreateClientPresentationInput = {
  create: CreateClientPresentationInput;
};

/**
 * AugmentedCreateClientProfileInput is used for create ClientProfile object.
 * Input was generated by ent.
 */
export type AugmentedCreateClientProfileInput = {
  create: CreateClientProfileInput;
  withAddress?: InputMaybe<AugmentedCreateAddressInput>;
  withCashflows?: InputMaybe<Array<AugmentedCreateCashflowInput>>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwnedOwnershipStakes?: InputMaybe<Array<AugmentedCreateOwnershipStakeInput>>;
  withProfessionalTeamRoles?: InputMaybe<Array<AugmentedCreateProfessionalTeamRoleInput>>;
  withTestamentaryEntities?: InputMaybe<Array<AugmentedCreateTestamentaryEntityInput>>;
};

/**
 * AugmentedCreateClientProfileRelationshipInput is used for create ClientProfileRelationship object.
 * Input was generated by ent.
 */
export type AugmentedCreateClientProfileRelationshipInput = {
  create: CreateClientProfileRelationshipInput;
};

/**
 * AugmentedCreateCollaboratorInput is used for create Collaborator object.
 * Input was generated by ent.
 */
export type AugmentedCreateCollaboratorInput = {
  create: CreateCollaboratorInput;
};

/**
 * AugmentedCreateControllingPartyInput is used for create ControllingParty object.
 * Input was generated by ent.
 */
export type AugmentedCreateControllingPartyInput = {
  create: CreateControllingPartyInput;
};

/**
 * AugmentedCreateCustodialPersonalAccountInput is used for create CustodialPersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedCreateCustodialPersonalAccountInput = {
  create: CreateCustodialPersonalAccountInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwner?: InputMaybe<AugmentedCreatePrincipalInput>;
};

/**
 * AugmentedCreateDispositionScenarioInput is used for create DispositionScenario object.
 * Input was generated by ent.
 */
export type AugmentedCreateDispositionScenarioInput = {
  create: CreateDispositionScenarioInput;
  withDispositiveProvisions?: InputMaybe<Array<AugmentedCreateDispositiveProvisionInput>>;
  withDispositiveProvisionsTemplate?: InputMaybe<AugmentedCreateDispositiveProvisionTemplateInput>;
  withSecondDeathDispositiveProvisions?: InputMaybe<Array<AugmentedCreateDispositiveProvisionInput>>;
  withSecondDeathDispositiveProvisionsTemplate?: InputMaybe<AugmentedCreateDispositiveProvisionTemplateInput>;
};

/**
 * AugmentedCreateDispositiveProvisionInput is used for create DispositiveProvision object.
 * Input was generated by ent.
 */
export type AugmentedCreateDispositiveProvisionInput = {
  create: CreateDispositiveProvisionInput;
  withEntity?: InputMaybe<AugmentedCreateEntityInput>;
  withIndividual?: InputMaybe<AugmentedCreateClientProfileInput>;
  withOrganization?: InputMaybe<AugmentedCreateClientOrganizationInput>;
  withTestamentaryEntity?: InputMaybe<AugmentedCreateTestamentaryEntityInput>;
};

/**
 * AugmentedCreateDispositiveProvisionTemplateInput is used for create DispositiveProvisionTemplate object.
 * Input was generated by ent.
 */
export type AugmentedCreateDispositiveProvisionTemplateInput = {
  create: CreateDispositiveProvisionTemplateInput;
  withDispositiveProvisions?: InputMaybe<Array<AugmentedCreateDispositiveProvisionInput>>;
};

/**
 * AugmentedCreateDocumentInput is used for create Document object.
 * Input was generated by ent.
 */
export type AugmentedCreateDocumentInput = {
  create: CreateDocumentInput;
};

/**
 * AugmentedCreateDocumentQuestionAnswersInput is used for create DocumentQuestionAnswers object.
 * Input was generated by ent.
 */
export type AugmentedCreateDocumentQuestionAnswersInput = {
  create: CreateDocumentQuestionAnswersInput;
};

/**
 * AugmentedCreateDocumentQuestionInput is used for create DocumentQuestion object.
 * Input was generated by ent.
 */
export type AugmentedCreateDocumentQuestionInput = {
  create: CreateDocumentQuestionInput;
  withAnswers?: InputMaybe<Array<AugmentedCreateDocumentQuestionAnswersInput>>;
};

/**
 * AugmentedCreateDocumentSummaryInput is used for create DocumentSummary object.
 * Input was generated by ent.
 */
export type AugmentedCreateDocumentSummaryInput = {
  create: CreateDocumentSummaryInput;
};

/**
 * AugmentedCreateDonorAdvisedFundInput is used for create DonorAdvisedFund object.
 * Input was generated by ent.
 */
export type AugmentedCreateDonorAdvisedFundInput = {
  create: CreateDonorAdvisedFundInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withDonors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedCreateEmployeeInput is used for create Employee object.
 * Input was generated by ent.
 */
export type AugmentedCreateEmployeeInput = {
  create: CreateEmployeeInput;
};

/**
 * AugmentedCreateEntityGraphViewInput is used for create EntityGraphView object.
 * Input was generated by ent.
 */
export type AugmentedCreateEntityGraphViewInput = {
  create: CreateEntityGraphViewInput;
};

/**
 * AugmentedCreateEntityInput is used for create Entity object.
 * Input was generated by ent.
 */
export type AugmentedCreateEntityInput = {
  create: CreateEntityInput;
  withCashflows?: InputMaybe<Array<AugmentedCreateCashflowInput>>;
  withCcorpBusinessEntity?: InputMaybe<AugmentedCreateCCorpBusinessEntityInput>;
  withCltTrust?: InputMaybe<AugmentedCreateCltTrustInput>;
  withCrtTrust?: InputMaybe<AugmentedCreateCrtTrustInput>;
  withCustodialPersonalAccount?: InputMaybe<AugmentedCreateCustodialPersonalAccountInput>;
  withDonorAdvisedFund?: InputMaybe<AugmentedCreateDonorAdvisedFundInput>;
  withGpBusinessEntity?: InputMaybe<AugmentedCreateGpBusinessEntityInput>;
  withGratTrust?: InputMaybe<AugmentedCreateGratTrustInput>;
  withIlitTrust?: InputMaybe<AugmentedCreateIlitTrustInput>;
  withIndividualPersonalAccount?: InputMaybe<AugmentedCreateIndividualPersonalAccountInput>;
  withInsurancePersonalAccount?: InputMaybe<AugmentedCreateInsurancePersonalAccountInput>;
  withIrrevocableTrust?: InputMaybe<AugmentedCreateIrrevocableTrustInput>;
  withJointPersonalAccount?: InputMaybe<AugmentedCreateJointPersonalAccountInput>;
  withLiabilitiesOwed?: InputMaybe<Array<AugmentedCreateLiabilityInput>>;
  withLlcBusinessEntity?: InputMaybe<AugmentedCreateLlcBusinessEntityInput>;
  withLpBusinessEntity?: InputMaybe<AugmentedCreateLpBusinessEntityInput>;
  withOwnedByStakes?: InputMaybe<Array<AugmentedCreateOwnershipStakeInput>>;
  withOwnedOwnershipStakes?: InputMaybe<Array<AugmentedCreateOwnershipStakeInput>>;
  withPrivateFoundation?: InputMaybe<AugmentedCreatePrivateFoundationInput>;
  withQprtTrust?: InputMaybe<AugmentedCreateQprtTrustInput>;
  withQualifiedTuitionPersonalAccount?: InputMaybe<AugmentedCreateQualifiedTuitionPersonalAccountInput>;
  withRetirementPersonalAccount?: InputMaybe<AugmentedCreateRetirementPersonalAccountInput>;
  withRevocableTrust?: InputMaybe<AugmentedCreateRevocableTrustInput>;
  withScorpBusinessEntity?: InputMaybe<AugmentedCreateSCorpBusinessEntityInput>;
  withSlatTrust?: InputMaybe<AugmentedCreateSlatTrustInput>;
  withSoleProprietorshipBusinessEntity?: InputMaybe<AugmentedCreateSoleProprietorshipBusinessEntityInput>;
  withStateTaxes?: InputMaybe<Array<AugmentedCreateEntityStateTaxInput>>;
};

/**
 * AugmentedCreateEntityProposalInput is used for create EntityProposal object.
 * Input was generated by ent.
 */
export type AugmentedCreateEntityProposalInput = {
  create: CreateEntityProposalInput;
  withProposal?: InputMaybe<AugmentedCreateProposalInput>;
  withProposalScenarios?: InputMaybe<Array<AugmentedCreateProposalScenarioInput>>;
};

/**
 * AugmentedCreateEntityStateTaxInput is used for create EntityStateTax object.
 * Input was generated by ent.
 */
export type AugmentedCreateEntityStateTaxInput = {
  create: CreateEntityStateTaxInput;
};

/**
 * AugmentedCreateEntityTaskInput is used for create EntityTask object.
 * Input was generated by ent.
 */
export type AugmentedCreateEntityTaskInput = {
  create: CreateEntityTaskInput;
};

/**
 * AugmentedCreateEstateTaxScenarioInput is used for create EstateTaxScenario object.
 * Input was generated by ent.
 */
export type AugmentedCreateEstateTaxScenarioInput = {
  create: CreateEstateTaxScenarioInput;
};

/**
 * AugmentedCreateEstateWaterfallHypotheticalTransferInput is used for create EstateWaterfallHypotheticalTransfer object.
 * Input was generated by ent.
 */
export type AugmentedCreateEstateWaterfallHypotheticalTransferInput = {
  create: CreateEstateWaterfallHypotheticalTransferInput;
  withDestinationEntity?: InputMaybe<AugmentedCreateEntityInput>;
};

/**
 * AugmentedCreateEstateWaterfallInput is used for create EstateWaterfall object.
 * Input was generated by ent.
 */
export type AugmentedCreateEstateWaterfallInput = {
  create: CreateEstateWaterfallInput;
  withHypotheticalTransfers?: InputMaybe<Array<AugmentedCreateEstateWaterfallHypotheticalTransferInput>>;
  withVisualizationConfig?: InputMaybe<AugmentedCreateEstateWaterfallVisualizationConfigInput>;
  withVisualizationGroups?: InputMaybe<Array<AugmentedCreateGraphVisualizationGroupInput>>;
};

/**
 * AugmentedCreateEstateWaterfallVisualizationConfigInput is used for create EstateWaterfallVisualizationConfig object.
 * Input was generated by ent.
 */
export type AugmentedCreateEstateWaterfallVisualizationConfigInput = {
  create: CreateEstateWaterfallVisualizationConfigInput;
};

/**
 * AugmentedCreateEstateWaterfallVisualizationFilterInput is used for create EstateWaterfallVisualizationFilter object.
 * Input was generated by ent.
 */
export type AugmentedCreateEstateWaterfallVisualizationFilterInput = {
  create: CreateEstateWaterfallVisualizationFilterInput;
};

/**
 * AugmentedCreateFeatureFlagInput is used for create FeatureFlag object.
 * Input was generated by ent.
 */
export type AugmentedCreateFeatureFlagInput = {
  create: CreateFeatureFlagInput;
};

/**
 * AugmentedCreateFeatureFlagQueryRuleInput is used for create FeatureFlagQueryRule object.
 * Input was generated by ent.
 */
export type AugmentedCreateFeatureFlagQueryRuleInput = {
  create: CreateFeatureFlagQueryRuleInput;
};

/**
 * AugmentedCreateFileInput is used for create File object.
 * Input was generated by ent.
 */
export type AugmentedCreateFileInput = {
  create: CreateFileInput;
};

/**
 * AugmentedCreateFilePageInput is used for create FilePage object.
 * Input was generated by ent.
 */
export type AugmentedCreateFilePageInput = {
  create: CreateFilePageInput;
};

/**
 * AugmentedCreateGPBusinessEntityInput is used for create GPBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedCreateGpBusinessEntityInput = {
  create: CreateGpBusinessEntityInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedCreateGRATAnnuityV2Input is used for create GRATAnnuityV2 object.
 * Input was generated by ent.
 */
export type AugmentedCreateGratAnnuityV2Input = {
  create: CreateGratAnnuityV2Input;
  withGratTrust?: InputMaybe<AugmentedCreateGratTrustInput>;
};

/**
 * AugmentedCreateGRATProposalScenarioInput is used for create GRATProposalScenario object.
 * Input was generated by ent.
 */
export type AugmentedCreateGratProposalScenarioInput = {
  create: CreateGratProposalScenarioInput;
};

/**
 * AugmentedCreateGRATTrustInput is used for create GRATTrust object.
 * Input was generated by ent.
 */
export type AugmentedCreateGratTrustInput = {
  create: CreateGratTrustInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withAnnuities?: InputMaybe<Array<AugmentedCreateGratAnnuityV2Input>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withDistributionAssetValuation?: InputMaybe<AugmentedCreateAssetValuationV2Input>;
  withGrantor?: InputMaybe<AugmentedCreatePrincipalInput>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedCreateGiftingProposalCashFlowInput is used for create GiftingProposalCashFlow object.
 * Input was generated by ent.
 */
export type AugmentedCreateGiftingProposalCashFlowInput = {
  create: CreateGiftingProposalCashFlowInput;
};

/**
 * AugmentedCreateGiftingProposalGiftInput is used for create GiftingProposalGift object.
 * Input was generated by ent.
 */
export type AugmentedCreateGiftingProposalGiftInput = {
  create: CreateGiftingProposalGiftInput;
  withRecipientClientOrganization?: InputMaybe<AugmentedCreateClientOrganizationInput>;
  withRecipientClientProfile?: InputMaybe<AugmentedCreateClientProfileInput>;
  withRecipientPortfolio?: InputMaybe<AugmentedCreateGiftingProposalPortfolioInput>;
  withSenders?: InputMaybe<Array<AugmentedCreateGiftingProposalGiftSenderInput>>;
};

/**
 * AugmentedCreateGiftingProposalGiftSenderInput is used for create GiftingProposalGiftSender object.
 * Input was generated by ent.
 */
export type AugmentedCreateGiftingProposalGiftSenderInput = {
  create: CreateGiftingProposalGiftSenderInput;
};

/**
 * AugmentedCreateGiftingProposalInput is used for create GiftingProposal object.
 * Input was generated by ent.
 */
export type AugmentedCreateGiftingProposalInput = {
  create: CreateGiftingProposalInput;
  withBaseCashFlows?: InputMaybe<Array<AugmentedCreateGiftingProposalCashFlowInput>>;
  withDefaultGiftingScenario?: InputMaybe<AugmentedCreateGiftingProposalScenarioInput>;
  withGiftingScenarios?: InputMaybe<Array<AugmentedCreateGiftingProposalScenarioInput>>;
  withOutOfEstatePortfolios?: InputMaybe<Array<AugmentedCreateGiftingProposalPortfolioInput>>;
  withProposal?: InputMaybe<AugmentedCreateProposalInput>;
};

/**
 * AugmentedCreateGiftingProposalPortfolioInput is used for create GiftingProposalPortfolio object.
 * Input was generated by ent.
 */
export type AugmentedCreateGiftingProposalPortfolioInput = {
  create: CreateGiftingProposalPortfolioInput;
};

/**
 * AugmentedCreateGiftingProposalScenarioInput is used for create GiftingProposalScenario object.
 * Input was generated by ent.
 */
export type AugmentedCreateGiftingProposalScenarioInput = {
  create: CreateGiftingProposalScenarioInput;
  withScenarioCashFlows?: InputMaybe<Array<AugmentedCreateGiftingProposalCashFlowInput>>;
  withScenarioGifts?: InputMaybe<Array<AugmentedCreateGiftingProposalGiftInput>>;
};

/**
 * AugmentedCreateGraphVisualizationGroupInput is used for create GraphVisualizationGroup object.
 * Input was generated by ent.
 */
export type AugmentedCreateGraphVisualizationGroupInput = {
  create: CreateGraphVisualizationGroupInput;
};

/**
 * AugmentedCreateHouseholdInput is used for create Household object.
 * Input was generated by ent.
 */
export type AugmentedCreateHouseholdInput = {
  create: CreateHouseholdInput;
  withClientProfiles?: InputMaybe<Array<AugmentedCreateClientProfileInput>>;
  withEstateTaxScenarios?: InputMaybe<Array<AugmentedCreateEstateTaxScenarioInput>>;
};

/**
 * AugmentedCreateHouseholdOnboardingInput is used for create HouseholdOnboarding object.
 * Input was generated by ent.
 */
export type AugmentedCreateHouseholdOnboardingInput = {
  create: CreateHouseholdOnboardingInput;
  withSteps?: InputMaybe<Array<AugmentedCreateHouseholdOnboardingStepInput>>;
};

/**
 * AugmentedCreateHouseholdOnboardingStepInput is used for create HouseholdOnboardingStep object.
 * Input was generated by ent.
 */
export type AugmentedCreateHouseholdOnboardingStepInput = {
  create: CreateHouseholdOnboardingStepInput;
};

/**
 * AugmentedCreateHypotheticalWaterfallDispositiveProvisionTemplateInput is used for create HypotheticalWaterfallDispositiveProvisionTemplate object.
 * Input was generated by ent.
 */
export type AugmentedCreateHypotheticalWaterfallDispositiveProvisionTemplateInput = {
  create: CreateHypotheticalWaterfallDispositiveProvisionTemplateInput;
};

/**
 * AugmentedCreateILITCrummeyLetterInput is used for create ILITCrummeyLetter object.
 * Input was generated by ent.
 */
export type AugmentedCreateIlitCrummeyLetterInput = {
  create: CreateIlitCrummeyLetterInput;
};

/**
 * AugmentedCreateILITTrustInput is used for create ILITTrust object.
 * Input was generated by ent.
 */
export type AugmentedCreateIlitTrustInput = {
  create: CreateIlitTrustInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withCrummeyLetters?: InputMaybe<Array<AugmentedCreateIlitCrummeyLetterInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedCreateIndividualPersonalAccountInput is used for create IndividualPersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedCreateIndividualPersonalAccountInput = {
  create: CreateIndividualPersonalAccountInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwner?: InputMaybe<AugmentedCreatePrincipalInput>;
};

/**
 * AugmentedCreateInferredFieldEvidenceInput is used for create InferredFieldEvidence object.
 * Input was generated by ent.
 */
export type AugmentedCreateInferredFieldEvidenceInput = {
  create: CreateInferredFieldEvidenceInput;
};

/**
 * AugmentedCreateInferredFieldInput is used for create InferredField object.
 * Input was generated by ent.
 */
export type AugmentedCreateInferredFieldInput = {
  create: CreateInferredFieldInput;
  withEvidence?: InputMaybe<Array<AugmentedCreateInferredFieldEvidenceInput>>;
};

/**
 * AugmentedCreateInsurancePersonalAccountInput is used for create InsurancePersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedCreateInsurancePersonalAccountInput = {
  create: CreateInsurancePersonalAccountInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwners?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedCreateInsurancePolicyInput is used for create InsurancePolicy object.
 * Input was generated by ent.
 */
export type AugmentedCreateInsurancePolicyInput = {
  create: CreateInsurancePolicyInput;
  withPremiumPayments?: InputMaybe<Array<AugmentedCreateInsurancePremiumPaymentInput>>;
};

/**
 * AugmentedCreateInsurancePremiumPaymentInput is used for create InsurancePremiumPayment object.
 * Input was generated by ent.
 */
export type AugmentedCreateInsurancePremiumPaymentInput = {
  create: CreateInsurancePremiumPaymentInput;
};

/**
 * AugmentedCreateIntegrationAssetClassInput is used for create IntegrationAssetClass object.
 * Input was generated by ent.
 */
export type AugmentedCreateIntegrationAssetClassInput = {
  create: CreateIntegrationAssetClassInput;
  withAssetClass?: InputMaybe<AugmentedCreateAssetClassInput>;
};

/**
 * AugmentedCreateIntegrationConfigurationInput is used for create IntegrationConfiguration object.
 * Input was generated by ent.
 */
export type AugmentedCreateIntegrationConfigurationInput = {
  create: CreateIntegrationConfigurationInput;
};

/**
 * AugmentedCreateIrrevocableTrustInput is used for create IrrevocableTrust object.
 * Input was generated by ent.
 */
export type AugmentedCreateIrrevocableTrustInput = {
  create: CreateIrrevocableTrustInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedCreateJointPersonalAccountInput is used for create JointPersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedCreateJointPersonalAccountInput = {
  create: CreateJointPersonalAccountInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwners?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
};

/**
 * AugmentedCreateKGRootInput is used for create KGRoot object.
 * Input was generated by ent.
 */
export type AugmentedCreateKgRootInput = {
  create: CreateKgRootInput;
};

/**
 * AugmentedCreateKeyPersonInput is used for create KeyPerson object.
 * Input was generated by ent.
 */
export type AugmentedCreateKeyPersonInput = {
  create: CreateKeyPersonInput;
  withEntity?: InputMaybe<AugmentedCreateEntityInput>;
  withIndividual?: InputMaybe<AugmentedCreateClientProfileInput>;
  withOrganization?: InputMaybe<AugmentedCreateClientOrganizationInput>;
  withRoles?: InputMaybe<Array<AugmentedCreateKeyPersonRoleInput>>;
};

/**
 * AugmentedCreateKeyPersonRoleInput is used for create KeyPersonRole object.
 * Input was generated by ent.
 */
export type AugmentedCreateKeyPersonRoleInput = {
  create: CreateKeyPersonRoleInput;
  withKeyPerson?: InputMaybe<AugmentedCreateKeyPersonInput>;
};

/**
 * AugmentedCreateLLCBusinessEntityInput is used for create LLCBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedCreateLlcBusinessEntityInput = {
  create: CreateLlcBusinessEntityInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedCreateLPBusinessEntityInput is used for create LPBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedCreateLpBusinessEntityInput = {
  create: CreateLpBusinessEntityInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedCreateLiabilityInput is used for create Liability object.
 * Input was generated by ent.
 */
export type AugmentedCreateLiabilityInput = {
  create: CreateLiabilityInput;
};

/**
 * AugmentedCreateLifetimeExclusionEventInput is used for create LifetimeExclusionEvent object.
 * Input was generated by ent.
 */
export type AugmentedCreateLifetimeExclusionEventInput = {
  create: CreateLifetimeExclusionEventInput;
  withRecipient?: InputMaybe<AugmentedCreateBeneficiaryInput>;
};

/**
 * AugmentedCreateLoggedTransferInput is used for create LoggedTransfer object.
 * Input was generated by ent.
 */
export type AugmentedCreateLoggedTransferInput = {
  create: CreateLoggedTransferInput;
  withLifetimeExclusionEvents?: InputMaybe<Array<AugmentedCreateLifetimeExclusionEventInput>>;
};

/**
 * AugmentedCreateOwnershipStakeInput is used for create OwnershipStake object.
 * Input was generated by ent.
 */
export type AugmentedCreateOwnershipStakeInput = {
  create: CreateOwnershipStakeInput;
  withOwnedEntity?: InputMaybe<AugmentedCreateEntityInput>;
  withOwningEntity?: InputMaybe<AugmentedCreateEntityInput>;
};

/**
 * AugmentedCreatePrincipalInput is used for create Principal object.
 * Input was generated by ent.
 */
export type AugmentedCreatePrincipalInput = {
  create: CreatePrincipalInput;
};

/**
 * AugmentedCreatePrivateFoundationInput is used for create PrivateFoundation object.
 * Input was generated by ent.
 */
export type AugmentedCreatePrivateFoundationInput = {
  create: CreatePrivateFoundationInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withDonors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedCreateProfessionalTeamRoleInput is used for create ProfessionalTeamRole object.
 * Input was generated by ent.
 */
export type AugmentedCreateProfessionalTeamRoleInput = {
  create: CreateProfessionalTeamRoleInput;
  withIndividual?: InputMaybe<AugmentedCreateClientProfileInput>;
};

/**
 * AugmentedCreateProposalInput is used for create Proposal object.
 * Input was generated by ent.
 */
export type AugmentedCreateProposalInput = {
  create: CreateProposalInput;
  withCltProposal?: InputMaybe<AugmentedCreateCltProposalInput>;
  withCrtProposal?: InputMaybe<AugmentedCreateCrtProposalInput>;
  withEntityProposals?: InputMaybe<Array<AugmentedCreateEntityProposalInput>>;
  withGiftingProposal?: InputMaybe<AugmentedCreateGiftingProposalInput>;
};

/**
 * AugmentedCreateProposalScenarioInput is used for create ProposalScenario object.
 * Input was generated by ent.
 */
export type AugmentedCreateProposalScenarioInput = {
  create: CreateProposalScenarioInput;
  withGratProposalScenario?: InputMaybe<AugmentedCreateGratProposalScenarioInput>;
};

/**
 * AugmentedCreateQPRTTrustInput is used for create QPRTTrust object.
 * Input was generated by ent.
 */
export type AugmentedCreateQprtTrustInput = {
  create: CreateQprtTrustInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedCreateQualifiedTuitionPersonalAccountInput is used for create QualifiedTuitionPersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedCreateQualifiedTuitionPersonalAccountInput = {
  create: CreateQualifiedTuitionPersonalAccountInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwner?: InputMaybe<AugmentedCreatePrincipalInput>;
};

/**
 * AugmentedCreateRetirementPersonalAccountInput is used for create RetirementPersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedCreateRetirementPersonalAccountInput = {
  create: CreateRetirementPersonalAccountInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwner?: InputMaybe<AugmentedCreatePrincipalInput>;
};

/**
 * AugmentedCreateRevocableTrustInput is used for create RevocableTrust object.
 * Input was generated by ent.
 */
export type AugmentedCreateRevocableTrustInput = {
  create: CreateRevocableTrustInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedCreateSCorpBusinessEntityInput is used for create SCorpBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedCreateSCorpBusinessEntityInput = {
  create: CreateSCorpBusinessEntityInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedCreateSLATTrustInput is used for create SLATTrust object.
 * Input was generated by ent.
 */
export type AugmentedCreateSlatTrustInput = {
  create: CreateSlatTrustInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantor?: InputMaybe<AugmentedCreatePrincipalInput>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withLifetimeBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withRemainderBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedCreateSSOConnectionInput is used for create SSOConnection object.
 * Input was generated by ent.
 */
export type AugmentedCreateSsoConnectionInput = {
  create: CreateSsoConnectionInput;
};

/**
 * AugmentedCreateScheduledDistributionInput is used for create ScheduledDistribution object.
 * Input was generated by ent.
 */
export type AugmentedCreateScheduledDistributionInput = {
  create: CreateScheduledDistributionInput;
};

/**
 * AugmentedCreateSoleProprietorshipBusinessEntityInput is used for create SoleProprietorshipBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedCreateSoleProprietorshipBusinessEntityInput = {
  create: CreateSoleProprietorshipBusinessEntityInput;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
};

/**
 * AugmentedCreateSubBrandInput is used for create SubBrand object.
 * Input was generated by ent.
 */
export type AugmentedCreateSubBrandInput = {
  create: CreateSubBrandInput;
};

/**
 * AugmentedCreateTeamInput is used for create Team object.
 * Input was generated by ent.
 */
export type AugmentedCreateTeamInput = {
  create: CreateTeamInput;
};

/**
 * AugmentedCreateTenantBrandingInput is used for create TenantBranding object.
 * Input was generated by ent.
 */
export type AugmentedCreateTenantBrandingInput = {
  create: CreateTenantBrandingInput;
};

/**
 * AugmentedCreateTenantInput is used for create Tenant object.
 * Input was generated by ent.
 */
export type AugmentedCreateTenantInput = {
  create: CreateTenantInput;
};

/**
 * AugmentedCreateTenantSSOConnectionInput is used for create TenantSSOConnection object.
 * Input was generated by ent.
 */
export type AugmentedCreateTenantSsoConnectionInput = {
  create: CreateTenantSsoConnectionInput;
};

/**
 * AugmentedCreateTestamentaryEntityInput is used for create TestamentaryEntity object.
 * Input was generated by ent.
 */
export type AugmentedCreateTestamentaryEntityInput = {
  create: CreateTestamentaryEntityInput;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withBeneficiariesAfterBothGrantorDeaths?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withDispositiveProvisions?: InputMaybe<Array<AugmentedCreateDispositiveProvisionInput>>;
  withDispositiveProvisionsTemplate?: InputMaybe<AugmentedCreateDispositiveProvisionTemplateInput>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositiveProvisions?: InputMaybe<Array<AugmentedCreateDispositiveProvisionInput>>;
  withHypotheticalDispositiveProvisionsTemplates?: InputMaybe<Array<AugmentedCreateHypotheticalWaterfallDispositiveProvisionTemplateInput>>;
  withStateTax?: InputMaybe<AugmentedCreateEntityStateTaxInput>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedCreateTrustAdvisorInput is used for create TrustAdvisor object.
 * Input was generated by ent.
 */
export type AugmentedCreateTrustAdvisorInput = {
  create: CreateTrustAdvisorInput;
  withRoles?: InputMaybe<Array<AugmentedCreateTrustAdvisorRoleInput>>;
};

/**
 * AugmentedCreateTrustAdvisorRoleInput is used for create TrustAdvisorRole object.
 * Input was generated by ent.
 */
export type AugmentedCreateTrustAdvisorRoleInput = {
  create: CreateTrustAdvisorRoleInput;
  withTrustAdvisor?: InputMaybe<AugmentedCreateTrustAdvisorInput>;
};

/**
 * AugmentedCreateTrusteeDutyInput is used for create TrusteeDuty object.
 * Input was generated by ent.
 */
export type AugmentedCreateTrusteeDutyInput = {
  create: CreateTrusteeDutyInput;
  withTrustee?: InputMaybe<AugmentedCreateTrusteeInput>;
};

/**
 * AugmentedCreateTrusteeInput is used for create Trustee object.
 * Input was generated by ent.
 */
export type AugmentedCreateTrusteeInput = {
  create: CreateTrusteeInput;
  withDuties?: InputMaybe<Array<AugmentedCreateTrusteeDutyInput>>;
};

/**
 * AugmentedCreateUserInput is used for create User object.
 * Input was generated by ent.
 */
export type AugmentedCreateUserInput = {
  create: CreateUserInput;
};

/**
 * AugmentedCreateUserNotificationConfigurationInput is used for create UserNotificationConfiguration object.
 * Input was generated by ent.
 */
export type AugmentedCreateUserNotificationConfigurationInput = {
  create: CreateUserNotificationConfigurationInput;
};

export type AugmentedSetGratTrustAnnuityV2PaidInput = {
  annuityId: Scalars['ID']['input'];
  assetValuation: AugmentedCreateAssetValuationV2Input;
  failedAnnuityPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
};

/**
 * AugmentedUpdateAISuggestionInput is used for update AISuggestion object.
 * Input was generated by ent.
 */
export type AugmentedUpdateAiSuggestionInput = {
  id: Scalars['ID']['input'];
  update: UpdateAiSuggestionInput;
};

/**
 * AugmentedUpdateAccessAgeParameterInput is used for update AccessAgeParameter object.
 * Input was generated by ent.
 */
export type AugmentedUpdateAccessAgeParameterInput = {
  id: Scalars['ID']['input'];
  update: UpdateAccessAgeParameterInput;
};

/**
 * AugmentedUpdateAccessParameterInput is used for update AccessParameter object.
 * Input was generated by ent.
 */
export type AugmentedUpdateAccessParameterInput = {
  id: Scalars['ID']['input'];
  update: UpdateAccessParameterInput;
  updateAccessAgeParameters?: InputMaybe<Array<AugmentedUpdateAccessAgeParameterInput>>;
  withAccessAgeParameters?: InputMaybe<Array<AugmentedCreateAccessAgeParameterInput>>;
};

/**
 * AugmentedUpdateAccountInput is used for update Account object.
 * Input was generated by ent.
 */
export type AugmentedUpdateAccountInput = {
  id: Scalars['ID']['input'];
  update: UpdateAccountInput;
  updateInitialValuation?: InputMaybe<AugmentedUpdateAssetValuationV2Input>;
  updateValuations?: InputMaybe<Array<AugmentedUpdateAssetValuationV2Input>>;
  withInitialValuation?: InputMaybe<AugmentedCreateAssetValuationV2Input>;
  withValuations?: InputMaybe<Array<AugmentedCreateAssetValuationV2Input>>;
};

/**
 * AugmentedUpdateAddressInput is used for update Address object.
 * Input was generated by ent.
 */
export type AugmentedUpdateAddressInput = {
  id: Scalars['ID']['input'];
  update: UpdateAddressInput;
};

/**
 * AugmentedUpdateAssetClassInput is used for update AssetClass object.
 * Input was generated by ent.
 */
export type AugmentedUpdateAssetClassInput = {
  id: Scalars['ID']['input'];
  update: UpdateAssetClassInput;
  updateBalanceSheetCategory?: InputMaybe<AugmentedUpdateBalanceSheetCategoryInput>;
  updateIntegrationAssetClasses?: InputMaybe<Array<AugmentedUpdateIntegrationAssetClassInput>>;
  withBalanceSheetCategory?: InputMaybe<AugmentedCreateBalanceSheetCategoryInput>;
  withIntegrationAssetClasses?: InputMaybe<Array<AugmentedCreateIntegrationAssetClassInput>>;
};

/**
 * AugmentedUpdateAssetV2Input is used for update AssetV2 object.
 * Input was generated by ent.
 */
export type AugmentedUpdateAssetV2Input = {
  id: Scalars['ID']['input'];
  update: UpdateAssetV2Input;
  updateAssetValue: AugmentedUpdateAssetValueV2Input;
};

/**
 * AugmentedUpdateAssetValuationV2Input is used for update AssetValuationV2 object.
 * Input was generated by ent.
 */
export type AugmentedUpdateAssetValuationV2Input = {
  id: Scalars['ID']['input'];
  update: UpdateAssetValuationV2Input;
  updateAssets?: InputMaybe<Array<AugmentedUpdateAssetV2Input>>;
  withAssets?: InputMaybe<Array<AugmentedCreateAssetV2Input>>;
};

/**
 * AugmentedUpdateAssetValueV2Input is used for update AssetValueV2 object.
 * Input was generated by ent.
 */
export type AugmentedUpdateAssetValueV2Input = {
  id: Scalars['ID']['input'];
  update: UpdateAssetValueV2Input;
};

/**
 * AugmentedUpdateBalanceSheetCategoryInput is used for update BalanceSheetCategory object.
 * Input was generated by ent.
 */
export type AugmentedUpdateBalanceSheetCategoryInput = {
  id: Scalars['ID']['input'];
  update: UpdateBalanceSheetCategoryInput;
  updateAssetClasses?: InputMaybe<Array<AugmentedUpdateAssetClassInput>>;
  withAssetClasses?: InputMaybe<Array<AugmentedCreateAssetClassInput>>;
};

/**
 * AugmentedUpdateBalanceSheetViewConfigurationInput is used for update BalanceSheetViewConfiguration object.
 * Input was generated by ent.
 */
export type AugmentedUpdateBalanceSheetViewConfigurationInput = {
  id: Scalars['ID']['input'];
  update: UpdateBalanceSheetViewConfigurationInput;
};

/**
 * AugmentedUpdateBeneficiaryAccessParameterInput is used for update BeneficiaryAccessParameter object.
 * Input was generated by ent.
 */
export type AugmentedUpdateBeneficiaryAccessParameterInput = {
  id: Scalars['ID']['input'];
  update: UpdateBeneficiaryAccessParameterInput;
};

/**
 * AugmentedUpdateBeneficiaryDistributionInput is used for update BeneficiaryDistribution object.
 * Input was generated by ent.
 */
export type AugmentedUpdateBeneficiaryDistributionInput = {
  id: Scalars['ID']['input'];
  update: UpdateBeneficiaryDistributionInput;
  updateDistributionParameters?: InputMaybe<Array<AugmentedUpdateBeneficiaryDistributionParameterInput>>;
  withDistributionParameters?: InputMaybe<Array<AugmentedCreateBeneficiaryDistributionParameterInput>>;
};

/**
 * AugmentedUpdateBeneficiaryDistributionParameterInput is used for update BeneficiaryDistributionParameter object.
 * Input was generated by ent.
 */
export type AugmentedUpdateBeneficiaryDistributionParameterInput = {
  id: Scalars['ID']['input'];
  update: UpdateBeneficiaryDistributionParameterInput;
  updateDistribution?: InputMaybe<AugmentedUpdateBeneficiaryDistributionInput>;
};

/**
 * AugmentedUpdateBeneficiaryInput is used for update Beneficiary object.
 * Input was generated by ent.
 */
export type AugmentedUpdateBeneficiaryInput = {
  id: Scalars['ID']['input'];
  update: UpdateBeneficiaryInput;
  updateAccessParameters?: InputMaybe<Array<AugmentedUpdateAccessParameterInput>>;
  updateDistributions?: InputMaybe<Array<AugmentedUpdateBeneficiaryDistributionInput>>;
  updateEntity?: InputMaybe<AugmentedUpdateEntityInput>;
  updateIndividual?: InputMaybe<AugmentedUpdateClientProfileInput>;
  updateOrganization?: InputMaybe<AugmentedUpdateClientOrganizationInput>;
  updatePowerOfAppointment?: InputMaybe<AugmentedUpdateBeneficiaryPowerOfAppointmentInput>;
  updateScheduledDistributions?: InputMaybe<Array<AugmentedUpdateScheduledDistributionInput>>;
  withAccessParameters?: InputMaybe<Array<AugmentedCreateAccessParameterInput>>;
  withDistributions?: InputMaybe<Array<AugmentedCreateBeneficiaryDistributionInput>>;
  withPowerOfAppointment?: InputMaybe<AugmentedCreateBeneficiaryPowerOfAppointmentInput>;
  withScheduledDistributions?: InputMaybe<Array<AugmentedCreateScheduledDistributionInput>>;
};

/**
 * AugmentedUpdateBeneficiaryPowerOfAppointmentInput is used for update BeneficiaryPowerOfAppointment object.
 * Input was generated by ent.
 */
export type AugmentedUpdateBeneficiaryPowerOfAppointmentInput = {
  id: Scalars['ID']['input'];
  update: UpdateBeneficiaryPowerOfAppointmentInput;
};

/**
 * AugmentedUpdateCCorpBusinessEntityInput is used for update CCorpBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedUpdateCCorpBusinessEntityInput = {
  id: Scalars['ID']['input'];
  update: UpdateCCorpBusinessEntityInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateKeyPeople?: InputMaybe<Array<AugmentedUpdateKeyPersonInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedUpdateCLTProposalInput is used for update CLTProposal object.
 * Input was generated by ent.
 */
export type AugmentedUpdateCltProposalInput = {
  id: Scalars['ID']['input'];
  update: UpdateCltProposalInput;
  updateCharitableIncomeBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateProposal?: InputMaybe<AugmentedUpdateProposalInput>;
  updateRemainderBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  withCharitableIncomeBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withRemainderBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
};

/**
 * AugmentedUpdateCLTTrustInput is used for update CLTTrust object.
 * Input was generated by ent.
 */
export type AugmentedUpdateCltTrustInput = {
  id: Scalars['ID']['input'];
  update: UpdateCltTrustInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateGrantors?: InputMaybe<Array<AugmentedUpdatePrincipalInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateLifetimeBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  updateRemainderBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateSuccessorTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  updateTrustAdvisors?: InputMaybe<Array<AugmentedUpdateTrustAdvisorInput>>;
  updateTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withLifetimeBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withRemainderBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedUpdateCRTProposalInput is used for update CRTProposal object.
 * Input was generated by ent.
 */
export type AugmentedUpdateCrtProposalInput = {
  id: Scalars['ID']['input'];
  update: UpdateCrtProposalInput;
  updateCharitableRemainderBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateIncomeBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateProposal?: InputMaybe<AugmentedUpdateProposalInput>;
  withCharitableRemainderBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withIncomeBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
};

/**
 * AugmentedUpdateCRTTrustInput is used for update CRTTrust object.
 * Input was generated by ent.
 */
export type AugmentedUpdateCrtTrustInput = {
  id: Scalars['ID']['input'];
  update: UpdateCrtTrustInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateGrantors?: InputMaybe<Array<AugmentedUpdatePrincipalInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateLifetimeBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  updateRemainderBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateSuccessorTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  updateTrustAdvisors?: InputMaybe<Array<AugmentedUpdateTrustAdvisorInput>>;
  updateTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withLifetimeBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withRemainderBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedUpdateCashflowInput is used for update Cashflow object.
 * Input was generated by ent.
 */
export type AugmentedUpdateCashflowInput = {
  id: Scalars['ID']['input'];
  update: UpdateCashflowInput;
};

/**
 * AugmentedUpdateClientOrganizationInput is used for update ClientOrganization object.
 * Input was generated by ent.
 */
export type AugmentedUpdateClientOrganizationInput = {
  id: Scalars['ID']['input'];
  update: UpdateClientOrganizationInput;
  updateAddress?: InputMaybe<AugmentedUpdateAddressInput>;
  withAddress?: InputMaybe<AugmentedCreateAddressInput>;
};

/**
 * AugmentedUpdateClientPresentationInput is used for update ClientPresentation object.
 * Input was generated by ent.
 */
export type AugmentedUpdateClientPresentationInput = {
  id: Scalars['ID']['input'];
  update: UpdateClientPresentationInput;
};

/**
 * AugmentedUpdateClientProfileInput is used for update ClientProfile object.
 * Input was generated by ent.
 */
export type AugmentedUpdateClientProfileInput = {
  id: Scalars['ID']['input'];
  update: UpdateClientProfileInput;
  updateAddress?: InputMaybe<AugmentedUpdateAddressInput>;
  updateCashflows?: InputMaybe<Array<AugmentedUpdateCashflowInput>>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateOwnedOwnershipStakes?: InputMaybe<Array<AugmentedUpdateOwnershipStakeInput>>;
  updateProfessionalTeamRoles?: InputMaybe<Array<AugmentedUpdateProfessionalTeamRoleInput>>;
  updateTestamentaryEntities?: InputMaybe<Array<AugmentedUpdateTestamentaryEntityInput>>;
  withAddress?: InputMaybe<AugmentedCreateAddressInput>;
  withCashflows?: InputMaybe<Array<AugmentedCreateCashflowInput>>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwnedOwnershipStakes?: InputMaybe<Array<AugmentedCreateOwnershipStakeInput>>;
  withProfessionalTeamRoles?: InputMaybe<Array<AugmentedCreateProfessionalTeamRoleInput>>;
  withTestamentaryEntities?: InputMaybe<Array<AugmentedCreateTestamentaryEntityInput>>;
};

/**
 * AugmentedUpdateClientProfileRelationshipInput is used for update ClientProfileRelationship object.
 * Input was generated by ent.
 */
export type AugmentedUpdateClientProfileRelationshipInput = {
  id: Scalars['ID']['input'];
  update: UpdateClientProfileRelationshipInput;
};

/**
 * AugmentedUpdateCollaboratorInput is used for update Collaborator object.
 * Input was generated by ent.
 */
export type AugmentedUpdateCollaboratorInput = {
  id: Scalars['ID']['input'];
  update: UpdateCollaboratorInput;
};

/**
 * AugmentedUpdateControllingPartyInput is used for update ControllingParty object.
 * Input was generated by ent.
 */
export type AugmentedUpdateControllingPartyInput = {
  id: Scalars['ID']['input'];
  update: UpdateControllingPartyInput;
};

/**
 * AugmentedUpdateCustodialPersonalAccountInput is used for update CustodialPersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedUpdateCustodialPersonalAccountInput = {
  id: Scalars['ID']['input'];
  update: UpdateCustodialPersonalAccountInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateControllingParties?: InputMaybe<Array<AugmentedUpdateControllingPartyInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateOwner?: InputMaybe<AugmentedUpdatePrincipalInput>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwner?: InputMaybe<AugmentedCreatePrincipalInput>;
};

/**
 * AugmentedUpdateDispositionScenarioInput is used for update DispositionScenario object.
 * Input was generated by ent.
 */
export type AugmentedUpdateDispositionScenarioInput = {
  id: Scalars['ID']['input'];
  update: UpdateDispositionScenarioInput;
  updateDispositiveProvisions?: InputMaybe<Array<AugmentedUpdateDispositiveProvisionInput>>;
  updateDispositiveProvisionsTemplate?: InputMaybe<AugmentedUpdateDispositiveProvisionTemplateInput>;
  updateSecondDeathDispositiveProvisions?: InputMaybe<Array<AugmentedUpdateDispositiveProvisionInput>>;
  updateSecondDeathDispositiveProvisionsTemplate?: InputMaybe<AugmentedUpdateDispositiveProvisionTemplateInput>;
  withDispositiveProvisions?: InputMaybe<Array<AugmentedCreateDispositiveProvisionInput>>;
  withDispositiveProvisionsTemplate?: InputMaybe<AugmentedCreateDispositiveProvisionTemplateInput>;
  withSecondDeathDispositiveProvisions?: InputMaybe<Array<AugmentedCreateDispositiveProvisionInput>>;
  withSecondDeathDispositiveProvisionsTemplate?: InputMaybe<AugmentedCreateDispositiveProvisionTemplateInput>;
};

/**
 * AugmentedUpdateDispositiveProvisionInput is used for update DispositiveProvision object.
 * Input was generated by ent.
 */
export type AugmentedUpdateDispositiveProvisionInput = {
  id: Scalars['ID']['input'];
  update: UpdateDispositiveProvisionInput;
  updateEntity?: InputMaybe<AugmentedUpdateEntityInput>;
  updateIndividual?: InputMaybe<AugmentedUpdateClientProfileInput>;
  updateOrganization?: InputMaybe<AugmentedUpdateClientOrganizationInput>;
  updateTestamentaryEntity?: InputMaybe<AugmentedUpdateTestamentaryEntityInput>;
};

/**
 * AugmentedUpdateDispositiveProvisionTemplateInput is used for update DispositiveProvisionTemplate object.
 * Input was generated by ent.
 */
export type AugmentedUpdateDispositiveProvisionTemplateInput = {
  id: Scalars['ID']['input'];
  update: UpdateDispositiveProvisionTemplateInput;
  updateDispositiveProvisions?: InputMaybe<Array<AugmentedUpdateDispositiveProvisionInput>>;
  withDispositiveProvisions?: InputMaybe<Array<AugmentedCreateDispositiveProvisionInput>>;
};

/**
 * AugmentedUpdateDocumentInput is used for update Document object.
 * Input was generated by ent.
 */
export type AugmentedUpdateDocumentInput = {
  id: Scalars['ID']['input'];
  update: UpdateDocumentInput;
};

/**
 * AugmentedUpdateDocumentSummaryInput is used for update DocumentSummary object.
 * Input was generated by ent.
 */
export type AugmentedUpdateDocumentSummaryInput = {
  id: Scalars['ID']['input'];
  update: UpdateDocumentSummaryInput;
};

/**
 * AugmentedUpdateDonorAdvisedFundInput is used for update DonorAdvisedFund object.
 * Input was generated by ent.
 */
export type AugmentedUpdateDonorAdvisedFundInput = {
  id: Scalars['ID']['input'];
  update: UpdateDonorAdvisedFundInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateControllingParties?: InputMaybe<Array<AugmentedUpdateControllingPartyInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateDonors?: InputMaybe<Array<AugmentedUpdatePrincipalInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withDonors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedUpdateEmployeeInput is used for update Employee object.
 * Input was generated by ent.
 */
export type AugmentedUpdateEmployeeInput = {
  id: Scalars['ID']['input'];
  update: UpdateEmployeeInput;
};

/**
 * AugmentedUpdateEntityGraphViewInput is used for update EntityGraphView object.
 * Input was generated by ent.
 */
export type AugmentedUpdateEntityGraphViewInput = {
  id: Scalars['ID']['input'];
  update: UpdateEntityGraphViewInput;
};

/**
 * AugmentedUpdateEntityInput is used for update Entity object.
 * Input was generated by ent.
 */
export type AugmentedUpdateEntityInput = {
  id: Scalars['ID']['input'];
  update: UpdateEntityInput;
  updateCashflows?: InputMaybe<Array<AugmentedUpdateCashflowInput>>;
  updateCcorpBusinessEntity?: InputMaybe<AugmentedUpdateCCorpBusinessEntityInput>;
  updateCltTrust?: InputMaybe<AugmentedUpdateCltTrustInput>;
  updateCrtTrust?: InputMaybe<AugmentedUpdateCrtTrustInput>;
  updateCustodialPersonalAccount?: InputMaybe<AugmentedUpdateCustodialPersonalAccountInput>;
  updateDonorAdvisedFund?: InputMaybe<AugmentedUpdateDonorAdvisedFundInput>;
  updateGpBusinessEntity?: InputMaybe<AugmentedUpdateGpBusinessEntityInput>;
  updateGratTrust?: InputMaybe<AugmentedUpdateGratTrustInput>;
  updateIlitTrust?: InputMaybe<AugmentedUpdateIlitTrustInput>;
  updateIndividualPersonalAccount?: InputMaybe<AugmentedUpdateIndividualPersonalAccountInput>;
  updateInsurancePersonalAccount?: InputMaybe<AugmentedUpdateInsurancePersonalAccountInput>;
  updateIrrevocableTrust?: InputMaybe<AugmentedUpdateIrrevocableTrustInput>;
  updateJointPersonalAccount?: InputMaybe<AugmentedUpdateJointPersonalAccountInput>;
  updateLiabilitiesOwed?: InputMaybe<Array<AugmentedUpdateLiabilityInput>>;
  updateLlcBusinessEntity?: InputMaybe<AugmentedUpdateLlcBusinessEntityInput>;
  updateLpBusinessEntity?: InputMaybe<AugmentedUpdateLpBusinessEntityInput>;
  updateOwnedByStakes?: InputMaybe<Array<AugmentedUpdateOwnershipStakeInput>>;
  updateOwnedOwnershipStakes?: InputMaybe<Array<AugmentedUpdateOwnershipStakeInput>>;
  updatePrivateFoundation?: InputMaybe<AugmentedUpdatePrivateFoundationInput>;
  updateQprtTrust?: InputMaybe<AugmentedUpdateQprtTrustInput>;
  updateQualifiedTuitionPersonalAccount?: InputMaybe<AugmentedUpdateQualifiedTuitionPersonalAccountInput>;
  updateRetirementPersonalAccount?: InputMaybe<AugmentedUpdateRetirementPersonalAccountInput>;
  updateRevocableTrust?: InputMaybe<AugmentedUpdateRevocableTrustInput>;
  updateScorpBusinessEntity?: InputMaybe<AugmentedUpdateSCorpBusinessEntityInput>;
  updateSlatTrust?: InputMaybe<AugmentedUpdateSlatTrustInput>;
  updateSoleProprietorshipBusinessEntity?: InputMaybe<AugmentedUpdateSoleProprietorshipBusinessEntityInput>;
  updateStateTaxes?: InputMaybe<Array<AugmentedUpdateEntityStateTaxInput>>;
  withCashflows?: InputMaybe<Array<AugmentedCreateCashflowInput>>;
  withIrrevocableTrust?: InputMaybe<AugmentedCreateIrrevocableTrustInput>;
  withLiabilitiesOwed?: InputMaybe<Array<AugmentedCreateLiabilityInput>>;
  withOwnedByStakes?: InputMaybe<Array<AugmentedCreateOwnershipStakeInput>>;
  withOwnedOwnershipStakes?: InputMaybe<Array<AugmentedCreateOwnershipStakeInput>>;
  withRevocableTrust?: InputMaybe<AugmentedCreateRevocableTrustInput>;
  withStateTaxes?: InputMaybe<Array<AugmentedCreateEntityStateTaxInput>>;
};

/**
 * AugmentedUpdateEntityProposalInput is used for update EntityProposal object.
 * Input was generated by ent.
 */
export type AugmentedUpdateEntityProposalInput = {
  id: Scalars['ID']['input'];
  update: UpdateEntityProposalInput;
  updateProposal?: InputMaybe<AugmentedUpdateProposalInput>;
  updateProposalScenarios?: InputMaybe<Array<AugmentedUpdateProposalScenarioInput>>;
  withProposalScenarios?: InputMaybe<Array<AugmentedCreateProposalScenarioInput>>;
};

/**
 * AugmentedUpdateEntityStateTaxInput is used for update EntityStateTax object.
 * Input was generated by ent.
 */
export type AugmentedUpdateEntityStateTaxInput = {
  id: Scalars['ID']['input'];
  update: UpdateEntityStateTaxInput;
};

/**
 * AugmentedUpdateEntityTaskInput is used for update EntityTask object.
 * Input was generated by ent.
 */
export type AugmentedUpdateEntityTaskInput = {
  id: Scalars['ID']['input'];
  update: UpdateEntityTaskInput;
};

/**
 * AugmentedUpdateEstateTaxScenarioInput is used for update EstateTaxScenario object.
 * Input was generated by ent.
 */
export type AugmentedUpdateEstateTaxScenarioInput = {
  id: Scalars['ID']['input'];
  update: UpdateEstateTaxScenarioInput;
};

/**
 * AugmentedUpdateEstateWaterfallHypotheticalTransferInput is used for update EstateWaterfallHypotheticalTransfer object.
 * Input was generated by ent.
 */
export type AugmentedUpdateEstateWaterfallHypotheticalTransferInput = {
  id: Scalars['ID']['input'];
  update: UpdateEstateWaterfallHypotheticalTransferInput;
  updateDestinationEntity?: InputMaybe<AugmentedUpdateEntityInput>;
  withDestinationEntity?: InputMaybe<AugmentedCreateEntityInput>;
};

/**
 * AugmentedUpdateEstateWaterfallInput is used for update EstateWaterfall object.
 * Input was generated by ent.
 */
export type AugmentedUpdateEstateWaterfallInput = {
  id: Scalars['ID']['input'];
  update: UpdateEstateWaterfallInput;
  updateHypotheticalTransfers?: InputMaybe<Array<AugmentedUpdateEstateWaterfallHypotheticalTransferInput>>;
  updateVisualizationConfig?: InputMaybe<AugmentedUpdateEstateWaterfallVisualizationConfigInput>;
  updateVisualizationGroups?: InputMaybe<Array<AugmentedUpdateGraphVisualizationGroupInput>>;
  withHypotheticalTransfers?: InputMaybe<Array<AugmentedCreateEstateWaterfallHypotheticalTransferInput>>;
  withVisualizationConfig?: InputMaybe<AugmentedCreateEstateWaterfallVisualizationConfigInput>;
  withVisualizationGroups?: InputMaybe<Array<AugmentedCreateGraphVisualizationGroupInput>>;
};

/**
 * AugmentedUpdateEstateWaterfallVisualizationConfigInput is used for update EstateWaterfallVisualizationConfig object.
 * Input was generated by ent.
 */
export type AugmentedUpdateEstateWaterfallVisualizationConfigInput = {
  id: Scalars['ID']['input'];
  update: UpdateEstateWaterfallVisualizationConfigInput;
};

/**
 * AugmentedUpdateEstateWaterfallVisualizationFilterInput is used for update EstateWaterfallVisualizationFilter object.
 * Input was generated by ent.
 */
export type AugmentedUpdateEstateWaterfallVisualizationFilterInput = {
  id: Scalars['ID']['input'];
  update: UpdateEstateWaterfallVisualizationFilterInput;
};

/**
 * AugmentedUpdateFeatureFlagInput is used for update FeatureFlag object.
 * Input was generated by ent.
 */
export type AugmentedUpdateFeatureFlagInput = {
  id: Scalars['ID']['input'];
  update: UpdateFeatureFlagInput;
};

/**
 * AugmentedUpdateFeatureFlagQueryRuleInput is used for update FeatureFlagQueryRule object.
 * Input was generated by ent.
 */
export type AugmentedUpdateFeatureFlagQueryRuleInput = {
  id: Scalars['ID']['input'];
  update: UpdateFeatureFlagQueryRuleInput;
};

/**
 * AugmentedUpdateGPBusinessEntityInput is used for update GPBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedUpdateGpBusinessEntityInput = {
  id: Scalars['ID']['input'];
  update: UpdateGpBusinessEntityInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateKeyPeople?: InputMaybe<Array<AugmentedUpdateKeyPersonInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedUpdateGRATAnnuityV2Input is used for update GRATAnnuityV2 object.
 * Input was generated by ent.
 */
export type AugmentedUpdateGratAnnuityV2Input = {
  id: Scalars['ID']['input'];
  update: UpdateGratAnnuityV2Input;
  updateGratTrust?: InputMaybe<AugmentedUpdateGratTrustInput>;
};

/**
 * AugmentedUpdateGRATProposalScenarioInput is used for update GRATProposalScenario object.
 * Input was generated by ent.
 */
export type AugmentedUpdateGratProposalScenarioInput = {
  id: Scalars['ID']['input'];
  update: UpdateGratProposalScenarioInput;
};

/**
 * AugmentedUpdateGRATTrustInput is used for update GRATTrust object.
 * Input was generated by ent.
 */
export type AugmentedUpdateGratTrustInput = {
  id: Scalars['ID']['input'];
  setAnnuityToPaid?: InputMaybe<AugmentedSetGratTrustAnnuityV2PaidInput>;
  update: UpdateGratTrustInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateAnnuities?: InputMaybe<Array<AugmentedUpdateGratAnnuityV2Input>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateDistributionAssetValuation?: InputMaybe<AugmentedUpdateAssetValuationV2Input>;
  updateGrantor?: InputMaybe<AugmentedUpdatePrincipalInput>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateSuccessorTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  updateTrustAdvisors?: InputMaybe<Array<AugmentedUpdateTrustAdvisorInput>>;
  updateTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withAnnuities?: InputMaybe<Array<AugmentedCreateGratAnnuityV2Input>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withDistributionAssetValuation?: InputMaybe<AugmentedCreateAssetValuationV2Input>;
  withGrantor?: InputMaybe<AugmentedCreatePrincipalInput>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedUpdateGiftingProposalCashFlowInput is used for update GiftingProposalCashFlow object.
 * Input was generated by ent.
 */
export type AugmentedUpdateGiftingProposalCashFlowInput = {
  id: Scalars['ID']['input'];
  update: UpdateGiftingProposalCashFlowInput;
};

/**
 * AugmentedUpdateGiftingProposalGiftInput is used for update GiftingProposalGift object.
 * Input was generated by ent.
 */
export type AugmentedUpdateGiftingProposalGiftInput = {
  id: Scalars['ID']['input'];
  update: UpdateGiftingProposalGiftInput;
  updateRecipientClientOrganization?: InputMaybe<AugmentedUpdateClientOrganizationInput>;
  updateRecipientClientProfile?: InputMaybe<AugmentedUpdateClientProfileInput>;
  updateRecipientPortfolio?: InputMaybe<AugmentedUpdateGiftingProposalPortfolioInput>;
  updateSenders?: InputMaybe<Array<AugmentedUpdateGiftingProposalGiftSenderInput>>;
  withRecipientClientOrganization?: InputMaybe<AugmentedCreateClientOrganizationInput>;
  withRecipientClientProfile?: InputMaybe<AugmentedCreateClientProfileInput>;
  withRecipientPortfolio?: InputMaybe<AugmentedCreateGiftingProposalPortfolioInput>;
  withSenders?: InputMaybe<Array<AugmentedCreateGiftingProposalGiftSenderInput>>;
};

/**
 * AugmentedUpdateGiftingProposalGiftSenderInput is used for update GiftingProposalGiftSender object.
 * Input was generated by ent.
 */
export type AugmentedUpdateGiftingProposalGiftSenderInput = {
  id: Scalars['ID']['input'];
  update: UpdateGiftingProposalGiftSenderInput;
};

/**
 * AugmentedUpdateGiftingProposalInput is used for update GiftingProposal object.
 * Input was generated by ent.
 */
export type AugmentedUpdateGiftingProposalInput = {
  id: Scalars['ID']['input'];
  update: UpdateGiftingProposalInput;
  updateBaseCashFlows?: InputMaybe<Array<AugmentedUpdateGiftingProposalCashFlowInput>>;
  updateDefaultGiftingScenario?: InputMaybe<AugmentedUpdateGiftingProposalScenarioInput>;
  updateGiftingScenarios?: InputMaybe<Array<AugmentedUpdateGiftingProposalScenarioInput>>;
  updateOutOfEstatePortfolios?: InputMaybe<Array<AugmentedUpdateGiftingProposalPortfolioInput>>;
  updateProposal?: InputMaybe<AugmentedUpdateProposalInput>;
  withBaseCashFlows?: InputMaybe<Array<AugmentedCreateGiftingProposalCashFlowInput>>;
  withDefaultGiftingScenario?: InputMaybe<AugmentedCreateGiftingProposalScenarioInput>;
  withGiftingScenarios?: InputMaybe<Array<AugmentedCreateGiftingProposalScenarioInput>>;
  withOutOfEstatePortfolios?: InputMaybe<Array<AugmentedCreateGiftingProposalPortfolioInput>>;
};

/**
 * AugmentedUpdateGiftingProposalPortfolioInput is used for update GiftingProposalPortfolio object.
 * Input was generated by ent.
 */
export type AugmentedUpdateGiftingProposalPortfolioInput = {
  id: Scalars['ID']['input'];
  update: UpdateGiftingProposalPortfolioInput;
};

/**
 * AugmentedUpdateGiftingProposalScenarioInput is used for update GiftingProposalScenario object.
 * Input was generated by ent.
 */
export type AugmentedUpdateGiftingProposalScenarioInput = {
  id: Scalars['ID']['input'];
  update: UpdateGiftingProposalScenarioInput;
  updateScenarioCashFlows?: InputMaybe<Array<AugmentedUpdateGiftingProposalCashFlowInput>>;
  updateScenarioGifts?: InputMaybe<Array<AugmentedUpdateGiftingProposalGiftInput>>;
  withScenarioCashFlows?: InputMaybe<Array<AugmentedCreateGiftingProposalCashFlowInput>>;
  withScenarioGifts?: InputMaybe<Array<AugmentedCreateGiftingProposalGiftInput>>;
};

/**
 * AugmentedUpdateGraphVisualizationGroupInput is used for update GraphVisualizationGroup object.
 * Input was generated by ent.
 */
export type AugmentedUpdateGraphVisualizationGroupInput = {
  id: Scalars['ID']['input'];
  update: UpdateGraphVisualizationGroupInput;
};

/**
 * AugmentedUpdateHouseholdInput is used for update Household object.
 * Input was generated by ent.
 */
export type AugmentedUpdateHouseholdInput = {
  id: Scalars['ID']['input'];
  update: UpdateHouseholdInput;
  updateClientProfiles?: InputMaybe<Array<AugmentedUpdateClientProfileInput>>;
  updateEstateTaxScenarios?: InputMaybe<Array<AugmentedUpdateEstateTaxScenarioInput>>;
  withClientProfiles?: InputMaybe<Array<AugmentedCreateClientProfileInput>>;
  withEstateTaxScenarios?: InputMaybe<Array<AugmentedCreateEstateTaxScenarioInput>>;
};

/**
 * AugmentedUpdateHouseholdOnboardingInput is used for update HouseholdOnboarding object.
 * Input was generated by ent.
 */
export type AugmentedUpdateHouseholdOnboardingInput = {
  id: Scalars['ID']['input'];
  update: UpdateHouseholdOnboardingInput;
  updateSteps?: InputMaybe<Array<AugmentedUpdateHouseholdOnboardingStepInput>>;
  withSteps?: InputMaybe<Array<AugmentedCreateHouseholdOnboardingStepInput>>;
};

/**
 * AugmentedUpdateHouseholdOnboardingStepInput is used for update HouseholdOnboardingStep object.
 * Input was generated by ent.
 */
export type AugmentedUpdateHouseholdOnboardingStepInput = {
  id: Scalars['ID']['input'];
  update: UpdateHouseholdOnboardingStepInput;
};

/**
 * AugmentedUpdateHypotheticalWaterfallDispositiveProvisionTemplateInput is used for update HypotheticalWaterfallDispositiveProvisionTemplate object.
 * Input was generated by ent.
 */
export type AugmentedUpdateHypotheticalWaterfallDispositiveProvisionTemplateInput = {
  id: Scalars['ID']['input'];
  update: UpdateHypotheticalWaterfallDispositiveProvisionTemplateInput;
};

/**
 * AugmentedUpdateILITCrummeyLetterInput is used for update ILITCrummeyLetter object.
 * Input was generated by ent.
 */
export type AugmentedUpdateIlitCrummeyLetterInput = {
  id: Scalars['ID']['input'];
  update: UpdateIlitCrummeyLetterInput;
};

/**
 * AugmentedUpdateILITTrustInput is used for update ILITTrust object.
 * Input was generated by ent.
 */
export type AugmentedUpdateIlitTrustInput = {
  id: Scalars['ID']['input'];
  update: UpdateIlitTrustInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateCrummeyLetters?: InputMaybe<Array<AugmentedUpdateIlitCrummeyLetterInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateGrantors?: InputMaybe<Array<AugmentedUpdatePrincipalInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  updateSuccessorTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  updateTrustAdvisors?: InputMaybe<Array<AugmentedUpdateTrustAdvisorInput>>;
  updateTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withCrummeyLetters?: InputMaybe<Array<AugmentedCreateIlitCrummeyLetterInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedUpdateIndividualPersonalAccountInput is used for update IndividualPersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedUpdateIndividualPersonalAccountInput = {
  id: Scalars['ID']['input'];
  update: UpdateIndividualPersonalAccountInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateControllingParties?: InputMaybe<Array<AugmentedUpdateControllingPartyInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateOwner?: InputMaybe<AugmentedUpdatePrincipalInput>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwner?: InputMaybe<AugmentedCreatePrincipalInput>;
};

/**
 * AugmentedUpdateInferredFieldEvidenceInput is used for update InferredFieldEvidence object.
 * Input was generated by ent.
 */
export type AugmentedUpdateInferredFieldEvidenceInput = {
  id: Scalars['ID']['input'];
  update: UpdateInferredFieldEvidenceInput;
};

/**
 * AugmentedUpdateInferredFieldInput is used for update InferredField object.
 * Input was generated by ent.
 */
export type AugmentedUpdateInferredFieldInput = {
  id: Scalars['ID']['input'];
  update: UpdateInferredFieldInput;
  updateEvidence?: InputMaybe<Array<AugmentedUpdateInferredFieldEvidenceInput>>;
  withEvidence?: InputMaybe<Array<AugmentedCreateInferredFieldEvidenceInput>>;
};

/**
 * AugmentedUpdateInsurancePersonalAccountInput is used for update InsurancePersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedUpdateInsurancePersonalAccountInput = {
  id: Scalars['ID']['input'];
  update: UpdateInsurancePersonalAccountInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateControllingParties?: InputMaybe<Array<AugmentedUpdateControllingPartyInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateOwners?: InputMaybe<Array<AugmentedUpdatePrincipalInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwners?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedUpdateInsurancePolicyInput is used for update InsurancePolicy object.
 * Input was generated by ent.
 */
export type AugmentedUpdateInsurancePolicyInput = {
  id: Scalars['ID']['input'];
  update: UpdateInsurancePolicyInput;
  updatePremiumPayments?: InputMaybe<Array<AugmentedUpdateInsurancePremiumPaymentInput>>;
  withPremiumPayments?: InputMaybe<Array<AugmentedCreateInsurancePremiumPaymentInput>>;
};

/**
 * AugmentedUpdateInsurancePremiumPaymentInput is used for update InsurancePremiumPayment object.
 * Input was generated by ent.
 */
export type AugmentedUpdateInsurancePremiumPaymentInput = {
  id: Scalars['ID']['input'];
  update: UpdateInsurancePremiumPaymentInput;
};

/**
 * AugmentedUpdateIntegrationAssetClassInput is used for update IntegrationAssetClass object.
 * Input was generated by ent.
 */
export type AugmentedUpdateIntegrationAssetClassInput = {
  id: Scalars['ID']['input'];
  update: UpdateIntegrationAssetClassInput;
  updateAssetClass?: InputMaybe<AugmentedUpdateAssetClassInput>;
  withAssetClass?: InputMaybe<AugmentedCreateAssetClassInput>;
};

/**
 * AugmentedUpdateIntegrationConfigurationInput is used for update IntegrationConfiguration object.
 * Input was generated by ent.
 */
export type AugmentedUpdateIntegrationConfigurationInput = {
  id: Scalars['ID']['input'];
  update: UpdateIntegrationConfigurationInput;
};

/**
 * AugmentedUpdateIrrevocableTrustInput is used for update IrrevocableTrust object.
 * Input was generated by ent.
 */
export type AugmentedUpdateIrrevocableTrustInput = {
  id: Scalars['ID']['input'];
  update: UpdateIrrevocableTrustInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateGrantors?: InputMaybe<Array<AugmentedUpdatePrincipalInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  updateSuccessorTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  updateTrustAdvisors?: InputMaybe<Array<AugmentedUpdateTrustAdvisorInput>>;
  updateTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedUpdateJointPersonalAccountInput is used for update JointPersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedUpdateJointPersonalAccountInput = {
  id: Scalars['ID']['input'];
  update: UpdateJointPersonalAccountInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateControllingParties?: InputMaybe<Array<AugmentedUpdateControllingPartyInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateOwners?: InputMaybe<Array<AugmentedUpdatePrincipalInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwners?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
};

/**
 * AugmentedUpdateKGRootInput is used for update KGRoot object.
 * Input was generated by ent.
 */
export type AugmentedUpdateKgRootInput = {
  id: Scalars['ID']['input'];
  update: UpdateKgRootInput;
};

/**
 * AugmentedUpdateKeyPersonInput is used for update KeyPerson object.
 * Input was generated by ent.
 */
export type AugmentedUpdateKeyPersonInput = {
  id: Scalars['ID']['input'];
  update: UpdateKeyPersonInput;
  updateEntity?: InputMaybe<AugmentedUpdateEntityInput>;
  updateIndividual?: InputMaybe<AugmentedUpdateClientProfileInput>;
  updateOrganization?: InputMaybe<AugmentedUpdateClientOrganizationInput>;
  updateRoles?: InputMaybe<Array<AugmentedUpdateKeyPersonRoleInput>>;
  withRoles?: InputMaybe<Array<AugmentedCreateKeyPersonRoleInput>>;
};

/**
 * AugmentedUpdateKeyPersonRoleInput is used for update KeyPersonRole object.
 * Input was generated by ent.
 */
export type AugmentedUpdateKeyPersonRoleInput = {
  id: Scalars['ID']['input'];
  update: UpdateKeyPersonRoleInput;
  updateKeyPerson?: InputMaybe<AugmentedUpdateKeyPersonInput>;
};

/**
 * AugmentedUpdateLLCBusinessEntityInput is used for update LLCBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedUpdateLlcBusinessEntityInput = {
  id: Scalars['ID']['input'];
  update: UpdateLlcBusinessEntityInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateKeyPeople?: InputMaybe<Array<AugmentedUpdateKeyPersonInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedUpdateLPBusinessEntityInput is used for update LPBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedUpdateLpBusinessEntityInput = {
  id: Scalars['ID']['input'];
  update: UpdateLpBusinessEntityInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateKeyPeople?: InputMaybe<Array<AugmentedUpdateKeyPersonInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedUpdateLiabilityInput is used for update Liability object.
 * Input was generated by ent.
 */
export type AugmentedUpdateLiabilityInput = {
  id: Scalars['ID']['input'];
  update: UpdateLiabilityInput;
};

/**
 * AugmentedUpdateLifetimeExclusionEventInput is used for update LifetimeExclusionEvent object.
 * Input was generated by ent.
 */
export type AugmentedUpdateLifetimeExclusionEventInput = {
  id: Scalars['ID']['input'];
  update: UpdateLifetimeExclusionEventInput;
  updateRecipient?: InputMaybe<AugmentedUpdateBeneficiaryInput>;
  withRecipient?: InputMaybe<AugmentedCreateBeneficiaryInput>;
};

/**
 * AugmentedUpdateLoggedTransferInput is used for update LoggedTransfer object.
 * Input was generated by ent.
 */
export type AugmentedUpdateLoggedTransferInput = {
  id: Scalars['ID']['input'];
  update: UpdateLoggedTransferInput;
  updateLifetimeExclusionEvents?: InputMaybe<Array<AugmentedUpdateLifetimeExclusionEventInput>>;
  withLifetimeExclusionEvents?: InputMaybe<Array<AugmentedCreateLifetimeExclusionEventInput>>;
};

/**
 * AugmentedUpdateOwnershipStakeInput is used for update OwnershipStake object.
 * Input was generated by ent.
 */
export type AugmentedUpdateOwnershipStakeInput = {
  id: Scalars['ID']['input'];
  update: UpdateOwnershipStakeInput;
  updateOwnedEntity?: InputMaybe<AugmentedUpdateEntityInput>;
  updateOwningEntity?: InputMaybe<AugmentedUpdateEntityInput>;
  withOwnedEntity?: InputMaybe<AugmentedCreateEntityInput>;
};

/**
 * AugmentedUpdatePrincipalInput is used for update Principal object.
 * Input was generated by ent.
 */
export type AugmentedUpdatePrincipalInput = {
  id: Scalars['ID']['input'];
  update: UpdatePrincipalInput;
};

/**
 * AugmentedUpdatePrivateFoundationInput is used for update PrivateFoundation object.
 * Input was generated by ent.
 */
export type AugmentedUpdatePrivateFoundationInput = {
  id: Scalars['ID']['input'];
  update: UpdatePrivateFoundationInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateControllingParties?: InputMaybe<Array<AugmentedUpdateControllingPartyInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateDonors?: InputMaybe<Array<AugmentedUpdatePrincipalInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withDonors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedUpdateProfessionalTeamRoleInput is used for update ProfessionalTeamRole object.
 * Input was generated by ent.
 */
export type AugmentedUpdateProfessionalTeamRoleInput = {
  id: Scalars['ID']['input'];
  update: UpdateProfessionalTeamRoleInput;
  updateIndividual?: InputMaybe<AugmentedUpdateClientProfileInput>;
};

/**
 * AugmentedUpdateProposalInput is used for update Proposal object.
 * Input was generated by ent.
 */
export type AugmentedUpdateProposalInput = {
  id: Scalars['ID']['input'];
  update: UpdateProposalInput;
  updateCltProposal?: InputMaybe<AugmentedUpdateCltProposalInput>;
  updateCrtProposal?: InputMaybe<AugmentedUpdateCrtProposalInput>;
  updateEntityProposals?: InputMaybe<Array<AugmentedUpdateEntityProposalInput>>;
  updateGiftingProposal?: InputMaybe<AugmentedUpdateGiftingProposalInput>;
  withCltProposal?: InputMaybe<AugmentedCreateCltProposalInput>;
  withCrtProposal?: InputMaybe<AugmentedCreateCrtProposalInput>;
  withEntityProposals?: InputMaybe<Array<AugmentedCreateEntityProposalInput>>;
  withGiftingProposal?: InputMaybe<AugmentedCreateGiftingProposalInput>;
};

/**
 * AugmentedUpdateProposalScenarioInput is used for update ProposalScenario object.
 * Input was generated by ent.
 */
export type AugmentedUpdateProposalScenarioInput = {
  id: Scalars['ID']['input'];
  update: UpdateProposalScenarioInput;
  updateGratProposalScenario?: InputMaybe<AugmentedUpdateGratProposalScenarioInput>;
  withGratProposalScenario?: InputMaybe<AugmentedCreateGratProposalScenarioInput>;
};

/**
 * AugmentedUpdateQPRTTrustInput is used for update QPRTTrust object.
 * Input was generated by ent.
 */
export type AugmentedUpdateQprtTrustInput = {
  id: Scalars['ID']['input'];
  update: UpdateQprtTrustInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateGrantors?: InputMaybe<Array<AugmentedUpdatePrincipalInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateSuccessorTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  updateTrustAdvisors?: InputMaybe<Array<AugmentedUpdateTrustAdvisorInput>>;
  updateTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedUpdateQualifiedTuitionPersonalAccountInput is used for update QualifiedTuitionPersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedUpdateQualifiedTuitionPersonalAccountInput = {
  id: Scalars['ID']['input'];
  update: UpdateQualifiedTuitionPersonalAccountInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateControllingParties?: InputMaybe<Array<AugmentedUpdateControllingPartyInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateOwner?: InputMaybe<AugmentedUpdatePrincipalInput>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwner?: InputMaybe<AugmentedCreatePrincipalInput>;
};

/**
 * AugmentedUpdateRetirementPersonalAccountInput is used for update RetirementPersonalAccount object.
 * Input was generated by ent.
 */
export type AugmentedUpdateRetirementPersonalAccountInput = {
  id: Scalars['ID']['input'];
  update: UpdateRetirementPersonalAccountInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateControllingParties?: InputMaybe<Array<AugmentedUpdateControllingPartyInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateOwner?: InputMaybe<AugmentedUpdatePrincipalInput>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withOwner?: InputMaybe<AugmentedCreatePrincipalInput>;
};

/**
 * AugmentedUpdateRevocableTrustInput is used for update RevocableTrust object.
 * Input was generated by ent.
 */
export type AugmentedUpdateRevocableTrustInput = {
  id: Scalars['ID']['input'];
  update: UpdateRevocableTrustInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateGrantors?: InputMaybe<Array<AugmentedUpdatePrincipalInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  updateSuccessorTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  updateTrustAdvisors?: InputMaybe<Array<AugmentedUpdateTrustAdvisorInput>>;
  updateTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantors?: InputMaybe<Array<AugmentedCreatePrincipalInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedUpdateSCorpBusinessEntityInput is used for update SCorpBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedUpdateSCorpBusinessEntityInput = {
  id: Scalars['ID']['input'];
  update: UpdateSCorpBusinessEntityInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateKeyPeople?: InputMaybe<Array<AugmentedUpdateKeyPersonInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
};

/**
 * AugmentedUpdateSLATTrustInput is used for update SLATTrust object.
 * Input was generated by ent.
 */
export type AugmentedUpdateSlatTrustInput = {
  id: Scalars['ID']['input'];
  update: UpdateSlatTrustInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateGrantor?: InputMaybe<AugmentedUpdatePrincipalInput>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateLifetimeBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updatePolicies?: InputMaybe<Array<AugmentedUpdateInsurancePolicyInput>>;
  updateRemainderBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateSuccessorTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  updateTrustAdvisors?: InputMaybe<Array<AugmentedUpdateTrustAdvisorInput>>;
  updateTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withGrantor?: InputMaybe<AugmentedCreatePrincipalInput>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withLifetimeBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withPolicies?: InputMaybe<Array<AugmentedCreateInsurancePolicyInput>>;
  withRemainderBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedUpdateSSOConnectionInput is used for update SSOConnection object.
 * Input was generated by ent.
 */
export type AugmentedUpdateSsoConnectionInput = {
  id: Scalars['ID']['input'];
  update: UpdateSsoConnectionInput;
};

/**
 * AugmentedUpdateScheduledDistributionInput is used for update ScheduledDistribution object.
 * Input was generated by ent.
 */
export type AugmentedUpdateScheduledDistributionInput = {
  id: Scalars['ID']['input'];
  update: UpdateScheduledDistributionInput;
};

/**
 * AugmentedUpdateSoleProprietorshipBusinessEntityInput is used for update SoleProprietorshipBusinessEntity object.
 * Input was generated by ent.
 */
export type AugmentedUpdateSoleProprietorshipBusinessEntityInput = {
  id: Scalars['ID']['input'];
  update: UpdateSoleProprietorshipBusinessEntityInput;
  updateAccounts?: InputMaybe<Array<AugmentedUpdateAccountInput>>;
  updateDesignerAccount?: InputMaybe<AugmentedUpdateAccountInput>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateKeyPeople?: InputMaybe<Array<AugmentedUpdateKeyPersonInput>>;
  withAccounts?: InputMaybe<Array<AugmentedCreateAccountInput>>;
  withDesignerAccount?: InputMaybe<AugmentedCreateAccountInput>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withKeyPeople?: InputMaybe<Array<AugmentedCreateKeyPersonInput>>;
};

/**
 * AugmentedUpdateSubBrandInput is used for update SubBrand object.
 * Input was generated by ent.
 */
export type AugmentedUpdateSubBrandInput = {
  id: Scalars['ID']['input'];
  update: UpdateSubBrandInput;
};

/**
 * AugmentedUpdateTeamInput is used for update Team object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTeamInput = {
  id: Scalars['ID']['input'];
  update: UpdateTeamInput;
};

/**
 * AugmentedUpdateTenantApplicationConfigurationInput is used for update TenantApplicationConfiguration object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTenantApplicationConfigurationInput = {
  id: Scalars['ID']['input'];
  update: UpdateTenantApplicationConfigurationInput;
};

/**
 * AugmentedUpdateTenantBrandingInput is used for update TenantBranding object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTenantBrandingInput = {
  id: Scalars['ID']['input'];
  update: UpdateTenantBrandingInput;
};

/**
 * AugmentedUpdateTenantDisclaimerConfigurationInput is used for update TenantDisclaimerConfiguration object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTenantDisclaimerConfigurationInput = {
  id: Scalars['ID']['input'];
  update: UpdateTenantDisclaimerConfigurationInput;
};

/**
 * AugmentedUpdateTenantInput is used for update Tenant object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTenantInput = {
  id: Scalars['ID']['input'];
  update: UpdateTenantInput;
};

/**
 * AugmentedUpdateTenantReminderEmailsConfigurationInput is used for update TenantReminderEmailsConfiguration object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTenantReminderEmailsConfigurationInput = {
  id: Scalars['ID']['input'];
  update: UpdateTenantReminderEmailsConfigurationInput;
};

/**
 * AugmentedUpdateTenantSSOConnectionInput is used for update TenantSSOConnection object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTenantSsoConnectionInput = {
  id: Scalars['ID']['input'];
  update: UpdateTenantSsoConnectionInput;
};

/**
 * AugmentedUpdateTestamentaryEntityInput is used for update TestamentaryEntity object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTestamentaryEntityInput = {
  id: Scalars['ID']['input'];
  update: UpdateTestamentaryEntityInput;
  updateBeneficiaries?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateBeneficiariesAfterBothGrantorDeaths?: InputMaybe<Array<AugmentedUpdateBeneficiaryInput>>;
  updateControllingParties?: InputMaybe<Array<AugmentedUpdateControllingPartyInput>>;
  updateDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateDispositiveProvisions?: InputMaybe<Array<AugmentedUpdateDispositiveProvisionInput>>;
  updateDispositiveProvisionsTemplate?: InputMaybe<AugmentedUpdateDispositiveProvisionTemplateInput>;
  updateHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedUpdateDispositionScenarioInput>>;
  updateHypotheticalDispositiveProvisions?: InputMaybe<Array<AugmentedUpdateDispositiveProvisionInput>>;
  updateHypotheticalDispositiveProvisionsTemplates?: InputMaybe<Array<AugmentedUpdateHypotheticalWaterfallDispositiveProvisionTemplateInput>>;
  updateStateTax?: InputMaybe<AugmentedUpdateEntityStateTaxInput>;
  updateSuccessorTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  updateTrustAdvisors?: InputMaybe<Array<AugmentedUpdateTrustAdvisorInput>>;
  updateTrustees?: InputMaybe<Array<AugmentedUpdateTrusteeInput>>;
  withBeneficiaries?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withBeneficiariesAfterBothGrantorDeaths?: InputMaybe<Array<AugmentedCreateBeneficiaryInput>>;
  withControllingParties?: InputMaybe<Array<AugmentedCreateControllingPartyInput>>;
  withDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withDispositiveProvisions?: InputMaybe<Array<AugmentedCreateDispositiveProvisionInput>>;
  withDispositiveProvisionsTemplate?: InputMaybe<AugmentedCreateDispositiveProvisionTemplateInput>;
  withHypotheticalDispositionScenarios?: InputMaybe<Array<AugmentedCreateDispositionScenarioInput>>;
  withHypotheticalDispositiveProvisions?: InputMaybe<Array<AugmentedCreateDispositiveProvisionInput>>;
  withHypotheticalDispositiveProvisionsTemplates?: InputMaybe<Array<AugmentedCreateHypotheticalWaterfallDispositiveProvisionTemplateInput>>;
  withStateTax?: InputMaybe<AugmentedCreateEntityStateTaxInput>;
  withSuccessorTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
  withTrustAdvisors?: InputMaybe<Array<AugmentedCreateTrustAdvisorInput>>;
  withTrustees?: InputMaybe<Array<AugmentedCreateTrusteeInput>>;
};

/**
 * AugmentedUpdateTrustAdvisorInput is used for update TrustAdvisor object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTrustAdvisorInput = {
  id: Scalars['ID']['input'];
  update: UpdateTrustAdvisorInput;
  updateRoles?: InputMaybe<Array<AugmentedUpdateTrustAdvisorRoleInput>>;
  withRoles?: InputMaybe<Array<AugmentedCreateTrustAdvisorRoleInput>>;
};

/**
 * AugmentedUpdateTrustAdvisorRoleInput is used for update TrustAdvisorRole object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTrustAdvisorRoleInput = {
  id: Scalars['ID']['input'];
  update: UpdateTrustAdvisorRoleInput;
  updateTrustAdvisor?: InputMaybe<AugmentedUpdateTrustAdvisorInput>;
};

/**
 * AugmentedUpdateTrusteeDutyInput is used for update TrusteeDuty object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTrusteeDutyInput = {
  id: Scalars['ID']['input'];
  update: UpdateTrusteeDutyInput;
  updateTrustee?: InputMaybe<AugmentedUpdateTrusteeInput>;
};

/**
 * AugmentedUpdateTrusteeInput is used for update Trustee object.
 * Input was generated by ent.
 */
export type AugmentedUpdateTrusteeInput = {
  id: Scalars['ID']['input'];
  update: UpdateTrusteeInput;
  updateDuties?: InputMaybe<Array<AugmentedUpdateTrusteeDutyInput>>;
  withDuties?: InputMaybe<Array<AugmentedCreateTrusteeDutyInput>>;
};

/**
 * AugmentedUpdateUserInput is used for update User object.
 * Input was generated by ent.
 */
export type AugmentedUpdateUserInput = {
  id: Scalars['ID']['input'];
  update: UpdateUserInput;
};

/**
 * AugmentedUpdateUserNotificationConfigurationInput is used for update UserNotificationConfiguration object.
 * Input was generated by ent.
 */
export type AugmentedUpdateUserNotificationConfigurationInput = {
  id: Scalars['ID']['input'];
  update: UpdateUserNotificationConfigurationInput;
};

/**
 * AuthorizationLevel is a description of the authorization level for a specific user for a specific
 * set of application functionality
 */
export enum AuthorizationLevel {
  /** EDITOR: Read, write, delete, and potentially other elevated access to a functionality set. */
  Admin = 'ADMIN',
  /** EDITOR: Read and write access to a functionality set. */
  Editor = 'EDITOR',
  /** NONE: No read or write access to a functionality set. */
  None = 'NONE'
}

export type BalanceSheet = {
  __typename?: 'BalanceSheet';
  columnGroups: Array<BalanceSheetColumnGroup>;
  columns: Array<BalanceSheetColumn>;
  rows: Array<BalanceSheetRow>;
};

export type BalanceSheetCategory = Node & {
  __typename?: 'BalanceSheetCategory';
  assetClasses: AssetClassConnection;
  createdAt: Scalars['Time']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Whether this is a system category that cannot be deleted */
  isSystemCategory: Scalars['Boolean']['output'];
  /** The order in which this balance sheet category should be displayed in the UI */
  sortOrder: Scalars['Int']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  type: BalanceSheetCategoryType;
  updatedAt: Scalars['Time']['output'];
};


export type BalanceSheetCategoryAssetClassesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AssetClassOrder>>;
  where?: InputMaybe<AssetClassWhereInput>;
};

/** A connection to a list of items. */
export type BalanceSheetCategoryConnection = {
  __typename?: 'BalanceSheetCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BalanceSheetCategoryEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type BalanceSheetCategoryEdge = {
  __typename?: 'BalanceSheetCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<BalanceSheetCategory>;
};

/** Ordering options for BalanceSheetCategory connections */
export type BalanceSheetCategoryOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order BalanceSheetCategories. */
  field: BalanceSheetCategoryOrderField;
};

/** Properties by which BalanceSheetCategory connections can be ordered. */
export enum BalanceSheetCategoryOrderField {
  CreatedAt = 'created_at',
  SortOrder = 'sort_order',
  UpdatedAt = 'updated_at'
}

/** BalanceSheetCategoryType is enum for the field type */
export enum BalanceSheetCategoryType {
  Asset = 'ASSET',
  Insurance = 'INSURANCE',
  Liabilities = 'LIABILITIES',
  Receivables = 'RECEIVABLES',
  Unspecified = 'UNSPECIFIED'
}

/**
 * BalanceSheetCategoryWhereInput is used for filtering BalanceSheetCategory objects.
 * Input was generated by ent.
 */
export type BalanceSheetCategoryWhereInput = {
  and?: InputMaybe<Array<BalanceSheetCategoryWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** asset_classes edge predicates */
  hasAssetClasses?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetClassesWith?: InputMaybe<Array<AssetClassWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_system_category field predicates */
  isSystemCategory?: InputMaybe<Scalars['Boolean']['input']>;
  isSystemCategoryNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BalanceSheetCategoryWhereInput>;
  or?: InputMaybe<Array<BalanceSheetCategoryWhereInput>>;
  /** sort_order field predicates */
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  sortOrderGT?: InputMaybe<Scalars['Int']['input']>;
  sortOrderGTE?: InputMaybe<Scalars['Int']['input']>;
  sortOrderIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  sortOrderLT?: InputMaybe<Scalars['Int']['input']>;
  sortOrderLTE?: InputMaybe<Scalars['Int']['input']>;
  sortOrderNEQ?: InputMaybe<Scalars['Int']['input']>;
  sortOrderNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** type field predicates */
  type?: InputMaybe<BalanceSheetCategoryType>;
  typeIn?: InputMaybe<Array<BalanceSheetCategoryType>>;
  typeNEQ?: InputMaybe<BalanceSheetCategoryType>;
  typeNotIn?: InputMaybe<Array<BalanceSheetCategoryType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type BalanceSheetColumn = {
  __typename?: 'BalanceSheetColumn';
  ID: Scalars['String']['output'];
  display: Scalars['String']['output'];
  group: Scalars['String']['output'];
  variant?: Maybe<BalanceSheetColumnVariant>;
};

export type BalanceSheetColumnGroup = {
  __typename?: 'BalanceSheetColumnGroup';
  ID: Scalars['String']['output'];
  display: Scalars['String']['output'];
};

export enum BalanceSheetColumnVariant {
  Highlight = 'HIGHLIGHT'
}

export type BalanceSheetRow = {
  __typename?: 'BalanceSheetRow';
  ID: Scalars['String']['output'];
  /**
   * associatedNodes is a link to associated entities or liabilites that are related to information in the
   * balance sheet row.
   */
  associatedNodes?: Maybe<Array<Maybe<Node>>>;
  categoryType?: Maybe<BalanceSheetCategoryType>;
  children?: Maybe<Array<BalanceSheetRow>>;
  description?: Maybe<Scalars['String']['output']>;
  display: Scalars['String']['output'];
  type: BalanceSheetRowType;
  values: Array<BalanceSheetRowValue>;
};

export enum BalanceSheetRowType {
  Category = 'CATEGORY',
  LineItem = 'LINE_ITEM',
  NetWorth = 'NET_WORTH',
  Total = 'TOTAL'
}

export type BalanceSheetRowValue = {
  __typename?: 'BalanceSheetRowValue';
  columnID: Scalars['String']['output'];
  /**
   * secondaryValue is, as of now, only used to communicate the cash value total of an insurance policy
   * (death benefits are communicated through the main number)
   */
  secondaryValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** secondaryValueDescription is, as of now, only used to add additional context/label the secondaryValue */
  secondaryValueDescription?: Maybe<Scalars['String']['output']>;
  value: Scalars['CurrencyUSD']['output'];
};

export type BalanceSheetViewConfiguration = Node & {
  __typename?: 'BalanceSheetViewConfiguration';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** Whether to hide small values on the balance sheet */
  hideSmallValues: Scalars['Boolean']['output'];
  household: Household;
  id: Scalars['ID']['output'];
  /** Whether to separate directly held assets in the estate into client 1/client 2/joint */
  separateInEstateDirectlyHeld: Scalars['Boolean']['output'];
  /** Whether to show separate columns for trusts that are in the estate */
  separateInEstateTrusts: Scalars['Boolean']['output'];
  /** Whether to show separate columns for trusts that are out of the estate */
  separateOutOfEstateTrusts: Scalars['Boolean']['output'];
  /** Whether to show assets in the estate */
  showInEstate: Scalars['Boolean']['output'];
  /** Whether to show assets out of the estate */
  showOutOfEstate: Scalars['Boolean']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** Ordering options for BalanceSheetViewConfiguration connections */
export type BalanceSheetViewConfigurationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order BalanceSheetViewConfigurations. */
  field: BalanceSheetViewConfigurationOrderField;
};

/** Properties by which BalanceSheetViewConfiguration connections can be ordered. */
export enum BalanceSheetViewConfigurationOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * BalanceSheetViewConfigurationWhereInput is used for filtering BalanceSheetViewConfiguration objects.
 * Input was generated by ent.
 */
export type BalanceSheetViewConfigurationWhereInput = {
  and?: InputMaybe<Array<BalanceSheetViewConfigurationWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** hide_small_values field predicates */
  hideSmallValues?: InputMaybe<Scalars['Boolean']['input']>;
  hideSmallValuesNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<BalanceSheetViewConfigurationWhereInput>;
  or?: InputMaybe<Array<BalanceSheetViewConfigurationWhereInput>>;
  /** separate_in_estate_directly_held field predicates */
  separateInEstateDirectlyHeld?: InputMaybe<Scalars['Boolean']['input']>;
  separateInEstateDirectlyHeldNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** separate_in_estate_trusts field predicates */
  separateInEstateTrusts?: InputMaybe<Scalars['Boolean']['input']>;
  separateInEstateTrustsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** separate_out_of_estate_trusts field predicates */
  separateOutOfEstateTrusts?: InputMaybe<Scalars['Boolean']['input']>;
  separateOutOfEstateTrustsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** show_in_estate field predicates */
  showInEstate?: InputMaybe<Scalars['Boolean']['input']>;
  showInEstateNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** show_out_of_estate field predicates */
  showOutOfEstate?: InputMaybe<Scalars['Boolean']['input']>;
  showOutOfEstateNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type BasicEntityDetailsSuggestion = {
  __typename?: 'BasicEntityDetailsSuggestion';
  beneficiaries?: Maybe<Array<PrincipalSuggestion>>;
  displayName?: Maybe<Scalars['String']['output']>;
  entityKind?: Maybe<EntityKind>;
  grantorsOrDonors?: Maybe<Array<PrincipalSuggestion>>;
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  trustees?: Maybe<Array<PrincipalSuggestion>>;
  valuationAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
};

export type Beneficiary = Node & {
  __typename?: 'Beneficiary';
  accessParameters: AccessParameterConnection;
  /** Reverse edge from GRATs to denote a GRAT remainder distribution */
  beneficiaryGrat?: Maybe<GratTrust>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** An edge to the distributions for this beneficiary */
  distributions?: Maybe<Array<BeneficiaryDistribution>>;
  /** An edge to the beneficiary, which is an entity */
  entity?: Maybe<Entity>;
  id: Scalars['ID']['output'];
  /** An edge to the beneficiary, which is an individual */
  individual?: Maybe<ClientProfile>;
  level?: Maybe<BeneficiaryLevel>;
  /** Free-form notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** An edge to the beneficiary, which is an organization */
  organization?: Maybe<ClientOrganization>;
  /** An edge to the power of appointment for this beneficiary */
  powerOfAppointment?: Maybe<BeneficiaryPowerOfAppointment>;
  scheduledDistributions: ScheduledDistributionConnection;
  summary: SummaryBeneficiaryv2;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type BeneficiaryAccessParametersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AccessParameterOrder>>;
  where?: InputMaybe<AccessParameterWhereInput>;
};


export type BeneficiaryScheduledDistributionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ScheduledDistributionOrder>>;
  where?: InputMaybe<ScheduledDistributionWhereInput>;
};

export type BeneficiaryAccessParameter = Node & {
  __typename?: 'BeneficiaryAccessParameter';
  /** Notes for when age is an access parameter */
  accessParameterAgeNote?: Maybe<Scalars['String']['output']>;
  /** Notes for when other is an access parameter */
  accessParameterOtherNote?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** The kind of distribution parameter */
  kind: BeneficiaryAccessParameterKind;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** BeneficiaryAccessParameterKind is enum for the field kind */
export enum BeneficiaryAccessParameterKind {
  HealthEducationMaintenanceSupport = 'HEALTH_EDUCATION_MAINTENANCE_SUPPORT',
  MandatoryDistribution = 'MANDATORY_DISTRIBUTION',
  Other = 'OTHER',
  UnderFullDiscretionOfTrustee = 'UNDER_FULL_DISCRETION_OF_TRUSTEE',
  UponReachingAge = 'UPON_REACHING_AGE'
}

/** Ordering options for BeneficiaryAccessParameter connections */
export type BeneficiaryAccessParameterOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order BeneficiaryAccessParameters. */
  field: BeneficiaryAccessParameterOrderField;
};

/** Properties by which BeneficiaryAccessParameter connections can be ordered. */
export enum BeneficiaryAccessParameterOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * BeneficiaryAccessParameterWhereInput is used for filtering BeneficiaryAccessParameter objects.
 * Input was generated by ent.
 */
export type BeneficiaryAccessParameterWhereInput = {
  /** access_parameter_age_note field predicates */
  accessParameterAgeNote?: InputMaybe<Scalars['String']['input']>;
  accessParameterAgeNoteContains?: InputMaybe<Scalars['String']['input']>;
  accessParameterAgeNoteContainsFold?: InputMaybe<Scalars['String']['input']>;
  accessParameterAgeNoteEqualFold?: InputMaybe<Scalars['String']['input']>;
  accessParameterAgeNoteGT?: InputMaybe<Scalars['String']['input']>;
  accessParameterAgeNoteGTE?: InputMaybe<Scalars['String']['input']>;
  accessParameterAgeNoteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  accessParameterAgeNoteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  accessParameterAgeNoteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accessParameterAgeNoteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  accessParameterAgeNoteLT?: InputMaybe<Scalars['String']['input']>;
  accessParameterAgeNoteLTE?: InputMaybe<Scalars['String']['input']>;
  accessParameterAgeNoteNEQ?: InputMaybe<Scalars['String']['input']>;
  accessParameterAgeNoteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accessParameterAgeNoteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** access_parameter_other_note field predicates */
  accessParameterOtherNote?: InputMaybe<Scalars['String']['input']>;
  accessParameterOtherNoteContains?: InputMaybe<Scalars['String']['input']>;
  accessParameterOtherNoteContainsFold?: InputMaybe<Scalars['String']['input']>;
  accessParameterOtherNoteEqualFold?: InputMaybe<Scalars['String']['input']>;
  accessParameterOtherNoteGT?: InputMaybe<Scalars['String']['input']>;
  accessParameterOtherNoteGTE?: InputMaybe<Scalars['String']['input']>;
  accessParameterOtherNoteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  accessParameterOtherNoteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  accessParameterOtherNoteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accessParameterOtherNoteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  accessParameterOtherNoteLT?: InputMaybe<Scalars['String']['input']>;
  accessParameterOtherNoteLTE?: InputMaybe<Scalars['String']['input']>;
  accessParameterOtherNoteNEQ?: InputMaybe<Scalars['String']['input']>;
  accessParameterOtherNoteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  accessParameterOtherNoteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<BeneficiaryAccessParameterWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<BeneficiaryAccessParameterKind>;
  kindIn?: InputMaybe<Array<BeneficiaryAccessParameterKind>>;
  kindNEQ?: InputMaybe<BeneficiaryAccessParameterKind>;
  kindNotIn?: InputMaybe<Array<BeneficiaryAccessParameterKind>>;
  not?: InputMaybe<BeneficiaryAccessParameterWhereInput>;
  or?: InputMaybe<Array<BeneficiaryAccessParameterWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type BeneficiaryDistribution = Node & {
  __typename?: 'BeneficiaryDistribution';
  /** the beneficiary this distribution is for */
  beneficiary?: Maybe<Beneficiary>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The AMOUNT kind of distribution. Only valid when distribution_kind is AMOUNT */
  distributionAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** The frequency of the distribution to beneficiaries */
  distributionFrequency?: Maybe<BeneficiaryDistributionDistributionFrequency>;
  /** The kind of distribution for this beneficiary */
  distributionKind: BeneficiaryDistributionDistributionKind;
  /** @deprecated Use `distribution_parameters` instead. */
  distributionParameter?: Maybe<BeneficiaryDistributionDistributionParameter>;
  /** The age at which the beneficiary will receive the distribution. Only valid when distribution_parameter is UPON_REACHING_AGE */
  distributionParameterUponReachingAge?: Maybe<Scalars['Int']['output']>;
  /** Distribution parameters of this distribution */
  distributionParameters?: Maybe<Array<BeneficiaryDistributionParameter>>;
  /** The percentage of the distribution. Only valid when distribution_kind is PERCENTAGE */
  distributionPercentage?: Maybe<Scalars['Percent']['output']>;
  id: Scalars['ID']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** BeneficiaryDistributionDistributionFrequency is enum for the field distribution_frequency */
export enum BeneficiaryDistributionDistributionFrequency {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Semiannually = 'SEMIANNUALLY'
}

/** BeneficiaryDistributionDistributionKind is enum for the field distribution_kind */
export enum BeneficiaryDistributionDistributionKind {
  AllIncome = 'ALL_INCOME',
  Amount = 'AMOUNT',
  NetIncome = 'NET_INCOME',
  NetIncomeWithMakeup = 'NET_INCOME_WITH_MAKEUP',
  Other = 'OTHER',
  Percentage = 'PERCENTAGE'
}

/** BeneficiaryDistributionDistributionParameter is enum for the field distribution_parameter */
export enum BeneficiaryDistributionDistributionParameter {
  HealthEducationMaintenanceSupport = 'HEALTH_EDUCATION_MAINTENANCE_SUPPORT',
  MandatoryDistribution = 'MANDATORY_DISTRIBUTION',
  Other = 'OTHER',
  UnderFullDiscretionOfTrustee = 'UNDER_FULL_DISCRETION_OF_TRUSTEE',
  UponReachingAge = 'UPON_REACHING_AGE'
}

/** Ordering options for BeneficiaryDistribution connections */
export type BeneficiaryDistributionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order BeneficiaryDistributions. */
  field: BeneficiaryDistributionOrderField;
};

/** Properties by which BeneficiaryDistribution connections can be ordered. */
export enum BeneficiaryDistributionOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type BeneficiaryDistributionParameter = Node & {
  __typename?: 'BeneficiaryDistributionParameter';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** distribution that this distribution parameter belongs to */
  distribution: BeneficiaryDistribution;
  id: Scalars['ID']['output'];
  /** The kind of distribution parameter for this distribution */
  kind: BeneficiaryDistributionParameterKind;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** BeneficiaryDistributionParameterKind is enum for the field kind */
export enum BeneficiaryDistributionParameterKind {
  HealthEducationMaintenanceSupport = 'HEALTH_EDUCATION_MAINTENANCE_SUPPORT',
  MandatoryDistribution = 'MANDATORY_DISTRIBUTION',
  Other = 'OTHER',
  UnderFullDiscretionOfTrustee = 'UNDER_FULL_DISCRETION_OF_TRUSTEE',
  UponReachingAge = 'UPON_REACHING_AGE'
}

/** Ordering options for BeneficiaryDistributionParameter connections */
export type BeneficiaryDistributionParameterOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order BeneficiaryDistributionParameters. */
  field: BeneficiaryDistributionParameterOrderField;
};

/** Properties by which BeneficiaryDistributionParameter connections can be ordered. */
export enum BeneficiaryDistributionParameterOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * BeneficiaryDistributionParameterWhereInput is used for filtering BeneficiaryDistributionParameter objects.
 * Input was generated by ent.
 */
export type BeneficiaryDistributionParameterWhereInput = {
  and?: InputMaybe<Array<BeneficiaryDistributionParameterWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** distribution edge predicates */
  hasDistribution?: InputMaybe<Scalars['Boolean']['input']>;
  hasDistributionWith?: InputMaybe<Array<BeneficiaryDistributionWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<BeneficiaryDistributionParameterKind>;
  kindIn?: InputMaybe<Array<BeneficiaryDistributionParameterKind>>;
  kindNEQ?: InputMaybe<BeneficiaryDistributionParameterKind>;
  kindNotIn?: InputMaybe<Array<BeneficiaryDistributionParameterKind>>;
  not?: InputMaybe<BeneficiaryDistributionParameterWhereInput>;
  or?: InputMaybe<Array<BeneficiaryDistributionParameterWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * BeneficiaryDistributionWhereInput is used for filtering BeneficiaryDistribution objects.
 * Input was generated by ent.
 */
export type BeneficiaryDistributionWhereInput = {
  and?: InputMaybe<Array<BeneficiaryDistributionWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** distribution_amount field predicates */
  distributionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  distributionAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  distributionAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  distributionAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  distributionAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  distributionAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  distributionAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  distributionAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  distributionAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  distributionAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** distribution_frequency field predicates */
  distributionFrequency?: InputMaybe<BeneficiaryDistributionDistributionFrequency>;
  distributionFrequencyIn?: InputMaybe<Array<BeneficiaryDistributionDistributionFrequency>>;
  distributionFrequencyIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  distributionFrequencyNEQ?: InputMaybe<BeneficiaryDistributionDistributionFrequency>;
  distributionFrequencyNotIn?: InputMaybe<Array<BeneficiaryDistributionDistributionFrequency>>;
  distributionFrequencyNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** distribution_kind field predicates */
  distributionKind?: InputMaybe<BeneficiaryDistributionDistributionKind>;
  distributionKindIn?: InputMaybe<Array<BeneficiaryDistributionDistributionKind>>;
  distributionKindNEQ?: InputMaybe<BeneficiaryDistributionDistributionKind>;
  distributionKindNotIn?: InputMaybe<Array<BeneficiaryDistributionDistributionKind>>;
  /** distribution_parameter field predicates */
  distributionParameter?: InputMaybe<BeneficiaryDistributionDistributionParameter>;
  distributionParameterIn?: InputMaybe<Array<BeneficiaryDistributionDistributionParameter>>;
  distributionParameterIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  distributionParameterNEQ?: InputMaybe<BeneficiaryDistributionDistributionParameter>;
  distributionParameterNotIn?: InputMaybe<Array<BeneficiaryDistributionDistributionParameter>>;
  distributionParameterNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** distribution_parameter_upon_reaching_age field predicates */
  distributionParameterUponReachingAge?: InputMaybe<Scalars['Int']['input']>;
  distributionParameterUponReachingAgeGT?: InputMaybe<Scalars['Int']['input']>;
  distributionParameterUponReachingAgeGTE?: InputMaybe<Scalars['Int']['input']>;
  distributionParameterUponReachingAgeIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  distributionParameterUponReachingAgeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  distributionParameterUponReachingAgeLT?: InputMaybe<Scalars['Int']['input']>;
  distributionParameterUponReachingAgeLTE?: InputMaybe<Scalars['Int']['input']>;
  distributionParameterUponReachingAgeNEQ?: InputMaybe<Scalars['Int']['input']>;
  distributionParameterUponReachingAgeNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  distributionParameterUponReachingAgeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** distribution_percentage field predicates */
  distributionPercentage?: InputMaybe<Scalars['Percent']['input']>;
  distributionPercentageGT?: InputMaybe<Scalars['Percent']['input']>;
  distributionPercentageGTE?: InputMaybe<Scalars['Percent']['input']>;
  distributionPercentageIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  distributionPercentageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  distributionPercentageLT?: InputMaybe<Scalars['Percent']['input']>;
  distributionPercentageLTE?: InputMaybe<Scalars['Percent']['input']>;
  distributionPercentageNEQ?: InputMaybe<Scalars['Percent']['input']>;
  distributionPercentageNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  distributionPercentageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** beneficiary edge predicates */
  hasBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiaryWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** distribution_parameters edge predicates */
  hasDistributionParameters?: InputMaybe<Scalars['Boolean']['input']>;
  hasDistributionParametersWith?: InputMaybe<Array<BeneficiaryDistributionParameterWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<BeneficiaryDistributionWhereInput>;
  or?: InputMaybe<Array<BeneficiaryDistributionWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** BeneficiaryLevel is enum for the field level */
export enum BeneficiaryLevel {
  Other = 'OTHER',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY'
}

/** Ordering options for Beneficiary connections */
export type BeneficiaryOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Beneficiaries. */
  field: BeneficiaryOrderField;
};

/** Properties by which Beneficiary connections can be ordered. */
export enum BeneficiaryOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type BeneficiaryPowerOfAppointment = Node & {
  __typename?: 'BeneficiaryPowerOfAppointment';
  /** the beneficiary this power of appointment is for */
  beneficiary?: Maybe<Beneficiary>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  household: Household;
  /** the household this object belongs to */
  householdID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** The power of appointment for the beneficiary */
  power?: Maybe<BeneficiaryPowerOfAppointmentPower>;
  /** Notes for when other is an access parameter */
  powerOtherNote?: Maybe<Scalars['String']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** Ordering options for BeneficiaryPowerOfAppointment connections */
export type BeneficiaryPowerOfAppointmentOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order BeneficiaryPowerOfAppointments. */
  field: BeneficiaryPowerOfAppointmentOrderField;
};

/** Properties by which BeneficiaryPowerOfAppointment connections can be ordered. */
export enum BeneficiaryPowerOfAppointmentOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** BeneficiaryPowerOfAppointmentPower is enum for the field power */
export enum BeneficiaryPowerOfAppointmentPower {
  General = 'GENERAL',
  Other = 'OTHER',
  Special = 'SPECIAL'
}

/**
 * BeneficiaryPowerOfAppointmentWhereInput is used for filtering BeneficiaryPowerOfAppointment objects.
 * Input was generated by ent.
 */
export type BeneficiaryPowerOfAppointmentWhereInput = {
  and?: InputMaybe<Array<BeneficiaryPowerOfAppointmentWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** beneficiary edge predicates */
  hasBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiaryWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** household_id field predicates */
  householdID?: InputMaybe<Scalars['ID']['input']>;
  householdIDContains?: InputMaybe<Scalars['ID']['input']>;
  householdIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDGT?: InputMaybe<Scalars['ID']['input']>;
  householdIDGTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  householdIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDLT?: InputMaybe<Scalars['ID']['input']>;
  householdIDLTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  householdIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<BeneficiaryPowerOfAppointmentWhereInput>;
  or?: InputMaybe<Array<BeneficiaryPowerOfAppointmentWhereInput>>;
  /** power field predicates */
  power?: InputMaybe<BeneficiaryPowerOfAppointmentPower>;
  powerIn?: InputMaybe<Array<BeneficiaryPowerOfAppointmentPower>>;
  powerIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  powerNEQ?: InputMaybe<BeneficiaryPowerOfAppointmentPower>;
  powerNotIn?: InputMaybe<Array<BeneficiaryPowerOfAppointmentPower>>;
  powerNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** power_other_note field predicates */
  powerOtherNote?: InputMaybe<Scalars['String']['input']>;
  powerOtherNoteContains?: InputMaybe<Scalars['String']['input']>;
  powerOtherNoteContainsFold?: InputMaybe<Scalars['String']['input']>;
  powerOtherNoteEqualFold?: InputMaybe<Scalars['String']['input']>;
  powerOtherNoteGT?: InputMaybe<Scalars['String']['input']>;
  powerOtherNoteGTE?: InputMaybe<Scalars['String']['input']>;
  powerOtherNoteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  powerOtherNoteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  powerOtherNoteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  powerOtherNoteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  powerOtherNoteLT?: InputMaybe<Scalars['String']['input']>;
  powerOtherNoteLTE?: InputMaybe<Scalars['String']['input']>;
  powerOtherNoteNEQ?: InputMaybe<Scalars['String']['input']>;
  powerOtherNoteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  powerOtherNoteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type BeneficiarySummaryQueryv2 = {
  id: Scalars['ID']['input'];
  kind: SummaryBeneficiaryKindv2;
};

/**
 * BeneficiaryWhereInput is used for filtering Beneficiary objects.
 * Input was generated by ent.
 */
export type BeneficiaryWhereInput = {
  and?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** access_parameters edge predicates */
  hasAccessParameters?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccessParametersWith?: InputMaybe<Array<AccessParameterWhereInput>>;
  /** beneficiary_grat edge predicates */
  hasBeneficiaryGrat?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiaryGratWith?: InputMaybe<Array<GratTrustWhereInput>>;
  /** distributions edge predicates */
  hasDistributions?: InputMaybe<Scalars['Boolean']['input']>;
  hasDistributionsWith?: InputMaybe<Array<BeneficiaryDistributionWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** individual edge predicates */
  hasIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** organization edge predicates */
  hasOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** power_of_appointment edge predicates */
  hasPowerOfAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  hasPowerOfAppointmentWith?: InputMaybe<Array<BeneficiaryPowerOfAppointmentWhereInput>>;
  /** scheduled_distributions edge predicates */
  hasScheduledDistributions?: InputMaybe<Scalars['Boolean']['input']>;
  hasScheduledDistributionsWith?: InputMaybe<Array<ScheduledDistributionWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** level field predicates */
  level?: InputMaybe<BeneficiaryLevel>;
  levelIn?: InputMaybe<Array<BeneficiaryLevel>>;
  levelIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  levelNEQ?: InputMaybe<BeneficiaryLevel>;
  levelNotIn?: InputMaybe<Array<BeneficiaryLevel>>;
  levelNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BeneficiaryWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type BlackDiamondQueryResults = {
  __typename?: 'BlackDiamondQueryResults';
  results: Scalars['String']['output'];
};

export type BoundingRegion = {
  __typename?: 'BoundingRegion';
  PageNumber: Scalars['Int']['output'];
  Polygon: Array<Coordinate>;
};

export type Branded = {
  buttonsLinkColor: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  darkSquareLogoURL?: Maybe<Scalars['String']['output']>;
  darkWideLogoURL?: Maybe<Scalars['String']['output']>;
  dataVisualizationNegativeColor?: Maybe<Scalars['String']['output']>;
  dataVisualizationPrimaryColor: Scalars['String']['output'];
  dataVisualizationSecondaryColor: Scalars['String']['output'];
  dataVisualizationTertiaryColor?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logoURL: Scalars['String']['output'];
  primaryColor: Scalars['String']['output'];
  secondaryColor: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
};

/**
 * BusinessEntity is a common interface for all
 * the business entities. It enforces some common
 * properties across all business entities.
 */
export type BusinessEntity = {
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  currentValue: Scalars['CurrencyUSD']['output'];
  description?: Maybe<Scalars['String']['output']>;
  designerAccount?: Maybe<Account>;
  displayName: Scalars['String']['output'];
  doingBusinessAsName?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entityExists: Scalars['Boolean']['output'];
  finCenID?: Maybe<Scalars['String']['output']>;
  formationJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  grantorControlledPercentage: Scalars['Percent']['output'];
  grantorControlledValue: Scalars['CurrencyUSD']['output'];
  id: Scalars['ID']['output'];
  keyPeople?: Maybe<Array<KeyPerson>>;
  keyPeopleNotes?: Maybe<Scalars['String']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  nongrantorControlledPercentage: Scalars['Percent']['output'];
  nongrantorControlledValue: Scalars['CurrencyUSD']['output'];
  ownershipAsOfDate?: Maybe<Scalars['Time']['output']>;
  requiresCtaReporting?: Maybe<Scalars['Boolean']['output']>;
  taxID?: Maybe<Scalars['String']['output']>;
  taxStatus?: Maybe<BusinessEntityTaxStatus>;
};

/** BusinessEntityTaxStatus is enum for the field tax_status */
export enum BusinessEntityTaxStatus {
  Corporation = 'CORPORATION',
  DisregardedEntity = 'DISREGARDED_ENTITY',
  NonTaxableEntity = 'NON_TAXABLE_ENTITY',
  Partnership = 'PARTNERSHIP'
}

export type CCorpBusinessEntity = BusinessEntity & EntitySubtype & Named & Node & {
  __typename?: 'CCorpBusinessEntity';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this business entity */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: Maybe<Scalars['String']['output']>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: Maybe<Scalars['String']['output']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  grantorControlledPercentage: Scalars['Percent']['output'];
  grantorControlledValue: Scalars['CurrencyUSD']['output'];
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  keyPeople?: Maybe<Array<KeyPerson>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: Maybe<Scalars['String']['output']>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  nongrantorControlledPercentage: Scalars['Percent']['output'];
  nongrantorControlledValue: Scalars['CurrencyUSD']['output'];
  /** Ownership as-of date. */
  ownershipAsOfDate?: Maybe<Scalars['Time']['output']>;
  policies?: Maybe<Array<InsurancePolicy>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: Maybe<Scalars['Boolean']['output']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxID?: Maybe<Scalars['String']['output']>;
  taxStatus?: Maybe<BusinessEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type CCorpBusinessEntityHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type CCorpBusinessEntityConnection = {
  __typename?: 'CCorpBusinessEntityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CCorpBusinessEntityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CCorpBusinessEntityEdge = {
  __typename?: 'CCorpBusinessEntityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CCorpBusinessEntity>;
};

/** Ordering options for CCorpBusinessEntity connections */
export type CCorpBusinessEntityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CCorpBusinessEntities. */
  field: CCorpBusinessEntityOrderField;
};

/** Properties by which CCorpBusinessEntity connections can be ordered. */
export enum CCorpBusinessEntityOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * CCorpBusinessEntityWhereInput is used for filtering CCorpBusinessEntity objects.
 * Input was generated by ent.
 */
export type CCorpBusinessEntityWhereInput = {
  and?: InputMaybe<Array<CCorpBusinessEntityWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** doing_business_as_name field predicates */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContains?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  doingBusinessAsNameLT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameLTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNEQ?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** fin_cen_id field predicates */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  finCenIDContains?: InputMaybe<Scalars['String']['input']>;
  finCenIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDGT?: InputMaybe<Scalars['String']['input']>;
  finCenIDGTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  finCenIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  finCenIDLT?: InputMaybe<Scalars['String']['input']>;
  finCenIDLTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDNEQ?: InputMaybe<Scalars['String']['input']>;
  finCenIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** formation_jurisdiction_state_code field predicates */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  formationJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** key_people edge predicates */
  hasKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  hasKeyPeopleWith?: InputMaybe<Array<KeyPersonWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** key_people_notes field predicates */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContains?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  keyPeopleNotesLT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesLTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<CCorpBusinessEntityWhereInput>;
  or?: InputMaybe<Array<CCorpBusinessEntityWhereInput>>;
  /** ownership_as_of_date field predicates */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownershipAsOfDateLT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateLTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requires_cta_reporting field predicates */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_id field predicates */
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxIDContains?: InputMaybe<Scalars['String']['input']>;
  taxIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  taxIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  taxIDGT?: InputMaybe<Scalars['String']['input']>;
  taxIDGTE?: InputMaybe<Scalars['String']['input']>;
  taxIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  taxIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  taxIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxIDLT?: InputMaybe<Scalars['String']['input']>;
  taxIDLTE?: InputMaybe<Scalars['String']['input']>;
  taxIDNEQ?: InputMaybe<Scalars['String']['input']>;
  taxIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type CltOptimization = {
  __typename?: 'CLTOptimization';
  annuityPayoutAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  unitrustPayoutPercent?: Maybe<Scalars['Percent']['output']>;
};

export type CltOptimizationInput = {
  donorsYearOfBirth?: InputMaybe<Array<Scalars['Int']['input']>>;
  fundingAmount: Scalars['CurrencyUSD']['input'];
  optimizationTarget: CharitableTrustOptimizationTarget;
  payoutFrequency: CharitableTrustPayoutFrequency;
  payoutKind: CharitableTrustPayoutKind;
  rate7520?: InputMaybe<Scalars['Percent']['input']>;
  termKind: CharitableTrustTermKind;
  termYears: Scalars['Int']['input'];
};

export type CltProposal = Node & {
  __typename?: 'CLTProposal';
  /** The amount of the annuity payout. Only valid when payout_kind is ANNUITY */
  annuityPayoutAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** The cost basis of assets proposed to be transferred to this CRT */
  assetCostBasis: Scalars['CurrencyUSD']['output'];
  /** Notes about the assets proposed to be transferred to this CRT */
  assetNotes?: Maybe<Scalars['String']['output']>;
  /** The total value of assets proposed to be transferred to this CRT */
  assetValue: Scalars['CurrencyUSD']['output'];
  calculationMethod?: Maybe<CharitableTrustCalculationMethod>;
  charitableIncomeBeneficiaries?: Maybe<Array<Beneficiary>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  donors: Array<ClientProfile>;
  id: Scalars['ID']['output'];
  noPlanProjectionHigh: NoPlanProjection;
  noPlanProjectionLow: NoPlanProjection;
  noPlanProjectionMedium: NoPlanProjection;
  optimizationTarget?: Maybe<CharitableTrustOptimizationTarget>;
  /** The frequency of payouts for this charitable trust. */
  payoutFrequency: CharitableTrustPayoutFrequency;
  payoutKind: CharitableTrustPayoutKind;
  /** The high end of the pre-tax return percentage assumption */
  preTaxReturnPercentageHigh?: Maybe<Scalars['Percent']['output']>;
  /** The low end of the pre-tax return assumption */
  preTaxReturnPercentageLow?: Maybe<Scalars['Percent']['output']>;
  /** The medium pre-tax return percentage assumption */
  preTaxReturnPercentageMedium?: Maybe<Scalars['Percent']['output']>;
  projectionHigh: CltProposalProjection;
  projectionLow: CltProposalProjection;
  projectionMedium: CltProposalProjection;
  proposal: Proposal;
  rate7520?: Maybe<Scalars['Percent']['output']>;
  remainderBeneficiaries?: Maybe<Array<Beneficiary>>;
  /** The associated tax drag for the high pre-tax return */
  taxDragPercentageHigh?: Maybe<Scalars['Percent']['output']>;
  /** The associated tax drag for the low pre-tax return */
  taxDragPercentageLow?: Maybe<Scalars['Percent']['output']>;
  /** The associated tax drag for the medium pre-tax return */
  taxDragPercentageMedium?: Maybe<Scalars['Percent']['output']>;
  /** Tax status for this trust */
  taxStatus: TrustTaxStatus;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  termKind: CharitableTrustTermKind;
  /** The number of years the CRT will pay out for. Only valid if term_kind is FIXED */
  termYears: Scalars['Int']['output'];
  /** The kind of unitrust. Only valid when payout_kind is UNITRUST; ignored otherwise. */
  unitrustKind?: Maybe<CharitableTrustUnitrustKind>;
  /** The percent of the unitrust payout. Only valid when payout_kind is UNITRUST */
  unitrustPayoutPercent?: Maybe<Scalars['Percent']['output']>;
  updatedAt: Scalars['Time']['output'];
  user: User;
  /** This is the year of analysis. If not specified, it will either default to the actuarial tables or the term_years */
  yearOfAnalysis?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type CltProposalConnection = {
  __typename?: 'CLTProposalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CltProposalEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CltProposalEdge = {
  __typename?: 'CLTProposalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CltProposal>;
};

/** Ordering options for CLTProposal connections */
export type CltProposalOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CLTProposals. */
  field: CltProposalOrderField;
};

/** Properties by which CLTProposal connections can be ordered. */
export enum CltProposalOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type CltProposalProjection = {
  __typename?: 'CLTProposalProjection';
  capitalGainsDeferred: Scalars['CurrencyUSD']['output'];
  capitalGainsTaxDeferred: Scalars['CurrencyUSD']['output'];
  charitableBenefit: Scalars['CurrencyUSD']['output'];
  estateTaxSaved: Scalars['CurrencyUSD']['output'];
  ordinaryIncomeTaxSaved: Scalars['CurrencyUSD']['output'];
  personalBenefit: Scalars['CurrencyUSD']['output'];
  personalRemainder: Scalars['CurrencyUSD']['output'];
  projectedCharitableBenefit: Scalars['CurrencyUSD']['output'];
  rate7520?: Maybe<Scalars['Percent']['output']>;
  taxableGift: Scalars['CurrencyUSD']['output'];
  yearly: Array<CltProposalYearlyProjection>;
};

export type CltProposalProjectionInput = {
  annuityPayoutAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  costBasis: Scalars['CurrencyUSD']['input'];
  donorsYearOfBirth?: InputMaybe<Array<Scalars['Int']['input']>>;
  fundingAmount: Scalars['CurrencyUSD']['input'];
  payoutFrequency: CharitableTrustPayoutFrequency;
  payoutKind: CharitableTrustPayoutKind;
  preTaxReturnPercentage: Scalars['Percent']['input'];
  rate7520?: InputMaybe<Scalars['Percent']['input']>;
  taxDrag: Scalars['Percent']['input'];
  termKind: CharitableTrustTermKind;
  termYears: Scalars['Int']['input'];
  unitrustPayoutPercent?: InputMaybe<Scalars['Percent']['input']>;
};

/**
 * CLTProposalWhereInput is used for filtering CLTProposal objects.
 * Input was generated by ent.
 */
export type CltProposalWhereInput = {
  and?: InputMaybe<Array<CltProposalWhereInput>>;
  /** annuity_payout_amount field predicates */
  annuityPayoutAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  annuityPayoutAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annuityPayoutAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  annuityPayoutAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** asset_cost_basis field predicates */
  assetCostBasis?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  assetCostBasisLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  /** asset_notes field predicates */
  assetNotes?: InputMaybe<Scalars['String']['input']>;
  assetNotesContains?: InputMaybe<Scalars['String']['input']>;
  assetNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  assetNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  assetNotesGT?: InputMaybe<Scalars['String']['input']>;
  assetNotesGTE?: InputMaybe<Scalars['String']['input']>;
  assetNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  assetNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  assetNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  assetNotesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  assetNotesLT?: InputMaybe<Scalars['String']['input']>;
  assetNotesLTE?: InputMaybe<Scalars['String']['input']>;
  assetNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  assetNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  assetNotesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** asset_value field predicates */
  assetValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  assetValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  /** calculation_method field predicates */
  calculationMethod?: InputMaybe<CharitableTrustCalculationMethod>;
  calculationMethodIn?: InputMaybe<Array<CharitableTrustCalculationMethod>>;
  calculationMethodIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  calculationMethodNEQ?: InputMaybe<CharitableTrustCalculationMethod>;
  calculationMethodNotIn?: InputMaybe<Array<CharitableTrustCalculationMethod>>;
  calculationMethodNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** charitable_income_beneficiaries edge predicates */
  hasCharitableIncomeBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasCharitableIncomeBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** donors edge predicates */
  hasDonors?: InputMaybe<Scalars['Boolean']['input']>;
  hasDonorsWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** proposal edge predicates */
  hasProposal?: InputMaybe<Scalars['Boolean']['input']>;
  hasProposalWith?: InputMaybe<Array<ProposalWhereInput>>;
  /** remainder_beneficiaries edge predicates */
  hasRemainderBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasRemainderBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CltProposalWhereInput>;
  /** optimization_target field predicates */
  optimizationTarget?: InputMaybe<CharitableTrustOptimizationTarget>;
  optimizationTargetIn?: InputMaybe<Array<CharitableTrustOptimizationTarget>>;
  optimizationTargetIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  optimizationTargetNEQ?: InputMaybe<CharitableTrustOptimizationTarget>;
  optimizationTargetNotIn?: InputMaybe<Array<CharitableTrustOptimizationTarget>>;
  optimizationTargetNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<CltProposalWhereInput>>;
  /** payout_frequency field predicates */
  payoutFrequency?: InputMaybe<CharitableTrustPayoutFrequency>;
  payoutFrequencyIn?: InputMaybe<Array<CharitableTrustPayoutFrequency>>;
  payoutFrequencyNEQ?: InputMaybe<CharitableTrustPayoutFrequency>;
  payoutFrequencyNotIn?: InputMaybe<Array<CharitableTrustPayoutFrequency>>;
  /** payout_kind field predicates */
  payoutKind?: InputMaybe<CharitableTrustPayoutKind>;
  payoutKindIn?: InputMaybe<Array<CharitableTrustPayoutKind>>;
  payoutKindNEQ?: InputMaybe<CharitableTrustPayoutKind>;
  payoutKindNotIn?: InputMaybe<Array<CharitableTrustPayoutKind>>;
  /** pre_tax_return_percentage_high field predicates */
  preTaxReturnPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighGT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighGTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageHighIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  preTaxReturnPercentageHighLT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighLTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighNEQ?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageHighNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** pre_tax_return_percentage_low field predicates */
  preTaxReturnPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowGT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowGTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageLowIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  preTaxReturnPercentageLowLT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowLTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowNEQ?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageLowNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** pre_tax_return_percentage_medium field predicates */
  preTaxReturnPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumGT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumGTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageMediumIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  preTaxReturnPercentageMediumLT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumLTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumNEQ?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageMediumNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** rate_7520 field predicates */
  rate7520?: InputMaybe<Scalars['Percent']['input']>;
  rate7520GT?: InputMaybe<Scalars['Percent']['input']>;
  rate7520GTE?: InputMaybe<Scalars['Percent']['input']>;
  rate7520In?: InputMaybe<Array<Scalars['Percent']['input']>>;
  rate7520IsNil?: InputMaybe<Scalars['Boolean']['input']>;
  rate7520LT?: InputMaybe<Scalars['Percent']['input']>;
  rate7520LTE?: InputMaybe<Scalars['Percent']['input']>;
  rate7520NEQ?: InputMaybe<Scalars['Percent']['input']>;
  rate7520NotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  rate7520NotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_drag_percentage_high field predicates */
  taxDragPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageHighIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDragPercentageHighLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageHighNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_drag_percentage_low field predicates */
  taxDragPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageLowIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDragPercentageLowLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageLowNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_drag_percentage_medium field predicates */
  taxDragPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageMediumIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDragPercentageMediumLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageMediumNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<TrustTaxStatus>;
  taxStatusIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusNEQ?: InputMaybe<TrustTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<TrustTaxStatus>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** term_kind field predicates */
  termKind?: InputMaybe<CharitableTrustTermKind>;
  termKindIn?: InputMaybe<Array<CharitableTrustTermKind>>;
  termKindNEQ?: InputMaybe<CharitableTrustTermKind>;
  termKindNotIn?: InputMaybe<Array<CharitableTrustTermKind>>;
  /** term_years field predicates */
  termYears?: InputMaybe<Scalars['Int']['input']>;
  termYearsGT?: InputMaybe<Scalars['Int']['input']>;
  termYearsGTE?: InputMaybe<Scalars['Int']['input']>;
  termYearsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termYearsLT?: InputMaybe<Scalars['Int']['input']>;
  termYearsLTE?: InputMaybe<Scalars['Int']['input']>;
  termYearsNEQ?: InputMaybe<Scalars['Int']['input']>;
  termYearsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** unitrust_kind field predicates */
  unitrustKind?: InputMaybe<CharitableTrustUnitrustKind>;
  unitrustKindIn?: InputMaybe<Array<CharitableTrustUnitrustKind>>;
  unitrustKindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unitrustKindNEQ?: InputMaybe<CharitableTrustUnitrustKind>;
  unitrustKindNotIn?: InputMaybe<Array<CharitableTrustUnitrustKind>>;
  unitrustKindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** unitrust_payout_percent field predicates */
  unitrustPayoutPercent?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentGT?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  unitrustPayoutPercentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unitrustPayoutPercentLT?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  unitrustPayoutPercentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** year_of_analysis field predicates */
  yearOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisGT?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisGTE?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  yearOfAnalysisIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  yearOfAnalysisLT?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisLTE?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisNEQ?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  yearOfAnalysisNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CltProposalYearlyProjection = {
  __typename?: 'CLTProposalYearlyProjection';
  beginningOfYear: Scalars['CurrencyUSD']['output'];
  cumulativeCharity: Scalars['CurrencyUSD']['output'];
  cumulativeTaxed: Scalars['CurrencyUSD']['output'];
  growth: Scalars['CurrencyUSD']['output'];
  payout: Scalars['CurrencyUSD']['output'];
  remainderValue: Scalars['CurrencyUSD']['output'];
  taxed: Scalars['CurrencyUSD']['output'];
  year: Scalars['Int']['output'];
};

export type CltTrust = EntitySubtype & Named & Node & Trust & {
  __typename?: 'CLTTrust';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  /** Taxable deduction that was recorded for funding this CLT */
  charitableDeduction?: Maybe<Scalars['CurrencyUSD']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this trust */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  grantors?: Maybe<Array<Principal>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  lifetimeBeneficiaries?: Maybe<Array<Beneficiary>>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  officialInterestRatePercent?: Maybe<Scalars['Percent']['output']>;
  policies?: Maybe<Array<InsurancePolicy>>;
  remainderBeneficiaries?: Maybe<Array<Beneficiary>>;
  successorTrustees?: Maybe<Array<Trustee>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<TrustTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** If true, term_duration_years will be ignored */
  termDurationLifetime: Scalars['Boolean']['output'];
  termDurationYears?: Maybe<Scalars['Int']['output']>;
  termEndDate?: Maybe<Scalars['Time']['output']>;
  terminationDate?: Maybe<Scalars['Time']['output']>;
  trustAdvisors?: Maybe<Array<TrustAdvisor>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  trustees?: Maybe<Array<Trustee>>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type CltTrustHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type CltTrustConnection = {
  __typename?: 'CLTTrustConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CltTrustEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CltTrustEdge = {
  __typename?: 'CLTTrustEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CltTrust>;
};

/** Ordering options for CLTTrust connections */
export type CltTrustOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CLTTrusts. */
  field: CltTrustOrderField;
};

/** Properties by which CLTTrust connections can be ordered. */
export enum CltTrustOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * CLTTrustWhereInput is used for filtering CLTTrust objects.
 * Input was generated by ent.
 */
export type CltTrustWhereInput = {
  and?: InputMaybe<Array<CltTrustWhereInput>>;
  /** charitable_deduction field predicates */
  charitableDeduction?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  charitableDeductionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  charitableDeductionLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  charitableDeductionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** grantors edge predicates */
  hasGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantorsWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** lifetime_beneficiaries edge predicates */
  hasLifetimeBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasLifetimeBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** remainder_beneficiaries edge predicates */
  hasRemainderBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasRemainderBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** successor_trustees edge predicates */
  hasSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuccessorTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** trust_advisors edge predicates */
  hasTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustAdvisorsWith?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** trustees edge predicates */
  hasTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<CltTrustWhereInput>;
  /** official_interest_rate_percent field predicates */
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentGT?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  officialInterestRatePercentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  officialInterestRatePercentLT?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  officialInterestRatePercentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<CltTrustWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<TrustTaxStatus>;
  taxStatusIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<TrustTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** term_duration_lifetime field predicates */
  termDurationLifetime?: InputMaybe<Scalars['Boolean']['input']>;
  termDurationLifetimeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** term_duration_years field predicates */
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termDurationYearsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  termDurationYearsLT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsLTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNEQ?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termDurationYearsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** termination_date field predicates */
  terminationDate?: InputMaybe<Scalars['Time']['input']>;
  terminationDateGT?: InputMaybe<Scalars['Time']['input']>;
  terminationDateGTE?: InputMaybe<Scalars['Time']['input']>;
  terminationDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  terminationDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  terminationDateLT?: InputMaybe<Scalars['Time']['input']>;
  terminationDateLTE?: InputMaybe<Scalars['Time']['input']>;
  terminationDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  terminationDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  terminationDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** trust_jurisdiction_state_code field predicates */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  trustJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type CrtOptimization = {
  __typename?: 'CRTOptimization';
  annuityPayoutAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  unitrustPayoutPercent?: Maybe<Scalars['Percent']['output']>;
};

export type CrtOptimizationInput = {
  donorsYearOfBirth?: InputMaybe<Array<Scalars['Int']['input']>>;
  fundingAmount: Scalars['CurrencyUSD']['input'];
  optimizationTarget: CharitableTrustOptimizationTarget;
  payoutFrequency: CharitableTrustPayoutFrequency;
  payoutKind: CharitableTrustPayoutKind;
  rate7520?: InputMaybe<Scalars['Percent']['input']>;
  termKind: CharitableTrustTermKind;
  termYears: Scalars['Int']['input'];
};

export type CrtProposal = Node & {
  __typename?: 'CRTProposal';
  /** The amount of the annuity payout. Only valid when payout_kind is ANNUITY */
  annuityPayoutAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** The cost basis of assets proposed to be transferred to this CRT */
  assetCostBasis: Scalars['CurrencyUSD']['output'];
  /** Notes about the assets proposed to be transferred to this CRT */
  assetNotes?: Maybe<Scalars['String']['output']>;
  /** The total value of assets proposed to be transferred to this CRT */
  assetValue: Scalars['CurrencyUSD']['output'];
  calculationMethod?: Maybe<CharitableTrustCalculationMethod>;
  charitableRemainderBeneficiaries?: Maybe<Array<Beneficiary>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  donors: Array<ClientProfile>;
  id: Scalars['ID']['output'];
  incomeBeneficiaries?: Maybe<Array<Beneficiary>>;
  noPlanProjectionHigh: NoPlanProjection;
  noPlanProjectionLow: NoPlanProjection;
  noPlanProjectionMedium: NoPlanProjection;
  optimizationTarget?: Maybe<CharitableTrustOptimizationTarget>;
  /** The frequency of payouts for this charitable trust. */
  payoutFrequency: CharitableTrustPayoutFrequency;
  payoutKind: CharitableTrustPayoutKind;
  /** The high end of the pre-tax return percentage assumption */
  preTaxReturnPercentageHigh?: Maybe<Scalars['Percent']['output']>;
  /** The low end of the pre-tax return assumption */
  preTaxReturnPercentageLow?: Maybe<Scalars['Percent']['output']>;
  /** The medium pre-tax return percentage assumption */
  preTaxReturnPercentageMedium?: Maybe<Scalars['Percent']['output']>;
  projectionHigh: CrtProposalProjection;
  projectionLow: CrtProposalProjection;
  projectionMedium: CrtProposalProjection;
  proposal: Proposal;
  rate7520?: Maybe<Scalars['Percent']['output']>;
  /** The associated tax drag for the high pre-tax return */
  taxDragPercentageHigh?: Maybe<Scalars['Percent']['output']>;
  /** The associated tax drag for the low pre-tax return */
  taxDragPercentageLow?: Maybe<Scalars['Percent']['output']>;
  /** The associated tax drag for the medium pre-tax return */
  taxDragPercentageMedium?: Maybe<Scalars['Percent']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  termKind: CharitableTrustTermKind;
  /** The number of years the CRT will pay out for. Only valid if term_kind is FIXED; ignored otherwise. */
  termYears: Scalars['Int']['output'];
  /** The kind of unitrust. Only valid when payout_kind is UNITRUST; ignored otherwise. */
  unitrustKind?: Maybe<CharitableTrustUnitrustKind>;
  /** The percent of the unitrust payout. Only valid when payout_kind is UNITRUST */
  unitrustPayoutPercent?: Maybe<Scalars['Percent']['output']>;
  updatedAt: Scalars['Time']['output'];
  user: User;
  /** This is the year of analysis. If not specified, it will either default to the actuarial tables or the term_years */
  yearOfAnalysis?: Maybe<Scalars['Int']['output']>;
};

/** A connection to a list of items. */
export type CrtProposalConnection = {
  __typename?: 'CRTProposalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CrtProposalEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CrtProposalEdge = {
  __typename?: 'CRTProposalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CrtProposal>;
};

/** Ordering options for CRTProposal connections */
export type CrtProposalOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CRTProposals. */
  field: CrtProposalOrderField;
};

/** Properties by which CRTProposal connections can be ordered. */
export enum CrtProposalOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type CrtProposalProjection = {
  __typename?: 'CRTProposalProjection';
  capitalGainsDeferred: Scalars['CurrencyUSD']['output'];
  capitalGainsTaxDeferred: Scalars['CurrencyUSD']['output'];
  charitableRemainder: Scalars['CurrencyUSD']['output'];
  metCharitableThreshold: Scalars['Boolean']['output'];
  ordinaryIncomeTaxSaved: Scalars['CurrencyUSD']['output'];
  personalBenefit: Scalars['CurrencyUSD']['output'];
  personalPortfolio: Scalars['CurrencyUSD']['output'];
  projectedCharitableRemainder: Scalars['CurrencyUSD']['output'];
  rate7520?: Maybe<Scalars['Percent']['output']>;
  yearly: Array<CrtProposalYearlyProjection>;
};

export type CrtProposalProjectionInput = {
  annuityPayoutAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  costBasis: Scalars['CurrencyUSD']['input'];
  donorsYearOfBirth?: InputMaybe<Array<Scalars['Int']['input']>>;
  fundingAmount: Scalars['CurrencyUSD']['input'];
  payoutFrequency: CharitableTrustPayoutFrequency;
  payoutKind: CharitableTrustPayoutKind;
  preTaxReturnPercentage: Scalars['Percent']['input'];
  rate7520?: InputMaybe<Scalars['Percent']['input']>;
  taxDrag: Scalars['Percent']['input'];
  termKind: CharitableTrustTermKind;
  termYears: Scalars['Int']['input'];
  unitrustPayoutPercent?: InputMaybe<Scalars['Percent']['input']>;
};

/**
 * CRTProposalWhereInput is used for filtering CRTProposal objects.
 * Input was generated by ent.
 */
export type CrtProposalWhereInput = {
  and?: InputMaybe<Array<CrtProposalWhereInput>>;
  /** annuity_payout_amount field predicates */
  annuityPayoutAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  annuityPayoutAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annuityPayoutAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annuityPayoutAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  annuityPayoutAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** asset_cost_basis field predicates */
  assetCostBasis?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  assetCostBasisLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetCostBasisNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  /** asset_notes field predicates */
  assetNotes?: InputMaybe<Scalars['String']['input']>;
  assetNotesContains?: InputMaybe<Scalars['String']['input']>;
  assetNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  assetNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  assetNotesGT?: InputMaybe<Scalars['String']['input']>;
  assetNotesGTE?: InputMaybe<Scalars['String']['input']>;
  assetNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  assetNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  assetNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  assetNotesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  assetNotesLT?: InputMaybe<Scalars['String']['input']>;
  assetNotesLTE?: InputMaybe<Scalars['String']['input']>;
  assetNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  assetNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  assetNotesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** asset_value field predicates */
  assetValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  assetValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  assetValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  /** calculation_method field predicates */
  calculationMethod?: InputMaybe<CharitableTrustCalculationMethod>;
  calculationMethodIn?: InputMaybe<Array<CharitableTrustCalculationMethod>>;
  calculationMethodIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  calculationMethodNEQ?: InputMaybe<CharitableTrustCalculationMethod>;
  calculationMethodNotIn?: InputMaybe<Array<CharitableTrustCalculationMethod>>;
  calculationMethodNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** charitable_remainder_beneficiaries edge predicates */
  hasCharitableRemainderBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasCharitableRemainderBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** donors edge predicates */
  hasDonors?: InputMaybe<Scalars['Boolean']['input']>;
  hasDonorsWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** income_beneficiaries edge predicates */
  hasIncomeBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasIncomeBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** proposal edge predicates */
  hasProposal?: InputMaybe<Scalars['Boolean']['input']>;
  hasProposalWith?: InputMaybe<Array<ProposalWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CrtProposalWhereInput>;
  /** optimization_target field predicates */
  optimizationTarget?: InputMaybe<CharitableTrustOptimizationTarget>;
  optimizationTargetIn?: InputMaybe<Array<CharitableTrustOptimizationTarget>>;
  optimizationTargetIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  optimizationTargetNEQ?: InputMaybe<CharitableTrustOptimizationTarget>;
  optimizationTargetNotIn?: InputMaybe<Array<CharitableTrustOptimizationTarget>>;
  optimizationTargetNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<CrtProposalWhereInput>>;
  /** payout_frequency field predicates */
  payoutFrequency?: InputMaybe<CharitableTrustPayoutFrequency>;
  payoutFrequencyIn?: InputMaybe<Array<CharitableTrustPayoutFrequency>>;
  payoutFrequencyNEQ?: InputMaybe<CharitableTrustPayoutFrequency>;
  payoutFrequencyNotIn?: InputMaybe<Array<CharitableTrustPayoutFrequency>>;
  /** payout_kind field predicates */
  payoutKind?: InputMaybe<CharitableTrustPayoutKind>;
  payoutKindIn?: InputMaybe<Array<CharitableTrustPayoutKind>>;
  payoutKindNEQ?: InputMaybe<CharitableTrustPayoutKind>;
  payoutKindNotIn?: InputMaybe<Array<CharitableTrustPayoutKind>>;
  /** pre_tax_return_percentage_high field predicates */
  preTaxReturnPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighGT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighGTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageHighIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  preTaxReturnPercentageHighLT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighLTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighNEQ?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageHighNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** pre_tax_return_percentage_low field predicates */
  preTaxReturnPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowGT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowGTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageLowIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  preTaxReturnPercentageLowLT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowLTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowNEQ?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageLowNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** pre_tax_return_percentage_medium field predicates */
  preTaxReturnPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumGT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumGTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageMediumIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  preTaxReturnPercentageMediumLT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumLTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumNEQ?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageMediumNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** rate_7520 field predicates */
  rate7520?: InputMaybe<Scalars['Percent']['input']>;
  rate7520GT?: InputMaybe<Scalars['Percent']['input']>;
  rate7520GTE?: InputMaybe<Scalars['Percent']['input']>;
  rate7520In?: InputMaybe<Array<Scalars['Percent']['input']>>;
  rate7520IsNil?: InputMaybe<Scalars['Boolean']['input']>;
  rate7520LT?: InputMaybe<Scalars['Percent']['input']>;
  rate7520LTE?: InputMaybe<Scalars['Percent']['input']>;
  rate7520NEQ?: InputMaybe<Scalars['Percent']['input']>;
  rate7520NotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  rate7520NotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_drag_percentage_high field predicates */
  taxDragPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageHighIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDragPercentageHighLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageHighNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_drag_percentage_low field predicates */
  taxDragPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageLowIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDragPercentageLowLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageLowNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_drag_percentage_medium field predicates */
  taxDragPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageMediumIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDragPercentageMediumLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageMediumNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** term_kind field predicates */
  termKind?: InputMaybe<CharitableTrustTermKind>;
  termKindIn?: InputMaybe<Array<CharitableTrustTermKind>>;
  termKindNEQ?: InputMaybe<CharitableTrustTermKind>;
  termKindNotIn?: InputMaybe<Array<CharitableTrustTermKind>>;
  /** term_years field predicates */
  termYears?: InputMaybe<Scalars['Int']['input']>;
  termYearsGT?: InputMaybe<Scalars['Int']['input']>;
  termYearsGTE?: InputMaybe<Scalars['Int']['input']>;
  termYearsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termYearsLT?: InputMaybe<Scalars['Int']['input']>;
  termYearsLTE?: InputMaybe<Scalars['Int']['input']>;
  termYearsNEQ?: InputMaybe<Scalars['Int']['input']>;
  termYearsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** unitrust_kind field predicates */
  unitrustKind?: InputMaybe<CharitableTrustUnitrustKind>;
  unitrustKindIn?: InputMaybe<Array<CharitableTrustUnitrustKind>>;
  unitrustKindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unitrustKindNEQ?: InputMaybe<CharitableTrustUnitrustKind>;
  unitrustKindNotIn?: InputMaybe<Array<CharitableTrustUnitrustKind>>;
  unitrustKindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** unitrust_payout_percent field predicates */
  unitrustPayoutPercent?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentGT?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  unitrustPayoutPercentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unitrustPayoutPercentLT?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  unitrustPayoutPercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  unitrustPayoutPercentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** year_of_analysis field predicates */
  yearOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisGT?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisGTE?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  yearOfAnalysisIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  yearOfAnalysisLT?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisLTE?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisNEQ?: InputMaybe<Scalars['Int']['input']>;
  yearOfAnalysisNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  yearOfAnalysisNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CrtProposalYearlyProjection = {
  __typename?: 'CRTProposalYearlyProjection';
  beginningOfYear: Scalars['CurrencyUSD']['output'];
  cumulativeIncome: Scalars['CurrencyUSD']['output'];
  cumulativeTaxed: Scalars['CurrencyUSD']['output'];
  growth: Scalars['CurrencyUSD']['output'];
  payout: Scalars['CurrencyUSD']['output'];
  remainderValue: Scalars['CurrencyUSD']['output'];
  taxed: Scalars['CurrencyUSD']['output'];
  year: Scalars['Int']['output'];
};

export type CrtTrust = EntitySubtype & Named & Node & Trust & {
  __typename?: 'CRTTrust';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  /** Taxable deduction that was recorded for funding this CRT */
  charitableDeduction?: Maybe<Scalars['CurrencyUSD']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this trust */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  grantors?: Maybe<Array<Principal>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  lifetimeBeneficiaries?: Maybe<Array<Beneficiary>>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  officialInterestRatePercent?: Maybe<Scalars['Percent']['output']>;
  policies?: Maybe<Array<InsurancePolicy>>;
  remainderBeneficiaries?: Maybe<Array<Beneficiary>>;
  successorTrustees?: Maybe<Array<Trustee>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<TrustTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** If true, term_duration_years will be ignored */
  termDurationLifetime: Scalars['Boolean']['output'];
  termDurationYears?: Maybe<Scalars['Int']['output']>;
  termEndDate?: Maybe<Scalars['Time']['output']>;
  terminationDate?: Maybe<Scalars['Time']['output']>;
  trustAdvisors?: Maybe<Array<TrustAdvisor>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  trustees?: Maybe<Array<Trustee>>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type CrtTrustHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type CrtTrustConnection = {
  __typename?: 'CRTTrustConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CrtTrustEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CrtTrustEdge = {
  __typename?: 'CRTTrustEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CrtTrust>;
};

/** Ordering options for CRTTrust connections */
export type CrtTrustOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CRTTrusts. */
  field: CrtTrustOrderField;
};

/** Properties by which CRTTrust connections can be ordered. */
export enum CrtTrustOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * CRTTrustWhereInput is used for filtering CRTTrust objects.
 * Input was generated by ent.
 */
export type CrtTrustWhereInput = {
  and?: InputMaybe<Array<CrtTrustWhereInput>>;
  /** charitable_deduction field predicates */
  charitableDeduction?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  charitableDeductionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  charitableDeductionLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  charitableDeductionNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  charitableDeductionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** grantors edge predicates */
  hasGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantorsWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** lifetime_beneficiaries edge predicates */
  hasLifetimeBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasLifetimeBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** remainder_beneficiaries edge predicates */
  hasRemainderBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasRemainderBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** successor_trustees edge predicates */
  hasSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuccessorTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** trust_advisors edge predicates */
  hasTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustAdvisorsWith?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** trustees edge predicates */
  hasTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<CrtTrustWhereInput>;
  /** official_interest_rate_percent field predicates */
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentGT?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  officialInterestRatePercentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  officialInterestRatePercentLT?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  officialInterestRatePercentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<CrtTrustWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<TrustTaxStatus>;
  taxStatusIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<TrustTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** term_duration_lifetime field predicates */
  termDurationLifetime?: InputMaybe<Scalars['Boolean']['input']>;
  termDurationLifetimeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** term_duration_years field predicates */
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termDurationYearsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  termDurationYearsLT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsLTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNEQ?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termDurationYearsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** termination_date field predicates */
  terminationDate?: InputMaybe<Scalars['Time']['input']>;
  terminationDateGT?: InputMaybe<Scalars['Time']['input']>;
  terminationDateGTE?: InputMaybe<Scalars['Time']['input']>;
  terminationDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  terminationDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  terminationDateLT?: InputMaybe<Scalars['Time']['input']>;
  terminationDateLTE?: InputMaybe<Scalars['Time']['input']>;
  terminationDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  terminationDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  terminationDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** trust_jurisdiction_state_code field predicates */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  trustJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type CsvAccountImportResponse = {
  __typename?: 'CSVAccountImportResponse';
  file: File;
  numEntitiesUpdated: Scalars['Int']['output'];
};

export type CsvValuationImportResponse = {
  __typename?: 'CSVValuationImportResponse';
  file: File;
  numValuationsUpdated: Scalars['Int']['output'];
  valuationUpdateErrorMessages: Array<EntityValuationErrorMessage>;
};

export type Capability = Node & {
  __typename?: 'Capability';
  authzedRelationName: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The roles that have this capability. */
  role?: Maybe<Array<Role>>;
  updatedAt: Scalars['Time']['output'];
};

/** Ordering options for Capability connections */
export type CapabilityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Capabilities. */
  field: CapabilityOrderField;
};

/** Properties by which Capability connections can be ordered. */
export enum CapabilityOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * CapabilityWhereInput is used for filtering Capability objects.
 * Input was generated by ent.
 */
export type CapabilityWhereInput = {
  and?: InputMaybe<Array<CapabilityWhereInput>>;
  /** authzed_relation_name field predicates */
  authzedRelationName?: InputMaybe<Scalars['String']['input']>;
  authzedRelationNameContains?: InputMaybe<Scalars['String']['input']>;
  authzedRelationNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  authzedRelationNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  authzedRelationNameGT?: InputMaybe<Scalars['String']['input']>;
  authzedRelationNameGTE?: InputMaybe<Scalars['String']['input']>;
  authzedRelationNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  authzedRelationNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  authzedRelationNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  authzedRelationNameLT?: InputMaybe<Scalars['String']['input']>;
  authzedRelationNameLTE?: InputMaybe<Scalars['String']['input']>;
  authzedRelationNameNEQ?: InputMaybe<Scalars['String']['input']>;
  authzedRelationNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** role edge predicates */
  hasRole?: InputMaybe<Scalars['Boolean']['input']>;
  hasRoleWith?: InputMaybe<Array<RoleWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CapabilityWhereInput>;
  or?: InputMaybe<Array<CapabilityWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Cashflow = Node & {
  __typename?: 'Cashflow';
  /** This dynamically sets the end date of this transfer to the year when both individuals die. It superceeds end_year and end_at_clients_death. Ignored if start_year is not set.  */
  afterBothDeaths?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['Time']['output'];
  displayName: Scalars['String']['output'];
  /** This sets the end date of this transfer when the client dies. This superceeds the end_year field. This is ignored if start_year is not set. */
  endAtClientsDeath?: Maybe<ClientProfile>;
  /** The year this transfer ends. Ignored if start_year is not set. */
  endYear?: Maybe<Scalars['Int']['output']>;
  entity?: Maybe<Entity>;
  /** The yearly growth percent of this transfer. Can be negative. */
  growthPercent?: Maybe<Scalars['Percent']['output']>;
  household: Household;
  /** the household this object belongs to */
  householdID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  individual?: Maybe<ClientProfile>;
  /** The year this transfer starts. If start_year is not set, it is assumed to be a one time transfer happening in the current year. If start_year is set, this is a yearly recurring transfer until end_year is reached. */
  startYear?: Maybe<Scalars['Int']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** Value of this transfer. Positive for income, negative for expense. */
  transferValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type CashflowConnection = {
  __typename?: 'CashflowConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CashflowEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CashflowEdge = {
  __typename?: 'CashflowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Cashflow>;
};

/** Ordering options for Cashflow connections */
export type CashflowOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Cashflows. */
  field: CashflowOrderField;
};

/** Properties by which Cashflow connections can be ordered. */
export enum CashflowOrderField {
  CreatedAt = 'created_at',
  StartYear = 'start_year',
  TransferValue = 'transfer_value',
  UpdatedAt = 'updated_at'
}

/**
 * CashflowWhereInput is used for filtering Cashflow objects.
 * Input was generated by ent.
 */
export type CashflowWhereInput = {
  /** after_both_deaths field predicates */
  afterBothDeaths?: InputMaybe<Scalars['Boolean']['input']>;
  afterBothDeathsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  afterBothDeathsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  afterBothDeathsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<CashflowWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** end_year field predicates */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  endYearGT?: InputMaybe<Scalars['Int']['input']>;
  endYearGTE?: InputMaybe<Scalars['Int']['input']>;
  endYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  endYearIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  endYearLT?: InputMaybe<Scalars['Int']['input']>;
  endYearLTE?: InputMaybe<Scalars['Int']['input']>;
  endYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  endYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  endYearNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** growth_percent field predicates */
  growthPercent?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentGT?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  growthPercentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  growthPercentLT?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  growthPercentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** end_at_clients_death edge predicates */
  hasEndAtClientsDeath?: InputMaybe<Scalars['Boolean']['input']>;
  hasEndAtClientsDeathWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** individual edge predicates */
  hasIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** household_id field predicates */
  householdID?: InputMaybe<Scalars['ID']['input']>;
  householdIDContains?: InputMaybe<Scalars['ID']['input']>;
  householdIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDGT?: InputMaybe<Scalars['ID']['input']>;
  householdIDGTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  householdIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDLT?: InputMaybe<Scalars['ID']['input']>;
  householdIDLTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  householdIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<CashflowWhereInput>;
  or?: InputMaybe<Array<CashflowWhereInput>>;
  /** start_year field predicates */
  startYear?: InputMaybe<Scalars['Int']['input']>;
  startYearGT?: InputMaybe<Scalars['Int']['input']>;
  startYearGTE?: InputMaybe<Scalars['Int']['input']>;
  startYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  startYearIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  startYearLT?: InputMaybe<Scalars['Int']['input']>;
  startYearLTE?: InputMaybe<Scalars['Int']['input']>;
  startYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  startYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  startYearNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** transfer_value field predicates */
  transferValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  transferValueIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  transferValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  transferValueNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type CharitableOrganizationPerformanceReport = {
  __typename?: 'CharitableOrganizationPerformanceReport';
  directGifts: Scalars['CurrencyUSD']['output'];
  grantsFromDAFs?: Maybe<Array<GrantsToCharitableOrganizations>>;
  grantsFromPrivateFoundations?: Maybe<Array<GrantsToCharitableOrganizations>>;
  totalWealthTransferred: Scalars['CurrencyUSD']['output'];
};

/** CharitableTrustCalculationMethod is enum for the field calculation_method */
export enum CharitableTrustCalculationMethod {
  Calculated = 'CALCULATED',
  Manual = 'MANUAL'
}

/** CharitableTrustOptimizationTarget is enum for the field optimization_target */
export enum CharitableTrustOptimizationTarget {
  Charitable = 'CHARITABLE',
  NonCharitable = 'NON_CHARITABLE'
}

/** CharitableTrustPayoutFrequency is enum for the field payout_frequency */
export enum CharitableTrustPayoutFrequency {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Semiannually = 'SEMIANNUALLY'
}

/** CharitableTrustPayoutKind is enum for the field payout_kind */
export enum CharitableTrustPayoutKind {
  Annuity = 'ANNUITY',
  Unitrust = 'UNITRUST'
}

/** CharitableTrustTermKind is enum for the field term_kind */
export enum CharitableTrustTermKind {
  Fixed = 'FIXED',
  Lifetime = 'LIFETIME'
}

/** CharitableTrustUnitrustKind is enum for the field unitrust_kind */
export enum CharitableTrustUnitrustKind {
  Flip = 'FLIP',
  NetIncome = 'NET_INCOME',
  NetIncomeWithMakeup = 'NET_INCOME_WITH_MAKEUP',
  Standard = 'STANDARD'
}

export type ClientOrganization = Node & {
  __typename?: 'ClientOrganization';
  address?: Maybe<Address>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  household?: Maybe<Household>;
  id: Scalars['ID']['output'];
  isBeneficiary: Scalars['Boolean']['output'];
  isTrustee: Scalars['Boolean']['output'];
  kind?: Maybe<ClientOrganizationKind>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  performanceReport: CharitableOrganizationPerformanceReport;
  pointOfContactEmail?: Maybe<Scalars['String']['output']>;
  pointOfContactLastName?: Maybe<Scalars['String']['output']>;
  pointOfContactName?: Maybe<Scalars['String']['output']>;
  pointOfContactPhone?: Maybe<Scalars['String']['output']>;
  /** The client organization that the suggestion is associated with */
  suggestedClientOrganization?: Maybe<Array<AiSuggestion>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type ClientOrganizationConnection = {
  __typename?: 'ClientOrganizationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClientOrganizationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ClientOrganizationEdge = {
  __typename?: 'ClientOrganizationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ClientOrganization>;
};

/** ClientOrganizationKind is enum for the field kind */
export enum ClientOrganizationKind {
  CharitableOrganization = 'CHARITABLE_ORGANIZATION',
  CorporateTrustee = 'CORPORATE_TRUSTEE',
  Other = 'OTHER'
}

/** Ordering options for ClientOrganization connections */
export type ClientOrganizationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ClientOrganizations. */
  field: ClientOrganizationOrderField;
};

/** Properties by which ClientOrganization connections can be ordered. */
export enum ClientOrganizationOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * ClientOrganizationWhereInput is used for filtering ClientOrganization objects.
 * Input was generated by ent.
 */
export type ClientOrganizationWhereInput = {
  and?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** address edge predicates */
  hasAddress?: InputMaybe<Scalars['Boolean']['input']>;
  hasAddressWith?: InputMaybe<Array<AddressWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** suggested_client_organization edge predicates */
  hasSuggestedClientOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuggestedClientOrganizationWith?: InputMaybe<Array<AiSuggestionWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_beneficiary field predicates */
  isBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  isBeneficiaryNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_trustee field predicates */
  isTrustee?: InputMaybe<Scalars['Boolean']['input']>;
  isTrusteeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** kind field predicates */
  kind?: InputMaybe<ClientOrganizationKind>;
  kindIn?: InputMaybe<Array<ClientOrganizationKind>>;
  kindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  kindNEQ?: InputMaybe<ClientOrganizationKind>;
  kindNotIn?: InputMaybe<Array<ClientOrganizationKind>>;
  kindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<ClientOrganizationWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** point_of_contact_email field predicates */
  pointOfContactEmail?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmailContains?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmailContainsFold?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmailEqualFold?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmailGT?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmailGTE?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  pointOfContactEmailIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  pointOfContactEmailLT?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmailLTE?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmailNEQ?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  pointOfContactEmailNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** point_of_contact_last_name field predicates */
  pointOfContactLastName?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastNameContains?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastNameGT?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastNameGTE?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  pointOfContactLastNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  pointOfContactLastNameLT?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastNameLTE?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  pointOfContactLastNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** point_of_contact_name field predicates */
  pointOfContactName?: InputMaybe<Scalars['String']['input']>;
  pointOfContactNameContains?: InputMaybe<Scalars['String']['input']>;
  pointOfContactNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  pointOfContactNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  pointOfContactNameGT?: InputMaybe<Scalars['String']['input']>;
  pointOfContactNameGTE?: InputMaybe<Scalars['String']['input']>;
  pointOfContactNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  pointOfContactNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  pointOfContactNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  pointOfContactNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  pointOfContactNameLT?: InputMaybe<Scalars['String']['input']>;
  pointOfContactNameLTE?: InputMaybe<Scalars['String']['input']>;
  pointOfContactNameNEQ?: InputMaybe<Scalars['String']['input']>;
  pointOfContactNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  pointOfContactNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** point_of_contact_phone field predicates */
  pointOfContactPhone?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhoneContains?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhoneContainsFold?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhoneEqualFold?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhoneGT?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhoneGTE?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhoneHasPrefix?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhoneHasSuffix?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhoneIn?: InputMaybe<Array<Scalars['String']['input']>>;
  pointOfContactPhoneIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  pointOfContactPhoneLT?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhoneLTE?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhoneNEQ?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhoneNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  pointOfContactPhoneNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ClientOverallPerformanceReport = {
  __typename?: 'ClientOverallPerformanceReport';
  impliedEstateTaxSavings: Scalars['CurrencyUSD']['output'];
  outOfEstateGrowth: Scalars['CurrencyUSD']['output'];
  totalWealthTransferred: Scalars['CurrencyUSD']['output'];
};

export type ClientPresentation = Node & {
  __typename?: 'ClientPresentation';
  coverSlideDescription?: Maybe<Scalars['String']['output']>;
  coverSlideTitle?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  household?: Maybe<Household>;
  id: Scalars['ID']['output'];
  /** if true, show all entities in the presentation. if false, only show the entities defined in entity_order */
  includeAllEntities: Scalars['Boolean']['output'];
  /** if true, show all waterfalls in the presentation. if false, only show the waterfalls defined in entity_order */
  includeAllEstateWaterfalls: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  presentationEntities: Array<ClientPresentationEntity>;
  presentationWaterfalls: Array<ClientPresentationWaterfall>;
  /** the ordered list of included sections in the presentation */
  sectionOrder: Array<ClientPresentationSection>;
  /** if true, include the cover slide in the presentation */
  showCoverSlide: Scalars['Boolean']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type ClientPresentationConnection = {
  __typename?: 'ClientPresentationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClientPresentationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ClientPresentationEdge = {
  __typename?: 'ClientPresentationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ClientPresentation>;
};

export type ClientPresentationEntity = {
  __typename?: 'ClientPresentationEntity';
  entity: Node;
  index: Scalars['Int']['output'];
};

/** Ordering options for ClientPresentation connections */
export type ClientPresentationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ClientPresentations. */
  field: ClientPresentationOrderField;
};

/** Properties by which ClientPresentation connections can be ordered. */
export enum ClientPresentationOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export enum ClientPresentationSection {
  AllEntitiesSlide = 'ALL_ENTITIES_SLIDE',
  BalanceSheet = 'BALANCE_SHEET',
  EntitiesGroup = 'ENTITIES_GROUP',
  EstateWaterfallsGroup = 'ESTATE_WATERFALLS_GROUP',
  ProfessionalTeam = 'PROFESSIONAL_TEAM'
}

export type ClientPresentationWaterfall = {
  __typename?: 'ClientPresentationWaterfall';
  index: Scalars['Int']['output'];
  waterfall: Node;
};

/**
 * ClientPresentationWhereInput is used for filtering ClientPresentation objects.
 * Input was generated by ent.
 */
export type ClientPresentationWhereInput = {
  and?: InputMaybe<Array<ClientPresentationWhereInput>>;
  /** cover_slide_description field predicates */
  coverSlideDescription?: InputMaybe<Scalars['String']['input']>;
  coverSlideDescriptionContains?: InputMaybe<Scalars['String']['input']>;
  coverSlideDescriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  coverSlideDescriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  coverSlideDescriptionGT?: InputMaybe<Scalars['String']['input']>;
  coverSlideDescriptionGTE?: InputMaybe<Scalars['String']['input']>;
  coverSlideDescriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  coverSlideDescriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  coverSlideDescriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  coverSlideDescriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  coverSlideDescriptionLT?: InputMaybe<Scalars['String']['input']>;
  coverSlideDescriptionLTE?: InputMaybe<Scalars['String']['input']>;
  coverSlideDescriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  coverSlideDescriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  coverSlideDescriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** cover_slide_title field predicates */
  coverSlideTitle?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitleContains?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitleContainsFold?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitleEqualFold?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitleGT?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitleGTE?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  coverSlideTitleIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  coverSlideTitleLT?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitleLTE?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitleNEQ?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  coverSlideTitleNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** include_all_entities field predicates */
  includeAllEntities?: InputMaybe<Scalars['Boolean']['input']>;
  includeAllEntitiesNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** include_all_estate_waterfalls field predicates */
  includeAllEstateWaterfalls?: InputMaybe<Scalars['Boolean']['input']>;
  includeAllEstateWaterfallsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<ClientPresentationWhereInput>;
  or?: InputMaybe<Array<ClientPresentationWhereInput>>;
  /** show_cover_slide field predicates */
  showCoverSlide?: InputMaybe<Scalars['Boolean']['input']>;
  showCoverSlideNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ClientProfile = Node & {
  __typename?: 'ClientProfile';
  addeparEntityID?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<Address>;
  cashflows: CashflowConnection;
  cltProposalDonors?: Maybe<Array<CltProposal>>;
  createdAt: Scalars['Time']['output'];
  crtProposalDonors?: Maybe<Array<CrtProposal>>;
  currentAnnualExclusionUsed: Scalars['CurrencyUSD']['output'];
  dateOfBirth?: Maybe<Scalars['Time']['output']>;
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  email?: Maybe<Scalars['String']['output']>;
  /** Estate waterfall filters associated with this client. Here to force the M2M relationship. */
  estateWaterfallFilters?: Maybe<Array<EstateWaterfallVisualizationFilter>>;
  firstName: Scalars['String']['output'];
  gstExclusionUsed: Scalars['CurrencyUSD']['output'];
  household?: Maybe<Household>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  hypotheticalTransfer?: Maybe<Array<EstateWaterfallHypotheticalTransfer>>;
  id: Scalars['ID']['output'];
  integrationClient?: Maybe<IntegrationClient>;
  /** Whether this client profile should be an eligible beneficiary for entities and gifts. */
  isBeneficiary: Scalars['Boolean']['output'];
  /** Whether this client profile should be an eligible grantor/owner/other principal for entities. */
  isGrantor: Scalars['Boolean']['output'];
  /** Whether this client_profile is one of the (at most) two primary clients on this household. */
  isPrimary: Scalars['Boolean']['output'];
  /** Whether this client profile should be an eligible trustee for entities. */
  isTrustee: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  legalName: Scalars['String']['output'];
  lifetimeExclusionEvents?: Maybe<Array<LifetimeExclusionEvent>>;
  lifetimeExclusionUsed: Scalars['CurrencyUSD']['output'];
  middleName?: Maybe<Scalars['String']['output']>;
  /** Ownership stakes this individual has. */
  ownedOwnershipStakes?: Maybe<Array<OwnershipStake>>;
  performanceReportGifts: GiftsToIndividualsPerformanceReport;
  policies?: Maybe<Array<InsurancePolicy>>;
  professionalTeamRoles: ProfessionalTeamRoleConnection;
  relations?: Maybe<Array<ClientProfile>>;
  relationships?: Maybe<Array<ClientProfileRelationship>>;
  remainingGSTExclusion: Scalars['CurrencyUSD']['output'];
  remainingLifetimeExclusion: Scalars['CurrencyUSD']['output'];
  remainingStateExemption: Scalars['CurrencyUSD']['output'];
  stateExemptionUsed: Scalars['CurrencyUSD']['output'];
  suffix?: Maybe<Scalars['String']['output']>;
  /** The client profile that the suggestion is associated with */
  suggestedClientProfile?: Maybe<Array<AiSuggestion>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  testamentaryEntities: TestamentaryEntityConnection;
  totalAnnualExclusionUsed: Scalars['CurrencyUSD']['output'];
  totalStateExemption: Scalars['CurrencyUSD']['output'];
  updatedAt: Scalars['Time']['output'];
  /** DEPRECATED: Use date_of_birth instead */
  yearOfBirth?: Maybe<Scalars['Int']['output']>;
};


export type ClientProfileCashflowsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CashflowOrder>>;
  where?: InputMaybe<CashflowWhereInput>;
};


export type ClientProfileHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};


export type ClientProfileProfessionalTeamRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProfessionalTeamRoleOrder>>;
  where?: InputMaybe<ProfessionalTeamRoleWhereInput>;
};


export type ClientProfileTestamentaryEntitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestamentaryEntityOrder>>;
  where?: InputMaybe<TestamentaryEntityWhereInput>;
};

/** A connection to a list of items. */
export type ClientProfileConnection = {
  __typename?: 'ClientProfileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClientProfileEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ClientProfileEdge = {
  __typename?: 'ClientProfileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ClientProfile>;
};

/** Ordering options for ClientProfile connections */
export type ClientProfileOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ClientProfiles. */
  field: ClientProfileOrderField;
};

/** Properties by which ClientProfile connections can be ordered. */
export enum ClientProfileOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type ClientProfileRelationship = Node & {
  __typename?: 'ClientProfileRelationship';
  clientProfileID: Scalars['ID']['output'];
  createdAt: Scalars['Time']['output'];
  fromClientProfile: ClientProfile;
  id: Scalars['ID']['output'];
  /** Free-form notes */
  notes?: Maybe<Scalars['String']['output']>;
  relationID: Scalars['ID']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  toClientProfile: ClientProfile;
  /** the relationship between the 'from' ClientProfile and the 'to' ClientProfile */
  type: ClientProfileRelationshipType;
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type ClientProfileRelationshipConnection = {
  __typename?: 'ClientProfileRelationshipConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ClientProfileRelationshipEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ClientProfileRelationshipEdge = {
  __typename?: 'ClientProfileRelationshipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ClientProfileRelationship>;
};

/** Ordering options for ClientProfileRelationship connections */
export type ClientProfileRelationshipOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ClientProfileRelationships. */
  field: ClientProfileRelationshipOrderField;
};

/** Properties by which ClientProfileRelationship connections can be ordered. */
export enum ClientProfileRelationshipOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** ClientProfileRelationshipType is enum for the field type */
export enum ClientProfileRelationshipType {
  Child = 'CHILD',
  ChildInLaw = 'CHILD_IN_LAW',
  Cousin = 'COUSIN',
  CousinInLaw = 'COUSIN_IN_LAW',
  DomesticPartner = 'DOMESTIC_PARTNER',
  ExChildInLaw = 'EX_CHILD_IN_LAW',
  ExCousinInLaw = 'EX_COUSIN_IN_LAW',
  ExDomesticPartner = 'EX_DOMESTIC_PARTNER',
  ExGrandchildInLaw = 'EX_GRANDCHILD_IN_LAW',
  ExGreatGrandchildInLaw = 'EX_GREAT_GRANDCHILD_IN_LAW',
  ExGreatGreatGrandchildInLaw = 'EX_GREAT_GREAT_GRANDCHILD_IN_LAW',
  ExNephewNieceInLaw = 'EX_NEPHEW_NIECE_IN_LAW',
  ExSiblingInLaw = 'EX_SIBLING_IN_LAW',
  ExSpouse = 'EX_SPOUSE',
  ExUncleAuntInLaw = 'EX_UNCLE_AUNT_IN_LAW',
  Friend = 'FRIEND',
  Grandchild = 'GRANDCHILD',
  GrandchildInLaw = 'GRANDCHILD_IN_LAW',
  Grandparent = 'GRANDPARENT',
  GreatGrandchild = 'GREAT_GRANDCHILD',
  GreatGrandchildInLaw = 'GREAT_GRANDCHILD_IN_LAW',
  GreatGrandparent = 'GREAT_GRANDPARENT',
  GreatGreatGrandchild = 'GREAT_GREAT_GRANDCHILD',
  GreatGreatGrandchildInLaw = 'GREAT_GREAT_GRANDCHILD_IN_LAW',
  NephewNiece = 'NEPHEW_NIECE',
  NephewNieceInLaw = 'NEPHEW_NIECE_IN_LAW',
  OtherNonRelative = 'OTHER_NON_RELATIVE',
  OtherRelative = 'OTHER_RELATIVE',
  Parent = 'PARENT',
  Sibling = 'SIBLING',
  SiblingInLaw = 'SIBLING_IN_LAW',
  Spouse = 'SPOUSE',
  UncleAunt = 'UNCLE_AUNT',
  UncleAuntInLaw = 'UNCLE_AUNT_IN_LAW'
}

/**
 * ClientProfileRelationshipWhereInput is used for filtering ClientProfileRelationship objects.
 * Input was generated by ent.
 */
export type ClientProfileRelationshipWhereInput = {
  and?: InputMaybe<Array<ClientProfileRelationshipWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ClientProfileRelationshipWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<ClientProfileRelationshipWhereInput>>;
  /** type field predicates */
  type?: InputMaybe<ClientProfileRelationshipType>;
  typeIn?: InputMaybe<Array<ClientProfileRelationshipType>>;
  typeNEQ?: InputMaybe<ClientProfileRelationshipType>;
  typeNotIn?: InputMaybe<Array<ClientProfileRelationshipType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * ClientProfileWhereInput is used for filtering ClientProfile objects.
 * Input was generated by ent.
 */
export type ClientProfileWhereInput = {
  /** addepar_entity_id field predicates */
  addeparEntityID?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDGT?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDGTE?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  addeparEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparEntityIDLT?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDLTE?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDNEQ?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  addeparEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** email field predicates */
  email?: InputMaybe<Scalars['String']['input']>;
  emailContains?: InputMaybe<Scalars['String']['input']>;
  emailContainsFold?: InputMaybe<Scalars['String']['input']>;
  emailEqualFold?: InputMaybe<Scalars['String']['input']>;
  emailGT?: InputMaybe<Scalars['String']['input']>;
  emailGTE?: InputMaybe<Scalars['String']['input']>;
  emailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  emailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  emailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  emailLT?: InputMaybe<Scalars['String']['input']>;
  emailLTE?: InputMaybe<Scalars['String']['input']>;
  emailNEQ?: InputMaybe<Scalars['String']['input']>;
  emailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** first_name field predicates */
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  firstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  firstNameGT?: InputMaybe<Scalars['String']['input']>;
  firstNameGTE?: InputMaybe<Scalars['String']['input']>;
  firstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  firstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  firstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameLT?: InputMaybe<Scalars['String']['input']>;
  firstNameLTE?: InputMaybe<Scalars['String']['input']>;
  firstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  firstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** address edge predicates */
  hasAddress?: InputMaybe<Scalars['Boolean']['input']>;
  hasAddressWith?: InputMaybe<Array<AddressWhereInput>>;
  /** cashflows edge predicates */
  hasCashflows?: InputMaybe<Scalars['Boolean']['input']>;
  hasCashflowsWith?: InputMaybe<Array<CashflowWhereInput>>;
  /** clt_proposal_donors edge predicates */
  hasCltProposalDonors?: InputMaybe<Scalars['Boolean']['input']>;
  hasCltProposalDonorsWith?: InputMaybe<Array<CltProposalWhereInput>>;
  /** crt_proposal_donors edge predicates */
  hasCrtProposalDonors?: InputMaybe<Scalars['Boolean']['input']>;
  hasCrtProposalDonorsWith?: InputMaybe<Array<CrtProposalWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** estate_waterfall_filters edge predicates */
  hasEstateWaterfallFilters?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstateWaterfallFiltersWith?: InputMaybe<Array<EstateWaterfallVisualizationFilterWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** hypothetical_transfer edge predicates */
  hasHypotheticalTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalTransferWith?: InputMaybe<Array<EstateWaterfallHypotheticalTransferWhereInput>>;
  /** integration_client edge predicates */
  hasIntegrationClient?: InputMaybe<Scalars['Boolean']['input']>;
  hasIntegrationClientWith?: InputMaybe<Array<IntegrationClientWhereInput>>;
  /** lifetime_exclusion_events edge predicates */
  hasLifetimeExclusionEvents?: InputMaybe<Scalars['Boolean']['input']>;
  hasLifetimeExclusionEventsWith?: InputMaybe<Array<LifetimeExclusionEventWhereInput>>;
  /** owned_ownership_stakes edge predicates */
  hasOwnedOwnershipStakes?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnedOwnershipStakesWith?: InputMaybe<Array<OwnershipStakeWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** professional_team_roles edge predicates */
  hasProfessionalTeamRoles?: InputMaybe<Scalars['Boolean']['input']>;
  hasProfessionalTeamRolesWith?: InputMaybe<Array<ProfessionalTeamRoleWhereInput>>;
  /** relations edge predicates */
  hasRelations?: InputMaybe<Scalars['Boolean']['input']>;
  hasRelationsWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** relationships edge predicates */
  hasRelationships?: InputMaybe<Scalars['Boolean']['input']>;
  hasRelationshipsWith?: InputMaybe<Array<ClientProfileRelationshipWhereInput>>;
  /** suggested_client_profile edge predicates */
  hasSuggestedClientProfile?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuggestedClientProfileWith?: InputMaybe<Array<AiSuggestionWhereInput>>;
  /** testamentary_entities edge predicates */
  hasTestamentaryEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasTestamentaryEntitiesWith?: InputMaybe<Array<TestamentaryEntityWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_beneficiary field predicates */
  isBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  isBeneficiaryNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_grantor field predicates */
  isGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  isGrantorNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_primary field predicates */
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  isPrimaryNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** is_trustee field predicates */
  isTrustee?: InputMaybe<Scalars['Boolean']['input']>;
  isTrusteeNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** last_name field predicates */
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  lastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  lastNameGT?: InputMaybe<Scalars['String']['input']>;
  lastNameGTE?: InputMaybe<Scalars['String']['input']>;
  lastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  lastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  lastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameLT?: InputMaybe<Scalars['String']['input']>;
  lastNameLTE?: InputMaybe<Scalars['String']['input']>;
  lastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  lastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** middle_name field predicates */
  middleName?: InputMaybe<Scalars['String']['input']>;
  middleNameContains?: InputMaybe<Scalars['String']['input']>;
  middleNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  middleNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  middleNameGT?: InputMaybe<Scalars['String']['input']>;
  middleNameGTE?: InputMaybe<Scalars['String']['input']>;
  middleNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  middleNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  middleNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  middleNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  middleNameLT?: InputMaybe<Scalars['String']['input']>;
  middleNameLTE?: InputMaybe<Scalars['String']['input']>;
  middleNameNEQ?: InputMaybe<Scalars['String']['input']>;
  middleNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  middleNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<ClientProfileWhereInput>;
  or?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** suffix field predicates */
  suffix?: InputMaybe<Scalars['String']['input']>;
  suffixContains?: InputMaybe<Scalars['String']['input']>;
  suffixContainsFold?: InputMaybe<Scalars['String']['input']>;
  suffixEqualFold?: InputMaybe<Scalars['String']['input']>;
  suffixGT?: InputMaybe<Scalars['String']['input']>;
  suffixGTE?: InputMaybe<Scalars['String']['input']>;
  suffixHasPrefix?: InputMaybe<Scalars['String']['input']>;
  suffixHasSuffix?: InputMaybe<Scalars['String']['input']>;
  suffixIn?: InputMaybe<Array<Scalars['String']['input']>>;
  suffixIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  suffixLT?: InputMaybe<Scalars['String']['input']>;
  suffixLTE?: InputMaybe<Scalars['String']['input']>;
  suffixNEQ?: InputMaybe<Scalars['String']['input']>;
  suffixNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  suffixNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Collaborator = Named & Node & {
  __typename?: 'Collaborator';
  createdAt: Scalars['Time']['output'];
  displayName: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  /** Households this collaborator is assigned to */
  households?: Maybe<Array<Household>>;
  id: Scalars['ID']['output'];
  /** The role of the collaborator, for display purposes only */
  kind: CollaboratorKind;
  lastName: Scalars['String']['output'];
  /** The name of the organization this collaborator works for */
  organizationName?: Maybe<Scalars['String']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** The professional title of the collaborator, e.g. 'Associate' */
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Time']['output'];
  /** The user associated with this collaborator */
  user?: Maybe<User>;
};

/** A connection to a list of items. */
export type CollaboratorConnection = {
  __typename?: 'CollaboratorConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CollaboratorEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CollaboratorEdge = {
  __typename?: 'CollaboratorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Collaborator>;
};

/** CollaboratorKind is enum for the field kind */
export enum CollaboratorKind {
  Attorney = 'ATTORNEY',
  Cpa = 'CPA',
  Other = 'OTHER'
}

/** Ordering options for Collaborator connections */
export type CollaboratorOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Collaborators. */
  field: CollaboratorOrderField;
};

/** Properties by which Collaborator connections can be ordered. */
export enum CollaboratorOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * CollaboratorWhereInput is used for filtering Collaborator objects.
 * Input was generated by ent.
 */
export type CollaboratorWhereInput = {
  and?: InputMaybe<Array<CollaboratorWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** first_name field predicates */
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  firstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  firstNameGT?: InputMaybe<Scalars['String']['input']>;
  firstNameGTE?: InputMaybe<Scalars['String']['input']>;
  firstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  firstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  firstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameLT?: InputMaybe<Scalars['String']['input']>;
  firstNameLTE?: InputMaybe<Scalars['String']['input']>;
  firstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  firstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** households edge predicates */
  hasHouseholds?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdsWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<CollaboratorKind>;
  kindIn?: InputMaybe<Array<CollaboratorKind>>;
  kindNEQ?: InputMaybe<CollaboratorKind>;
  kindNotIn?: InputMaybe<Array<CollaboratorKind>>;
  /** last_name field predicates */
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  lastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  lastNameGT?: InputMaybe<Scalars['String']['input']>;
  lastNameGTE?: InputMaybe<Scalars['String']['input']>;
  lastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  lastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  lastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameLT?: InputMaybe<Scalars['String']['input']>;
  lastNameLTE?: InputMaybe<Scalars['String']['input']>;
  lastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  lastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<CollaboratorWhereInput>;
  or?: InputMaybe<Array<CollaboratorWhereInput>>;
  /** organization_name field predicates */
  organizationName?: InputMaybe<Scalars['String']['input']>;
  organizationNameContains?: InputMaybe<Scalars['String']['input']>;
  organizationNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  organizationNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  organizationNameGT?: InputMaybe<Scalars['String']['input']>;
  organizationNameGTE?: InputMaybe<Scalars['String']['input']>;
  organizationNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  organizationNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  organizationNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  organizationNameLT?: InputMaybe<Scalars['String']['input']>;
  organizationNameLTE?: InputMaybe<Scalars['String']['input']>;
  organizationNameNEQ?: InputMaybe<Scalars['String']['input']>;
  organizationNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** title field predicates */
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
  titleContainsFold?: InputMaybe<Scalars['String']['input']>;
  titleEqualFold?: InputMaybe<Scalars['String']['input']>;
  titleGT?: InputMaybe<Scalars['String']['input']>;
  titleGTE?: InputMaybe<Scalars['String']['input']>;
  titleHasPrefix?: InputMaybe<Scalars['String']['input']>;
  titleHasSuffix?: InputMaybe<Scalars['String']['input']>;
  titleIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  titleLT?: InputMaybe<Scalars['String']['input']>;
  titleLTE?: InputMaybe<Scalars['String']['input']>;
  titleNEQ?: InputMaybe<Scalars['String']['input']>;
  titleNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  titleNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ControllingParty = Node & {
  __typename?: 'ControllingParty';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** An edge to the controlling party individual */
  individual?: Maybe<ClientProfile>;
  notes?: Maybe<Scalars['String']['output']>;
  /** An edge to the controlling party organization */
  organization?: Maybe<ClientOrganization>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** Ordering options for ControllingParty connections */
export type ControllingPartyOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ControllingParties. */
  field: ControllingPartyOrderField;
};

/** Properties by which ControllingParty connections can be ordered. */
export enum ControllingPartyOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * ControllingPartyWhereInput is used for filtering ControllingParty objects.
 * Input was generated by ent.
 */
export type ControllingPartyWhereInput = {
  and?: InputMaybe<Array<ControllingPartyWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** individual edge predicates */
  hasIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** organization edge predicates */
  hasOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ControllingPartyWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<ControllingPartyWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

export type CoordinateInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
};

export type CorePlatformEntity = Document | Household | Proposal;

/**
 * CreateAISuggestionEvidenceInput is used for create AISuggestionEvidence object.
 * Input was generated by ent.
 */
export type CreateAiSuggestionEvidenceInput = {
  /** The page number in the document */
  pageNumber: Scalars['Int']['input'];
  /** The coordinates of the bounding region around the paragraph */
  polygon: Array<CoordinateInput>;
  suggestionID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreateAISuggestionInput is used for create AISuggestion object.
 * Input was generated by ent.
 */
export type CreateAiSuggestionInput = {
  /** Whether the suggestion was accepted or rejected */
  acceptanceStatus?: InputMaybe<AiSuggestionAcceptanceStatus>;
  /** The suggested date the beneficial owners were last updated. This should only be set if the kind is BENEFICIAL_OWNERS_AS_OF_DATE */
  beneficialOwnersAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  /** The suggested tax status for the business entity. This should only be set if the kind is BUSINESS_ENTITY_TAX_STATUS */
  businessEntityTaxStatus?: InputMaybe<AiSuggestionBusinessEntityTaxStatus>;
  clientOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  clientProfileID?: InputMaybe<Scalars['ID']['input']>;
  /** The suggested Crummey withdrawal period (in days). This should only be set if the kind is CRUMMEY_WITHDRAWAL_PERIOD_DAYS. */
  crummeyWithdrawalPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  /** The suggested DBA name. This should only be set if the kind is DOING_BUSINESS_AS_NAME */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** The suggested effective date of the entity. This should only be set if the kind is EFFECTIVE_DATE. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  evidenceIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  fileID?: InputMaybe<Scalars['ID']['input']>;
  /** The suggested FinCEN ID for the business entity. This should only be set if the kind is FINCEN_ID */
  fincenID?: InputMaybe<Scalars['String']['input']>;
  /** The suggested GST status of the entity. This should only be set if the kind is GST_STATUS. */
  gstStatus?: InputMaybe<AiSuggestionEntityGstStatus>;
  /** Only relevant if the suggestion is for a grantor/trustee/trustAdvisor/beneficiary. This indicates if the user has gone through the process to attempt to match the suggestion with a client profile/organization row. */
  hasMatched?: InputMaybe<Scalars['Boolean']['input']>;
  /** The suggested jurisdiction (state code) of the entity. This should only be set if the kind is JURISDICTION_STATE_CODE. */
  jurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  kgRootID?: InputMaybe<Scalars['ID']['input']>;
  /** Indicates the field (or group of fields) the suggestion is for. */
  kind: AiSuggestionKind;
  /** The suggested legal name of the entity. This should only be set if the kind is LEGAL_NAME. */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** The suggested tax ID (EIN) for the business entity. This should only be set if the kind is TAX_ID */
  taxID?: InputMaybe<Scalars['String']['input']>;
};

/**
 * CreateAccessAgeParameterInput is used for create AccessAgeParameter object.
 * Input was generated by ent.
 */
export type CreateAccessAgeParameterInput = {
  accessParameterID?: InputMaybe<Scalars['ID']['input']>;
  /** The end of the age requirement for this distribution */
  ageRequirementEnd?: InputMaybe<Scalars['Int']['input']>;
  /** The start of the age requirement for this distribution */
  ageRequirementStart?: InputMaybe<Scalars['Int']['input']>;
  householdID: Scalars['ID']['input'];
  /** The notes for the age access parameter */
  notes?: InputMaybe<Scalars['String']['input']>;
};

/**
 * CreateAccessParameterInput is used for create AccessParameter object.
 * Input was generated by ent.
 */
export type CreateAccessParameterInput = {
  accessAgeParameterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The notes for the access parameter */
  accessParameterNotes?: InputMaybe<Scalars['String']['input']>;
  /** The AMOUNT kind of access. Only valid when kind is AMOUNT */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  beneficiaryID?: InputMaybe<Scalars['ID']['input']>;
  /** The frequency of the access */
  frequency?: InputMaybe<AccessParameterFrequency>;
  householdID: Scalars['ID']['input'];
  /** The kind of access parameter */
  kind: AccessParameterKind;
  /** The percentage of the access. Only valid when kind is PERCENTAGE */
  percentage?: InputMaybe<Scalars['Percent']['input']>;
};

/**
 * CreateAccountInput is used for create Account object.
 * Input was generated by ent.
 */
export type CreateAccountInput = {
  addeparEntityID?: InputMaybe<Scalars['Int']['input']>;
  displayName: Scalars['String']['input'];
  initialValuationID?: InputMaybe<Scalars['ID']['input']>;
  valuationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateAddressInput is used for create Address object.
 * Input was generated by ent.
 */
export type CreateAddressInput = {
  cityName?: InputMaybe<Scalars['String']['input']>;
  clientProfileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  stateCode: Scalars['String']['input'];
  streetLineOne?: InputMaybe<Scalars['String']['input']>;
  streetLineTwo?: InputMaybe<Scalars['String']['input']>;
};

/**
 * CreateAssetClassInput is used for create AssetClass object.
 * Input was generated by ent.
 */
export type CreateAssetClassInput = {
  balanceSheetCategoryID?: InputMaybe<Scalars['ID']['input']>;
  displayName: Scalars['String']['input'];
  /** The defined growth rate for the asset class */
  growthRate?: InputMaybe<Scalars['Percent']['input']>;
  integrationAssetClassIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Whether this is a system class that cannot be deleted, renamed, or have liquidity status changed */
  isSystemClass?: InputMaybe<Scalars['Boolean']['input']>;
  liquidityStatus: AssetClassLiquidityStatus;
  /** The order in which this asset class should be displayed in the UI */
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * CreateAssetV2Input is used for create AssetV2 object.
 * Input was generated by ent.
 */
export type CreateAssetV2Input = {
  /** The name of the account that this asset is part of. */
  accountName?: InputMaybe<Scalars['String']['input']>;
  category: AssetV2Category;
  classID?: InputMaybe<Scalars['ID']['input']>;
  /** This will be set for any asset category value we get from a third-party ingested asset category. */
  customIntegrationAssetCategory?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  /** The entity ID of the integrated account that this asset is part of. */
  integratedAccountID?: InputMaybe<Scalars['Int']['input']>;
  /** The integration this asset was ingested as part of. */
  integrationType?: InputMaybe<AssetV2IntegrationType>;
  qsbsEligibility?: InputMaybe<AssetV2QsbsEligibility>;
  ticker?: InputMaybe<Scalars['String']['input']>;
  valuationID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreateAssetValuationV2Input is used for create AssetValuationV2 object.
 * Input was generated by ent.
 */
export type CreateAssetValuationV2Input = {
  accountID?: InputMaybe<Scalars['ID']['input']>;
  /** Free-form notes */
  description?: InputMaybe<Scalars['String']['input']>;
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  effectiveDate: Scalars['Time']['input'];
  /**
   * Valuations can work in two ways.
   * The first is manually specifying all the assets+values for a valuation; we tally them up.
   * The second is by specifying a valuation_amount.
   * If a valuation amount is present, we use this to compute the value of a valuation
   * and ignore the value of underlying assets, etc.
   */
  fixedValuationAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  valuationReason?: InputMaybe<AssetValuationV2ValuationReason>;
  valuationSource?: InputMaybe<AssetValuationV2ValuationSource>;
};

/**
 * CreateAssetValueV2Input is used for create AssetValueV2 object.
 * Input was generated by ent.
 */
export type CreateAssetValueV2Input = {
  assetID?: InputMaybe<Scalars['ID']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  ownedPercent?: InputMaybe<Scalars['Percent']['input']>;
  ownedValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  ownershipType: AssetValueV2OwnershipType;
  shareCount?: InputMaybe<Scalars['Decimal']['input']>;
  shareValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
};

/**
 * CreateBalanceSheetCategoryInput is used for create BalanceSheetCategory object.
 * Input was generated by ent.
 */
export type CreateBalanceSheetCategoryInput = {
  assetClassIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  displayName: Scalars['String']['input'];
  /** Whether this is a system category that cannot be deleted */
  isSystemCategory?: InputMaybe<Scalars['Boolean']['input']>;
  /** The order in which this balance sheet category should be displayed in the UI */
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<BalanceSheetCategoryType>;
};

/**
 * CreateBeneficiaryAccessParameterInput is used for create BeneficiaryAccessParameter object.
 * Input was generated by ent.
 */
export type CreateBeneficiaryAccessParameterInput = {
  /** Notes for when age is an access parameter */
  accessParameterAgeNote?: InputMaybe<Scalars['String']['input']>;
  /** Notes for when other is an access parameter */
  accessParameterOtherNote?: InputMaybe<Scalars['String']['input']>;
  /** The kind of distribution parameter */
  kind: BeneficiaryAccessParameterKind;
};

/**
 * CreateBeneficiaryDistributionInput is used for create BeneficiaryDistribution object.
 * Input was generated by ent.
 */
export type CreateBeneficiaryDistributionInput = {
  beneficiaryID?: InputMaybe<Scalars['ID']['input']>;
  /** The AMOUNT kind of distribution. Only valid when distribution_kind is AMOUNT */
  distributionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The frequency of the distribution to beneficiaries */
  distributionFrequency?: InputMaybe<BeneficiaryDistributionDistributionFrequency>;
  /** The kind of distribution for this beneficiary */
  distributionKind: BeneficiaryDistributionDistributionKind;
  distributionParameter?: InputMaybe<BeneficiaryDistributionDistributionParameter>;
  distributionParameterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The age at which the beneficiary will receive the distribution. Only valid when distribution_parameter is UPON_REACHING_AGE */
  distributionParameterUponReachingAge?: InputMaybe<Scalars['Int']['input']>;
  /** The percentage of the distribution. Only valid when distribution_kind is PERCENTAGE */
  distributionPercentage?: InputMaybe<Scalars['Percent']['input']>;
};

/**
 * CreateBeneficiaryDistributionParameterInput is used for create BeneficiaryDistributionParameter object.
 * Input was generated by ent.
 */
export type CreateBeneficiaryDistributionParameterInput = {
  /** The kind of distribution parameter for this distribution */
  kind: BeneficiaryDistributionParameterKind;
};

/**
 * CreateBeneficiaryInput is used for create Beneficiary object.
 * Input was generated by ent.
 */
export type CreateBeneficiaryInput = {
  accessParameterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiaryGratID?: InputMaybe<Scalars['ID']['input']>;
  distributionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  individualID?: InputMaybe<Scalars['ID']['input']>;
  level?: InputMaybe<BeneficiaryLevel>;
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationID?: InputMaybe<Scalars['ID']['input']>;
  powerOfAppointmentID?: InputMaybe<Scalars['ID']['input']>;
  scheduledDistributionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateBeneficiaryPowerOfAppointmentInput is used for create BeneficiaryPowerOfAppointment object.
 * Input was generated by ent.
 */
export type CreateBeneficiaryPowerOfAppointmentInput = {
  beneficiaryID?: InputMaybe<Scalars['ID']['input']>;
  householdID: Scalars['ID']['input'];
  /** The power of appointment for the beneficiary */
  power?: InputMaybe<BeneficiaryPowerOfAppointmentPower>;
  /** Notes for when other is an access parameter */
  powerOtherNote?: InputMaybe<Scalars['String']['input']>;
};

/**
 * CreateCCorpBusinessEntityInput is used for create CCorpBusinessEntity object.
 * Input was generated by ent.
 */
export type CreateCCorpBusinessEntityInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * CreateCLTProposalInput is used for create CLTProposal object.
 * Input was generated by ent.
 */
export type CreateCltProposalInput = {
  /** The amount of the annuity payout. Only valid when payout_kind is ANNUITY */
  annuityPayoutAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The cost basis of assets proposed to be transferred to this CRT */
  assetCostBasis: Scalars['CurrencyUSD']['input'];
  /** Notes about the assets proposed to be transferred to this CRT */
  assetNotes?: InputMaybe<Scalars['String']['input']>;
  /** The total value of assets proposed to be transferred to this CRT */
  assetValue: Scalars['CurrencyUSD']['input'];
  calculationMethod?: InputMaybe<CharitableTrustCalculationMethod>;
  charitableIncomeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  donorIDs: Array<Scalars['ID']['input']>;
  optimizationTarget?: InputMaybe<CharitableTrustOptimizationTarget>;
  /** The frequency of payouts for this charitable trust. */
  payoutFrequency?: InputMaybe<CharitableTrustPayoutFrequency>;
  payoutKind: CharitableTrustPayoutKind;
  /** The high end of the pre-tax return percentage assumption */
  preTaxReturnPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The low end of the pre-tax return assumption */
  preTaxReturnPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The medium pre-tax return percentage assumption */
  preTaxReturnPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  rate7520?: InputMaybe<Scalars['Percent']['input']>;
  remainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The associated tax drag for the high pre-tax return */
  taxDragPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the low pre-tax return */
  taxDragPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the medium pre-tax return */
  taxDragPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  /** Tax status for this trust */
  taxStatus: TrustTaxStatus;
  termKind: CharitableTrustTermKind;
  /** The number of years the CRT will pay out for. Only valid if term_kind is FIXED */
  termYears: Scalars['Int']['input'];
  /** The kind of unitrust. Only valid when payout_kind is UNITRUST; ignored otherwise. */
  unitrustKind?: InputMaybe<CharitableTrustUnitrustKind>;
  /** The percent of the unitrust payout. Only valid when payout_kind is UNITRUST */
  unitrustPayoutPercent?: InputMaybe<Scalars['Percent']['input']>;
  /** This is the year of analysis. If not specified, it will either default to the actuarial tables or the term_years */
  yearOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * CreateCLTTrustInput is used for create CLTTrust object.
 * Input was generated by ent.
 */
export type CreateCltTrustInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Taxable deduction that was recorded for funding this CLT */
  charitableDeduction?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  grantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  lifetimeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  remainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  successorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** If true, term_duration_years will be ignored */
  termDurationLifetime?: InputMaybe<Scalars['Boolean']['input']>;
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  terminationDate?: InputMaybe<Scalars['Time']['input']>;
  trustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateCRTProposalInput is used for create CRTProposal object.
 * Input was generated by ent.
 */
export type CreateCrtProposalInput = {
  /** The amount of the annuity payout. Only valid when payout_kind is ANNUITY */
  annuityPayoutAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The cost basis of assets proposed to be transferred to this CRT */
  assetCostBasis: Scalars['CurrencyUSD']['input'];
  /** Notes about the assets proposed to be transferred to this CRT */
  assetNotes?: InputMaybe<Scalars['String']['input']>;
  /** The total value of assets proposed to be transferred to this CRT */
  assetValue: Scalars['CurrencyUSD']['input'];
  calculationMethod?: InputMaybe<CharitableTrustCalculationMethod>;
  charitableRemainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  donorIDs: Array<Scalars['ID']['input']>;
  incomeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  optimizationTarget?: InputMaybe<CharitableTrustOptimizationTarget>;
  /** The frequency of payouts for this charitable trust. */
  payoutFrequency?: InputMaybe<CharitableTrustPayoutFrequency>;
  payoutKind: CharitableTrustPayoutKind;
  /** The high end of the pre-tax return percentage assumption */
  preTaxReturnPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The low end of the pre-tax return assumption */
  preTaxReturnPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The medium pre-tax return percentage assumption */
  preTaxReturnPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  rate7520?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the high pre-tax return */
  taxDragPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the low pre-tax return */
  taxDragPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the medium pre-tax return */
  taxDragPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  termKind: CharitableTrustTermKind;
  /** The number of years the CRT will pay out for. Only valid if term_kind is FIXED; ignored otherwise. */
  termYears: Scalars['Int']['input'];
  /** The kind of unitrust. Only valid when payout_kind is UNITRUST; ignored otherwise. */
  unitrustKind?: InputMaybe<CharitableTrustUnitrustKind>;
  /** The percent of the unitrust payout. Only valid when payout_kind is UNITRUST */
  unitrustPayoutPercent?: InputMaybe<Scalars['Percent']['input']>;
  /** This is the year of analysis. If not specified, it will either default to the actuarial tables or the term_years */
  yearOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * CreateCRTTrustInput is used for create CRTTrust object.
 * Input was generated by ent.
 */
export type CreateCrtTrustInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Taxable deduction that was recorded for funding this CRT */
  charitableDeduction?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  grantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  lifetimeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  remainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  successorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** If true, term_duration_years will be ignored */
  termDurationLifetime?: InputMaybe<Scalars['Boolean']['input']>;
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  terminationDate?: InputMaybe<Scalars['Time']['input']>;
  trustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateCashflowInput is used for create Cashflow object.
 * Input was generated by ent.
 */
export type CreateCashflowInput = {
  /** This dynamically sets the end date of this transfer to the year when both individuals die. It superceeds end_year and end_at_clients_death. Ignored if start_year is not set.  */
  afterBothDeaths?: InputMaybe<Scalars['Boolean']['input']>;
  displayName: Scalars['String']['input'];
  endAtClientsDeathID?: InputMaybe<Scalars['ID']['input']>;
  /** The year this transfer ends. Ignored if start_year is not set. */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The yearly growth percent of this transfer. Can be negative. */
  growthPercent?: InputMaybe<Scalars['Percent']['input']>;
  householdID: Scalars['ID']['input'];
  individualID?: InputMaybe<Scalars['ID']['input']>;
  /** The year this transfer starts. If start_year is not set, it is assumed to be a one time transfer happening in the current year. If start_year is set, this is a yearly recurring transfer until end_year is reached. */
  startYear?: InputMaybe<Scalars['Int']['input']>;
  /** Value of this transfer. Positive for income, negative for expense. */
  transferValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
};

/**
 * CreateClientOrganizationInput is used for create ClientOrganization object.
 * Input was generated by ent.
 */
export type CreateClientOrganizationInput = {
  addressID?: InputMaybe<Scalars['ID']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  isBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  isTrustee?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<ClientOrganizationKind>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmail?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastName?: InputMaybe<Scalars['String']['input']>;
  pointOfContactName?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhone?: InputMaybe<Scalars['String']['input']>;
  suggestedClientOrganizationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateClientPresentationInput is used for create ClientPresentation object.
 * Input was generated by ent.
 */
export type CreateClientPresentationInput = {
  coverSlideDescription?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitle?: InputMaybe<Scalars['String']['input']>;
  /** The order that the entities should be shown in the presentation */
  entityOrder: Array<Scalars['ID']['input']>;
  /** The order that the estate waterfalls should be shown in the presentation */
  estateWaterfallOrder: Array<Scalars['ID']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  /** if true, show all entities in the presentation. if false, only show the entities defined in entity_order */
  includeAllEntities?: InputMaybe<Scalars['Boolean']['input']>;
  /** if true, show all waterfalls in the presentation. if false, only show the waterfalls defined in entity_order */
  includeAllEstateWaterfalls?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  /** the ordered list of included sections in the presentation */
  sectionOrder: Array<ClientPresentationSection>;
  /** if true, include the cover slide in the presentation */
  showCoverSlide?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * CreateClientProfileInput is used for create ClientProfile object.
 * Input was generated by ent.
 */
export type CreateClientProfileInput = {
  addeparEntityID?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<CreateAddressInput>;
  addressID?: InputMaybe<Scalars['ID']['input']>;
  cashflowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  dateOfBirth?: InputMaybe<Scalars['Time']['input']>;
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  email?: InputMaybe<Scalars['String']['input']>;
  estateWaterfallFilterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstName: Scalars['String']['input'];
  householdID?: InputMaybe<Scalars['ID']['input']>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  integrationClientID?: InputMaybe<Scalars['ID']['input']>;
  /** Whether this client profile should be an eligible beneficiary for entities and gifts. */
  isBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether this client profile should be an eligible grantor/owner/other principal for entities. */
  isGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether this client_profile is one of the (at most) two primary clients on this household. */
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether this client profile should be an eligible trustee for entities. */
  isTrustee?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  lifetimeExclusionEventIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  ownedOwnershipStakeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  professionalTeamRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  relationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  suggestedClientProfileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  testamentaryEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** DEPRECATED: Use date_of_birth instead */
  yearOfBirth?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * CreateClientProfileRelationshipInput is used for create ClientProfileRelationship object.
 * Input was generated by ent.
 */
export type CreateClientProfileRelationshipInput = {
  fromClientProfileID: Scalars['ID']['input'];
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  toClientProfileID: Scalars['ID']['input'];
  /** the relationship between the 'from' ClientProfile and the 'to' ClientProfile */
  type: ClientProfileRelationshipType;
};

export type CreateClientProfileWithDetailsInput = {
  clientProfile: CreateClientProfileInput;
  relationships?: InputMaybe<Array<CreateNewClientProfileRelationshipInput>>;
};

/**
 * CreateCollaboratorInput is used for create Collaborator object.
 * Input was generated by ent.
 */
export type CreateCollaboratorInput = {
  firstName: Scalars['String']['input'];
  householdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The role of the collaborator, for display purposes only */
  kind?: InputMaybe<CollaboratorKind>;
  lastName: Scalars['String']['input'];
  /** The name of the organization this collaborator works for */
  organizationName?: InputMaybe<Scalars['String']['input']>;
  /** The professional title of the collaborator, e.g. 'Associate' */
  title?: InputMaybe<Scalars['String']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateCollaboratorWithEmailInput = {
  create: CreateCollaboratorInput;
  email: Scalars['String']['input'];
};

/**
 * CreateControllingPartyInput is used for create ControllingParty object.
 * Input was generated by ent.
 */
export type CreateControllingPartyInput = {
  individualID?: InputMaybe<Scalars['ID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreateCustodialPersonalAccountInput is used for create CustodialPersonalAccount object.
 * Input was generated by ent.
 */
export type CreateCustodialPersonalAccountInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  controllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  ownerID?: InputMaybe<Scalars['ID']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * CreateDispositionScenarioInput is used for create DispositionScenario object.
 * Input was generated by ent.
 */
export type CreateDispositionScenarioInput = {
  associatedHypotheticalWaterfallID?: InputMaybe<Scalars['ID']['input']>;
  dispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  dispositiveProvisionsTemplateID?: InputMaybe<Scalars['ID']['input']>;
  firstGrantorDeathID: Scalars['ID']['input'];
  /** The time at which the scenario was last reviewed. This can be used to indicate that a user has looked at these provisions and they are correct, even if they are empty. */
  reviewedAt?: InputMaybe<Scalars['Time']['input']>;
  secondDeathDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondDeathDispositiveProvisionsTemplateID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreateDispositiveProvisionInput is used for create DispositiveProvision object.
 * Input was generated by ent.
 */
export type CreateDispositiveProvisionInput = {
  associatedHypotheticalWaterfallID?: InputMaybe<Scalars['ID']['input']>;
  /** The AMOUNT kind of disposition. Only valid when disposition_kind is AMOUNT */
  dispositionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  dispositionKind: DispositiveProvisionDispositionKind;
  /** The order of the disposition in the list of dispositions */
  dispositionOrder: Scalars['Int']['input'];
  /** The percentage of the disposition. Only valid when disposition_kind is PERCENTAGE */
  dispositionPercentage?: InputMaybe<Scalars['Percent']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  individualID?: InputMaybe<Scalars['ID']['input']>;
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationID?: InputMaybe<Scalars['ID']['input']>;
  testamentaryEntityID?: InputMaybe<Scalars['ID']['input']>;
  transferTaxKind: DispositiveProvisionTransferTaxKind;
};

/**
 * CreateDispositiveProvisionTemplateInput is used for create DispositiveProvisionTemplate object.
 * Input was generated by ent.
 */
export type CreateDispositiveProvisionTemplateInput = {
  /** The name of the template */
  displayName: Scalars['String']['input'];
  dispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  documentID?: InputMaybe<Scalars['ID']['input']>;
  householdID: Scalars['ID']['input'];
  /** The kind of dispositive provision tempalte */
  kind?: InputMaybe<DispositiveProvisionTemplateKind>;
  /** The sub kind of dispositive provision tempalte */
  subKind?: InputMaybe<DispositiveProvisionTemplateSubKind>;
};

/**
 * CreateDocumentInput is used for create Document object.
 * Input was generated by ent.
 */
export type CreateDocumentInput = {
  /** The precedence that the suggestions from this document should take when extracting data. If this is not explicitly set, suggestions order will be inferred by uploaded date (most recent first). Only applicable for documents associated with entities. */
  aiSuggestionOrder?: InputMaybe<Scalars['Int']['input']>;
  defaultDocumentOfEntityID?: InputMaybe<Scalars['ID']['input']>;
  documentSummaryID?: InputMaybe<Scalars['ID']['input']>;
  /** Whether this document should be used for AI suggestions. Only applicable for documents associated with entities. */
  enableAiSuggestions?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  fileID: Scalars['ID']['input'];
  householdID: Scalars['ID']['input'];
  lifetimeExclusionEventIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  /** DEPRECATED: This is a spillover concept from the initial POC. */
  parsingStatus?: InputMaybe<DocumentParsingStatus>;
  type: DocumentType;
};

/**
 * CreateDocumentQuestionAnswersInput is used for create DocumentQuestionAnswers object.
 * Input was generated by ent.
 */
export type CreateDocumentQuestionAnswersInput = {
  answer: Scalars['String']['input'];
  chain: Scalars['String']['input'];
};

/**
 * CreateDocumentQuestionInput is used for create DocumentQuestion object.
 * Input was generated by ent.
 */
export type CreateDocumentQuestionInput = {
  answerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  asyncJobID?: InputMaybe<Scalars['ID']['input']>;
  documentID: Scalars['ID']['input'];
  /** Helps us determine how to parse the answer */
  kind?: InputMaybe<DocumentQuestionKind>;
  question: Scalars['String']['input'];
};

/**
 * CreateDocumentSummaryInput is used for create DocumentSummary object.
 * Input was generated by ent.
 */
export type CreateDocumentSummaryInput = {
  /** The user's feedback on the AI-generated summary. Ignore if entry_mode is USER */
  aiSummaryFeedback?: InputMaybe<Scalars['String']['input']>;
  /** The user's rating of the AI-generated summary. Ignore if entry_mode is USER */
  aiSummaryRating?: InputMaybe<DocumentSummaryAiSummaryRating>;
  documentID: Scalars['ID']['input'];
  /** Indicates if the summary content was updated by the user or generated by AI. Default: AI_AUTO */
  entryMode?: InputMaybe<DocumentSummaryEntryMode>;
  /** The most recently-edited summary for the document */
  summary: Scalars['String']['input'];
  /** The format of the summary string when displaying it to the user on the frontend. Default: MARKDOWN */
  summaryFormat?: InputMaybe<DocumentSummarySummaryFormat>;
};

/**
 * CreateDonorAdvisedFundInput is used for create DonorAdvisedFund object.
 * Input was generated by ent.
 */
export type CreateDonorAdvisedFundInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  controllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  donorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * CreateEmployeeInput is used for create Employee object.
 * Input was generated by ent.
 */
export type CreateEmployeeInput = {
  assignedHouseholdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  teamIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  teamOwnerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  userID: Scalars['ID']['input'];
};

/**
 * CreateEntityGraphViewInput is used for create EntityGraphView object.
 * Input was generated by ent.
 */
export type CreateEntityGraphViewInput = {
  displayName: Scalars['String']['input'];
  householdID: Scalars['ID']['input'];
  kind?: InputMaybe<EntityGraphViewKind>;
  /** the definitions of the nodes in the graph */
  nodeConfigurations: Array<EntityGraphNodeConfigurationInput>;
  orientation?: InputMaybe<EntityGraphViewOrientation>;
};

/**
 * CreateEntityInput is used for create Entity object.
 * Input was generated by ent.
 */
export type CreateEntityInput = {
  activityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['Int']['input']>;
  /** when true, indicates that the addepar entity this is linked to isn't owned by the grantor on addepar. this could be because the grantor on addepar is deceased or not present on Luminary. */
  addeparLinkedToNongrantorEntity?: InputMaybe<Scalars['Boolean']['input']>;
  /** how the entity got created */
  attributionSource?: InputMaybe<EntityAttributionSource>;
  cashflowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  ccorpBusinessEntityID?: InputMaybe<Scalars['ID']['input']>;
  cltTrustID?: InputMaybe<Scalars['ID']['input']>;
  crtTrustID?: InputMaybe<Scalars['ID']['input']>;
  custodialPersonalAccountID?: InputMaybe<Scalars['ID']['input']>;
  defaultDocumentID?: InputMaybe<Scalars['ID']['input']>;
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  donorAdvisedFundID?: InputMaybe<Scalars['ID']['input']>;
  emailNotificationRecordIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  entityProposalIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  estateWaterfallFilterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  gpBusinessEntityID?: InputMaybe<Scalars['ID']['input']>;
  gratTrustID?: InputMaybe<Scalars['ID']['input']>;
  householdID: Scalars['ID']['input'];
  ilitTrustID?: InputMaybe<Scalars['ID']['input']>;
  individualPersonalAccountID?: InputMaybe<Scalars['ID']['input']>;
  insurancePersonalAccountID?: InputMaybe<Scalars['ID']['input']>;
  integrationEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  irrevocableTrustID?: InputMaybe<Scalars['ID']['input']>;
  jointPersonalAccountID?: InputMaybe<Scalars['ID']['input']>;
  liabilitiesOwedIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  llcBusinessEntityID?: InputMaybe<Scalars['ID']['input']>;
  lpBusinessEntityID?: InputMaybe<Scalars['ID']['input']>;
  ownedByStakeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  ownedOwnershipStakeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  privateFoundationID?: InputMaybe<Scalars['ID']['input']>;
  qprtTrustID?: InputMaybe<Scalars['ID']['input']>;
  qualifiedTuitionPersonalAccountID?: InputMaybe<Scalars['ID']['input']>;
  retirementPersonalAccountID?: InputMaybe<Scalars['ID']['input']>;
  revocableTrustID?: InputMaybe<Scalars['ID']['input']>;
  scorpBusinessEntityID?: InputMaybe<Scalars['ID']['input']>;
  slatTrustID?: InputMaybe<Scalars['ID']['input']>;
  soleProprietorshipBusinessEntityID?: InputMaybe<Scalars['ID']['input']>;
  /** the lifecycle stage of the entity. not all stages are applicable to all entities; most go directly into ACTIVE */
  stage: EntityStage;
  stateTaxisIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  taskIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateEntityProposalInput is used for create EntityProposal object.
 * Input was generated by ent.
 */
export type CreateEntityProposalInput = {
  assetValuationProjectionType: EntityProposalAssetValuationProjectionType;
  entityID: Scalars['ID']['input'];
  proposalID: Scalars['ID']['input'];
  proposalScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** the percent value for tax drag for the entity */
  taxDrag?: InputMaybe<Scalars['Percent']['input']>;
};

/**
 * CreateEntityStateTaxInput is used for create EntityStateTax object.
 * Input was generated by ent.
 */
export type CreateEntityStateTaxInput = {
  entityID?: InputMaybe<Scalars['ID']['input']>;
  inEstateStatus: EntityInEstateStatus;
  stateCode: StateCode;
  testamentaryEntityID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreateEntityTaskInput is used for create EntityTask object.
 * Input was generated by ent.
 */
export type CreateEntityTaskInput = {
  assignedByID?: InputMaybe<Scalars['ID']['input']>;
  assignedToID?: InputMaybe<Scalars['ID']['input']>;
  /** the reason this task was cancelled. if present, the task status is computed as canceled */
  cancelledReason?: InputMaybe<Scalars['String']['input']>;
  /** the timestamp at which this task was marked as complete */
  completedAt?: InputMaybe<Scalars['Time']['input']>;
  completedByID?: InputMaybe<Scalars['ID']['input']>;
  /** the date at which this task is due */
  dueAt: Scalars['Time']['input'];
  entityID: Scalars['ID']['input'];
  ilitCrummeyLetterID?: InputMaybe<Scalars['ID']['input']>;
  loggedTransferIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  premiumPaymentID?: InputMaybe<Scalars['ID']['input']>;
  reminderEmailNotificationRecordIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  taskDependencyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  taskDependentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  type: EntityTaskType;
};

/**
 * CreateEstateTaxScenarioInput is used for create EstateTaxScenario object.
 * Input was generated by ent.
 */
export type CreateEstateTaxScenarioInput = {
  displayName: Scalars['String']['input'];
  /** The order of payout for the first death. Includes Entities and Testamentary Entities. */
  firstDeathPayoutOrder: Array<Scalars['ID']['input']>;
  firstGrantorDeathID: Scalars['ID']['input'];
  isExemptionPortable?: InputMaybe<Scalars['Boolean']['input']>;
  payoutKind: EstateTaxScenarioPayoutKind;
  /** The order of payout for the second death, only valid when there are multiple grantors. Includes Entities and Testamentary Entities. */
  secondDeathPayoutOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateEstateWaterfallHypotheticalTransferInput is used for create EstateWaterfallHypotheticalTransfer object.
 * Input was generated by ent.
 */
export type CreateEstateWaterfallHypotheticalTransferInput = {
  /** Assume transfers will grow/shrink by this percentage each year. Only valid for recurring transfers. */
  assetGrowthReturn?: InputMaybe<Scalars['Percent']['input']>;
  destinationEntityID?: InputMaybe<Scalars['ID']['input']>;
  destinationIndividualID?: InputMaybe<Scalars['ID']['input']>;
  /** The kind of destination for this transfer. */
  destinationKind: EstateWaterfallHypotheticalTransferDestinationKind;
  destinationOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  /** The year this transfer ends. Ignored if start_year is not set. */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  giftingGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If true, this is a GST gift. Only valid when transfer_tax_kind is GRANTOR_TAXABLE_GIFT. */
  isGstGift?: InputMaybe<Scalars['Boolean']['input']>;
  sourceEntityID?: InputMaybe<Scalars['ID']['input']>;
  sourceIndividualID?: InputMaybe<Scalars['ID']['input']>;
  sourceInsurancePolicyID?: InputMaybe<Scalars['ID']['input']>;
  /** The kind of the source for this transfer. */
  sourceKind: EstateWaterfallHypotheticalTransferSourceKind;
  sourceOwnedBusinessEntityID?: InputMaybe<Scalars['ID']['input']>;
  sourceOwnedEntityID?: InputMaybe<Scalars['ID']['input']>;
  sourceOwnershipStakeID?: InputMaybe<Scalars['ID']['input']>;
  /** The year this transfer starts. If start_year is not set, it is assumed to be a one time transfer happening in the current year. If start_year is set, this is a yearly recurring transfer until end_year is reached. */
  startYear?: InputMaybe<Scalars['Int']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  /** Taxable value of the transfer. If nil, it will be calculated. */
  taxableValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The order of this transfer in the list of transfers for a given source. */
  transferOrder: Scalars['Int']['input'];
  transferTaxKind: EstateWaterfallHypotheticalTransferTransferTaxKind;
  /** Value of this transfer. */
  transferValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
};

/**
 * CreateEstateWaterfallInput is used for create EstateWaterfall object.
 * Input was generated by ent.
 */
export type CreateEstateWaterfallInput = {
  /** Assume assets will grow/shrink by this percentage each year. */
  assetGrowthReturn?: InputMaybe<Scalars['Percent']['input']>;
  displayName: Scalars['String']['input'];
  /** Lifetime exemption grows by this around each year. */
  exemptionGrowthRate?: InputMaybe<Scalars['Percent']['input']>;
  filterID?: InputMaybe<Scalars['ID']['input']>;
  firstGrantorDeathID: Scalars['ID']['input'];
  /** Projected year that the first grantor dies. */
  firstGrantorDeathYear?: InputMaybe<Scalars['Int']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  hypotheticalScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  hypotheticalTransferIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** the definitions of the nodes in the graph */
  nodeConfigurations?: InputMaybe<Array<EntityGraphNodeConfigurationInput>>;
  parentID?: InputMaybe<Scalars['ID']['input']>;
  /** Projected year that the second grantor dies. Only applicable when there are two grantors. */
  secondGrantorDeathYear?: InputMaybe<Scalars['Int']['input']>;
  visualizationConfigID?: InputMaybe<Scalars['ID']['input']>;
  visualizationGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If true, exemption amount is halved at the end of 2025, and continues growing at the same rate. */
  willExemptionSunset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateEstateWaterfallOptsInput = {
  applyAutoGrouping?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * CreateEstateWaterfallVisualizationConfigInput is used for create EstateWaterfallVisualizationConfig object.
 * Input was generated by ent.
 */
export type CreateEstateWaterfallVisualizationConfigInput = {
  /** If true, always shows edge labels. */
  alwaysShowEdgeLabels?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, hides edges. */
  hideEdges?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, hides list of entities in a group. */
  hideEntitiesListInGroups?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, hides dollar values on the entity tile. */
  hideEntityValues?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, hides all tax tiles. */
  hideTaxTiles?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, shows zero dollar tiles. */
  showZeroDollarTiles?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, uses brand colors instead of luminary defaults. */
  useBrandColors?: InputMaybe<Scalars['Boolean']['input']>;
  waterfallID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreateEstateWaterfallVisualizationFilterInput is used for create EstateWaterfallVisualizationFilter object.
 * Input was generated by ent.
 */
export type CreateEstateWaterfallVisualizationFilterInput = {
  includedClientIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  includedEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  waterfallID: Scalars['ID']['input'];
};

/**
 * CreateFeatureFlagInput is used for create FeatureFlag object.
 * Input was generated by ent.
 */
export type CreateFeatureFlagInput = {
  flagDescription?: InputMaybe<Scalars['String']['input']>;
  flagName: Scalars['String']['input'];
  flagStatus?: InputMaybe<FeatureFlagFlagStatus>;
  ownedBy?: InputMaybe<Scalars['String']['input']>;
  queryRuleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateFeatureFlagQueryRuleInput is used for create FeatureFlagQueryRule object.
 * Input was generated by ent.
 */
export type CreateFeatureFlagQueryRuleInput = {
  featureFlagID: Scalars['ID']['input'];
  flagQuery: Scalars['String']['input'];
  flagVariationName: Scalars['String']['input'];
  kind?: InputMaybe<FeatureFlagQueryRuleKind>;
};

/**
 * CreateFileInput is used for create File object.
 * Input was generated by ent.
 */
export type CreateFileInput = {
  aiSuggestionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The sha256 of the file to be uploaded. We check the file integrity S3 side. */
  contentsSha256: Scalars['String']['input'];
  documentID?: InputMaybe<Scalars['ID']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  inferredFieldIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  kgRootIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  pageIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateFilePageInput is used for create FilePage object.
 * Input was generated by ent.
 */
export type CreateFilePageInput = {
  /** The angle of the page. This helps establish an orientation. */
  angle?: InputMaybe<Scalars['Float']['input']>;
  fileID?: InputMaybe<Scalars['ID']['input']>;
  /** The height of the page. */
  height?: InputMaybe<Scalars['Float']['input']>;
  /** The page number of the file. 1-indexed. */
  pageNumber: Scalars['Int']['input'];
  /** The unit of measurement for the page. Typically 'inch'. */
  unit?: InputMaybe<Scalars['String']['input']>;
  /** The width of the page. */
  width?: InputMaybe<Scalars['Float']['input']>;
};

/**
 * CreateGPBusinessEntityInput is used for create GPBusinessEntity object.
 * Input was generated by ent.
 */
export type CreateGpBusinessEntityInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * CreateGRATAnnuityV2Input is used for create GRATAnnuityV2 object.
 * Input was generated by ent.
 */
export type CreateGratAnnuityV2Input = {
  associatedAssetValuationID?: InputMaybe<Scalars['ID']['input']>;
  associatedTaskID?: InputMaybe<Scalars['ID']['input']>;
  /** if the annuity payment failed, this is the amount that was actually paid */
  failedAnnuityPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** the percent of the initial funding value due to be paid with this annuity */
  initialFundingValuePercent: Scalars['Percent']['input'];
  /** the date the annuity was paid on */
  paidOn?: InputMaybe<Scalars['Time']['input']>;
  /** the term duration of the GRAT that this annuity is associated with */
  termDurationYears: Scalars['Int']['input'];
  /** which 1-indexed annuity payment this represents; if a 2, represents the second annuity payment */
  yearOfTerm: Scalars['Int']['input'];
};

/**
 * CreateGRATProposalScenarioInput is used for create GRATProposalScenario object.
 * Input was generated by ent.
 */
export type CreateGratProposalScenarioInput = {
  inTermValuationType?: InputMaybe<GratProposalScenarioInTermValuationType>;
  /** the percent value for projected return after the lifetime of the GRAT */
  projectedAnnualReturnAfterTerm?: InputMaybe<Scalars['Percent']['input']>;
  /** the percent value for projected return during the lifetime of the GRAT */
  projectedAnnualReturnThroughTerm?: InputMaybe<Scalars['Percent']['input']>;
  projectedMarketValueAtTerm?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** the default selected time period for this projection */
  projectionTimePeriodYears: Scalars['Int']['input'];
};

/**
 * CreateGRATTrustInput is used for create GRATTrust object.
 * Input was generated by ent.
 */
export type CreateGratTrustInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** the percent by which the annuity should increase each year in a variable annuity amount scenario */
  annuityAnnualIncreasePercent?: InputMaybe<Scalars['Percent']['input']>;
  annuityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  distributionAssetValuationID?: InputMaybe<Scalars['ID']['input']>;
  /** the date that the trust remainder was distributed */
  distributionDate?: InputMaybe<Scalars['Time']['input']>;
  downsideThresholdPercent?: InputMaybe<Scalars['Percent']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  grantorID?: InputMaybe<Scalars['ID']['input']>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** notes from the advisor team to the implementing attorney */
  implementationNotesToAttorney?: InputMaybe<Scalars['String']['input']>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  projectedMarketValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedRateOfReturn?: InputMaybe<Scalars['Percent']['input']>;
  projectedSharePrice?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  rollingPeriodYears?: InputMaybe<Scalars['Int']['input']>;
  successorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** taxable gift that the advisor is trying to achieve; should be used as an input for annuities calcs */
  targetTaxableGift?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** The actual taxable gift that was recorded for this GRAT */
  taxableGift?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  trustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If true, the upside threshold value is used for the GRAT substitution threshold instead of the upside threshold percent. */
  upsideAsTargetSurplus?: InputMaybe<Scalars['Boolean']['input']>;
  upsideThresholdPercent?: InputMaybe<Scalars['Percent']['input']>;
  upsideThresholdValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
};

/**
 * CreateGiftingProposalCashFlowInput is used for create GiftingProposalCashFlow object.
 * Input was generated by ent.
 */
export type CreateGiftingProposalCashFlowInput = {
  /** Cash flow amount */
  amount: Scalars['CurrencyUSD']['input'];
  /** Whether this cash flow occurs every year or only once */
  annuallyRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the cash flow is an income or expense */
  cashFlowType: GiftingProposalCashFlowCashFlowType;
  /** the user-friendly name of this cash flow */
  displayName: Scalars['String']['input'];
  /** If recurring, the year this cash flow ends. If not recurring, this field is not set. */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  /** If recurring, the percentage that the cash flow amount grows each year */
  growthPercentage?: InputMaybe<Scalars['Percent']['input']>;
  /** The order to display cash flows in the UI */
  order: Scalars['Int']['input'];
  /** If recurring, the year this cash flow starts. If not recurring, the year this cash flow occurs. */
  startYear: Scalars['Int']['input'];
};

/**
 * CreateGiftingProposalGiftInput is used for create GiftingProposalGift object.
 * Input was generated by ent.
 */
export type CreateGiftingProposalGiftInput = {
  /** Gift amount */
  amount: Scalars['CurrencyUSD']['input'];
  /** Whether this gift occurs every year or only once */
  annuallyRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, gift amount is discounted for gift tax purposes */
  discount?: InputMaybe<Scalars['Boolean']['input']>;
  /** If discount is true, the discount percentage applied to the gift amount */
  discountPercentage?: InputMaybe<Scalars['Percent']['input']>;
  /** the user-friendly name of this gifting scenario */
  displayName: Scalars['String']['input'];
  /** If recurring, the year this gift ends. If not recurring, this field is not set. */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  /** If recurring, the percentage that the gift amount grows each year */
  growthPercentage?: InputMaybe<Scalars['Percent']['input']>;
  /** If true, gift is taxable, and sender will be one or both grantors. If false, can be annual exclusion, or charitable. */
  isTaxable: Scalars['Boolean']['input'];
  /** Will be null (or ignored) if gift is taxable. */
  nonTaxableGiftType?: InputMaybe<GiftingProposalGiftNonTaxableGiftType>;
  /** The order to display, and process the gifts when simulating the proposal scenarios */
  order: Scalars['Int']['input'];
  recipientClientOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  recipientClientProfileID?: InputMaybe<Scalars['ID']['input']>;
  /** The kind of recipient for this gift. Either one of the modeled out-of-estate portfolios, an individual, or an organization. */
  recipientKind?: InputMaybe<GiftingProposalGiftRecipientKind>;
  recipientPortfolioID?: InputMaybe<Scalars['ID']['input']>;
  senderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If recurring, the year this gift starts. If not recurring, the year this gift occurs. */
  startYear: Scalars['Int']['input'];
};

/**
 * CreateGiftingProposalGiftSenderInput is used for create GiftingProposalGiftSender object.
 * Input was generated by ent.
 */
export type CreateGiftingProposalGiftSenderInput = {
  grantorID: Scalars['ID']['input'];
};

/**
 * CreateGiftingProposalInput is used for create GiftingProposal object.
 * Input was generated by ent.
 */
export type CreateGiftingProposalInput = {
  baseCashFlowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  defaultGiftingScenarioID?: InputMaybe<Scalars['ID']['input']>;
  /** Gift exemption grows by this amount each year. */
  exemptionGrowthRate?: InputMaybe<Scalars['Percent']['input']>;
  /** If true, exemption amount is halved at the end of 2025, then continues growing */
  exemptionSunsets?: InputMaybe<Scalars['Boolean']['input']>;
  giftingScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The total value of all assets in the client's estate */
  inEstatePortfolioValue: Scalars['CurrencyUSD']['input'];
  /** number of years to run the gifting analysis */
  lengthOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
  outOfEstatePortfolioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The high end of the pre-tax return percentage assumption */
  preTaxReturnPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The low end of the pre-tax return assumption */
  preTaxReturnPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The medium pre-tax return percentage assumption */
  preTaxReturnPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  /** The selected pre-tax return (and tax drag) category. Used when computing projections for scenarios. */
  selectedPreTaxReturnCategory?: InputMaybe<GiftingProposalSelectedPreTaxReturnCategory>;
  /** The selected year of analysis. Defaults to end year. Used when computing projections for scenarios. */
  selectedYearOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
  /** If true, show after estate tax */
  showAfterEstateTax?: InputMaybe<Scalars['Boolean']['input']>;
  /** Status of the gifting proposal */
  status?: InputMaybe<GiftingProposalStatus>;
  /** The associated tax drag for the high pre-tax return */
  taxDragPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the low pre-tax return */
  taxDragPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the medium pre-tax return */
  taxDragPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
};

/**
 * CreateGiftingProposalPortfolioInput is used for create GiftingProposalPortfolio object.
 * Input was generated by ent.
 */
export type CreateGiftingProposalPortfolioInput = {
  /** the user-friendly name of the out-of-estate portfolio */
  displayName: Scalars['String']['input'];
  giftingProposalID?: InputMaybe<Scalars['ID']['input']>;
  /** The order to display portfolios in the UI */
  order: Scalars['Int']['input'];
  /** Portfolio type */
  portfolioType: GiftingProposalPortfolioPortfolioType;
  recipientOfIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The starting value of the out-of-estate portfolio */
  startingAssetValue: Scalars['CurrencyUSD']['input'];
};

/**
 * CreateGiftingProposalScenarioInput is used for create GiftingProposalScenario object.
 * Input was generated by ent.
 */
export type CreateGiftingProposalScenarioInput = {
  /** the user-friendly name of this gifting scenario */
  displayName: Scalars['String']['input'];
  /** The order to display scenarios in the UI */
  order: Scalars['Int']['input'];
  scenarioCashFlowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  scenarioGiftIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateGraphVisualizationGroupInput is used for create GraphVisualizationGroup object.
 * Input was generated by ent.
 */
export type CreateGraphVisualizationGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** An optional namespace for this group. Can correspond to sections in the Graph Visualization. */
  namespace?: InputMaybe<Scalars['String']['input']>;
  /** The IDs of the nodes in this group. */
  nodeIds: Array<Scalars['ID']['input']>;
};

/**
 * CreateHouseholdInput is used for create Household object.
 * Input was generated by ent.
 */
export type CreateHouseholdInput = {
  activityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  assignedEmployeeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  assignedTeamIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  balanceSheetViewConfigurationID?: InputMaybe<Scalars['ID']['input']>;
  /** whether this client is billable or not. if not BILLABLE, this client's entities should be excluding from customer billing */
  billableKind?: InputMaybe<HouseholdBillableKind>;
  clientOrganizationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clientProfileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  collaboratorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  entityGraphViewIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  entityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  estateTaxScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  estateWaterfallIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  integrationClientID?: InputMaybe<Scalars['ID']['input']>;
  liabilityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  onboardingID?: InputMaybe<Scalars['ID']['input']>;
  onboardingKgRootIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  presentationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  primaryRelationshipOwnerID: Scalars['ID']['input'];
  profiles?: InputMaybe<Array<CreateClientProfileWithDetailsInput>>;
  proposalIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  subBrandID?: InputMaybe<Scalars['ID']['input']>;
  testamentaryEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateHouseholdOnboardingInput is used for create HouseholdOnboarding object.
 * Input was generated by ent.
 */
export type CreateHouseholdOnboardingInput = {
  /** the time at which the onboarding was completed or dismissed */
  completedAt?: InputMaybe<Scalars['Time']['input']>;
  /** the method by which the onboarding was completed */
  completionMethod?: InputMaybe<HouseholdOnboardingCompletionMethod>;
  householdID: Scalars['ID']['input'];
  stepIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateHouseholdOnboardingStepInput is used for create HouseholdOnboardingStep object.
 * Input was generated by ent.
 */
export type CreateHouseholdOnboardingStepInput = {
  /** the time at which the onboarding was completed or dismissed */
  completedAt?: InputMaybe<Scalars['Time']['input']>;
  /** which step is represented */
  kind: HouseholdOnboardingStepKind;
};

/**
 * CreateHypotheticalWaterfallDispositiveProvisionTemplateInput is used for create HypotheticalWaterfallDispositiveProvisionTemplate object.
 * Input was generated by ent.
 */
export type CreateHypotheticalWaterfallDispositiveProvisionTemplateInput = {
  associatedHypotheticalWaterfallID: Scalars['ID']['input'];
  dispositiveProvisionTemplateID: Scalars['ID']['input'];
  householdID: Scalars['ID']['input'];
  testamentaryEntityID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreateILITCrummeyLetterInput is used for create ILITCrummeyLetter object.
 * Input was generated by ent.
 */
export type CreateIlitCrummeyLetterInput = {
  associatedTaskID?: InputMaybe<Scalars['ID']['input']>;
  crummeyWithdrawalPeriodDays: Scalars['Int']['input'];
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  ilitTrustID?: InputMaybe<Scalars['ID']['input']>;
  recipientID?: InputMaybe<Scalars['ID']['input']>;
  sendBefore?: InputMaybe<Scalars['Time']['input']>;
  sentOn?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * CreateILITTrustInput is used for create ILITTrust object.
 * Input was generated by ent.
 */
export type CreateIlitTrustInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  crummeyWithdrawalPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  grantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  successorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  trustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateIndividualPersonalAccountInput is used for create IndividualPersonalAccount object.
 * Input was generated by ent.
 */
export type CreateIndividualPersonalAccountInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  controllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  ownerID?: InputMaybe<Scalars['ID']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * CreateInferredFieldEvidenceInput is used for create InferredFieldEvidence object.
 * Input was generated by ent.
 */
export type CreateInferredFieldEvidenceInput = {
  inferredFieldID?: InputMaybe<Scalars['ID']['input']>;
  /** The page (in the file) that this evidence is for */
  pageNumber: Scalars['Int']['input'];
  /** the polygon that was drawn to infer the field */
  polygon: Array<CoordinateInput>;
};

/**
 * CreateInferredFieldInput is used for create InferredField object.
 * Input was generated by ent.
 */
export type CreateInferredFieldInput = {
  /** Whether the user has accepted or rejected the inferred field */
  acceptanceStatus?: InputMaybe<InferredFieldAcceptanceStatus>;
  /** When the user set the acceptance status */
  acceptanceStatusSetAt?: InputMaybe<Scalars['Time']['input']>;
  evidenceIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The name of the field (db column) that we inferred for the `target_id` object. If absent, evidence is for the entire object. */
  fieldName?: InputMaybe<Scalars['String']['input']>;
  fileID: Scalars['ID']['input'];
  /** The object we inferred the field for */
  targetID: Scalars['String']['input'];
};

/**
 * CreateInsurancePersonalAccountInput is used for create InsurancePersonalAccount object.
 * Input was generated by ent.
 */
export type CreateInsurancePersonalAccountInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  controllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  ownerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * CreateInsurancePolicyInput is used for create InsurancePolicy object.
 * Input was generated by ent.
 */
export type CreateInsurancePolicyInput = {
  carrierName?: InputMaybe<Scalars['String']['input']>;
  /** Only applicable for non-term policies */
  cashValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  cashValueDate?: InputMaybe<Scalars['Time']['input']>;
  /** Only applicable for term policies */
  conversionDate?: InputMaybe<Scalars['Time']['input']>;
  deathBenefitAmount: Scalars['CurrencyUSD']['input'];
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  initialPremiumDueDate?: InputMaybe<Scalars['Time']['input']>;
  kind: InsurancePolicyKind;
  /** Only applicable for non-term policies */
  loanBalanceOutstanding?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  policyHolderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  premiumAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumFrequency?: InputMaybe<InsurancePolicyPremiumFrequency>;
  startDate: Scalars['Time']['input'];
  /** Only applicable for non-term policies. Allows multiple holders. */
  survivorship?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only applicable for term policies */
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * CreateInsurancePremiumPaymentInput is used for create InsurancePremiumPayment object.
 * Input was generated by ent.
 */
export type CreateInsurancePremiumPaymentInput = {
  associatedTaskID?: InputMaybe<Scalars['ID']['input']>;
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  dueOn?: InputMaybe<Scalars['Time']['input']>;
  paidOn?: InputMaybe<Scalars['Time']['input']>;
  policyID?: InputMaybe<Scalars['ID']['input']>;
  premiumAmount: Scalars['CurrencyUSD']['input'];
};

/**
 * CreateIntegrationAssetClassInput is used for create IntegrationAssetClass object.
 * Input was generated by ent.
 */
export type CreateIntegrationAssetClassInput = {
  assetClassID?: InputMaybe<Scalars['ID']['input']>;
  /** We use this to map this integration_asset_class to the provider's asset class name. This is imperfect, because if someone updates a name then we will make a new asset class and reconnect it. */
  integrationName: Scalars['String']['input'];
  provider: IntegrationAssetClassProvider;
};

/**
 * CreateIntegrationConfigurationInput is used for create IntegrationConfiguration object.
 * Input was generated by ent.
 */
export type CreateIntegrationConfigurationInput = {
  /** The client ID for the Addepar OAuth app. */
  addeparClientID?: InputMaybe<Scalars['String']['input']>;
  /** The client Secret for the Addepar OAuth app. */
  addeparClientSecret?: InputMaybe<Scalars['String']['input']>;
  addeparClientType?: InputMaybe<IntegrationConfigurationAddeparClientType>;
  /** ID for Addepar account. */
  addeparFirmID?: InputMaybe<Scalars['String']['input']>;
  /** The subdomain of the Addepar instance to connect to. */
  addeparSubdomain?: InputMaybe<Scalars['String']['input']>;
  addeparTokenID?: InputMaybe<Scalars['ID']['input']>;
  /** Whether the tenant expects to use Black Diamond. We use this to expose prompts to users to authenticate to Black Diamond. */
  blackDiamondEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user-defined name for the source of the file valuation import. Exposed alongside the valuation to show where it came from. */
  csvValuationImportName?: InputMaybe<Scalars['String']['input']>;
};

/**
 * CreateIrrevocableTrustInput is used for create IrrevocableTrust object.
 * Input was generated by ent.
 */
export type CreateIrrevocableTrustInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  grantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  successorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  trustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateJointPersonalAccountInput is used for create JointPersonalAccount object.
 * Input was generated by ent.
 */
export type CreateJointPersonalAccountInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  controllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  ownerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  type?: InputMaybe<JointPersonalAccountType>;
};

/**
 * CreateKGRootInput is used for create KGRoot object.
 * Input was generated by ent.
 */
export type CreateKgRootInput = {
  aiSuggestionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  asyncJobID?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdID: Scalars['ID']['input'];
  nodeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The status of the construction of this KGRoot. It will be COMPLETE when the KGNodes have all been generated for the associated files and merged into the root. */
  status?: InputMaybe<KgRootStatus>;
};

/**
 * CreateKeyPersonInput is used for create KeyPerson object.
 * Input was generated by ent.
 */
export type CreateKeyPersonInput = {
  entityID?: InputMaybe<Scalars['ID']['input']>;
  individualID?: InputMaybe<Scalars['ID']['input']>;
  organizationID?: InputMaybe<Scalars['ID']['input']>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateKeyPersonRoleInput is used for create KeyPersonRole object.
 * Input was generated by ent.
 */
export type CreateKeyPersonRoleInput = {
  kind?: InputMaybe<KeyPersonRoleKind>;
};

/**
 * CreateLLCBusinessEntityInput is used for create LLCBusinessEntity object.
 * Input was generated by ent.
 */
export type CreateLlcBusinessEntityInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * CreateLPBusinessEntityInput is used for create LPBusinessEntity object.
 * Input was generated by ent.
 */
export type CreateLpBusinessEntityInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * CreateLiabilityInput is used for create Liability object.
 * Input was generated by ent.
 */
export type CreateLiabilityInput = {
  currentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  currentAmountAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  displayName: Scalars['String']['input'];
  finalPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  initialAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaid?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestRate?: InputMaybe<Scalars['Percent']['input']>;
  paymentFrequency?: InputMaybe<LiabilityPaymentFrequency>;
  paymentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  paymentKind?: InputMaybe<LiabilityPaymentKind>;
  recipientEntityID?: InputMaybe<Scalars['ID']['input']>;
  recurringPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  sourceClientOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  sourceClientProfileID?: InputMaybe<Scalars['ID']['input']>;
  sourceEntityID?: InputMaybe<Scalars['ID']['input']>;
  termEnd?: InputMaybe<Scalars['Time']['input']>;
  termStart?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * CreateLifetimeExclusionEventInput is used for create LifetimeExclusionEvent object.
 * Input was generated by ent.
 */
export type CreateLifetimeExclusionEventInput = {
  /** The amount of annual exclusion this gift used */
  annualExclusionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  associatedTaskID?: InputMaybe<Scalars['ID']['input']>;
  benefitOfID?: InputMaybe<Scalars['ID']['input']>;
  /** Amount asset was discounted for exclusion */
  discountAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The date of gifting or when the adjustment was recorded */
  eventDate: Scalars['Time']['input'];
  /** The amount gifted to the recipient */
  giftAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  giftCategory?: InputMaybe<LifetimeExclusionEventGiftCategory>;
  grantorID: Scalars['ID']['input'];
  /** The amount of GST exclusion this gift used */
  gstExclusionAmount: Scalars['CurrencyUSD']['input'];
  kind: LifetimeExclusionEventKind;
  /** The amount of lifetime exclusion this gift used */
  lifetimeExclusionAmount: Scalars['CurrencyUSD']['input'];
  loggedTransferID?: InputMaybe<Scalars['ID']['input']>;
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  recipientID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreateLoggedTransferInput is used for create LoggedTransfer object.
 * Input was generated by ent.
 */
export type CreateLoggedTransferInput = {
  /** The amount of logged transfer. */
  amount: Scalars['CurrencyUSD']['input'];
  associatedTaskID?: InputMaybe<Scalars['ID']['input']>;
  documentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  lifetimeExclusionEventIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Custom purpose if "Other" purpose is selected. */
  otherPurposeDescription?: InputMaybe<Scalars['String']['input']>;
  /** Purpose of transfer */
  purpose?: InputMaybe<LoggedTransferPurpose>;
  receivingEntityID?: InputMaybe<Scalars['ID']['input']>;
  receivingGrantorID?: InputMaybe<Scalars['ID']['input']>;
  receivingOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  sourceEntityID?: InputMaybe<Scalars['ID']['input']>;
  sourceGrantorID?: InputMaybe<Scalars['ID']['input']>;
  sourceOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  transactionDate: Scalars['Time']['input'];
};

export type CreateNewClientProfileRelationshipInput = {
  toClientProfileID: Scalars['ID']['input'];
  type: ClientProfileRelationshipType;
};

/**
 * CreateOwnershipStakeInput is used for create OwnershipStake object.
 * Input was generated by ent.
 */
export type CreateOwnershipStakeInput = {
  ownedEntityID?: InputMaybe<Scalars['ID']['input']>;
  /** The percentage ownership of the underlying entity. */
  ownershipPercentage?: InputMaybe<Scalars['Percent']['input']>;
  owningEntityID?: InputMaybe<Scalars['ID']['input']>;
  owningIndividualID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreatePrincipalInput is used for create Principal object.
 * Input was generated by ent.
 */
export type CreatePrincipalInput = {
  individualID?: InputMaybe<Scalars['ID']['input']>;
  /**
   * The percentage ownership of the underlying entity, funded by these principals.
   * 			Typically only valid for multiple ownership, non-trust non-charitable entities (eg: LLC, LP, ...)
   */
  ownershipPercentage?: InputMaybe<Scalars['Percent']['input']>;
};

/**
 * CreatePrivateFoundationInput is used for create PrivateFoundation object.
 * Input was generated by ent.
 */
export type CreatePrivateFoundationInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  controllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  donorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * CreateProfessionalTeamRoleInput is used for create ProfessionalTeamRole object.
 * Input was generated by ent.
 */
export type CreateProfessionalTeamRoleInput = {
  associatedClientIndividualID?: InputMaybe<Scalars['ID']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  individualID?: InputMaybe<Scalars['ID']['input']>;
  kind: ProfessionalTeamRoleKind;
  /** If the kind is OTHER, this is the custom role name */
  otherRoleName?: InputMaybe<Scalars['String']['input']>;
  /** If the kind is POWER_OF_ATTORNEY, this is the type of power of attorney */
  powerOfAttorneyKind?: InputMaybe<ProfessionalTeamRolePowerOfAttorneyKind>;
};

/**
 * CreateProposalInput is used for create Proposal object.
 * Input was generated by ent.
 */
export type CreateProposalInput = {
  /** notes to the client that accompany the proposal */
  clientNotes: Scalars['String']['input'];
  cltProposalID?: InputMaybe<Scalars['ID']['input']>;
  crtProposalID?: InputMaybe<Scalars['ID']['input']>;
  /** the user-friendly name of the proposal */
  displayName: Scalars['String']['input'];
  entityProposalIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  giftingProposalID?: InputMaybe<Scalars['ID']['input']>;
  householdID: Scalars['ID']['input'];
  /** whether or not to include a "cumulative view" tab on the proposal */
  includeCumulativeView: Scalars['Boolean']['input'];
};

/**
 * CreateProposalScenarioInput is used for create ProposalScenario object.
 * Input was generated by ent.
 */
export type CreateProposalScenarioInput = {
  gratProposalScenarioID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreateQPRTTrustInput is used for create QPRTTrust object.
 * Input was generated by ent.
 */
export type CreateQprtTrustInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  grantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  successorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** The actual taxable gift that was recorded for this QPRT */
  taxableGift?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  terminationDate?: InputMaybe<Scalars['Time']['input']>;
  trustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateQualifiedTuitionPersonalAccountInput is used for create QualifiedTuitionPersonalAccount object.
 * Input was generated by ent.
 */
export type CreateQualifiedTuitionPersonalAccountInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  controllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  ownerID?: InputMaybe<Scalars['ID']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * CreateRetirementPersonalAccountInput is used for create RetirementPersonalAccount object.
 * Input was generated by ent.
 */
export type CreateRetirementPersonalAccountInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  controllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  ownerID?: InputMaybe<Scalars['ID']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  type?: InputMaybe<RetirementPersonalAccountType>;
};

/**
 * CreateRevocableTrustInput is used for create RevocableTrust object.
 * Input was generated by ent.
 */
export type CreateRevocableTrustInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  grantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  successorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  trustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateSCorpBusinessEntityInput is used for create SCorpBusinessEntity object.
 * Input was generated by ent.
 */
export type CreateSCorpBusinessEntityInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * CreateSLATTrustInput is used for create SLATTrust object.
 * Input was generated by ent.
 */
export type CreateSlatTrustInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  grantorID?: InputMaybe<Scalars['ID']['input']>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  lifetimeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  policyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  remainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  successorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  trustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateSSOConnectionInput is used for create SSOConnection object.
 * Input was generated by ent.
 */
export type CreateSsoConnectionInput = {
  /** A list of email domains that are allowed to use this SSO connection. Do not include the @ symbol. */
  boundEmailDomains: Array<Scalars['String']['input']>;
  configuredTenantIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The name of the SSO connection. */
  displayName: Scalars['String']['input'];
  /** The ID of the WorkOS organization this SSO connection corresponds to. */
  workosOrganizationID: Scalars['String']['input'];
};

/**
 * CreateScheduledDistributionInput is used for create ScheduledDistribution object.
 * Input was generated by ent.
 */
export type CreateScheduledDistributionInput = {
  /** The end of the age requirement for this distribution */
  ageRequirementEnd?: InputMaybe<Scalars['Int']['input']>;
  /** The start of the age requirement for this distribution */
  ageRequirementStart?: InputMaybe<Scalars['Int']['input']>;
  /** The AMOUNT kind of distribution. Only valid when kind is AMOUNT */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  beneficiaryID?: InputMaybe<Scalars['ID']['input']>;
  /** The frequency of the distribution to beneficiaries */
  frequency?: InputMaybe<ScheduledDistributionFrequency>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  /** The kind of scheduled distribution */
  kind: ScheduledDistributionKind;
  /** The percentage of the distribution. Only valid when kind is PERCENTAGE */
  percentage?: InputMaybe<Scalars['Percent']['input']>;
  /** The notes for the scheduled distribution */
  scheduledDistributionNotes?: InputMaybe<Scalars['String']['input']>;
};

/**
 * CreateSoleProprietorshipBusinessEntityInput is used for create SoleProprietorshipBusinessEntity object.
 * Input was generated by ent.
 */
export type CreateSoleProprietorshipBusinessEntityInput = {
  accountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * CreateSubBrandInput is used for create SubBrand object.
 * Input was generated by ent.
 */
export type CreateSubBrandInput = {
  buttonsLinkColor: Scalars['String']['input'];
  darkSquareLogoURL?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURL?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColor: Scalars['String']['input'];
  dataVisualizationSecondaryColor: Scalars['String']['input'];
  dataVisualizationTertiaryColor?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  householdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  logoURL: Scalars['String']['input'];
  primaryColor: Scalars['String']['input'];
  secondaryColor: Scalars['String']['input'];
};

/**
 * CreateTeamInput is used for create Team object.
 * Input was generated by ent.
 */
export type CreateTeamInput = {
  assignedHouseholdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  employeeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  name: Scalars['String']['input'];
  ownerID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * CreateTenantBrandingInput is used for create TenantBranding object.
 * Input was generated by ent.
 */
export type CreateTenantBrandingInput = {
  buttonsLinkColor: Scalars['String']['input'];
  darkSquareLogoURL?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURL?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColor: Scalars['String']['input'];
  dataVisualizationSecondaryColor: Scalars['String']['input'];
  dataVisualizationTertiaryColor?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  logoURL: Scalars['String']['input'];
  primaryColor: Scalars['String']['input'];
  secondaryColor: Scalars['String']['input'];
  tenantID: Scalars['ID']['input'];
};

export type CreateTenantBrandingLogoInput = {
  contentsSha256: Scalars['String']['input'];
};

/**
 * CreateTenantInput is used for create Tenant object.
 * Input was generated by ent.
 */
export type CreateTenantInput = {
  brandingID?: InputMaybe<Scalars['ID']['input']>;
  /** WARNING: FOR ANALYTICS/REPORTING ONLY. NOT FOR BUSINESS LOGIC. The date the contract started. */
  contractStartDate?: InputMaybe<Scalars['Time']['input']>;
  kind: TenantKind;
  /** NOTE: Name is only used to initialize the tenant name. It is not used for display purposes. Use the TenantBranding.DisplayName property instead. */
  name: Scalars['String']['input'];
  ssoConnectionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  subdomain: Scalars['String']['input'];
  /** WARNING: FOR ANALYTICS/REPORTING ONLY. NOT FOR BUSINESS LOGIC. This is the bucket that the tenant falls into for usage tracking. */
  usageBucket?: InputMaybe<TenantUsageBucket>;
};

/**
 * CreateTenantSSOConnectionInput is used for create TenantSSOConnection object.
 * Input was generated by ent.
 */
export type CreateTenantSsoConnectionInput = {
  enableJIT?: InputMaybe<Scalars['Boolean']['input']>;
  /** The time at which JIT was enabled for this tenant. If null, JIT is disabled. JIT means that users will be created on the fly when they sign in with SSO. */
  jitEnabledAt?: InputMaybe<Scalars['Time']['input']>;
  ssoConnectionID: Scalars['ID']['input'];
  tenantID: Scalars['ID']['input'];
};

/**
 * CreateTestamentaryEntityInput is used for create TestamentaryEntity object.
 * Input was generated by ent.
 */
export type CreateTestamentaryEntityInput = {
  beneficiariesAfterBothGrantorDeathIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  benefitsFromIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  controllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Description for this testamentary entity */
  description?: InputMaybe<Scalars['String']['input']>;
  displayName: Scalars['String']['input'];
  dispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  dispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  dispositiveProvisionsTemplateID?: InputMaybe<Scalars['ID']['input']>;
  grantorDeathID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  hypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  hypotheticalDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  hypotheticalDispositiveProvisionsTemplateIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Type of testamentary entity. */
  kind: TestamentaryEntityKind;
  /** The time at which the scenario was last reviewed. This can be used to indicate that a user has looked at these provisions and they are correct, even if they are empty. */
  reviewedAt?: InputMaybe<Scalars['Time']['input']>;
  stateTaxID?: InputMaybe<Scalars['ID']['input']>;
  successorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  survivingSpouseStateInEstateStatus?: InputMaybe<EntityInEstateStatus>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  trustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  trusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateTrustAdvisorInput is used for create TrustAdvisor object.
 * Input was generated by ent.
 */
export type CreateTrustAdvisorInput = {
  individualID?: InputMaybe<Scalars['ID']['input']>;
  /** The level of this trust advisor. */
  level: TrustAdvisorLevel;
  /** A Note about this trust advisor. Only relevant if the level is "OTHER". */
  note?: InputMaybe<Scalars['String']['input']>;
  organizationID?: InputMaybe<Scalars['ID']['input']>;
  roleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * CreateTrustAdvisorRoleInput is used for create TrustAdvisorRole object.
 * Input was generated by ent.
 */
export type CreateTrustAdvisorRoleInput = {
  /** The role of this trust advisor. */
  kind: TrustAdvisorRoleKind;
};

/**
 * CreateTrusteeDutyInput is used for create TrusteeDuty object.
 * Input was generated by ent.
 */
export type CreateTrusteeDutyInput = {
  /** The kind of duty for this trustee */
  kind: TrusteeDutyKind;
};

/**
 * CreateTrusteeInput is used for create Trustee object.
 * Input was generated by ent.
 */
export type CreateTrusteeInput = {
  dutyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  individualID?: InputMaybe<Scalars['ID']['input']>;
  level?: InputMaybe<TrusteeLevel>;
  notes?: InputMaybe<Scalars['String']['input']>;
  organizationID?: InputMaybe<Scalars['ID']['input']>;
  trusteeCategory?: InputMaybe<TrusteeTrusteeCategory>;
};

/**
 * CreateUserInput is used for create User object.
 * Input was generated by ent.
 */
export type CreateUserInput = {
  assignedTaskIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  asyncJobIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  collaboratorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  email: Scalars['String']['input'];
  employeeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

/**
 * CreateUserNotificationConfigurationInput is used for create UserNotificationConfiguration object.
 * Input was generated by ent.
 */
export type CreateUserNotificationConfigurationInput = {
  /** Whether or not the user has enabled receiving reminder email notifications. */
  allRemainderEmailsDisabled: Scalars['Boolean']['input'];
  receivingUserID: Scalars['ID']['input'];
};

export type CustodialPersonalAccount = EntitySubtype & Named & Node & PersonalAccount & {
  __typename?: 'CustodialPersonalAccount';
  accounts?: Maybe<Array<Account>>;
  addeparEntityID?: Maybe<Scalars['String']['output']>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  controllingParties?: Maybe<Array<ControllingParty>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this personal account */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in entities that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  owner?: Maybe<Principal>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<NonTrustEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type CustodialPersonalAccountHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type CustodialPersonalAccountConnection = {
  __typename?: 'CustodialPersonalAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustodialPersonalAccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CustodialPersonalAccountEdge = {
  __typename?: 'CustodialPersonalAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<CustodialPersonalAccount>;
};

/** Ordering options for CustodialPersonalAccount connections */
export type CustodialPersonalAccountOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order CustodialPersonalAccounts. */
  field: CustodialPersonalAccountOrderField;
};

/** Properties by which CustodialPersonalAccount connections can be ordered. */
export enum CustodialPersonalAccountOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * CustodialPersonalAccountWhereInput is used for filtering CustodialPersonalAccount objects.
 * Input was generated by ent.
 */
export type CustodialPersonalAccountWhereInput = {
  /** addepar_entity_id field predicates */
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContains?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparEntityIDLT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDLTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNEQ?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<CustodialPersonalAccountWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** controlling_parties edge predicates */
  hasControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  hasControllingPartiesWith?: InputMaybe<Array<ControllingPartyWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** owner edge predicates */
  hasOwner?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnerWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<CustodialPersonalAccountWhereInput>;
  or?: InputMaybe<Array<CustodialPersonalAccountWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type DeleteTenantInput = {
  subdomain: Scalars['String']['input'];
  subdomainSHA256: Scalars['String']['input'];
};

export type DesignSummaryDetails = {
  __typename?: 'DesignSummaryDetails';
  annuityPaymentAnnualIncreasePercentage: Scalars['String']['output'];
  annuityPayments: Array<SummaryAnnuityPaymentDetails>;
  assets: Array<SummaryAsset>;
  attorneyNotes?: Maybe<Scalars['String']['output']>;
  beneficiaries: Array<SummaryBeneficiary>;
  displayName: Scalars['String']['output'];
  grantor: SummaryPerson;
  grantorRetainedInterest: Scalars['String']['output'];
  initialFundingValue: Scalars['String']['output'];
  legalName?: Maybe<Scalars['String']['output']>;
  officialInterestRate: Scalars['String']['output'];
  spouseDisplayName?: Maybe<Scalars['String']['output']>;
  successorTrustees: Array<SummaryPerson>;
  taxableGiftAmount: Scalars['String']['output'];
  termDescription: Scalars['String']['output'];
  termDetailsInfo: TermDetailsInfo;
  /** @deprecated Use termDetailsInfo instead */
  termDetailsWarning?: Maybe<Scalars['String']['output']>;
  trustSitus?: Maybe<Scalars['String']['output']>;
  trustees: Array<SummaryPerson>;
};

export type DispositionScenario = Node & {
  __typename?: 'DispositionScenario';
  /** If this disposition scenario is hypothetical, this is the waterfall that is associated with it. */
  associatedHypotheticalWaterfall?: Maybe<EstateWaterfall>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  dispositiveProvisions: DispositiveProvisionConnection;
  /** The dispositive provision template associated with this scenario. When present, dispositive_provisions will be ignored. */
  dispositiveProvisionsTemplate?: Maybe<DispositiveProvisionTemplate>;
  /** This disposition scenario corresponds to the death of the associated grantor. */
  firstGrantorDeath: ClientProfile;
  id: Scalars['ID']['output'];
  /** The time at which the scenario was last reviewed. This can be used to indicate that a user has looked at these provisions and they are correct, even if they are empty. */
  reviewedAt?: Maybe<Scalars['Time']['output']>;
  secondDeathDispositiveProvisions: DispositiveProvisionConnection;
  /** The dispositive provision template associated with this scenario and second grantor's death. When present, second_death_dispositive_provisions will be ignored. */
  secondDeathDispositiveProvisionsTemplate?: Maybe<DispositiveProvisionTemplate>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type DispositionScenarioDispositiveProvisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositiveProvisionOrder>>;
  where?: InputMaybe<DispositiveProvisionWhereInput>;
};


export type DispositionScenarioSecondDeathDispositiveProvisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositiveProvisionOrder>>;
  where?: InputMaybe<DispositiveProvisionWhereInput>;
};

/** A connection to a list of items. */
export type DispositionScenarioConnection = {
  __typename?: 'DispositionScenarioConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DispositionScenarioEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DispositionScenarioEdge = {
  __typename?: 'DispositionScenarioEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DispositionScenario>;
};

/** Ordering options for DispositionScenario connections */
export type DispositionScenarioOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DispositionScenarios. */
  field: DispositionScenarioOrderField;
};

/** Properties by which DispositionScenario connections can be ordered. */
export enum DispositionScenarioOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * DispositionScenarioWhereInput is used for filtering DispositionScenario objects.
 * Input was generated by ent.
 */
export type DispositionScenarioWhereInput = {
  and?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** associated_hypothetical_waterfall edge predicates */
  hasAssociatedHypotheticalWaterfall?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssociatedHypotheticalWaterfallWith?: InputMaybe<Array<EstateWaterfallWhereInput>>;
  /** dispositive_provisions edge predicates */
  hasDispositiveProvisions?: InputMaybe<Scalars['Boolean']['input']>;
  /** dispositive_provisions_template edge predicates */
  hasDispositiveProvisionsTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositiveProvisionsTemplateWith?: InputMaybe<Array<DispositiveProvisionTemplateWhereInput>>;
  hasDispositiveProvisionsWith?: InputMaybe<Array<DispositiveProvisionWhereInput>>;
  /** first_grantor_death edge predicates */
  hasFirstGrantorDeath?: InputMaybe<Scalars['Boolean']['input']>;
  hasFirstGrantorDeathWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** second_death_dispositive_provisions edge predicates */
  hasSecondDeathDispositiveProvisions?: InputMaybe<Scalars['Boolean']['input']>;
  /** second_death_dispositive_provisions_template edge predicates */
  hasSecondDeathDispositiveProvisionsTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  hasSecondDeathDispositiveProvisionsTemplateWith?: InputMaybe<Array<DispositiveProvisionTemplateWhereInput>>;
  hasSecondDeathDispositiveProvisionsWith?: InputMaybe<Array<DispositiveProvisionWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DispositionScenarioWhereInput>;
  or?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** reviewed_at field predicates */
  reviewedAt?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtGT?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  reviewedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedAtLT?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  reviewedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type DispositiveProvision = Node & {
  __typename?: 'DispositiveProvision';
  /** If this disposition scenario is hypothetical, this is the waterfall that is associated with it. */
  associatedHypotheticalWaterfall?: Maybe<EstateWaterfall>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The AMOUNT kind of disposition. Only valid when disposition_kind is AMOUNT */
  dispositionAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  dispositionKind: DispositiveProvisionDispositionKind;
  /** The order of the disposition in the list of dispositions */
  dispositionOrder: Scalars['Int']['output'];
  /** The percentage of the disposition. Only valid when disposition_kind is PERCENTAGE */
  dispositionPercentage?: Maybe<Scalars['Percent']['output']>;
  /** An edge to the beneficiary, which is an entity */
  entity?: Maybe<Entity>;
  id: Scalars['ID']['output'];
  /** An edge to the beneficiary, which is an individual */
  individual?: Maybe<ClientProfile>;
  /** Free-form notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** An edge to the beneficiary, which is an organization */
  organization?: Maybe<ClientOrganization>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** An edge to the beneficiary, which is a testamentary entity */
  testamentaryEntity?: Maybe<TestamentaryEntity>;
  transferTaxKind: DispositiveProvisionTransferTaxKind;
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type DispositiveProvisionConnection = {
  __typename?: 'DispositiveProvisionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DispositiveProvisionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** DispositiveProvisionDispositionKind is enum for the field disposition_kind */
export enum DispositiveProvisionDispositionKind {
  Amount = 'AMOUNT',
  AnythingLeftOver = 'ANYTHING_LEFT_OVER',
  Percentage = 'PERCENTAGE',
  RemainingFederalLifetimeExemptionInExcessOfStateExemption = 'REMAINING_FEDERAL_LIFETIME_EXEMPTION_IN_EXCESS_OF_STATE_EXEMPTION',
  RemainingGstExclusionOfGrantorInExcessOfLifetimeExclusion = 'REMAINING_GST_EXCLUSION_OF_GRANTOR_IN_EXCESS_OF_LIFETIME_EXCLUSION',
  RemainingLifetimeExclusionOfGrantor = 'REMAINING_LIFETIME_EXCLUSION_OF_GRANTOR',
  RemainingStateExemption = 'REMAINING_STATE_EXEMPTION'
}

/** An edge in a connection. */
export type DispositiveProvisionEdge = {
  __typename?: 'DispositiveProvisionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DispositiveProvision>;
};

/** Ordering options for DispositiveProvision connections */
export type DispositiveProvisionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DispositiveProvisions. */
  field: DispositiveProvisionOrderField;
};

/** Properties by which DispositiveProvision connections can be ordered. */
export enum DispositiveProvisionOrderField {
  DispositionOrder = 'DISPOSITION_ORDER',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type DispositiveProvisionTemplate = Node & {
  __typename?: 'DispositiveProvisionTemplate';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The name of the template */
  displayName: Scalars['String']['output'];
  dispositiveProvisions: DispositiveProvisionConnection;
  /** The document associated with this template */
  document?: Maybe<Document>;
  household: Household;
  /** the household this object belongs to */
  householdID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** The kind of dispositive provision tempalte */
  kind: DispositiveProvisionTemplateKind;
  /** The sub kind of dispositive provision tempalte */
  subKind: DispositiveProvisionTemplateSubKind;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updateUser: User;
  updatedAt: Scalars['Time']['output'];
  updatedBy: Scalars['ID']['output'];
  user: User;
};


export type DispositiveProvisionTemplateDispositiveProvisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositiveProvisionOrder>>;
  where?: InputMaybe<DispositiveProvisionWhereInput>;
};

/** A connection to a list of items. */
export type DispositiveProvisionTemplateConnection = {
  __typename?: 'DispositiveProvisionTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DispositiveProvisionTemplateEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DispositiveProvisionTemplateEdge = {
  __typename?: 'DispositiveProvisionTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DispositiveProvisionTemplate>;
};

/** DispositiveProvisionTemplateKind is enum for the field kind */
export enum DispositiveProvisionTemplateKind {
  AbcTrust = 'ABC_TRUST',
  AbTrust = 'AB_TRUST',
  Default = 'DEFAULT',
  MaritalTrust = 'MARITAL_TRUST',
  OutrightToSurvivingSpouse = 'OUTRIGHT_TO_SURVIVING_SPOUSE',
  PouroverWill = 'POUROVER_WILL'
}

/** Ordering options for DispositiveProvisionTemplate connections */
export type DispositiveProvisionTemplateOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DispositiveProvisionTemplates. */
  field: DispositiveProvisionTemplateOrderField;
};

/** Properties by which DispositiveProvisionTemplate connections can be ordered. */
export enum DispositiveProvisionTemplateOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** DispositiveProvisionTemplateSubKind is enum for the field sub_kind */
export enum DispositiveProvisionTemplateSubKind {
  ClaytonQtip = 'CLAYTON_QTIP',
  Disclaimer = 'DISCLAIMER',
  None = 'NONE',
  Survivor = 'SURVIVOR'
}

/**
 * DispositiveProvisionTemplateWhereInput is used for filtering DispositiveProvisionTemplate objects.
 * Input was generated by ent.
 */
export type DispositiveProvisionTemplateWhereInput = {
  and?: InputMaybe<Array<DispositiveProvisionTemplateWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** dispositive_provisions edge predicates */
  hasDispositiveProvisions?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositiveProvisionsWith?: InputMaybe<Array<DispositiveProvisionWhereInput>>;
  /** document edge predicates */
  hasDocument?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** update_user edge predicates */
  hasUpdateUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** household_id field predicates */
  householdID?: InputMaybe<Scalars['ID']['input']>;
  householdIDContains?: InputMaybe<Scalars['ID']['input']>;
  householdIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDGT?: InputMaybe<Scalars['ID']['input']>;
  householdIDGTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  householdIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDLT?: InputMaybe<Scalars['ID']['input']>;
  householdIDLTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  householdIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<DispositiveProvisionTemplateKind>;
  kindIn?: InputMaybe<Array<DispositiveProvisionTemplateKind>>;
  kindNEQ?: InputMaybe<DispositiveProvisionTemplateKind>;
  kindNotIn?: InputMaybe<Array<DispositiveProvisionTemplateKind>>;
  not?: InputMaybe<DispositiveProvisionTemplateWhereInput>;
  or?: InputMaybe<Array<DispositiveProvisionTemplateWhereInput>>;
  /** sub_kind field predicates */
  subKind?: InputMaybe<DispositiveProvisionTemplateSubKind>;
  subKindIn?: InputMaybe<Array<DispositiveProvisionTemplateSubKind>>;
  subKindNEQ?: InputMaybe<DispositiveProvisionTemplateSubKind>;
  subKindNotIn?: InputMaybe<Array<DispositiveProvisionTemplateSubKind>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['ID']['input']>;
  updatedByContains?: InputMaybe<Scalars['ID']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByGT?: InputMaybe<Scalars['ID']['input']>;
  updatedByGTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedByLT?: InputMaybe<Scalars['ID']['input']>;
  updatedByLTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['ID']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** DispositiveProvisionTransferTaxKind is enum for the field transfer_tax_kind */
export enum DispositiveProvisionTransferTaxKind {
  Charitable = 'CHARITABLE',
  Generation_2OrOtherIndividual = 'GENERATION_2_OR_OTHER_INDIVIDUAL',
  Generation_2Then_3 = 'GENERATION_2_THEN_3',
  Generation_3 = 'GENERATION_3',
  Pourover = 'POUROVER',
  SpouseCreditShelter = 'SPOUSE_CREDIT_SHELTER',
  SpouseFederalCreditShelterStateMaritalExclusion = 'SPOUSE_FEDERAL_CREDIT_SHELTER_STATE_MARITAL_EXCLUSION',
  SpouseMaritalExclusion = 'SPOUSE_MARITAL_EXCLUSION'
}

/**
 * DispositiveProvisionWhereInput is used for filtering DispositiveProvision objects.
 * Input was generated by ent.
 */
export type DispositiveProvisionWhereInput = {
  and?: InputMaybe<Array<DispositiveProvisionWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** disposition_amount field predicates */
  dispositionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  dispositionAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  dispositionAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  dispositionAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  dispositionAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dispositionAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  dispositionAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  dispositionAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  dispositionAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  dispositionAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** disposition_kind field predicates */
  dispositionKind?: InputMaybe<DispositiveProvisionDispositionKind>;
  dispositionKindIn?: InputMaybe<Array<DispositiveProvisionDispositionKind>>;
  dispositionKindNEQ?: InputMaybe<DispositiveProvisionDispositionKind>;
  dispositionKindNotIn?: InputMaybe<Array<DispositiveProvisionDispositionKind>>;
  /** disposition_order field predicates */
  dispositionOrder?: InputMaybe<Scalars['Int']['input']>;
  dispositionOrderGT?: InputMaybe<Scalars['Int']['input']>;
  dispositionOrderGTE?: InputMaybe<Scalars['Int']['input']>;
  dispositionOrderIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  dispositionOrderLT?: InputMaybe<Scalars['Int']['input']>;
  dispositionOrderLTE?: InputMaybe<Scalars['Int']['input']>;
  dispositionOrderNEQ?: InputMaybe<Scalars['Int']['input']>;
  dispositionOrderNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** disposition_percentage field predicates */
  dispositionPercentage?: InputMaybe<Scalars['Percent']['input']>;
  dispositionPercentageGT?: InputMaybe<Scalars['Percent']['input']>;
  dispositionPercentageGTE?: InputMaybe<Scalars['Percent']['input']>;
  dispositionPercentageIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  dispositionPercentageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dispositionPercentageLT?: InputMaybe<Scalars['Percent']['input']>;
  dispositionPercentageLTE?: InputMaybe<Scalars['Percent']['input']>;
  dispositionPercentageNEQ?: InputMaybe<Scalars['Percent']['input']>;
  dispositionPercentageNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  dispositionPercentageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** associated_hypothetical_waterfall edge predicates */
  hasAssociatedHypotheticalWaterfall?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssociatedHypotheticalWaterfallWith?: InputMaybe<Array<EstateWaterfallWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** individual edge predicates */
  hasIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** organization edge predicates */
  hasOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** testamentary_entity edge predicates */
  hasTestamentaryEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasTestamentaryEntityWith?: InputMaybe<Array<TestamentaryEntityWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DispositiveProvisionWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<DispositiveProvisionWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** transfer_tax_kind field predicates */
  transferTaxKind?: InputMaybe<DispositiveProvisionTransferTaxKind>;
  transferTaxKindIn?: InputMaybe<Array<DispositiveProvisionTransferTaxKind>>;
  transferTaxKindNEQ?: InputMaybe<DispositiveProvisionTransferTaxKind>;
  transferTaxKindNotIn?: InputMaybe<Array<DispositiveProvisionTransferTaxKind>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Document = Node & {
  __typename?: 'Document';
  /** The precedence that the suggestions from this document should take when extracting data. If this is not explicitly set, suggestions order will be inferred by uploaded date (most recent first). Only applicable for documents associated with entities. */
  aiSuggestionOrder?: Maybe<Scalars['Int']['output']>;
  asyncJobs: AsyncJobConnection;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** If set, this document is the default document for the entity */
  defaultDocumentOfEntity?: Maybe<Entity>;
  displayType: Scalars['String']['output'];
  /** The summary and rating/feedback for this document */
  documentSummary?: Maybe<DocumentSummary>;
  /** Whether this document should be used for AI suggestions. Only applicable for documents associated with entities. */
  enableAiSuggestions?: Maybe<Scalars['Boolean']['output']>;
  /** Entity that owns this document */
  entity?: Maybe<Entity>;
  file: File;
  household: Household;
  id: Scalars['ID']['output'];
  lifetimeExclusionEvent?: Maybe<Array<LifetimeExclusionEvent>>;
  name: Scalars['String']['output'];
  ocrExtractedMarkdownContent?: Maybe<Scalars['String']['output']>;
  /** DEPRECATED: This is a spillover concept from the initial POC. */
  parsingStatus?: Maybe<DocumentParsingStatus>;
  questions: DocumentQuestionConnection;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  type: DocumentType;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type DocumentAsyncJobsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AsyncJobOrder>>;
  where?: InputMaybe<AsyncJobWhereInput>;
};


export type DocumentQuestionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DocumentQuestionOrder>>;
  where?: InputMaybe<DocumentQuestionWhereInput>;
};

export type DocumentBoundingRegionQueryInput = {
  documentID: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  matchThreshold?: InputMaybe<Scalars['Float']['input']>;
  query: Scalars['String']['input'];
  skipGeneratedResponse?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of items. */
export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Document>;
};

export type DocumentEvidenceSearch = {
  __typename?: 'DocumentEvidenceSearch';
  documentID: Scalars['ID']['output'];
  searchString: Scalars['String']['output'];
};

export type DocumentInferenceEvaluationPrompts = Node & {
  __typename?: 'DocumentInferenceEvaluationPrompts';
  /** The async job that this prompt is associated with. */
  asyncJob: AsyncJob;
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** The system prompt. */
  prompt: Scalars['String']['output'];
  scores?: Maybe<Array<DocumentInferenceEvaluationScores>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  /** Indicates isConsolidated, withEvidence, invokeFunction. */
  variant: Scalars['String']['output'];
};

/** Ordering options for DocumentInferenceEvaluationPrompts connections */
export type DocumentInferenceEvaluationPromptsOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DocumentInferenceEvaluationPromptsSlice. */
  field: DocumentInferenceEvaluationPromptsOrderField;
};

/** Properties by which DocumentInferenceEvaluationPrompts connections can be ordered. */
export enum DocumentInferenceEvaluationPromptsOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * DocumentInferenceEvaluationPromptsWhereInput is used for filtering DocumentInferenceEvaluationPrompts objects.
 * Input was generated by ent.
 */
export type DocumentInferenceEvaluationPromptsWhereInput = {
  and?: InputMaybe<Array<DocumentInferenceEvaluationPromptsWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** async_job edge predicates */
  hasAsyncJob?: InputMaybe<Scalars['Boolean']['input']>;
  hasAsyncJobWith?: InputMaybe<Array<AsyncJobWhereInput>>;
  /** scores edge predicates */
  hasScores?: InputMaybe<Scalars['Boolean']['input']>;
  hasScoresWith?: InputMaybe<Array<DocumentInferenceEvaluationScoresWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DocumentInferenceEvaluationPromptsWhereInput>;
  or?: InputMaybe<Array<DocumentInferenceEvaluationPromptsWhereInput>>;
  /** prompt field predicates */
  prompt?: InputMaybe<Scalars['String']['input']>;
  promptContains?: InputMaybe<Scalars['String']['input']>;
  promptContainsFold?: InputMaybe<Scalars['String']['input']>;
  promptEqualFold?: InputMaybe<Scalars['String']['input']>;
  promptGT?: InputMaybe<Scalars['String']['input']>;
  promptGTE?: InputMaybe<Scalars['String']['input']>;
  promptHasPrefix?: InputMaybe<Scalars['String']['input']>;
  promptHasSuffix?: InputMaybe<Scalars['String']['input']>;
  promptIn?: InputMaybe<Array<Scalars['String']['input']>>;
  promptLT?: InputMaybe<Scalars['String']['input']>;
  promptLTE?: InputMaybe<Scalars['String']['input']>;
  promptNEQ?: InputMaybe<Scalars['String']['input']>;
  promptNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** variant field predicates */
  variant?: InputMaybe<Scalars['String']['input']>;
  variantContains?: InputMaybe<Scalars['String']['input']>;
  variantContainsFold?: InputMaybe<Scalars['String']['input']>;
  variantEqualFold?: InputMaybe<Scalars['String']['input']>;
  variantGT?: InputMaybe<Scalars['String']['input']>;
  variantGTE?: InputMaybe<Scalars['String']['input']>;
  variantHasPrefix?: InputMaybe<Scalars['String']['input']>;
  variantHasSuffix?: InputMaybe<Scalars['String']['input']>;
  variantIn?: InputMaybe<Array<Scalars['String']['input']>>;
  variantLT?: InputMaybe<Scalars['String']['input']>;
  variantLTE?: InputMaybe<Scalars['String']['input']>;
  variantNEQ?: InputMaybe<Scalars['String']['input']>;
  variantNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DocumentInferenceEvaluationScores = Node & {
  __typename?: 'DocumentInferenceEvaluationScores';
  /** The cosine similarity score between the ground truth embedding, and the inferred embedding, generated for the given prompt. */
  cosineSimilarity: Scalars['Float']['output'];
  createdAt: Scalars['Time']['output'];
  /** The document that this score is for. */
  document: Document;
  /** The string we're scoring against. */
  expected: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The inferred string that we're scoring. */
  inferred: Scalars['String']['output'];
  /** The prompt that this score is for. */
  prompt: DocumentInferenceEvaluationPrompts;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** Ordering options for DocumentInferenceEvaluationScores connections */
export type DocumentInferenceEvaluationScoresOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DocumentInferenceEvaluationScoresSlice. */
  field: DocumentInferenceEvaluationScoresOrderField;
};

/** Properties by which DocumentInferenceEvaluationScores connections can be ordered. */
export enum DocumentInferenceEvaluationScoresOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * DocumentInferenceEvaluationScoresWhereInput is used for filtering DocumentInferenceEvaluationScores objects.
 * Input was generated by ent.
 */
export type DocumentInferenceEvaluationScoresWhereInput = {
  and?: InputMaybe<Array<DocumentInferenceEvaluationScoresWhereInput>>;
  /** cosine_similarity field predicates */
  cosineSimilarity?: InputMaybe<Scalars['Float']['input']>;
  cosineSimilarityGT?: InputMaybe<Scalars['Float']['input']>;
  cosineSimilarityGTE?: InputMaybe<Scalars['Float']['input']>;
  cosineSimilarityIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  cosineSimilarityLT?: InputMaybe<Scalars['Float']['input']>;
  cosineSimilarityLTE?: InputMaybe<Scalars['Float']['input']>;
  cosineSimilarityNEQ?: InputMaybe<Scalars['Float']['input']>;
  cosineSimilarityNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** expected field predicates */
  expected?: InputMaybe<Scalars['String']['input']>;
  expectedContains?: InputMaybe<Scalars['String']['input']>;
  expectedContainsFold?: InputMaybe<Scalars['String']['input']>;
  expectedEqualFold?: InputMaybe<Scalars['String']['input']>;
  expectedGT?: InputMaybe<Scalars['String']['input']>;
  expectedGTE?: InputMaybe<Scalars['String']['input']>;
  expectedHasPrefix?: InputMaybe<Scalars['String']['input']>;
  expectedHasSuffix?: InputMaybe<Scalars['String']['input']>;
  expectedIn?: InputMaybe<Array<Scalars['String']['input']>>;
  expectedLT?: InputMaybe<Scalars['String']['input']>;
  expectedLTE?: InputMaybe<Scalars['String']['input']>;
  expectedNEQ?: InputMaybe<Scalars['String']['input']>;
  expectedNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** document edge predicates */
  hasDocument?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** prompt edge predicates */
  hasPrompt?: InputMaybe<Scalars['Boolean']['input']>;
  hasPromptWith?: InputMaybe<Array<DocumentInferenceEvaluationPromptsWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** inferred field predicates */
  inferred?: InputMaybe<Scalars['String']['input']>;
  inferredContains?: InputMaybe<Scalars['String']['input']>;
  inferredContainsFold?: InputMaybe<Scalars['String']['input']>;
  inferredEqualFold?: InputMaybe<Scalars['String']['input']>;
  inferredGT?: InputMaybe<Scalars['String']['input']>;
  inferredGTE?: InputMaybe<Scalars['String']['input']>;
  inferredHasPrefix?: InputMaybe<Scalars['String']['input']>;
  inferredHasSuffix?: InputMaybe<Scalars['String']['input']>;
  inferredIn?: InputMaybe<Array<Scalars['String']['input']>>;
  inferredLT?: InputMaybe<Scalars['String']['input']>;
  inferredLTE?: InputMaybe<Scalars['String']['input']>;
  inferredNEQ?: InputMaybe<Scalars['String']['input']>;
  inferredNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<DocumentInferenceEvaluationScoresWhereInput>;
  or?: InputMaybe<Array<DocumentInferenceEvaluationScoresWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for Document connections */
export type DocumentOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Documents. */
  field: DocumentOrderField;
};

/** Properties by which Document connections can be ordered. */
export enum DocumentOrderField {
  AiSuggestionOrder = 'ai_suggestion_order',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** DocumentParsingStatus is enum for the field parsing_status */
export enum DocumentParsingStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Extracting = 'EXTRACTING',
  OcrError = 'OCR_ERROR',
  Processing = 'PROCESSING'
}

export type DocumentQuestion = Node & {
  __typename?: 'DocumentQuestion';
  /** Answer(s) to this question, generated from the LLM, using the document */
  answers?: Maybe<Array<DocumentQuestionAnswers>>;
  /** The async job that generated the question/answer response */
  asyncJob?: Maybe<AsyncJob>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The document that this question is about */
  document: Document;
  id: Scalars['ID']['output'];
  /** Helps us determine how to parse the answer */
  kind?: Maybe<DocumentQuestionKind>;
  question: Scalars['String']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

export type DocumentQuestionAnswers = Node & {
  __typename?: 'DocumentQuestionAnswers';
  answer: Scalars['String']['output'];
  chain: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  question?: Maybe<DocumentQuestion>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type DocumentQuestionAnswersConnection = {
  __typename?: 'DocumentQuestionAnswersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentQuestionAnswersEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DocumentQuestionAnswersEdge = {
  __typename?: 'DocumentQuestionAnswersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DocumentQuestionAnswers>;
};

/** Ordering options for DocumentQuestionAnswers connections */
export type DocumentQuestionAnswersOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DocumentQuestionAnswersSlice. */
  field: DocumentQuestionAnswersOrderField;
};

/** Properties by which DocumentQuestionAnswers connections can be ordered. */
export enum DocumentQuestionAnswersOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * DocumentQuestionAnswersWhereInput is used for filtering DocumentQuestionAnswers objects.
 * Input was generated by ent.
 */
export type DocumentQuestionAnswersWhereInput = {
  and?: InputMaybe<Array<DocumentQuestionAnswersWhereInput>>;
  /** answer field predicates */
  answer?: InputMaybe<Scalars['String']['input']>;
  answerContains?: InputMaybe<Scalars['String']['input']>;
  answerContainsFold?: InputMaybe<Scalars['String']['input']>;
  answerEqualFold?: InputMaybe<Scalars['String']['input']>;
  answerGT?: InputMaybe<Scalars['String']['input']>;
  answerGTE?: InputMaybe<Scalars['String']['input']>;
  answerHasPrefix?: InputMaybe<Scalars['String']['input']>;
  answerHasSuffix?: InputMaybe<Scalars['String']['input']>;
  answerIn?: InputMaybe<Array<Scalars['String']['input']>>;
  answerLT?: InputMaybe<Scalars['String']['input']>;
  answerLTE?: InputMaybe<Scalars['String']['input']>;
  answerNEQ?: InputMaybe<Scalars['String']['input']>;
  answerNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** chain field predicates */
  chain?: InputMaybe<Scalars['String']['input']>;
  chainContains?: InputMaybe<Scalars['String']['input']>;
  chainContainsFold?: InputMaybe<Scalars['String']['input']>;
  chainEqualFold?: InputMaybe<Scalars['String']['input']>;
  chainGT?: InputMaybe<Scalars['String']['input']>;
  chainGTE?: InputMaybe<Scalars['String']['input']>;
  chainHasPrefix?: InputMaybe<Scalars['String']['input']>;
  chainHasSuffix?: InputMaybe<Scalars['String']['input']>;
  chainIn?: InputMaybe<Array<Scalars['String']['input']>>;
  chainLT?: InputMaybe<Scalars['String']['input']>;
  chainLTE?: InputMaybe<Scalars['String']['input']>;
  chainNEQ?: InputMaybe<Scalars['String']['input']>;
  chainNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** question edge predicates */
  hasQuestion?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuestionWith?: InputMaybe<Array<DocumentQuestionWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DocumentQuestionAnswersWhereInput>;
  or?: InputMaybe<Array<DocumentQuestionAnswersWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** A connection to a list of items. */
export type DocumentQuestionConnection = {
  __typename?: 'DocumentQuestionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DocumentQuestionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DocumentQuestionEdge = {
  __typename?: 'DocumentQuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DocumentQuestion>;
};

/** DocumentQuestionKind is enum for the field kind */
export enum DocumentQuestionKind {
  BeneficialOwnerDetails = 'BENEFICIAL_OWNER_DETAILS',
  BeneficialOwnerDetailsNoEvidence = 'BENEFICIAL_OWNER_DETAILS_NO_EVIDENCE',
  BeneficiaryDetails = 'BENEFICIARY_DETAILS',
  BeneficiaryDetailsNoEvidence = 'BENEFICIARY_DETAILS_NO_EVIDENCE',
  BusinessEntityDetails = 'BUSINESS_ENTITY_DETAILS',
  BusinessEntityDetailsNoEvidence = 'BUSINESS_ENTITY_DETAILS_NO_EVIDENCE',
  BusinessEntityFincen = 'BUSINESS_ENTITY_FINCEN',
  BusinessEntityFincenNoEvidence = 'BUSINESS_ENTITY_FINCEN_NO_EVIDENCE',
  BusinessEntityTax = 'BUSINESS_ENTITY_TAX',
  BusinessEntityTaxNoEvidence = 'BUSINESS_ENTITY_TAX_NO_EVIDENCE',
  ConsolidatedTrustDetails = 'CONSOLIDATED_TRUST_DETAILS',
  GrantorDetails = 'GRANTOR_DETAILS',
  GrantorDetailsNoEvidence = 'GRANTOR_DETAILS_NO_EVIDENCE',
  KeyPersonDetails = 'KEY_PERSON_DETAILS',
  KeyPersonDetailsNoEvidence = 'KEY_PERSON_DETAILS_NO_EVIDENCE',
  Summary = 'SUMMARY',
  TrusteeDetails = 'TRUSTEE_DETAILS',
  TrusteeDetailsNoEvidence = 'TRUSTEE_DETAILS_NO_EVIDENCE',
  TrustAdvisorDetails = 'TRUST_ADVISOR_DETAILS',
  TrustAdvisorDetailsNoEvidence = 'TRUST_ADVISOR_DETAILS_NO_EVIDENCE',
  TrustDetails = 'TRUST_DETAILS',
  TrustDetailsNoEvidence = 'TRUST_DETAILS_NO_EVIDENCE'
}

/** Ordering options for DocumentQuestion connections */
export type DocumentQuestionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DocumentQuestions. */
  field: DocumentQuestionOrderField;
};

/** Properties by which DocumentQuestion connections can be ordered. */
export enum DocumentQuestionOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * DocumentQuestionWhereInput is used for filtering DocumentQuestion objects.
 * Input was generated by ent.
 */
export type DocumentQuestionWhereInput = {
  and?: InputMaybe<Array<DocumentQuestionWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** answers edge predicates */
  hasAnswers?: InputMaybe<Scalars['Boolean']['input']>;
  hasAnswersWith?: InputMaybe<Array<DocumentQuestionAnswersWhereInput>>;
  /** async_job edge predicates */
  hasAsyncJob?: InputMaybe<Scalars['Boolean']['input']>;
  hasAsyncJobWith?: InputMaybe<Array<AsyncJobWhereInput>>;
  /** document edge predicates */
  hasDocument?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<DocumentQuestionKind>;
  kindIn?: InputMaybe<Array<DocumentQuestionKind>>;
  kindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  kindNEQ?: InputMaybe<DocumentQuestionKind>;
  kindNotIn?: InputMaybe<Array<DocumentQuestionKind>>;
  kindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<DocumentQuestionWhereInput>;
  or?: InputMaybe<Array<DocumentQuestionWhereInput>>;
  /** question field predicates */
  question?: InputMaybe<Scalars['String']['input']>;
  questionContains?: InputMaybe<Scalars['String']['input']>;
  questionContainsFold?: InputMaybe<Scalars['String']['input']>;
  questionEqualFold?: InputMaybe<Scalars['String']['input']>;
  questionGT?: InputMaybe<Scalars['String']['input']>;
  questionGTE?: InputMaybe<Scalars['String']['input']>;
  questionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  questionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  questionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  questionLT?: InputMaybe<Scalars['String']['input']>;
  questionLTE?: InputMaybe<Scalars['String']['input']>;
  questionNEQ?: InputMaybe<Scalars['String']['input']>;
  questionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type DocumentSummary = Node & {
  __typename?: 'DocumentSummary';
  /** The user's feedback on the AI-generated summary. Ignore if entry_mode is USER */
  aiSummaryFeedback?: Maybe<Scalars['String']['output']>;
  /** The user's rating of the AI-generated summary. Ignore if entry_mode is USER */
  aiSummaryRating?: Maybe<DocumentSummaryAiSummaryRating>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The document that this summary is associated with */
  document: Document;
  /** Indicates if the summary content was updated by the user or generated by AI. Default: AI_AUTO */
  entryMode?: Maybe<DocumentSummaryEntryMode>;
  id: Scalars['ID']['output'];
  /** The most recently-edited summary for the document */
  summary: Scalars['String']['output'];
  /** The format of the summary string when displaying it to the user on the frontend. Default: MARKDOWN */
  summaryFormat?: Maybe<DocumentSummarySummaryFormat>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updateUser: User;
  updatedAt: Scalars['Time']['output'];
  updatedBy: Scalars['ID']['output'];
  user: User;
};

/** DocumentSummaryAiSummaryRating is enum for the field ai_summary_rating */
export enum DocumentSummaryAiSummaryRating {
  Bad = 'BAD',
  Good = 'GOOD'
}

/** DocumentSummaryEntryMode is enum for the field entry_mode */
export enum DocumentSummaryEntryMode {
  AiAuto = 'AI_AUTO',
  AiUserInvoked = 'AI_USER_INVOKED',
  User = 'USER'
}

/** Ordering options for DocumentSummary connections */
export type DocumentSummaryOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DocumentSummaries. */
  field: DocumentSummaryOrderField;
};

/** Properties by which DocumentSummary connections can be ordered. */
export enum DocumentSummaryOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** DocumentSummarySummaryFormat is enum for the field summary_format */
export enum DocumentSummarySummaryFormat {
  Markdown = 'MARKDOWN',
  PlainText = 'PLAIN_TEXT'
}

/**
 * DocumentSummaryWhereInput is used for filtering DocumentSummary objects.
 * Input was generated by ent.
 */
export type DocumentSummaryWhereInput = {
  /** ai_summary_feedback field predicates */
  aiSummaryFeedback?: InputMaybe<Scalars['String']['input']>;
  aiSummaryFeedbackContains?: InputMaybe<Scalars['String']['input']>;
  aiSummaryFeedbackContainsFold?: InputMaybe<Scalars['String']['input']>;
  aiSummaryFeedbackEqualFold?: InputMaybe<Scalars['String']['input']>;
  aiSummaryFeedbackGT?: InputMaybe<Scalars['String']['input']>;
  aiSummaryFeedbackGTE?: InputMaybe<Scalars['String']['input']>;
  aiSummaryFeedbackHasPrefix?: InputMaybe<Scalars['String']['input']>;
  aiSummaryFeedbackHasSuffix?: InputMaybe<Scalars['String']['input']>;
  aiSummaryFeedbackIn?: InputMaybe<Array<Scalars['String']['input']>>;
  aiSummaryFeedbackIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  aiSummaryFeedbackLT?: InputMaybe<Scalars['String']['input']>;
  aiSummaryFeedbackLTE?: InputMaybe<Scalars['String']['input']>;
  aiSummaryFeedbackNEQ?: InputMaybe<Scalars['String']['input']>;
  aiSummaryFeedbackNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  aiSummaryFeedbackNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** ai_summary_rating field predicates */
  aiSummaryRating?: InputMaybe<DocumentSummaryAiSummaryRating>;
  aiSummaryRatingIn?: InputMaybe<Array<DocumentSummaryAiSummaryRating>>;
  aiSummaryRatingIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  aiSummaryRatingNEQ?: InputMaybe<DocumentSummaryAiSummaryRating>;
  aiSummaryRatingNotIn?: InputMaybe<Array<DocumentSummaryAiSummaryRating>>;
  aiSummaryRatingNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<DocumentSummaryWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** entry_mode field predicates */
  entryMode?: InputMaybe<DocumentSummaryEntryMode>;
  entryModeIn?: InputMaybe<Array<DocumentSummaryEntryMode>>;
  entryModeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  entryModeNEQ?: InputMaybe<DocumentSummaryEntryMode>;
  entryModeNotIn?: InputMaybe<Array<DocumentSummaryEntryMode>>;
  entryModeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** document edge predicates */
  hasDocument?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** update_user edge predicates */
  hasUpdateUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<DocumentSummaryWhereInput>;
  or?: InputMaybe<Array<DocumentSummaryWhereInput>>;
  /** summary field predicates */
  summary?: InputMaybe<Scalars['String']['input']>;
  summaryContains?: InputMaybe<Scalars['String']['input']>;
  summaryContainsFold?: InputMaybe<Scalars['String']['input']>;
  summaryEqualFold?: InputMaybe<Scalars['String']['input']>;
  /** summary_format field predicates */
  summaryFormat?: InputMaybe<DocumentSummarySummaryFormat>;
  summaryFormatIn?: InputMaybe<Array<DocumentSummarySummaryFormat>>;
  summaryFormatIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  summaryFormatNEQ?: InputMaybe<DocumentSummarySummaryFormat>;
  summaryFormatNotIn?: InputMaybe<Array<DocumentSummarySummaryFormat>>;
  summaryFormatNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  summaryGT?: InputMaybe<Scalars['String']['input']>;
  summaryGTE?: InputMaybe<Scalars['String']['input']>;
  summaryHasPrefix?: InputMaybe<Scalars['String']['input']>;
  summaryHasSuffix?: InputMaybe<Scalars['String']['input']>;
  summaryIn?: InputMaybe<Array<Scalars['String']['input']>>;
  summaryLT?: InputMaybe<Scalars['String']['input']>;
  summaryLTE?: InputMaybe<Scalars['String']['input']>;
  summaryNEQ?: InputMaybe<Scalars['String']['input']>;
  summaryNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['ID']['input']>;
  updatedByContains?: InputMaybe<Scalars['ID']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByGT?: InputMaybe<Scalars['ID']['input']>;
  updatedByGTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedByLT?: InputMaybe<Scalars['ID']['input']>;
  updatedByLTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['ID']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** DocumentType is enum for the field type */
export enum DocumentType {
  AccountDocumentationStatement = 'ACCOUNT_DOCUMENTATION_STATEMENT',
  ArticlesOfIncorporation = 'ARTICLES_OF_INCORPORATION',
  AssetValuation = 'ASSET_VALUATION',
  AssignmentOfInterest = 'ASSIGNMENT_OF_INTEREST',
  BalanceSheet = 'BALANCE_SHEET',
  BeneficialOwnershipInformationReport = 'BENEFICIAL_OWNERSHIP_INFORMATION_REPORT',
  CorporateBylaws = 'CORPORATE_BYLAWS',
  CrummeyLetter = 'CRUMMEY_LETTER',
  DriversLicense = 'DRIVERS_LICENSE',
  ExistingRemainderTrustDocument = 'EXISTING_REMAINDER_TRUST_DOCUMENT',
  FincenFiling = 'FINCEN_FILING',
  GiftTaxReturn = 'GIFT_TAX_RETURN',
  GratDesignSummary = 'GRAT_DESIGN_SUMMARY',
  HealthcareProxy = 'HEALTHCARE_PROXY',
  IncomeTaxReturn = 'INCOME_TAX_RETURN',
  InsurancePolicy = 'INSURANCE_POLICY',
  InsurancePremiumPayment = 'INSURANCE_PREMIUM_PAYMENT',
  LlcAgreement = 'LLC_AGREEMENT',
  LoanNoteAgreement = 'LOAN_NOTE_AGREEMENT',
  LoggedContribution = 'LOGGED_CONTRIBUTION',
  LoggedDistribution = 'LOGGED_DISTRIBUTION',
  OperatingAgreement = 'OPERATING_AGREEMENT',
  Other = 'OTHER',
  PartnershipAgreement = 'PARTNERSHIP_AGREEMENT',
  Passport = 'PASSPORT',
  PowerOfAttorney = 'POWER_OF_ATTORNEY',
  ShareholdersAgreement = 'SHAREHOLDERS_AGREEMENT',
  SignedTrustDocument = 'SIGNED_TRUST_DOCUMENT',
  StateBusinessFiling = 'STATE_BUSINESS_FILING',
  TaxFiling = 'TAX_FILING',
  TaxIdConfirmation = 'TAX_ID_CONFIRMATION',
  TaxReceipt = 'TAX_RECEIPT',
  TransferConfirmation = 'TRANSFER_CONFIRMATION',
  Will = 'WILL'
}

/**
 * DocumentWhereInput is used for filtering Document objects.
 * Input was generated by ent.
 */
export type DocumentWhereInput = {
  /** ai_suggestion_order field predicates */
  aiSuggestionOrder?: InputMaybe<Scalars['Int']['input']>;
  aiSuggestionOrderGT?: InputMaybe<Scalars['Int']['input']>;
  aiSuggestionOrderGTE?: InputMaybe<Scalars['Int']['input']>;
  aiSuggestionOrderIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  aiSuggestionOrderIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  aiSuggestionOrderLT?: InputMaybe<Scalars['Int']['input']>;
  aiSuggestionOrderLTE?: InputMaybe<Scalars['Int']['input']>;
  aiSuggestionOrderNEQ?: InputMaybe<Scalars['Int']['input']>;
  aiSuggestionOrderNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  aiSuggestionOrderNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<DocumentWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** enable_ai_suggestions field predicates */
  enableAiSuggestions?: InputMaybe<Scalars['Boolean']['input']>;
  enableAiSuggestionsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  enableAiSuggestionsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  enableAiSuggestionsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** async_jobs edge predicates */
  hasAsyncJobs?: InputMaybe<Scalars['Boolean']['input']>;
  hasAsyncJobsWith?: InputMaybe<Array<AsyncJobWhereInput>>;
  /** default_document_of_entity edge predicates */
  hasDefaultDocumentOfEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasDefaultDocumentOfEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** document_summary edge predicates */
  hasDocumentSummary?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentSummaryWith?: InputMaybe<Array<DocumentSummaryWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** file edge predicates */
  hasFile?: InputMaybe<Scalars['Boolean']['input']>;
  hasFileWith?: InputMaybe<Array<FileWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** lifetime_exclusion_event edge predicates */
  hasLifetimeExclusionEvent?: InputMaybe<Scalars['Boolean']['input']>;
  hasLifetimeExclusionEventWith?: InputMaybe<Array<LifetimeExclusionEventWhereInput>>;
  /** questions edge predicates */
  hasQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  hasQuestionsWith?: InputMaybe<Array<DocumentQuestionWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<DocumentWhereInput>;
  or?: InputMaybe<Array<DocumentWhereInput>>;
  /** parsing_status field predicates */
  parsingStatus?: InputMaybe<DocumentParsingStatus>;
  parsingStatusIn?: InputMaybe<Array<DocumentParsingStatus>>;
  parsingStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  parsingStatusNEQ?: InputMaybe<DocumentParsingStatus>;
  parsingStatusNotIn?: InputMaybe<Array<DocumentParsingStatus>>;
  parsingStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** type field predicates */
  type?: InputMaybe<DocumentType>;
  typeIn?: InputMaybe<Array<DocumentType>>;
  typeNEQ?: InputMaybe<DocumentType>;
  typeNotIn?: InputMaybe<Array<DocumentType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type DonorAdvisedFund = EntitySubtype & Named & Node & {
  __typename?: 'DonorAdvisedFund';
  accounts?: Maybe<Array<Account>>;
  addeparEntityID?: Maybe<Scalars['String']['output']>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  controllingParties?: Maybe<Array<ControllingParty>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this personal account */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in entities that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  donors?: Maybe<Array<Principal>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  performanceReport: PerformanceReport;
  policies?: Maybe<Array<InsurancePolicy>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<NonTrustEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type DonorAdvisedFundHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type DonorAdvisedFundConnection = {
  __typename?: 'DonorAdvisedFundConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DonorAdvisedFundEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DonorAdvisedFundEdge = {
  __typename?: 'DonorAdvisedFundEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<DonorAdvisedFund>;
};

/** Ordering options for DonorAdvisedFund connections */
export type DonorAdvisedFundOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order DonorAdvisedFunds. */
  field: DonorAdvisedFundOrderField;
};

/** Properties by which DonorAdvisedFund connections can be ordered. */
export enum DonorAdvisedFundOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * DonorAdvisedFundWhereInput is used for filtering DonorAdvisedFund objects.
 * Input was generated by ent.
 */
export type DonorAdvisedFundWhereInput = {
  /** addepar_entity_id field predicates */
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContains?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparEntityIDLT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDLTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNEQ?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<DonorAdvisedFundWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** controlling_parties edge predicates */
  hasControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  hasControllingPartiesWith?: InputMaybe<Array<ControllingPartyWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** donors edge predicates */
  hasDonors?: InputMaybe<Scalars['Boolean']['input']>;
  hasDonorsWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<DonorAdvisedFundWhereInput>;
  or?: InputMaybe<Array<DonorAdvisedFundWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type DuplicateEstateWaterfallInput = {
  id: Scalars['ID']['input'];
  makeHypothetical?: InputMaybe<Scalars['Boolean']['input']>;
  newName: Scalars['String']['input'];
};

export type DuplicateEstateWaterfallOptsInput = {
  applyAutoGrouping?: InputMaybe<Scalars['Boolean']['input']>;
  resetAssumptions?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Employee = Named & Node & {
  __typename?: 'Employee';
  assignedHouseholds?: Maybe<Array<Household>>;
  createdAt: Scalars['Time']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  initials: Scalars['String']['output'];
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  roles?: Maybe<Array<Role>>;
  /** Employees can be team owners */
  teamOwner?: Maybe<Array<Team>>;
  /** Employees can belong to teams */
  teams?: Maybe<Array<Team>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type EmployeeConnection = {
  __typename?: 'EmployeeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EmployeeEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EmployeeEdge = {
  __typename?: 'EmployeeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Employee>;
};

/** Ordering options for Employee connections */
export type EmployeeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Employees. */
  field: EmployeeOrderField;
};

/** Properties by which Employee connections can be ordered. */
export enum EmployeeOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * EmployeeWhereInput is used for filtering Employee objects.
 * Input was generated by ent.
 */
export type EmployeeWhereInput = {
  and?: InputMaybe<Array<EmployeeWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** assigned_households edge predicates */
  hasAssignedHouseholds?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssignedHouseholdsWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** roles edge predicates */
  hasRoles?: InputMaybe<Scalars['Boolean']['input']>;
  hasRolesWith?: InputMaybe<Array<RoleWhereInput>>;
  /** team_owner edge predicates */
  hasTeamOwner?: InputMaybe<Scalars['Boolean']['input']>;
  hasTeamOwnerWith?: InputMaybe<Array<TeamWhereInput>>;
  /** teams edge predicates */
  hasTeams?: InputMaybe<Scalars['Boolean']['input']>;
  hasTeamsWith?: InputMaybe<Array<TeamWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_admin field predicates */
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  isAdminIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  isAdminNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  isAdminNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<EmployeeWhereInput>;
  or?: InputMaybe<Array<EmployeeWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Entity = Node & {
  __typename?: 'Entity';
  activities?: Maybe<Array<Activity>>;
  addeparEntityID?: Maybe<Scalars['Int']['output']>;
  /** when true, indicates that the addepar entity this is linked to isn't owned by the grantor on addepar. this could be because the grantor on addepar is deceased or not present on Luminary. */
  addeparLinkedToNongrantorEntity?: Maybe<Scalars['Boolean']['output']>;
  asyncJobs: AsyncJobConnection;
  /** how the entity got created */
  attributionSource?: Maybe<EntityAttributionSource>;
  cashflows: CashflowConnection;
  /** CCorp business entity subtype associated with this entity */
  ccorpBusinessEntity?: Maybe<CCorpBusinessEntity>;
  /** CLT trust subtype associated with this entity */
  cltTrust?: Maybe<CltTrust>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** CRT trust subtype associated with this entity */
  crtTrust?: Maybe<CrtTrust>;
  currentLiabilitiesOwed: Scalars['CurrencyUSD']['output'];
  /** Custodial personal account subtype associated with this entity */
  custodialPersonalAccount?: Maybe<CustodialPersonalAccount>;
  /** The default document for this entity. */
  defaultDocument?: Maybe<Document>;
  documents: DocumentConnection;
  /** Donor advised fund subtype associated with this entity */
  donorAdvisedFund?: Maybe<DonorAdvisedFund>;
  /** Email notification records associated with this entity */
  emailNotificationRecords?: Maybe<Array<ReminderEmailNotificationRecord>>;
  entityProposals?: Maybe<Array<EntityProposal>>;
  /** Estate waterfall filters associated with this entity. Here to force the M2M relationship. */
  estateWaterfallFilters?: Maybe<Array<EstateWaterfallVisualizationFilter>>;
  extendedDisplayKind: Scalars['String']['output'];
  /** GP business entity subtype associated with this entity */
  gpBusinessEntity?: Maybe<GpBusinessEntity>;
  /** GRAT trust subtype associated with this entity */
  gratTrust?: Maybe<GratTrust>;
  /** Household that owns this entity */
  household: Household;
  id: Scalars['ID']['output'];
  /** ILIT trust subtype associated with this entity */
  ilitTrust?: Maybe<IlitTrust>;
  /** Individual personal account subtype associated with this entity */
  individualPersonalAccount?: Maybe<IndividualPersonalAccount>;
  inferenceDocuments?: Maybe<Array<Document>>;
  /** Insurance personal account subtype associated with this entity */
  insurancePersonalAccount?: Maybe<InsurancePersonalAccount>;
  integrationEntities?: Maybe<Array<IntegrationEntity>>;
  /** Irrevocable trust subtype associated with this entity */
  irrevocableTrust?: Maybe<IrrevocableTrust>;
  /** Joint personal account subtype associated with this entity */
  jointPersonalAccount?: Maybe<JointPersonalAccount>;
  /** Type of entity. This tells us what subtype edge to traverse */
  kind: EntityKind;
  liabilitiesOwed: LiabilityConnection;
  /** LLC business entity subtype associated with this entity */
  llcBusinessEntity?: Maybe<LlcBusinessEntity>;
  /** LP business entity subtype associated with this entity */
  lpBusinessEntity?: Maybe<LpBusinessEntity>;
  nextTask?: Maybe<EntityTask>;
  /** The ownership stake of this entity for another individual or entity. */
  ownedByStakes?: Maybe<Array<OwnershipStake>>;
  /** Ownership stakes this entity has */
  ownedOwnershipStakes?: Maybe<Array<OwnershipStake>>;
  /** Private foundation subtype associated with this entity */
  privateFoundation?: Maybe<PrivateFoundation>;
  /** QPRT trust subtype associated with this entity */
  qprtTrust?: Maybe<QprtTrust>;
  /** Qualified tuition personal account subtype associated with this entity */
  qualifiedTuitionPersonalAccount?: Maybe<QualifiedTuitionPersonalAccount>;
  /** Retirement personal account subtype associated with this entity */
  retirementPersonalAccount?: Maybe<RetirementPersonalAccount>;
  /** Revocable trust subtype associated with this entity */
  revocableTrust?: Maybe<RevocableTrust>;
  /** SCorp business entity subtype associated with this entity */
  scorpBusinessEntity?: Maybe<SCorpBusinessEntity>;
  /** SLAT trust subtype associated with this entity */
  slatTrust?: Maybe<SlatTrust>;
  /** Sole Proprietorship business entity subtype associated with this entity */
  soleProprietorshipBusinessEntity?: Maybe<SoleProprietorshipBusinessEntity>;
  /** the lifecycle stage of the entity. not all stages are applicable to all entities; most go directly into ACTIVE */
  stage: EntityStage;
  /** Entity state tax elections */
  stateTaxes?: Maybe<Array<EntityStateTax>>;
  /** The subtype of the entity. */
  subtype: EntitySubtype;
  tasks: EntityTaskConnection;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type EntityAsyncJobsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AsyncJobOrder>>;
  where?: InputMaybe<AsyncJobWhereInput>;
};


export type EntityCashflowsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CashflowOrder>>;
  where?: InputMaybe<CashflowWhereInput>;
};


export type EntityDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DocumentOrder>>;
  where?: InputMaybe<DocumentWhereInput>;
};


export type EntityLiabilitiesOwedArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LiabilityOrder>>;
  where?: InputMaybe<LiabilityWhereInput>;
};


export type EntityTasksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityTaskOrder>>;
  where?: InputMaybe<EntityTaskWhereInput>;
};

/** EntityAttributionSource is enum for the field attribution_source */
export enum EntityAttributionSource {
  AddeparBulkImport = 'ADDEPAR_BULK_IMPORT',
  DocumentExtraction = 'DOCUMENT_EXTRACTION',
  Duplicate = 'DUPLICATE',
  FromSpeech = 'FROM_SPEECH',
  IntakeForm = 'INTAKE_FORM',
  StrategyDesigner = 'STRATEGY_DESIGNER'
}

/** A connection to a list of items. */
export type EntityConnection = {
  __typename?: 'EntityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EntityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EntityEdge = {
  __typename?: 'EntityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Entity>;
};

/** EntityGSTStatus is enum for the field gst_status */
export enum EntityGstStatus {
  GstExempt = 'GST_EXEMPT',
  GstNonExempt = 'GST_NON_EXEMPT',
  MixedGst = 'MIXED_GST'
}

export type EntityGraphNodeConfiguration = {
  __typename?: 'EntityGraphNodeConfiguration';
  height?: Maybe<Scalars['Float']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  nodeID: Scalars['ID']['output'];
  xPosition: Scalars['Float']['output'];
  yPosition: Scalars['Float']['output'];
};

export type EntityGraphNodeConfigurationInput = {
  height?: InputMaybe<Scalars['Float']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  nodeID: Scalars['ID']['input'];
  xPosition: Scalars['Float']['input'];
  yPosition: Scalars['Float']['input'];
};

export type EntityGraphView = Node & {
  __typename?: 'EntityGraphView';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  household: Household;
  id: Scalars['ID']['output'];
  kind?: Maybe<EntityGraphViewKind>;
  /** the definitions of the nodes in the graph */
  nodeConfigurations: Array<EntityGraphNodeConfiguration>;
  orientation?: Maybe<EntityGraphViewOrientation>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type EntityGraphViewConnection = {
  __typename?: 'EntityGraphViewConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EntityGraphViewEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EntityGraphViewEdge = {
  __typename?: 'EntityGraphViewEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EntityGraphView>;
};

/** EntityGraphViewKind is enum for the field kind */
export enum EntityGraphViewKind {
  Beneficiary = 'BENEFICIARY',
  Ownership = 'OWNERSHIP'
}

/** Ordering options for EntityGraphView connections */
export type EntityGraphViewOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EntityGraphViews. */
  field: EntityGraphViewOrderField;
};

/** Properties by which EntityGraphView connections can be ordered. */
export enum EntityGraphViewOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** EntityGraphViewOrientation is enum for the field orientation */
export enum EntityGraphViewOrientation {
  BottomTop = 'BOTTOM_TOP',
  LeftRight = 'LEFT_RIGHT',
  RightLeft = 'RIGHT_LEFT',
  TopBottom = 'TOP_BOTTOM'
}

/**
 * EntityGraphViewWhereInput is used for filtering EntityGraphView objects.
 * Input was generated by ent.
 */
export type EntityGraphViewWhereInput = {
  and?: InputMaybe<Array<EntityGraphViewWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<EntityGraphViewKind>;
  kindIn?: InputMaybe<Array<EntityGraphViewKind>>;
  kindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  kindNEQ?: InputMaybe<EntityGraphViewKind>;
  kindNotIn?: InputMaybe<Array<EntityGraphViewKind>>;
  kindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<EntityGraphViewWhereInput>;
  or?: InputMaybe<Array<EntityGraphViewWhereInput>>;
  /** orientation field predicates */
  orientation?: InputMaybe<EntityGraphViewOrientation>;
  orientationIn?: InputMaybe<Array<EntityGraphViewOrientation>>;
  orientationIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  orientationNEQ?: InputMaybe<EntityGraphViewOrientation>;
  orientationNotIn?: InputMaybe<Array<EntityGraphViewOrientation>>;
  orientationNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** EntityInEstateStatus is enum for the field in_estate_status */
export enum EntityInEstateStatus {
  InEstate = 'IN_ESTATE',
  OutOfEstate = 'OUT_OF_ESTATE'
}

/** EntityKind is enum for the field kind */
export enum EntityKind {
  CcorpBusinessEntity = 'CCORP_BUSINESS_ENTITY',
  CltTrust = 'CLT_TRUST',
  CrtTrust = 'CRT_TRUST',
  CustodialPersonalAccount = 'CUSTODIAL_PERSONAL_ACCOUNT',
  DonorAdvisedFund = 'DONOR_ADVISED_FUND',
  GpBusinessEntity = 'GP_BUSINESS_ENTITY',
  GratTrust = 'GRAT_TRUST',
  IlitTrust = 'ILIT_TRUST',
  IndividualPersonalAccount = 'INDIVIDUAL_PERSONAL_ACCOUNT',
  InsurancePersonalAccount = 'INSURANCE_PERSONAL_ACCOUNT',
  IrrevocableTrust = 'IRREVOCABLE_TRUST',
  JointPersonalAccount = 'JOINT_PERSONAL_ACCOUNT',
  LlcBusinessEntity = 'LLC_BUSINESS_ENTITY',
  LpBusinessEntity = 'LP_BUSINESS_ENTITY',
  PrivateFoundation = 'PRIVATE_FOUNDATION',
  QprtTrust = 'QPRT_TRUST',
  QualifiedTuitionPersonalAccount = 'QUALIFIED_TUITION_PERSONAL_ACCOUNT',
  RetirementPersonalAccount = 'RETIREMENT_PERSONAL_ACCOUNT',
  RevocableTrust = 'REVOCABLE_TRUST',
  ScorpBusinessEntity = 'SCORP_BUSINESS_ENTITY',
  SlatTrust = 'SLAT_TRUST',
  SoleProprietorshipBusinessEntity = 'SOLE_PROPRIETORSHIP_BUSINESS_ENTITY'
}

/** Ordering options for Entity connections */
export type EntityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Entities. */
  field: EntityOrderField;
};

/** Properties by which Entity connections can be ordered. */
export enum EntityOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type EntityProposal = Node & {
  __typename?: 'EntityProposal';
  assetValuationProjectionType: EntityProposalAssetValuationProjectionType;
  createdAt: Scalars['Time']['output'];
  entity: Entity;
  id: Scalars['ID']['output'];
  proposal: Proposal;
  proposalScenarios?: Maybe<Array<ProposalScenario>>;
  /** the percent value for tax drag for the entity */
  taxDrag?: Maybe<Scalars['Percent']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** EntityProposalAssetValuationProjectionType is enum for the field asset_valuation_projection_type */
export enum EntityProposalAssetValuationProjectionType {
  ProjectedRateOfReturn = 'PROJECTED_RATE_OF_RETURN',
  ProjectedValue = 'PROJECTED_VALUE'
}

/** A connection to a list of items. */
export type EntityProposalConnection = {
  __typename?: 'EntityProposalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EntityProposalEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EntityProposalEdge = {
  __typename?: 'EntityProposalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EntityProposal>;
};

/** Ordering options for EntityProposal connections */
export type EntityProposalOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EntityProposals. */
  field: EntityProposalOrderField;
};

/** Properties by which EntityProposal connections can be ordered. */
export enum EntityProposalOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * EntityProposalWhereInput is used for filtering EntityProposal objects.
 * Input was generated by ent.
 */
export type EntityProposalWhereInput = {
  and?: InputMaybe<Array<EntityProposalWhereInput>>;
  /** asset_valuation_projection_type field predicates */
  assetValuationProjectionType?: InputMaybe<EntityProposalAssetValuationProjectionType>;
  assetValuationProjectionTypeIn?: InputMaybe<Array<EntityProposalAssetValuationProjectionType>>;
  assetValuationProjectionTypeNEQ?: InputMaybe<EntityProposalAssetValuationProjectionType>;
  assetValuationProjectionTypeNotIn?: InputMaybe<Array<EntityProposalAssetValuationProjectionType>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** proposal edge predicates */
  hasProposal?: InputMaybe<Scalars['Boolean']['input']>;
  /** proposal_scenarios edge predicates */
  hasProposalScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasProposalScenariosWith?: InputMaybe<Array<ProposalScenarioWhereInput>>;
  hasProposalWith?: InputMaybe<Array<ProposalWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EntityProposalWhereInput>;
  or?: InputMaybe<Array<EntityProposalWhereInput>>;
  /** tax_drag field predicates */
  taxDrag?: InputMaybe<Scalars['Percent']['input']>;
  taxDragGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDragLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDragNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export enum EntityReportColumn {
  EntityAssetsSummary = 'ENTITY_ASSETS_SUMMARY',
  EntityAssetsValue = 'ENTITY_ASSETS_VALUE',
  EntityName = 'ENTITY_NAME',
  GratNextAnnuityDueDate = 'GRAT_NEXT_ANNUITY_DUE_DATE',
  GratRemainingAnnuityAmounts = 'GRAT_REMAINING_ANNUITY_AMOUNTS',
  GratRemainingAnnuityPayments = 'GRAT_REMAINING_ANNUITY_PAYMENTS',
  GratSurplus = 'GRAT_SURPLUS'
}

export type EntityReportOptions = {
  columns: Array<EntityReportColumn>;
  /** This currently only supports GRATs */
  entityKinds: Array<EntityKind>;
};

/** EntityStage is enum for the field stage */
export enum EntityStage {
  Active = 'ACTIVE',
  AiCreating = 'AI_CREATING',
  AiCreationFailed = 'AI_CREATION_FAILED',
  AiNeedsReview = 'AI_NEEDS_REVIEW',
  Archived = 'ARCHIVED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Implementation = 'IMPLEMENTATION',
  ReadyForProposal = 'READY_FOR_PROPOSAL'
}

export type EntityStateTax = Node & {
  __typename?: 'EntityStateTax';
  createdAt: Scalars['Time']['output'];
  entity?: Maybe<Entity>;
  id: Scalars['ID']['output'];
  inEstateStatus: EntityInEstateStatus;
  stateCode: StateCode;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  testamentaryEntity?: Maybe<TestamentaryEntity>;
  updatedAt: Scalars['Time']['output'];
};

/** Ordering options for EntityStateTax connections */
export type EntityStateTaxOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EntityStateTaxes. */
  field: EntityStateTaxOrderField;
};

/** Properties by which EntityStateTax connections can be ordered. */
export enum EntityStateTaxOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * EntityStateTaxWhereInput is used for filtering EntityStateTax objects.
 * Input was generated by ent.
 */
export type EntityStateTaxWhereInput = {
  and?: InputMaybe<Array<EntityStateTaxWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** testamentary_entity edge predicates */
  hasTestamentaryEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasTestamentaryEntityWith?: InputMaybe<Array<TestamentaryEntityWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  not?: InputMaybe<EntityStateTaxWhereInput>;
  or?: InputMaybe<Array<EntityStateTaxWhereInput>>;
  /** state_code field predicates */
  stateCode?: InputMaybe<StateCode>;
  stateCodeIn?: InputMaybe<Array<StateCode>>;
  stateCodeNEQ?: InputMaybe<StateCode>;
  stateCodeNotIn?: InputMaybe<Array<StateCode>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * Entity is a generic type that can be used to represent any entity that
 * has a name and an ID and a related advisor client.
 */
export type EntitySubtype = {
  currentValue: Scalars['CurrencyUSD']['output'];
  designerAccount?: Maybe<Account>;
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  entityExists: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
};

export type EntityTask = Node & {
  __typename?: 'EntityTask';
  assignedBy?: Maybe<User>;
  assignedTo?: Maybe<User>;
  /** the reason this task was cancelled. if present, the task status is computed as canceled */
  cancelledReason?: Maybe<Scalars['String']['output']>;
  /** the timestamp at which this task was marked as complete */
  completedAt?: Maybe<Scalars['Time']['output']>;
  completedBy?: Maybe<User>;
  completionState: EntityTaskCompletionState;
  createdAt: Scalars['Time']['output'];
  /**
   * dependency_checksum is an optional validation mechanism where we summarize the underlying data that supports this task.
   * If the checksum changes, then we consider this task invalid.
   */
  dependencyChecksum?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  /** the date at which this task is due */
  dueAt: Scalars['Time']['output'];
  entity: Entity;
  id: Scalars['ID']['output'];
  ilitCrummeyLetter?: Maybe<IlitCrummeyLetter>;
  loggedTransfers?: Maybe<Array<LoggedTransfer>>;
  premiumPayment?: Maybe<InsurancePremiumPayment>;
  reminderEmailNotificationRecord?: Maybe<Array<ReminderEmailNotificationRecord>>;
  shortTitle: Scalars['String']['output'];
  taskDependencies?: Maybe<Array<EntityTask>>;
  taskDependents?: Maybe<Array<EntityTask>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  type: EntityTaskType;
  updatedAt: Scalars['Time']['output'];
};

export enum EntityTaskCompletionState {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  CompleteWithErrors = 'COMPLETE_WITH_ERRORS',
  Incomplete = 'INCOMPLETE'
}

/** A connection to a list of items. */
export type EntityTaskConnection = {
  __typename?: 'EntityTaskConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EntityTaskEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EntityTaskEdge = {
  __typename?: 'EntityTaskEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EntityTask>;
};

/** Ordering options for EntityTask connections */
export type EntityTaskOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EntityTasks. */
  field: EntityTaskOrderField;
};

/** Properties by which EntityTask connections can be ordered. */
export enum EntityTaskOrderField {
  CompletedAt = 'completed_at',
  CreatedAt = 'created_at',
  DueAt = 'due_at',
  UpdatedAt = 'updated_at'
}

/** EntityTaskType is enum for the field type */
export enum EntityTaskType {
  GratAnnuityPayment = 'GRAT_ANNUITY_PAYMENT',
  GratAttorneySelection = 'GRAT_ATTORNEY_SELECTION',
  GratCompleteDesignSummary = 'GRAT_COMPLETE_DESIGN_SUMMARY',
  GratConfirmAnnuities = 'GRAT_CONFIRM_ANNUITIES',
  GratConfirmAssetTransfer = 'GRAT_CONFIRM_ASSET_TRANSFER',
  GratConfirmFunding = 'GRAT_CONFIRM_FUNDING',
  GratConfirmTrustDetails = 'GRAT_CONFIRM_TRUST_DETAILS',
  GratCreateTrustAccount = 'GRAT_CREATE_TRUST_ACCOUNT',
  GratDistributeTrustRemainder = 'GRAT_DISTRIBUTE_TRUST_REMAINDER',
  GratFinalizeImplementation = 'GRAT_FINALIZE_IMPLEMENTATION',
  GratSetSubstitutionThresholds = 'GRAT_SET_SUBSTITUTION_THRESHOLDS',
  GratShareDesignSummary = 'GRAT_SHARE_DESIGN_SUMMARY',
  GratUploadTrustDocument = 'GRAT_UPLOAD_TRUST_DOCUMENT',
  IlitMakeAnnualExclusionGifts = 'ILIT_MAKE_ANNUAL_EXCLUSION_GIFTS',
  IlitMakePremiumPayment = 'ILIT_MAKE_PREMIUM_PAYMENT',
  IlitSendCrummeyLetters = 'ILIT_SEND_CRUMMEY_LETTERS'
}

/**
 * EntityTaskWhereInput is used for filtering EntityTask objects.
 * Input was generated by ent.
 */
export type EntityTaskWhereInput = {
  and?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** cancelled_reason field predicates */
  cancelledReason?: InputMaybe<Scalars['String']['input']>;
  cancelledReasonContains?: InputMaybe<Scalars['String']['input']>;
  cancelledReasonContainsFold?: InputMaybe<Scalars['String']['input']>;
  cancelledReasonEqualFold?: InputMaybe<Scalars['String']['input']>;
  cancelledReasonGT?: InputMaybe<Scalars['String']['input']>;
  cancelledReasonGTE?: InputMaybe<Scalars['String']['input']>;
  cancelledReasonHasPrefix?: InputMaybe<Scalars['String']['input']>;
  cancelledReasonHasSuffix?: InputMaybe<Scalars['String']['input']>;
  cancelledReasonIn?: InputMaybe<Array<Scalars['String']['input']>>;
  cancelledReasonIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  cancelledReasonLT?: InputMaybe<Scalars['String']['input']>;
  cancelledReasonLTE?: InputMaybe<Scalars['String']['input']>;
  cancelledReasonNEQ?: InputMaybe<Scalars['String']['input']>;
  cancelledReasonNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  cancelledReasonNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** completed_at field predicates */
  completedAt?: InputMaybe<Scalars['Time']['input']>;
  completedAtGT?: InputMaybe<Scalars['Time']['input']>;
  completedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  completedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  completedAtLT?: InputMaybe<Scalars['Time']['input']>;
  completedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  completedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  completedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** dependency_checksum field predicates */
  dependencyChecksum?: InputMaybe<Scalars['String']['input']>;
  dependencyChecksumContains?: InputMaybe<Scalars['String']['input']>;
  dependencyChecksumContainsFold?: InputMaybe<Scalars['String']['input']>;
  dependencyChecksumEqualFold?: InputMaybe<Scalars['String']['input']>;
  dependencyChecksumGT?: InputMaybe<Scalars['String']['input']>;
  dependencyChecksumGTE?: InputMaybe<Scalars['String']['input']>;
  dependencyChecksumHasPrefix?: InputMaybe<Scalars['String']['input']>;
  dependencyChecksumHasSuffix?: InputMaybe<Scalars['String']['input']>;
  dependencyChecksumIn?: InputMaybe<Array<Scalars['String']['input']>>;
  dependencyChecksumIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dependencyChecksumLT?: InputMaybe<Scalars['String']['input']>;
  dependencyChecksumLTE?: InputMaybe<Scalars['String']['input']>;
  dependencyChecksumNEQ?: InputMaybe<Scalars['String']['input']>;
  dependencyChecksumNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  dependencyChecksumNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** due_at field predicates */
  dueAt?: InputMaybe<Scalars['Time']['input']>;
  dueAtGT?: InputMaybe<Scalars['Time']['input']>;
  dueAtGTE?: InputMaybe<Scalars['Time']['input']>;
  dueAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  dueAtLT?: InputMaybe<Scalars['Time']['input']>;
  dueAtLTE?: InputMaybe<Scalars['Time']['input']>;
  dueAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  dueAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** assigned_by edge predicates */
  hasAssignedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssignedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** assigned_to edge predicates */
  hasAssignedTo?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssignedToWith?: InputMaybe<Array<UserWhereInput>>;
  /** completed_by edge predicates */
  hasCompletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  hasCompletedByWith?: InputMaybe<Array<UserWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** ilit_crummey_letter edge predicates */
  hasIlitCrummeyLetter?: InputMaybe<Scalars['Boolean']['input']>;
  hasIlitCrummeyLetterWith?: InputMaybe<Array<IlitCrummeyLetterWhereInput>>;
  /** logged_transfers edge predicates */
  hasLoggedTransfers?: InputMaybe<Scalars['Boolean']['input']>;
  hasLoggedTransfersWith?: InputMaybe<Array<LoggedTransferWhereInput>>;
  /** premium_payment edge predicates */
  hasPremiumPayment?: InputMaybe<Scalars['Boolean']['input']>;
  hasPremiumPaymentWith?: InputMaybe<Array<InsurancePremiumPaymentWhereInput>>;
  /** reminder_email_notification_record edge predicates */
  hasReminderEmailNotificationRecord?: InputMaybe<Scalars['Boolean']['input']>;
  hasReminderEmailNotificationRecordWith?: InputMaybe<Array<ReminderEmailNotificationRecordWhereInput>>;
  /** task_dependencies edge predicates */
  hasTaskDependencies?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaskDependenciesWith?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** task_dependents edge predicates */
  hasTaskDependents?: InputMaybe<Scalars['Boolean']['input']>;
  hasTaskDependentsWith?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EntityTaskWhereInput>;
  or?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** type field predicates */
  type?: InputMaybe<EntityTaskType>;
  typeIn?: InputMaybe<Array<EntityTaskType>>;
  typeNEQ?: InputMaybe<EntityTaskType>;
  typeNotIn?: InputMaybe<Array<EntityTaskType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EntityValuationErrorMessage = {
  __typename?: 'EntityValuationErrorMessage';
  entity: Entity;
  errorMessage: Scalars['String']['output'];
};

/**
 * EntityWhereInput is used for filtering Entity objects.
 * Input was generated by ent.
 */
export type EntityWhereInput = {
  /** addepar_entity_id field predicates */
  addeparEntityID?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDGT?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDGTE?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  addeparEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparEntityIDLT?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDLTE?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDNEQ?: InputMaybe<Scalars['Int']['input']>;
  addeparEntityIDNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  addeparEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** addepar_linked_to_nongrantor_entity field predicates */
  addeparLinkedToNongrantorEntity?: InputMaybe<Scalars['Boolean']['input']>;
  addeparLinkedToNongrantorEntityIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparLinkedToNongrantorEntityNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  addeparLinkedToNongrantorEntityNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<EntityWhereInput>>;
  /** attribution_source field predicates */
  attributionSource?: InputMaybe<EntityAttributionSource>;
  attributionSourceIn?: InputMaybe<Array<EntityAttributionSource>>;
  attributionSourceIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  attributionSourceNEQ?: InputMaybe<EntityAttributionSource>;
  attributionSourceNotIn?: InputMaybe<Array<EntityAttributionSource>>;
  attributionSourceNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** activities edge predicates */
  hasActivities?: InputMaybe<Scalars['Boolean']['input']>;
  hasActivitiesWith?: InputMaybe<Array<ActivityWhereInput>>;
  /** async_jobs edge predicates */
  hasAsyncJobs?: InputMaybe<Scalars['Boolean']['input']>;
  hasAsyncJobsWith?: InputMaybe<Array<AsyncJobWhereInput>>;
  /** cashflows edge predicates */
  hasCashflows?: InputMaybe<Scalars['Boolean']['input']>;
  hasCashflowsWith?: InputMaybe<Array<CashflowWhereInput>>;
  /** ccorp_business_entity edge predicates */
  hasCcorpBusinessEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasCcorpBusinessEntityWith?: InputMaybe<Array<CCorpBusinessEntityWhereInput>>;
  /** clt_trust edge predicates */
  hasCltTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasCltTrustWith?: InputMaybe<Array<CltTrustWhereInput>>;
  /** crt_trust edge predicates */
  hasCrtTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasCrtTrustWith?: InputMaybe<Array<CrtTrustWhereInput>>;
  /** custodial_personal_account edge predicates */
  hasCustodialPersonalAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasCustodialPersonalAccountWith?: InputMaybe<Array<CustodialPersonalAccountWhereInput>>;
  /** default_document edge predicates */
  hasDefaultDocument?: InputMaybe<Scalars['Boolean']['input']>;
  hasDefaultDocumentWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** documents edge predicates */
  hasDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentsWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** donor_advised_fund edge predicates */
  hasDonorAdvisedFund?: InputMaybe<Scalars['Boolean']['input']>;
  hasDonorAdvisedFundWith?: InputMaybe<Array<DonorAdvisedFundWhereInput>>;
  /** email_notification_records edge predicates */
  hasEmailNotificationRecords?: InputMaybe<Scalars['Boolean']['input']>;
  hasEmailNotificationRecordsWith?: InputMaybe<Array<ReminderEmailNotificationRecordWhereInput>>;
  /** entity_proposals edge predicates */
  hasEntityProposals?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityProposalsWith?: InputMaybe<Array<EntityProposalWhereInput>>;
  /** estate_waterfall_filters edge predicates */
  hasEstateWaterfallFilters?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstateWaterfallFiltersWith?: InputMaybe<Array<EstateWaterfallVisualizationFilterWhereInput>>;
  /** gp_business_entity edge predicates */
  hasGpBusinessEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasGpBusinessEntityWith?: InputMaybe<Array<GpBusinessEntityWhereInput>>;
  /** grat_trust edge predicates */
  hasGratTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasGratTrustWith?: InputMaybe<Array<GratTrustWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** ilit_trust edge predicates */
  hasIlitTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasIlitTrustWith?: InputMaybe<Array<IlitTrustWhereInput>>;
  /** individual_personal_account edge predicates */
  hasIndividualPersonalAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasIndividualPersonalAccountWith?: InputMaybe<Array<IndividualPersonalAccountWhereInput>>;
  /** insurance_personal_account edge predicates */
  hasInsurancePersonalAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasInsurancePersonalAccountWith?: InputMaybe<Array<InsurancePersonalAccountWhereInput>>;
  /** integration_entities edge predicates */
  hasIntegrationEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasIntegrationEntitiesWith?: InputMaybe<Array<IntegrationEntityWhereInput>>;
  /** irrevocable_trust edge predicates */
  hasIrrevocableTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasIrrevocableTrustWith?: InputMaybe<Array<IrrevocableTrustWhereInput>>;
  /** joint_personal_account edge predicates */
  hasJointPersonalAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasJointPersonalAccountWith?: InputMaybe<Array<JointPersonalAccountWhereInput>>;
  /** liabilities_owed edge predicates */
  hasLiabilitiesOwed?: InputMaybe<Scalars['Boolean']['input']>;
  hasLiabilitiesOwedWith?: InputMaybe<Array<LiabilityWhereInput>>;
  /** llc_business_entity edge predicates */
  hasLlcBusinessEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasLlcBusinessEntityWith?: InputMaybe<Array<LlcBusinessEntityWhereInput>>;
  /** lp_business_entity edge predicates */
  hasLpBusinessEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasLpBusinessEntityWith?: InputMaybe<Array<LpBusinessEntityWhereInput>>;
  /** owned_by_stakes edge predicates */
  hasOwnedByStakes?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnedByStakesWith?: InputMaybe<Array<OwnershipStakeWhereInput>>;
  /** owned_ownership_stakes edge predicates */
  hasOwnedOwnershipStakes?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnedOwnershipStakesWith?: InputMaybe<Array<OwnershipStakeWhereInput>>;
  /** private_foundation edge predicates */
  hasPrivateFoundation?: InputMaybe<Scalars['Boolean']['input']>;
  hasPrivateFoundationWith?: InputMaybe<Array<PrivateFoundationWhereInput>>;
  /** qprt_trust edge predicates */
  hasQprtTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasQprtTrustWith?: InputMaybe<Array<QprtTrustWhereInput>>;
  /** qualified_tuition_personal_account edge predicates */
  hasQualifiedTuitionPersonalAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasQualifiedTuitionPersonalAccountWith?: InputMaybe<Array<QualifiedTuitionPersonalAccountWhereInput>>;
  /** retirement_personal_account edge predicates */
  hasRetirementPersonalAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasRetirementPersonalAccountWith?: InputMaybe<Array<RetirementPersonalAccountWhereInput>>;
  /** revocable_trust edge predicates */
  hasRevocableTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasRevocableTrustWith?: InputMaybe<Array<RevocableTrustWhereInput>>;
  /** scorp_business_entity edge predicates */
  hasScorpBusinessEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasScorpBusinessEntityWith?: InputMaybe<Array<SCorpBusinessEntityWhereInput>>;
  /** slat_trust edge predicates */
  hasSlatTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasSlatTrustWith?: InputMaybe<Array<SlatTrustWhereInput>>;
  /** sole_proprietorship_business_entity edge predicates */
  hasSoleProprietorshipBusinessEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasSoleProprietorshipBusinessEntityWith?: InputMaybe<Array<SoleProprietorshipBusinessEntityWhereInput>>;
  /** state_taxes edge predicates */
  hasStateTaxes?: InputMaybe<Scalars['Boolean']['input']>;
  hasStateTaxesWith?: InputMaybe<Array<EntityStateTaxWhereInput>>;
  /** tasks edge predicates */
  hasTasks?: InputMaybe<Scalars['Boolean']['input']>;
  hasTasksWith?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<EntityKind>;
  kindIn?: InputMaybe<Array<EntityKind>>;
  kindNEQ?: InputMaybe<EntityKind>;
  kindNotIn?: InputMaybe<Array<EntityKind>>;
  not?: InputMaybe<EntityWhereInput>;
  or?: InputMaybe<Array<EntityWhereInput>>;
  /** stage field predicates */
  stage?: InputMaybe<EntityStage>;
  stageIn?: InputMaybe<Array<EntityStage>>;
  stageNEQ?: InputMaybe<EntityStage>;
  stageNotIn?: InputMaybe<Array<EntityStage>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export enum ErrorCodes {
  UserAlreadyExists = 'USER_ALREADY_EXISTS'
}

export type EstateTaxScenario = Node & {
  __typename?: 'EstateTaxScenario';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  firstDeathPayoutOrder: Array<Node>;
  firstDeathPayoutOrderMissingEntities: Array<Node>;
  /** This estate tax scenario corresponds to the death of the associated grantor. */
  firstGrantorDeath: ClientProfile;
  /** This estate tax scenario corresponds to this Household. */
  household?: Maybe<Household>;
  id: Scalars['ID']['output'];
  isExemptionPortable: Scalars['Boolean']['output'];
  payoutKind: EstateTaxScenarioPayoutKind;
  secondDeathPayoutOrder?: Maybe<Array<Node>>;
  secondDeathPayoutOrderMissingEntities?: Maybe<Array<Node>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type EstateTaxScenarioConnection = {
  __typename?: 'EstateTaxScenarioConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstateTaxScenarioEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstateTaxScenarioEdge = {
  __typename?: 'EstateTaxScenarioEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstateTaxScenario>;
};

/** Ordering options for EstateTaxScenario connections */
export type EstateTaxScenarioOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstateTaxScenarios. */
  field: EstateTaxScenarioOrderField;
};

/** Properties by which EstateTaxScenario connections can be ordered. */
export enum EstateTaxScenarioOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** EstateTaxScenarioPayoutKind is enum for the field payout_kind */
export enum EstateTaxScenarioPayoutKind {
  Custom = 'CUSTOM',
  ProRata = 'PRO_RATA'
}

export type EstateTaxScenarioPossibleEntitiesInput = {
  clientProfileDeathConsidered: Scalars['ID']['input'];
  firstGrantorDeath: Scalars['ID']['input'];
};

/**
 * EstateTaxScenarioWhereInput is used for filtering EstateTaxScenario objects.
 * Input was generated by ent.
 */
export type EstateTaxScenarioWhereInput = {
  and?: InputMaybe<Array<EstateTaxScenarioWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** first_grantor_death edge predicates */
  hasFirstGrantorDeath?: InputMaybe<Scalars['Boolean']['input']>;
  hasFirstGrantorDeathWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_exemption_portable field predicates */
  isExemptionPortable?: InputMaybe<Scalars['Boolean']['input']>;
  isExemptionPortableNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<EstateTaxScenarioWhereInput>;
  or?: InputMaybe<Array<EstateTaxScenarioWhereInput>>;
  /** payout_kind field predicates */
  payoutKind?: InputMaybe<EstateTaxScenarioPayoutKind>;
  payoutKindIn?: InputMaybe<Array<EstateTaxScenarioPayoutKind>>;
  payoutKindNEQ?: InputMaybe<EstateTaxScenarioPayoutKind>;
  payoutKindNotIn?: InputMaybe<Array<EstateTaxScenarioPayoutKind>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type EstateWaterfall = Node & {
  __typename?: 'EstateWaterfall';
  /** Assume assets will grow/shrink by this percentage each year. */
  assetGrowthReturn?: Maybe<Scalars['Percent']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  /** Lifetime exemption grows by this around each year. */
  exemptionGrowthRate?: Maybe<Scalars['Percent']['output']>;
  /** When present, the filter to use for this waterfall. */
  filter?: Maybe<EstateWaterfallVisualizationFilter>;
  /** This disposition scenario corresponds to the death of the associated grantor. */
  firstGrantorDeath: ClientProfile;
  /** Projected year that the first grantor dies. */
  firstGrantorDeathYear?: Maybe<Scalars['Int']['output']>;
  /** This estate waterfall corresponds to this Household. */
  household?: Maybe<Household>;
  hypotheticalScenarios?: Maybe<Array<EstateWaterfall>>;
  hypotheticalTransfers: EstateWaterfallHypotheticalTransferConnection;
  id: Scalars['ID']['output'];
  isTwoGrantor: Scalars['Boolean']['output'];
  /** the definitions of the nodes in the graph */
  nodeConfigurations: Array<EntityGraphNodeConfiguration>;
  /** An estate waterfall can have hypothetical scenarios */
  parent?: Maybe<EstateWaterfall>;
  /** Projected year that the second grantor dies. Only applicable when there are two grantors. */
  secondGrantorDeathYear?: Maybe<Scalars['Int']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
  visualization: EstateWaterfallViz;
  /** Used to set various options in the visualization. */
  visualizationConfig?: Maybe<EstateWaterfallVisualizationConfig>;
  visualizationGroups: GraphVisualizationGroupConnection;
  visualizationWithProjections: EstateWaterfallViz;
  /** If true, exemption amount is halved at the end of 2025, and continues growing at the same rate. */
  willExemptionSunset?: Maybe<Scalars['Boolean']['output']>;
};


export type EstateWaterfallHypotheticalTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EstateWaterfallHypotheticalTransferOrder>>;
  where?: InputMaybe<EstateWaterfallHypotheticalTransferWhereInput>;
};


export type EstateWaterfallVisualizationArgs = {
  opts?: InputMaybe<EstateWaterfallVizConfigOptsInput>;
};


export type EstateWaterfallVisualizationGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GraphVisualizationGroupOrder>>;
  where?: InputMaybe<GraphVisualizationGroupWhereInput>;
};


export type EstateWaterfallVisualizationWithProjectionsArgs = {
  input?: InputMaybe<EstateWaterfallProjectionInput>;
  opts?: InputMaybe<EstateWaterfallVizConfigOptsInput>;
};

/** A connection to a list of items. */
export type EstateWaterfallConnection = {
  __typename?: 'EstateWaterfallConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstateWaterfallEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstateWaterfallEdge = {
  __typename?: 'EstateWaterfallEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstateWaterfall>;
};

export enum EstateWaterfallEdgeKind {
  Disposition = 'DISPOSITION',
  Pourover = 'POUROVER',
  Transfer = 'TRANSFER'
}

export type EstateWaterfallHypotheticalTransfer = Node & {
  __typename?: 'EstateWaterfallHypotheticalTransfer';
  /** Assume transfers will grow/shrink by this percentage each year. Only valid for recurring transfers. */
  assetGrowthReturn?: Maybe<Scalars['Percent']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** If the destination is an entity, this is the entity. */
  destinationEntity?: Maybe<Entity>;
  /** If the destination is an individual, this is the individual. */
  destinationIndividual?: Maybe<ClientProfile>;
  /** The kind of destination for this transfer. */
  destinationKind: EstateWaterfallHypotheticalTransferDestinationKind;
  /** If the destination is an organization, this is the organization. */
  destinationOrganization?: Maybe<ClientOrganization>;
  /** The year this transfer ends. Ignored if start_year is not set. */
  endYear?: Maybe<Scalars['Int']['output']>;
  /**
   * This is the list of grantors that made the gift.
   * Will only be read if the transfer tax kind is GRANTOR_TAXABLE_GIFT.
   * Can set one or both grantors.
   * MUST NOT be empty if the transfer tax kind is GRANTOR_TAXABLE_GIFT.
   */
  giftingGrantors?: Maybe<Array<ClientProfile>>;
  id: Scalars['ID']['output'];
  /** If true, this is a GST gift. Only valid when transfer_tax_kind is GRANTOR_TAXABLE_GIFT. */
  isGstGift: Scalars['Boolean']['output'];
  /** If the source is an entity, this is the entity. */
  sourceEntity?: Maybe<Entity>;
  /** If the source is an individual, this is the individual. */
  sourceIndividual?: Maybe<ClientProfile>;
  /** The insurance policy to be transferred. If present, the policy must belong to the source. */
  sourceInsurancePolicy?: Maybe<InsurancePolicy>;
  /** The kind of the source for this transfer. */
  sourceKind: EstateWaterfallHypotheticalTransferSourceKind;
  /** DEPRECATED: use source_owned_entity instead */
  sourceOwnedBusinessEntity?: Maybe<Entity>;
  /**
   * If we want to transfer assets specifically related to an owned business entity, specify the entity here.
   * 			There must be an ownership stake for this to take effect.
   */
  sourceOwnedEntity?: Maybe<Entity>;
  /** DEPRECATED: use source_owned_entity instead */
  sourceOwnershipStake?: Maybe<OwnershipStake>;
  /** The year this transfer starts. If start_year is not set, it is assumed to be a one time transfer happening in the current year. If start_year is set, this is a yearly recurring transfer until end_year is reached. */
  startYear?: Maybe<Scalars['Int']['output']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  /** Taxable value of the transfer. If nil, it will be calculated. */
  taxableValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** The order of this transfer in the list of transfers for a given source. */
  transferOrder: Scalars['Int']['output'];
  transferTaxKind: EstateWaterfallHypotheticalTransferTransferTaxKind;
  /** Value of this transfer. */
  transferValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type EstateWaterfallHypotheticalTransferConnection = {
  __typename?: 'EstateWaterfallHypotheticalTransferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstateWaterfallHypotheticalTransferEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** EstateWaterfallHypotheticalTransferDestinationKind is enum for the field destination_kind */
export enum EstateWaterfallHypotheticalTransferDestinationKind {
  Entity = 'ENTITY',
  External = 'EXTERNAL',
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION'
}

/** An edge in a connection. */
export type EstateWaterfallHypotheticalTransferEdge = {
  __typename?: 'EstateWaterfallHypotheticalTransferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstateWaterfallHypotheticalTransfer>;
};

/** Ordering options for EstateWaterfallHypotheticalTransfer connections */
export type EstateWaterfallHypotheticalTransferOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstateWaterfallHypotheticalTransfers. */
  field: EstateWaterfallHypotheticalTransferOrderField;
};

/** Properties by which EstateWaterfallHypotheticalTransfer connections can be ordered. */
export enum EstateWaterfallHypotheticalTransferOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** EstateWaterfallHypotheticalTransferSourceKind is enum for the field source_kind */
export enum EstateWaterfallHypotheticalTransferSourceKind {
  Entity = 'ENTITY',
  External = 'EXTERNAL',
  Individual = 'INDIVIDUAL'
}

/** EstateWaterfallHypotheticalTransferTransferTaxKind is enum for the field transfer_tax_kind */
export enum EstateWaterfallHypotheticalTransferTransferTaxKind {
  AnualExclusionGift = 'ANUAL_EXCLUSION_GIFT',
  CharitableGift = 'CHARITABLE_GIFT',
  GenericNonTaxableGift = 'GENERIC_NON_TAXABLE_GIFT',
  GrantorTaxableGift = 'GRANTOR_TAXABLE_GIFT',
  NonGiftTransfer = 'NON_GIFT_TRANSFER'
}

/**
 * EstateWaterfallHypotheticalTransferWhereInput is used for filtering EstateWaterfallHypotheticalTransfer objects.
 * Input was generated by ent.
 */
export type EstateWaterfallHypotheticalTransferWhereInput = {
  and?: InputMaybe<Array<EstateWaterfallHypotheticalTransferWhereInput>>;
  /** asset_growth_return field predicates */
  assetGrowthReturn?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnGT?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnGTE?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  assetGrowthReturnIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  assetGrowthReturnLT?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnLTE?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnNEQ?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  assetGrowthReturnNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** destination_kind field predicates */
  destinationKind?: InputMaybe<EstateWaterfallHypotheticalTransferDestinationKind>;
  destinationKindIn?: InputMaybe<Array<EstateWaterfallHypotheticalTransferDestinationKind>>;
  destinationKindNEQ?: InputMaybe<EstateWaterfallHypotheticalTransferDestinationKind>;
  destinationKindNotIn?: InputMaybe<Array<EstateWaterfallHypotheticalTransferDestinationKind>>;
  /** end_year field predicates */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  endYearGT?: InputMaybe<Scalars['Int']['input']>;
  endYearGTE?: InputMaybe<Scalars['Int']['input']>;
  endYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  endYearIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  endYearLT?: InputMaybe<Scalars['Int']['input']>;
  endYearLTE?: InputMaybe<Scalars['Int']['input']>;
  endYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  endYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  endYearNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** destination_entity edge predicates */
  hasDestinationEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasDestinationEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** destination_individual edge predicates */
  hasDestinationIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasDestinationIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** destination_organization edge predicates */
  hasDestinationOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasDestinationOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** gifting_grantors edge predicates */
  hasGiftingGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  hasGiftingGrantorsWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** source_entity edge predicates */
  hasSourceEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** source_individual edge predicates */
  hasSourceIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** source_insurance_policy edge predicates */
  hasSourceInsurancePolicy?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceInsurancePolicyWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** source_owned_business_entity edge predicates */
  hasSourceOwnedBusinessEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceOwnedBusinessEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** source_owned_entity edge predicates */
  hasSourceOwnedEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceOwnedEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** source_ownership_stake edge predicates */
  hasSourceOwnershipStake?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceOwnershipStakeWith?: InputMaybe<Array<OwnershipStakeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_gst_gift field predicates */
  isGstGift?: InputMaybe<Scalars['Boolean']['input']>;
  isGstGiftNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<EstateWaterfallHypotheticalTransferWhereInput>;
  or?: InputMaybe<Array<EstateWaterfallHypotheticalTransferWhereInput>>;
  /** source_kind field predicates */
  sourceKind?: InputMaybe<EstateWaterfallHypotheticalTransferSourceKind>;
  sourceKindIn?: InputMaybe<Array<EstateWaterfallHypotheticalTransferSourceKind>>;
  sourceKindNEQ?: InputMaybe<EstateWaterfallHypotheticalTransferSourceKind>;
  sourceKindNotIn?: InputMaybe<Array<EstateWaterfallHypotheticalTransferSourceKind>>;
  /** start_year field predicates */
  startYear?: InputMaybe<Scalars['Int']['input']>;
  startYearGT?: InputMaybe<Scalars['Int']['input']>;
  startYearGTE?: InputMaybe<Scalars['Int']['input']>;
  startYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  startYearIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  startYearLT?: InputMaybe<Scalars['Int']['input']>;
  startYearLTE?: InputMaybe<Scalars['Int']['input']>;
  startYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  startYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  startYearNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** taxable_value field predicates */
  taxableValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  taxableValueIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxableValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  taxableValueNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** transfer_order field predicates */
  transferOrder?: InputMaybe<Scalars['Int']['input']>;
  transferOrderGT?: InputMaybe<Scalars['Int']['input']>;
  transferOrderGTE?: InputMaybe<Scalars['Int']['input']>;
  transferOrderIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  transferOrderLT?: InputMaybe<Scalars['Int']['input']>;
  transferOrderLTE?: InputMaybe<Scalars['Int']['input']>;
  transferOrderNEQ?: InputMaybe<Scalars['Int']['input']>;
  transferOrderNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** transfer_tax_kind field predicates */
  transferTaxKind?: InputMaybe<EstateWaterfallHypotheticalTransferTransferTaxKind>;
  transferTaxKindIn?: InputMaybe<Array<EstateWaterfallHypotheticalTransferTransferTaxKind>>;
  transferTaxKindNEQ?: InputMaybe<EstateWaterfallHypotheticalTransferTransferTaxKind>;
  transferTaxKindNotIn?: InputMaybe<Array<EstateWaterfallHypotheticalTransferTransferTaxKind>>;
  /** transfer_value field predicates */
  transferValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  transferValueIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  transferValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  transferValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  transferValueNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for EstateWaterfall connections */
export type EstateWaterfallOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstateWaterfalls. */
  field: EstateWaterfallOrderField;
};

/** Properties by which EstateWaterfall connections can be ordered. */
export enum EstateWaterfallOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type EstateWaterfallProjectionInput = {
  assetGrowthReturn?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRate?: InputMaybe<Scalars['Percent']['input']>;
  firstGrantorDeathYear?: InputMaybe<Scalars['Int']['input']>;
  secondGrantorDeathYear?: InputMaybe<Scalars['Int']['input']>;
  sourceWaterfallID?: InputMaybe<Scalars['ID']['input']>;
  willExemptionSunset?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EstateWaterfallVisualizationConfig = Node & {
  __typename?: 'EstateWaterfallVisualizationConfig';
  /** If true, always shows edge labels. */
  alwaysShowEdgeLabels: Scalars['Boolean']['output'];
  createdAt: Scalars['Time']['output'];
  /** If true, hides edges. */
  hideEdges: Scalars['Boolean']['output'];
  /** If true, hides list of entities in a group. */
  hideEntitiesListInGroups: Scalars['Boolean']['output'];
  /** If true, hides dollar values on the entity tile. */
  hideEntityValues: Scalars['Boolean']['output'];
  /** If true, hides all tax tiles. */
  hideTaxTiles: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** If true, shows zero dollar tiles. */
  showZeroDollarTiles: Scalars['Boolean']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updateUser: User;
  updatedAt: Scalars['Time']['output'];
  updatedBy: Scalars['ID']['output'];
  /** If true, uses brand colors instead of luminary defaults. */
  useBrandColors: Scalars['Boolean']['output'];
  waterfall?: Maybe<EstateWaterfall>;
};

/** A connection to a list of items. */
export type EstateWaterfallVisualizationConfigConnection = {
  __typename?: 'EstateWaterfallVisualizationConfigConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstateWaterfallVisualizationConfigEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstateWaterfallVisualizationConfigEdge = {
  __typename?: 'EstateWaterfallVisualizationConfigEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstateWaterfallVisualizationConfig>;
};

/** Ordering options for EstateWaterfallVisualizationConfig connections */
export type EstateWaterfallVisualizationConfigOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstateWaterfallVisualizationConfigs. */
  field: EstateWaterfallVisualizationConfigOrderField;
};

/** Properties by which EstateWaterfallVisualizationConfig connections can be ordered. */
export enum EstateWaterfallVisualizationConfigOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * EstateWaterfallVisualizationConfigWhereInput is used for filtering EstateWaterfallVisualizationConfig objects.
 * Input was generated by ent.
 */
export type EstateWaterfallVisualizationConfigWhereInput = {
  /** always_show_edge_labels field predicates */
  alwaysShowEdgeLabels?: InputMaybe<Scalars['Boolean']['input']>;
  alwaysShowEdgeLabelsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<EstateWaterfallVisualizationConfigWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** update_user edge predicates */
  hasUpdateUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** waterfall edge predicates */
  hasWaterfall?: InputMaybe<Scalars['Boolean']['input']>;
  hasWaterfallWith?: InputMaybe<Array<EstateWaterfallWhereInput>>;
  /** hide_edges field predicates */
  hideEdges?: InputMaybe<Scalars['Boolean']['input']>;
  hideEdgesNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** hide_entities_list_in_groups field predicates */
  hideEntitiesListInGroups?: InputMaybe<Scalars['Boolean']['input']>;
  hideEntitiesListInGroupsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** hide_entity_values field predicates */
  hideEntityValues?: InputMaybe<Scalars['Boolean']['input']>;
  hideEntityValuesNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** hide_tax_tiles field predicates */
  hideTaxTiles?: InputMaybe<Scalars['Boolean']['input']>;
  hideTaxTilesNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstateWaterfallVisualizationConfigWhereInput>;
  or?: InputMaybe<Array<EstateWaterfallVisualizationConfigWhereInput>>;
  /** show_zero_dollar_tiles field predicates */
  showZeroDollarTiles?: InputMaybe<Scalars['Boolean']['input']>;
  showZeroDollarTilesNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['ID']['input']>;
  updatedByContains?: InputMaybe<Scalars['ID']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByGT?: InputMaybe<Scalars['ID']['input']>;
  updatedByGTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedByLT?: InputMaybe<Scalars['ID']['input']>;
  updatedByLTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['ID']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** use_brand_colors field predicates */
  useBrandColors?: InputMaybe<Scalars['Boolean']['input']>;
  useBrandColorsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EstateWaterfallVisualizationFilter = Node & {
  __typename?: 'EstateWaterfallVisualizationFilter';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  includedClients: ClientProfileConnection;
  includedEntities: EntityConnection;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updateUser: User;
  updatedAt: Scalars['Time']['output'];
  updatedBy: Scalars['ID']['output'];
  user: User;
  waterfall: EstateWaterfall;
};


export type EstateWaterfallVisualizationFilterIncludedClientsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientProfileOrder>>;
  where?: InputMaybe<ClientProfileWhereInput>;
};


export type EstateWaterfallVisualizationFilterIncludedEntitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityOrder>>;
  where?: InputMaybe<EntityWhereInput>;
};

/** A connection to a list of items. */
export type EstateWaterfallVisualizationFilterConnection = {
  __typename?: 'EstateWaterfallVisualizationFilterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EstateWaterfallVisualizationFilterEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EstateWaterfallVisualizationFilterEdge = {
  __typename?: 'EstateWaterfallVisualizationFilterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<EstateWaterfallVisualizationFilter>;
};

/** Ordering options for EstateWaterfallVisualizationFilter connections */
export type EstateWaterfallVisualizationFilterOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order EstateWaterfallVisualizationFilters. */
  field: EstateWaterfallVisualizationFilterOrderField;
};

/** Properties by which EstateWaterfallVisualizationFilter connections can be ordered. */
export enum EstateWaterfallVisualizationFilterOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * EstateWaterfallVisualizationFilterWhereInput is used for filtering EstateWaterfallVisualizationFilter objects.
 * Input was generated by ent.
 */
export type EstateWaterfallVisualizationFilterWhereInput = {
  and?: InputMaybe<Array<EstateWaterfallVisualizationFilterWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** included_clients edge predicates */
  hasIncludedClients?: InputMaybe<Scalars['Boolean']['input']>;
  hasIncludedClientsWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** included_entities edge predicates */
  hasIncludedEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasIncludedEntitiesWith?: InputMaybe<Array<EntityWhereInput>>;
  /** update_user edge predicates */
  hasUpdateUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** waterfall edge predicates */
  hasWaterfall?: InputMaybe<Scalars['Boolean']['input']>;
  hasWaterfallWith?: InputMaybe<Array<EstateWaterfallWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstateWaterfallVisualizationFilterWhereInput>;
  or?: InputMaybe<Array<EstateWaterfallVisualizationFilterWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['ID']['input']>;
  updatedByContains?: InputMaybe<Scalars['ID']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByGT?: InputMaybe<Scalars['ID']['input']>;
  updatedByGTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedByLT?: InputMaybe<Scalars['ID']['input']>;
  updatedByLTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['ID']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type EstateWaterfallViz = {
  __typename?: 'EstateWaterfallViz';
  _cacheKey: Scalars['String']['output'];
  beforeFirstDeathTaxSummary?: Maybe<TaxSummary>;
  edges: Array<EstateWaterfallVizEdge>;
  estateTaxScenario?: Maybe<EstateTaxScenario>;
  firstDeathRemainingLifetimeExemption: Scalars['CurrencyUSD']['output'];
  firstDeathTaxSummary: TaxSummary;
  lifetimeExemptionAppliedTowardsGiftTax?: Maybe<Scalars['CurrencyUSD']['output']>;
  nodes: Array<EstateWaterfallVizNode>;
  secondDeathRemainingLifetimeExemption: Scalars['CurrencyUSD']['output'];
  secondDeathTaxSummary?: Maybe<TaxSummary>;
};

/**
 * EstateWaterfallVizConfigOptsInput is used to override values
 * for the waterfall visualization.
 */
export type EstateWaterfallVizConfigOptsInput = {
  /**
   * showZeroDollarTiles when nil, we use the default value as set by the EstateWaterfallVisualizationConfig.
   * when true, we show zero dollar edges, which implies zero dollar tiles are also shown.
   */
  showZeroDollarTiles?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EstateWaterfallVizEdge = {
  __typename?: 'EstateWaterfallVizEdge';
  associatedDispositiveProvisions?: Maybe<Array<Maybe<EstateWaterfallVizEdgeAssociatedDispositiveProvision>>>;
  /**
   * associatedPouroverDispositions are the dispositive provisions associated with this edge
   * that are part of a pourover template. When present, the destination can be considered a pourover
   * entity.
   */
  associatedPouroverDispositions?: Maybe<Array<Maybe<EstateWaterfallVizEdgeAssociatedDispositiveProvision>>>;
  associatedTransfers?: Maybe<Array<Maybe<EstateWaterfallVizEdgeAssociatedTransfer>>>;
  from: EstateWaterfallVizNode;
  kind: EstateWaterfallEdgeKind;
  to: EstateWaterfallVizNode;
  value: Scalars['CurrencyUSD']['output'];
};

export type EstateWaterfallVizEdgeAssociatedDispositiveProvision = {
  __typename?: 'EstateWaterfallVizEdgeAssociatedDispositiveProvision';
  dispositiveProvision: DispositiveProvision;
  value: Scalars['CurrencyUSD']['output'];
};

export type EstateWaterfallVizEdgeAssociatedTransfer = {
  __typename?: 'EstateWaterfallVizEdgeAssociatedTransfer';
  transfer: EstateWaterfallHypotheticalTransfer;
  value: Scalars['CurrencyUSD']['output'];
};

export type EstateWaterfallVizNode = GraphVisualizationNode & {
  __typename?: 'EstateWaterfallVizNode';
  /** _cacheKey is a unique key used for client-side caching. */
  _cacheKey: Scalars['String']['output'];
  /** addedDeathBenefit is the death benefit added to the node during this death event */
  addedDeathBenefit?: Maybe<Scalars['CurrencyUSD']['output']>;
  afterDeath: AfterDeath;
  /** assetValue is the value of the assets in this node. */
  assetValue: Scalars['CurrencyUSD']['output'];
  /** futureDeathBenefit is the value to be added to the node after the death of the grantors. */
  futureDeathBenefit?: Maybe<Scalars['CurrencyUSD']['output']>;
  group?: Maybe<GraphVisualizationGroup>;
  /**
   * The ID of the node.
   * This is a bit redundant given the `node` field, but it's useful.
   */
  id: Scalars['ID']['output'];
  /**
   * inEstateStatus lets us know if this node is in the estate or not.
   * if the underlying data is inconclusive, we default to in-estate.
   */
  inEstateStatus: EntityInEstateStatus;
  /**
   * isHidden is true if this node is hidden in the waterfall view due to
   * the associated estate waterfall visualization filter
   */
  isHidden: Scalars['Boolean']['output'];
  /** isHypothetical is true if this node is hypothetical and belongs to a hypothetical waterfall. */
  isHypothetical: Scalars['Boolean']['output'];
  /** liabilityValue is the value of the liabilities held by node. */
  liabilityValue: Scalars['CurrencyUSD']['output'];
  /**
   * The namespace for this node.
   * Always corresponds to afterDeath in the EW context.
   * This triplet should uniquely identify a node in the waterfall, important for client-side caching.
   */
  namespace?: Maybe<Scalars['String']['output']>;
  node: Node;
  /** We may or may not always have one of these */
  nodeConfiguration?: Maybe<EntityGraphNodeConfiguration>;
  /**
   * nonTaxableValue is the value of any transfers to charitable recipients, or
   * that use the unlimited marital exclusion
   */
  nonTaxableValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** stateInEstateStatus is the in-estate status of this node for applicable states. */
  stateInEstateStatus?: Maybe<Array<NodeStateInEstateStatus>>;
  /**
   * taxableValue is the value of this node that is subject to estate tax.
   * As long as estate tax rate is 40%: taxableValue * 40% = transferredToEstateTax.
   * For CUSTOM payout, this is: value - nonTaxableValue
   * For PRO-RATA payout, this is: value - exemptionApplied - nonTaxableValue
   */
  taxableValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  /**
   * transferredToEstateTax is the amount transferred to the estate tax
   * when we simulate the death of the corresponding grantor.
   */
  transferredToEstateTax?: Maybe<Scalars['CurrencyUSD']['output']>;
  /**
   * transferredToGiftingTax is the amount transferred to the gifting tax tile
   * when we simulate hypothetical transfers.
   * This is only present at death "current" for now.
   * This is only present for hypothetical waterfalls.
   */
  transferredToGiftingTax?: Maybe<Scalars['CurrencyUSD']['output']>;
  /**
   * value is the value of this node in the waterfall.
   * it can either be the current value of the node (afterDeathNone)
   * or the amount transferred into the entity (or remaining in the entity)
   * after the death of a grantor (afterDeathFirst or afterDeathSecond)
   */
  value: Scalars['CurrencyUSD']['output'];
  /**
   * valueBeforeDispositions is the value of this node before any dispositions
   * at death. For projection waterfalls, this typically includes the appreciated assets.
   * This property is absent for nodes after the last death.
   */
  valueBeforeDispositions?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** valueBeforePouroverWills is the value of this node before any pourover wills were applied. */
  valueBeforePouroverWills?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** valueBeforeTransfers is the value of this node before any transfers */
  valueBeforeTransfers?: Maybe<Scalars['CurrencyUSD']['output']>;
  waterfallID: Scalars['ID']['output'];
};

/**
 * EstateWaterfallWhereInput is used for filtering EstateWaterfall objects.
 * Input was generated by ent.
 */
export type EstateWaterfallWhereInput = {
  and?: InputMaybe<Array<EstateWaterfallWhereInput>>;
  /** asset_growth_return field predicates */
  assetGrowthReturn?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnGT?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnGTE?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  assetGrowthReturnIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  assetGrowthReturnLT?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnLTE?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnNEQ?: InputMaybe<Scalars['Percent']['input']>;
  assetGrowthReturnNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  assetGrowthReturnNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** exemption_growth_rate field predicates */
  exemptionGrowthRate?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateGT?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateGTE?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  exemptionGrowthRateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  exemptionGrowthRateLT?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateLTE?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateNEQ?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  exemptionGrowthRateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** first_grantor_death_year field predicates */
  firstGrantorDeathYear?: InputMaybe<Scalars['Int']['input']>;
  firstGrantorDeathYearGT?: InputMaybe<Scalars['Int']['input']>;
  firstGrantorDeathYearGTE?: InputMaybe<Scalars['Int']['input']>;
  firstGrantorDeathYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  firstGrantorDeathYearIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  firstGrantorDeathYearLT?: InputMaybe<Scalars['Int']['input']>;
  firstGrantorDeathYearLTE?: InputMaybe<Scalars['Int']['input']>;
  firstGrantorDeathYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  firstGrantorDeathYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  firstGrantorDeathYearNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** filter edge predicates */
  hasFilter?: InputMaybe<Scalars['Boolean']['input']>;
  hasFilterWith?: InputMaybe<Array<EstateWaterfallVisualizationFilterWhereInput>>;
  /** first_grantor_death edge predicates */
  hasFirstGrantorDeath?: InputMaybe<Scalars['Boolean']['input']>;
  hasFirstGrantorDeathWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** hypothetical_scenarios edge predicates */
  hasHypotheticalScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalScenariosWith?: InputMaybe<Array<EstateWaterfallWhereInput>>;
  /** hypothetical_transfers edge predicates */
  hasHypotheticalTransfers?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalTransfersWith?: InputMaybe<Array<EstateWaterfallHypotheticalTransferWhereInput>>;
  /** parent edge predicates */
  hasParent?: InputMaybe<Scalars['Boolean']['input']>;
  hasParentWith?: InputMaybe<Array<EstateWaterfallWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** visualization_config edge predicates */
  hasVisualizationConfig?: InputMaybe<Scalars['Boolean']['input']>;
  hasVisualizationConfigWith?: InputMaybe<Array<EstateWaterfallVisualizationConfigWhereInput>>;
  /** visualization_groups edge predicates */
  hasVisualizationGroups?: InputMaybe<Scalars['Boolean']['input']>;
  hasVisualizationGroupsWith?: InputMaybe<Array<GraphVisualizationGroupWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<EstateWaterfallWhereInput>;
  or?: InputMaybe<Array<EstateWaterfallWhereInput>>;
  /** second_grantor_death_year field predicates */
  secondGrantorDeathYear?: InputMaybe<Scalars['Int']['input']>;
  secondGrantorDeathYearGT?: InputMaybe<Scalars['Int']['input']>;
  secondGrantorDeathYearGTE?: InputMaybe<Scalars['Int']['input']>;
  secondGrantorDeathYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  secondGrantorDeathYearIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  secondGrantorDeathYearLT?: InputMaybe<Scalars['Int']['input']>;
  secondGrantorDeathYearLTE?: InputMaybe<Scalars['Int']['input']>;
  secondGrantorDeathYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  secondGrantorDeathYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  secondGrantorDeathYearNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** will_exemption_sunset field predicates */
  willExemptionSunset?: InputMaybe<Scalars['Boolean']['input']>;
  willExemptionSunsetIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  willExemptionSunsetNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  willExemptionSunsetNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExtendedCreateUserInput = {
  create: CreateUserInput;
  withCollaborator?: InputMaybe<PartialCreateCollaboratorInput>;
  withEmployee?: InputMaybe<PartialCreateEmployeeInput>;
};

export type FeatureFlag = Node & {
  __typename?: 'FeatureFlag';
  createdAt: Scalars['Time']['output'];
  flagDescription?: Maybe<Scalars['String']['output']>;
  flagName: Scalars['String']['output'];
  flagStatus: FeatureFlagFlagStatus;
  id: Scalars['ID']['output'];
  ownedBy: Scalars['String']['output'];
  queryRules?: Maybe<Array<FeatureFlagQueryRule>>;
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type FeatureFlagConnection = {
  __typename?: 'FeatureFlagConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FeatureFlagEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FeatureFlagEdge = {
  __typename?: 'FeatureFlagEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FeatureFlag>;
};

export type FeatureFlagEvaluationResult = {
  __typename?: 'FeatureFlagEvaluationResult';
  flag: FeatureFlag;
  flagEnablementStatus: FlagEnablementStatus;
  flagEvaluationResult: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

/** FeatureFlagFlagStatus is enum for the field flag_status */
export enum FeatureFlagFlagStatus {
  Off = 'OFF',
  On = 'ON'
}

/** Ordering options for FeatureFlag connections */
export type FeatureFlagOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order FeatureFlags. */
  field: FeatureFlagOrderField;
};

/** Properties by which FeatureFlag connections can be ordered. */
export enum FeatureFlagOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type FeatureFlagQueryRule = Node & {
  __typename?: 'FeatureFlagQueryRule';
  createdAt: Scalars['Time']['output'];
  /** A query rule can only be associated with a single flag. */
  featureFlag: FeatureFlag;
  flagQuery: Scalars['String']['output'];
  flagVariationName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind?: Maybe<FeatureFlagQueryRuleKind>;
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type FeatureFlagQueryRuleConnection = {
  __typename?: 'FeatureFlagQueryRuleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FeatureFlagQueryRuleEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FeatureFlagQueryRuleEdge = {
  __typename?: 'FeatureFlagQueryRuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FeatureFlagQueryRule>;
};

/** FeatureFlagQueryRuleKind is enum for the field kind */
export enum FeatureFlagQueryRuleKind {
  Tenant = 'TENANT',
  User = 'USER'
}

/** Ordering options for FeatureFlagQueryRule connections */
export type FeatureFlagQueryRuleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order FeatureFlagQueryRules. */
  field: FeatureFlagQueryRuleOrderField;
};

/** Properties by which FeatureFlagQueryRule connections can be ordered. */
export enum FeatureFlagQueryRuleOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * FeatureFlagQueryRuleWhereInput is used for filtering FeatureFlagQueryRule objects.
 * Input was generated by ent.
 */
export type FeatureFlagQueryRuleWhereInput = {
  and?: InputMaybe<Array<FeatureFlagQueryRuleWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** flag_query field predicates */
  flagQuery?: InputMaybe<Scalars['String']['input']>;
  flagQueryContains?: InputMaybe<Scalars['String']['input']>;
  flagQueryContainsFold?: InputMaybe<Scalars['String']['input']>;
  flagQueryEqualFold?: InputMaybe<Scalars['String']['input']>;
  flagQueryGT?: InputMaybe<Scalars['String']['input']>;
  flagQueryGTE?: InputMaybe<Scalars['String']['input']>;
  flagQueryHasPrefix?: InputMaybe<Scalars['String']['input']>;
  flagQueryHasSuffix?: InputMaybe<Scalars['String']['input']>;
  flagQueryIn?: InputMaybe<Array<Scalars['String']['input']>>;
  flagQueryLT?: InputMaybe<Scalars['String']['input']>;
  flagQueryLTE?: InputMaybe<Scalars['String']['input']>;
  flagQueryNEQ?: InputMaybe<Scalars['String']['input']>;
  flagQueryNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** flag_variation_name field predicates */
  flagVariationName?: InputMaybe<Scalars['String']['input']>;
  flagVariationNameContains?: InputMaybe<Scalars['String']['input']>;
  flagVariationNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  flagVariationNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  flagVariationNameGT?: InputMaybe<Scalars['String']['input']>;
  flagVariationNameGTE?: InputMaybe<Scalars['String']['input']>;
  flagVariationNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  flagVariationNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  flagVariationNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  flagVariationNameLT?: InputMaybe<Scalars['String']['input']>;
  flagVariationNameLTE?: InputMaybe<Scalars['String']['input']>;
  flagVariationNameNEQ?: InputMaybe<Scalars['String']['input']>;
  flagVariationNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** feature_flag edge predicates */
  hasFeatureFlag?: InputMaybe<Scalars['Boolean']['input']>;
  hasFeatureFlagWith?: InputMaybe<Array<FeatureFlagWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<FeatureFlagQueryRuleKind>;
  kindIn?: InputMaybe<Array<FeatureFlagQueryRuleKind>>;
  kindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  kindNEQ?: InputMaybe<FeatureFlagQueryRuleKind>;
  kindNotIn?: InputMaybe<Array<FeatureFlagQueryRuleKind>>;
  kindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<FeatureFlagQueryRuleWhereInput>;
  or?: InputMaybe<Array<FeatureFlagQueryRuleWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * FeatureFlagWhereInput is used for filtering FeatureFlag objects.
 * Input was generated by ent.
 */
export type FeatureFlagWhereInput = {
  and?: InputMaybe<Array<FeatureFlagWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** flag_description field predicates */
  flagDescription?: InputMaybe<Scalars['String']['input']>;
  flagDescriptionContains?: InputMaybe<Scalars['String']['input']>;
  flagDescriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  flagDescriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  flagDescriptionGT?: InputMaybe<Scalars['String']['input']>;
  flagDescriptionGTE?: InputMaybe<Scalars['String']['input']>;
  flagDescriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  flagDescriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  flagDescriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  flagDescriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  flagDescriptionLT?: InputMaybe<Scalars['String']['input']>;
  flagDescriptionLTE?: InputMaybe<Scalars['String']['input']>;
  flagDescriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  flagDescriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  flagDescriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** flag_name field predicates */
  flagName?: InputMaybe<Scalars['String']['input']>;
  flagNameContains?: InputMaybe<Scalars['String']['input']>;
  flagNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  flagNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  flagNameGT?: InputMaybe<Scalars['String']['input']>;
  flagNameGTE?: InputMaybe<Scalars['String']['input']>;
  flagNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  flagNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  flagNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  flagNameLT?: InputMaybe<Scalars['String']['input']>;
  flagNameLTE?: InputMaybe<Scalars['String']['input']>;
  flagNameNEQ?: InputMaybe<Scalars['String']['input']>;
  flagNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** flag_status field predicates */
  flagStatus?: InputMaybe<FeatureFlagFlagStatus>;
  flagStatusIn?: InputMaybe<Array<FeatureFlagFlagStatus>>;
  flagStatusNEQ?: InputMaybe<FeatureFlagFlagStatus>;
  flagStatusNotIn?: InputMaybe<Array<FeatureFlagFlagStatus>>;
  /** query_rules edge predicates */
  hasQueryRules?: InputMaybe<Scalars['Boolean']['input']>;
  hasQueryRulesWith?: InputMaybe<Array<FeatureFlagQueryRuleWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<FeatureFlagWhereInput>;
  or?: InputMaybe<Array<FeatureFlagWhereInput>>;
  /** owned_by field predicates */
  ownedBy?: InputMaybe<Scalars['String']['input']>;
  ownedByContains?: InputMaybe<Scalars['String']['input']>;
  ownedByContainsFold?: InputMaybe<Scalars['String']['input']>;
  ownedByEqualFold?: InputMaybe<Scalars['String']['input']>;
  ownedByGT?: InputMaybe<Scalars['String']['input']>;
  ownedByGTE?: InputMaybe<Scalars['String']['input']>;
  ownedByHasPrefix?: InputMaybe<Scalars['String']['input']>;
  ownedByHasSuffix?: InputMaybe<Scalars['String']['input']>;
  ownedByIn?: InputMaybe<Array<Scalars['String']['input']>>;
  ownedByLT?: InputMaybe<Scalars['String']['input']>;
  ownedByLTE?: InputMaybe<Scalars['String']['input']>;
  ownedByNEQ?: InputMaybe<Scalars['String']['input']>;
  ownedByNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type File = Node & {
  __typename?: 'File';
  /** The AI Suggestions for this file */
  aiSuggestions?: Maybe<Array<AiSuggestion>>;
  /** The sha256 of the file to be uploaded. We check the file integrity S3 side. */
  contentsSha256: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  document?: Maybe<Document>;
  download: FileDownload;
  /**
   * This is the time at which we first detected the file in s3.
   * 			We need to do this asynchronously since uploads go directly to s3.
   * 			Set by Temporal.
   */
  fileUploadedAt?: Maybe<Scalars['Time']['output']>;
  household?: Maybe<Household>;
  householdID?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  inferredFields?: Maybe<Array<InferredField>>;
  ingestionKind: FileIngestionKind;
  kgNodes: KgNodeConnection;
  /** The KGRoots that the knowledge graph nodes of this file are included in. This is mainly used to track when the construction of the KGRoot is complete; when the nodes of all files are merged into the root. */
  kgRoots?: Maybe<Array<KgRoot>>;
  pages?: Maybe<Array<FilePage>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type FileKgNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<KgNodeOrder>>;
  where?: InputMaybe<KgNodeWhereInput>;
};

/** A connection to a list of items. */
export type FileConnection = {
  __typename?: 'FileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FileEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

export type FileDownload = {
  __typename?: 'FileDownload';
  downloadURL: Scalars['String']['output'];
  downloadURLExpiresInSeconds: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FileEdge = {
  __typename?: 'FileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<File>;
};

/** FileIngestionKind is enum for the field ingestion_kind */
export enum FileIngestionKind {
  Generated = 'GENERATED',
  Uploaded = 'UPLOADED'
}

/** Ordering options for File connections */
export type FileOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Files. */
  field: FileOrderField;
};

/** Properties by which File connections can be ordered. */
export enum FileOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type FilePage = Node & {
  __typename?: 'FilePage';
  /** The angle of the page. This helps establish an orientation. */
  angle?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The corresponding file for this page metadata */
  file?: Maybe<File>;
  /** The height of the page. */
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  /** The page number of the file. 1-indexed. */
  pageNumber: Scalars['Int']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** The unit of measurement for the page. Typically 'inch'. */
  unit?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Time']['output'];
  user: User;
  /** The width of the page. */
  width?: Maybe<Scalars['Float']['output']>;
};

/** A connection to a list of items. */
export type FilePageConnection = {
  __typename?: 'FilePageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<FilePageEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FilePageEdge = {
  __typename?: 'FilePageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<FilePage>;
};

/** Ordering options for FilePage connections */
export type FilePageOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order FilePages. */
  field: FilePageOrderField;
};

/** Properties by which FilePage connections can be ordered. */
export enum FilePageOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * FilePageWhereInput is used for filtering FilePage objects.
 * Input was generated by ent.
 */
export type FilePageWhereInput = {
  and?: InputMaybe<Array<FilePageWhereInput>>;
  /** angle field predicates */
  angle?: InputMaybe<Scalars['Float']['input']>;
  angleGT?: InputMaybe<Scalars['Float']['input']>;
  angleGTE?: InputMaybe<Scalars['Float']['input']>;
  angleIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  angleIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  angleLT?: InputMaybe<Scalars['Float']['input']>;
  angleLTE?: InputMaybe<Scalars['Float']['input']>;
  angleNEQ?: InputMaybe<Scalars['Float']['input']>;
  angleNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  angleNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** file edge predicates */
  hasFile?: InputMaybe<Scalars['Boolean']['input']>;
  hasFileWith?: InputMaybe<Array<FileWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** height field predicates */
  height?: InputMaybe<Scalars['Float']['input']>;
  heightGT?: InputMaybe<Scalars['Float']['input']>;
  heightGTE?: InputMaybe<Scalars['Float']['input']>;
  heightIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  heightIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  heightLT?: InputMaybe<Scalars['Float']['input']>;
  heightLTE?: InputMaybe<Scalars['Float']['input']>;
  heightNEQ?: InputMaybe<Scalars['Float']['input']>;
  heightNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  heightNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<FilePageWhereInput>;
  or?: InputMaybe<Array<FilePageWhereInput>>;
  /** page_number field predicates */
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageNumberGT?: InputMaybe<Scalars['Int']['input']>;
  pageNumberGTE?: InputMaybe<Scalars['Int']['input']>;
  pageNumberIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  pageNumberLT?: InputMaybe<Scalars['Int']['input']>;
  pageNumberLTE?: InputMaybe<Scalars['Int']['input']>;
  pageNumberNEQ?: InputMaybe<Scalars['Int']['input']>;
  pageNumberNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** unit field predicates */
  unit?: InputMaybe<Scalars['String']['input']>;
  unitContains?: InputMaybe<Scalars['String']['input']>;
  unitContainsFold?: InputMaybe<Scalars['String']['input']>;
  unitEqualFold?: InputMaybe<Scalars['String']['input']>;
  unitGT?: InputMaybe<Scalars['String']['input']>;
  unitGTE?: InputMaybe<Scalars['String']['input']>;
  unitHasPrefix?: InputMaybe<Scalars['String']['input']>;
  unitHasSuffix?: InputMaybe<Scalars['String']['input']>;
  unitIn?: InputMaybe<Array<Scalars['String']['input']>>;
  unitIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  unitLT?: InputMaybe<Scalars['String']['input']>;
  unitLTE?: InputMaybe<Scalars['String']['input']>;
  unitNEQ?: InputMaybe<Scalars['String']['input']>;
  unitNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  unitNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** width field predicates */
  width?: InputMaybe<Scalars['Float']['input']>;
  widthGT?: InputMaybe<Scalars['Float']['input']>;
  widthGTE?: InputMaybe<Scalars['Float']['input']>;
  widthIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  widthIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  widthLT?: InputMaybe<Scalars['Float']['input']>;
  widthLTE?: InputMaybe<Scalars['Float']['input']>;
  widthNEQ?: InputMaybe<Scalars['Float']['input']>;
  widthNotIn?: InputMaybe<Array<Scalars['Float']['input']>>;
  widthNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileParagraph = Node & {
  __typename?: 'FileParagraph';
  /** the bounding regions correspondign to this paragraph. */
  boundingRegions: Array<BoundingRegion>;
  /** the content of the paragraph */
  content: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The corresponding file for this paragraph metadata */
  file?: Maybe<File>;
  id: Scalars['ID']['output'];
  /** the implied paragraph number in the file. this is implied from the list of paragraphs as retured from Azure Form Recognizer */
  impliedParagraphNumber: Scalars['Int']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** Ordering options for FileParagraph connections */
export type FileParagraphOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order FileParagraphs. */
  field: FileParagraphOrderField;
};

/** Properties by which FileParagraph connections can be ordered. */
export enum FileParagraphOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type FileUpload = {
  __typename?: 'FileUpload';
  file: File;
  uploadMethod: Scalars['String']['output'];
  uploadURL: Scalars['String']['output'];
  uploadURLExpiresInSeconds: Scalars['Int']['output'];
};

/**
 * FileWhereInput is used for filtering File objects.
 * Input was generated by ent.
 */
export type FileWhereInput = {
  and?: InputMaybe<Array<FileWhereInput>>;
  /** contents_sha256 field predicates */
  contentsSha256?: InputMaybe<Scalars['String']['input']>;
  contentsSha256Contains?: InputMaybe<Scalars['String']['input']>;
  contentsSha256ContainsFold?: InputMaybe<Scalars['String']['input']>;
  contentsSha256EqualFold?: InputMaybe<Scalars['String']['input']>;
  contentsSha256GT?: InputMaybe<Scalars['String']['input']>;
  contentsSha256GTE?: InputMaybe<Scalars['String']['input']>;
  contentsSha256HasPrefix?: InputMaybe<Scalars['String']['input']>;
  contentsSha256HasSuffix?: InputMaybe<Scalars['String']['input']>;
  contentsSha256In?: InputMaybe<Array<Scalars['String']['input']>>;
  contentsSha256LT?: InputMaybe<Scalars['String']['input']>;
  contentsSha256LTE?: InputMaybe<Scalars['String']['input']>;
  contentsSha256NEQ?: InputMaybe<Scalars['String']['input']>;
  contentsSha256NotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** file_uploaded_at field predicates */
  fileUploadedAt?: InputMaybe<Scalars['Time']['input']>;
  fileUploadedAtGT?: InputMaybe<Scalars['Time']['input']>;
  fileUploadedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  fileUploadedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  fileUploadedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  fileUploadedAtLT?: InputMaybe<Scalars['Time']['input']>;
  fileUploadedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  fileUploadedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  fileUploadedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  fileUploadedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** ai_suggestions edge predicates */
  hasAiSuggestions?: InputMaybe<Scalars['Boolean']['input']>;
  hasAiSuggestionsWith?: InputMaybe<Array<AiSuggestionWhereInput>>;
  /** document edge predicates */
  hasDocument?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** inferred_fields edge predicates */
  hasInferredFields?: InputMaybe<Scalars['Boolean']['input']>;
  hasInferredFieldsWith?: InputMaybe<Array<InferredFieldWhereInput>>;
  /** kg_nodes edge predicates */
  hasKgNodes?: InputMaybe<Scalars['Boolean']['input']>;
  hasKgNodesWith?: InputMaybe<Array<KgNodeWhereInput>>;
  /** kg_roots edge predicates */
  hasKgRoots?: InputMaybe<Scalars['Boolean']['input']>;
  hasKgRootsWith?: InputMaybe<Array<KgRootWhereInput>>;
  /** pages edge predicates */
  hasPages?: InputMaybe<Scalars['Boolean']['input']>;
  hasPagesWith?: InputMaybe<Array<FilePageWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** household_id field predicates */
  householdID?: InputMaybe<Scalars['ID']['input']>;
  householdIDContains?: InputMaybe<Scalars['ID']['input']>;
  householdIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDGT?: InputMaybe<Scalars['ID']['input']>;
  householdIDGTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  householdIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  householdIDLT?: InputMaybe<Scalars['ID']['input']>;
  householdIDLTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  householdIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** ingestion_kind field predicates */
  ingestionKind?: InputMaybe<FileIngestionKind>;
  ingestionKindIn?: InputMaybe<Array<FileIngestionKind>>;
  ingestionKindNEQ?: InputMaybe<FileIngestionKind>;
  ingestionKindNotIn?: InputMaybe<Array<FileIngestionKind>>;
  not?: InputMaybe<FileWhereInput>;
  or?: InputMaybe<Array<FileWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export enum FlagEnablementStatus {
  Off = 'OFF',
  OnForEnv = 'ON_FOR_ENV',
  OnForTenant = 'ON_FOR_TENANT'
}

export type GpBusinessEntity = BusinessEntity & EntitySubtype & Named & Node & {
  __typename?: 'GPBusinessEntity';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this business entity */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: Maybe<Scalars['String']['output']>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: Maybe<Scalars['String']['output']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  grantorControlledPercentage: Scalars['Percent']['output'];
  grantorControlledValue: Scalars['CurrencyUSD']['output'];
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  keyPeople?: Maybe<Array<KeyPerson>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: Maybe<Scalars['String']['output']>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  nongrantorControlledPercentage: Scalars['Percent']['output'];
  nongrantorControlledValue: Scalars['CurrencyUSD']['output'];
  /** Ownership as-of date. */
  ownershipAsOfDate?: Maybe<Scalars['Time']['output']>;
  policies?: Maybe<Array<InsurancePolicy>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: Maybe<Scalars['Boolean']['output']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxID?: Maybe<Scalars['String']['output']>;
  taxStatus?: Maybe<BusinessEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type GpBusinessEntityHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type GpBusinessEntityConnection = {
  __typename?: 'GPBusinessEntityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GpBusinessEntityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GpBusinessEntityEdge = {
  __typename?: 'GPBusinessEntityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GpBusinessEntity>;
};

/** Ordering options for GPBusinessEntity connections */
export type GpBusinessEntityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GPBusinessEntities. */
  field: GpBusinessEntityOrderField;
};

/** Properties by which GPBusinessEntity connections can be ordered. */
export enum GpBusinessEntityOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * GPBusinessEntityWhereInput is used for filtering GPBusinessEntity objects.
 * Input was generated by ent.
 */
export type GpBusinessEntityWhereInput = {
  and?: InputMaybe<Array<GpBusinessEntityWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** doing_business_as_name field predicates */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContains?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  doingBusinessAsNameLT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameLTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNEQ?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** fin_cen_id field predicates */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  finCenIDContains?: InputMaybe<Scalars['String']['input']>;
  finCenIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDGT?: InputMaybe<Scalars['String']['input']>;
  finCenIDGTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  finCenIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  finCenIDLT?: InputMaybe<Scalars['String']['input']>;
  finCenIDLTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDNEQ?: InputMaybe<Scalars['String']['input']>;
  finCenIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** formation_jurisdiction_state_code field predicates */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  formationJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** key_people edge predicates */
  hasKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  hasKeyPeopleWith?: InputMaybe<Array<KeyPersonWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** key_people_notes field predicates */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContains?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  keyPeopleNotesLT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesLTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<GpBusinessEntityWhereInput>;
  or?: InputMaybe<Array<GpBusinessEntityWhereInput>>;
  /** ownership_as_of_date field predicates */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownershipAsOfDateLT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateLTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requires_cta_reporting field predicates */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_id field predicates */
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxIDContains?: InputMaybe<Scalars['String']['input']>;
  taxIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  taxIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  taxIDGT?: InputMaybe<Scalars['String']['input']>;
  taxIDGTE?: InputMaybe<Scalars['String']['input']>;
  taxIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  taxIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  taxIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxIDLT?: InputMaybe<Scalars['String']['input']>;
  taxIDLTE?: InputMaybe<Scalars['String']['input']>;
  taxIDNEQ?: InputMaybe<Scalars['String']['input']>;
  taxIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type GratAnnuityV2 = Node & {
  __typename?: 'GRATAnnuityV2';
  actualPaymentAmount: Scalars['CurrencyUSD']['output'];
  /** the asset valuation that was created as a result of this annuity payment */
  associatedAssetValuation?: Maybe<AssetValuationV2>;
  associatedTask?: Maybe<EntityTask>;
  /** @deprecated Use initialFundingValuePercentage instead */
  computedInitialFundingValuePercentage: Scalars['Percent']['output'];
  computedPaymentAmount: Scalars['CurrencyUSD']['output'];
  createdAt: Scalars['Time']['output'];
  /** if the annuity payment failed, this is the amount that was actually paid */
  failedAnnuityPaymentAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  gratTrust?: Maybe<GratTrust>;
  id: Scalars['ID']['output'];
  /** the percent of the initial funding value due to be paid with this annuity */
  initialFundingValuePercent: Scalars['Percent']['output'];
  /** the date the annuity was paid on */
  paidOn?: Maybe<Scalars['Time']['output']>;
  paymentWindowEndDate: Scalars['Time']['output'];
  paymentWindowStartDate: Scalars['Time']['output'];
  previousValuation: AssetValuationV2;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** the term duration of the GRAT that this annuity is associated with */
  termDurationYears: Scalars['Int']['output'];
  updatedAt: Scalars['Time']['output'];
  /** which 1-indexed annuity payment this represents; if a 2, represents the second annuity payment */
  yearOfTerm: Scalars['Int']['output'];
};

/** A connection to a list of items. */
export type GratAnnuityV2Connection = {
  __typename?: 'GRATAnnuityV2Connection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GratAnnuityV2Edge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GratAnnuityV2Edge = {
  __typename?: 'GRATAnnuityV2Edge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GratAnnuityV2>;
};

/** Ordering options for GRATAnnuityV2 connections */
export type GratAnnuityV2Order = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GRATAnnuityV2s. */
  field: GratAnnuityV2OrderField;
};

/** Properties by which GRATAnnuityV2 connections can be ordered. */
export enum GratAnnuityV2OrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * GRATAnnuityV2WhereInput is used for filtering GRATAnnuityV2 objects.
 * Input was generated by ent.
 */
export type GratAnnuityV2WhereInput = {
  and?: InputMaybe<Array<GratAnnuityV2WhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** failed_annuity_payment_amount field predicates */
  failedAnnuityPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  failedAnnuityPaymentAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  failedAnnuityPaymentAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  failedAnnuityPaymentAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  failedAnnuityPaymentAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  failedAnnuityPaymentAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  failedAnnuityPaymentAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  failedAnnuityPaymentAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  failedAnnuityPaymentAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  failedAnnuityPaymentAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** associated_asset_valuation edge predicates */
  hasAssociatedAssetValuation?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssociatedAssetValuationWith?: InputMaybe<Array<AssetValuationV2WhereInput>>;
  /** associated_task edge predicates */
  hasAssociatedTask?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssociatedTaskWith?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** grat_trust edge predicates */
  hasGratTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasGratTrustWith?: InputMaybe<Array<GratTrustWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** initial_funding_value_percent field predicates */
  initialFundingValuePercent?: InputMaybe<Scalars['Percent']['input']>;
  initialFundingValuePercentGT?: InputMaybe<Scalars['Percent']['input']>;
  initialFundingValuePercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  initialFundingValuePercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  initialFundingValuePercentLT?: InputMaybe<Scalars['Percent']['input']>;
  initialFundingValuePercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  initialFundingValuePercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  initialFundingValuePercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  not?: InputMaybe<GratAnnuityV2WhereInput>;
  or?: InputMaybe<Array<GratAnnuityV2WhereInput>>;
  /** paid_on field predicates */
  paidOn?: InputMaybe<Scalars['Time']['input']>;
  paidOnGT?: InputMaybe<Scalars['Time']['input']>;
  paidOnGTE?: InputMaybe<Scalars['Time']['input']>;
  paidOnIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  paidOnIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  paidOnLT?: InputMaybe<Scalars['Time']['input']>;
  paidOnLTE?: InputMaybe<Scalars['Time']['input']>;
  paidOnNEQ?: InputMaybe<Scalars['Time']['input']>;
  paidOnNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  paidOnNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** term_duration_years field predicates */
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termDurationYearsLT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsLTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNEQ?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** year_of_term field predicates */
  yearOfTerm?: InputMaybe<Scalars['Int']['input']>;
  yearOfTermGT?: InputMaybe<Scalars['Int']['input']>;
  yearOfTermGTE?: InputMaybe<Scalars['Int']['input']>;
  yearOfTermIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  yearOfTermLT?: InputMaybe<Scalars['Int']['input']>;
  yearOfTermLTE?: InputMaybe<Scalars['Int']['input']>;
  yearOfTermNEQ?: InputMaybe<Scalars['Int']['input']>;
  yearOfTermNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type GratDesignSummaryDetails = {
  __typename?: 'GRATDesignSummaryDetails';
  annuityPaymentAnnualIncreasePercentage: Scalars['String']['output'];
  annuityPayments: Array<SummaryAnnuityPaymentDetails>;
  assets: Array<SummaryAsset>;
  attorneyNotes?: Maybe<Scalars['String']['output']>;
  beneficiaries: Array<SummaryBeneficiaryv2>;
  displayName: Scalars['String']['output'];
  grantor: SummaryPerson;
  grantorRetainedInterest: Scalars['String']['output'];
  initialFundingValue: Scalars['String']['output'];
  legalName?: Maybe<Scalars['String']['output']>;
  officialInterestRate: Scalars['String']['output'];
  spouseDisplayName?: Maybe<Scalars['String']['output']>;
  successorTrustees: Array<SummaryPerson>;
  taxableGiftAmount: Scalars['String']['output'];
  termDescription: Scalars['String']['output'];
  termDetailsInfo: TermDetailsInfo;
  trustAdvisors: Array<SummaryPerson>;
  trustSitus?: Maybe<Scalars['String']['output']>;
  trustees: Array<SummaryPerson>;
};

export type GratProposalScenario = Node & {
  __typename?: 'GRATProposalScenario';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  inTermCAGRV2: Scalars['Percent']['output'];
  inTermValuationType?: Maybe<GratProposalScenarioInTermValuationType>;
  /** the percent value for projected return after the lifetime of the GRAT */
  projectedAnnualReturnAfterTerm?: Maybe<Scalars['Percent']['output']>;
  /** the percent value for projected return during the lifetime of the GRAT */
  projectedAnnualReturnThroughTerm?: Maybe<Scalars['Percent']['output']>;
  projectedMarketValueAtTerm?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** the default selected time period for this projection */
  projectionTimePeriodYears: Scalars['Int']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type GratProposalScenarioConnection = {
  __typename?: 'GRATProposalScenarioConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GratProposalScenarioEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GratProposalScenarioEdge = {
  __typename?: 'GRATProposalScenarioEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GratProposalScenario>;
};

/** GRATProposalScenarioInTermValuationType is enum for the field in_term_valuation_type */
export enum GratProposalScenarioInTermValuationType {
  EndTermValue = 'end_term_value',
  PercentRateOfReturn = 'percent_rate_of_return'
}

/** Ordering options for GRATProposalScenario connections */
export type GratProposalScenarioOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GRATProposalScenarios. */
  field: GratProposalScenarioOrderField;
};

/** Properties by which GRATProposalScenario connections can be ordered. */
export enum GratProposalScenarioOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * GRATProposalScenarioWhereInput is used for filtering GRATProposalScenario objects.
 * Input was generated by ent.
 */
export type GratProposalScenarioWhereInput = {
  and?: InputMaybe<Array<GratProposalScenarioWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_term_valuation_type field predicates */
  inTermValuationType?: InputMaybe<GratProposalScenarioInTermValuationType>;
  inTermValuationTypeIn?: InputMaybe<Array<GratProposalScenarioInTermValuationType>>;
  inTermValuationTypeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inTermValuationTypeNEQ?: InputMaybe<GratProposalScenarioInTermValuationType>;
  inTermValuationTypeNotIn?: InputMaybe<Array<GratProposalScenarioInTermValuationType>>;
  inTermValuationTypeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<GratProposalScenarioWhereInput>;
  or?: InputMaybe<Array<GratProposalScenarioWhereInput>>;
  /** projected_annual_return_after_term field predicates */
  projectedAnnualReturnAfterTerm?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnAfterTermGT?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnAfterTermGTE?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnAfterTermIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  projectedAnnualReturnAfterTermIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  projectedAnnualReturnAfterTermLT?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnAfterTermLTE?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnAfterTermNEQ?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnAfterTermNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  projectedAnnualReturnAfterTermNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** projected_annual_return_through_term field predicates */
  projectedAnnualReturnThroughTerm?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnThroughTermGT?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnThroughTermGTE?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnThroughTermIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  projectedAnnualReturnThroughTermIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  projectedAnnualReturnThroughTermLT?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnThroughTermLTE?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnThroughTermNEQ?: InputMaybe<Scalars['Percent']['input']>;
  projectedAnnualReturnThroughTermNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  projectedAnnualReturnThroughTermNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** projected_market_value_at_term field predicates */
  projectedMarketValueAtTerm?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueAtTermGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueAtTermGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueAtTermIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  projectedMarketValueAtTermIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  projectedMarketValueAtTermLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueAtTermLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueAtTermNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueAtTermNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  projectedMarketValueAtTermNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** projection_time_period_years field predicates */
  projectionTimePeriodYears?: InputMaybe<Scalars['Int']['input']>;
  projectionTimePeriodYearsGT?: InputMaybe<Scalars['Int']['input']>;
  projectionTimePeriodYearsGTE?: InputMaybe<Scalars['Int']['input']>;
  projectionTimePeriodYearsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  projectionTimePeriodYearsLT?: InputMaybe<Scalars['Int']['input']>;
  projectionTimePeriodYearsLTE?: InputMaybe<Scalars['Int']['input']>;
  projectionTimePeriodYearsNEQ?: InputMaybe<Scalars['Int']['input']>;
  projectionTimePeriodYearsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type GratTrust = EntitySubtype & Named & Node & Trust & {
  __typename?: 'GRATTrust';
  accounts?: Maybe<Array<Account>>;
  /** the annuities that are associated with this GRAT strategy instance */
  annuities?: Maybe<Array<GratAnnuityV2>>;
  /** the percent by which the annuity should increase each year in a variable annuity amount scenario */
  annuityAnnualIncreasePercent?: Maybe<Scalars['Percent']['output']>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  completionDate?: Maybe<Scalars['Time']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this trust */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** the assets remaining in the GRAT that were distributed to the beneficiaries */
  distributionAssetValuation?: Maybe<AssetValuationV2>;
  /** the date that the trust remainder was distributed */
  distributionDate?: Maybe<Scalars['Time']['output']>;
  downsideThresholdPercent?: Maybe<Scalars['Percent']['output']>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  grantor?: Maybe<Principal>;
  grantorRetainedInterest: Scalars['CurrencyUSD']['output'];
  gratSummaryDisplay?: Maybe<Scalars['String']['output']>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  /** notes from the advisor team to the implementing attorney */
  implementationNotesToAttorney?: Maybe<Scalars['String']['output']>;
  impliedEstateTaxSavings: Scalars['CurrencyUSD']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  initialFundingValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  officialInterestRatePercent?: Maybe<Scalars['Percent']['output']>;
  performanceReport: TrustPerformanceReport;
  projectedMarketValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  projectedRateOfReturn?: Maybe<Scalars['Percent']['output']>;
  projectedSharePrice?: Maybe<Scalars['CurrencyUSD']['output']>;
  rollingPeriodYears?: Maybe<Scalars['Int']['output']>;
  successorTrustees?: Maybe<Array<Trustee>>;
  /** taxable gift that the advisor is trying to achieve; should be used as an input for annuities calcs */
  targetTaxableGift?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<TrustTaxStatus>;
  /** The actual taxable gift that was recorded for this GRAT */
  taxableGift?: Maybe<Scalars['CurrencyUSD']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  termDurationYears?: Maybe<Scalars['Int']['output']>;
  termEndDate?: Maybe<Scalars['Time']['output']>;
  trustAdvisors?: Maybe<Array<TrustAdvisor>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  trustees?: Maybe<Array<Trustee>>;
  updatedAt: Scalars['Time']['output'];
  /** If true, the upside threshold value is used for the GRAT substitution threshold instead of the upside threshold percent. */
  upsideAsTargetSurplus: Scalars['Boolean']['output'];
  upsideThresholdPercent?: Maybe<Scalars['Percent']['output']>;
  upsideThresholdValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  user: User;
};


export type GratTrustHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type GratTrustConnection = {
  __typename?: 'GRATTrustConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GratTrustEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GratTrustEdge = {
  __typename?: 'GRATTrustEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GratTrust>;
};

/** Ordering options for GRATTrust connections */
export type GratTrustOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GRATTrusts. */
  field: GratTrustOrderField;
};

/** Properties by which GRATTrust connections can be ordered. */
export enum GratTrustOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * GRATTrustWhereInput is used for filtering GRATTrust objects.
 * Input was generated by ent.
 */
export type GratTrustWhereInput = {
  and?: InputMaybe<Array<GratTrustWhereInput>>;
  /** annuity_annual_increase_percent field predicates */
  annuityAnnualIncreasePercent?: InputMaybe<Scalars['Percent']['input']>;
  annuityAnnualIncreasePercentGT?: InputMaybe<Scalars['Percent']['input']>;
  annuityAnnualIncreasePercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  annuityAnnualIncreasePercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  annuityAnnualIncreasePercentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annuityAnnualIncreasePercentLT?: InputMaybe<Scalars['Percent']['input']>;
  annuityAnnualIncreasePercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  annuityAnnualIncreasePercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  annuityAnnualIncreasePercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  annuityAnnualIncreasePercentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** distribution_date field predicates */
  distributionDate?: InputMaybe<Scalars['Time']['input']>;
  distributionDateGT?: InputMaybe<Scalars['Time']['input']>;
  distributionDateGTE?: InputMaybe<Scalars['Time']['input']>;
  distributionDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  distributionDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  distributionDateLT?: InputMaybe<Scalars['Time']['input']>;
  distributionDateLTE?: InputMaybe<Scalars['Time']['input']>;
  distributionDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  distributionDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  distributionDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** downside_threshold_percent field predicates */
  downsideThresholdPercent?: InputMaybe<Scalars['Percent']['input']>;
  downsideThresholdPercentGT?: InputMaybe<Scalars['Percent']['input']>;
  downsideThresholdPercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  downsideThresholdPercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  downsideThresholdPercentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  downsideThresholdPercentLT?: InputMaybe<Scalars['Percent']['input']>;
  downsideThresholdPercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  downsideThresholdPercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  downsideThresholdPercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  downsideThresholdPercentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** annuities edge predicates */
  hasAnnuities?: InputMaybe<Scalars['Boolean']['input']>;
  hasAnnuitiesWith?: InputMaybe<Array<GratAnnuityV2WhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** distribution_asset_valuation edge predicates */
  hasDistributionAssetValuation?: InputMaybe<Scalars['Boolean']['input']>;
  hasDistributionAssetValuationWith?: InputMaybe<Array<AssetValuationV2WhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** grantor edge predicates */
  hasGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantorWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** successor_trustees edge predicates */
  hasSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuccessorTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** trust_advisors edge predicates */
  hasTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustAdvisorsWith?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** trustees edge predicates */
  hasTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** implementation_notes_to_attorney field predicates */
  implementationNotesToAttorney?: InputMaybe<Scalars['String']['input']>;
  implementationNotesToAttorneyContains?: InputMaybe<Scalars['String']['input']>;
  implementationNotesToAttorneyContainsFold?: InputMaybe<Scalars['String']['input']>;
  implementationNotesToAttorneyEqualFold?: InputMaybe<Scalars['String']['input']>;
  implementationNotesToAttorneyGT?: InputMaybe<Scalars['String']['input']>;
  implementationNotesToAttorneyGTE?: InputMaybe<Scalars['String']['input']>;
  implementationNotesToAttorneyHasPrefix?: InputMaybe<Scalars['String']['input']>;
  implementationNotesToAttorneyHasSuffix?: InputMaybe<Scalars['String']['input']>;
  implementationNotesToAttorneyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  implementationNotesToAttorneyIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  implementationNotesToAttorneyLT?: InputMaybe<Scalars['String']['input']>;
  implementationNotesToAttorneyLTE?: InputMaybe<Scalars['String']['input']>;
  implementationNotesToAttorneyNEQ?: InputMaybe<Scalars['String']['input']>;
  implementationNotesToAttorneyNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  implementationNotesToAttorneyNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<GratTrustWhereInput>;
  /** official_interest_rate_percent field predicates */
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentGT?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  officialInterestRatePercentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  officialInterestRatePercentLT?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  officialInterestRatePercentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<GratTrustWhereInput>>;
  /** projected_market_value field predicates */
  projectedMarketValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  projectedMarketValueIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  projectedMarketValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedMarketValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  projectedMarketValueNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** projected_rate_of_return field predicates */
  projectedRateOfReturn?: InputMaybe<Scalars['Percent']['input']>;
  projectedRateOfReturnGT?: InputMaybe<Scalars['Percent']['input']>;
  projectedRateOfReturnGTE?: InputMaybe<Scalars['Percent']['input']>;
  projectedRateOfReturnIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  projectedRateOfReturnIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  projectedRateOfReturnLT?: InputMaybe<Scalars['Percent']['input']>;
  projectedRateOfReturnLTE?: InputMaybe<Scalars['Percent']['input']>;
  projectedRateOfReturnNEQ?: InputMaybe<Scalars['Percent']['input']>;
  projectedRateOfReturnNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  projectedRateOfReturnNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** projected_share_price field predicates */
  projectedSharePrice?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedSharePriceGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedSharePriceGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedSharePriceIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  projectedSharePriceIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  projectedSharePriceLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedSharePriceLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedSharePriceNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedSharePriceNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  projectedSharePriceNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** rolling_period_years field predicates */
  rollingPeriodYears?: InputMaybe<Scalars['Int']['input']>;
  rollingPeriodYearsGT?: InputMaybe<Scalars['Int']['input']>;
  rollingPeriodYearsGTE?: InputMaybe<Scalars['Int']['input']>;
  rollingPeriodYearsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  rollingPeriodYearsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  rollingPeriodYearsLT?: InputMaybe<Scalars['Int']['input']>;
  rollingPeriodYearsLTE?: InputMaybe<Scalars['Int']['input']>;
  rollingPeriodYearsNEQ?: InputMaybe<Scalars['Int']['input']>;
  rollingPeriodYearsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  rollingPeriodYearsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** target_taxable_gift field predicates */
  targetTaxableGift?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  targetTaxableGiftGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  targetTaxableGiftGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  targetTaxableGiftIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  targetTaxableGiftIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  targetTaxableGiftLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  targetTaxableGiftLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  targetTaxableGiftNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  targetTaxableGiftNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  targetTaxableGiftNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<TrustTaxStatus>;
  taxStatusIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<TrustTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** taxable_gift field predicates */
  taxableGift?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  taxableGiftIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxableGiftLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  taxableGiftNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** term_duration_years field predicates */
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termDurationYearsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  termDurationYearsLT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsLTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNEQ?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termDurationYearsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** trust_jurisdiction_state_code field predicates */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  trustJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** upside_as_target_surplus field predicates */
  upsideAsTargetSurplus?: InputMaybe<Scalars['Boolean']['input']>;
  upsideAsTargetSurplusNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** upside_threshold_percent field predicates */
  upsideThresholdPercent?: InputMaybe<Scalars['Percent']['input']>;
  upsideThresholdPercentGT?: InputMaybe<Scalars['Percent']['input']>;
  upsideThresholdPercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  upsideThresholdPercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  upsideThresholdPercentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  upsideThresholdPercentLT?: InputMaybe<Scalars['Percent']['input']>;
  upsideThresholdPercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  upsideThresholdPercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  upsideThresholdPercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  upsideThresholdPercentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** upside_threshold_value field predicates */
  upsideThresholdValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  upsideThresholdValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  upsideThresholdValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  upsideThresholdValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  upsideThresholdValueIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  upsideThresholdValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  upsideThresholdValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  upsideThresholdValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  upsideThresholdValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  upsideThresholdValueNotNil?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GenerateKnowledgeGraphInput = {
  documentIds: Array<Scalars['ID']['input']>;
  householdID: Scalars['ID']['input'];
};

export type GiftingProposal = Node & {
  __typename?: 'GiftingProposal';
  /** The base cash flow items for this proposal that will be applied to all scenarios */
  baseCashFlows?: Maybe<Array<GiftingProposalCashFlow>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The default gifting scenario for this proposal. There can only be one default. */
  defaultGiftingScenario?: Maybe<GiftingProposalScenario>;
  /** Gift exemption grows by this amount each year. */
  exemptionGrowthRate?: Maybe<Scalars['Percent']['output']>;
  /** If true, exemption amount is halved at the end of 2025, then continues growing */
  exemptionSunsets?: Maybe<Scalars['Boolean']['output']>;
  /** The various gifting scenarios modeled for this proposal */
  giftingScenarios?: Maybe<Array<GiftingProposalScenario>>;
  id: Scalars['ID']['output'];
  /** The total value of all assets in the client's estate */
  inEstatePortfolioValue: Scalars['CurrencyUSD']['output'];
  /** number of years to run the gifting analysis */
  lengthOfAnalysis: Scalars['Int']['output'];
  /** The out-of-estate portfolios modeled for this proposal */
  outOfEstatePortfolios?: Maybe<Array<GiftingProposalPortfolio>>;
  /** The high end of the pre-tax return percentage assumption */
  preTaxReturnPercentageHigh?: Maybe<Scalars['Percent']['output']>;
  /** The low end of the pre-tax return assumption */
  preTaxReturnPercentageLow?: Maybe<Scalars['Percent']['output']>;
  /** The medium pre-tax return percentage assumption */
  preTaxReturnPercentageMedium?: Maybe<Scalars['Percent']['output']>;
  proposal: Proposal;
  /** @deprecated no longer needed */
  proposalYears: GiftingProposalYears;
  /** The selected pre-tax return (and tax drag) category. Used when computing projections for scenarios. */
  selectedPreTaxReturnCategory?: Maybe<GiftingProposalSelectedPreTaxReturnCategory>;
  /** The selected year of analysis. Defaults to end year. Used when computing projections for scenarios. */
  selectedYearOfAnalysis?: Maybe<Scalars['Int']['output']>;
  /** If true, show after estate tax */
  showAfterEstateTax?: Maybe<Scalars['Boolean']['output']>;
  /** Status of the gifting proposal */
  status: GiftingProposalStatus;
  /** The associated tax drag for the high pre-tax return */
  taxDragPercentageHigh?: Maybe<Scalars['Percent']['output']>;
  /** The associated tax drag for the low pre-tax return */
  taxDragPercentageLow?: Maybe<Scalars['Percent']['output']>;
  /** The associated tax drag for the medium pre-tax return */
  taxDragPercentageMedium?: Maybe<Scalars['Percent']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

export type GiftingProposalCashFlow = Node & {
  __typename?: 'GiftingProposalCashFlow';
  /** Cash flow amount */
  amount: Scalars['CurrencyUSD']['output'];
  /** Whether this cash flow occurs every year or only once */
  annuallyRecurring?: Maybe<Scalars['Boolean']['output']>;
  /** Whether the cash flow is an income or expense */
  cashFlowType: GiftingProposalCashFlowCashFlowType;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** the user-friendly name of this cash flow */
  displayName: Scalars['String']['output'];
  /** If recurring, the year this cash flow ends. If not recurring, this field is not set. */
  endYear?: Maybe<Scalars['Int']['output']>;
  /** Will be nil if this is a scenario cash flow, rather than a base cash flow for the whole proposal. */
  giftingProposal?: Maybe<GiftingProposal>;
  /** Will be nil if this is a base cash flow, rather than cash flow for a specific scenario. */
  giftingScenario?: Maybe<GiftingProposalScenario>;
  /** If recurring, the percentage that the cash flow amount grows each year */
  growthPercentage?: Maybe<Scalars['Percent']['output']>;
  id: Scalars['ID']['output'];
  /** The order to display cash flows in the UI */
  order: Scalars['Int']['output'];
  /** If recurring, the year this cash flow starts. If not recurring, the year this cash flow occurs. */
  startYear: Scalars['Int']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** GiftingProposalCashFlowCashFlowType is enum for the field cash_flow_type */
export enum GiftingProposalCashFlowCashFlowType {
  Expense = 'EXPENSE',
  Income = 'INCOME'
}

/** A connection to a list of items. */
export type GiftingProposalCashFlowConnection = {
  __typename?: 'GiftingProposalCashFlowConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GiftingProposalCashFlowEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GiftingProposalCashFlowEdge = {
  __typename?: 'GiftingProposalCashFlowEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GiftingProposalCashFlow>;
};

/** Ordering options for GiftingProposalCashFlow connections */
export type GiftingProposalCashFlowOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GiftingProposalCashFlows. */
  field: GiftingProposalCashFlowOrderField;
};

/** Properties by which GiftingProposalCashFlow connections can be ordered. */
export enum GiftingProposalCashFlowOrderField {
  CashFlowOrder = 'CASH_FLOW_ORDER',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * GiftingProposalCashFlowWhereInput is used for filtering GiftingProposalCashFlow objects.
 * Input was generated by ent.
 */
export type GiftingProposalCashFlowWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  amountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  and?: InputMaybe<Array<GiftingProposalCashFlowWhereInput>>;
  /** annually_recurring field predicates */
  annuallyRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  annuallyRecurringIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annuallyRecurringNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  annuallyRecurringNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** cash_flow_type field predicates */
  cashFlowType?: InputMaybe<GiftingProposalCashFlowCashFlowType>;
  cashFlowTypeIn?: InputMaybe<Array<GiftingProposalCashFlowCashFlowType>>;
  cashFlowTypeNEQ?: InputMaybe<GiftingProposalCashFlowCashFlowType>;
  cashFlowTypeNotIn?: InputMaybe<Array<GiftingProposalCashFlowCashFlowType>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** end_year field predicates */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  endYearGT?: InputMaybe<Scalars['Int']['input']>;
  endYearGTE?: InputMaybe<Scalars['Int']['input']>;
  endYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  endYearIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  endYearLT?: InputMaybe<Scalars['Int']['input']>;
  endYearLTE?: InputMaybe<Scalars['Int']['input']>;
  endYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  endYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  endYearNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** growth_percentage field predicates */
  growthPercentage?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageGT?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageGTE?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  growthPercentageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  growthPercentageLT?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageLTE?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageNEQ?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  growthPercentageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** gifting_proposal edge predicates */
  hasGiftingProposal?: InputMaybe<Scalars['Boolean']['input']>;
  hasGiftingProposalWith?: InputMaybe<Array<GiftingProposalWhereInput>>;
  /** gifting_scenario edge predicates */
  hasGiftingScenario?: InputMaybe<Scalars['Boolean']['input']>;
  hasGiftingScenarioWith?: InputMaybe<Array<GiftingProposalScenarioWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<GiftingProposalCashFlowWhereInput>;
  or?: InputMaybe<Array<GiftingProposalCashFlowWhereInput>>;
  /** order field predicates */
  order?: InputMaybe<Scalars['Int']['input']>;
  orderGT?: InputMaybe<Scalars['Int']['input']>;
  orderGTE?: InputMaybe<Scalars['Int']['input']>;
  orderIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderLT?: InputMaybe<Scalars['Int']['input']>;
  orderLTE?: InputMaybe<Scalars['Int']['input']>;
  orderNEQ?: InputMaybe<Scalars['Int']['input']>;
  orderNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** start_year field predicates */
  startYear?: InputMaybe<Scalars['Int']['input']>;
  startYearGT?: InputMaybe<Scalars['Int']['input']>;
  startYearGTE?: InputMaybe<Scalars['Int']['input']>;
  startYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  startYearLT?: InputMaybe<Scalars['Int']['input']>;
  startYearLTE?: InputMaybe<Scalars['Int']['input']>;
  startYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  startYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type GiftingProposalClientProjectionParams = {
  exemptionSunsets?: InputMaybe<Scalars['Boolean']['input']>;
  giftingProposalId: Scalars['ID']['input'];
  preTaxReturnCategory?: InputMaybe<GiftingProposalSelectedPreTaxReturnCategory>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of items. */
export type GiftingProposalConnection = {
  __typename?: 'GiftingProposalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GiftingProposalEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GiftingProposalEdge = {
  __typename?: 'GiftingProposalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GiftingProposal>;
};

export type GiftingProposalGift = Node & {
  __typename?: 'GiftingProposalGift';
  /** Gift amount */
  amount: Scalars['CurrencyUSD']['output'];
  /** Whether this gift occurs every year or only once */
  annuallyRecurring?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** If true, gift amount is discounted for gift tax purposes */
  discount?: Maybe<Scalars['Boolean']['output']>;
  /** If discount is true, the discount percentage applied to the gift amount */
  discountPercentage?: Maybe<Scalars['Percent']['output']>;
  /** the user-friendly name of this gifting scenario */
  displayName: Scalars['String']['output'];
  /** If recurring, the year this gift ends. If not recurring, this field is not set. */
  endYear?: Maybe<Scalars['Int']['output']>;
  giftingScenario?: Maybe<GiftingProposalScenario>;
  /** If recurring, the percentage that the gift amount grows each year */
  growthPercentage?: Maybe<Scalars['Percent']['output']>;
  id: Scalars['ID']['output'];
  /** If true, gift is taxable, and sender will be one or both grantors. If false, can be annual exclusion, or charitable. */
  isTaxable: Scalars['Boolean']['output'];
  /** Will be null (or ignored) if gift is taxable. */
  nonTaxableGiftType?: Maybe<GiftingProposalGiftNonTaxableGiftType>;
  /** The order to display, and process the gifts when simulating the proposal scenarios */
  order: Scalars['Int']['output'];
  /** One of the client organizations that will receive the gift. Only one of the recipient fields will be set. */
  recipientClientOrganization?: Maybe<ClientOrganization>;
  /** One of the client profiles that will receive the gift. Only one of the recipient fields will be set. */
  recipientClientProfile?: Maybe<ClientProfile>;
  /** The kind of recipient for this gift. Either one of the modeled out-of-estate portfolios, an individual, or an organization. */
  recipientKind?: Maybe<GiftingProposalGiftRecipientKind>;
  /** One of the out-of-estate portfolios within the proposal that will receive the gift. Only one of the recipient fields will be set. */
  recipientPortfolio?: Maybe<GiftingProposalPortfolio>;
  /** The grantors of the gift */
  senders?: Maybe<Array<GiftingProposalGiftSender>>;
  /** If recurring, the year this gift starts. If not recurring, the year this gift occurs. */
  startYear: Scalars['Int']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type GiftingProposalGiftConnection = {
  __typename?: 'GiftingProposalGiftConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GiftingProposalGiftEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GiftingProposalGiftEdge = {
  __typename?: 'GiftingProposalGiftEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GiftingProposalGift>;
};

/** GiftingProposalGiftNonTaxableGiftType is enum for the field non_taxable_gift_type */
export enum GiftingProposalGiftNonTaxableGiftType {
  AnnualExclusion = 'ANNUAL_EXCLUSION',
  Charitable = 'CHARITABLE'
}

/** Ordering options for GiftingProposalGift connections */
export type GiftingProposalGiftOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GiftingProposalGifts. */
  field: GiftingProposalGiftOrderField;
};

/** Properties by which GiftingProposalGift connections can be ordered. */
export enum GiftingProposalGiftOrderField {
  GiftOrder = 'GIFT_ORDER',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** GiftingProposalGiftRecipientKind is enum for the field recipient_kind */
export enum GiftingProposalGiftRecipientKind {
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION',
  OutOfEstatePortfolio = 'OUT_OF_ESTATE_PORTFOLIO'
}

export type GiftingProposalGiftSender = Node & {
  __typename?: 'GiftingProposalGiftSender';
  createdAt: Scalars['Time']['output'];
  gift?: Maybe<GiftingProposalGift>;
  /** The client profile that is the grantor/sender */
  grantor: ClientProfile;
  id: Scalars['ID']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type GiftingProposalGiftSenderConnection = {
  __typename?: 'GiftingProposalGiftSenderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GiftingProposalGiftSenderEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GiftingProposalGiftSenderEdge = {
  __typename?: 'GiftingProposalGiftSenderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GiftingProposalGiftSender>;
};

/** Ordering options for GiftingProposalGiftSender connections */
export type GiftingProposalGiftSenderOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GiftingProposalGiftSenders. */
  field: GiftingProposalGiftSenderOrderField;
};

/** Properties by which GiftingProposalGiftSender connections can be ordered. */
export enum GiftingProposalGiftSenderOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * GiftingProposalGiftSenderWhereInput is used for filtering GiftingProposalGiftSender objects.
 * Input was generated by ent.
 */
export type GiftingProposalGiftSenderWhereInput = {
  and?: InputMaybe<Array<GiftingProposalGiftSenderWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** gift edge predicates */
  hasGift?: InputMaybe<Scalars['Boolean']['input']>;
  hasGiftWith?: InputMaybe<Array<GiftingProposalGiftWhereInput>>;
  /** grantor edge predicates */
  hasGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantorWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<GiftingProposalGiftSenderWhereInput>;
  or?: InputMaybe<Array<GiftingProposalGiftSenderWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * GiftingProposalGiftWhereInput is used for filtering GiftingProposalGift objects.
 * Input was generated by ent.
 */
export type GiftingProposalGiftWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  amountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  and?: InputMaybe<Array<GiftingProposalGiftWhereInput>>;
  /** annually_recurring field predicates */
  annuallyRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  annuallyRecurringIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annuallyRecurringNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  annuallyRecurringNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** discount field predicates */
  discount?: InputMaybe<Scalars['Boolean']['input']>;
  discountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  discountNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  discountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** discount_percentage field predicates */
  discountPercentage?: InputMaybe<Scalars['Percent']['input']>;
  discountPercentageGT?: InputMaybe<Scalars['Percent']['input']>;
  discountPercentageGTE?: InputMaybe<Scalars['Percent']['input']>;
  discountPercentageIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  discountPercentageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  discountPercentageLT?: InputMaybe<Scalars['Percent']['input']>;
  discountPercentageLTE?: InputMaybe<Scalars['Percent']['input']>;
  discountPercentageNEQ?: InputMaybe<Scalars['Percent']['input']>;
  discountPercentageNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  discountPercentageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** end_year field predicates */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  endYearGT?: InputMaybe<Scalars['Int']['input']>;
  endYearGTE?: InputMaybe<Scalars['Int']['input']>;
  endYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  endYearIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  endYearLT?: InputMaybe<Scalars['Int']['input']>;
  endYearLTE?: InputMaybe<Scalars['Int']['input']>;
  endYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  endYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  endYearNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** growth_percentage field predicates */
  growthPercentage?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageGT?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageGTE?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  growthPercentageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  growthPercentageLT?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageLTE?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageNEQ?: InputMaybe<Scalars['Percent']['input']>;
  growthPercentageNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  growthPercentageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** gifting_scenario edge predicates */
  hasGiftingScenario?: InputMaybe<Scalars['Boolean']['input']>;
  hasGiftingScenarioWith?: InputMaybe<Array<GiftingProposalScenarioWhereInput>>;
  /** recipient_client_organization edge predicates */
  hasRecipientClientOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecipientClientOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** recipient_client_profile edge predicates */
  hasRecipientClientProfile?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecipientClientProfileWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** recipient_portfolio edge predicates */
  hasRecipientPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecipientPortfolioWith?: InputMaybe<Array<GiftingProposalPortfolioWhereInput>>;
  /** senders edge predicates */
  hasSenders?: InputMaybe<Scalars['Boolean']['input']>;
  hasSendersWith?: InputMaybe<Array<GiftingProposalGiftSenderWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_taxable field predicates */
  isTaxable?: InputMaybe<Scalars['Boolean']['input']>;
  isTaxableNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** non_taxable_gift_type field predicates */
  nonTaxableGiftType?: InputMaybe<GiftingProposalGiftNonTaxableGiftType>;
  nonTaxableGiftTypeIn?: InputMaybe<Array<GiftingProposalGiftNonTaxableGiftType>>;
  nonTaxableGiftTypeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  nonTaxableGiftTypeNEQ?: InputMaybe<GiftingProposalGiftNonTaxableGiftType>;
  nonTaxableGiftTypeNotIn?: InputMaybe<Array<GiftingProposalGiftNonTaxableGiftType>>;
  nonTaxableGiftTypeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<GiftingProposalGiftWhereInput>;
  or?: InputMaybe<Array<GiftingProposalGiftWhereInput>>;
  /** order field predicates */
  order?: InputMaybe<Scalars['Int']['input']>;
  orderGT?: InputMaybe<Scalars['Int']['input']>;
  orderGTE?: InputMaybe<Scalars['Int']['input']>;
  orderIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderLT?: InputMaybe<Scalars['Int']['input']>;
  orderLTE?: InputMaybe<Scalars['Int']['input']>;
  orderNEQ?: InputMaybe<Scalars['Int']['input']>;
  orderNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** recipient_kind field predicates */
  recipientKind?: InputMaybe<GiftingProposalGiftRecipientKind>;
  recipientKindIn?: InputMaybe<Array<GiftingProposalGiftRecipientKind>>;
  recipientKindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  recipientKindNEQ?: InputMaybe<GiftingProposalGiftRecipientKind>;
  recipientKindNotIn?: InputMaybe<Array<GiftingProposalGiftRecipientKind>>;
  recipientKindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** start_year field predicates */
  startYear?: InputMaybe<Scalars['Int']['input']>;
  startYearGT?: InputMaybe<Scalars['Int']['input']>;
  startYearGTE?: InputMaybe<Scalars['Int']['input']>;
  startYearIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  startYearLT?: InputMaybe<Scalars['Int']['input']>;
  startYearLTE?: InputMaybe<Scalars['Int']['input']>;
  startYearNEQ?: InputMaybe<Scalars['Int']['input']>;
  startYearNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for GiftingProposal connections */
export type GiftingProposalOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GiftingProposals. */
  field: GiftingProposalOrderField;
};

/** Properties by which GiftingProposal connections can be ordered. */
export enum GiftingProposalOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type GiftingProposalPortfolio = Node & {
  __typename?: 'GiftingProposalPortfolio';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** the user-friendly name of the out-of-estate portfolio */
  displayName: Scalars['String']['output'];
  giftingProposal?: Maybe<GiftingProposal>;
  id: Scalars['ID']['output'];
  /** The order to display portfolios in the UI */
  order: Scalars['Int']['output'];
  /** Portfolio type */
  portfolioType: GiftingProposalPortfolioPortfolioType;
  recipientOf?: Maybe<Array<GiftingProposalGift>>;
  /** The starting value of the out-of-estate portfolio */
  startingAssetValue: Scalars['CurrencyUSD']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type GiftingProposalPortfolioConnection = {
  __typename?: 'GiftingProposalPortfolioConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GiftingProposalPortfolioEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GiftingProposalPortfolioEdge = {
  __typename?: 'GiftingProposalPortfolioEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GiftingProposalPortfolio>;
};

/** Ordering options for GiftingProposalPortfolio connections */
export type GiftingProposalPortfolioOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GiftingProposalPortfolios. */
  field: GiftingProposalPortfolioOrderField;
};

/** Properties by which GiftingProposalPortfolio connections can be ordered. */
export enum GiftingProposalPortfolioOrderField {
  PortfolioOrder = 'PORTFOLIO_ORDER',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** GiftingProposalPortfolioPortfolioType is enum for the field portfolio_type */
export enum GiftingProposalPortfolioPortfolioType {
  Charitable = 'CHARITABLE',
  GrantorTrust = 'GRANTOR_TRUST',
  NonGrantorIndividual = 'NON_GRANTOR_INDIVIDUAL',
  NonGrantorTrust = 'NON_GRANTOR_TRUST'
}

/**
 * GiftingProposalPortfolioWhereInput is used for filtering GiftingProposalPortfolio objects.
 * Input was generated by ent.
 */
export type GiftingProposalPortfolioWhereInput = {
  and?: InputMaybe<Array<GiftingProposalPortfolioWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** gifting_proposal edge predicates */
  hasGiftingProposal?: InputMaybe<Scalars['Boolean']['input']>;
  hasGiftingProposalWith?: InputMaybe<Array<GiftingProposalWhereInput>>;
  /** recipient_of edge predicates */
  hasRecipientOf?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecipientOfWith?: InputMaybe<Array<GiftingProposalGiftWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<GiftingProposalPortfolioWhereInput>;
  or?: InputMaybe<Array<GiftingProposalPortfolioWhereInput>>;
  /** order field predicates */
  order?: InputMaybe<Scalars['Int']['input']>;
  orderGT?: InputMaybe<Scalars['Int']['input']>;
  orderGTE?: InputMaybe<Scalars['Int']['input']>;
  orderIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderLT?: InputMaybe<Scalars['Int']['input']>;
  orderLTE?: InputMaybe<Scalars['Int']['input']>;
  orderNEQ?: InputMaybe<Scalars['Int']['input']>;
  orderNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** portfolio_type field predicates */
  portfolioType?: InputMaybe<GiftingProposalPortfolioPortfolioType>;
  portfolioTypeIn?: InputMaybe<Array<GiftingProposalPortfolioPortfolioType>>;
  portfolioTypeNEQ?: InputMaybe<GiftingProposalPortfolioPortfolioType>;
  portfolioTypeNotIn?: InputMaybe<Array<GiftingProposalPortfolioPortfolioType>>;
  /** starting_asset_value field predicates */
  startingAssetValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  startingAssetValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  startingAssetValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  startingAssetValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  startingAssetValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  startingAssetValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  startingAssetValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  startingAssetValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type GiftingProposalProjections = {
  __typename?: 'GiftingProposalProjections';
  estateTax: Scalars['CurrencyUSD']['output'];
  estateTaxSummary: TaxSummary;
  giftTax: Scalars['CurrencyUSD']['output'];
  inEstateValueAfterTax: Scalars['CurrencyUSD']['output'];
  inEstateValueBeforeTax: Scalars['CurrencyUSD']['output'];
  lifetimeExemptionAppliedTowardGiftTax: Scalars['CurrencyUSD']['output'];
  outOfEstateCharitableValue: Scalars['CurrencyUSD']['output'];
  outOfEstateFamilyValue: Scalars['CurrencyUSD']['output'];
  outOfEstateTotalValue: Scalars['CurrencyUSD']['output'];
  remainingLifetimeExemption: Scalars['CurrencyUSD']['output'];
  scenario: GiftingProposalScenario;
  scenarioId: Scalars['ID']['output'];
  taxableGift: Scalars['CurrencyUSD']['output'];
  totalValue: Scalars['CurrencyUSD']['output'];
};

export type GiftingProposalScenario = Node & {
  __typename?: 'GiftingProposalScenario';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** the user-friendly name of this gifting scenario */
  displayName: Scalars['String']['output'];
  giftingProposal?: Maybe<GiftingProposal>;
  id: Scalars['ID']['output'];
  isBaseline: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  /** The order to display scenarios in the UI */
  order: Scalars['Int']['output'];
  projections: GiftingProposalProjections;
  /** The cash flows included in this scenario */
  scenarioCashFlows?: Maybe<Array<GiftingProposalCashFlow>>;
  /** The gifts included in this scenario */
  scenarioGifts?: Maybe<Array<GiftingProposalGift>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type GiftingProposalScenarioConnection = {
  __typename?: 'GiftingProposalScenarioConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GiftingProposalScenarioEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GiftingProposalScenarioEdge = {
  __typename?: 'GiftingProposalScenarioEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GiftingProposalScenario>;
};

/** Ordering options for GiftingProposalScenario connections */
export type GiftingProposalScenarioOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GiftingProposalScenarios. */
  field: GiftingProposalScenarioOrderField;
};

/** Properties by which GiftingProposalScenario connections can be ordered. */
export enum GiftingProposalScenarioOrderField {
  ScenarioOrder = 'SCENARIO_ORDER',
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * GiftingProposalScenarioWhereInput is used for filtering GiftingProposalScenario objects.
 * Input was generated by ent.
 */
export type GiftingProposalScenarioWhereInput = {
  and?: InputMaybe<Array<GiftingProposalScenarioWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** gifting_proposal edge predicates */
  hasGiftingProposal?: InputMaybe<Scalars['Boolean']['input']>;
  hasGiftingProposalWith?: InputMaybe<Array<GiftingProposalWhereInput>>;
  /** scenario_cash_flows edge predicates */
  hasScenarioCashFlows?: InputMaybe<Scalars['Boolean']['input']>;
  hasScenarioCashFlowsWith?: InputMaybe<Array<GiftingProposalCashFlowWhereInput>>;
  /** scenario_gifts edge predicates */
  hasScenarioGifts?: InputMaybe<Scalars['Boolean']['input']>;
  hasScenarioGiftsWith?: InputMaybe<Array<GiftingProposalGiftWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<GiftingProposalScenarioWhereInput>;
  or?: InputMaybe<Array<GiftingProposalScenarioWhereInput>>;
  /** order field predicates */
  order?: InputMaybe<Scalars['Int']['input']>;
  orderGT?: InputMaybe<Scalars['Int']['input']>;
  orderGTE?: InputMaybe<Scalars['Int']['input']>;
  orderIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  orderLT?: InputMaybe<Scalars['Int']['input']>;
  orderLTE?: InputMaybe<Scalars['Int']['input']>;
  orderNEQ?: InputMaybe<Scalars['Int']['input']>;
  orderNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** GiftingProposalSelectedPreTaxReturnCategory is enum for the field selected_pre_tax_return_category */
export enum GiftingProposalSelectedPreTaxReturnCategory {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

/** GiftingProposalStatus is enum for the field status */
export enum GiftingProposalStatus {
  Draft = 'DRAFT',
  ProposalCreated = 'PROPOSAL_CREATED',
  ReadyForProposal = 'READY_FOR_PROPOSAL'
}

/**
 * GiftingProposalWhereInput is used for filtering GiftingProposal objects.
 * Input was generated by ent.
 */
export type GiftingProposalWhereInput = {
  and?: InputMaybe<Array<GiftingProposalWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** exemption_growth_rate field predicates */
  exemptionGrowthRate?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateGT?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateGTE?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  exemptionGrowthRateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  exemptionGrowthRateLT?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateLTE?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateNEQ?: InputMaybe<Scalars['Percent']['input']>;
  exemptionGrowthRateNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  exemptionGrowthRateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** exemption_sunsets field predicates */
  exemptionSunsets?: InputMaybe<Scalars['Boolean']['input']>;
  exemptionSunsetsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  exemptionSunsetsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  exemptionSunsetsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** base_cash_flows edge predicates */
  hasBaseCashFlows?: InputMaybe<Scalars['Boolean']['input']>;
  hasBaseCashFlowsWith?: InputMaybe<Array<GiftingProposalCashFlowWhereInput>>;
  /** default_gifting_scenario edge predicates */
  hasDefaultGiftingScenario?: InputMaybe<Scalars['Boolean']['input']>;
  hasDefaultGiftingScenarioWith?: InputMaybe<Array<GiftingProposalScenarioWhereInput>>;
  /** gifting_scenarios edge predicates */
  hasGiftingScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasGiftingScenariosWith?: InputMaybe<Array<GiftingProposalScenarioWhereInput>>;
  /** out_of_estate_portfolios edge predicates */
  hasOutOfEstatePortfolios?: InputMaybe<Scalars['Boolean']['input']>;
  hasOutOfEstatePortfoliosWith?: InputMaybe<Array<GiftingProposalPortfolioWhereInput>>;
  /** proposal edge predicates */
  hasProposal?: InputMaybe<Scalars['Boolean']['input']>;
  hasProposalWith?: InputMaybe<Array<ProposalWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_portfolio_value field predicates */
  inEstatePortfolioValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  inEstatePortfolioValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  inEstatePortfolioValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  inEstatePortfolioValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  inEstatePortfolioValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  inEstatePortfolioValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  inEstatePortfolioValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  inEstatePortfolioValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  /** length_of_analysis field predicates */
  lengthOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
  lengthOfAnalysisGT?: InputMaybe<Scalars['Int']['input']>;
  lengthOfAnalysisGTE?: InputMaybe<Scalars['Int']['input']>;
  lengthOfAnalysisIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  lengthOfAnalysisLT?: InputMaybe<Scalars['Int']['input']>;
  lengthOfAnalysisLTE?: InputMaybe<Scalars['Int']['input']>;
  lengthOfAnalysisNEQ?: InputMaybe<Scalars['Int']['input']>;
  lengthOfAnalysisNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  not?: InputMaybe<GiftingProposalWhereInput>;
  or?: InputMaybe<Array<GiftingProposalWhereInput>>;
  /** pre_tax_return_percentage_high field predicates */
  preTaxReturnPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighGT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighGTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageHighIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  preTaxReturnPercentageHighLT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighLTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighNEQ?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageHighNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageHighNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** pre_tax_return_percentage_low field predicates */
  preTaxReturnPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowGT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowGTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageLowIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  preTaxReturnPercentageLowLT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowLTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowNEQ?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageLowNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageLowNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** pre_tax_return_percentage_medium field predicates */
  preTaxReturnPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumGT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumGTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageMediumIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  preTaxReturnPercentageMediumLT?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumLTE?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumNEQ?: InputMaybe<Scalars['Percent']['input']>;
  preTaxReturnPercentageMediumNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  preTaxReturnPercentageMediumNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** selected_pre_tax_return_category field predicates */
  selectedPreTaxReturnCategory?: InputMaybe<GiftingProposalSelectedPreTaxReturnCategory>;
  selectedPreTaxReturnCategoryIn?: InputMaybe<Array<GiftingProposalSelectedPreTaxReturnCategory>>;
  selectedPreTaxReturnCategoryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  selectedPreTaxReturnCategoryNEQ?: InputMaybe<GiftingProposalSelectedPreTaxReturnCategory>;
  selectedPreTaxReturnCategoryNotIn?: InputMaybe<Array<GiftingProposalSelectedPreTaxReturnCategory>>;
  selectedPreTaxReturnCategoryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** selected_year_of_analysis field predicates */
  selectedYearOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
  selectedYearOfAnalysisGT?: InputMaybe<Scalars['Int']['input']>;
  selectedYearOfAnalysisGTE?: InputMaybe<Scalars['Int']['input']>;
  selectedYearOfAnalysisIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  selectedYearOfAnalysisIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  selectedYearOfAnalysisLT?: InputMaybe<Scalars['Int']['input']>;
  selectedYearOfAnalysisLTE?: InputMaybe<Scalars['Int']['input']>;
  selectedYearOfAnalysisNEQ?: InputMaybe<Scalars['Int']['input']>;
  selectedYearOfAnalysisNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  selectedYearOfAnalysisNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** show_after_estate_tax field predicates */
  showAfterEstateTax?: InputMaybe<Scalars['Boolean']['input']>;
  showAfterEstateTaxIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  showAfterEstateTaxNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  showAfterEstateTaxNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** status field predicates */
  status?: InputMaybe<GiftingProposalStatus>;
  statusIn?: InputMaybe<Array<GiftingProposalStatus>>;
  statusNEQ?: InputMaybe<GiftingProposalStatus>;
  statusNotIn?: InputMaybe<Array<GiftingProposalStatus>>;
  /** tax_drag_percentage_high field predicates */
  taxDragPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageHighIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDragPercentageHighLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageHighNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageHighNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_drag_percentage_low field predicates */
  taxDragPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageLowIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDragPercentageLowLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageLowNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageLowNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_drag_percentage_medium field predicates */
  taxDragPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageMediumIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDragPercentageMediumLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDragPercentageMediumNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDragPercentageMediumNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type GiftingProposalYears = {
  __typename?: 'GiftingProposalYears';
  end: Scalars['Int']['output'];
  median: Scalars['Int']['output'];
  start: Scalars['Int']['output'];
};

export type GiftsToIndividualsPerformanceReport = {
  __typename?: 'GiftsToIndividualsPerformanceReport';
  wealthTransferred: Scalars['CurrencyUSD']['output'];
};

export type GlobalSearchInput = {
  first?: InputMaybe<Scalars['Int']['input']>;
  maxDistance?: InputMaybe<Scalars['Float']['input']>;
  query: Scalars['String']['input'];
  searchTypes?: InputMaybe<Array<SearchableType>>;
};

export type GlobalSearchResult = {
  __typename?: 'GlobalSearchResult';
  clientOrganizationResults: Array<SearchResult>;
  clientProfileResults: Array<SearchResult>;
  documentResults: Array<SearchResult>;
  entityResults: Array<SearchResult>;
  householdResults: Array<SearchResult>;
  proposalResults: Array<SearchResult>;
};

export type GrantsToCharitableOrganizations = {
  __typename?: 'GrantsToCharitableOrganizations';
  entityID: Scalars['ID']['output'];
  grantingEntityName: Scalars['String']['output'];
  wealthTransferred: Scalars['CurrencyUSD']['output'];
};

export type GraphVisualizationGroup = Node & {
  __typename?: 'GraphVisualizationGroup';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** An optional namespace for this group. Can correspond to sections in the Graph Visualization. */
  namespace?: Maybe<Scalars['String']['output']>;
  nodes: Array<Node>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type GraphVisualizationGroupConnection = {
  __typename?: 'GraphVisualizationGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<GraphVisualizationGroupEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GraphVisualizationGroupEdge = {
  __typename?: 'GraphVisualizationGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<GraphVisualizationGroup>;
};

/** Ordering options for GraphVisualizationGroup connections */
export type GraphVisualizationGroupOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order GraphVisualizationGroups. */
  field: GraphVisualizationGroupOrderField;
};

/** Properties by which GraphVisualizationGroup connections can be ordered. */
export enum GraphVisualizationGroupOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * GraphVisualizationGroupWhereInput is used for filtering GraphVisualizationGroup objects.
 * Input was generated by ent.
 */
export type GraphVisualizationGroupWhereInput = {
  and?: InputMaybe<Array<GraphVisualizationGroupWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** namespace field predicates */
  namespace?: InputMaybe<Scalars['String']['input']>;
  namespaceContains?: InputMaybe<Scalars['String']['input']>;
  namespaceContainsFold?: InputMaybe<Scalars['String']['input']>;
  namespaceEqualFold?: InputMaybe<Scalars['String']['input']>;
  namespaceGT?: InputMaybe<Scalars['String']['input']>;
  namespaceGTE?: InputMaybe<Scalars['String']['input']>;
  namespaceHasPrefix?: InputMaybe<Scalars['String']['input']>;
  namespaceHasSuffix?: InputMaybe<Scalars['String']['input']>;
  namespaceIn?: InputMaybe<Array<Scalars['String']['input']>>;
  namespaceIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  namespaceLT?: InputMaybe<Scalars['String']['input']>;
  namespaceLTE?: InputMaybe<Scalars['String']['input']>;
  namespaceNEQ?: InputMaybe<Scalars['String']['input']>;
  namespaceNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  namespaceNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<GraphVisualizationGroupWhereInput>;
  or?: InputMaybe<Array<GraphVisualizationGroupWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type GraphVisualizationNode = {
  group?: Maybe<GraphVisualizationGroup>;
  id: Scalars['ID']['output'];
  namespace?: Maybe<Scalars['String']['output']>;
  node: Node;
  nodeConfiguration?: Maybe<EntityGraphNodeConfiguration>;
};

export type Household = Node & {
  __typename?: 'Household';
  activities: ActivityConnection;
  /** Employees assigned to this client */
  assignedEmployees?: Maybe<Array<Employee>>;
  /** Teams assigned to this client */
  assignedTeams?: Maybe<Array<Team>>;
  /** The balance sheet view configuration for this household */
  balanceSheetViewConfiguration?: Maybe<BalanceSheetViewConfiguration>;
  /** whether this client is billable or not. if not BILLABLE, this client's entities should be excluding from customer billing */
  billableKind: HouseholdBillableKind;
  branding: Branded;
  clientOrganizations?: Maybe<Array<ClientOrganization>>;
  clientPerformanceReport: ClientOverallPerformanceReport;
  clientProfiles?: Maybe<Array<ClientProfile>>;
  /** Collaborators that can access this client */
  collaborators?: Maybe<Array<Collaborator>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  documents?: Maybe<Array<Document>>;
  entities: EntityConnection;
  entityGraphViews?: Maybe<Array<EntityGraphView>>;
  estateTaxScenarios?: Maybe<Array<EstateTaxScenario>>;
  estateValueMetrics: HouseholdMetrics;
  estateWaterfalls?: Maybe<Array<EstateWaterfall>>;
  id: Scalars['ID']['output'];
  integrationClient?: Maybe<IntegrationClient>;
  isTwoGrantor: Scalars['Boolean']['output'];
  /** The liabilities associated with this household */
  liabilities?: Maybe<Array<Liability>>;
  nextTaskDue?: Maybe<EntityTask>;
  /** Free-form notes */
  notes?: Maybe<Scalars['String']['output']>;
  /** The onboarding process for this household */
  onboarding?: Maybe<HouseholdOnboarding>;
  onboardingKgRoots: KgRootConnection;
  /** @deprecated No longer supported */
  possibleBeneficiaries: Array<ClientProfile>;
  possibleBeneficiariesV2: PossibleBeneficiariesV2;
  possibleGrantors: Array<ClientProfile>;
  possiblePrimaryClients: Array<ClientProfile>;
  /**
   * possibleTaskAssignees is a list of the users on the platform that are eligible to be a task assignee for this household.
   * We use Users rather than Employees because we expect that we will assign collaborators to tasks as well.
   * @deprecated Use possibleTaskAssigneesV2 instead
   */
  possibleTaskAssignees: Array<Maybe<User>>;
  /** @deprecated No longer supported */
  possibleTrustees: Array<ClientProfile>;
  possibleTrusteesV2: PossibleTrusteesV2;
  presentations: ClientPresentationConnection;
  primaryRelationshipOwner: User;
  proposals?: Maybe<Array<Proposal>>;
  reportingEntities: Array<Maybe<Node>>;
  /** An optional sub-brand associated with this household */
  subBrand?: Maybe<SubBrand>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  testamentaryEntities: TestamentaryEntityConnection;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type HouseholdActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ActivityOrder>>;
  where?: InputMaybe<ActivityWhereInput>;
};


export type HouseholdEntitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityOrder>>;
  where?: InputMaybe<EntityWhereInput>;
};


export type HouseholdEstateValueMetricsArgs = {
  where?: InputMaybe<EntityWhereInput>;
};


export type HouseholdOnboardingKgRootsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<KgRootOrder>>;
  where?: InputMaybe<KgRootWhereInput>;
};


export type HouseholdPresentationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientPresentationOrder>>;
  where?: InputMaybe<ClientPresentationWhereInput>;
};


export type HouseholdTestamentaryEntitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestamentaryEntityOrder>>;
  where?: InputMaybe<TestamentaryEntityWhereInput>;
};

/** HouseholdBillableKind is enum for the field billable_kind */
export enum HouseholdBillableKind {
  Billable = 'BILLABLE',
  NonBillable = 'NON_BILLABLE',
  Prospect = 'PROSPECT'
}

/** A connection to a list of items. */
export type HouseholdConnection = {
  __typename?: 'HouseholdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HouseholdEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type HouseholdEdge = {
  __typename?: 'HouseholdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Household>;
};

export type HouseholdMetrics = {
  __typename?: 'HouseholdMetrics';
  inEstateValue: Scalars['CurrencyUSD']['output'];
  outOfEstateCharityValue: Scalars['CurrencyUSD']['output'];
  outOfEstateFamilyValue: Scalars['CurrencyUSD']['output'];
  outOfEstateValue: Scalars['CurrencyUSD']['output'];
  totalEstateValue: Scalars['CurrencyUSD']['output'];
};

export type HouseholdOnboarding = Node & {
  __typename?: 'HouseholdOnboarding';
  /** the time at which the onboarding was completed or dismissed */
  completedAt?: Maybe<Scalars['Time']['output']>;
  /** the method by which the onboarding was completed */
  completionMethod?: Maybe<HouseholdOnboardingCompletionMethod>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  household: Household;
  id: Scalars['ID']['output'];
  steps?: Maybe<Array<HouseholdOnboardingStep>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updateUser: User;
  updatedAt: Scalars['Time']['output'];
  updatedBy: Scalars['ID']['output'];
  user: User;
};

/** HouseholdOnboardingCompletionMethod is enum for the field completion_method */
export enum HouseholdOnboardingCompletionMethod {
  Completed = 'COMPLETED',
  Dismissed = 'DISMISSED'
}

/** Ordering options for HouseholdOnboarding connections */
export type HouseholdOnboardingOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order HouseholdOnboardings. */
  field: HouseholdOnboardingOrderField;
};

/** Properties by which HouseholdOnboarding connections can be ordered. */
export enum HouseholdOnboardingOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type HouseholdOnboardingStep = Node & {
  __typename?: 'HouseholdOnboardingStep';
  /** the time at which the onboarding was completed or dismissed */
  completedAt?: Maybe<Scalars['Time']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** which step is represented */
  kind: HouseholdOnboardingStepKind;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** HouseholdOnboardingStepKind is enum for the field kind */
export enum HouseholdOnboardingStepKind {
  AddEntities = 'ADD_ENTITIES',
  CreateHypotheticalWaterfall = 'CREATE_HYPOTHETICAL_WATERFALL',
  CreateWealthProfile = 'CREATE_WEALTH_PROFILE',
  CustomizeTaxCalc = 'CUSTOMIZE_TAX_CALC',
  GeneratePresentation = 'GENERATE_PRESENTATION',
  ModelStrategy = 'MODEL_STRATEGY',
  ReviewExemption = 'REVIEW_EXEMPTION',
  SpecifyBeneficiaries = 'SPECIFY_BENEFICIARIES',
  SpecifyDispositiveProvisions = 'SPECIFY_DISPOSITIVE_PROVISIONS',
  SummarizeDocument = 'SUMMARIZE_DOCUMENT'
}

/** Ordering options for HouseholdOnboardingStep connections */
export type HouseholdOnboardingStepOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order HouseholdOnboardingSteps. */
  field: HouseholdOnboardingStepOrderField;
};

/** Properties by which HouseholdOnboardingStep connections can be ordered. */
export enum HouseholdOnboardingStepOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * HouseholdOnboardingStepWhereInput is used for filtering HouseholdOnboardingStep objects.
 * Input was generated by ent.
 */
export type HouseholdOnboardingStepWhereInput = {
  and?: InputMaybe<Array<HouseholdOnboardingStepWhereInput>>;
  /** completed_at field predicates */
  completedAt?: InputMaybe<Scalars['Time']['input']>;
  completedAtGT?: InputMaybe<Scalars['Time']['input']>;
  completedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  completedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  completedAtLT?: InputMaybe<Scalars['Time']['input']>;
  completedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  completedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  completedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<HouseholdOnboardingStepKind>;
  kindIn?: InputMaybe<Array<HouseholdOnboardingStepKind>>;
  kindNEQ?: InputMaybe<HouseholdOnboardingStepKind>;
  kindNotIn?: InputMaybe<Array<HouseholdOnboardingStepKind>>;
  not?: InputMaybe<HouseholdOnboardingStepWhereInput>;
  or?: InputMaybe<Array<HouseholdOnboardingStepWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * HouseholdOnboardingWhereInput is used for filtering HouseholdOnboarding objects.
 * Input was generated by ent.
 */
export type HouseholdOnboardingWhereInput = {
  and?: InputMaybe<Array<HouseholdOnboardingWhereInput>>;
  /** completed_at field predicates */
  completedAt?: InputMaybe<Scalars['Time']['input']>;
  completedAtGT?: InputMaybe<Scalars['Time']['input']>;
  completedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  completedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  completedAtLT?: InputMaybe<Scalars['Time']['input']>;
  completedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  completedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  completedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  completedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** completion_method field predicates */
  completionMethod?: InputMaybe<HouseholdOnboardingCompletionMethod>;
  completionMethodIn?: InputMaybe<Array<HouseholdOnboardingCompletionMethod>>;
  completionMethodIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  completionMethodNEQ?: InputMaybe<HouseholdOnboardingCompletionMethod>;
  completionMethodNotIn?: InputMaybe<Array<HouseholdOnboardingCompletionMethod>>;
  completionMethodNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** steps edge predicates */
  hasSteps?: InputMaybe<Scalars['Boolean']['input']>;
  hasStepsWith?: InputMaybe<Array<HouseholdOnboardingStepWhereInput>>;
  /** update_user edge predicates */
  hasUpdateUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<HouseholdOnboardingWhereInput>;
  or?: InputMaybe<Array<HouseholdOnboardingWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['ID']['input']>;
  updatedByContains?: InputMaybe<Scalars['ID']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByGT?: InputMaybe<Scalars['ID']['input']>;
  updatedByGTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedByLT?: InputMaybe<Scalars['ID']['input']>;
  updatedByLTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['ID']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Ordering options for Household connections */
export type HouseholdOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Households. */
  field: HouseholdOrderField;
};

/** Properties by which Household connections can be ordered. */
export enum HouseholdOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * HouseholdWhereInput is used for filtering Household objects.
 * Input was generated by ent.
 */
export type HouseholdWhereInput = {
  and?: InputMaybe<Array<HouseholdWhereInput>>;
  /** billable_kind field predicates */
  billableKind?: InputMaybe<HouseholdBillableKind>;
  billableKindIn?: InputMaybe<Array<HouseholdBillableKind>>;
  billableKindNEQ?: InputMaybe<HouseholdBillableKind>;
  billableKindNotIn?: InputMaybe<Array<HouseholdBillableKind>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** activities edge predicates */
  hasActivities?: InputMaybe<Scalars['Boolean']['input']>;
  hasActivitiesWith?: InputMaybe<Array<ActivityWhereInput>>;
  /** assigned_employees edge predicates */
  hasAssignedEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssignedEmployeesWith?: InputMaybe<Array<EmployeeWhereInput>>;
  /** assigned_teams edge predicates */
  hasAssignedTeams?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssignedTeamsWith?: InputMaybe<Array<TeamWhereInput>>;
  /** balance_sheet_view_configuration edge predicates */
  hasBalanceSheetViewConfiguration?: InputMaybe<Scalars['Boolean']['input']>;
  hasBalanceSheetViewConfigurationWith?: InputMaybe<Array<BalanceSheetViewConfigurationWhereInput>>;
  /** client_organizations edge predicates */
  hasClientOrganizations?: InputMaybe<Scalars['Boolean']['input']>;
  hasClientOrganizationsWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** client_profiles edge predicates */
  hasClientProfiles?: InputMaybe<Scalars['Boolean']['input']>;
  hasClientProfilesWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** collaborators edge predicates */
  hasCollaborators?: InputMaybe<Scalars['Boolean']['input']>;
  hasCollaboratorsWith?: InputMaybe<Array<CollaboratorWhereInput>>;
  /** documents edge predicates */
  hasDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentsWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** entities edge predicates */
  hasEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntitiesWith?: InputMaybe<Array<EntityWhereInput>>;
  /** entity_graph_views edge predicates */
  hasEntityGraphViews?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityGraphViewsWith?: InputMaybe<Array<EntityGraphViewWhereInput>>;
  /** estate_tax_scenarios edge predicates */
  hasEstateTaxScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstateTaxScenariosWith?: InputMaybe<Array<EstateTaxScenarioWhereInput>>;
  /** estate_waterfalls edge predicates */
  hasEstateWaterfalls?: InputMaybe<Scalars['Boolean']['input']>;
  hasEstateWaterfallsWith?: InputMaybe<Array<EstateWaterfallWhereInput>>;
  /** integration_client edge predicates */
  hasIntegrationClient?: InputMaybe<Scalars['Boolean']['input']>;
  hasIntegrationClientWith?: InputMaybe<Array<IntegrationClientWhereInput>>;
  /** liabilities edge predicates */
  hasLiabilities?: InputMaybe<Scalars['Boolean']['input']>;
  hasLiabilitiesWith?: InputMaybe<Array<LiabilityWhereInput>>;
  /** onboarding edge predicates */
  hasOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  /** onboarding_kg_roots edge predicates */
  hasOnboardingKgRoots?: InputMaybe<Scalars['Boolean']['input']>;
  hasOnboardingKgRootsWith?: InputMaybe<Array<KgRootWhereInput>>;
  hasOnboardingWith?: InputMaybe<Array<HouseholdOnboardingWhereInput>>;
  /** presentations edge predicates */
  hasPresentations?: InputMaybe<Scalars['Boolean']['input']>;
  hasPresentationsWith?: InputMaybe<Array<ClientPresentationWhereInput>>;
  /** primary_relationship_owner edge predicates */
  hasPrimaryRelationshipOwner?: InputMaybe<Scalars['Boolean']['input']>;
  hasPrimaryRelationshipOwnerWith?: InputMaybe<Array<UserWhereInput>>;
  /** proposals edge predicates */
  hasProposals?: InputMaybe<Scalars['Boolean']['input']>;
  hasProposalsWith?: InputMaybe<Array<ProposalWhereInput>>;
  /** sub_brand edge predicates */
  hasSubBrand?: InputMaybe<Scalars['Boolean']['input']>;
  hasSubBrandWith?: InputMaybe<Array<SubBrandWhereInput>>;
  /** testamentary_entities edge predicates */
  hasTestamentaryEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasTestamentaryEntitiesWith?: InputMaybe<Array<TestamentaryEntityWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<HouseholdWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<HouseholdWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type HypotheticalWaterfallDispositiveProvisionTemplate = Node & {
  __typename?: 'HypotheticalWaterfallDispositiveProvisionTemplate';
  /** The hypothetical estate waterfall associated with this template link. */
  associatedHypotheticalWaterfall: EstateWaterfall;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The dispositive provision template associated with this hypothetical waterfall. */
  dispositiveProvisionTemplate: DispositiveProvisionTemplate;
  household: Household;
  /** the household this object belongs to */
  householdID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** The testamentary entities that are associated with this hypothetical waterfall. */
  testamentaryEntity?: Maybe<TestamentaryEntity>;
  updateUser: User;
  updatedAt: Scalars['Time']['output'];
  updatedBy: Scalars['ID']['output'];
  user: User;
};

/** A connection to a list of items. */
export type HypotheticalWaterfallDispositiveProvisionTemplateConnection = {
  __typename?: 'HypotheticalWaterfallDispositiveProvisionTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HypotheticalWaterfallDispositiveProvisionTemplateEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type HypotheticalWaterfallDispositiveProvisionTemplateEdge = {
  __typename?: 'HypotheticalWaterfallDispositiveProvisionTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<HypotheticalWaterfallDispositiveProvisionTemplate>;
};

/** Ordering options for HypotheticalWaterfallDispositiveProvisionTemplate connections */
export type HypotheticalWaterfallDispositiveProvisionTemplateOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order HypotheticalWaterfallDispositiveProvisionTemplates. */
  field: HypotheticalWaterfallDispositiveProvisionTemplateOrderField;
};

/** Properties by which HypotheticalWaterfallDispositiveProvisionTemplate connections can be ordered. */
export enum HypotheticalWaterfallDispositiveProvisionTemplateOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * HypotheticalWaterfallDispositiveProvisionTemplateWhereInput is used for filtering HypotheticalWaterfallDispositiveProvisionTemplate objects.
 * Input was generated by ent.
 */
export type HypotheticalWaterfallDispositiveProvisionTemplateWhereInput = {
  and?: InputMaybe<Array<HypotheticalWaterfallDispositiveProvisionTemplateWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** associated_hypothetical_waterfall edge predicates */
  hasAssociatedHypotheticalWaterfall?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssociatedHypotheticalWaterfallWith?: InputMaybe<Array<EstateWaterfallWhereInput>>;
  /** dispositive_provision_template edge predicates */
  hasDispositiveProvisionTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositiveProvisionTemplateWith?: InputMaybe<Array<DispositiveProvisionTemplateWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** testamentary_entity edge predicates */
  hasTestamentaryEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasTestamentaryEntityWith?: InputMaybe<Array<TestamentaryEntityWhereInput>>;
  /** update_user edge predicates */
  hasUpdateUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** household_id field predicates */
  householdID?: InputMaybe<Scalars['ID']['input']>;
  householdIDContains?: InputMaybe<Scalars['ID']['input']>;
  householdIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDGT?: InputMaybe<Scalars['ID']['input']>;
  householdIDGTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  householdIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDLT?: InputMaybe<Scalars['ID']['input']>;
  householdIDLTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  householdIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<HypotheticalWaterfallDispositiveProvisionTemplateWhereInput>;
  or?: InputMaybe<Array<HypotheticalWaterfallDispositiveProvisionTemplateWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['ID']['input']>;
  updatedByContains?: InputMaybe<Scalars['ID']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByGT?: InputMaybe<Scalars['ID']['input']>;
  updatedByGTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedByLT?: InputMaybe<Scalars['ID']['input']>;
  updatedByLTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['ID']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type IlitCrummeyLetter = Node & {
  __typename?: 'ILITCrummeyLetter';
  associatedTask?: Maybe<EntityTask>;
  createdAt: Scalars['Time']['output'];
  crummeyWithdrawalPeriodDays: Scalars['Int']['output'];
  /** Documents that are associated with this Crummey letter */
  documents?: Maybe<Array<Document>>;
  id: Scalars['ID']['output'];
  ilitTrust?: Maybe<IlitTrust>;
  recipient?: Maybe<ClientProfile>;
  sendBefore?: Maybe<Scalars['Time']['output']>;
  sentOn?: Maybe<Scalars['Time']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type IlitCrummeyLetterConnection = {
  __typename?: 'ILITCrummeyLetterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IlitCrummeyLetterEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IlitCrummeyLetterEdge = {
  __typename?: 'ILITCrummeyLetterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IlitCrummeyLetter>;
};

/** Ordering options for ILITCrummeyLetter connections */
export type IlitCrummeyLetterOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ILITCrummeyLetters. */
  field: IlitCrummeyLetterOrderField;
};

/** Properties by which ILITCrummeyLetter connections can be ordered. */
export enum IlitCrummeyLetterOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * ILITCrummeyLetterWhereInput is used for filtering ILITCrummeyLetter objects.
 * Input was generated by ent.
 */
export type IlitCrummeyLetterWhereInput = {
  and?: InputMaybe<Array<IlitCrummeyLetterWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** crummey_withdrawal_period_days field predicates */
  crummeyWithdrawalPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysGT?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysGTE?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  crummeyWithdrawalPeriodDaysLT?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysLTE?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysNEQ?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** associated_task edge predicates */
  hasAssociatedTask?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssociatedTaskWith?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** documents edge predicates */
  hasDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentsWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** ilit_trust edge predicates */
  hasIlitTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasIlitTrustWith?: InputMaybe<Array<IlitTrustWhereInput>>;
  /** recipient edge predicates */
  hasRecipient?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecipientWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<IlitCrummeyLetterWhereInput>;
  or?: InputMaybe<Array<IlitCrummeyLetterWhereInput>>;
  /** send_before field predicates */
  sendBefore?: InputMaybe<Scalars['Time']['input']>;
  sendBeforeGT?: InputMaybe<Scalars['Time']['input']>;
  sendBeforeGTE?: InputMaybe<Scalars['Time']['input']>;
  sendBeforeIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  sendBeforeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  sendBeforeLT?: InputMaybe<Scalars['Time']['input']>;
  sendBeforeLTE?: InputMaybe<Scalars['Time']['input']>;
  sendBeforeNEQ?: InputMaybe<Scalars['Time']['input']>;
  sendBeforeNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  sendBeforeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** sent_on field predicates */
  sentOn?: InputMaybe<Scalars['Time']['input']>;
  sentOnGT?: InputMaybe<Scalars['Time']['input']>;
  sentOnGTE?: InputMaybe<Scalars['Time']['input']>;
  sentOnIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  sentOnIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  sentOnLT?: InputMaybe<Scalars['Time']['input']>;
  sentOnLTE?: InputMaybe<Scalars['Time']['input']>;
  sentOnNEQ?: InputMaybe<Scalars['Time']['input']>;
  sentOnNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  sentOnNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type IlitTrust = EntitySubtype & Named & Node & Trust & {
  __typename?: 'ILITTrust';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  crummeyLetters: IlitCrummeyLetterConnection;
  crummeyWithdrawalPeriodDays?: Maybe<Scalars['Int']['output']>;
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this trust */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  grantors?: Maybe<Array<Principal>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  policies?: Maybe<Array<InsurancePolicy>>;
  successorTrustees?: Maybe<Array<Trustee>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<TrustTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  totalPolicyCashValue: Scalars['CurrencyUSD']['output'];
  trustAdvisors?: Maybe<Array<TrustAdvisor>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  trustees?: Maybe<Array<Trustee>>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type IlitTrustCrummeyLettersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<IlitCrummeyLetterOrder>>;
  where?: InputMaybe<IlitCrummeyLetterWhereInput>;
};


export type IlitTrustHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type IlitTrustConnection = {
  __typename?: 'ILITTrustConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IlitTrustEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IlitTrustEdge = {
  __typename?: 'ILITTrustEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IlitTrust>;
};

/** Ordering options for ILITTrust connections */
export type IlitTrustOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ILITTrusts. */
  field: IlitTrustOrderField;
};

/** Properties by which ILITTrust connections can be ordered. */
export enum IlitTrustOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * ILITTrustWhereInput is used for filtering ILITTrust objects.
 * Input was generated by ent.
 */
export type IlitTrustWhereInput = {
  and?: InputMaybe<Array<IlitTrustWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** crummey_withdrawal_period_days field predicates */
  crummeyWithdrawalPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysGT?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysGTE?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  crummeyWithdrawalPeriodDaysIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  crummeyWithdrawalPeriodDaysLT?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysLTE?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysNEQ?: InputMaybe<Scalars['Int']['input']>;
  crummeyWithdrawalPeriodDaysNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  crummeyWithdrawalPeriodDaysNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** crummey_letters edge predicates */
  hasCrummeyLetters?: InputMaybe<Scalars['Boolean']['input']>;
  hasCrummeyLettersWith?: InputMaybe<Array<IlitCrummeyLetterWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** grantors edge predicates */
  hasGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantorsWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** successor_trustees edge predicates */
  hasSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuccessorTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** trust_advisors edge predicates */
  hasTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustAdvisorsWith?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** trustees edge predicates */
  hasTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<IlitTrustWhereInput>;
  or?: InputMaybe<Array<IlitTrustWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<TrustTaxStatus>;
  taxStatusIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<TrustTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** trust_jurisdiction_state_code field predicates */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  trustJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type IrsConstants = {
  __typename?: 'IRSConstants';
  annualGiftTaxExclusionAmount: Scalars['CurrencyUSD']['output'];
  availableStateEstateTaxes: Array<StateCode>;
  federalEstateTaxPercent: Scalars['Percent']['output'];
  federalLongTermCapitalGrainsTaxPercent: Scalars['Percent']['output'];
  federalOrdinaryIncomeTaxPercentMaxBracket: Scalars['Percent']['output'];
  gstExemptionAmount: Scalars['CurrencyUSD']['output'];
  lifetimeExemptionAmount: Scalars['CurrencyUSD']['output'];
  localFederalEstateTaxPercentFormatted?: Maybe<Scalars['String']['output']>;
  rate7520: Scalars['Percent']['output'];
};

export type IndividualPersonalAccount = EntitySubtype & Named & Node & PersonalAccount & {
  __typename?: 'IndividualPersonalAccount';
  accounts?: Maybe<Array<Account>>;
  addeparEntityID?: Maybe<Scalars['String']['output']>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  controllingParties?: Maybe<Array<ControllingParty>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this personal account */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in entities that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  owner?: Maybe<Principal>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<NonTrustEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type IndividualPersonalAccountHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type IndividualPersonalAccountConnection = {
  __typename?: 'IndividualPersonalAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IndividualPersonalAccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IndividualPersonalAccountEdge = {
  __typename?: 'IndividualPersonalAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IndividualPersonalAccount>;
};

/** Ordering options for IndividualPersonalAccount connections */
export type IndividualPersonalAccountOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order IndividualPersonalAccounts. */
  field: IndividualPersonalAccountOrderField;
};

/** Properties by which IndividualPersonalAccount connections can be ordered. */
export enum IndividualPersonalAccountOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * IndividualPersonalAccountWhereInput is used for filtering IndividualPersonalAccount objects.
 * Input was generated by ent.
 */
export type IndividualPersonalAccountWhereInput = {
  /** addepar_entity_id field predicates */
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContains?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparEntityIDLT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDLTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNEQ?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<IndividualPersonalAccountWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** controlling_parties edge predicates */
  hasControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  hasControllingPartiesWith?: InputMaybe<Array<ControllingPartyWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** owner edge predicates */
  hasOwner?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnerWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<IndividualPersonalAccountWhereInput>;
  or?: InputMaybe<Array<IndividualPersonalAccountWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type InferDocumentTypeInput = {
  fileName: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type InferDocumentTypeOutput = {
  __typename?: 'InferDocumentTypeOutput';
  documentType: DocumentType;
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type InferredBeneficialOwnerDetails = InferredTrustActor & {
  __typename?: 'InferredBeneficialOwnerDetails';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type InferredBeneficiaryDetails = InferredTrustActor & {
  __typename?: 'InferredBeneficiaryDetails';
  distributionAmount?: Maybe<Scalars['String']['output']>;
  distributionFrequency?: Maybe<BeneficiaryDistributionDistributionFrequency>;
  distributionPercentage?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  order?: Maybe<BeneficiaryLevel>;
  organizationName?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type InferredBusinessEntityDetails = {
  __typename?: 'InferredBusinessEntityDetails';
  beneficialOwnersAsOfDate?: Maybe<Scalars['String']['output']>;
  beneficiaryOwners: Array<InferredBeneficialOwnerDetails>;
  doingBusinessAsName?: Maybe<Scalars['String']['output']>;
  effectiveDate?: Maybe<Scalars['String']['output']>;
  finCenID?: Maybe<Scalars['String']['output']>;
  formationJurisdiction?: Maybe<Scalars['String']['output']>;
  keyPersons: Array<InferredKeyPersonDetails>;
  legalName?: Maybe<Scalars['String']['output']>;
  taxID?: Maybe<Scalars['String']['output']>;
  taxStatus?: Maybe<BusinessEntityTaxStatus>;
};

export type InferredField = Node & {
  __typename?: 'InferredField';
  /** Whether the user has accepted or rejected the inferred field */
  acceptanceStatus?: Maybe<InferredFieldAcceptanceStatus>;
  /** When the user set the acceptance status */
  acceptanceStatusSetAt?: Maybe<Scalars['Time']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** Specific evidence for this field */
  evidence?: Maybe<Array<InferredFieldEvidence>>;
  /** The name of the field (db column) that we inferred for the `target_id` object. If absent, evidence is for the entire object. */
  fieldName?: Maybe<Scalars['String']['output']>;
  /** The file that powered this inference */
  file: File;
  id: Scalars['ID']['output'];
  /** The object we inferred the field for */
  targetID: Scalars['String']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** InferredFieldAcceptanceStatus is enum for the field acceptance_status */
export enum InferredFieldAcceptanceStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED'
}

export type InferredFieldEvidence = Node & {
  __typename?: 'InferredFieldEvidence';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  inferredField?: Maybe<InferredField>;
  /** The page (in the file) that this evidence is for */
  pageNumber: Scalars['Int']['output'];
  /** the polygon that was drawn to infer the field */
  polygon: Array<Coordinate>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** Ordering options for InferredFieldEvidence connections */
export type InferredFieldEvidenceOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InferredFieldEvidences. */
  field: InferredFieldEvidenceOrderField;
};

/** Properties by which InferredFieldEvidence connections can be ordered. */
export enum InferredFieldEvidenceOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * InferredFieldEvidenceWhereInput is used for filtering InferredFieldEvidence objects.
 * Input was generated by ent.
 */
export type InferredFieldEvidenceWhereInput = {
  and?: InputMaybe<Array<InferredFieldEvidenceWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** inferred_field edge predicates */
  hasInferredField?: InputMaybe<Scalars['Boolean']['input']>;
  hasInferredFieldWith?: InputMaybe<Array<InferredFieldWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<InferredFieldEvidenceWhereInput>;
  or?: InputMaybe<Array<InferredFieldEvidenceWhereInput>>;
  /** page_number field predicates */
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageNumberGT?: InputMaybe<Scalars['Int']['input']>;
  pageNumberGTE?: InputMaybe<Scalars['Int']['input']>;
  pageNumberIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  pageNumberLT?: InputMaybe<Scalars['Int']['input']>;
  pageNumberLTE?: InputMaybe<Scalars['Int']['input']>;
  pageNumberNEQ?: InputMaybe<Scalars['Int']['input']>;
  pageNumberNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for InferredField connections */
export type InferredFieldOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InferredFields. */
  field: InferredFieldOrderField;
};

/** Properties by which InferredField connections can be ordered. */
export enum InferredFieldOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * InferredFieldWhereInput is used for filtering InferredField objects.
 * Input was generated by ent.
 */
export type InferredFieldWhereInput = {
  /** acceptance_status field predicates */
  acceptanceStatus?: InputMaybe<InferredFieldAcceptanceStatus>;
  acceptanceStatusIn?: InputMaybe<Array<InferredFieldAcceptanceStatus>>;
  acceptanceStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  acceptanceStatusNEQ?: InputMaybe<InferredFieldAcceptanceStatus>;
  acceptanceStatusNotIn?: InputMaybe<Array<InferredFieldAcceptanceStatus>>;
  acceptanceStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** acceptance_status_set_at field predicates */
  acceptanceStatusSetAt?: InputMaybe<Scalars['Time']['input']>;
  acceptanceStatusSetAtGT?: InputMaybe<Scalars['Time']['input']>;
  acceptanceStatusSetAtGTE?: InputMaybe<Scalars['Time']['input']>;
  acceptanceStatusSetAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  acceptanceStatusSetAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  acceptanceStatusSetAtLT?: InputMaybe<Scalars['Time']['input']>;
  acceptanceStatusSetAtLTE?: InputMaybe<Scalars['Time']['input']>;
  acceptanceStatusSetAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  acceptanceStatusSetAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  acceptanceStatusSetAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<InferredFieldWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** field_name field predicates */
  fieldName?: InputMaybe<Scalars['String']['input']>;
  fieldNameContains?: InputMaybe<Scalars['String']['input']>;
  fieldNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  fieldNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  fieldNameGT?: InputMaybe<Scalars['String']['input']>;
  fieldNameGTE?: InputMaybe<Scalars['String']['input']>;
  fieldNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  fieldNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  fieldNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  fieldNameLT?: InputMaybe<Scalars['String']['input']>;
  fieldNameLTE?: InputMaybe<Scalars['String']['input']>;
  fieldNameNEQ?: InputMaybe<Scalars['String']['input']>;
  fieldNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  fieldNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** evidence edge predicates */
  hasEvidence?: InputMaybe<Scalars['Boolean']['input']>;
  hasEvidenceWith?: InputMaybe<Array<InferredFieldEvidenceWhereInput>>;
  /** file edge predicates */
  hasFile?: InputMaybe<Scalars['Boolean']['input']>;
  hasFileWith?: InputMaybe<Array<FileWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<InferredFieldWhereInput>;
  or?: InputMaybe<Array<InferredFieldWhereInput>>;
  /** target_id field predicates */
  targetID?: InputMaybe<Scalars['String']['input']>;
  targetIDContains?: InputMaybe<Scalars['String']['input']>;
  targetIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  targetIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  targetIDGT?: InputMaybe<Scalars['String']['input']>;
  targetIDGTE?: InputMaybe<Scalars['String']['input']>;
  targetIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  targetIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  targetIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  targetIDLT?: InputMaybe<Scalars['String']['input']>;
  targetIDLTE?: InputMaybe<Scalars['String']['input']>;
  targetIDNEQ?: InputMaybe<Scalars['String']['input']>;
  targetIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type InferredGrantorDetails = InferredTrustActor & {
  __typename?: 'InferredGrantorDetails';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type InferredKeyPersonDetails = InferredTrustActor & {
  __typename?: 'InferredKeyPersonDetails';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  roles: Array<KeyPersonRoleKind>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type InferredTrustActor = {
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type InferredTrustAdvisorDetails = InferredTrustActor & {
  __typename?: 'InferredTrustAdvisorDetails';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  order: TrustAdvisorLevel;
  organizationName?: Maybe<Scalars['String']['output']>;
  roles: Array<TrustAdvisorRoleKind>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type InferredTrustDetails = {
  __typename?: 'InferredTrustDetails';
  beneficiaries: Array<InferredBeneficiaryDetails>;
  grantors: Array<InferredGrantorDetails>;
  trustAdvisors: Array<InferredTrustAdvisorDetails>;
  trustDate: Scalars['String']['output'];
  trustGSTStatus?: Maybe<EntityGstStatus>;
  trustJurisdiction: Scalars['String']['output'];
  trustName: Scalars['String']['output'];
  trustees: Array<InferredTrusteeDetails>;
};

export type InferredTrusteeDetails = InferredTrustActor & {
  __typename?: 'InferredTrusteeDetails';
  category: TrusteeTrusteeCategory;
  duties: Array<TrusteeDutyKind>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  order?: Maybe<TrusteeLevel>;
  organizationName?: Maybe<Scalars['String']['output']>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export enum InfoMessageDisplayType {
  Standard = 'standard',
  Warning = 'warning'
}

export type InsurancePersonalAccount = EntitySubtype & Named & Node & PersonalAccount & {
  __typename?: 'InsurancePersonalAccount';
  accounts?: Maybe<Array<Account>>;
  addeparEntityID?: Maybe<Scalars['String']['output']>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  controllingParties?: Maybe<Array<ControllingParty>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this personal account */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in entities that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  owners?: Maybe<Array<Principal>>;
  policies?: Maybe<Array<InsurancePolicy>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<NonTrustEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  totalPolicyCashValue: Scalars['CurrencyUSD']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type InsurancePersonalAccountHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type InsurancePersonalAccountConnection = {
  __typename?: 'InsurancePersonalAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InsurancePersonalAccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InsurancePersonalAccountEdge = {
  __typename?: 'InsurancePersonalAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InsurancePersonalAccount>;
};

/** Ordering options for InsurancePersonalAccount connections */
export type InsurancePersonalAccountOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InsurancePersonalAccounts. */
  field: InsurancePersonalAccountOrderField;
};

/** Properties by which InsurancePersonalAccount connections can be ordered. */
export enum InsurancePersonalAccountOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * InsurancePersonalAccountWhereInput is used for filtering InsurancePersonalAccount objects.
 * Input was generated by ent.
 */
export type InsurancePersonalAccountWhereInput = {
  /** addepar_entity_id field predicates */
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContains?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparEntityIDLT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDLTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNEQ?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<InsurancePersonalAccountWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** controlling_parties edge predicates */
  hasControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  hasControllingPartiesWith?: InputMaybe<Array<ControllingPartyWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** owners edge predicates */
  hasOwners?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnersWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<InsurancePersonalAccountWhereInput>;
  or?: InputMaybe<Array<InsurancePersonalAccountWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type InsurancePolicy = Node & {
  __typename?: 'InsurancePolicy';
  carrierName?: Maybe<Scalars['String']['output']>;
  /** Only applicable for non-term policies */
  cashValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  cashValueDate?: Maybe<Scalars['Time']['output']>;
  /** Only applicable for term policies */
  conversionDate?: Maybe<Scalars['Time']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  deathBenefitAmount: Scalars['CurrencyUSD']['output'];
  /** documents that are associated with this policy */
  documents?: Maybe<Array<Document>>;
  id: Scalars['ID']['output'];
  ilitTrust?: Maybe<IlitTrust>;
  initialPremiumDueDate?: Maybe<Scalars['Time']['output']>;
  insurancePersonalAccount?: Maybe<InsurancePersonalAccount>;
  kind: InsurancePolicyKind;
  /** Only applicable for non-term policies */
  loanBalanceOutstanding?: Maybe<Scalars['CurrencyUSD']['output']>;
  nextPremiumDueDate?: Maybe<Scalars['Time']['output']>;
  /** Free-form notes */
  notes?: Maybe<Scalars['String']['output']>;
  policyHolders?: Maybe<Array<ClientProfile>>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  premiumAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  premiumFrequency?: Maybe<InsurancePolicyPremiumFrequency>;
  premiumPayments: InsurancePremiumPaymentConnection;
  startDate: Scalars['Time']['output'];
  /** Only applicable for non-term policies. Allows multiple holders. */
  survivorship: Scalars['Boolean']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** Only applicable for term policies */
  termDurationYears?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type InsurancePolicyPremiumPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InsurancePremiumPaymentOrder>>;
  where?: InputMaybe<InsurancePremiumPaymentWhereInput>;
};

/** InsurancePolicyKind is enum for the field kind */
export enum InsurancePolicyKind {
  Term = 'TERM',
  Universal = 'UNIVERSAL',
  Variable = 'VARIABLE',
  Whole = 'WHOLE'
}

/** Ordering options for InsurancePolicy connections */
export type InsurancePolicyOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InsurancePolicies. */
  field: InsurancePolicyOrderField;
};

/** Properties by which InsurancePolicy connections can be ordered. */
export enum InsurancePolicyOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** InsurancePolicyPremiumFrequency is enum for the field premium_frequency */
export enum InsurancePolicyPremiumFrequency {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  Semiannually = 'SEMIANNUALLY'
}

/**
 * InsurancePolicyWhereInput is used for filtering InsurancePolicy objects.
 * Input was generated by ent.
 */
export type InsurancePolicyWhereInput = {
  and?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** carrier_name field predicates */
  carrierName?: InputMaybe<Scalars['String']['input']>;
  carrierNameContains?: InputMaybe<Scalars['String']['input']>;
  carrierNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  carrierNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  carrierNameGT?: InputMaybe<Scalars['String']['input']>;
  carrierNameGTE?: InputMaybe<Scalars['String']['input']>;
  carrierNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  carrierNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  carrierNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  carrierNameLT?: InputMaybe<Scalars['String']['input']>;
  carrierNameLTE?: InputMaybe<Scalars['String']['input']>;
  carrierNameNEQ?: InputMaybe<Scalars['String']['input']>;
  carrierNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  carrierNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** cash_value field predicates */
  cashValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** cash_value_date field predicates */
  cashValueDate?: InputMaybe<Scalars['Time']['input']>;
  cashValueDateGT?: InputMaybe<Scalars['Time']['input']>;
  cashValueDateGTE?: InputMaybe<Scalars['Time']['input']>;
  cashValueDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  cashValueDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  cashValueDateLT?: InputMaybe<Scalars['Time']['input']>;
  cashValueDateLTE?: InputMaybe<Scalars['Time']['input']>;
  cashValueDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  cashValueDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  cashValueDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  cashValueGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  cashValueGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  cashValueIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  cashValueIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  cashValueLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  cashValueLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  cashValueNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  cashValueNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  cashValueNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** conversion_date field predicates */
  conversionDate?: InputMaybe<Scalars['Time']['input']>;
  conversionDateGT?: InputMaybe<Scalars['Time']['input']>;
  conversionDateGTE?: InputMaybe<Scalars['Time']['input']>;
  conversionDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  conversionDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  conversionDateLT?: InputMaybe<Scalars['Time']['input']>;
  conversionDateLTE?: InputMaybe<Scalars['Time']['input']>;
  conversionDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  conversionDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  conversionDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** death_benefit_amount field predicates */
  deathBenefitAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  deathBenefitAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  deathBenefitAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  deathBenefitAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  deathBenefitAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  deathBenefitAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  deathBenefitAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  deathBenefitAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  /** documents edge predicates */
  hasDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentsWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** ilit_trust edge predicates */
  hasIlitTrust?: InputMaybe<Scalars['Boolean']['input']>;
  hasIlitTrustWith?: InputMaybe<Array<IlitTrustWhereInput>>;
  /** insurance_personal_account edge predicates */
  hasInsurancePersonalAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasInsurancePersonalAccountWith?: InputMaybe<Array<InsurancePersonalAccountWhereInput>>;
  /** policy_holders edge predicates */
  hasPolicyHolders?: InputMaybe<Scalars['Boolean']['input']>;
  hasPolicyHoldersWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** premium_payments edge predicates */
  hasPremiumPayments?: InputMaybe<Scalars['Boolean']['input']>;
  hasPremiumPaymentsWith?: InputMaybe<Array<InsurancePremiumPaymentWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** initial_premium_due_date field predicates */
  initialPremiumDueDate?: InputMaybe<Scalars['Time']['input']>;
  initialPremiumDueDateGT?: InputMaybe<Scalars['Time']['input']>;
  initialPremiumDueDateGTE?: InputMaybe<Scalars['Time']['input']>;
  initialPremiumDueDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  initialPremiumDueDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  initialPremiumDueDateLT?: InputMaybe<Scalars['Time']['input']>;
  initialPremiumDueDateLTE?: InputMaybe<Scalars['Time']['input']>;
  initialPremiumDueDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  initialPremiumDueDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  initialPremiumDueDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** kind field predicates */
  kind?: InputMaybe<InsurancePolicyKind>;
  kindIn?: InputMaybe<Array<InsurancePolicyKind>>;
  kindNEQ?: InputMaybe<InsurancePolicyKind>;
  kindNotIn?: InputMaybe<Array<InsurancePolicyKind>>;
  /** loan_balance_outstanding field predicates */
  loanBalanceOutstanding?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  loanBalanceOutstandingGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  loanBalanceOutstandingGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  loanBalanceOutstandingIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  loanBalanceOutstandingIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  loanBalanceOutstandingLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  loanBalanceOutstandingLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  loanBalanceOutstandingNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  loanBalanceOutstandingNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  loanBalanceOutstandingNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<InsurancePolicyWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** policy_number field predicates */
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  policyNumberContains?: InputMaybe<Scalars['String']['input']>;
  policyNumberContainsFold?: InputMaybe<Scalars['String']['input']>;
  policyNumberEqualFold?: InputMaybe<Scalars['String']['input']>;
  policyNumberGT?: InputMaybe<Scalars['String']['input']>;
  policyNumberGTE?: InputMaybe<Scalars['String']['input']>;
  policyNumberHasPrefix?: InputMaybe<Scalars['String']['input']>;
  policyNumberHasSuffix?: InputMaybe<Scalars['String']['input']>;
  policyNumberIn?: InputMaybe<Array<Scalars['String']['input']>>;
  policyNumberIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  policyNumberLT?: InputMaybe<Scalars['String']['input']>;
  policyNumberLTE?: InputMaybe<Scalars['String']['input']>;
  policyNumberNEQ?: InputMaybe<Scalars['String']['input']>;
  policyNumberNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  policyNumberNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** premium_amount field predicates */
  premiumAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  premiumAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  premiumAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  premiumAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** premium_frequency field predicates */
  premiumFrequency?: InputMaybe<InsurancePolicyPremiumFrequency>;
  premiumFrequencyIn?: InputMaybe<Array<InsurancePolicyPremiumFrequency>>;
  premiumFrequencyIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  premiumFrequencyNEQ?: InputMaybe<InsurancePolicyPremiumFrequency>;
  premiumFrequencyNotIn?: InputMaybe<Array<InsurancePolicyPremiumFrequency>>;
  premiumFrequencyNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** start_date field predicates */
  startDate?: InputMaybe<Scalars['Time']['input']>;
  startDateGT?: InputMaybe<Scalars['Time']['input']>;
  startDateGTE?: InputMaybe<Scalars['Time']['input']>;
  startDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  startDateLT?: InputMaybe<Scalars['Time']['input']>;
  startDateLTE?: InputMaybe<Scalars['Time']['input']>;
  startDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  startDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** survivorship field predicates */
  survivorship?: InputMaybe<Scalars['Boolean']['input']>;
  survivorshipNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** term_duration_years field predicates */
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termDurationYearsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  termDurationYearsLT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsLTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNEQ?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termDurationYearsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type InsurancePremiumPayment = Node & {
  __typename?: 'InsurancePremiumPayment';
  associatedTask?: Maybe<EntityTask>;
  createdAt: Scalars['Time']['output'];
  /** Documents that are associated with the premium payment */
  documents?: Maybe<Array<Document>>;
  dueOn?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  paidOn?: Maybe<Scalars['Time']['output']>;
  policy?: Maybe<InsurancePolicy>;
  premiumAmount: Scalars['CurrencyUSD']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type InsurancePremiumPaymentConnection = {
  __typename?: 'InsurancePremiumPaymentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InsurancePremiumPaymentEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type InsurancePremiumPaymentEdge = {
  __typename?: 'InsurancePremiumPaymentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<InsurancePremiumPayment>;
};

/** Ordering options for InsurancePremiumPayment connections */
export type InsurancePremiumPaymentOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order InsurancePremiumPayments. */
  field: InsurancePremiumPaymentOrderField;
};

/** Properties by which InsurancePremiumPayment connections can be ordered. */
export enum InsurancePremiumPaymentOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * InsurancePremiumPaymentWhereInput is used for filtering InsurancePremiumPayment objects.
 * Input was generated by ent.
 */
export type InsurancePremiumPaymentWhereInput = {
  and?: InputMaybe<Array<InsurancePremiumPaymentWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** due_on field predicates */
  dueOn?: InputMaybe<Scalars['Time']['input']>;
  dueOnGT?: InputMaybe<Scalars['Time']['input']>;
  dueOnGTE?: InputMaybe<Scalars['Time']['input']>;
  dueOnIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  dueOnIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dueOnLT?: InputMaybe<Scalars['Time']['input']>;
  dueOnLTE?: InputMaybe<Scalars['Time']['input']>;
  dueOnNEQ?: InputMaybe<Scalars['Time']['input']>;
  dueOnNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  dueOnNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** associated_task edge predicates */
  hasAssociatedTask?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssociatedTaskWith?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** documents edge predicates */
  hasDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentsWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** policy edge predicates */
  hasPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  hasPolicyWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<InsurancePremiumPaymentWhereInput>;
  or?: InputMaybe<Array<InsurancePremiumPaymentWhereInput>>;
  /** paid_on field predicates */
  paidOn?: InputMaybe<Scalars['Time']['input']>;
  paidOnGT?: InputMaybe<Scalars['Time']['input']>;
  paidOnGTE?: InputMaybe<Scalars['Time']['input']>;
  paidOnIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  paidOnIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  paidOnLT?: InputMaybe<Scalars['Time']['input']>;
  paidOnLTE?: InputMaybe<Scalars['Time']['input']>;
  paidOnNEQ?: InputMaybe<Scalars['Time']['input']>;
  paidOnNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  paidOnNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** premium_amount field predicates */
  premiumAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  premiumAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type IntegrationAssetClass = Node & {
  __typename?: 'IntegrationAssetClass';
  assetClass?: Maybe<AssetClass>;
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** We use this to map this integration_asset_class to the provider's asset class name. This is imperfect, because if someone updates a name then we will make a new asset class and reconnect it. */
  integrationName: Scalars['String']['output'];
  provider: IntegrationAssetClassProvider;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type IntegrationAssetClassConnection = {
  __typename?: 'IntegrationAssetClassConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IntegrationAssetClassEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IntegrationAssetClassEdge = {
  __typename?: 'IntegrationAssetClassEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IntegrationAssetClass>;
};

/** Ordering options for IntegrationAssetClass connections */
export type IntegrationAssetClassOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order IntegrationAssetClasses. */
  field: IntegrationAssetClassOrderField;
};

/** Properties by which IntegrationAssetClass connections can be ordered. */
export enum IntegrationAssetClassOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** IntegrationAssetClassProvider is enum for the field provider */
export enum IntegrationAssetClassProvider {
  Addepar = 'ADDEPAR',
  BlackDiamond = 'BLACK_DIAMOND'
}

/**
 * IntegrationAssetClassWhereInput is used for filtering IntegrationAssetClass objects.
 * Input was generated by ent.
 */
export type IntegrationAssetClassWhereInput = {
  and?: InputMaybe<Array<IntegrationAssetClassWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** asset_class edge predicates */
  hasAssetClass?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssetClassWith?: InputMaybe<Array<AssetClassWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** integration_name field predicates */
  integrationName?: InputMaybe<Scalars['String']['input']>;
  integrationNameContains?: InputMaybe<Scalars['String']['input']>;
  integrationNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  integrationNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  integrationNameGT?: InputMaybe<Scalars['String']['input']>;
  integrationNameGTE?: InputMaybe<Scalars['String']['input']>;
  integrationNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  integrationNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  integrationNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  integrationNameLT?: InputMaybe<Scalars['String']['input']>;
  integrationNameLTE?: InputMaybe<Scalars['String']['input']>;
  integrationNameNEQ?: InputMaybe<Scalars['String']['input']>;
  integrationNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<IntegrationAssetClassWhereInput>;
  or?: InputMaybe<Array<IntegrationAssetClassWhereInput>>;
  /** provider field predicates */
  provider?: InputMaybe<IntegrationAssetClassProvider>;
  providerIn?: InputMaybe<Array<IntegrationAssetClassProvider>>;
  providerNEQ?: InputMaybe<IntegrationAssetClassProvider>;
  providerNotIn?: InputMaybe<Array<IntegrationAssetClassProvider>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type IntegrationClient = Node & {
  __typename?: 'IntegrationClient';
  allowedUsers?: Maybe<Array<User>>;
  /** Luminary client profiles that are linked with this Addepar client. */
  clientProfiles?: Maybe<Array<ClientProfile>>;
  createdAt: Scalars['Time']['output'];
  externalKind?: Maybe<IntegrationClientExternalKind>;
  /** Luminary households that are linked with this integration client. */
  households?: Maybe<Array<Household>>;
  id: Scalars['ID']['output'];
  integrationEntities: IntegrationEntityConnection;
  integrationID: Scalars['String']['output'];
  kind: IntegrationClientKind;
  name: Scalars['String']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};


export type IntegrationClientIntegrationEntitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<IntegrationEntityOrder>>;
  where?: InputMaybe<IntegrationEntityWhereInput>;
};

/** A connection to a list of items. */
export type IntegrationClientConnection = {
  __typename?: 'IntegrationClientConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IntegrationClientEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IntegrationClientEdge = {
  __typename?: 'IntegrationClientEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IntegrationClient>;
};

/** IntegrationClientExternalKind is enum for the field external_kind */
export enum IntegrationClientExternalKind {
  AddeparClient = 'ADDEPAR_CLIENT',
  AddeparGroup = 'ADDEPAR_GROUP',
  AddeparHousehold = 'ADDEPAR_HOUSEHOLD',
  BlackDiamondRelationship = 'BLACK_DIAMOND_RELATIONSHIP'
}

/** IntegrationClientKind is enum for the field kind */
export enum IntegrationClientKind {
  Addepar = 'ADDEPAR',
  BlackDiamond = 'BLACK_DIAMOND'
}

/** Ordering options for IntegrationClient connections */
export type IntegrationClientOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order IntegrationClients. */
  field: IntegrationClientOrderField;
};

/** Properties by which IntegrationClient connections can be ordered. */
export enum IntegrationClientOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * IntegrationClientWhereInput is used for filtering IntegrationClient objects.
 * Input was generated by ent.
 */
export type IntegrationClientWhereInput = {
  and?: InputMaybe<Array<IntegrationClientWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** external_kind field predicates */
  externalKind?: InputMaybe<IntegrationClientExternalKind>;
  externalKindIn?: InputMaybe<Array<IntegrationClientExternalKind>>;
  externalKindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalKindNEQ?: InputMaybe<IntegrationClientExternalKind>;
  externalKindNotIn?: InputMaybe<Array<IntegrationClientExternalKind>>;
  externalKindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** allowed_users edge predicates */
  hasAllowedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  hasAllowedUsersWith?: InputMaybe<Array<UserWhereInput>>;
  /** client_profiles edge predicates */
  hasClientProfiles?: InputMaybe<Scalars['Boolean']['input']>;
  hasClientProfilesWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** households edge predicates */
  hasHouseholds?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdsWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** integration_entities edge predicates */
  hasIntegrationEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasIntegrationEntitiesWith?: InputMaybe<Array<IntegrationEntityWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** integration_id field predicates */
  integrationID?: InputMaybe<Scalars['String']['input']>;
  integrationIDContains?: InputMaybe<Scalars['String']['input']>;
  integrationIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  integrationIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  integrationIDGT?: InputMaybe<Scalars['String']['input']>;
  integrationIDGTE?: InputMaybe<Scalars['String']['input']>;
  integrationIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  integrationIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  integrationIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  integrationIDLT?: InputMaybe<Scalars['String']['input']>;
  integrationIDLTE?: InputMaybe<Scalars['String']['input']>;
  integrationIDNEQ?: InputMaybe<Scalars['String']['input']>;
  integrationIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<IntegrationClientKind>;
  kindIn?: InputMaybe<Array<IntegrationClientKind>>;
  kindNEQ?: InputMaybe<IntegrationClientKind>;
  kindNotIn?: InputMaybe<Array<IntegrationClientKind>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<IntegrationClientWhereInput>;
  or?: InputMaybe<Array<IntegrationClientWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type IntegrationConfiguration = Node & {
  __typename?: 'IntegrationConfiguration';
  /** The client ID for the Addepar OAuth app. */
  addeparClientID?: Maybe<Scalars['String']['output']>;
  addeparClientType: IntegrationConfigurationAddeparClientType;
  /** ID for Addepar account. */
  addeparFirmID?: Maybe<Scalars['String']['output']>;
  /** The subdomain of the Addepar instance to connect to. */
  addeparSubdomain?: Maybe<Scalars['String']['output']>;
  /** Whether the tenant expects to use Black Diamond. We use this to expose prompts to users to authenticate to Black Diamond. */
  blackDiamondEnabled: Scalars['Boolean']['output'];
  createdAt: Scalars['Time']['output'];
  /** The user-defined name for the source of the file valuation import. Exposed alongside the valuation to show where it came from. */
  csvValuationImportName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** IntegrationConfigurationAddeparClientType is enum for the field addepar_client_type */
export enum IntegrationConfigurationAddeparClientType {
  All = 'ALL',
  Client = 'CLIENT',
  Group = 'GROUP',
  Household = 'HOUSEHOLD'
}

/** A connection to a list of items. */
export type IntegrationConfigurationConnection = {
  __typename?: 'IntegrationConfigurationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IntegrationConfigurationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IntegrationConfigurationEdge = {
  __typename?: 'IntegrationConfigurationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IntegrationConfiguration>;
};

/** Ordering options for IntegrationConfiguration connections */
export type IntegrationConfigurationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order IntegrationConfigurations. */
  field: IntegrationConfigurationOrderField;
};

/** Properties by which IntegrationConfiguration connections can be ordered. */
export enum IntegrationConfigurationOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * IntegrationConfigurationWhereInput is used for filtering IntegrationConfiguration objects.
 * Input was generated by ent.
 */
export type IntegrationConfigurationWhereInput = {
  /** addepar_client_id field predicates */
  addeparClientID?: InputMaybe<Scalars['String']['input']>;
  addeparClientIDContains?: InputMaybe<Scalars['String']['input']>;
  addeparClientIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  addeparClientIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  addeparClientIDGT?: InputMaybe<Scalars['String']['input']>;
  addeparClientIDGTE?: InputMaybe<Scalars['String']['input']>;
  addeparClientIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  addeparClientIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  addeparClientIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparClientIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparClientIDLT?: InputMaybe<Scalars['String']['input']>;
  addeparClientIDLTE?: InputMaybe<Scalars['String']['input']>;
  addeparClientIDNEQ?: InputMaybe<Scalars['String']['input']>;
  addeparClientIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparClientIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** addepar_client_type field predicates */
  addeparClientType?: InputMaybe<IntegrationConfigurationAddeparClientType>;
  addeparClientTypeIn?: InputMaybe<Array<IntegrationConfigurationAddeparClientType>>;
  addeparClientTypeNEQ?: InputMaybe<IntegrationConfigurationAddeparClientType>;
  addeparClientTypeNotIn?: InputMaybe<Array<IntegrationConfigurationAddeparClientType>>;
  /** addepar_firm_id field predicates */
  addeparFirmID?: InputMaybe<Scalars['String']['input']>;
  addeparFirmIDContains?: InputMaybe<Scalars['String']['input']>;
  addeparFirmIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  addeparFirmIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  addeparFirmIDGT?: InputMaybe<Scalars['String']['input']>;
  addeparFirmIDGTE?: InputMaybe<Scalars['String']['input']>;
  addeparFirmIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  addeparFirmIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  addeparFirmIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparFirmIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparFirmIDLT?: InputMaybe<Scalars['String']['input']>;
  addeparFirmIDLTE?: InputMaybe<Scalars['String']['input']>;
  addeparFirmIDNEQ?: InputMaybe<Scalars['String']['input']>;
  addeparFirmIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparFirmIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** addepar_subdomain field predicates */
  addeparSubdomain?: InputMaybe<Scalars['String']['input']>;
  addeparSubdomainContains?: InputMaybe<Scalars['String']['input']>;
  addeparSubdomainContainsFold?: InputMaybe<Scalars['String']['input']>;
  addeparSubdomainEqualFold?: InputMaybe<Scalars['String']['input']>;
  addeparSubdomainGT?: InputMaybe<Scalars['String']['input']>;
  addeparSubdomainGTE?: InputMaybe<Scalars['String']['input']>;
  addeparSubdomainHasPrefix?: InputMaybe<Scalars['String']['input']>;
  addeparSubdomainHasSuffix?: InputMaybe<Scalars['String']['input']>;
  addeparSubdomainIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparSubdomainIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparSubdomainLT?: InputMaybe<Scalars['String']['input']>;
  addeparSubdomainLTE?: InputMaybe<Scalars['String']['input']>;
  addeparSubdomainNEQ?: InputMaybe<Scalars['String']['input']>;
  addeparSubdomainNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparSubdomainNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<IntegrationConfigurationWhereInput>>;
  /** black_diamond_enabled field predicates */
  blackDiamondEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  blackDiamondEnabledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** csv_valuation_import_name field predicates */
  csvValuationImportName?: InputMaybe<Scalars['String']['input']>;
  csvValuationImportNameContains?: InputMaybe<Scalars['String']['input']>;
  csvValuationImportNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  csvValuationImportNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  csvValuationImportNameGT?: InputMaybe<Scalars['String']['input']>;
  csvValuationImportNameGTE?: InputMaybe<Scalars['String']['input']>;
  csvValuationImportNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  csvValuationImportNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  csvValuationImportNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  csvValuationImportNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  csvValuationImportNameLT?: InputMaybe<Scalars['String']['input']>;
  csvValuationImportNameLTE?: InputMaybe<Scalars['String']['input']>;
  csvValuationImportNameNEQ?: InputMaybe<Scalars['String']['input']>;
  csvValuationImportNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  csvValuationImportNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<IntegrationConfigurationWhereInput>;
  or?: InputMaybe<Array<IntegrationConfigurationWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type IntegrationEntity = Node & {
  __typename?: 'IntegrationEntity';
  allowedUsers?: Maybe<Array<User>>;
  /** Child entities that are linked to this integration entity in an integration import. */
  childEntities?: Maybe<Array<IntegrationEntity>>;
  createdAt: Scalars['Time']['output'];
  /** Luminary entities that are linked with integration entity. */
  entities?: Maybe<Array<Entity>>;
  externalKind?: Maybe<IntegrationEntityExternalKind>;
  id: Scalars['ID']['output'];
  /** Integration client associated with this entity */
  integrationClient?: Maybe<IntegrationClient>;
  integrationID: Scalars['String']['output'];
  kind: IntegrationEntityKind;
  name: Scalars['String']['output'];
  /** Parent entity that is linked with integration entity in an integration import. */
  parentEntity?: Maybe<IntegrationEntity>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type IntegrationEntityConnection = {
  __typename?: 'IntegrationEntityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IntegrationEntityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IntegrationEntityEdge = {
  __typename?: 'IntegrationEntityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IntegrationEntity>;
};

/** IntegrationEntityExternalKind is enum for the field external_kind */
export enum IntegrationEntityExternalKind {
  AddeparHoldingAccount = 'ADDEPAR_HOLDING_ACCOUNT',
  AddeparLegalEntity = 'ADDEPAR_LEGAL_ENTITY',
  BlackDiamondAccount = 'BLACK_DIAMOND_ACCOUNT',
  BlackDiamondPortfolio = 'BLACK_DIAMOND_PORTFOLIO'
}

/** IntegrationEntityKind is enum for the field kind */
export enum IntegrationEntityKind {
  Addepar = 'ADDEPAR',
  BlackDiamondAccount = 'BLACK_DIAMOND_ACCOUNT',
  BlackDiamondPortfolio = 'BLACK_DIAMOND_PORTFOLIO',
  CsvImport = 'CSV_IMPORT'
}

/** Ordering options for IntegrationEntity connections */
export type IntegrationEntityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order IntegrationEntities. */
  field: IntegrationEntityOrderField;
};

/** Properties by which IntegrationEntity connections can be ordered. */
export enum IntegrationEntityOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * IntegrationEntityWhereInput is used for filtering IntegrationEntity objects.
 * Input was generated by ent.
 */
export type IntegrationEntityWhereInput = {
  and?: InputMaybe<Array<IntegrationEntityWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** external_kind field predicates */
  externalKind?: InputMaybe<IntegrationEntityExternalKind>;
  externalKindIn?: InputMaybe<Array<IntegrationEntityExternalKind>>;
  externalKindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  externalKindNEQ?: InputMaybe<IntegrationEntityExternalKind>;
  externalKindNotIn?: InputMaybe<Array<IntegrationEntityExternalKind>>;
  externalKindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** allowed_users edge predicates */
  hasAllowedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  hasAllowedUsersWith?: InputMaybe<Array<UserWhereInput>>;
  /** child_entities edge predicates */
  hasChildEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasChildEntitiesWith?: InputMaybe<Array<IntegrationEntityWhereInput>>;
  /** entities edge predicates */
  hasEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntitiesWith?: InputMaybe<Array<EntityWhereInput>>;
  /** integration_client edge predicates */
  hasIntegrationClient?: InputMaybe<Scalars['Boolean']['input']>;
  hasIntegrationClientWith?: InputMaybe<Array<IntegrationClientWhereInput>>;
  /** parent_entity edge predicates */
  hasParentEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasParentEntityWith?: InputMaybe<Array<IntegrationEntityWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** integration_id field predicates */
  integrationID?: InputMaybe<Scalars['String']['input']>;
  integrationIDContains?: InputMaybe<Scalars['String']['input']>;
  integrationIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  integrationIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  integrationIDGT?: InputMaybe<Scalars['String']['input']>;
  integrationIDGTE?: InputMaybe<Scalars['String']['input']>;
  integrationIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  integrationIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  integrationIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  integrationIDLT?: InputMaybe<Scalars['String']['input']>;
  integrationIDLTE?: InputMaybe<Scalars['String']['input']>;
  integrationIDNEQ?: InputMaybe<Scalars['String']['input']>;
  integrationIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<IntegrationEntityKind>;
  kindIn?: InputMaybe<Array<IntegrationEntityKind>>;
  kindNEQ?: InputMaybe<IntegrationEntityKind>;
  kindNotIn?: InputMaybe<Array<IntegrationEntityKind>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<IntegrationEntityWhereInput>;
  or?: InputMaybe<Array<IntegrationEntityWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type InterestRateDetails = {
  __typename?: 'InterestRateDetails';
  interestRate: Scalars['Percent']['output'];
  monthYearDisplay: Scalars['String']['output'];
};

export type InterestRateSummary = {
  __typename?: 'InterestRateSummary';
  currentMonth: InterestRateDetails;
  nextMonth?: Maybe<InterestRateDetails>;
};

export type IrrevocableTrust = EntitySubtype & Named & Node & Trust & {
  __typename?: 'IrrevocableTrust';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this trust */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  grantors?: Maybe<Array<Principal>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  performanceReport: TrustPerformanceReport;
  policies?: Maybe<Array<InsurancePolicy>>;
  successorTrustees?: Maybe<Array<Trustee>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<TrustTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  trustAdvisors?: Maybe<Array<TrustAdvisor>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  trustees?: Maybe<Array<Trustee>>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type IrrevocableTrustHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type IrrevocableTrustConnection = {
  __typename?: 'IrrevocableTrustConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IrrevocableTrustEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type IrrevocableTrustEdge = {
  __typename?: 'IrrevocableTrustEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<IrrevocableTrust>;
};

/** Ordering options for IrrevocableTrust connections */
export type IrrevocableTrustOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order IrrevocableTrusts. */
  field: IrrevocableTrustOrderField;
};

/** Properties by which IrrevocableTrust connections can be ordered. */
export enum IrrevocableTrustOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * IrrevocableTrustWhereInput is used for filtering IrrevocableTrust objects.
 * Input was generated by ent.
 */
export type IrrevocableTrustWhereInput = {
  and?: InputMaybe<Array<IrrevocableTrustWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** grantors edge predicates */
  hasGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantorsWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** successor_trustees edge predicates */
  hasSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuccessorTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** trust_advisors edge predicates */
  hasTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustAdvisorsWith?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** trustees edge predicates */
  hasTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<IrrevocableTrustWhereInput>;
  or?: InputMaybe<Array<IrrevocableTrustWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<TrustTaxStatus>;
  taxStatusIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<TrustTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** trust_jurisdiction_state_code field predicates */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  trustJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type IsLoggedIn = {
  __typename?: 'IsLoggedIn';
  isLoggedIn: Scalars['Boolean']['output'];
  isTokenValid: Scalars['Boolean']['output'];
};

export type IsLoggedInToAddepar = {
  __typename?: 'IsLoggedInToAddepar';
  isLoggedIn: Scalars['Boolean']['output'];
  isTokenValid: Scalars['Boolean']['output'];
};

export type JointPersonalAccount = EntitySubtype & Named & Node & PersonalAccount & {
  __typename?: 'JointPersonalAccount';
  accounts?: Maybe<Array<Account>>;
  addeparEntityID?: Maybe<Scalars['String']['output']>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  controllingParties?: Maybe<Array<ControllingParty>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this personal account */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in entities that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  owners?: Maybe<Array<Principal>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<NonTrustEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  type?: Maybe<JointPersonalAccountType>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type JointPersonalAccountHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type JointPersonalAccountConnection = {
  __typename?: 'JointPersonalAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<JointPersonalAccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type JointPersonalAccountEdge = {
  __typename?: 'JointPersonalAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<JointPersonalAccount>;
};

/** Ordering options for JointPersonalAccount connections */
export type JointPersonalAccountOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order JointPersonalAccounts. */
  field: JointPersonalAccountOrderField;
};

/** Properties by which JointPersonalAccount connections can be ordered. */
export enum JointPersonalAccountOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** JointPersonalAccountType is enum for the field type */
export enum JointPersonalAccountType {
  CommunityProperty = 'COMMUNITY_PROPERTY',
  CommunityPropertyWithRightOfSurvivorship = 'COMMUNITY_PROPERTY_WITH_RIGHT_OF_SURVIVORSHIP',
  JointTenants = 'JOINT_TENANTS',
  JointTenantsWithRightOfSurvivorship = 'JOINT_TENANTS_WITH_RIGHT_OF_SURVIVORSHIP',
  TenantsByEntirety = 'TENANTS_BY_ENTIRETY',
  TenantsInCommon = 'TENANTS_IN_COMMON'
}

/**
 * JointPersonalAccountWhereInput is used for filtering JointPersonalAccount objects.
 * Input was generated by ent.
 */
export type JointPersonalAccountWhereInput = {
  /** addepar_entity_id field predicates */
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContains?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparEntityIDLT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDLTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNEQ?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<JointPersonalAccountWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** controlling_parties edge predicates */
  hasControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  hasControllingPartiesWith?: InputMaybe<Array<ControllingPartyWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** owners edge predicates */
  hasOwners?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnersWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<JointPersonalAccountWhereInput>;
  or?: InputMaybe<Array<JointPersonalAccountWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** type field predicates */
  type?: InputMaybe<JointPersonalAccountType>;
  typeIn?: InputMaybe<Array<JointPersonalAccountType>>;
  typeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  typeNEQ?: InputMaybe<JointPersonalAccountType>;
  typeNotIn?: InputMaybe<Array<JointPersonalAccountType>>;
  typeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type KgNode = Node & {
  __typename?: 'KGNode';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The LLM description of the node. */
  description?: Maybe<Scalars['String']['output']>;
  /** The main document to use when searching for evidence for this node. */
  evidenceDocument?: Maybe<Document>;
  /** The file that this KGNode belongs to. A node can only belong to at most one file. Nodes that were created as a result of being merged into a KGRoot will not have this field set, since it could have relationships that were extracted from multiple files. */
  file?: Maybe<File>;
  /** The file_id that this KGNode belongs to. A node can only belong to at most one file. Nodes that were created as a result of being merged into a KGRoot will not have this field set, since it could have relationships that were extracted from multiple files. */
  fileID?: Maybe<Scalars['ID']['output']>;
  household: Household;
  /** the household this object belongs to */
  householdID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** The relationships where this node is the target. The KGNode at the other end of the relationship is the source. */
  inboundRelationships?: Maybe<Array<KgRelationship>>;
  /** The KGRoot that this KGNode belongs to. This will only be set if the node was created as a result of being merged into a KGRoot. In this case, the file edge will not be set. */
  kgRoot?: Maybe<KgRoot>;
  /** The name of the node. This uniquely identifies the node within the knowledge graph root. */
  name: Scalars['String']['output'];
  /** The relationships where this node is the source. The KGNode at the other end of the relationship is the target. */
  outboundRelationships?: Maybe<Array<KgRelationship>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** The type of the node. */
  type: KgNodeType;
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type KgNodeConnection = {
  __typename?: 'KGNodeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<KgNodeEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type KgNodeEdge = {
  __typename?: 'KGNodeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<KgNode>;
};

/** Ordering options for KGNode connections */
export type KgNodeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order KGNodes. */
  field: KgNodeOrderField;
};

/** Properties by which KGNode connections can be ordered. */
export enum KgNodeOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** KGNodeType is enum for the field type */
export enum KgNodeType {
  AccountEntity = 'ACCOUNT_ENTITY',
  BusinessEntity = 'BUSINESS_ENTITY',
  CharitableEntity = 'CHARITABLE_ENTITY',
  CharitableOrganization = 'CHARITABLE_ORGANIZATION',
  CorporateOrganization = 'CORPORATE_ORGANIZATION',
  Person = 'PERSON',
  TrustEntity = 'TRUST_ENTITY'
}

/**
 * KGNodeWhereInput is used for filtering KGNode objects.
 * Input was generated by ent.
 */
export type KgNodeWhereInput = {
  and?: InputMaybe<Array<KgNodeWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** file_id field predicates */
  fileID?: InputMaybe<Scalars['ID']['input']>;
  fileIDContains?: InputMaybe<Scalars['ID']['input']>;
  fileIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  fileIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  fileIDGT?: InputMaybe<Scalars['ID']['input']>;
  fileIDGTE?: InputMaybe<Scalars['ID']['input']>;
  fileIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  fileIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  fileIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  fileIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  fileIDLT?: InputMaybe<Scalars['ID']['input']>;
  fileIDLTE?: InputMaybe<Scalars['ID']['input']>;
  fileIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  fileIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  fileIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** evidence_document edge predicates */
  hasEvidenceDocument?: InputMaybe<Scalars['Boolean']['input']>;
  hasEvidenceDocumentWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** file edge predicates */
  hasFile?: InputMaybe<Scalars['Boolean']['input']>;
  hasFileWith?: InputMaybe<Array<FileWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** inbound_relationships edge predicates */
  hasInboundRelationships?: InputMaybe<Scalars['Boolean']['input']>;
  hasInboundRelationshipsWith?: InputMaybe<Array<KgRelationshipWhereInput>>;
  /** kg_root edge predicates */
  hasKgRoot?: InputMaybe<Scalars['Boolean']['input']>;
  hasKgRootWith?: InputMaybe<Array<KgRootWhereInput>>;
  /** outbound_relationships edge predicates */
  hasOutboundRelationships?: InputMaybe<Scalars['Boolean']['input']>;
  hasOutboundRelationshipsWith?: InputMaybe<Array<KgRelationshipWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** household_id field predicates */
  householdID?: InputMaybe<Scalars['ID']['input']>;
  householdIDContains?: InputMaybe<Scalars['ID']['input']>;
  householdIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDGT?: InputMaybe<Scalars['ID']['input']>;
  householdIDGTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  householdIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDLT?: InputMaybe<Scalars['ID']['input']>;
  householdIDLTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  householdIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<KgNodeWhereInput>;
  or?: InputMaybe<Array<KgNodeWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** type field predicates */
  type?: InputMaybe<KgNodeType>;
  typeIn?: InputMaybe<Array<KgNodeType>>;
  typeNEQ?: InputMaybe<KgNodeType>;
  typeNotIn?: InputMaybe<Array<KgNodeType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type KgRelationship = Node & {
  __typename?: 'KGRelationship';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The LLM description of the relationship. */
  description: Scalars['String']['output'];
  /** The document to use when searching for evidence for this relationship. */
  evidenceDocument: Document;
  id: Scalars['ID']['output'];
  /** The source node of the relationship. */
  source: KgNode;
  /** The KGNode ID that is the source of this relationship. */
  sourceNodeID: Scalars['ID']['output'];
  /** How confident the LLM is in the relationship. */
  strength?: Maybe<Scalars['Int']['output']>;
  /** The target node of the relationship. */
  target: KgNode;
  /** The KGNode ID that is the target of this relationship. */
  targetNodeID: Scalars['ID']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** The type of the relationship between the source and target nodes. */
  type: KgRelationshipType;
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** Ordering options for KGRelationship connections */
export type KgRelationshipOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order KGRelationships. */
  field: KgRelationshipOrderField;
};

/** Properties by which KGRelationship connections can be ordered. */
export enum KgRelationshipOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** KGRelationshipType is enum for the field type */
export enum KgRelationshipType {
  BeneficiaryOf = 'BENEFICIARY_OF',
  ChildOf = 'CHILD_OF',
  CreatedUponTheDeathOf = 'CREATED_UPON_THE_DEATH_OF',
  DomesticPartnerOf = 'DOMESTIC_PARTNER_OF',
  DonorOf = 'DONOR_OF',
  FriendOf = 'FRIEND_OF',
  GrandchildOf = 'GRANDCHILD_OF',
  GrandparentOf = 'GRANDPARENT_OF',
  GrantorOf = 'GRANTOR_OF',
  Other = 'OTHER',
  OwnerOf = 'OWNER_OF',
  ParentOf = 'PARENT_OF',
  SiblingOf = 'SIBLING_OF',
  SpouseOf = 'SPOUSE_OF',
  TrusteeOf = 'TRUSTEE_OF',
  TrustAdvisorOf = 'TRUST_ADVISOR_OF'
}

/**
 * KGRelationshipWhereInput is used for filtering KGRelationship objects.
 * Input was generated by ent.
 */
export type KgRelationshipWhereInput = {
  and?: InputMaybe<Array<KgRelationshipWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** evidence_document edge predicates */
  hasEvidenceDocument?: InputMaybe<Scalars['Boolean']['input']>;
  hasEvidenceDocumentWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** source edge predicates */
  hasSource?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceWith?: InputMaybe<Array<KgNodeWhereInput>>;
  /** target edge predicates */
  hasTarget?: InputMaybe<Scalars['Boolean']['input']>;
  hasTargetWith?: InputMaybe<Array<KgNodeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<KgRelationshipWhereInput>;
  or?: InputMaybe<Array<KgRelationshipWhereInput>>;
  /** source_node_id field predicates */
  sourceNodeID?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIDContains?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIDGT?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIDGTE?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourceNodeIDLT?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIDLTE?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  sourceNodeIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** strength field predicates */
  strength?: InputMaybe<Scalars['Int']['input']>;
  strengthGT?: InputMaybe<Scalars['Int']['input']>;
  strengthGTE?: InputMaybe<Scalars['Int']['input']>;
  strengthIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  strengthIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  strengthLT?: InputMaybe<Scalars['Int']['input']>;
  strengthLTE?: InputMaybe<Scalars['Int']['input']>;
  strengthNEQ?: InputMaybe<Scalars['Int']['input']>;
  strengthNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  strengthNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** target_node_id field predicates */
  targetNodeID?: InputMaybe<Scalars['ID']['input']>;
  targetNodeIDContains?: InputMaybe<Scalars['ID']['input']>;
  targetNodeIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  targetNodeIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  targetNodeIDGT?: InputMaybe<Scalars['ID']['input']>;
  targetNodeIDGTE?: InputMaybe<Scalars['ID']['input']>;
  targetNodeIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  targetNodeIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  targetNodeIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  targetNodeIDLT?: InputMaybe<Scalars['ID']['input']>;
  targetNodeIDLTE?: InputMaybe<Scalars['ID']['input']>;
  targetNodeIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  targetNodeIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** type field predicates */
  type?: InputMaybe<KgRelationshipType>;
  typeIn?: InputMaybe<Array<KgRelationshipType>>;
  typeNEQ?: InputMaybe<KgRelationshipType>;
  typeNotIn?: InputMaybe<Array<KgRelationshipType>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type KgRoot = Node & {
  __typename?: 'KGRoot';
  /** The AI suggestions that were created from the nodes/relationships in this KGRoot. */
  aiSuggestions?: Maybe<Array<AiSuggestion>>;
  /** The async job to track the status of the KGRoot creation. */
  asyncJob?: Maybe<AsyncJob>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** The files whose nodes/relationships should be included in this KGRoot. */
  files?: Maybe<Array<File>>;
  household: Household;
  /** the household this object belongs to */
  householdID: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  nodes: KgNodeConnection;
  /** The status of the construction of this KGRoot. It will be COMPLETE when the KGNodes have all been generated for the associated files and merged into the root. */
  status: KgRootStatus;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updateUser: User;
  updatedAt: Scalars['Time']['output'];
  updatedBy: Scalars['ID']['output'];
  user: User;
};


export type KgRootNodesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<KgNodeOrder>>;
  where?: InputMaybe<KgNodeWhereInput>;
};

/** A connection to a list of items. */
export type KgRootConnection = {
  __typename?: 'KGRootConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<KgRootEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type KgRootEdge = {
  __typename?: 'KGRootEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<KgRoot>;
};

/** Ordering options for KGRoot connections */
export type KgRootOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order KGRoots. */
  field: KgRootOrderField;
};

/** Properties by which KGRoot connections can be ordered. */
export enum KgRootOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** KGRootStatus is enum for the field status */
export enum KgRootStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  SuggestionReviewComplete = 'SUGGESTION_REVIEW_COMPLETE'
}

/**
 * KGRootWhereInput is used for filtering KGRoot objects.
 * Input was generated by ent.
 */
export type KgRootWhereInput = {
  and?: InputMaybe<Array<KgRootWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** ai_suggestions edge predicates */
  hasAiSuggestions?: InputMaybe<Scalars['Boolean']['input']>;
  hasAiSuggestionsWith?: InputMaybe<Array<AiSuggestionWhereInput>>;
  /** async_job edge predicates */
  hasAsyncJob?: InputMaybe<Scalars['Boolean']['input']>;
  hasAsyncJobWith?: InputMaybe<Array<AsyncJobWhereInput>>;
  /** files edge predicates */
  hasFiles?: InputMaybe<Scalars['Boolean']['input']>;
  hasFilesWith?: InputMaybe<Array<FileWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** nodes edge predicates */
  hasNodes?: InputMaybe<Scalars['Boolean']['input']>;
  hasNodesWith?: InputMaybe<Array<KgNodeWhereInput>>;
  /** update_user edge predicates */
  hasUpdateUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** household_id field predicates */
  householdID?: InputMaybe<Scalars['ID']['input']>;
  householdIDContains?: InputMaybe<Scalars['ID']['input']>;
  householdIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDGT?: InputMaybe<Scalars['ID']['input']>;
  householdIDGTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  householdIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDLT?: InputMaybe<Scalars['ID']['input']>;
  householdIDLTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  householdIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<KgRootWhereInput>;
  or?: InputMaybe<Array<KgRootWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<KgRootStatus>;
  statusIn?: InputMaybe<Array<KgRootStatus>>;
  statusNEQ?: InputMaybe<KgRootStatus>;
  statusNotIn?: InputMaybe<Array<KgRootStatus>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['ID']['input']>;
  updatedByContains?: InputMaybe<Scalars['ID']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByGT?: InputMaybe<Scalars['ID']['input']>;
  updatedByGTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedByLT?: InputMaybe<Scalars['ID']['input']>;
  updatedByLTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['ID']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type KeyPerson = Node & {
  __typename?: 'KeyPerson';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** An edge to the key person, which is an entity */
  entity?: Maybe<Entity>;
  id: Scalars['ID']['output'];
  /** An edge to the key person, which is an individual */
  individual?: Maybe<ClientProfile>;
  /** An edge to the key person, which is an organization */
  organization?: Maybe<ClientOrganization>;
  /** Roles of this key person */
  roles?: Maybe<Array<KeyPersonRole>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** Ordering options for KeyPerson connections */
export type KeyPersonOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order KeyPersons. */
  field: KeyPersonOrderField;
};

/** Properties by which KeyPerson connections can be ordered. */
export enum KeyPersonOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type KeyPersonRole = Node & {
  __typename?: 'KeyPersonRole';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** Key person that this role belongs to */
  keyPerson: KeyPerson;
  kind?: Maybe<KeyPersonRoleKind>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** KeyPersonRoleKind is enum for the field kind */
export enum KeyPersonRoleKind {
  BoardMember = 'BOARD_MEMBER',
  ControllingPerson = 'CONTROLLING_PERSON',
  Director = 'DIRECTOR',
  GeneralPartner = 'GENERAL_PARTNER',
  Manager = 'MANAGER',
  Officer = 'OFFICER',
  Other = 'OTHER',
  President = 'PRESIDENT',
  Secretary = 'SECRETARY',
  Signatory = 'SIGNATORY',
  VicePresident = 'VICE_PRESIDENT'
}

/** Ordering options for KeyPersonRole connections */
export type KeyPersonRoleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order KeyPersonRoles. */
  field: KeyPersonRoleOrderField;
};

/** Properties by which KeyPersonRole connections can be ordered. */
export enum KeyPersonRoleOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * KeyPersonRoleWhereInput is used for filtering KeyPersonRole objects.
 * Input was generated by ent.
 */
export type KeyPersonRoleWhereInput = {
  and?: InputMaybe<Array<KeyPersonRoleWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** key_person edge predicates */
  hasKeyPerson?: InputMaybe<Scalars['Boolean']['input']>;
  hasKeyPersonWith?: InputMaybe<Array<KeyPersonWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<KeyPersonRoleKind>;
  kindIn?: InputMaybe<Array<KeyPersonRoleKind>>;
  kindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  kindNEQ?: InputMaybe<KeyPersonRoleKind>;
  kindNotIn?: InputMaybe<Array<KeyPersonRoleKind>>;
  kindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<KeyPersonRoleWhereInput>;
  or?: InputMaybe<Array<KeyPersonRoleWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * KeyPersonWhereInput is used for filtering KeyPerson objects.
 * Input was generated by ent.
 */
export type KeyPersonWhereInput = {
  and?: InputMaybe<Array<KeyPersonWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** individual edge predicates */
  hasIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** organization edge predicates */
  hasOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** roles edge predicates */
  hasRoles?: InputMaybe<Scalars['Boolean']['input']>;
  hasRolesWith?: InputMaybe<Array<KeyPersonRoleWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<KeyPersonWhereInput>;
  or?: InputMaybe<Array<KeyPersonWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type LlcBusinessEntity = BusinessEntity & EntitySubtype & Named & Node & {
  __typename?: 'LLCBusinessEntity';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this business entity */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: Maybe<Scalars['String']['output']>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: Maybe<Scalars['String']['output']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  grantorControlledPercentage: Scalars['Percent']['output'];
  grantorControlledValue: Scalars['CurrencyUSD']['output'];
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  keyPeople?: Maybe<Array<KeyPerson>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: Maybe<Scalars['String']['output']>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  nongrantorControlledPercentage: Scalars['Percent']['output'];
  nongrantorControlledValue: Scalars['CurrencyUSD']['output'];
  /** Ownership as-of date. */
  ownershipAsOfDate?: Maybe<Scalars['Time']['output']>;
  policies?: Maybe<Array<InsurancePolicy>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: Maybe<Scalars['Boolean']['output']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxID?: Maybe<Scalars['String']['output']>;
  taxStatus?: Maybe<BusinessEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type LlcBusinessEntityHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type LlcBusinessEntityConnection = {
  __typename?: 'LLCBusinessEntityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LlcBusinessEntityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LlcBusinessEntityEdge = {
  __typename?: 'LLCBusinessEntityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LlcBusinessEntity>;
};

/** Ordering options for LLCBusinessEntity connections */
export type LlcBusinessEntityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order LLCBusinessEntities. */
  field: LlcBusinessEntityOrderField;
};

/** Properties by which LLCBusinessEntity connections can be ordered. */
export enum LlcBusinessEntityOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * LLCBusinessEntityWhereInput is used for filtering LLCBusinessEntity objects.
 * Input was generated by ent.
 */
export type LlcBusinessEntityWhereInput = {
  and?: InputMaybe<Array<LlcBusinessEntityWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** doing_business_as_name field predicates */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContains?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  doingBusinessAsNameLT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameLTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNEQ?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** fin_cen_id field predicates */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  finCenIDContains?: InputMaybe<Scalars['String']['input']>;
  finCenIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDGT?: InputMaybe<Scalars['String']['input']>;
  finCenIDGTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  finCenIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  finCenIDLT?: InputMaybe<Scalars['String']['input']>;
  finCenIDLTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDNEQ?: InputMaybe<Scalars['String']['input']>;
  finCenIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** formation_jurisdiction_state_code field predicates */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  formationJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** key_people edge predicates */
  hasKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  hasKeyPeopleWith?: InputMaybe<Array<KeyPersonWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** key_people_notes field predicates */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContains?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  keyPeopleNotesLT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesLTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<LlcBusinessEntityWhereInput>;
  or?: InputMaybe<Array<LlcBusinessEntityWhereInput>>;
  /** ownership_as_of_date field predicates */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownershipAsOfDateLT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateLTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requires_cta_reporting field predicates */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_id field predicates */
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxIDContains?: InputMaybe<Scalars['String']['input']>;
  taxIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  taxIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  taxIDGT?: InputMaybe<Scalars['String']['input']>;
  taxIDGTE?: InputMaybe<Scalars['String']['input']>;
  taxIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  taxIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  taxIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxIDLT?: InputMaybe<Scalars['String']['input']>;
  taxIDLTE?: InputMaybe<Scalars['String']['input']>;
  taxIDNEQ?: InputMaybe<Scalars['String']['input']>;
  taxIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type LpBusinessEntity = BusinessEntity & EntitySubtype & Named & Node & {
  __typename?: 'LPBusinessEntity';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this business entity */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: Maybe<Scalars['String']['output']>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: Maybe<Scalars['String']['output']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  grantorControlledPercentage: Scalars['Percent']['output'];
  grantorControlledValue: Scalars['CurrencyUSD']['output'];
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  keyPeople?: Maybe<Array<KeyPerson>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: Maybe<Scalars['String']['output']>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  nongrantorControlledPercentage: Scalars['Percent']['output'];
  nongrantorControlledValue: Scalars['CurrencyUSD']['output'];
  /** Ownership as-of date. */
  ownershipAsOfDate?: Maybe<Scalars['Time']['output']>;
  policies?: Maybe<Array<InsurancePolicy>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: Maybe<Scalars['Boolean']['output']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxID?: Maybe<Scalars['String']['output']>;
  taxStatus?: Maybe<BusinessEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type LpBusinessEntityHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type LpBusinessEntityConnection = {
  __typename?: 'LPBusinessEntityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LpBusinessEntityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LpBusinessEntityEdge = {
  __typename?: 'LPBusinessEntityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LpBusinessEntity>;
};

/** Ordering options for LPBusinessEntity connections */
export type LpBusinessEntityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order LPBusinessEntities. */
  field: LpBusinessEntityOrderField;
};

/** Properties by which LPBusinessEntity connections can be ordered. */
export enum LpBusinessEntityOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * LPBusinessEntityWhereInput is used for filtering LPBusinessEntity objects.
 * Input was generated by ent.
 */
export type LpBusinessEntityWhereInput = {
  and?: InputMaybe<Array<LpBusinessEntityWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** doing_business_as_name field predicates */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContains?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  doingBusinessAsNameLT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameLTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNEQ?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** fin_cen_id field predicates */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  finCenIDContains?: InputMaybe<Scalars['String']['input']>;
  finCenIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDGT?: InputMaybe<Scalars['String']['input']>;
  finCenIDGTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  finCenIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  finCenIDLT?: InputMaybe<Scalars['String']['input']>;
  finCenIDLTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDNEQ?: InputMaybe<Scalars['String']['input']>;
  finCenIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** formation_jurisdiction_state_code field predicates */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  formationJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** key_people edge predicates */
  hasKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  hasKeyPeopleWith?: InputMaybe<Array<KeyPersonWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** key_people_notes field predicates */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContains?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  keyPeopleNotesLT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesLTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<LpBusinessEntityWhereInput>;
  or?: InputMaybe<Array<LpBusinessEntityWhereInput>>;
  /** ownership_as_of_date field predicates */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownershipAsOfDateLT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateLTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requires_cta_reporting field predicates */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_id field predicates */
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxIDContains?: InputMaybe<Scalars['String']['input']>;
  taxIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  taxIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  taxIDGT?: InputMaybe<Scalars['String']['input']>;
  taxIDGTE?: InputMaybe<Scalars['String']['input']>;
  taxIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  taxIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  taxIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxIDLT?: InputMaybe<Scalars['String']['input']>;
  taxIDLTE?: InputMaybe<Scalars['String']['input']>;
  taxIDNEQ?: InputMaybe<Scalars['String']['input']>;
  taxIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type LastViewedWaterfallWhereInput = {
  householdId: Scalars['ID']['input'];
};

export type Liability = Node & {
  __typename?: 'Liability';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  currentAmountAsOfDate?: Maybe<Scalars['Time']['output']>;
  displayName: Scalars['String']['output'];
  finalPaymentAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** The household associated with this liability. */
  household?: Maybe<Household>;
  id: Scalars['ID']['output'];
  initialAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  interestPaid?: Maybe<Scalars['CurrencyUSD']['output']>;
  interestRate?: Maybe<Scalars['Percent']['output']>;
  paymentFrequency?: Maybe<LiabilityPaymentFrequency>;
  paymentKind?: Maybe<LiabilityPaymentKind>;
  payments?: Maybe<Array<LiabilityPayment>>;
  /** The entity that is the recipient (borrower) of this liability. */
  recipientEntity?: Maybe<Entity>;
  recurringPaymentAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** The client organization that is the source (lender) of this liability. */
  sourceClientOrganization?: Maybe<ClientOrganization>;
  /** The client profile that is the source (lender) of this liability. */
  sourceClientProfile?: Maybe<ClientProfile>;
  /** The entity that is the source (lender) of this liability. */
  sourceEntity?: Maybe<Entity>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  termEnd?: Maybe<Scalars['Time']['output']>;
  termStart?: Maybe<Scalars['Time']['output']>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type LiabilityConnection = {
  __typename?: 'LiabilityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LiabilityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LiabilityEdge = {
  __typename?: 'LiabilityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Liability>;
};

/** Ordering options for Liability connections */
export type LiabilityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Liabilities. */
  field: LiabilityOrderField;
};

/** Properties by which Liability connections can be ordered. */
export enum LiabilityOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type LiabilityPayment = Node & {
  __typename?: 'LiabilityPayment';
  createdAt: Scalars['Time']['output'];
  date?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  interestPaymentAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  liability: Liability;
  newLiabilityAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  oldLiabilityAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  principalPaymentAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  totalPaymentAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  updatedAt: Scalars['Time']['output'];
};

export type LiabilityPaymentCalculationDetails = {
  __typename?: 'LiabilityPaymentCalculationDetails';
  finalPaymentAmount: Scalars['CurrencyUSD']['output'];
  finalPaymentInterestAmount: Scalars['CurrencyUSD']['output'];
  finalPaymentPrincipalAmount: Scalars['CurrencyUSD']['output'];
  numberOfPayments: Scalars['Int']['output'];
  recurringPaymentAmount: Scalars['CurrencyUSD']['output'];
  termEnd: Scalars['Time']['output'];
  totalInterestPaid: Scalars['CurrencyUSD']['output'];
  totalPaid: Scalars['CurrencyUSD']['output'];
};

export type LiabilityPaymentCalculationInput = {
  interestRate: Scalars['Percent']['input'];
  paymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  paymentFrequency: LiabilityPaymentFrequency;
  paymentKind?: InputMaybe<LiabilityPaymentKind>;
  principalAmount: Scalars['CurrencyUSD']['input'];
  termEnd?: InputMaybe<Scalars['Time']['input']>;
  termStart: Scalars['Time']['input'];
};

/** LiabilityPaymentFrequency is enum for the field payment_frequency */
export enum LiabilityPaymentFrequency {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Semiannually = 'SEMIANNUALLY'
}

/** LiabilityPaymentKind is enum for the field payment_kind */
export enum LiabilityPaymentKind {
  Amortized = 'AMORTIZED',
  InterestOnly = 'INTEREST_ONLY'
}

/** Ordering options for LiabilityPayment connections */
export type LiabilityPaymentOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order LiabilityPayments. */
  field: LiabilityPaymentOrderField;
};

/** Properties by which LiabilityPayment connections can be ordered. */
export enum LiabilityPaymentOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * LiabilityPaymentWhereInput is used for filtering LiabilityPayment objects.
 * Input was generated by ent.
 */
export type LiabilityPaymentWhereInput = {
  and?: InputMaybe<Array<LiabilityPaymentWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** date field predicates */
  date?: InputMaybe<Scalars['Time']['input']>;
  dateGT?: InputMaybe<Scalars['Time']['input']>;
  dateGTE?: InputMaybe<Scalars['Time']['input']>;
  dateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  dateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dateLT?: InputMaybe<Scalars['Time']['input']>;
  dateLTE?: InputMaybe<Scalars['Time']['input']>;
  dateNEQ?: InputMaybe<Scalars['Time']['input']>;
  dateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  dateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** liability edge predicates */
  hasLiability?: InputMaybe<Scalars['Boolean']['input']>;
  hasLiabilityWith?: InputMaybe<Array<LiabilityWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** interest_payment_amount field predicates */
  interestPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaymentAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaymentAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaymentAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  interestPaymentAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  interestPaymentAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaymentAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaymentAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaymentAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  interestPaymentAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** new_liability_amount field predicates */
  newLiabilityAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  newLiabilityAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  newLiabilityAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  newLiabilityAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  newLiabilityAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  newLiabilityAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  newLiabilityAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  newLiabilityAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  newLiabilityAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  newLiabilityAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<LiabilityPaymentWhereInput>;
  /** old_liability_amount field predicates */
  oldLiabilityAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  oldLiabilityAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  oldLiabilityAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  oldLiabilityAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  oldLiabilityAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  oldLiabilityAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  oldLiabilityAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  oldLiabilityAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  oldLiabilityAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  oldLiabilityAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<LiabilityPaymentWhereInput>>;
  /** principal_payment_amount field predicates */
  principalPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  principalPaymentAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  principalPaymentAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  principalPaymentAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  principalPaymentAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  principalPaymentAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  principalPaymentAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  principalPaymentAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  principalPaymentAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  principalPaymentAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** total_payment_amount field predicates */
  totalPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalPaymentAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalPaymentAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalPaymentAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  totalPaymentAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  totalPaymentAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalPaymentAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalPaymentAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalPaymentAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  totalPaymentAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * LiabilityWhereInput is used for filtering Liability objects.
 * Input was generated by ent.
 */
export type LiabilityWhereInput = {
  and?: InputMaybe<Array<LiabilityWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** current_amount field predicates */
  currentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** current_amount_as_of_date field predicates */
  currentAmountAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  currentAmountAsOfDateGT?: InputMaybe<Scalars['Time']['input']>;
  currentAmountAsOfDateGTE?: InputMaybe<Scalars['Time']['input']>;
  currentAmountAsOfDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  currentAmountAsOfDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  currentAmountAsOfDateLT?: InputMaybe<Scalars['Time']['input']>;
  currentAmountAsOfDateLTE?: InputMaybe<Scalars['Time']['input']>;
  currentAmountAsOfDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  currentAmountAsOfDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  currentAmountAsOfDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  currentAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  currentAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  currentAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  currentAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  currentAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  currentAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  currentAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  currentAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  currentAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** final_payment_amount field predicates */
  finalPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  finalPaymentAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  finalPaymentAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  finalPaymentAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  finalPaymentAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  finalPaymentAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  finalPaymentAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  finalPaymentAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  finalPaymentAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  finalPaymentAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** payments edge predicates */
  hasPayments?: InputMaybe<Scalars['Boolean']['input']>;
  hasPaymentsWith?: InputMaybe<Array<LiabilityPaymentWhereInput>>;
  /** recipient_entity edge predicates */
  hasRecipientEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecipientEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** source_client_organization edge predicates */
  hasSourceClientOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceClientOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** source_client_profile edge predicates */
  hasSourceClientProfile?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceClientProfileWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** source_entity edge predicates */
  hasSourceEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** initial_amount field predicates */
  initialAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  initialAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  initialAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  initialAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  initialAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  initialAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  initialAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  initialAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  initialAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  initialAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** interest_paid field predicates */
  interestPaid?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaidGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaidGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaidIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  interestPaidIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  interestPaidLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaidLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaidNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaidNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  interestPaidNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** interest_rate field predicates */
  interestRate?: InputMaybe<Scalars['Percent']['input']>;
  interestRateGT?: InputMaybe<Scalars['Percent']['input']>;
  interestRateGTE?: InputMaybe<Scalars['Percent']['input']>;
  interestRateIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  interestRateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  interestRateLT?: InputMaybe<Scalars['Percent']['input']>;
  interestRateLTE?: InputMaybe<Scalars['Percent']['input']>;
  interestRateNEQ?: InputMaybe<Scalars['Percent']['input']>;
  interestRateNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  interestRateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<LiabilityWhereInput>;
  or?: InputMaybe<Array<LiabilityWhereInput>>;
  /** payment_frequency field predicates */
  paymentFrequency?: InputMaybe<LiabilityPaymentFrequency>;
  paymentFrequencyIn?: InputMaybe<Array<LiabilityPaymentFrequency>>;
  paymentFrequencyIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  paymentFrequencyNEQ?: InputMaybe<LiabilityPaymentFrequency>;
  paymentFrequencyNotIn?: InputMaybe<Array<LiabilityPaymentFrequency>>;
  paymentFrequencyNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** payment_kind field predicates */
  paymentKind?: InputMaybe<LiabilityPaymentKind>;
  paymentKindIn?: InputMaybe<Array<LiabilityPaymentKind>>;
  paymentKindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  paymentKindNEQ?: InputMaybe<LiabilityPaymentKind>;
  paymentKindNotIn?: InputMaybe<Array<LiabilityPaymentKind>>;
  paymentKindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** recurring_payment_amount field predicates */
  recurringPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  recurringPaymentAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  recurringPaymentAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  recurringPaymentAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  recurringPaymentAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  recurringPaymentAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  recurringPaymentAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  recurringPaymentAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  recurringPaymentAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  recurringPaymentAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** term_end field predicates */
  termEnd?: InputMaybe<Scalars['Time']['input']>;
  termEndGT?: InputMaybe<Scalars['Time']['input']>;
  termEndGTE?: InputMaybe<Scalars['Time']['input']>;
  termEndIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  termEndIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  termEndLT?: InputMaybe<Scalars['Time']['input']>;
  termEndLTE?: InputMaybe<Scalars['Time']['input']>;
  termEndNEQ?: InputMaybe<Scalars['Time']['input']>;
  termEndNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  termEndNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** term_start field predicates */
  termStart?: InputMaybe<Scalars['Time']['input']>;
  termStartGT?: InputMaybe<Scalars['Time']['input']>;
  termStartGTE?: InputMaybe<Scalars['Time']['input']>;
  termStartIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  termStartIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  termStartLT?: InputMaybe<Scalars['Time']['input']>;
  termStartLTE?: InputMaybe<Scalars['Time']['input']>;
  termStartNEQ?: InputMaybe<Scalars['Time']['input']>;
  termStartNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  termStartNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type LifetimeExclusionEvent = Node & {
  __typename?: 'LifetimeExclusionEvent';
  /** The amount of annual exclusion this gift used */
  annualExclusionAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** The task which generated this exclusion event */
  associatedTask?: Maybe<EntityTask>;
  /** The end beneficiary of the gift if its not the recipient of the transfer */
  benefitOf?: Maybe<ClientProfile>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** Amount asset was discounted for exclusion */
  discountAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  documents?: Maybe<Array<Document>>;
  /** The date of gifting or when the adjustment was recorded */
  eventDate: Scalars['Time']['output'];
  /** The amount gifted to the recipient */
  giftAmount?: Maybe<Scalars['CurrencyUSD']['output']>;
  giftCategory?: Maybe<LifetimeExclusionEventGiftCategory>;
  /** The individual who's exclusion this is applied to */
  grantor: ClientProfile;
  grantorID: Scalars['ID']['output'];
  /** The amount of GST exclusion this gift used */
  gstExclusionAmount: Scalars['CurrencyUSD']['output'];
  id: Scalars['ID']['output'];
  kind: LifetimeExclusionEventKind;
  /** The amount of lifetime exclusion this gift used */
  lifetimeExclusionAmount: Scalars['CurrencyUSD']['output'];
  loggedTransfer?: Maybe<LoggedTransfer>;
  /** Free-form notes */
  notes?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Beneficiary>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type LifetimeExclusionEventConnection = {
  __typename?: 'LifetimeExclusionEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LifetimeExclusionEventEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LifetimeExclusionEventEdge = {
  __typename?: 'LifetimeExclusionEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LifetimeExclusionEvent>;
};

/** LifetimeExclusionEventGiftCategory is enum for the field gift_category */
export enum LifetimeExclusionEventGiftCategory {
  Cash = 'CASH',
  Other = 'OTHER',
  PublicStockSingle = 'PUBLIC_STOCK_SINGLE'
}

/** LifetimeExclusionEventKind is enum for the field kind */
export enum LifetimeExclusionEventKind {
  Adjustment = 'ADJUSTMENT',
  Gift = 'GIFT'
}

/** Ordering options for LifetimeExclusionEvent connections */
export type LifetimeExclusionEventOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order LifetimeExclusionEvents. */
  field: LifetimeExclusionEventOrderField;
};

/** Properties by which LifetimeExclusionEvent connections can be ordered. */
export enum LifetimeExclusionEventOrderField {
  CreatedAt = 'created_at',
  EventDate = 'event_date',
  UpdatedAt = 'updated_at'
}

/**
 * LifetimeExclusionEventWhereInput is used for filtering LifetimeExclusionEvent objects.
 * Input was generated by ent.
 */
export type LifetimeExclusionEventWhereInput = {
  and?: InputMaybe<Array<LifetimeExclusionEventWhereInput>>;
  /** annual_exclusion_amount field predicates */
  annualExclusionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annualExclusionAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annualExclusionAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annualExclusionAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  annualExclusionAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  annualExclusionAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annualExclusionAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annualExclusionAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  annualExclusionAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  annualExclusionAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** discount_amount field predicates */
  discountAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  discountAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  discountAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  discountAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  discountAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  discountAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  discountAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  discountAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  discountAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  discountAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** event_date field predicates */
  eventDate?: InputMaybe<Scalars['Time']['input']>;
  eventDateGT?: InputMaybe<Scalars['Time']['input']>;
  eventDateGTE?: InputMaybe<Scalars['Time']['input']>;
  eventDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  eventDateLT?: InputMaybe<Scalars['Time']['input']>;
  eventDateLTE?: InputMaybe<Scalars['Time']['input']>;
  eventDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  eventDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** gift_amount field predicates */
  giftAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  giftAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  giftAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  giftAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  giftAmountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  giftAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  giftAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  giftAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  giftAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  giftAmountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** gift_category field predicates */
  giftCategory?: InputMaybe<LifetimeExclusionEventGiftCategory>;
  giftCategoryIn?: InputMaybe<Array<LifetimeExclusionEventGiftCategory>>;
  giftCategoryIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  giftCategoryNEQ?: InputMaybe<LifetimeExclusionEventGiftCategory>;
  giftCategoryNotIn?: InputMaybe<Array<LifetimeExclusionEventGiftCategory>>;
  giftCategoryNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** grantor_id field predicates */
  grantorID?: InputMaybe<Scalars['ID']['input']>;
  grantorIDContains?: InputMaybe<Scalars['ID']['input']>;
  grantorIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  grantorIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  grantorIDGT?: InputMaybe<Scalars['ID']['input']>;
  grantorIDGTE?: InputMaybe<Scalars['ID']['input']>;
  grantorIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  grantorIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  grantorIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  grantorIDLT?: InputMaybe<Scalars['ID']['input']>;
  grantorIDLTE?: InputMaybe<Scalars['ID']['input']>;
  grantorIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  grantorIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** gst_exclusion_amount field predicates */
  gstExclusionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  gstExclusionAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  gstExclusionAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  gstExclusionAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  gstExclusionAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  gstExclusionAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  gstExclusionAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  gstExclusionAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  /** associated_task edge predicates */
  hasAssociatedTask?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssociatedTaskWith?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** benefit_of edge predicates */
  hasBenefitOf?: InputMaybe<Scalars['Boolean']['input']>;
  hasBenefitOfWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** documents edge predicates */
  hasDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentsWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** grantor edge predicates */
  hasGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantorWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** logged_transfer edge predicates */
  hasLoggedTransfer?: InputMaybe<Scalars['Boolean']['input']>;
  hasLoggedTransferWith?: InputMaybe<Array<LoggedTransferWhereInput>>;
  /** recipient edge predicates */
  hasRecipient?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecipientWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<LifetimeExclusionEventKind>;
  kindIn?: InputMaybe<Array<LifetimeExclusionEventKind>>;
  kindNEQ?: InputMaybe<LifetimeExclusionEventKind>;
  kindNotIn?: InputMaybe<Array<LifetimeExclusionEventKind>>;
  /** lifetime_exclusion_amount field predicates */
  lifetimeExclusionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  lifetimeExclusionAmountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  lifetimeExclusionAmountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  lifetimeExclusionAmountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  lifetimeExclusionAmountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  lifetimeExclusionAmountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  lifetimeExclusionAmountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  lifetimeExclusionAmountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  not?: InputMaybe<LifetimeExclusionEventWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<LifetimeExclusionEventWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type LoggedTransfer = Node & {
  __typename?: 'LoggedTransfer';
  /** The amount of logged transfer. */
  amount: Scalars['CurrencyUSD']['output'];
  /** The task which generated this transfer */
  associatedTask?: Maybe<EntityTask>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** Documents that might be associated with the transfer. */
  documents?: Maybe<Array<Document>>;
  friendlyContributionPurpose?: Maybe<Scalars['String']['output']>;
  friendlyDistributionPurpose?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lifetimeExclusionEvents?: Maybe<Array<LifetimeExclusionEvent>>;
  /** Custom purpose if "Other" purpose is selected. */
  otherPurposeDescription?: Maybe<Scalars['String']['output']>;
  /** Purpose of transfer */
  purpose?: Maybe<LoggedTransferPurpose>;
  /** An edge to the receiving entity of the transfer */
  receivingEntity?: Maybe<Entity>;
  /** An edge to the receiving grantor of the transfer */
  receivingGrantor?: Maybe<ClientProfile>;
  /** An edge to the receiving organization of the transfer */
  receivingOrganization?: Maybe<ClientOrganization>;
  /** An edge to the source entity of the transfer */
  sourceEntity?: Maybe<Entity>;
  /** An edge to the source grantor of the transfer */
  sourceGrantor?: Maybe<ClientProfile>;
  /** An edge to the source organization of the transfer */
  sourceOrganization?: Maybe<ClientOrganization>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  transactionDate: Scalars['Time']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type LoggedTransferConnection = {
  __typename?: 'LoggedTransferConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LoggedTransferEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type LoggedTransferEdge = {
  __typename?: 'LoggedTransferEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<LoggedTransfer>;
};

/** Ordering options for LoggedTransfer connections */
export type LoggedTransferOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order LoggedTransfers. */
  field: LoggedTransferOrderField;
};

/** Properties by which LoggedTransfer connections can be ordered. */
export enum LoggedTransferOrderField {
  CreatedAt = 'created_at',
  TransactionDate = 'transaction_date',
  UpdatedAt = 'updated_at'
}

/** LoggedTransferPurpose is enum for the field purpose */
export enum LoggedTransferPurpose {
  BeneficiaryDistribution = 'BENEFICIARY_DISTRIBUTION',
  CharitableGift = 'CHARITABLE_GIFT',
  Grant = 'GRANT',
  GratRemainderDistribution = 'GRAT_REMAINDER_DISTRIBUTION',
  NonGiftAddition = 'NON_GIFT_ADDITION',
  Other = 'OTHER',
  TaxableGift = 'TAXABLE_GIFT'
}

/**
 * LoggedTransferWhereInput is used for filtering LoggedTransfer objects.
 * Input was generated by ent.
 */
export type LoggedTransferWhereInput = {
  /** amount field predicates */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  amountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  and?: InputMaybe<Array<LoggedTransferWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** associated_task edge predicates */
  hasAssociatedTask?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssociatedTaskWith?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** documents edge predicates */
  hasDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  hasDocumentsWith?: InputMaybe<Array<DocumentWhereInput>>;
  /** lifetime_exclusion_events edge predicates */
  hasLifetimeExclusionEvents?: InputMaybe<Scalars['Boolean']['input']>;
  hasLifetimeExclusionEventsWith?: InputMaybe<Array<LifetimeExclusionEventWhereInput>>;
  /** receiving_entity edge predicates */
  hasReceivingEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasReceivingEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** receiving_grantor edge predicates */
  hasReceivingGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  hasReceivingGrantorWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** receiving_organization edge predicates */
  hasReceivingOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasReceivingOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** source_entity edge predicates */
  hasSourceEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** source_grantor edge predicates */
  hasSourceGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceGrantorWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** source_organization edge predicates */
  hasSourceOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasSourceOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<LoggedTransferWhereInput>;
  or?: InputMaybe<Array<LoggedTransferWhereInput>>;
  /** other_purpose_description field predicates */
  otherPurposeDescription?: InputMaybe<Scalars['String']['input']>;
  otherPurposeDescriptionContains?: InputMaybe<Scalars['String']['input']>;
  otherPurposeDescriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherPurposeDescriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherPurposeDescriptionGT?: InputMaybe<Scalars['String']['input']>;
  otherPurposeDescriptionGTE?: InputMaybe<Scalars['String']['input']>;
  otherPurposeDescriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherPurposeDescriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherPurposeDescriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherPurposeDescriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  otherPurposeDescriptionLT?: InputMaybe<Scalars['String']['input']>;
  otherPurposeDescriptionLTE?: InputMaybe<Scalars['String']['input']>;
  otherPurposeDescriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  otherPurposeDescriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherPurposeDescriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** purpose field predicates */
  purpose?: InputMaybe<LoggedTransferPurpose>;
  purposeIn?: InputMaybe<Array<LoggedTransferPurpose>>;
  purposeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  purposeNEQ?: InputMaybe<LoggedTransferPurpose>;
  purposeNotIn?: InputMaybe<Array<LoggedTransferPurpose>>;
  purposeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** transaction_date field predicates */
  transactionDate?: InputMaybe<Scalars['Time']['input']>;
  transactionDateGT?: InputMaybe<Scalars['Time']['input']>;
  transactionDateGTE?: InputMaybe<Scalars['Time']['input']>;
  transactionDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  transactionDateLT?: InputMaybe<Scalars['Time']['input']>;
  transactionDateLTE?: InputMaybe<Scalars['Time']['input']>;
  transactionDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  transactionDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type MostRecentlyViewedEntityMap = {
  __typename?: 'MostRecentlyViewedEntityMap';
  id?: Maybe<Scalars['ID']['output']>;
};

export type MostRecentlyViewedEntityMapWhereInput = {
  householdId: Scalars['ID']['input'];
};

export type MostRecentlyViewedWaterfall = {
  __typename?: 'MostRecentlyViewedWaterfall';
  id?: Maybe<Scalars['ID']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  DANGEROUS_deleteTestData?: Maybe<Scalars['Int']['output']>;
  /**
   * Updates the acceptance status of the AI suggestion, and associates it
   * with a client_profile/client_organization/entity row (if applicable).
   */
  acknowledgeAISuggestions: Array<AiSuggestion>;
  assignEntityTasksToUser: Scalars['Int']['output'];
  /**
   * Backfills the document inference metadata for the specified documents.
   * Metadata includes:
   *    - Invoking the OCR Lambda to extract document content
   *    - Storing the file page info
   *    - Storing the embedding for each paragraph from each document, and bounding regions
   */
  asyncBackfillDocumentInferenceMetadata: Array<AsyncJob>;
  /** Runs the OCR Lambda function for all documents in the tenant that have not been OCR'd yet. */
  asyncDocumentOCRBackfill: Array<AsyncJob>;
  /** Summarizes the documents matching the where clause. */
  asyncSummarizeDocuments: Array<AsyncJob>;
  autoGroupEstateWaterfall: EstateWaterfall;
  cancelEntityTask: EntityTask;
  completeEntityTask: EntityTask;
  convertEntity: Entity;
  copyArchetypeHousehold: Household;
  copyHousehold: Household;
  createAssetClass: AssetClass;
  createAssetClasses: Array<Maybe<AssetClass>>;
  createAssetValuationV2: AssetValuationV2;
  createBalanceSheetCategories: Array<Maybe<BalanceSheetCategory>>;
  createBalanceSheetCategory: BalanceSheetCategory;
  createCashflow: Cashflow;
  createClientOrganization: ClientOrganization;
  createClientOrganizations: Array<ClientOrganization>;
  createClientPresentation: ClientPresentation;
  createClientProfile: ClientProfile;
  createClientProfiles: Array<ClientProfile>;
  createDispositiveProvisionTemplate: DispositiveProvisionTemplate;
  createDocument: Document;
  createDocumentSharingToken: SharingToken;
  createDocumentSummary: DocumentSummary;
  createDocuments: Array<Document>;
  createEmployee: Employee;
  createEntities: Array<Maybe<Entity>>;
  createEntity: Entity;
  createEntityGraphView: EntityGraphView;
  createEstateTaxScenario: EstateTaxScenario;
  createEstateWaterfall: EstateWaterfall;
  createEstateWaterfallVisualizationConfig: EstateWaterfallVisualizationConfig;
  createEstateWaterfallVisualizationFilter: EstateWaterfallVisualizationFilter;
  createGraphVisualizationGroup: GraphVisualizationGroup;
  createHousehold: Household;
  createHouseholdOnboarding: HouseholdOnboarding;
  createHouseholdOnboardingStep: HouseholdOnboardingStep;
  createHouseholds: Array<Maybe<Household>>;
  createHypotheticalWaterfallDispositiveProvisionTemplate: HypotheticalWaterfallDispositiveProvisionTemplate;
  createIntegrationEntitiesForUploadedCSV: Scalars['Boolean']['output'];
  createKgRoot: KgRoot;
  createLiability: Liability;
  createLifetimeExclusionEvent: LifetimeExclusionEvent;
  createLoggedTransfer: LoggedTransfer;
  createOwnershipStake: OwnershipStake;
  createProfessionalTeamRole: ProfessionalTeamRole;
  createProposalSharingToken: SharingToken;
  createProposalV2: Proposal;
  createSSOConnection: SsoConnection;
  createSubBrand: SubBrand;
  createTeam: Team;
  createTenant?: Maybe<Scalars['ID']['output']>;
  createTenantSSOConnection: TenantSsoConnection;
  createTenantUserv2: User;
  createTestamentaryEntities: Array<TestamentaryEntity>;
  createTestamentaryEntity: TestamentaryEntity;
  createUserNotificationConfiguration: UserNotificationConfiguration;
  deleteAssetClass: Scalars['ID']['output'];
  deleteAssetValuationv2: Scalars['ID']['output'];
  deleteBalanceSheetCategory: Scalars['ID']['output'];
  deleteCashflows: Scalars['Int']['output'];
  deleteClientOrganization: Scalars['ID']['output'];
  deleteClientPresentation?: Maybe<Scalars['Int']['output']>;
  deleteClientProfile: Scalars['ID']['output'];
  deleteCollaborator: Scalars['ID']['output'];
  deleteDispositiveProvision: Scalars['ID']['output'];
  deleteDispositiveProvisionTemplate: Scalars['ID']['output'];
  deleteDocument: Scalars['ID']['output'];
  deleteDocuments: Scalars['Int']['output'];
  deleteEntities: Scalars['Int']['output'];
  deleteEntityGraphView: Scalars['ID']['output'];
  deleteEstateWaterfall: Scalars['ID']['output'];
  deleteEstateWaterfallVisualizationFilter: Scalars['ID']['output'];
  deleteFeatureFlagQueryRule: Scalars['Int']['output'];
  deleteGraphVisualizationGroups: Array<Scalars['ID']['output']>;
  deleteHouseholds: Scalars['Int']['output'];
  deleteLiability: Scalars['ID']['output'];
  deleteLifetimeExclusionEvent: Scalars['ID']['output'];
  deleteLoggedTransfer: Scalars['ID']['output'];
  deleteProposal: Scalars['ID']['output'];
  deleteSubBrand: Scalars['ID']['output'];
  deleteTenant: Scalars['ID']['output'];
  deleteTenantSSOConnection: Scalars['ID']['output'];
  deleteTenantUser: Scalars['ID']['output'];
  deleteTestamentaryEntity: Scalars['ID']['output'];
  downloadBalanceSheetExcel?: Maybe<File>;
  duplicateEntity: Entity;
  duplicateEstateWaterfall: EstateWaterfall;
  duplicateProposal: Proposal;
  duplicateTestamentaryEntity: TestamentaryEntity;
  exportEntityReport: File;
  exportHousehold: Scalars['String']['output'];
  /** Returns a reference to a file containing basic household and entity information, as well as details of linked accounts */
  exportHouseholdAccounts: File;
  exportLifetimeExclusionEvents: File;
  flushAndReloadAddeparClientsAndEntitiesForUser: Scalars['Boolean']['output'];
  generateGRATDesignSummaryV2: Document;
  /**
   * ADMIN ONLY: Generate a knowledge graph for a given document.
   * This is mostly for testing purposes.
   */
  generateKnowledgeGraphForDocument: Scalars['String']['output'];
  /**
   * Starts the AIOnboarding process, which generates knowledge graphs for each
   * given document, and returns the root node that contains the consolidated KG.
   * The root node will contain the status, and will be "complete" when all
   * documents are processed.
   */
  generateKnowledgeGraphFromDocuments: KgRoot;
  /** Connects entities with IntegrationIDs for integration_entities of kind CSV_IMPORT */
  importEntityAccountAssociations?: Maybe<CsvAccountImportResponse>;
  importHousehold: Household;
  ingestValuationsFromCSV?: Maybe<CsvValuationImportResponse>;
  insertIntegrationConfiguration?: Maybe<IntegrationConfiguration>;
  /**
   * possibleTaskAssignees is a list of the users on the platform that are eligible to be a task assignee for this household.
   * We use Users rather than Employees because we expect that we will assign collaborators to tasks as well.
   */
  possibleTaskAssigneesV2: UserConnection;
  print: File;
  reInviteTenantUser: User;
  /**
   * Run AI suggestions for a document. This will associate the AsyncJob with
   * the DocumentID, for the DOCUMENT_INFERENCE_AI_SUGGESTION AJ kind.
   */
  runAISuggestions: AsyncJob;
  seedEntityWithAISuggestions: Entity;
  /**
   * AB trust with survivors from https://docs.google.com/spreadsheets/d/1z2CtRcCrNmlutDmXfMjEwDhrwTby6Vb4BzPV6lzvSz8/edit#gid=1047548555
   * This can not be used in prod.
   * Expect this endpoint to be deleted once we ship estate waterfall feature.
   */
  seedEstateWaterfall: Household;
  seedEstateWaterfallSimpleCharity: Household;
  /**
   * SimpleTrustWithBequests from https://docs.google.com/spreadsheets/d/1z2CtRcCrNmlutDmXfMjEwDhrwTby6Vb4BzPV6lzvSz8/edit#gid=1047548555
   * This can not be used in prod.
   * Expect this endpoint to be deleted once we ship estate waterfall feature.
   */
  seedEstateWaterfallSimpleTrustWithBequests: Household;
  seedSingleGrantor: Household;
  /**
   * sessionHeartbeat updates the session's expiration time.
   * only effective for tenants with inactivity timeouts configured, noop otherwise.
   */
  sessionHeartbeat: SessionHeartbeat;
  setFeatureFlagStatus: FeatureFlag;
  syncAddeparEntityValuations: Scalars['Boolean']['output'];
  updateAssetClass: AssetClass;
  updateAssetClasses: Array<Maybe<AssetClass>>;
  updateAssetValuationV2: AssetValuationV2;
  /**
   * Use this to update all assets from one class to a previous class.
   * Used to remove all assets from association with a given class before deleting it.
   */
  updateAssetsToNewClass: Scalars['Int']['output'];
  updateBalanceSheetCategories: Array<Maybe<BalanceSheetCategory>>;
  updateBalanceSheetCategory: BalanceSheetCategory;
  updateBalanceSheetViewConfiguration: BalanceSheetViewConfiguration;
  updateCashflow: Cashflow;
  updateClientOrganization: ClientOrganization;
  updateClientPresentation: ClientPresentation;
  updateClientProfile: ClientProfile;
  updateClientProfileRelationships: Array<Maybe<ClientProfileRelationship>>;
  updateClientProfiles: Array<Maybe<ClientProfile>>;
  updateCollaborator: Collaborator;
  updateDispositiveProvisionTemplate: DispositiveProvisionTemplate;
  updateDocument: Document;
  updateDocumentSummary: DocumentSummary;
  updateDocuments: Array<Document>;
  updateEmployee: Employee;
  updateEntities: Array<Maybe<Entity>>;
  updateEntity: Entity;
  updateEntityGraphView: EntityGraphView;
  updateEntityTask: EntityTask;
  updateEstateTaxScenario: EstateTaxScenario;
  updateEstateWaterfall: EstateWaterfall;
  updateEstateWaterfallVisualizationConfig: EstateWaterfallVisualizationConfig;
  updateEstateWaterfallVisualizationFilter: EstateWaterfallVisualizationFilter;
  updateFeatureFlagQueryRule: FeatureFlagQueryRule;
  updateFeatureFlagStatus: FeatureFlag;
  updateGiftingProposal: GiftingProposal;
  updateGiftingProposalScenario: GiftingProposalScenario;
  updateGraphVisualizationGroup: GraphVisualizationGroup;
  updateHousehold: Household;
  updateHouseholdOnboarding: HouseholdOnboarding;
  updateHouseholdOnboardingStep: HouseholdOnboardingStep;
  updateHouseholds: Array<Maybe<Household>>;
  updateHypotheticalWaterfallDispositiveProvisionTemplate: HypotheticalWaterfallDispositiveProvisionTemplate;
  updateIntegrationConfiguration?: Maybe<IntegrationConfiguration>;
  updateKgRoot: KgRoot;
  updateLiability: Liability;
  updateLifetimeExclusionEvent: LifetimeExclusionEvent;
  updateLoggedTransfer: LoggedTransfer;
  updateNotificationStatus: Scalars['Int']['output'];
  updateOwnershipStake: OwnershipStake;
  updateProfessionalTeamRole: ProfessionalTeamRole;
  updateProposalV2: Proposal;
  updateSSOConnection: SsoConnection;
  updateSubBrand: SubBrand;
  updateTeam: Team;
  updateTenant: Tenant;
  updateTenantApplicationConfiguration?: Maybe<TenantApplicationConfiguration>;
  updateTenantBranding: TenantBranding;
  updateTenantDisclaimerConfiguration: TenantDisclaimerConfiguration;
  updateTenantReminderEmailsConfiguration: TenantReminderEmailsConfiguration;
  updateTenantSSOConnection: TenantSsoConnection;
  updateTestamentaryEntity: TestamentaryEntity;
  updateUserNotificationConfiguration: UserNotificationConfiguration;
  uploadCSVImportFIle: FileUpload;
  uploadFile: FileUpload;
  uploadTenantBrandingLogo: TenantBrandingLogoUpload;
};


export type MutationDangerous_DeleteTestDataArgs = {
  endTime?: InputMaybe<Scalars['Time']['input']>;
  startTime?: InputMaybe<Scalars['Time']['input']>;
  tenantID: Scalars['ID']['input'];
};


export type MutationAcknowledgeAiSuggestionsArgs = {
  input: Array<AcknowledgeAiSuggestionInput>;
};


export type MutationAssignEntityTasksToUserArgs = {
  dueDate?: InputMaybe<Scalars['Time']['input']>;
  input: EntityTaskWhereInput;
  user?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationAsyncBackfillDocumentInferenceMetadataArgs = {
  where: DocumentWhereInput;
};


export type MutationAsyncDocumentOcrBackfillArgs = {
  skipExistingOCR?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationAsyncSummarizeDocumentsArgs = {
  input: SummarizeDocumentsInput;
};


export type MutationAutoGroupEstateWaterfallArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCancelEntityTaskArgs = {
  cancelledReason: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationCompleteEntityTaskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationConvertEntityArgs = {
  entityId: Scalars['ID']['input'];
};


export type MutationCopyArchetypeHouseholdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCopyHouseholdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateAssetClassArgs = {
  input: AugmentedCreateAssetClassInput;
};


export type MutationCreateAssetClassesArgs = {
  inputs: Array<InputMaybe<AugmentedCreateAssetClassInput>>;
};


export type MutationCreateAssetValuationV2Args = {
  input: AugmentedCreateAssetValuationV2Input;
};


export type MutationCreateBalanceSheetCategoriesArgs = {
  inputs: Array<InputMaybe<AugmentedCreateBalanceSheetCategoryInput>>;
};


export type MutationCreateBalanceSheetCategoryArgs = {
  input: AugmentedCreateBalanceSheetCategoryInput;
};


export type MutationCreateCashflowArgs = {
  input: AugmentedCreateCashflowInput;
};


export type MutationCreateClientOrganizationArgs = {
  input: AugmentedCreateClientOrganizationInput;
};


export type MutationCreateClientOrganizationsArgs = {
  input: Array<AugmentedCreateClientOrganizationInput>;
};


export type MutationCreateClientPresentationArgs = {
  input: AugmentedCreateClientPresentationInput;
};


export type MutationCreateClientProfileArgs = {
  input: CreateClientProfileWithDetailsInput;
};


export type MutationCreateClientProfilesArgs = {
  input: Array<CreateClientProfileWithDetailsInput>;
};


export type MutationCreateDispositiveProvisionTemplateArgs = {
  input: AugmentedCreateDispositiveProvisionTemplateInput;
};


export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput;
};


export type MutationCreateDocumentSharingTokenArgs = {
  documentID: Scalars['ID']['input'];
};


export type MutationCreateDocumentSummaryArgs = {
  documentID: Scalars['ID']['input'];
  input: CreateDocumentSummaryInput;
};


export type MutationCreateDocumentsArgs = {
  input: Array<CreateDocumentInput>;
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


export type MutationCreateEntitiesArgs = {
  input: Array<InputMaybe<AugmentedCreateEntityInput>>;
};


export type MutationCreateEntityArgs = {
  input: AugmentedCreateEntityInput;
};


export type MutationCreateEntityGraphViewArgs = {
  input: AugmentedCreateEntityGraphViewInput;
};


export type MutationCreateEstateTaxScenarioArgs = {
  input: AugmentedCreateEstateTaxScenarioInput;
};


export type MutationCreateEstateWaterfallArgs = {
  input: AugmentedCreateEstateWaterfallInput;
  opts?: InputMaybe<CreateEstateWaterfallOptsInput>;
};


export type MutationCreateEstateWaterfallVisualizationConfigArgs = {
  input: AugmentedCreateEstateWaterfallVisualizationConfigInput;
};


export type MutationCreateEstateWaterfallVisualizationFilterArgs = {
  input: AugmentedCreateEstateWaterfallVisualizationFilterInput;
};


export type MutationCreateGraphVisualizationGroupArgs = {
  input: AugmentedCreateGraphVisualizationGroupInput;
};


export type MutationCreateHouseholdArgs = {
  input: CreateHouseholdInput;
};


export type MutationCreateHouseholdOnboardingArgs = {
  input: AugmentedCreateHouseholdOnboardingInput;
};


export type MutationCreateHouseholdOnboardingStepArgs = {
  input: AugmentedCreateHouseholdOnboardingStepInput;
};


export type MutationCreateHouseholdsArgs = {
  inputs: Array<InputMaybe<AugmentedCreateHouseholdInput>>;
};


export type MutationCreateHypotheticalWaterfallDispositiveProvisionTemplateArgs = {
  input: AugmentedCreateHypotheticalWaterfallDispositiveProvisionTemplateInput;
};


export type MutationCreateIntegrationEntitiesForUploadedCsvArgs = {
  entityValuationsFileID: Scalars['ID']['input'];
};


export type MutationCreateKgRootArgs = {
  input: AugmentedCreateKgRootInput;
};


export type MutationCreateLiabilityArgs = {
  input: AugmentedCreateLiabilityInput;
};


export type MutationCreateLifetimeExclusionEventArgs = {
  input: AugmentedCreateLifetimeExclusionEventInput;
};


export type MutationCreateLoggedTransferArgs = {
  input: AugmentedCreateLoggedTransferInput;
};


export type MutationCreateOwnershipStakeArgs = {
  input: AugmentedCreateOwnershipStakeInput;
};


export type MutationCreateProfessionalTeamRoleArgs = {
  input: AugmentedCreateProfessionalTeamRoleInput;
};


export type MutationCreateProposalSharingTokenArgs = {
  proposalID: Scalars['ID']['input'];
};


export type MutationCreateProposalV2Args = {
  input: AugmentedCreateProposalInput;
};


export type MutationCreateSsoConnectionArgs = {
  input: AugmentedCreateSsoConnectionInput;
};


export type MutationCreateSubBrandArgs = {
  input: AugmentedCreateSubBrandInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};


export type MutationCreateTenantSsoConnectionArgs = {
  input: AugmentedCreateTenantSsoConnectionInput;
};


export type MutationCreateTenantUserv2Args = {
  input: ExtendedCreateUserInput;
};


export type MutationCreateTestamentaryEntitiesArgs = {
  input: Array<AugmentedCreateTestamentaryEntityInput>;
};


export type MutationCreateTestamentaryEntityArgs = {
  input: AugmentedCreateTestamentaryEntityInput;
};


export type MutationCreateUserNotificationConfigurationArgs = {
  input: AugmentedCreateUserNotificationConfigurationInput;
};


export type MutationDeleteAssetClassArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAssetValuationv2Args = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBalanceSheetCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCashflowsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationDeleteClientOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteClientPresentationArgs = {
  clientID: Scalars['ID']['input'];
  presentationID: Scalars['ID']['input'];
};


export type MutationDeleteClientProfileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCollaboratorArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDispositiveProvisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDispositiveProvisionTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDocumentsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationDeleteEntitiesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationDeleteEntityGraphViewArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEstateWaterfallArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteEstateWaterfallVisualizationFilterArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFeatureFlagQueryRuleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteGraphVisualizationGroupsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationDeleteHouseholdsArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type MutationDeleteLiabilityArgs = {
  liabilityId: Scalars['ID']['input'];
};


export type MutationDeleteLifetimeExclusionEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLoggedTransferArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProposalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSubBrandArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTenantArgs = {
  input: DeleteTenantInput;
};


export type MutationDeleteTenantSsoConnectionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTenantUserArgs = {
  email: Scalars['String']['input'];
};


export type MutationDeleteTestamentaryEntityArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDownloadBalanceSheetExcelArgs = {
  householdID: Scalars['ID']['input'];
};


export type MutationDuplicateEntityArgs = {
  entityId: Scalars['ID']['input'];
};


export type MutationDuplicateEstateWaterfallArgs = {
  input: DuplicateEstateWaterfallInput;
  opts?: InputMaybe<DuplicateEstateWaterfallOptsInput>;
};


export type MutationDuplicateProposalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDuplicateTestamentaryEntityArgs = {
  id: Scalars['ID']['input'];
};


export type MutationExportEntityReportArgs = {
  householdID: Scalars['ID']['input'];
  options: EntityReportOptions;
};


export type MutationExportHouseholdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationExportLifetimeExclusionEventsArgs = {
  householdID: Scalars['ID']['input'];
  where?: InputMaybe<LifetimeExclusionEventWhereInput>;
};


export type MutationGenerateGratDesignSummaryV2Args = {
  entityID: Scalars['ID']['input'];
};


export type MutationGenerateKnowledgeGraphForDocumentArgs = {
  documentId: Scalars['ID']['input'];
};


export type MutationGenerateKnowledgeGraphFromDocumentsArgs = {
  input: GenerateKnowledgeGraphInput;
};


export type MutationImportEntityAccountAssociationsArgs = {
  fileID: Scalars['ID']['input'];
};


export type MutationImportHouseholdArgs = {
  data: Scalars['String']['input'];
};


export type MutationIngestValuationsFromCsvArgs = {
  entityValuationsFileID: Scalars['ID']['input'];
};


export type MutationInsertIntegrationConfigurationArgs = {
  input: AugmentedCreateIntegrationConfigurationInput;
};


export type MutationPossibleTaskAssigneesV2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationPrintArgs = {
  input: PrintInput;
};


export type MutationReInviteTenantUserArgs = {
  email: Scalars['String']['input'];
};


export type MutationRunAiSuggestionsArgs = {
  documentID: Scalars['ID']['input'];
};


export type MutationSeedEntityWithAiSuggestionsArgs = {
  input: SeedEntityWithAiSuggestionsInput;
};


export type MutationSetFeatureFlagStatusArgs = {
  name: Scalars['String']['input'];
  status: FlagEnablementStatus;
};


export type MutationUpdateAssetClassArgs = {
  input: AugmentedUpdateAssetClassInput;
};


export type MutationUpdateAssetClassesArgs = {
  inputs: Array<InputMaybe<AugmentedUpdateAssetClassInput>>;
};


export type MutationUpdateAssetValuationV2Args = {
  input: AugmentedUpdateAssetValuationV2Input;
};


export type MutationUpdateAssetsToNewClassArgs = {
  newCategoryID: Scalars['ID']['input'];
  previousCategoryID: Scalars['ID']['input'];
};


export type MutationUpdateBalanceSheetCategoriesArgs = {
  inputs: Array<InputMaybe<AugmentedUpdateBalanceSheetCategoryInput>>;
};


export type MutationUpdateBalanceSheetCategoryArgs = {
  input: AugmentedUpdateBalanceSheetCategoryInput;
};


export type MutationUpdateBalanceSheetViewConfigurationArgs = {
  input: AugmentedUpdateBalanceSheetViewConfigurationInput;
};


export type MutationUpdateCashflowArgs = {
  input: AugmentedUpdateCashflowInput;
};


export type MutationUpdateClientOrganizationArgs = {
  input: AugmentedUpdateClientOrganizationInput;
};


export type MutationUpdateClientPresentationArgs = {
  input: AugmentedUpdateClientPresentationInput;
};


export type MutationUpdateClientProfileArgs = {
  id: Scalars['ID']['input'];
  input: UpdateClientProfileInput;
};


export type MutationUpdateClientProfileRelationshipsArgs = {
  householdId: Scalars['ID']['input'];
  updateRelationships?: InputMaybe<Array<UpdateClientProfileRelationship>>;
};


export type MutationUpdateClientProfilesArgs = {
  householdId: Scalars['ID']['input'];
  inputs: Array<InputMaybe<AugmentedUpdateClientProfileInput>>;
};


export type MutationUpdateCollaboratorArgs = {
  input: UpdateCollaboratorByIdInput;
};


export type MutationUpdateDispositiveProvisionTemplateArgs = {
  input: AugmentedUpdateDispositiveProvisionTemplateInput;
};


export type MutationUpdateDocumentArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDocumentInput;
};


export type MutationUpdateDocumentSummaryArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDocumentSummaryInput;
};


export type MutationUpdateDocumentsArgs = {
  input: Array<AugmentedUpdateDocumentInput>;
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};


export type MutationUpdateEntitiesArgs = {
  input: Array<InputMaybe<AugmentedUpdateEntityInput>>;
};


export type MutationUpdateEntityArgs = {
  input: AugmentedUpdateEntityInput;
};


export type MutationUpdateEntityGraphViewArgs = {
  input: AugmentedUpdateEntityGraphViewInput;
};


export type MutationUpdateEntityTaskArgs = {
  input: AugmentedUpdateEntityTaskInput;
};


export type MutationUpdateEstateTaxScenarioArgs = {
  input: AugmentedUpdateEstateTaxScenarioInput;
};


export type MutationUpdateEstateWaterfallArgs = {
  input: AugmentedUpdateEstateWaterfallInput;
};


export type MutationUpdateEstateWaterfallVisualizationConfigArgs = {
  input: AugmentedUpdateEstateWaterfallVisualizationConfigInput;
};


export type MutationUpdateEstateWaterfallVisualizationFilterArgs = {
  input: AugmentedUpdateEstateWaterfallVisualizationFilterInput;
};


export type MutationUpdateFeatureFlagQueryRuleArgs = {
  flagValue: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  tenantId: Scalars['ID']['input'];
};


export type MutationUpdateFeatureFlagStatusArgs = {
  input: UpdateFeatureFlagInput;
  name: Scalars['String']['input'];
};


export type MutationUpdateGiftingProposalArgs = {
  input: AugmentedUpdateGiftingProposalInput;
};


export type MutationUpdateGiftingProposalScenarioArgs = {
  input: AugmentedUpdateGiftingProposalScenarioInput;
};


export type MutationUpdateGraphVisualizationGroupArgs = {
  input: AugmentedUpdateGraphVisualizationGroupInput;
};


export type MutationUpdateHouseholdArgs = {
  id: Scalars['ID']['input'];
  input: UpdateHouseholdInput;
};


export type MutationUpdateHouseholdOnboardingArgs = {
  input: AugmentedUpdateHouseholdOnboardingInput;
};


export type MutationUpdateHouseholdOnboardingStepArgs = {
  input: AugmentedUpdateHouseholdOnboardingStepInput;
};


export type MutationUpdateHouseholdsArgs = {
  inputs: Array<InputMaybe<AugmentedUpdateHouseholdInput>>;
};


export type MutationUpdateHypotheticalWaterfallDispositiveProvisionTemplateArgs = {
  input: AugmentedUpdateHypotheticalWaterfallDispositiveProvisionTemplateInput;
};


export type MutationUpdateIntegrationConfigurationArgs = {
  input: AugmentedUpdateIntegrationConfigurationInput;
};


export type MutationUpdateKgRootArgs = {
  input: AugmentedUpdateKgRootInput;
};


export type MutationUpdateLiabilityArgs = {
  input: AugmentedUpdateLiabilityInput;
};


export type MutationUpdateLifetimeExclusionEventArgs = {
  input: AugmentedUpdateLifetimeExclusionEventInput;
};


export type MutationUpdateLoggedTransferArgs = {
  input: AugmentedUpdateLoggedTransferInput;
};


export type MutationUpdateNotificationStatusArgs = {
  status: NotificationStatus;
  where: NotificationWhereInput;
};


export type MutationUpdateOwnershipStakeArgs = {
  input: AugmentedUpdateOwnershipStakeInput;
};


export type MutationUpdateProfessionalTeamRoleArgs = {
  input: AugmentedUpdateProfessionalTeamRoleInput;
};


export type MutationUpdateProposalV2Args = {
  input: AugmentedUpdateProposalInput;
};


export type MutationUpdateSsoConnectionArgs = {
  input: AugmentedUpdateSsoConnectionInput;
};


export type MutationUpdateSubBrandArgs = {
  input: AugmentedUpdateSubBrandInput;
};


export type MutationUpdateTeamArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTeamInput;
};


export type MutationUpdateTenantArgs = {
  input: AugmentedUpdateTenantInput;
};


export type MutationUpdateTenantApplicationConfigurationArgs = {
  input: AugmentedUpdateTenantApplicationConfigurationInput;
};


export type MutationUpdateTenantBrandingArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTenantBrandingInput;
};


export type MutationUpdateTenantDisclaimerConfigurationArgs = {
  input: AugmentedUpdateTenantDisclaimerConfigurationInput;
};


export type MutationUpdateTenantReminderEmailsConfigurationArgs = {
  input: AugmentedUpdateTenantReminderEmailsConfigurationInput;
};


export type MutationUpdateTenantSsoConnectionArgs = {
  input: AugmentedUpdateTenantSsoConnectionInput;
};


export type MutationUpdateTestamentaryEntityArgs = {
  input: AugmentedUpdateTestamentaryEntityInput;
};


export type MutationUpdateUserNotificationConfigurationArgs = {
  input: AugmentedUpdateUserNotificationConfigurationInput;
};


export type MutationUploadCsvImportFIleArgs = {
  input: CreateFileInput;
};


export type MutationUploadFileArgs = {
  input: CreateFileInput;
};


export type MutationUploadTenantBrandingLogoArgs = {
  input: CreateTenantBrandingLogoInput;
};

export type Named = {
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type NoPlanProjection = {
  __typename?: 'NoPlanProjection';
  projectedValue: Scalars['CurrencyUSD']['output'];
  yearly: Array<NoPlanProjectionYearly>;
};

export type NoPlanProjectionInput = {
  assetValue: Scalars['CurrencyUSD']['input'];
  costBasis: Scalars['CurrencyUSD']['input'];
  growthRate: Scalars['Percent']['input'];
  ignoreCapitalGainsTax?: InputMaybe<Scalars['Boolean']['input']>;
  taxDrag: Scalars['Percent']['input'];
  termYears: Scalars['Int']['input'];
};

export type NoPlanProjectionYearly = {
  __typename?: 'NoPlanProjectionYearly';
  beginningOfYear: Scalars['CurrencyUSD']['output'];
  endOfYear: Scalars['CurrencyUSD']['output'];
  growth: Scalars['CurrencyUSD']['output'];
  year: Scalars['Int']['output'];
};

/**
 * An object with an ID.
 * Follows the [Relay Global Object Identification Specification](https://relay.dev/graphql/objectidentification.htm)
 */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID']['output'];
};

export type NodeStateInEstateStatus = {
  __typename?: 'NodeStateInEstateStatus';
  inEstateStatus: EntityInEstateStatus;
  nonTaxableValue?: Maybe<Scalars['CurrencyUSD']['output']>;
  state: StateCode;
  taxableValue?: Maybe<Scalars['CurrencyUSD']['output']>;
};

/** NonTrustEntityTaxStatus is enum for the field tax_status */
export enum NonTrustEntityTaxStatus {
  NonTaxable = 'NON_TAXABLE',
  Taxable = 'TAXABLE'
}

export type Notification = Node & {
  __typename?: 'Notification';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  kind: NotificationKind;
  status?: Maybe<NotificationStatus>;
  targetEntity?: Maybe<Entity>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updateUser: User;
  updatedAt: Scalars['Time']['output'];
  updatedBy: Scalars['ID']['output'];
  user: User;
};

/** A connection to a list of items. */
export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NotificationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Notification>;
};

/** NotificationKind is enum for the field kind */
export enum NotificationKind {
  EntityCreateDispositiveProvisions = 'ENTITY_CREATE_DISPOSITIVE_PROVISIONS',
  EntityCreateValuation = 'ENTITY_CREATE_VALUATION'
}

/** Ordering options for Notification connections */
export type NotificationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Notifications. */
  field: NotificationOrderField;
};

/** Properties by which Notification connections can be ordered. */
export enum NotificationOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** NotificationStatus is enum for the field status */
export enum NotificationStatus {
  Acknowledged = 'ACKNOWLEDGED',
  Dismissed = 'DISMISSED'
}

/**
 * NotificationWhereInput is used for filtering Notification objects.
 * Input was generated by ent.
 */
export type NotificationWhereInput = {
  and?: InputMaybe<Array<NotificationWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** target_entity edge predicates */
  hasTargetEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasTargetEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** update_user edge predicates */
  hasUpdateUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUpdateUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<NotificationKind>;
  kindIn?: InputMaybe<Array<NotificationKind>>;
  kindNEQ?: InputMaybe<NotificationKind>;
  kindNotIn?: InputMaybe<Array<NotificationKind>>;
  not?: InputMaybe<NotificationWhereInput>;
  or?: InputMaybe<Array<NotificationWhereInput>>;
  /** status field predicates */
  status?: InputMaybe<NotificationStatus>;
  statusIn?: InputMaybe<Array<NotificationStatus>>;
  statusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  statusNEQ?: InputMaybe<NotificationStatus>;
  statusNotIn?: InputMaybe<Array<NotificationStatus>>;
  statusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** updated_by field predicates */
  updatedBy?: InputMaybe<Scalars['ID']['input']>;
  updatedByContains?: InputMaybe<Scalars['ID']['input']>;
  updatedByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  updatedByGT?: InputMaybe<Scalars['ID']['input']>;
  updatedByGTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  updatedByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  updatedByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  updatedByLT?: InputMaybe<Scalars['ID']['input']>;
  updatedByLTE?: InputMaybe<Scalars['ID']['input']>;
  updatedByNEQ?: InputMaybe<Scalars['ID']['input']>;
  updatedByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type OnboardingEntitySuggestion = {
  __typename?: 'OnboardingEntitySuggestion';
  entityKind?: Maybe<EntityKind>;
  entityName?: Maybe<Scalars['String']['output']>;
  principalClientProfileIDs: Array<Scalars['ID']['output']>;
};

export type OnboardingIndividualSuggestion = {
  __typename?: 'OnboardingIndividualSuggestion';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  personalRelationships: Array<PersonalRelationshipSuggestion>;
  suffix?: Maybe<Scalars['String']['output']>;
};

export type OnboardingOrganizationSuggestion = {
  __typename?: 'OnboardingOrganizationSuggestion';
  organizationKind?: Maybe<ClientOrganizationKind>;
  organizationName?: Maybe<Scalars['String']['output']>;
};

export type OnboardingTestamentaryEntitySuggestion = {
  __typename?: 'OnboardingTestamentaryEntitySuggestion';
  testamentaryEntityKind?: Maybe<TestamentaryEntityKind>;
  testamentaryEntityName?: Maybe<Scalars['String']['output']>;
};

/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export enum OrderDirection {
  /** Specifies an ascending order for a given `orderBy` argument. */
  Asc = 'ASC',
  /** Specifies a descending order for a given `orderBy` argument. */
  Desc = 'DESC'
}

export type OtherTenant = {
  __typename?: 'OtherTenant';
  branding: TenantBranding;
  name: Scalars['String']['output'];
};

export type OwnershipStake = Node & {
  __typename?: 'OwnershipStake';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isGrantorControlled: Scalars['Boolean']['output'];
  /** The entity that this ownership stake is in. */
  ownedEntity?: Maybe<Entity>;
  ownedEntityID?: Maybe<Scalars['ID']['output']>;
  ownedValue: Scalars['CurrencyUSD']['output'];
  /** The percentage ownership of the underlying entity. */
  ownershipPercentage?: Maybe<Scalars['Percent']['output']>;
  /** The entity that owns this ownership stake. */
  owningEntity?: Maybe<Entity>;
  /** The individual that owns this ownership stake. */
  owningIndividual?: Maybe<ClientProfile>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** Ordering options for OwnershipStake connections */
export type OwnershipStakeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order OwnershipStakes. */
  field: OwnershipStakeOrderField;
};

/** Properties by which OwnershipStake connections can be ordered. */
export enum OwnershipStakeOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * OwnershipStakeWhereInput is used for filtering OwnershipStake objects.
 * Input was generated by ent.
 */
export type OwnershipStakeWhereInput = {
  and?: InputMaybe<Array<OwnershipStakeWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** owned_entity edge predicates */
  hasOwnedEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnedEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** owning_entity edge predicates */
  hasOwningEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwningEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** owning_individual edge predicates */
  hasOwningIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwningIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<OwnershipStakeWhereInput>;
  or?: InputMaybe<Array<OwnershipStakeWhereInput>>;
  /** owned_entity_id field predicates */
  ownedEntityID?: InputMaybe<Scalars['ID']['input']>;
  ownedEntityIDContains?: InputMaybe<Scalars['ID']['input']>;
  ownedEntityIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  ownedEntityIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  ownedEntityIDGT?: InputMaybe<Scalars['ID']['input']>;
  ownedEntityIDGTE?: InputMaybe<Scalars['ID']['input']>;
  ownedEntityIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  ownedEntityIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  ownedEntityIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  ownedEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownedEntityIDLT?: InputMaybe<Scalars['ID']['input']>;
  ownedEntityIDLTE?: InputMaybe<Scalars['ID']['input']>;
  ownedEntityIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  ownedEntityIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  ownedEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** ownership_percentage field predicates */
  ownershipPercentage?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageGT?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageGTE?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  ownershipPercentageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownershipPercentageLT?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageLTE?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageNEQ?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  ownershipPercentageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * Information about pagination in a connection.
 * https://relay.dev/graphql/connections.htm#sec-undefined.PageInfo
 */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export type PartialCreateCollaboratorInput = {
  firstName: Scalars['String']['input'];
  householdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The role of the collaborator, for display purposes only */
  kind?: InputMaybe<CollaboratorKind>;
  lastName: Scalars['String']['input'];
  /** The name of the organization this collaborator works for */
  organizationName?: InputMaybe<Scalars['String']['input']>;
  /** The professional title of the collaborator, e.g. 'Associate' */
  title?: InputMaybe<Scalars['String']['input']>;
};

export type PartialCreateEmployeeInput = {
  assignedHouseholdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  roleIDs: Array<Scalars['ID']['input']>;
};

export type PerformanceReport = {
  __typename?: 'PerformanceReport';
  grantsToCharity: Scalars['CurrencyUSD']['output'];
  wealthTransferred: Scalars['CurrencyUSD']['output'];
};

/**
 * PersonalAccount is a common interface for all
 * the accounts. It enforces some common
 * properties across all accounts.
 */
export type PersonalAccount = {
  accounts?: Maybe<Array<Account>>;
  addeparEntityID?: Maybe<Scalars['String']['output']>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  controllingParties?: Maybe<Array<ControllingParty>>;
  currentValue: Scalars['CurrencyUSD']['output'];
  description?: Maybe<Scalars['String']['output']>;
  designerAccount?: Maybe<Account>;
  displayName: Scalars['String']['output'];
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entityExists: Scalars['Boolean']['output'];
  gstStatus?: Maybe<EntityGstStatus>;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  taxStatus?: Maybe<NonTrustEntityTaxStatus>;
};

export type PersonalRelationshipSuggestion = {
  __typename?: 'PersonalRelationshipSuggestion';
  clientProfileID?: Maybe<Scalars['ID']['output']>;
  relationshipType?: Maybe<ClientProfileRelationshipType>;
};

export type PossibleBeneficiariesV2 = {
  __typename?: 'PossibleBeneficiariesV2';
  clients: Array<ClientProfile>;
  entities: Array<Entity>;
  organizations: Array<ClientOrganization>;
};

export type PossibleTrusteesV2 = {
  __typename?: 'PossibleTrusteesV2';
  clients: Array<ClientProfile>;
  organizations: Array<ClientOrganization>;
};

export type Principal = Node & {
  __typename?: 'Principal';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** An edge to the principal, which is an individual */
  individual?: Maybe<ClientProfile>;
  /**
   * The percentage ownership of the underlying entity, funded by these principals.
   * 			Typically only valid for multiple ownership, non-trust non-charitable entities (eg: LLC, LP, ...)
   */
  ownershipPercentage?: Maybe<Scalars['Percent']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** Ordering options for Principal connections */
export type PrincipalOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Principals. */
  field: PrincipalOrderField;
};

/** Properties by which Principal connections can be ordered. */
export enum PrincipalOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type PrincipalSuggestion = {
  __typename?: 'PrincipalSuggestion';
  firstName?: Maybe<Scalars['String']['output']>;
  individualID?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

/**
 * PrincipalWhereInput is used for filtering Principal objects.
 * Input was generated by ent.
 */
export type PrincipalWhereInput = {
  and?: InputMaybe<Array<PrincipalWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<PrincipalWhereInput>;
  or?: InputMaybe<Array<PrincipalWhereInput>>;
  /** ownership_percentage field predicates */
  ownershipPercentage?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageGT?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageGTE?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  ownershipPercentageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownershipPercentageLT?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageLTE?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageNEQ?: InputMaybe<Scalars['Percent']['input']>;
  ownershipPercentageNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  ownershipPercentageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type PrintInput = {
  householdID: Scalars['ID']['input'];
  relativePath: Scalars['String']['input'];
};

export type PrivateFoundation = EntitySubtype & Named & Node & {
  __typename?: 'PrivateFoundation';
  accounts?: Maybe<Array<Account>>;
  addeparEntityID?: Maybe<Scalars['String']['output']>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  controllingParties?: Maybe<Array<ControllingParty>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this personal account */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in entities that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  donors?: Maybe<Array<Principal>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  performanceReport: PerformanceReport;
  policies?: Maybe<Array<InsurancePolicy>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<NonTrustEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type PrivateFoundationHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type PrivateFoundationConnection = {
  __typename?: 'PrivateFoundationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PrivateFoundationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type PrivateFoundationEdge = {
  __typename?: 'PrivateFoundationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<PrivateFoundation>;
};

/** Ordering options for PrivateFoundation connections */
export type PrivateFoundationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order PrivateFoundations. */
  field: PrivateFoundationOrderField;
};

/** Properties by which PrivateFoundation connections can be ordered. */
export enum PrivateFoundationOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * PrivateFoundationWhereInput is used for filtering PrivateFoundation objects.
 * Input was generated by ent.
 */
export type PrivateFoundationWhereInput = {
  /** addepar_entity_id field predicates */
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContains?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparEntityIDLT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDLTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNEQ?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<PrivateFoundationWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** controlling_parties edge predicates */
  hasControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  hasControllingPartiesWith?: InputMaybe<Array<ControllingPartyWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** donors edge predicates */
  hasDonors?: InputMaybe<Scalars['Boolean']['input']>;
  hasDonorsWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<PrivateFoundationWhereInput>;
  or?: InputMaybe<Array<PrivateFoundationWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type ProfessionalTeamRole = Node & {
  __typename?: 'ProfessionalTeamRole';
  /** The client that this team member is associated with or a fiduciary of */
  associatedClientIndividual?: Maybe<ClientProfile>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  household?: Maybe<Household>;
  id: Scalars['ID']['output'];
  /** The client_profile that represents this professional team member */
  individual?: Maybe<ClientProfile>;
  kind: ProfessionalTeamRoleKind;
  /** If the kind is OTHER, this is the custom role name */
  otherRoleName?: Maybe<Scalars['String']['output']>;
  /** If the kind is POWER_OF_ATTORNEY, this is the type of power of attorney */
  powerOfAttorneyKind?: Maybe<ProfessionalTeamRolePowerOfAttorneyKind>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type ProfessionalTeamRoleConnection = {
  __typename?: 'ProfessionalTeamRoleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProfessionalTeamRoleEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProfessionalTeamRoleEdge = {
  __typename?: 'ProfessionalTeamRoleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ProfessionalTeamRole>;
};

/** ProfessionalTeamRoleKind is enum for the field kind */
export enum ProfessionalTeamRoleKind {
  AccountantCpa = 'ACCOUNTANT_CPA',
  Attorney = 'ATTORNEY',
  Bookkeeper = 'BOOKKEEPER',
  BusinessManager = 'BUSINESS_MANAGER',
  Conservator = 'CONSERVATOR',
  EstateExecutor = 'ESTATE_EXECUTOR',
  Guardian = 'GUARDIAN',
  HealthcareAgent = 'HEALTHCARE_AGENT',
  InsuranceAgent = 'INSURANCE_AGENT',
  Other = 'OTHER',
  Physician = 'PHYSICIAN',
  PowerOfAttorney = 'POWER_OF_ATTORNEY'
}

/** Ordering options for ProfessionalTeamRole connections */
export type ProfessionalTeamRoleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ProfessionalTeamRoles. */
  field: ProfessionalTeamRoleOrderField;
};

/** Properties by which ProfessionalTeamRole connections can be ordered. */
export enum ProfessionalTeamRoleOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** ProfessionalTeamRolePowerOfAttorneyKind is enum for the field power_of_attorney_kind */
export enum ProfessionalTeamRolePowerOfAttorneyKind {
  Durable = 'DURABLE',
  General = 'GENERAL',
  Limited = 'LIMITED'
}

/**
 * ProfessionalTeamRoleWhereInput is used for filtering ProfessionalTeamRole objects.
 * Input was generated by ent.
 */
export type ProfessionalTeamRoleWhereInput = {
  and?: InputMaybe<Array<ProfessionalTeamRoleWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** associated_client_individual edge predicates */
  hasAssociatedClientIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssociatedClientIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** individual edge predicates */
  hasIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<ProfessionalTeamRoleKind>;
  kindIn?: InputMaybe<Array<ProfessionalTeamRoleKind>>;
  kindNEQ?: InputMaybe<ProfessionalTeamRoleKind>;
  kindNotIn?: InputMaybe<Array<ProfessionalTeamRoleKind>>;
  not?: InputMaybe<ProfessionalTeamRoleWhereInput>;
  or?: InputMaybe<Array<ProfessionalTeamRoleWhereInput>>;
  /** other_role_name field predicates */
  otherRoleName?: InputMaybe<Scalars['String']['input']>;
  otherRoleNameContains?: InputMaybe<Scalars['String']['input']>;
  otherRoleNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  otherRoleNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  otherRoleNameGT?: InputMaybe<Scalars['String']['input']>;
  otherRoleNameGTE?: InputMaybe<Scalars['String']['input']>;
  otherRoleNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  otherRoleNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  otherRoleNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherRoleNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  otherRoleNameLT?: InputMaybe<Scalars['String']['input']>;
  otherRoleNameLTE?: InputMaybe<Scalars['String']['input']>;
  otherRoleNameNEQ?: InputMaybe<Scalars['String']['input']>;
  otherRoleNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  otherRoleNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** power_of_attorney_kind field predicates */
  powerOfAttorneyKind?: InputMaybe<ProfessionalTeamRolePowerOfAttorneyKind>;
  powerOfAttorneyKindIn?: InputMaybe<Array<ProfessionalTeamRolePowerOfAttorneyKind>>;
  powerOfAttorneyKindIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  powerOfAttorneyKindNEQ?: InputMaybe<ProfessionalTeamRolePowerOfAttorneyKind>;
  powerOfAttorneyKindNotIn?: InputMaybe<Array<ProfessionalTeamRolePowerOfAttorneyKind>>;
  powerOfAttorneyKindNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Projection = {
  __typename?: 'Projection';
  grantorRetainedInterest: Scalars['CurrencyUSD']['output'];
  initialFundingValue: Scalars['CurrencyUSD']['output'];
  rollingProjections: Array<Projection>;
  scenarioTableRows: Array<ScenarioTableRow>;
  taxableGiftAmount: Scalars['CurrencyUSD']['output'];
  totalAnnuityPayments: Scalars['CurrencyUSD']['output'];
  totalAssetValue: Scalars['CurrencyUSD']['output'];
  transferredOutOfEstate: Scalars['CurrencyUSD']['output'];
};

export type ProjectionParams = {
  annualAnnuityIncrease: Scalars['Percent']['input'];
  initialFundingValue: Scalars['CurrencyUSD']['input'];
  officialInterestRate: Scalars['Percent']['input'];
  projectedRateOfReturn: Scalars['Percent']['input'];
  rollingPeriodYears?: InputMaybe<Scalars['Int']['input']>;
  targetGift: Scalars['CurrencyUSD']['input'];
  termDurationYears: Scalars['Int']['input'];
};

export type Proposal = Node & {
  __typename?: 'Proposal';
  /** notes to the client that accompany the proposal */
  clientNotes: Scalars['String']['output'];
  /** The associated CLT proposal */
  cltProposal?: Maybe<CltProposal>;
  createdAt: Scalars['Time']['output'];
  /** The associated CRT proposal */
  crtProposal?: Maybe<CrtProposal>;
  /** the user-friendly name of the proposal */
  displayName: Scalars['String']['output'];
  /** The entities included in this proposal */
  entityProposals?: Maybe<Array<EntityProposal>>;
  /** The associated gifting proposal */
  giftingProposal?: Maybe<GiftingProposal>;
  household: Household;
  id: Scalars['ID']['output'];
  /** whether or not to include a "cumulative view" tab on the proposal */
  includeCumulativeView: Scalars['Boolean']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type ProposalConnection = {
  __typename?: 'ProposalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProposalEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProposalEdge = {
  __typename?: 'ProposalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Proposal>;
};

/** Ordering options for Proposal connections */
export type ProposalOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Proposals. */
  field: ProposalOrderField;
};

/** Properties by which Proposal connections can be ordered. */
export enum ProposalOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type ProposalScenario = Node & {
  __typename?: 'ProposalScenario';
  createdAt: Scalars['Time']['output'];
  gratProposalScenario?: Maybe<GratProposalScenario>;
  id: Scalars['ID']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type ProposalScenarioConnection = {
  __typename?: 'ProposalScenarioConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProposalScenarioEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProposalScenarioEdge = {
  __typename?: 'ProposalScenarioEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ProposalScenario>;
};

/** Ordering options for ProposalScenario connections */
export type ProposalScenarioOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ProposalScenarios. */
  field: ProposalScenarioOrderField;
};

/** Properties by which ProposalScenario connections can be ordered. */
export enum ProposalScenarioOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type ProposalScenarioProjection = {
  __typename?: 'ProposalScenarioProjection';
  noPlan: ProposalScenarioProjectionValue;
  withPlan: ProposalScenarioProjectionValue;
};

export type ProposalScenarioProjectionParamsV2 = {
  afterTermAnnualRateOfReturn: Scalars['Percent']['input'];
  endTermProjectedValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  entityID: Scalars['ID']['input'];
  inTermAnnualRateOfReturn?: InputMaybe<Scalars['Percent']['input']>;
  projectionTimelineYears: Scalars['Int']['input'];
  taxDragEstimate: Scalars['Percent']['input'];
};

export type ProposalScenarioProjectionResult = {
  __typename?: 'ProposalScenarioProjectionResult';
  postTax: ProposalScenarioProjection;
  preTax: ProposalScenarioProjection;
};

export type ProposalScenarioProjectionValue = {
  __typename?: 'ProposalScenarioProjectionValue';
  estateTax: Scalars['CurrencyUSD']['output'];
  inEstate: Scalars['CurrencyUSD']['output'];
  outOfEstate: Scalars['CurrencyUSD']['output'];
};

/**
 * ProposalScenarioWhereInput is used for filtering ProposalScenario objects.
 * Input was generated by ent.
 */
export type ProposalScenarioWhereInput = {
  and?: InputMaybe<Array<ProposalScenarioWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** grat_proposal_scenario edge predicates */
  hasGratProposalScenario?: InputMaybe<Scalars['Boolean']['input']>;
  hasGratProposalScenarioWith?: InputMaybe<Array<GratProposalScenarioWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ProposalScenarioWhereInput>;
  or?: InputMaybe<Array<ProposalScenarioWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * ProposalWhereInput is used for filtering Proposal objects.
 * Input was generated by ent.
 */
export type ProposalWhereInput = {
  and?: InputMaybe<Array<ProposalWhereInput>>;
  /** client_notes field predicates */
  clientNotes?: InputMaybe<Scalars['String']['input']>;
  clientNotesContains?: InputMaybe<Scalars['String']['input']>;
  clientNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  clientNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  clientNotesGT?: InputMaybe<Scalars['String']['input']>;
  clientNotesGTE?: InputMaybe<Scalars['String']['input']>;
  clientNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  clientNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  clientNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  clientNotesLT?: InputMaybe<Scalars['String']['input']>;
  clientNotesLTE?: InputMaybe<Scalars['String']['input']>;
  clientNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  clientNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** clt_proposal edge predicates */
  hasCltProposal?: InputMaybe<Scalars['Boolean']['input']>;
  hasCltProposalWith?: InputMaybe<Array<CltProposalWhereInput>>;
  /** crt_proposal edge predicates */
  hasCrtProposal?: InputMaybe<Scalars['Boolean']['input']>;
  hasCrtProposalWith?: InputMaybe<Array<CrtProposalWhereInput>>;
  /** entity_proposals edge predicates */
  hasEntityProposals?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityProposalsWith?: InputMaybe<Array<EntityProposalWhereInput>>;
  /** gifting_proposal edge predicates */
  hasGiftingProposal?: InputMaybe<Scalars['Boolean']['input']>;
  hasGiftingProposalWith?: InputMaybe<Array<GiftingProposalWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** include_cumulative_view field predicates */
  includeCumulativeView?: InputMaybe<Scalars['Boolean']['input']>;
  includeCumulativeViewNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<ProposalWhereInput>;
  or?: InputMaybe<Array<ProposalWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type QprtTrust = EntitySubtype & Named & Node & Trust & {
  __typename?: 'QPRTTrust';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this trust */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  grantors?: Maybe<Array<Principal>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  officialInterestRatePercent?: Maybe<Scalars['Percent']['output']>;
  successorTrustees?: Maybe<Array<Trustee>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<TrustTaxStatus>;
  /** The actual taxable gift that was recorded for this QPRT */
  taxableGift?: Maybe<Scalars['CurrencyUSD']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  termDurationYears?: Maybe<Scalars['Int']['output']>;
  termEndDate?: Maybe<Scalars['Time']['output']>;
  terminationDate?: Maybe<Scalars['Time']['output']>;
  trustAdvisors?: Maybe<Array<TrustAdvisor>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  trustees?: Maybe<Array<Trustee>>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type QprtTrustHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type QprtTrustConnection = {
  __typename?: 'QPRTTrustConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QprtTrustEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QprtTrustEdge = {
  __typename?: 'QPRTTrustEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<QprtTrust>;
};

/** Ordering options for QPRTTrust connections */
export type QprtTrustOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order QPRTTrusts. */
  field: QprtTrustOrderField;
};

/** Properties by which QPRTTrust connections can be ordered. */
export enum QprtTrustOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * QPRTTrustWhereInput is used for filtering QPRTTrust objects.
 * Input was generated by ent.
 */
export type QprtTrustWhereInput = {
  and?: InputMaybe<Array<QprtTrustWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** grantors edge predicates */
  hasGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantorsWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** successor_trustees edge predicates */
  hasSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuccessorTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** trust_advisors edge predicates */
  hasTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustAdvisorsWith?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** trustees edge predicates */
  hasTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<QprtTrustWhereInput>;
  /** official_interest_rate_percent field predicates */
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentGT?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentGTE?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  officialInterestRatePercentIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  officialInterestRatePercentLT?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentLTE?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentNEQ?: InputMaybe<Scalars['Percent']['input']>;
  officialInterestRatePercentNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  officialInterestRatePercentNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<QprtTrustWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<TrustTaxStatus>;
  taxStatusIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<TrustTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** taxable_gift field predicates */
  taxableGift?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  taxableGiftIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxableGiftLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  taxableGiftNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  taxableGiftNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** term_duration_years field predicates */
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsGTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termDurationYearsIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  termDurationYearsLT?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsLTE?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNEQ?: InputMaybe<Scalars['Int']['input']>;
  termDurationYearsNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  termDurationYearsNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** termination_date field predicates */
  terminationDate?: InputMaybe<Scalars['Time']['input']>;
  terminationDateGT?: InputMaybe<Scalars['Time']['input']>;
  terminationDateGTE?: InputMaybe<Scalars['Time']['input']>;
  terminationDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  terminationDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  terminationDateLT?: InputMaybe<Scalars['Time']['input']>;
  terminationDateLTE?: InputMaybe<Scalars['Time']['input']>;
  terminationDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  terminationDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  terminationDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** trust_jurisdiction_state_code field predicates */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  trustJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type QualifiedTuitionPersonalAccount = EntitySubtype & Named & Node & PersonalAccount & {
  __typename?: 'QualifiedTuitionPersonalAccount';
  accounts?: Maybe<Array<Account>>;
  addeparEntityID?: Maybe<Scalars['String']['output']>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  controllingParties?: Maybe<Array<ControllingParty>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this personal account */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in entities that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  owner?: Maybe<Principal>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<NonTrustEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type QualifiedTuitionPersonalAccountHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type QualifiedTuitionPersonalAccountConnection = {
  __typename?: 'QualifiedTuitionPersonalAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QualifiedTuitionPersonalAccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type QualifiedTuitionPersonalAccountEdge = {
  __typename?: 'QualifiedTuitionPersonalAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<QualifiedTuitionPersonalAccount>;
};

/** Ordering options for QualifiedTuitionPersonalAccount connections */
export type QualifiedTuitionPersonalAccountOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order QualifiedTuitionPersonalAccounts. */
  field: QualifiedTuitionPersonalAccountOrderField;
};

/** Properties by which QualifiedTuitionPersonalAccount connections can be ordered. */
export enum QualifiedTuitionPersonalAccountOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * QualifiedTuitionPersonalAccountWhereInput is used for filtering QualifiedTuitionPersonalAccount objects.
 * Input was generated by ent.
 */
export type QualifiedTuitionPersonalAccountWhereInput = {
  /** addepar_entity_id field predicates */
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContains?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparEntityIDLT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDLTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNEQ?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<QualifiedTuitionPersonalAccountWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** controlling_parties edge predicates */
  hasControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  hasControllingPartiesWith?: InputMaybe<Array<ControllingPartyWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** owner edge predicates */
  hasOwner?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnerWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<QualifiedTuitionPersonalAccountWhereInput>;
  or?: InputMaybe<Array<QualifiedTuitionPersonalAccountWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Query = {
  __typename?: 'Query';
  activityGroups: ActivityGroupConnection;
  applicationAuthorization: ApplicationAuthorization;
  archetypeHouseholds: HouseholdConnection;
  assetClasses: AssetClassConnection;
  /**
   * Returns a generated balance sheet data structure based on the specified household. When presentationConfiguration
   * is true, reads from a default BalanceSheetViewConfiguration rather than one that's configured for the household.
   */
  balanceSheet: BalanceSheet;
  balanceSheetCategories: BalanceSheetCategoryConnection;
  beneficiarySummariesv2: Array<Maybe<SummaryBeneficiaryv2>>;
  cashflows: CashflowConnection;
  clientOrganizations: ClientOrganizationConnection;
  clientPresentations: ClientPresentationConnection;
  clientProfiles: ClientProfileConnection;
  cltOptimization: CltOptimization;
  cltProposalProjection: CltProposalProjection;
  collaborators: CollaboratorConnection;
  crtOptimization: CrtOptimization;
  crtProposalProjection: CrtProposalProjection;
  currentUser?: Maybe<User>;
  dispositiveProvisionTemplates: DispositiveProvisionTemplateConnection;
  documents: DocumentConnection;
  employees: EmployeeConnection;
  entities: EntityConnection;
  entityGraphViews: EntityGraphViewConnection;
  entityTasks: EntityTaskConnection;
  estateTaxScenarioPossibleEntities: Array<Node>;
  estateTaxScenarios: EstateTaxScenarioConnection;
  estateWaterfalls: EstateWaterfallConnection;
  featureFlagEvaluationResult: Array<FeatureFlagEvaluationResult>;
  featureFlags: FeatureFlagConnection;
  getAddeparJobStatusAndResults: AddeparJobStatusAndResults;
  getCollaboratorUser?: Maybe<User>;
  getMostRecentDocumentAsyncJobs: Array<AsyncJob>;
  giftingProposalClientProjections: Array<GiftingProposalProjections>;
  globalSearch: GlobalSearchResult;
  /**
   * grantors is intended to be the literal definition of grantors, which is to say "client profiles who are eligible
   * or likely to put assets into trusts." This is distinct from being a "primary client".
   */
  grantors: Array<ClientProfile>;
  gratAnnuityV2s: GratAnnuityV2Connection;
  gratDesignSummaryDetails: GratDesignSummaryDetails;
  gratScenarioProjection: Projection;
  households: HouseholdConnection;
  /** Invokes the LLM to infer the DocumentType based on the input file name. */
  inferDocumentTypes: Array<InferDocumentTypeOutput>;
  integrationAssetClasses: IntegrationAssetClassConnection;
  integrationClients: IntegrationClientConnection;
  integrationConfiguration?: Maybe<IntegrationConfiguration>;
  integrationEntities: IntegrationEntityConnection;
  irsConstants: IrsConstants;
  isIntegrationUserLoggedInToAddepar: IsLoggedInToAddepar;
  isIntegrationUserLoggedInToBlackDiamond: IsLoggedIn;
  isUserLoggedInToAddepar: IsLoggedInToAddepar;
  isUserLoggedInToBlackDiamond: IsLoggedIn;
  kgRoots: KgRootConnection;
  liabilities: LiabilityConnection;
  liabilityPaymentCalculation: LiabilityPaymentCalculationDetails;
  lifetimeExclusionEvents: LifetimeExclusionEventConnection;
  loggedTransfers: LoggedTransferConnection;
  noPlanProjection: NoPlanProjection;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Lookup nodes by a list of IDs. */
  nodes: Array<Maybe<Node>>;
  notifications: NotificationConnection;
  npv: Scalars['CurrencyUSD']['output'];
  official7520InterestRate: InterestRateSummary;
  /** Get the total value of assets administered on the platform */
  platformAUM: Scalars['CurrencyUSD']['output'];
  possibleHypotheticalTransferEntities: EntityConnection;
  /**
   * possibleRelationshipOwners is a list of the employees on the platform that are eligible to be a relationship owner for an advisorClient
   * @deprecated Use possibleRelationshipOwnersV2 instead
   */
  possibleRelationshipOwners: Array<Maybe<Employee>>;
  possibleRelationshipOwnersV2: EmployeeConnection;
  proposalScenarioProjectionV2: ProposalScenarioProjectionResult;
  proposalYearProjectionOptionsV2: Array<YearProjectionOption>;
  proposals: ProposalConnection;
  queryBlackDiamondAccount: BlackDiamondQueryResults;
  queryBlackDiamondAccountDetails: BlackDiamondQueryResults;
  queryBlackDiamondPortfolio: BlackDiamondQueryResults;
  /**
   * Searches the document to find bounding regions that best answer the query,
   * and generates a response to the query synchronously.
   */
  queryDocument: QueryDocumentResultWithResponse;
  recentClients: Array<Household>;
  recentDocuments: Array<Document>;
  recentEntities: Array<Entity>;
  recentProposals: Array<Proposal>;
  reminderEmailNotificationRecords: ReminderEmailNotificationRecordConnection;
  roles: RoleConnection;
  searchHouseholds: HouseholdConnection;
  searchRelevantDocumentParagraphs: Array<SearchResult>;
  simulateDispositiveProvisions: SimulateDispositiveProvisions;
  ssoConnections: SsoConnectionConnection;
  subBrands: SubBrandConnection;
  suggestEntityDetailsFromText: BasicEntityDetailsSuggestion;
  teams: TeamConnection;
  tenantApplicationConfiguration?: Maybe<TenantApplicationConfiguration>;
  tenantInformation: TenantInformation;
  tenantSSOConnections: TenantSsoConnectionConnection;
  testamentaryEntities: TestamentaryEntityConnection;
  userNotificationConfigurations: UserNotificationConfigurationConnection;
};


export type QueryActivityGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first: Scalars['Int']['input'];
  where?: InputMaybe<ActivityWhereInput>;
};


export type QueryArchetypeHouseholdsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<HouseholdOrder>>;
  where?: InputMaybe<HouseholdWhereInput>;
};


export type QueryAssetClassesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AssetClassOrder>>;
  where?: InputMaybe<AssetClassWhereInput>;
};


export type QueryBalanceSheetArgs = {
  householdID: Scalars['ID']['input'];
  presentationConfiguration?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBalanceSheetCategoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BalanceSheetCategoryOrder>>;
  where?: InputMaybe<BalanceSheetCategoryWhereInput>;
};


export type QueryBeneficiarySummariesv2Args = {
  grantorClientProfileId: Scalars['ID']['input'];
  inputs: Array<BeneficiarySummaryQueryv2>;
};


export type QueryCashflowsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CashflowOrder>>;
  where?: InputMaybe<CashflowWhereInput>;
};


export type QueryClientOrganizationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientOrganizationOrder>>;
  where?: InputMaybe<ClientOrganizationWhereInput>;
};


export type QueryClientPresentationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientPresentationOrder>>;
  where?: InputMaybe<ClientPresentationWhereInput>;
};


export type QueryClientProfilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientProfileOrder>>;
  where?: InputMaybe<ClientProfileWhereInput>;
};


export type QueryCltOptimizationArgs = {
  input?: InputMaybe<CltOptimizationInput>;
};


export type QueryCltProposalProjectionArgs = {
  input: CltProposalProjectionInput;
};


export type QueryCollaboratorsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CollaboratorOrder>>;
  where?: InputMaybe<CollaboratorWhereInput>;
};


export type QueryCrtOptimizationArgs = {
  input?: InputMaybe<CrtOptimizationInput>;
};


export type QueryCrtProposalProjectionArgs = {
  input: CrtProposalProjectionInput;
};


export type QueryDispositiveProvisionTemplatesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositiveProvisionTemplateOrder>>;
  where?: InputMaybe<DispositiveProvisionTemplateWhereInput>;
};


export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DocumentOrder>>;
  where?: InputMaybe<DocumentWhereInput>;
};


export type QueryEmployeesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeOrder>>;
  where?: InputMaybe<EmployeeWhereInput>;
};


export type QueryEntitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityOrder>>;
  where?: InputMaybe<EntityWhereInput>;
};


export type QueryEntityGraphViewsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityGraphViewOrder>>;
  where?: InputMaybe<EntityGraphViewWhereInput>;
};


export type QueryEntityTasksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityTaskOrder>>;
  where?: InputMaybe<EntityTaskWhereInput>;
};


export type QueryEstateTaxScenarioPossibleEntitiesArgs = {
  input: EstateTaxScenarioPossibleEntitiesInput;
};


export type QueryEstateTaxScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EstateTaxScenarioOrder>>;
  where?: InputMaybe<EstateTaxScenarioWhereInput>;
};


export type QueryEstateWaterfallsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EstateWaterfallOrder>>;
  where?: InputMaybe<EstateWaterfallWhereInput>;
};


export type QueryFeatureFlagsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<FeatureFlagOrder>>;
  where?: InputMaybe<FeatureFlagWhereInput>;
};


export type QueryGetAddeparJobStatusAndResultsArgs = {
  jobID: Scalars['String']['input'];
};


export type QueryGetCollaboratorUserArgs = {
  email: Scalars['String']['input'];
};


export type QueryGetMostRecentDocumentAsyncJobsArgs = {
  kind: AsyncJobKind;
};


export type QueryGiftingProposalClientProjectionsArgs = {
  params: GiftingProposalClientProjectionParams;
};


export type QueryGlobalSearchArgs = {
  input: GlobalSearchInput;
};


export type QueryGrantorsArgs = {
  query: Scalars['String']['input'];
};


export type QueryGratAnnuityV2sArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GratAnnuityV2Order>>;
  where?: InputMaybe<GratAnnuityV2WhereInput>;
};


export type QueryGratDesignSummaryDetailsArgs = {
  entityID: Scalars['ID']['input'];
};


export type QueryGratScenarioProjectionArgs = {
  params: ProjectionParams;
};


export type QueryHouseholdsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<HouseholdOrder>>;
  where?: InputMaybe<HouseholdWhereInput>;
};


export type QueryInferDocumentTypesArgs = {
  input: Array<InferDocumentTypeInput>;
};


export type QueryIntegrationAssetClassesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<IntegrationAssetClassOrder>>;
  where?: InputMaybe<IntegrationAssetClassWhereInput>;
};


export type QueryIntegrationClientsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<IntegrationClientOrder>>;
  where?: InputMaybe<IntegrationClientWhereInput>;
};


export type QueryIntegrationEntitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<IntegrationEntityOrder>>;
  where?: InputMaybe<IntegrationEntityWhereInput>;
};


export type QueryIrsConstantsArgs = {
  at?: InputMaybe<Scalars['Time']['input']>;
};


export type QueryKgRootsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<KgRootOrder>>;
  where?: InputMaybe<KgRootWhereInput>;
};


export type QueryLiabilitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LiabilityOrder>>;
  where?: InputMaybe<LiabilityWhereInput>;
};


export type QueryLiabilityPaymentCalculationArgs = {
  params: LiabilityPaymentCalculationInput;
};


export type QueryLifetimeExclusionEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LifetimeExclusionEventOrder>>;
  where?: InputMaybe<LifetimeExclusionEventWhereInput>;
};


export type QueryLoggedTransfersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LoggedTransferOrder>>;
  where?: InputMaybe<LoggedTransferWhereInput>;
};


export type QueryNoPlanProjectionArgs = {
  input: NoPlanProjectionInput;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNodesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<NotificationOrder>>;
  where?: InputMaybe<NotificationWhereInput>;
};


export type QueryNpvArgs = {
  cashFlows: Array<Scalars['CurrencyUSD']['input']>;
  discountPercent: Scalars['Percent']['input'];
};


export type QueryPossibleHypotheticalTransferEntitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityOrder>>;
  where?: InputMaybe<EntityWhereInput>;
};


export type QueryPossibleRelationshipOwnersV2Args = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmployeeOrder>>;
  where?: InputMaybe<EmployeeWhereInput>;
};


export type QueryProposalScenarioProjectionV2Args = {
  input: ProposalScenarioProjectionParamsV2;
};


export type QueryProposalYearProjectionOptionsV2Args = {
  entityId: Scalars['ID']['input'];
};


export type QueryProposalsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProposalOrder>>;
  where?: InputMaybe<ProposalWhereInput>;
};


export type QueryQueryBlackDiamondAccountArgs = {
  accountID: Scalars['String']['input'];
};


export type QueryQueryBlackDiamondAccountDetailsArgs = {
  accountID: Scalars['String']['input'];
};


export type QueryQueryBlackDiamondPortfolioArgs = {
  portfolioID: Scalars['String']['input'];
};


export type QueryQueryDocumentArgs = {
  input: DocumentBoundingRegionQueryInput;
};


export type QueryRecentClientsArgs = {
  first: Scalars['Int']['input'];
};


export type QueryRecentDocumentsArgs = {
  first: Scalars['Int']['input'];
  where: RecentEntityWhereInput;
};


export type QueryRecentEntitiesArgs = {
  first: Scalars['Int']['input'];
  where: RecentEntityWhereInput;
};


export type QueryRecentProposalsArgs = {
  first: Scalars['Int']['input'];
  where: RecentEntityWhereInput;
};


export type QueryReminderEmailNotificationRecordsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReminderEmailNotificationRecordOrder>>;
  where?: InputMaybe<ReminderEmailNotificationRecordWhereInput>;
};


export type QueryRolesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RoleOrder>>;
  where?: InputMaybe<RoleWhereInput>;
};


export type QuerySearchHouseholdsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
  where?: InputMaybe<HouseholdWhereInput>;
};


export type QuerySearchRelevantDocumentParagraphsArgs = {
  input: SearchRelevantDocumentParagraphsInput;
};


export type QuerySimulateDispositiveProvisionsArgs = {
  input: SimulateDispositiveProvisionsInput;
};


export type QuerySsoConnectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SsoConnectionOrder>>;
  where?: InputMaybe<SsoConnectionWhereInput>;
};


export type QuerySubBrandsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SubBrandOrder>>;
  where?: InputMaybe<SubBrandWhereInput>;
};


export type QuerySuggestEntityDetailsFromTextArgs = {
  household: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};


export type QueryTeamsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TeamOrder>>;
  where?: InputMaybe<TeamWhereInput>;
};


export type QueryTenantSsoConnectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TenantSsoConnectionOrder>>;
  where?: InputMaybe<TenantSsoConnectionWhereInput>;
};


export type QueryTestamentaryEntitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestamentaryEntityOrder>>;
  where?: InputMaybe<TestamentaryEntityWhereInput>;
};


export type QueryUserNotificationConfigurationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserNotificationConfigurationOrder>>;
  where?: InputMaybe<UserNotificationConfigurationWhereInput>;
};

export type QueryDocumentResultWithResponse = {
  __typename?: 'QueryDocumentResultWithResponse';
  boundingRegions: Array<BoundingRegion>;
  response: Scalars['String']['output'];
};

export type RecentEntityWhereInput = {
  clientID?: InputMaybe<Scalars['ID']['input']>;
};

export type ReminderEmailNotificationRecord = Node & {
  __typename?: 'ReminderEmailNotificationRecord';
  createdAt: Scalars['Time']['output'];
  /** The entity that this email notification is associated with. */
  entities?: Maybe<Array<Entity>>;
  id: Scalars['ID']['output'];
  /** User readable name of the template used for the email. */
  notificationType: ReminderEmailNotificationRecordNotificationType;
  /** The user who received this email notification. */
  recipient: User;
  /** The external ID of the SendGrid email. This can be used to look up the email contents in SendGrid. */
  sendgridExternalID: Scalars['String']['output'];
  /** The template ID of the SendGrid template used for the email. */
  sendgridTemplateID: Scalars['String']['output'];
  /** The time at which this email notification was sent. */
  sentAt: Scalars['Time']['output'];
  /** The task(s) that this email notification is associated with. If this is null, then this email notification is not associated with any task. */
  tasks?: Maybe<Array<EntityTask>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type ReminderEmailNotificationRecordConnection = {
  __typename?: 'ReminderEmailNotificationRecordConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ReminderEmailNotificationRecordEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ReminderEmailNotificationRecordEdge = {
  __typename?: 'ReminderEmailNotificationRecordEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ReminderEmailNotificationRecord>;
};

/** ReminderEmailNotificationRecordNotificationType is enum for the field notification_type */
export enum ReminderEmailNotificationRecordNotificationType {
  AnnualGiftExclusionToIlit = 'ANNUAL_GIFT_EXCLUSION_TO_ILIT',
  AnnuityPaymentDue = 'ANNUITY_PAYMENT_DUE',
  AssignedTask = 'ASSIGNED_TASK',
  CrummeyLetterDue = 'CRUMMEY_LETTER_DUE',
  DistributeTrustRemainder = 'DISTRIBUTE_TRUST_REMAINDER',
  GratAssetSubstitutionThresholdReached = 'GRAT_ASSET_SUBSTITUTION_THRESHOLD_REACHED',
  InsurancePremiumDue = 'INSURANCE_PREMIUM_DUE',
  OverdueTaskReminder = 'OVERDUE_TASK_REMINDER',
  UnassignedTask = 'UNASSIGNED_TASK',
  UnassignedTaskReminder = 'UNASSIGNED_TASK_REMINDER'
}

/** Ordering options for ReminderEmailNotificationRecord connections */
export type ReminderEmailNotificationRecordOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ReminderEmailNotificationRecords. */
  field: ReminderEmailNotificationRecordOrderField;
};

/** Properties by which ReminderEmailNotificationRecord connections can be ordered. */
export enum ReminderEmailNotificationRecordOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * ReminderEmailNotificationRecordWhereInput is used for filtering ReminderEmailNotificationRecord objects.
 * Input was generated by ent.
 */
export type ReminderEmailNotificationRecordWhereInput = {
  and?: InputMaybe<Array<ReminderEmailNotificationRecordWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** entities edge predicates */
  hasEntities?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntitiesWith?: InputMaybe<Array<EntityWhereInput>>;
  /** recipient edge predicates */
  hasRecipient?: InputMaybe<Scalars['Boolean']['input']>;
  hasRecipientWith?: InputMaybe<Array<UserWhereInput>>;
  /** tasks edge predicates */
  hasTasks?: InputMaybe<Scalars['Boolean']['input']>;
  hasTasksWith?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<ReminderEmailNotificationRecordWhereInput>;
  /** notification_type field predicates */
  notificationType?: InputMaybe<ReminderEmailNotificationRecordNotificationType>;
  notificationTypeIn?: InputMaybe<Array<ReminderEmailNotificationRecordNotificationType>>;
  notificationTypeNEQ?: InputMaybe<ReminderEmailNotificationRecordNotificationType>;
  notificationTypeNotIn?: InputMaybe<Array<ReminderEmailNotificationRecordNotificationType>>;
  or?: InputMaybe<Array<ReminderEmailNotificationRecordWhereInput>>;
  /** sendgrid_external_id field predicates */
  sendgridExternalID?: InputMaybe<Scalars['String']['input']>;
  sendgridExternalIDContains?: InputMaybe<Scalars['String']['input']>;
  sendgridExternalIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  sendgridExternalIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  sendgridExternalIDGT?: InputMaybe<Scalars['String']['input']>;
  sendgridExternalIDGTE?: InputMaybe<Scalars['String']['input']>;
  sendgridExternalIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sendgridExternalIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sendgridExternalIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sendgridExternalIDLT?: InputMaybe<Scalars['String']['input']>;
  sendgridExternalIDLTE?: InputMaybe<Scalars['String']['input']>;
  sendgridExternalIDNEQ?: InputMaybe<Scalars['String']['input']>;
  sendgridExternalIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** sendgrid_template_id field predicates */
  sendgridTemplateID?: InputMaybe<Scalars['String']['input']>;
  sendgridTemplateIDContains?: InputMaybe<Scalars['String']['input']>;
  sendgridTemplateIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  sendgridTemplateIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  sendgridTemplateIDGT?: InputMaybe<Scalars['String']['input']>;
  sendgridTemplateIDGTE?: InputMaybe<Scalars['String']['input']>;
  sendgridTemplateIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  sendgridTemplateIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  sendgridTemplateIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  sendgridTemplateIDLT?: InputMaybe<Scalars['String']['input']>;
  sendgridTemplateIDLTE?: InputMaybe<Scalars['String']['input']>;
  sendgridTemplateIDNEQ?: InputMaybe<Scalars['String']['input']>;
  sendgridTemplateIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** sent_at field predicates */
  sentAt?: InputMaybe<Scalars['Time']['input']>;
  sentAtGT?: InputMaybe<Scalars['Time']['input']>;
  sentAtGTE?: InputMaybe<Scalars['Time']['input']>;
  sentAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  sentAtLT?: InputMaybe<Scalars['Time']['input']>;
  sentAtLTE?: InputMaybe<Scalars['Time']['input']>;
  sentAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  sentAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type RetirementPersonalAccount = EntitySubtype & Named & Node & PersonalAccount & {
  __typename?: 'RetirementPersonalAccount';
  accounts?: Maybe<Array<Account>>;
  addeparEntityID?: Maybe<Scalars['String']['output']>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  controllingParties?: Maybe<Array<ControllingParty>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this personal account */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in entities that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  owner?: Maybe<Principal>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<NonTrustEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  type?: Maybe<RetirementPersonalAccountType>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type RetirementPersonalAccountHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type RetirementPersonalAccountConnection = {
  __typename?: 'RetirementPersonalAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RetirementPersonalAccountEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RetirementPersonalAccountEdge = {
  __typename?: 'RetirementPersonalAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RetirementPersonalAccount>;
};

/** Ordering options for RetirementPersonalAccount connections */
export type RetirementPersonalAccountOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RetirementPersonalAccounts. */
  field: RetirementPersonalAccountOrderField;
};

/** Properties by which RetirementPersonalAccount connections can be ordered. */
export enum RetirementPersonalAccountOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** RetirementPersonalAccountType is enum for the field type */
export enum RetirementPersonalAccountType {
  DefinedBenefitPlan = 'DEFINED_BENEFIT_PLAN',
  InheritedIra = 'INHERITED_IRA',
  InheritedRothIra = 'INHERITED_ROTH_IRA',
  Ira = 'IRA',
  Other = 'OTHER',
  RothIra = 'ROTH_IRA',
  Type_401K = 'TYPE_401K'
}

/**
 * RetirementPersonalAccountWhereInput is used for filtering RetirementPersonalAccount objects.
 * Input was generated by ent.
 */
export type RetirementPersonalAccountWhereInput = {
  /** addepar_entity_id field predicates */
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContains?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDGTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  addeparEntityIDLT?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDLTE?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNEQ?: InputMaybe<Scalars['String']['input']>;
  addeparEntityIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  addeparEntityIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<RetirementPersonalAccountWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** controlling_parties edge predicates */
  hasControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  hasControllingPartiesWith?: InputMaybe<Array<ControllingPartyWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** owner edge predicates */
  hasOwner?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnerWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<RetirementPersonalAccountWhereInput>;
  or?: InputMaybe<Array<RetirementPersonalAccountWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** type field predicates */
  type?: InputMaybe<RetirementPersonalAccountType>;
  typeIn?: InputMaybe<Array<RetirementPersonalAccountType>>;
  typeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  typeNEQ?: InputMaybe<RetirementPersonalAccountType>;
  typeNotIn?: InputMaybe<Array<RetirementPersonalAccountType>>;
  typeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type RevocableTrust = EntitySubtype & Named & Node & Trust & {
  __typename?: 'RevocableTrust';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  beneficiaries?: Maybe<Array<Beneficiary>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this trust */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  grantors?: Maybe<Array<Principal>>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  policies?: Maybe<Array<InsurancePolicy>>;
  successorTrustees?: Maybe<Array<Trustee>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<TrustTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  trustAdvisors?: Maybe<Array<TrustAdvisor>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  trustees?: Maybe<Array<Trustee>>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type RevocableTrustHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type RevocableTrustConnection = {
  __typename?: 'RevocableTrustConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RevocableTrustEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RevocableTrustEdge = {
  __typename?: 'RevocableTrustEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<RevocableTrust>;
};

/** Ordering options for RevocableTrust connections */
export type RevocableTrustOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order RevocableTrusts. */
  field: RevocableTrustOrderField;
};

/** Properties by which RevocableTrust connections can be ordered. */
export enum RevocableTrustOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * RevocableTrustWhereInput is used for filtering RevocableTrust objects.
 * Input was generated by ent.
 */
export type RevocableTrustWhereInput = {
  and?: InputMaybe<Array<RevocableTrustWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** grantors edge predicates */
  hasGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantorsWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** successor_trustees edge predicates */
  hasSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuccessorTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** trust_advisors edge predicates */
  hasTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustAdvisorsWith?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** trustees edge predicates */
  hasTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<RevocableTrustWhereInput>;
  or?: InputMaybe<Array<RevocableTrustWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<TrustTaxStatus>;
  taxStatusIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<TrustTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** trust_jurisdiction_state_code field predicates */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  trustJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Role = Node & {
  __typename?: 'Role';
  /** The capabilities that this role has. */
  capabilities?: Maybe<Array<Capability>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  displayName: Scalars['String']['output'];
  /** The employees that have this role. */
  employees?: Maybe<Array<Employee>>;
  id: Scalars['ID']['output'];
  isDefault: Scalars['Boolean']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type RoleConnection = {
  __typename?: 'RoleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RoleEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RoleEdge = {
  __typename?: 'RoleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Role>;
};

/** Ordering options for Role connections */
export type RoleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Roles. */
  field: RoleOrderField;
};

/** Properties by which Role connections can be ordered. */
export enum RoleOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * RoleWhereInput is used for filtering Role objects.
 * Input was generated by ent.
 */
export type RoleWhereInput = {
  and?: InputMaybe<Array<RoleWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** capabilities edge predicates */
  hasCapabilities?: InputMaybe<Scalars['Boolean']['input']>;
  hasCapabilitiesWith?: InputMaybe<Array<CapabilityWhereInput>>;
  /** employees edge predicates */
  hasEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  hasEmployeesWith?: InputMaybe<Array<EmployeeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** is_default field predicates */
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  isDefaultNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<RoleWhereInput>;
  or?: InputMaybe<Array<RoleWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type SCorpBusinessEntity = BusinessEntity & EntitySubtype & Named & Node & {
  __typename?: 'SCorpBusinessEntity';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this business entity */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: Maybe<Scalars['String']['output']>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: Maybe<Scalars['String']['output']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  grantorControlledPercentage: Scalars['Percent']['output'];
  grantorControlledValue: Scalars['CurrencyUSD']['output'];
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  keyPeople?: Maybe<Array<KeyPerson>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: Maybe<Scalars['String']['output']>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  nongrantorControlledPercentage: Scalars['Percent']['output'];
  nongrantorControlledValue: Scalars['CurrencyUSD']['output'];
  /** Ownership as-of date. */
  ownershipAsOfDate?: Maybe<Scalars['Time']['output']>;
  policies?: Maybe<Array<InsurancePolicy>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: Maybe<Scalars['Boolean']['output']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxID?: Maybe<Scalars['String']['output']>;
  taxStatus?: Maybe<BusinessEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type SCorpBusinessEntityHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type SCorpBusinessEntityConnection = {
  __typename?: 'SCorpBusinessEntityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SCorpBusinessEntityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SCorpBusinessEntityEdge = {
  __typename?: 'SCorpBusinessEntityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SCorpBusinessEntity>;
};

/** Ordering options for SCorpBusinessEntity connections */
export type SCorpBusinessEntityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SCorpBusinessEntities. */
  field: SCorpBusinessEntityOrderField;
};

/** Properties by which SCorpBusinessEntity connections can be ordered. */
export enum SCorpBusinessEntityOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * SCorpBusinessEntityWhereInput is used for filtering SCorpBusinessEntity objects.
 * Input was generated by ent.
 */
export type SCorpBusinessEntityWhereInput = {
  and?: InputMaybe<Array<SCorpBusinessEntityWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** doing_business_as_name field predicates */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContains?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  doingBusinessAsNameLT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameLTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNEQ?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** fin_cen_id field predicates */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  finCenIDContains?: InputMaybe<Scalars['String']['input']>;
  finCenIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDGT?: InputMaybe<Scalars['String']['input']>;
  finCenIDGTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  finCenIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  finCenIDLT?: InputMaybe<Scalars['String']['input']>;
  finCenIDLTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDNEQ?: InputMaybe<Scalars['String']['input']>;
  finCenIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** formation_jurisdiction_state_code field predicates */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  formationJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** key_people edge predicates */
  hasKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  hasKeyPeopleWith?: InputMaybe<Array<KeyPersonWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** key_people_notes field predicates */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContains?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  keyPeopleNotesLT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesLTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<SCorpBusinessEntityWhereInput>;
  or?: InputMaybe<Array<SCorpBusinessEntityWhereInput>>;
  /** ownership_as_of_date field predicates */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownershipAsOfDateLT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateLTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requires_cta_reporting field predicates */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_id field predicates */
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxIDContains?: InputMaybe<Scalars['String']['input']>;
  taxIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  taxIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  taxIDGT?: InputMaybe<Scalars['String']['input']>;
  taxIDGTE?: InputMaybe<Scalars['String']['input']>;
  taxIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  taxIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  taxIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxIDLT?: InputMaybe<Scalars['String']['input']>;
  taxIDLTE?: InputMaybe<Scalars['String']['input']>;
  taxIDNEQ?: InputMaybe<Scalars['String']['input']>;
  taxIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type SlatTrust = EntitySubtype & Named & Node & Trust & {
  __typename?: 'SLATTrust';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this trust */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  /** Display name */
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  grantor?: Maybe<Principal>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: Maybe<EntityGstStatus>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  lifetimeBeneficiaries?: Maybe<Array<Beneficiary>>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  performanceReport: TrustPerformanceReport;
  policies?: Maybe<Array<InsurancePolicy>>;
  remainderBeneficiaries?: Maybe<Array<Beneficiary>>;
  successorTrustees?: Maybe<Array<Trustee>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxStatus?: Maybe<TrustTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  trustAdvisors?: Maybe<Array<TrustAdvisor>>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  trustees?: Maybe<Array<Trustee>>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type SlatTrustHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type SlatTrustConnection = {
  __typename?: 'SLATTrustConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SlatTrustEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SlatTrustEdge = {
  __typename?: 'SLATTrustEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SlatTrust>;
};

/** Ordering options for SLATTrust connections */
export type SlatTrustOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SLATTrusts. */
  field: SlatTrustOrderField;
};

/** Properties by which SLATTrust connections can be ordered. */
export enum SlatTrustOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * SLATTrustWhereInput is used for filtering SLATTrust objects.
 * Input was generated by ent.
 */
export type SlatTrustWhereInput = {
  and?: InputMaybe<Array<SlatTrustWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** grantor edge predicates */
  hasGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantorWith?: InputMaybe<Array<PrincipalWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** lifetime_beneficiaries edge predicates */
  hasLifetimeBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasLifetimeBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** policies edge predicates */
  hasPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  hasPoliciesWith?: InputMaybe<Array<InsurancePolicyWhereInput>>;
  /** remainder_beneficiaries edge predicates */
  hasRemainderBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  hasRemainderBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** successor_trustees edge predicates */
  hasSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuccessorTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** trust_advisors edge predicates */
  hasTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustAdvisorsWith?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** trustees edge predicates */
  hasTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<SlatTrustWhereInput>;
  or?: InputMaybe<Array<SlatTrustWhereInput>>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<TrustTaxStatus>;
  taxStatusIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<TrustTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<TrustTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** trust_jurisdiction_state_code field predicates */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  trustJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  trustJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  trustJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type SsoConnection = Node & {
  __typename?: 'SSOConnection';
  /** A list of email domains that are allowed to use this SSO connection. Do not include the @ symbol. */
  boundEmailDomains: Array<Scalars['String']['output']>;
  configuredTenants?: Maybe<Array<Tenant>>;
  createdAt: Scalars['Time']['output'];
  /** The name of the SSO connection. */
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  tenantSSOConnections?: Maybe<Array<TenantSsoConnection>>;
  updatedAt: Scalars['Time']['output'];
  /** The ID of the WorkOS organization this SSO connection corresponds to. */
  workosOrganizationID: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type SsoConnectionConnection = {
  __typename?: 'SSOConnectionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SsoConnectionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SsoConnectionEdge = {
  __typename?: 'SSOConnectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SsoConnection>;
};

/** Ordering options for SSOConnection connections */
export type SsoConnectionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SSOConnections. */
  field: SsoConnectionOrderField;
};

/** Properties by which SSOConnection connections can be ordered. */
export enum SsoConnectionOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * SSOConnectionWhereInput is used for filtering SSOConnection objects.
 * Input was generated by ent.
 */
export type SsoConnectionWhereInput = {
  and?: InputMaybe<Array<SsoConnectionWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** tenant_sso_connections edge predicates */
  hasTenantSSOConnections?: InputMaybe<Scalars['Boolean']['input']>;
  hasTenantSSOConnectionsWith?: InputMaybe<Array<TenantSsoConnectionWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<SsoConnectionWhereInput>;
  or?: InputMaybe<Array<SsoConnectionWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** workos_organization_id field predicates */
  workosOrganizationID?: InputMaybe<Scalars['String']['input']>;
  workosOrganizationIDContains?: InputMaybe<Scalars['String']['input']>;
  workosOrganizationIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  workosOrganizationIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  workosOrganizationIDGT?: InputMaybe<Scalars['String']['input']>;
  workosOrganizationIDGTE?: InputMaybe<Scalars['String']['input']>;
  workosOrganizationIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  workosOrganizationIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  workosOrganizationIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  workosOrganizationIDLT?: InputMaybe<Scalars['String']['input']>;
  workosOrganizationIDLTE?: InputMaybe<Scalars['String']['input']>;
  workosOrganizationIDNEQ?: InputMaybe<Scalars['String']['input']>;
  workosOrganizationIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ScenarioTableRow = {
  __typename?: 'ScenarioTableRow';
  annuityPayment: Scalars['CurrencyUSD']['output'];
  annuityPaymentPercentOfInitialFunding: Scalars['Percent']['output'];
  beginningPrincipal: Scalars['CurrencyUSD']['output'];
  principalGrowth: Scalars['CurrencyUSD']['output'];
  remainderValue: Scalars['CurrencyUSD']['output'];
  year: Scalars['Int']['output'];
};

export type ScheduledDistribution = Node & {
  __typename?: 'ScheduledDistribution';
  /** The end of the age requirement for this distribution */
  ageRequirementEnd?: Maybe<Scalars['Int']['output']>;
  /** The start of the age requirement for this distribution */
  ageRequirementStart?: Maybe<Scalars['Int']['output']>;
  /** The AMOUNT kind of distribution. Only valid when kind is AMOUNT */
  amount?: Maybe<Scalars['CurrencyUSD']['output']>;
  /** the beneficiary this scheduled distribution is for */
  beneficiary?: Maybe<Beneficiary>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** The frequency of the distribution to beneficiaries */
  frequency?: Maybe<ScheduledDistributionFrequency>;
  household?: Maybe<Household>;
  /** the household this object belongs to */
  householdID?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  /** The kind of scheduled distribution */
  kind: ScheduledDistributionKind;
  /** The percentage of the distribution. Only valid when kind is PERCENTAGE */
  percentage?: Maybe<Scalars['Percent']['output']>;
  /** The notes for the scheduled distribution */
  scheduledDistributionNotes?: Maybe<Scalars['String']['output']>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** A connection to a list of items. */
export type ScheduledDistributionConnection = {
  __typename?: 'ScheduledDistributionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ScheduledDistributionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ScheduledDistributionEdge = {
  __typename?: 'ScheduledDistributionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<ScheduledDistribution>;
};

/** ScheduledDistributionFrequency is enum for the field frequency */
export enum ScheduledDistributionFrequency {
  Annually = 'ANNUALLY',
  Monthly = 'MONTHLY',
  OneTime = 'ONE_TIME',
  Quarterly = 'QUARTERLY',
  Semiannually = 'SEMIANNUALLY'
}

/** ScheduledDistributionKind is enum for the field kind */
export enum ScheduledDistributionKind {
  AllIncome = 'ALL_INCOME',
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE'
}

/** Ordering options for ScheduledDistribution connections */
export type ScheduledDistributionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order ScheduledDistributions. */
  field: ScheduledDistributionOrderField;
};

/** Properties by which ScheduledDistribution connections can be ordered. */
export enum ScheduledDistributionOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * ScheduledDistributionWhereInput is used for filtering ScheduledDistribution objects.
 * Input was generated by ent.
 */
export type ScheduledDistributionWhereInput = {
  /** age_requirement_end field predicates */
  ageRequirementEnd?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndGT?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndGTE?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  ageRequirementEndIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ageRequirementEndLT?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndLTE?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndNEQ?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementEndNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  ageRequirementEndNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** age_requirement_start field predicates */
  ageRequirementStart?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartGT?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartGTE?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  ageRequirementStartIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ageRequirementStartLT?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartLTE?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartNEQ?: InputMaybe<Scalars['Int']['input']>;
  ageRequirementStartNotIn?: InputMaybe<Array<Scalars['Int']['input']>>;
  ageRequirementStartNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** amount field predicates */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountGT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountGTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  amountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  amountLT?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountLTE?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountNEQ?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  amountNotIn?: InputMaybe<Array<Scalars['CurrencyUSD']['input']>>;
  amountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<ScheduledDistributionWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** frequency field predicates */
  frequency?: InputMaybe<ScheduledDistributionFrequency>;
  frequencyIn?: InputMaybe<Array<ScheduledDistributionFrequency>>;
  frequencyIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  frequencyNEQ?: InputMaybe<ScheduledDistributionFrequency>;
  frequencyNotIn?: InputMaybe<Array<ScheduledDistributionFrequency>>;
  frequencyNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** beneficiary edge predicates */
  hasBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiaryWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** household_id field predicates */
  householdID?: InputMaybe<Scalars['ID']['input']>;
  householdIDContains?: InputMaybe<Scalars['ID']['input']>;
  householdIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDGT?: InputMaybe<Scalars['ID']['input']>;
  householdIDGTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  householdIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  householdIDLT?: InputMaybe<Scalars['ID']['input']>;
  householdIDLTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  householdIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<ScheduledDistributionKind>;
  kindIn?: InputMaybe<Array<ScheduledDistributionKind>>;
  kindNEQ?: InputMaybe<ScheduledDistributionKind>;
  kindNotIn?: InputMaybe<Array<ScheduledDistributionKind>>;
  not?: InputMaybe<ScheduledDistributionWhereInput>;
  or?: InputMaybe<Array<ScheduledDistributionWhereInput>>;
  /** percentage field predicates */
  percentage?: InputMaybe<Scalars['Percent']['input']>;
  percentageGT?: InputMaybe<Scalars['Percent']['input']>;
  percentageGTE?: InputMaybe<Scalars['Percent']['input']>;
  percentageIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  percentageIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  percentageLT?: InputMaybe<Scalars['Percent']['input']>;
  percentageLTE?: InputMaybe<Scalars['Percent']['input']>;
  percentageNEQ?: InputMaybe<Scalars['Percent']['input']>;
  percentageNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  percentageNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** scheduled_distribution_notes field predicates */
  scheduledDistributionNotes?: InputMaybe<Scalars['String']['input']>;
  scheduledDistributionNotesContains?: InputMaybe<Scalars['String']['input']>;
  scheduledDistributionNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  scheduledDistributionNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  scheduledDistributionNotesGT?: InputMaybe<Scalars['String']['input']>;
  scheduledDistributionNotesGTE?: InputMaybe<Scalars['String']['input']>;
  scheduledDistributionNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  scheduledDistributionNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  scheduledDistributionNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  scheduledDistributionNotesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  scheduledDistributionNotesLT?: InputMaybe<Scalars['String']['input']>;
  scheduledDistributionNotesLTE?: InputMaybe<Scalars['String']['input']>;
  scheduledDistributionNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  scheduledDistributionNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  scheduledDistributionNotesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type SearchRelevantDocumentParagraphsInput = {
  documentID: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  matchThreshold?: InputMaybe<Scalars['Float']['input']>;
  query: Scalars['String']['input'];
};

export type SearchResult = {
  __typename?: 'SearchResult';
  node: Node;
  nodes: Array<Node>;
  score: Scalars['Float']['output'];
};

export enum SearchableType {
  ClientOrganization = 'CLIENT_ORGANIZATION',
  ClientProfile = 'CLIENT_PROFILE',
  Document = 'DOCUMENT',
  Entity = 'ENTITY',
  Household = 'HOUSEHOLD',
  Proposal = 'PROPOSAL'
}

export type SeedEntityWithAiSuggestionsInput = {
  entityID: Scalars['ID']['input'];
};

/**
 * SessionHeartbeat is the response when a session is updated
 * via a heartbeat.
 */
export type SessionHeartbeat = {
  __typename?: 'SessionHeartbeat';
  /** inactiveAt is the time the session will be considered inactive. */
  inactiveAt?: Maybe<Scalars['Time']['output']>;
  /** notAfter is the maximum time the session is valid. */
  notAfter: Scalars['Time']['output'];
};

export type SharingToken = {
  __typename?: 'SharingToken';
  NotAfter: Scalars['Time']['output'];
  Token: Scalars['String']['output'];
  URL: Scalars['String']['output'];
};

export type SimulateDispositiveProvision = {
  __typename?: 'SimulateDispositiveProvision';
  transferAmount: Scalars['CurrencyUSD']['output'];
  transferAmountWasClipped: Scalars['Boolean']['output'];
};

export type SimulateDispositiveProvisionInput = {
  dispositionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  dispositionKind: DispositiveProvisionDispositionKind;
  dispositionPercentage?: InputMaybe<Scalars['Percent']['input']>;
};

export type SimulateDispositiveProvisions = {
  __typename?: 'SimulateDispositiveProvisions';
  firstDeath: Array<SimulateDispositiveProvision>;
  secondDeath: Array<SimulateDispositiveProvision>;
  valueRemainingAfterFirstDeath: Scalars['CurrencyUSD']['output'];
  valueRemainingAfterSecondDeath: Scalars['CurrencyUSD']['output'];
};

export type SimulateDispositiveProvisionsInput = {
  firstDeathClientProfile: Scalars['ID']['input'];
  /** Order matters! */
  firstDeathProvisions: Array<SimulateDispositiveProvisionInput>;
  secondDeathClientProfile?: InputMaybe<Scalars['ID']['input']>;
  /** Order matters! */
  secondDeathProvisions: Array<SimulateDispositiveProvisionInput>;
  valueToDistribute: Scalars['CurrencyUSD']['input'];
};

export type SoleProprietorshipBusinessEntity = BusinessEntity & EntitySubtype & Named & Node & {
  __typename?: 'SoleProprietorshipBusinessEntity';
  accounts?: Maybe<Array<Account>>;
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  currentValue: Scalars['CurrencyUSD']['output'];
  /** Description for this business entity */
  description?: Maybe<Scalars['String']['output']>;
  /** This is a "dummy" account that we use for modeling assets in trusts that aren't integrated with an asset provider like Addepar */
  designerAccount?: Maybe<Account>;
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: Maybe<Scalars['String']['output']>;
  /** Effective date. */
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  entity?: Maybe<Entity>;
  /** Whether the entity has already been created and is in effect */
  entityExists: Scalars['Boolean']['output'];
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: Maybe<Scalars['String']['output']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  grantorControlledPercentage: Scalars['Percent']['output'];
  grantorControlledValue: Scalars['CurrencyUSD']['output'];
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  id: Scalars['ID']['output'];
  keyPeople?: Maybe<Array<KeyPerson>>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: Maybe<Scalars['String']['output']>;
  /** Legal name */
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  mostRecentValuationDate?: Maybe<Scalars['Time']['output']>;
  nongrantorControlledPercentage: Scalars['Percent']['output'];
  nongrantorControlledValue: Scalars['CurrencyUSD']['output'];
  /** Ownership as-of date. */
  ownershipAsOfDate?: Maybe<Scalars['Time']['output']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: Maybe<Scalars['Boolean']['output']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: Maybe<Scalars['Percent']['output']>;
  taxID?: Maybe<Scalars['String']['output']>;
  taxStatus?: Maybe<BusinessEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type SoleProprietorshipBusinessEntityHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};

/** A connection to a list of items. */
export type SoleProprietorshipBusinessEntityConnection = {
  __typename?: 'SoleProprietorshipBusinessEntityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SoleProprietorshipBusinessEntityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SoleProprietorshipBusinessEntityEdge = {
  __typename?: 'SoleProprietorshipBusinessEntityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SoleProprietorshipBusinessEntity>;
};

/** Ordering options for SoleProprietorshipBusinessEntity connections */
export type SoleProprietorshipBusinessEntityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SoleProprietorshipBusinessEntities. */
  field: SoleProprietorshipBusinessEntityOrderField;
};

/** Properties by which SoleProprietorshipBusinessEntity connections can be ordered. */
export enum SoleProprietorshipBusinessEntityOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * SoleProprietorshipBusinessEntityWhereInput is used for filtering SoleProprietorshipBusinessEntity objects.
 * Input was generated by ent.
 */
export type SoleProprietorshipBusinessEntityWhereInput = {
  and?: InputMaybe<Array<SoleProprietorshipBusinessEntityWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** doing_business_as_name field predicates */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContains?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameGTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  doingBusinessAsNameLT?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameLTE?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNEQ?: InputMaybe<Scalars['String']['input']>;
  doingBusinessAsNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  doingBusinessAsNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** effective_date field predicates */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateGTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  effectiveDateLT?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateLTE?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  effectiveDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  effectiveDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** entity_exists field predicates */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityExistsNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** fin_cen_id field predicates */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  finCenIDContains?: InputMaybe<Scalars['String']['input']>;
  finCenIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  finCenIDGT?: InputMaybe<Scalars['String']['input']>;
  finCenIDGTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  finCenIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  finCenIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  finCenIDLT?: InputMaybe<Scalars['String']['input']>;
  finCenIDLTE?: InputMaybe<Scalars['String']['input']>;
  finCenIDNEQ?: InputMaybe<Scalars['String']['input']>;
  finCenIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  finCenIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** formation_jurisdiction_state_code field predicates */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContains?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeContainsFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeEqualFold?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeGTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasPrefix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeHasSuffix?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  formationJurisdictionStateCodeLT?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeLTE?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNEQ?: InputMaybe<Scalars['String']['input']>;
  formationJurisdictionStateCodeNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  formationJurisdictionStateCodeNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** accounts edge predicates */
  hasAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  hasAccountsWith?: InputMaybe<Array<AccountWhereInput>>;
  /** designer_account edge predicates */
  hasDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  hasDesignerAccountWith?: InputMaybe<Array<AccountWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** entity edge predicates */
  hasEntity?: InputMaybe<Scalars['Boolean']['input']>;
  hasEntityWith?: InputMaybe<Array<EntityWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** key_people edge predicates */
  hasKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  hasKeyPeopleWith?: InputMaybe<Array<KeyPersonWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** key_people_notes field predicates */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContains?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesContainsFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesEqualFold?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesGTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  keyPeopleNotesLT?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesLTE?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNEQ?: InputMaybe<Scalars['String']['input']>;
  keyPeopleNotesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  keyPeopleNotesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** legal_name field predicates */
  legalName?: InputMaybe<Scalars['String']['input']>;
  legalNameContains?: InputMaybe<Scalars['String']['input']>;
  legalNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  legalNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  legalNameGT?: InputMaybe<Scalars['String']['input']>;
  legalNameGTE?: InputMaybe<Scalars['String']['input']>;
  legalNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  legalNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  legalNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  legalNameLT?: InputMaybe<Scalars['String']['input']>;
  legalNameLTE?: InputMaybe<Scalars['String']['input']>;
  legalNameNEQ?: InputMaybe<Scalars['String']['input']>;
  legalNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  legalNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<SoleProprietorshipBusinessEntityWhereInput>;
  or?: InputMaybe<Array<SoleProprietorshipBusinessEntityWhereInput>>;
  /** ownership_as_of_date field predicates */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateGTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  ownershipAsOfDateLT?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateLTE?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNEQ?: InputMaybe<Scalars['Time']['input']>;
  ownershipAsOfDateNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  ownershipAsOfDateNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** requires_cta_reporting field predicates */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  requiresCtaReportingNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_discount field predicates */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountGTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxDiscountLT?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountLTE?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNEQ?: InputMaybe<Scalars['Percent']['input']>;
  taxDiscountNotIn?: InputMaybe<Array<Scalars['Percent']['input']>>;
  taxDiscountNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_id field predicates */
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxIDContains?: InputMaybe<Scalars['String']['input']>;
  taxIDContainsFold?: InputMaybe<Scalars['String']['input']>;
  taxIDEqualFold?: InputMaybe<Scalars['String']['input']>;
  taxIDGT?: InputMaybe<Scalars['String']['input']>;
  taxIDGTE?: InputMaybe<Scalars['String']['input']>;
  taxIDHasPrefix?: InputMaybe<Scalars['String']['input']>;
  taxIDHasSuffix?: InputMaybe<Scalars['String']['input']>;
  taxIDIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxIDLT?: InputMaybe<Scalars['String']['input']>;
  taxIDLTE?: InputMaybe<Scalars['String']['input']>;
  taxIDNEQ?: InputMaybe<Scalars['String']['input']>;
  taxIDNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  taxIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<BusinessEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<BusinessEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** StateCode is enum for the field state_code */
export enum StateCode {
  Ak = 'AK',
  Al = 'AL',
  Ar = 'AR',
  Az = 'AZ',
  Ca = 'CA',
  Co = 'CO',
  Ct = 'CT',
  Dc = 'DC',
  De = 'DE',
  Fl = 'FL',
  Ga = 'GA',
  Hi = 'HI',
  Ia = 'IA',
  Id = 'ID',
  Il = 'IL',
  In = 'IN',
  Ks = 'KS',
  Ky = 'KY',
  La = 'LA',
  Ma = 'MA',
  Md = 'MD',
  Me = 'ME',
  Mi = 'MI',
  Mn = 'MN',
  Mo = 'MO',
  Ms = 'MS',
  Mt = 'MT',
  Nc = 'NC',
  Nd = 'ND',
  Ne = 'NE',
  Nh = 'NH',
  Nj = 'NJ',
  Nm = 'NM',
  Nv = 'NV',
  Ny = 'NY',
  Oh = 'OH',
  Ok = 'OK',
  Or = 'OR',
  Pa = 'PA',
  Ri = 'RI',
  Sc = 'SC',
  Sd = 'SD',
  Tn = 'TN',
  Tx = 'TX',
  Ut = 'UT',
  Va = 'VA',
  Vt = 'VT',
  Wa = 'WA',
  Wi = 'WI',
  Wv = 'WV',
  Wy = 'WY'
}

export type SubBrand = Branded & Named & Node & {
  __typename?: 'SubBrand';
  buttonsLinkColor: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  darkSquareLogoURL?: Maybe<Scalars['String']['output']>;
  darkWideLogoURL?: Maybe<Scalars['String']['output']>;
  dataVisualizationNegativeColor?: Maybe<Scalars['String']['output']>;
  dataVisualizationPrimaryColor: Scalars['String']['output'];
  dataVisualizationSecondaryColor: Scalars['String']['output'];
  dataVisualizationTertiaryColor?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  households: HouseholdConnection;
  id: Scalars['ID']['output'];
  logoURL: Scalars['String']['output'];
  primaryColor: Scalars['String']['output'];
  secondaryColor: Scalars['String']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type SubBrandHouseholdsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<HouseholdOrder>>;
  where?: InputMaybe<HouseholdWhereInput>;
};

/** A connection to a list of items. */
export type SubBrandConnection = {
  __typename?: 'SubBrandConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubBrandEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SubBrandEdge = {
  __typename?: 'SubBrandEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<SubBrand>;
};

/** Ordering options for SubBrand connections */
export type SubBrandOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order SubBrands. */
  field: SubBrandOrderField;
};

/** Properties by which SubBrand connections can be ordered. */
export enum SubBrandOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * SubBrandWhereInput is used for filtering SubBrand objects.
 * Input was generated by ent.
 */
export type SubBrandWhereInput = {
  and?: InputMaybe<Array<SubBrandWhereInput>>;
  /** buttons_link_color field predicates */
  buttonsLinkColor?: InputMaybe<Scalars['String']['input']>;
  buttonsLinkColorContains?: InputMaybe<Scalars['String']['input']>;
  buttonsLinkColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  buttonsLinkColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  buttonsLinkColorGT?: InputMaybe<Scalars['String']['input']>;
  buttonsLinkColorGTE?: InputMaybe<Scalars['String']['input']>;
  buttonsLinkColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  buttonsLinkColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  buttonsLinkColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  buttonsLinkColorLT?: InputMaybe<Scalars['String']['input']>;
  buttonsLinkColorLTE?: InputMaybe<Scalars['String']['input']>;
  buttonsLinkColorNEQ?: InputMaybe<Scalars['String']['input']>;
  buttonsLinkColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** dark_square_logo_url field predicates */
  darkSquareLogoURL?: InputMaybe<Scalars['String']['input']>;
  darkSquareLogoURLContains?: InputMaybe<Scalars['String']['input']>;
  darkSquareLogoURLContainsFold?: InputMaybe<Scalars['String']['input']>;
  darkSquareLogoURLEqualFold?: InputMaybe<Scalars['String']['input']>;
  darkSquareLogoURLGT?: InputMaybe<Scalars['String']['input']>;
  darkSquareLogoURLGTE?: InputMaybe<Scalars['String']['input']>;
  darkSquareLogoURLHasPrefix?: InputMaybe<Scalars['String']['input']>;
  darkSquareLogoURLHasSuffix?: InputMaybe<Scalars['String']['input']>;
  darkSquareLogoURLIn?: InputMaybe<Array<Scalars['String']['input']>>;
  darkSquareLogoURLIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  darkSquareLogoURLLT?: InputMaybe<Scalars['String']['input']>;
  darkSquareLogoURLLTE?: InputMaybe<Scalars['String']['input']>;
  darkSquareLogoURLNEQ?: InputMaybe<Scalars['String']['input']>;
  darkSquareLogoURLNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  darkSquareLogoURLNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** dark_wide_logo_url field predicates */
  darkWideLogoURL?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURLContains?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURLContainsFold?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURLEqualFold?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURLGT?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURLGTE?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURLHasPrefix?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURLHasSuffix?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURLIn?: InputMaybe<Array<Scalars['String']['input']>>;
  darkWideLogoURLIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  darkWideLogoURLLT?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURLLTE?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURLNEQ?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURLNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  darkWideLogoURLNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** data_visualization_negative_color field predicates */
  dataVisualizationNegativeColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColorContains?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColorGT?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColorGTE?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  dataVisualizationNegativeColorIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dataVisualizationNegativeColorLT?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColorLTE?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColorNEQ?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  dataVisualizationNegativeColorNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** data_visualization_primary_color field predicates */
  dataVisualizationPrimaryColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColorContains?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColorGT?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColorGTE?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  dataVisualizationPrimaryColorLT?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColorLTE?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColorNEQ?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** data_visualization_secondary_color field predicates */
  dataVisualizationSecondaryColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColorContains?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColorGT?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColorGTE?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  dataVisualizationSecondaryColorLT?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColorLTE?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColorNEQ?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** data_visualization_tertiary_color field predicates */
  dataVisualizationTertiaryColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColorContains?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColorGT?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColorGTE?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  dataVisualizationTertiaryColorIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  dataVisualizationTertiaryColorLT?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColorLTE?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColorNEQ?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  dataVisualizationTertiaryColorNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** households edge predicates */
  hasHouseholds?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdsWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** logo_url field predicates */
  logoURL?: InputMaybe<Scalars['String']['input']>;
  logoURLContains?: InputMaybe<Scalars['String']['input']>;
  logoURLContainsFold?: InputMaybe<Scalars['String']['input']>;
  logoURLEqualFold?: InputMaybe<Scalars['String']['input']>;
  logoURLGT?: InputMaybe<Scalars['String']['input']>;
  logoURLGTE?: InputMaybe<Scalars['String']['input']>;
  logoURLHasPrefix?: InputMaybe<Scalars['String']['input']>;
  logoURLHasSuffix?: InputMaybe<Scalars['String']['input']>;
  logoURLIn?: InputMaybe<Array<Scalars['String']['input']>>;
  logoURLLT?: InputMaybe<Scalars['String']['input']>;
  logoURLLTE?: InputMaybe<Scalars['String']['input']>;
  logoURLNEQ?: InputMaybe<Scalars['String']['input']>;
  logoURLNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<SubBrandWhereInput>;
  or?: InputMaybe<Array<SubBrandWhereInput>>;
  /** primary_color field predicates */
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  primaryColorContains?: InputMaybe<Scalars['String']['input']>;
  primaryColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  primaryColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  primaryColorGT?: InputMaybe<Scalars['String']['input']>;
  primaryColorGTE?: InputMaybe<Scalars['String']['input']>;
  primaryColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  primaryColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  primaryColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  primaryColorLT?: InputMaybe<Scalars['String']['input']>;
  primaryColorLTE?: InputMaybe<Scalars['String']['input']>;
  primaryColorNEQ?: InputMaybe<Scalars['String']['input']>;
  primaryColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** secondary_color field predicates */
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
  secondaryColorContains?: InputMaybe<Scalars['String']['input']>;
  secondaryColorContainsFold?: InputMaybe<Scalars['String']['input']>;
  secondaryColorEqualFold?: InputMaybe<Scalars['String']['input']>;
  secondaryColorGT?: InputMaybe<Scalars['String']['input']>;
  secondaryColorGTE?: InputMaybe<Scalars['String']['input']>;
  secondaryColorHasPrefix?: InputMaybe<Scalars['String']['input']>;
  secondaryColorHasSuffix?: InputMaybe<Scalars['String']['input']>;
  secondaryColorIn?: InputMaybe<Array<Scalars['String']['input']>>;
  secondaryColorLT?: InputMaybe<Scalars['String']['input']>;
  secondaryColorLTE?: InputMaybe<Scalars['String']['input']>;
  secondaryColorNEQ?: InputMaybe<Scalars['String']['input']>;
  secondaryColorNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  household: Household;
};


export type SubscriptionHouseholdArgs = {
  id: Scalars['ID']['input'];
};

export type SummarizeDocumentsInput = {
  summaryEntryMode?: DocumentSummaryEntryMode;
  where: DocumentWhereInput;
};

export type SummaryAnnuityPaymentDetails = {
  __typename?: 'SummaryAnnuityPaymentDetails';
  label: Scalars['String']['output'];
  paymentPercentage: Scalars['String']['output'];
};

export type SummaryAsset = {
  __typename?: 'SummaryAsset';
  displayName: Scalars['String']['output'];
  qsbsDetails?: Maybe<Scalars['String']['output']>;
  shareCount?: Maybe<Scalars['String']['output']>;
  sharePrice?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SummaryBeneficiary = {
  __typename?: 'SummaryBeneficiary';
  additionalContext: Array<Maybe<Scalars['String']['output']>>;
  badgeText?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  displayType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  ownershipPercentage: Scalars['String']['output'];
};

export enum SummaryBeneficiaryKindv2 {
  ClientProfile = 'CLIENT_PROFILE',
  Entity = 'ENTITY',
  Organization = 'ORGANIZATION'
}

export type SummaryBeneficiaryv2 = {
  __typename?: 'SummaryBeneficiaryv2';
  additionalContext: Array<Maybe<Scalars['String']['output']>>;
  badgeText?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  displayType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  kind: SummaryBeneficiaryKindv2;
  ownershipPercentage: Scalars['String']['output'];
};

export type SummaryPerson = {
  __typename?: 'SummaryPerson';
  additionalContext?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  address: Scalars['String']['output'];
  badgeText?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  displayType?: Maybe<Scalars['String']['output']>;
};

export type TaxDetail = {
  __typename?: 'TaxDetail';
  _cacheKey: Scalars['String']['output'];
  deductions: Scalars['CurrencyUSD']['output'];
  exemptionAvailable: Scalars['CurrencyUSD']['output'];
  exemptionCliff?: Maybe<Scalars['CurrencyUSD']['output']>;
  exemptionUsed: Scalars['CurrencyUSD']['output'];
  portableExemption?: Maybe<Scalars['CurrencyUSD']['output']>;
  stateCode?: Maybe<StateCode>;
  stateDisclosure?: Maybe<Scalars['String']['output']>;
  tax: Scalars['CurrencyUSD']['output'];
  taxableAmount: Scalars['CurrencyUSD']['output'];
  taxableAmountAfterExemption: Scalars['CurrencyUSD']['output'];
  taxableAmountOverCliff?: Maybe<Scalars['Boolean']['output']>;
  taxableTransfers: Scalars['CurrencyUSD']['output'];
  totalExemption: Scalars['CurrencyUSD']['output'];
};

export type TaxSummary = {
  __typename?: 'TaxSummary';
  _cacheKey: Scalars['String']['output'];
  federalTax?: Maybe<TaxDetail>;
  stateTax?: Maybe<Array<TaxDetail>>;
};

export type Team = Node & {
  __typename?: 'Team';
  assignedHouseholds?: Maybe<Array<Household>>;
  createdAt: Scalars['Time']['output'];
  employees?: Maybe<Array<Employee>>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<Employee>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type TeamConnection = {
  __typename?: 'TeamConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TeamEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TeamEdge = {
  __typename?: 'TeamEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<Team>;
};

/** Ordering options for Team connections */
export type TeamOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Teams. */
  field: TeamOrderField;
};

/** Properties by which Team connections can be ordered. */
export enum TeamOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * TeamWhereInput is used for filtering Team objects.
 * Input was generated by ent.
 */
export type TeamWhereInput = {
  and?: InputMaybe<Array<TeamWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** assigned_households edge predicates */
  hasAssignedHouseholds?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssignedHouseholdsWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** employees edge predicates */
  hasEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  hasEmployeesWith?: InputMaybe<Array<EmployeeWhereInput>>;
  /** owner edge predicates */
  hasOwner?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnerWith?: InputMaybe<Array<EmployeeWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** name field predicates */
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  nameContainsFold?: InputMaybe<Scalars['String']['input']>;
  nameEqualFold?: InputMaybe<Scalars['String']['input']>;
  nameGT?: InputMaybe<Scalars['String']['input']>;
  nameGTE?: InputMaybe<Scalars['String']['input']>;
  nameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  nameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  nameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  nameLT?: InputMaybe<Scalars['String']['input']>;
  nameLTE?: InputMaybe<Scalars['String']['input']>;
  nameNEQ?: InputMaybe<Scalars['String']['input']>;
  nameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<TeamWhereInput>;
  or?: InputMaybe<Array<TeamWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type Tenant = Node & {
  __typename?: 'Tenant';
  branding?: Maybe<TenantBranding>;
  /** WARNING: FOR ANALYTICS/REPORTING ONLY. NOT FOR BUSINESS LOGIC. The date the contract started. */
  contractStartDate?: Maybe<Scalars['Time']['output']>;
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  kind: TenantKind;
  /** NOTE: Name is only used to initialize the tenant name. It is not used for display purposes. Use the TenantBranding.DisplayName property instead. */
  name: Scalars['String']['output'];
  /** These are sso_connections that are allowed to be used by this tenant. */
  ssoConnections?: Maybe<Array<SsoConnection>>;
  subdomain: Scalars['String']['output'];
  tenantSSOConnections?: Maybe<Array<TenantSsoConnection>>;
  updatedAt: Scalars['Time']['output'];
  /** WARNING: FOR ANALYTICS/REPORTING ONLY. NOT FOR BUSINESS LOGIC. This is the bucket that the tenant falls into for usage tracking. */
  usageBucket: TenantUsageBucket;
};

export type TenantApplicationConfiguration = Node & {
  __typename?: 'TenantApplicationConfiguration';
  createdAt: Scalars['Time']['output'];
  /** If true, disables all AI capabilities for this tenant. */
  disableAiCapabilities: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  /** User Sessions expire after a period of inactivity. This field controls the timeout period. */
  userSessionInactivityTimeout: TenantApplicationConfigurationUserSessionInactivityTimeout;
};

/** Ordering options for TenantApplicationConfiguration connections */
export type TenantApplicationConfigurationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order TenantApplicationConfigurations. */
  field: TenantApplicationConfigurationOrderField;
};

/** Properties by which TenantApplicationConfiguration connections can be ordered. */
export enum TenantApplicationConfigurationOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** TenantApplicationConfigurationUserSessionInactivityTimeout is enum for the field user_session_inactivity_timeout */
export enum TenantApplicationConfigurationUserSessionInactivityTimeout {
  FifteenMinutes = 'FIFTEEN_MINUTES',
  FiveSecondsForTesting = 'FIVE_SECONDS_FOR_TESTING',
  Off = 'OFF',
  OneHour = 'ONE_HOUR'
}

/**
 * TenantApplicationConfigurationWhereInput is used for filtering TenantApplicationConfiguration objects.
 * Input was generated by ent.
 */
export type TenantApplicationConfigurationWhereInput = {
  and?: InputMaybe<Array<TenantApplicationConfigurationWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** disable_ai_capabilities field predicates */
  disableAiCapabilities?: InputMaybe<Scalars['Boolean']['input']>;
  disableAiCapabilitiesNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<TenantApplicationConfigurationWhereInput>;
  or?: InputMaybe<Array<TenantApplicationConfigurationWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** user_session_inactivity_timeout field predicates */
  userSessionInactivityTimeout?: InputMaybe<TenantApplicationConfigurationUserSessionInactivityTimeout>;
  userSessionInactivityTimeoutIn?: InputMaybe<Array<TenantApplicationConfigurationUserSessionInactivityTimeout>>;
  userSessionInactivityTimeoutNEQ?: InputMaybe<TenantApplicationConfigurationUserSessionInactivityTimeout>;
  userSessionInactivityTimeoutNotIn?: InputMaybe<Array<TenantApplicationConfigurationUserSessionInactivityTimeout>>;
};

export type TenantBranding = Branded & Node & {
  __typename?: 'TenantBranding';
  buttonsLinkColor: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  darkSquareLogoURL?: Maybe<Scalars['String']['output']>;
  darkWideLogoURL?: Maybe<Scalars['String']['output']>;
  dataVisualizationNegativeColor?: Maybe<Scalars['String']['output']>;
  dataVisualizationPrimaryColor: Scalars['String']['output'];
  dataVisualizationSecondaryColor: Scalars['String']['output'];
  dataVisualizationTertiaryColor?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  logoURL: Scalars['String']['output'];
  primaryColor: Scalars['String']['output'];
  secondaryColor: Scalars['String']['output'];
  tenant: Tenant;
  updatedAt: Scalars['Time']['output'];
};

export type TenantBrandingLogoUpload = {
  __typename?: 'TenantBrandingLogoUpload';
  uploadMethod: Scalars['String']['output'];
  uploadURL: Scalars['String']['output'];
  uploadURLExpiresInSeconds: Scalars['Int']['output'];
};

export type TenantDisclaimerConfiguration = Node & {
  __typename?: 'TenantDisclaimerConfiguration';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** If true, include the default Luminary company disclosures in the presentation */
  includeLuminaryDisclaimer: Scalars['Boolean']['output'];
  presentationCoverDisclaimerBody?: Maybe<Scalars['String']['output']>;
  presentationCoverDisclaimerTitle?: Maybe<Scalars['String']['output']>;
  presentationFooterDisclaimerBody?: Maybe<Scalars['String']['output']>;
  presentationFooterDisclaimerTitle?: Maybe<Scalars['String']['output']>;
  presentationStandaloneDisclaimerBody?: Maybe<Scalars['String']['output']>;
  presentationStandaloneDisclaimerTitle?: Maybe<Scalars['String']['output']>;
  tenant: Tenant;
  updatedAt: Scalars['Time']['output'];
};

export type TenantInformation = {
  __typename?: 'TenantInformation';
  branding: TenantBranding;
  contractStartDate?: Maybe<Scalars['Time']['output']>;
  disclosuresConfiguration?: Maybe<TenantDisclaimerConfiguration>;
  id: Scalars['ID']['output'];
  kind: TenantKind;
  name: Scalars['String']['output'];
  otherTenantBranding?: Maybe<Array<Maybe<OtherTenant>>>;
  tenantSSOConnections: Array<TenantSsoConnection>;
  usageBucket: TenantUsageBucket;
};

/** TenantKind is enum for the field kind */
export enum TenantKind {
  NonProduction = 'NON_PRODUCTION',
  Production = 'PRODUCTION'
}

export type TenantReminderEmailsConfiguration = Node & {
  __typename?: 'TenantReminderEmailsConfiguration';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** If true, do NOT send periodic reminder emails for tasks. */
  reminderEmailsDisabled: Scalars['Boolean']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

export type TenantSsoConnection = Node & {
  __typename?: 'TenantSSOConnection';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** The time at which JIT was enabled for this tenant. If null, JIT is disabled. JIT means that users will be created on the fly when they sign in with SSO. */
  jitEnabledAt?: Maybe<Scalars['Time']['output']>;
  ssoConnection: SsoConnection;
  ssoConnectionID: Scalars['ID']['output'];
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type TenantSsoConnectionConnection = {
  __typename?: 'TenantSSOConnectionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TenantSsoConnectionEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TenantSsoConnectionEdge = {
  __typename?: 'TenantSSOConnectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TenantSsoConnection>;
};

/** Ordering options for TenantSSOConnection connections */
export type TenantSsoConnectionOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order TenantSSOConnections. */
  field: TenantSsoConnectionOrderField;
};

/** Properties by which TenantSSOConnection connections can be ordered. */
export enum TenantSsoConnectionOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * TenantSSOConnectionWhereInput is used for filtering TenantSSOConnection objects.
 * Input was generated by ent.
 */
export type TenantSsoConnectionWhereInput = {
  and?: InputMaybe<Array<TenantSsoConnectionWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** jit_enabled_at field predicates */
  jitEnabledAt?: InputMaybe<Scalars['Time']['input']>;
  jitEnabledAtGT?: InputMaybe<Scalars['Time']['input']>;
  jitEnabledAtGTE?: InputMaybe<Scalars['Time']['input']>;
  jitEnabledAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  jitEnabledAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  jitEnabledAtLT?: InputMaybe<Scalars['Time']['input']>;
  jitEnabledAtLTE?: InputMaybe<Scalars['Time']['input']>;
  jitEnabledAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  jitEnabledAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  jitEnabledAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<TenantSsoConnectionWhereInput>;
  or?: InputMaybe<Array<TenantSsoConnectionWhereInput>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** TenantUsageBucket is enum for the field usage_bucket */
export enum TenantUsageBucket {
  Internal = 'INTERNAL',
  Paid = 'PAID',
  Unpaid = 'UNPAID',
  UnpaidTrial = 'UNPAID_TRIAL'
}

export type TermDetailsInfo = {
  __typename?: 'TermDetailsInfo';
  display: Scalars['String']['output'];
  displayType: InfoMessageDisplayType;
};

export type TestamentaryEntity = Node & {
  __typename?: 'TestamentaryEntity';
  beneficiaries?: Maybe<Array<Beneficiary>>;
  /** This is only valid when the AC has 2 grantors */
  beneficiariesAfterBothGrantorDeaths?: Maybe<Array<Beneficiary>>;
  /** Dispositive provisions that benefit this testamentary entity. We need to be careful to filter out DPs bound to hypothetical waterfalls. */
  benefitsFrom?: Maybe<Array<DispositiveProvision>>;
  controllingParties?: Maybe<Array<ControllingParty>>;
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** Description for this testamentary entity */
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  dispositionScenarios?: Maybe<Array<DispositionScenario>>;
  dispositiveProvisions: DispositiveProvisionConnection;
  /** The dispositive provision template associated with this scenario. When present, dispositive_provisions will be ignored. */
  dispositiveProvisionsTemplate?: Maybe<DispositiveProvisionTemplate>;
  /** Testamentary entities are created when a grantor dies. This testamentary entity corresponds to this grantor's death specifically. */
  grantorDeath?: Maybe<ClientProfile>;
  gstStatus?: Maybe<EntityGstStatus>;
  household?: Maybe<Household>;
  /** the household this object belongs to */
  householdID?: Maybe<Scalars['ID']['output']>;
  hypotheticalDispositionScenarios: DispositionScenarioConnection;
  hypotheticalDispositiveProvisions: DispositiveProvisionConnection;
  hypotheticalDispositiveProvisionsTemplates: HypotheticalWaterfallDispositiveProvisionTemplateConnection;
  id: Scalars['ID']['output'];
  inEstateStatus?: Maybe<EntityInEstateStatus>;
  /** Type of testamentary entity. */
  kind: TestamentaryEntityKind;
  /** The time at which the scenario was last reviewed. This can be used to indicate that a user has looked at these provisions and they are correct, even if they are empty. */
  reviewedAt?: Maybe<Scalars['Time']['output']>;
  /** Testamentary entity state tax elections */
  stateTax?: Maybe<EntityStateTax>;
  successorTrustees?: Maybe<Array<Trustee>>;
  survivingSpouseStateInEstateStatus?: Maybe<EntityInEstateStatus>;
  taxStatus?: Maybe<NonTrustEntityTaxStatus>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  trustAdvisors?: Maybe<Array<TrustAdvisor>>;
  trustees?: Maybe<Array<Trustee>>;
  updatedAt: Scalars['Time']['output'];
  user: User;
};


export type TestamentaryEntityDispositiveProvisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositiveProvisionOrder>>;
  where?: InputMaybe<DispositiveProvisionWhereInput>;
};


export type TestamentaryEntityHypotheticalDispositionScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositionScenarioOrder>>;
  where?: InputMaybe<DispositionScenarioWhereInput>;
};


export type TestamentaryEntityHypotheticalDispositiveProvisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DispositiveProvisionOrder>>;
  where?: InputMaybe<DispositiveProvisionWhereInput>;
};


export type TestamentaryEntityHypotheticalDispositiveProvisionsTemplatesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<HypotheticalWaterfallDispositiveProvisionTemplateOrder>>;
  where?: InputMaybe<HypotheticalWaterfallDispositiveProvisionTemplateWhereInput>;
};

/** A connection to a list of items. */
export type TestamentaryEntityConnection = {
  __typename?: 'TestamentaryEntityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TestamentaryEntityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TestamentaryEntityEdge = {
  __typename?: 'TestamentaryEntityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<TestamentaryEntity>;
};

/** TestamentaryEntityKind is enum for the field kind */
export enum TestamentaryEntityKind {
  CharitableEntity = 'CHARITABLE_ENTITY',
  CharitableTrust = 'CHARITABLE_TRUST',
  GrantorEstate = 'GRANTOR_ESTATE',
  InheritedRetirementAccount = 'INHERITED_RETIREMENT_ACCOUNT',
  TestamentaryTrust = 'TESTAMENTARY_TRUST'
}

/** Ordering options for TestamentaryEntity connections */
export type TestamentaryEntityOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order TestamentaryEntities. */
  field: TestamentaryEntityOrderField;
};

/** Properties by which TestamentaryEntity connections can be ordered. */
export enum TestamentaryEntityOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * TestamentaryEntityWhereInput is used for filtering TestamentaryEntity objects.
 * Input was generated by ent.
 */
export type TestamentaryEntityWhereInput = {
  and?: InputMaybe<Array<TestamentaryEntityWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** description field predicates */
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionContains?: InputMaybe<Scalars['String']['input']>;
  descriptionContainsFold?: InputMaybe<Scalars['String']['input']>;
  descriptionEqualFold?: InputMaybe<Scalars['String']['input']>;
  descriptionGT?: InputMaybe<Scalars['String']['input']>;
  descriptionGTE?: InputMaybe<Scalars['String']['input']>;
  descriptionHasPrefix?: InputMaybe<Scalars['String']['input']>;
  descriptionHasSuffix?: InputMaybe<Scalars['String']['input']>;
  descriptionIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  descriptionLT?: InputMaybe<Scalars['String']['input']>;
  descriptionLTE?: InputMaybe<Scalars['String']['input']>;
  descriptionNEQ?: InputMaybe<Scalars['String']['input']>;
  descriptionNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  descriptionNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** display_name field predicates */
  displayName?: InputMaybe<Scalars['String']['input']>;
  displayNameContains?: InputMaybe<Scalars['String']['input']>;
  displayNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  displayNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  displayNameGT?: InputMaybe<Scalars['String']['input']>;
  displayNameGTE?: InputMaybe<Scalars['String']['input']>;
  displayNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  displayNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  displayNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  displayNameLT?: InputMaybe<Scalars['String']['input']>;
  displayNameLTE?: InputMaybe<Scalars['String']['input']>;
  displayNameNEQ?: InputMaybe<Scalars['String']['input']>;
  displayNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** gst_status field predicates */
  gstStatus?: InputMaybe<EntityGstStatus>;
  gstStatusIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  gstStatusNEQ?: InputMaybe<EntityGstStatus>;
  gstStatusNotIn?: InputMaybe<Array<EntityGstStatus>>;
  gstStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** beneficiaries edge predicates */
  hasBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  /** beneficiaries_after_both_grantor_deaths edge predicates */
  hasBeneficiariesAfterBothGrantorDeaths?: InputMaybe<Scalars['Boolean']['input']>;
  hasBeneficiariesAfterBothGrantorDeathsWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  hasBeneficiariesWith?: InputMaybe<Array<BeneficiaryWhereInput>>;
  /** benefits_from edge predicates */
  hasBenefitsFrom?: InputMaybe<Scalars['Boolean']['input']>;
  hasBenefitsFromWith?: InputMaybe<Array<DispositiveProvisionWhereInput>>;
  /** controlling_parties edge predicates */
  hasControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  hasControllingPartiesWith?: InputMaybe<Array<ControllingPartyWhereInput>>;
  /** disposition_scenarios edge predicates */
  hasDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** dispositive_provisions edge predicates */
  hasDispositiveProvisions?: InputMaybe<Scalars['Boolean']['input']>;
  /** dispositive_provisions_template edge predicates */
  hasDispositiveProvisionsTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  hasDispositiveProvisionsTemplateWith?: InputMaybe<Array<DispositiveProvisionTemplateWhereInput>>;
  hasDispositiveProvisionsWith?: InputMaybe<Array<DispositiveProvisionWhereInput>>;
  /** grantor_death edge predicates */
  hasGrantorDeath?: InputMaybe<Scalars['Boolean']['input']>;
  hasGrantorDeathWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** household edge predicates */
  hasHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  hasHouseholdWith?: InputMaybe<Array<HouseholdWhereInput>>;
  /** hypothetical_disposition_scenarios edge predicates */
  hasHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositionScenariosWith?: InputMaybe<Array<DispositionScenarioWhereInput>>;
  /** hypothetical_dispositive_provisions edge predicates */
  hasHypotheticalDispositiveProvisions?: InputMaybe<Scalars['Boolean']['input']>;
  /** hypothetical_dispositive_provisions_templates edge predicates */
  hasHypotheticalDispositiveProvisionsTemplates?: InputMaybe<Scalars['Boolean']['input']>;
  hasHypotheticalDispositiveProvisionsTemplatesWith?: InputMaybe<Array<HypotheticalWaterfallDispositiveProvisionTemplateWhereInput>>;
  hasHypotheticalDispositiveProvisionsWith?: InputMaybe<Array<DispositiveProvisionWhereInput>>;
  /** state_tax edge predicates */
  hasStateTax?: InputMaybe<Scalars['Boolean']['input']>;
  hasStateTaxWith?: InputMaybe<Array<EntityStateTaxWhereInput>>;
  /** successor_trustees edge predicates */
  hasSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasSuccessorTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** trust_advisors edge predicates */
  hasTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustAdvisorsWith?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** trustees edge predicates */
  hasTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrusteesWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** household_id field predicates */
  householdID?: InputMaybe<Scalars['ID']['input']>;
  householdIDContains?: InputMaybe<Scalars['ID']['input']>;
  householdIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  householdIDGT?: InputMaybe<Scalars['ID']['input']>;
  householdIDGTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  householdIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  householdIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  householdIDLT?: InputMaybe<Scalars['ID']['input']>;
  householdIDLTE?: InputMaybe<Scalars['ID']['input']>;
  householdIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  householdIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  householdIDNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** in_estate_status field predicates */
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  inEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  inEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  inEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** kind field predicates */
  kind?: InputMaybe<TestamentaryEntityKind>;
  kindIn?: InputMaybe<Array<TestamentaryEntityKind>>;
  kindNEQ?: InputMaybe<TestamentaryEntityKind>;
  kindNotIn?: InputMaybe<Array<TestamentaryEntityKind>>;
  not?: InputMaybe<TestamentaryEntityWhereInput>;
  or?: InputMaybe<Array<TestamentaryEntityWhereInput>>;
  /** reviewed_at field predicates */
  reviewedAt?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtGT?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  reviewedAtIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  reviewedAtLT?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  reviewedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  reviewedAtNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** surviving_spouse_state_in_estate_status field predicates */
  survivingSpouseStateInEstateStatus?: InputMaybe<EntityInEstateStatus>;
  survivingSpouseStateInEstateStatusIn?: InputMaybe<Array<EntityInEstateStatus>>;
  survivingSpouseStateInEstateStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  survivingSpouseStateInEstateStatusNEQ?: InputMaybe<EntityInEstateStatus>;
  survivingSpouseStateInEstateStatusNotIn?: InputMaybe<Array<EntityInEstateStatus>>;
  survivingSpouseStateInEstateStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tax_status field predicates */
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  taxStatusNEQ?: InputMaybe<NonTrustEntityTaxStatus>;
  taxStatusNotIn?: InputMaybe<Array<NonTrustEntityTaxStatus>>;
  taxStatusNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * Trust is a common interface for all
 * the trusts. It enforces some common
 * properties across all trusts.
 */
export type Trust = {
  assetSummaryDisplay?: Maybe<Scalars['String']['output']>;
  currentValue: Scalars['CurrencyUSD']['output'];
  description?: Maybe<Scalars['String']['output']>;
  designerAccount?: Maybe<Account>;
  displayName: Scalars['String']['output'];
  effectiveDate?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  legalName?: Maybe<Scalars['String']['output']>;
  mostRecentValuation?: Maybe<AssetValuationV2>;
  successorTrustees?: Maybe<Array<Trustee>>;
  trustAdvisors?: Maybe<Array<TrustAdvisor>>;
  trustJurisdictionStateCode?: Maybe<Scalars['String']['output']>;
  trustees?: Maybe<Array<Trustee>>;
};

export type TrustAdvisor = Node & {
  __typename?: 'TrustAdvisor';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** An edge to the trust advisor, which is an individual */
  individual?: Maybe<ClientProfile>;
  /** The level of this trust advisor. */
  level: TrustAdvisorLevel;
  /** A Note about this trust advisor. Only relevant if the level is "OTHER". */
  note?: Maybe<Scalars['String']['output']>;
  /** An edge to the trustee, which is an organization */
  organization?: Maybe<ClientOrganization>;
  /** Roles of this trust advisor. */
  roles?: Maybe<Array<TrustAdvisorRole>>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** TrustAdvisorLevel is enum for the field level */
export enum TrustAdvisorLevel {
  Other = 'OTHER',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY'
}

/** Ordering options for TrustAdvisor connections */
export type TrustAdvisorOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order TrustAdvisors. */
  field: TrustAdvisorOrderField;
};

/** Properties by which TrustAdvisor connections can be ordered. */
export enum TrustAdvisorOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

export type TrustAdvisorRole = Node & {
  __typename?: 'TrustAdvisorRole';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** The role of this trust advisor. */
  kind: TrustAdvisorRoleKind;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** Trust advisor that this role belongs to. */
  trustAdvisor: TrustAdvisor;
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** TrustAdvisorRoleKind is enum for the field kind */
export enum TrustAdvisorRoleKind {
  DistributionAdvisor = 'DISTRIBUTION_ADVISOR',
  InvestmentAdvisor = 'INVESTMENT_ADVISOR',
  Other = 'OTHER',
  TrustProtector = 'TRUST_PROTECTOR'
}

/** Ordering options for TrustAdvisorRole connections */
export type TrustAdvisorRoleOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order TrustAdvisorRoles. */
  field: TrustAdvisorRoleOrderField;
};

/** Properties by which TrustAdvisorRole connections can be ordered. */
export enum TrustAdvisorRoleOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * TrustAdvisorRoleWhereInput is used for filtering TrustAdvisorRole objects.
 * Input was generated by ent.
 */
export type TrustAdvisorRoleWhereInput = {
  and?: InputMaybe<Array<TrustAdvisorRoleWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** trust_advisor edge predicates */
  hasTrustAdvisor?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrustAdvisorWith?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<TrustAdvisorRoleKind>;
  kindIn?: InputMaybe<Array<TrustAdvisorRoleKind>>;
  kindNEQ?: InputMaybe<TrustAdvisorRoleKind>;
  kindNotIn?: InputMaybe<Array<TrustAdvisorRoleKind>>;
  not?: InputMaybe<TrustAdvisorRoleWhereInput>;
  or?: InputMaybe<Array<TrustAdvisorRoleWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * TrustAdvisorWhereInput is used for filtering TrustAdvisor objects.
 * Input was generated by ent.
 */
export type TrustAdvisorWhereInput = {
  and?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** individual edge predicates */
  hasIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** organization edge predicates */
  hasOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** roles edge predicates */
  hasRoles?: InputMaybe<Scalars['Boolean']['input']>;
  hasRolesWith?: InputMaybe<Array<TrustAdvisorRoleWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** level field predicates */
  level?: InputMaybe<TrustAdvisorLevel>;
  levelIn?: InputMaybe<Array<TrustAdvisorLevel>>;
  levelNEQ?: InputMaybe<TrustAdvisorLevel>;
  levelNotIn?: InputMaybe<Array<TrustAdvisorLevel>>;
  not?: InputMaybe<TrustAdvisorWhereInput>;
  /** note field predicates */
  note?: InputMaybe<Scalars['String']['input']>;
  noteContains?: InputMaybe<Scalars['String']['input']>;
  noteContainsFold?: InputMaybe<Scalars['String']['input']>;
  noteEqualFold?: InputMaybe<Scalars['String']['input']>;
  noteGT?: InputMaybe<Scalars['String']['input']>;
  noteGTE?: InputMaybe<Scalars['String']['input']>;
  noteHasPrefix?: InputMaybe<Scalars['String']['input']>;
  noteHasSuffix?: InputMaybe<Scalars['String']['input']>;
  noteIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  noteLT?: InputMaybe<Scalars['String']['input']>;
  noteLTE?: InputMaybe<Scalars['String']['input']>;
  noteNEQ?: InputMaybe<Scalars['String']['input']>;
  noteNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  noteNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<TrustAdvisorWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type TrustPerformanceReport = {
  __typename?: 'TrustPerformanceReport';
  distributions: Scalars['CurrencyUSD']['output'];
  impliedEstateTaxSavings: Scalars['CurrencyUSD']['output'];
  outOfEstateGrowth: Scalars['CurrencyUSD']['output'];
  taxableGifts: Scalars['CurrencyUSD']['output'];
  transfers: Scalars['CurrencyUSD']['output'];
  wealthTransferred: Scalars['CurrencyUSD']['output'];
};

/** TrustTaxStatus is enum for the field tax_status */
export enum TrustTaxStatus {
  GrantorTrust = 'GRANTOR_TRUST',
  NonGrantorTrust = 'NON_GRANTOR_TRUST',
  NonTaxableTrust = 'NON_TAXABLE_TRUST'
}

export type Trustee = Node & {
  __typename?: 'Trustee';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  /** Duties of this trustee */
  duties?: Maybe<Array<TrusteeDuty>>;
  id: Scalars['ID']['output'];
  /** An edge to the trustee, which is an individual */
  individual?: Maybe<ClientProfile>;
  level?: Maybe<TrusteeLevel>;
  notes?: Maybe<Scalars['String']['output']>;
  /** An edge to the trustee, which is an organization */
  organization?: Maybe<ClientOrganization>;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  trusteeCategory: TrusteeTrusteeCategory;
  updatedAt: Scalars['Time']['output'];
  user: User;
};

export type TrusteeDuty = Node & {
  __typename?: 'TrusteeDuty';
  createdAt: Scalars['Time']['output'];
  createdBy: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  /** The kind of duty for this trustee */
  kind: TrusteeDutyKind;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  /** Trustee that this duty belongs to */
  trustee: Trustee;
  updatedAt: Scalars['Time']['output'];
  user: User;
};

/** TrusteeDutyKind is enum for the field kind */
export enum TrusteeDutyKind {
  Administration = 'ADMINISTRATION',
  Distribution = 'DISTRIBUTION',
  Investment = 'INVESTMENT',
  Special = 'SPECIAL'
}

/** Ordering options for TrusteeDuty connections */
export type TrusteeDutyOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order TrusteeDuties. */
  field: TrusteeDutyOrderField;
};

/** Properties by which TrusteeDuty connections can be ordered. */
export enum TrusteeDutyOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * TrusteeDutyWhereInput is used for filtering TrusteeDuty objects.
 * Input was generated by ent.
 */
export type TrusteeDutyWhereInput = {
  and?: InputMaybe<Array<TrusteeDutyWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** trustee edge predicates */
  hasTrustee?: InputMaybe<Scalars['Boolean']['input']>;
  hasTrusteeWith?: InputMaybe<Array<TrusteeWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** kind field predicates */
  kind?: InputMaybe<TrusteeDutyKind>;
  kindIn?: InputMaybe<Array<TrusteeDutyKind>>;
  kindNEQ?: InputMaybe<TrusteeDutyKind>;
  kindNotIn?: InputMaybe<Array<TrusteeDutyKind>>;
  not?: InputMaybe<TrusteeDutyWhereInput>;
  or?: InputMaybe<Array<TrusteeDutyWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** TrusteeLevel is enum for the field level */
export enum TrusteeLevel {
  Other = 'OTHER',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY'
}

/** Ordering options for Trustee connections */
export type TrusteeOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Trustees. */
  field: TrusteeOrderField;
};

/** Properties by which Trustee connections can be ordered. */
export enum TrusteeOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/** TrusteeTrusteeCategory is enum for the field trustee_category */
export enum TrusteeTrusteeCategory {
  Primary = 'PRIMARY',
  Successor = 'SUCCESSOR'
}

/**
 * TrusteeWhereInput is used for filtering Trustee objects.
 * Input was generated by ent.
 */
export type TrusteeWhereInput = {
  and?: InputMaybe<Array<TrusteeWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** created_by field predicates */
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdByContains?: InputMaybe<Scalars['ID']['input']>;
  createdByContainsFold?: InputMaybe<Scalars['ID']['input']>;
  createdByEqualFold?: InputMaybe<Scalars['ID']['input']>;
  createdByGT?: InputMaybe<Scalars['ID']['input']>;
  createdByGTE?: InputMaybe<Scalars['ID']['input']>;
  createdByHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  createdByHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  createdByIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  createdByLT?: InputMaybe<Scalars['ID']['input']>;
  createdByLTE?: InputMaybe<Scalars['ID']['input']>;
  createdByNEQ?: InputMaybe<Scalars['ID']['input']>;
  createdByNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** duties edge predicates */
  hasDuties?: InputMaybe<Scalars['Boolean']['input']>;
  hasDutiesWith?: InputMaybe<Array<TrusteeDutyWhereInput>>;
  /** individual edge predicates */
  hasIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  hasIndividualWith?: InputMaybe<Array<ClientProfileWhereInput>>;
  /** organization edge predicates */
  hasOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  hasOrganizationWith?: InputMaybe<Array<ClientOrganizationWhereInput>>;
  /** user edge predicates */
  hasUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** level field predicates */
  level?: InputMaybe<TrusteeLevel>;
  levelIn?: InputMaybe<Array<TrusteeLevel>>;
  levelIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  levelNEQ?: InputMaybe<TrusteeLevel>;
  levelNotIn?: InputMaybe<Array<TrusteeLevel>>;
  levelNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<TrusteeWhereInput>;
  /** notes field predicates */
  notes?: InputMaybe<Scalars['String']['input']>;
  notesContains?: InputMaybe<Scalars['String']['input']>;
  notesContainsFold?: InputMaybe<Scalars['String']['input']>;
  notesEqualFold?: InputMaybe<Scalars['String']['input']>;
  notesGT?: InputMaybe<Scalars['String']['input']>;
  notesGTE?: InputMaybe<Scalars['String']['input']>;
  notesHasPrefix?: InputMaybe<Scalars['String']['input']>;
  notesHasSuffix?: InputMaybe<Scalars['String']['input']>;
  notesIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  notesLT?: InputMaybe<Scalars['String']['input']>;
  notesLTE?: InputMaybe<Scalars['String']['input']>;
  notesNEQ?: InputMaybe<Scalars['String']['input']>;
  notesNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notesNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<TrusteeWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** trustee_category field predicates */
  trusteeCategory?: InputMaybe<TrusteeTrusteeCategory>;
  trusteeCategoryIn?: InputMaybe<Array<TrusteeTrusteeCategory>>;
  trusteeCategoryNEQ?: InputMaybe<TrusteeTrusteeCategory>;
  trusteeCategoryNotIn?: InputMaybe<Array<TrusteeTrusteeCategory>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/**
 * UpdateAISuggestionInput is used for update AISuggestion object.
 * Input was generated by ent.
 */
export type UpdateAiSuggestionInput = {
  /** Whether the suggestion was accepted or rejected */
  acceptanceStatus?: InputMaybe<AiSuggestionAcceptanceStatus>;
  addEvidenceIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAcceptanceStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearClientOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  clearClientProfile?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearEvidence?: InputMaybe<Scalars['Boolean']['input']>;
  clearHasMatched?: InputMaybe<Scalars['Boolean']['input']>;
  clientOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  clientProfileID?: InputMaybe<Scalars['ID']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** Only relevant if the suggestion is for a grantor/trustee/trustAdvisor/beneficiary. This indicates if the user has gone through the process to attempt to match the suggestion with a client profile/organization row. */
  hasMatched?: InputMaybe<Scalars['Boolean']['input']>;
  removeEvidenceIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateAccessAgeParameterInput is used for update AccessAgeParameter object.
 * Input was generated by ent.
 */
export type UpdateAccessAgeParameterInput = {
  accessParameterID?: InputMaybe<Scalars['ID']['input']>;
  /** The end of the age requirement for this distribution */
  ageRequirementEnd?: InputMaybe<Scalars['Int']['input']>;
  /** The start of the age requirement for this distribution */
  ageRequirementStart?: InputMaybe<Scalars['Int']['input']>;
  clearAccessParameter?: InputMaybe<Scalars['Boolean']['input']>;
  clearAgeRequirementEnd?: InputMaybe<Scalars['Boolean']['input']>;
  clearAgeRequirementStart?: InputMaybe<Scalars['Boolean']['input']>;
  clearNotes?: InputMaybe<Scalars['Boolean']['input']>;
  /** The notes for the age access parameter */
  notes?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateAccessParameterInput is used for update AccessParameter object.
 * Input was generated by ent.
 */
export type UpdateAccessParameterInput = {
  /** The notes for the access parameter */
  accessParameterNotes?: InputMaybe<Scalars['String']['input']>;
  addAccessAgeParameterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The AMOUNT kind of access. Only valid when kind is AMOUNT */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  beneficiaryID?: InputMaybe<Scalars['ID']['input']>;
  clearAccessAgeParameters?: InputMaybe<Scalars['Boolean']['input']>;
  clearAccessParameterNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  clearFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  clearPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  /** The frequency of the access */
  frequency?: InputMaybe<AccessParameterFrequency>;
  /** The kind of access parameter */
  kind?: InputMaybe<AccessParameterKind>;
  /** The percentage of the access. Only valid when kind is PERCENTAGE */
  percentage?: InputMaybe<Scalars['Percent']['input']>;
  removeAccessAgeParameterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateAccountInput is used for update Account object.
 * Input was generated by ent.
 */
export type UpdateAccountInput = {
  addValuationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['Int']['input']>;
  clearAddeparEntityID?: InputMaybe<Scalars['Boolean']['input']>;
  clearInitialValuation?: InputMaybe<Scalars['Boolean']['input']>;
  clearValuations?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  initialValuationID?: InputMaybe<Scalars['ID']['input']>;
  removeValuationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateAddressInput is used for update Address object.
 * Input was generated by ent.
 */
export type UpdateAddressInput = {
  addClientProfileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  clearCityName?: InputMaybe<Scalars['Boolean']['input']>;
  clearClientProfile?: InputMaybe<Scalars['Boolean']['input']>;
  clearPostalCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearStreetLineOne?: InputMaybe<Scalars['Boolean']['input']>;
  clearStreetLineTwo?: InputMaybe<Scalars['Boolean']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  removeClientProfileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  streetLineOne?: InputMaybe<Scalars['String']['input']>;
  streetLineTwo?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateAssetClassInput is used for update AssetClass object.
 * Input was generated by ent.
 */
export type UpdateAssetClassInput = {
  addIntegrationAssetClassIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  balanceSheetCategoryID?: InputMaybe<Scalars['ID']['input']>;
  clearBalanceSheetCategory?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrowthRate?: InputMaybe<Scalars['Boolean']['input']>;
  clearIntegrationAssetClasses?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The defined growth rate for the asset class */
  growthRate?: InputMaybe<Scalars['Percent']['input']>;
  liquidityStatus?: InputMaybe<AssetClassLiquidityStatus>;
  removeIntegrationAssetClassIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The order in which this asset class should be displayed in the UI */
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * UpdateAssetV2Input is used for update AssetV2 object.
 * Input was generated by ent.
 */
export type UpdateAssetV2Input = {
  /** The name of the account that this asset is part of. */
  accountName?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<AssetV2Category>;
  classID?: InputMaybe<Scalars['ID']['input']>;
  clearAccountName?: InputMaybe<Scalars['Boolean']['input']>;
  clearClass?: InputMaybe<Scalars['Boolean']['input']>;
  clearCustomIntegrationAssetCategory?: InputMaybe<Scalars['Boolean']['input']>;
  clearIntegratedAccountID?: InputMaybe<Scalars['Boolean']['input']>;
  clearIntegrationType?: InputMaybe<Scalars['Boolean']['input']>;
  clearTicker?: InputMaybe<Scalars['Boolean']['input']>;
  clearValuation?: InputMaybe<Scalars['Boolean']['input']>;
  /** This will be set for any asset category value we get from a third-party ingested asset category. */
  customIntegrationAssetCategory?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The entity ID of the integrated account that this asset is part of. */
  integratedAccountID?: InputMaybe<Scalars['Int']['input']>;
  /** The integration this asset was ingested as part of. */
  integrationType?: InputMaybe<AssetV2IntegrationType>;
  qsbsEligibility?: InputMaybe<AssetV2QsbsEligibility>;
  ticker?: InputMaybe<Scalars['String']['input']>;
  valuationID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateAssetValuationV2Input is used for update AssetValuationV2 object.
 * Input was generated by ent.
 */
export type UpdateAssetValuationV2Input = {
  addAssetIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAssets?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  clearFixedValuationAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearValuationReason?: InputMaybe<Scalars['Boolean']['input']>;
  /** Free-form notes */
  description?: InputMaybe<Scalars['String']['input']>;
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /**
   * Valuations can work in two ways.
   * The first is manually specifying all the assets+values for a valuation; we tally them up.
   * The second is by specifying a valuation_amount.
   * If a valuation amount is present, we use this to compute the value of a valuation
   * and ignore the value of underlying assets, etc.
   */
  fixedValuationAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  removeAssetIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  valuationReason?: InputMaybe<AssetValuationV2ValuationReason>;
  valuationSource?: InputMaybe<AssetValuationV2ValuationSource>;
};

/**
 * UpdateAssetValueV2Input is used for update AssetValueV2 object.
 * Input was generated by ent.
 */
export type UpdateAssetValueV2Input = {
  assetID?: InputMaybe<Scalars['ID']['input']>;
  clearAsset?: InputMaybe<Scalars['Boolean']['input']>;
  clearCurrency?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnedPercent?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnedValue?: InputMaybe<Scalars['Boolean']['input']>;
  clearShareCount?: InputMaybe<Scalars['Boolean']['input']>;
  clearShareValue?: InputMaybe<Scalars['Boolean']['input']>;
  clearTotalValue?: InputMaybe<Scalars['Boolean']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  ownedPercent?: InputMaybe<Scalars['Percent']['input']>;
  ownedValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  ownershipType?: InputMaybe<AssetValueV2OwnershipType>;
  shareCount?: InputMaybe<Scalars['Decimal']['input']>;
  shareValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  totalValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
};

/**
 * UpdateBalanceSheetCategoryInput is used for update BalanceSheetCategory object.
 * Input was generated by ent.
 */
export type UpdateBalanceSheetCategoryInput = {
  addAssetClassIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAssetClasses?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  removeAssetClassIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The order in which this balance sheet category should be displayed in the UI */
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * UpdateBalanceSheetViewConfigurationInput is used for update BalanceSheetViewConfiguration object.
 * Input was generated by ent.
 */
export type UpdateBalanceSheetViewConfigurationInput = {
  appendColumnOrder?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The order that columns should show up */
  columnOrder?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Whether to hide small values on the balance sheet */
  hideSmallValues?: InputMaybe<Scalars['Boolean']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  /** Whether to separate directly held assets in the estate into client 1/client 2/joint */
  separateInEstateDirectlyHeld?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to show separate columns for trusts that are in the estate */
  separateInEstateTrusts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to show separate columns for trusts that are out of the estate */
  separateOutOfEstateTrusts?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to show assets in the estate */
  showInEstate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether to show assets out of the estate */
  showOutOfEstate?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * UpdateBeneficiaryAccessParameterInput is used for update BeneficiaryAccessParameter object.
 * Input was generated by ent.
 */
export type UpdateBeneficiaryAccessParameterInput = {
  /** Notes for when age is an access parameter */
  accessParameterAgeNote?: InputMaybe<Scalars['String']['input']>;
  /** Notes for when other is an access parameter */
  accessParameterOtherNote?: InputMaybe<Scalars['String']['input']>;
  clearAccessParameterAgeNote?: InputMaybe<Scalars['Boolean']['input']>;
  clearAccessParameterOtherNote?: InputMaybe<Scalars['Boolean']['input']>;
  /** The kind of distribution parameter */
  kind?: InputMaybe<BeneficiaryAccessParameterKind>;
};

/**
 * UpdateBeneficiaryDistributionInput is used for update BeneficiaryDistribution object.
 * Input was generated by ent.
 */
export type UpdateBeneficiaryDistributionInput = {
  addDistributionParameterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiaryID?: InputMaybe<Scalars['ID']['input']>;
  clearBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  clearDistributionAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDistributionFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  clearDistributionParameter?: InputMaybe<Scalars['Boolean']['input']>;
  clearDistributionParameterUponReachingAge?: InputMaybe<Scalars['Boolean']['input']>;
  clearDistributionParameters?: InputMaybe<Scalars['Boolean']['input']>;
  clearDistributionPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  /** The AMOUNT kind of distribution. Only valid when distribution_kind is AMOUNT */
  distributionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The frequency of the distribution to beneficiaries */
  distributionFrequency?: InputMaybe<BeneficiaryDistributionDistributionFrequency>;
  /** The kind of distribution for this beneficiary */
  distributionKind?: InputMaybe<BeneficiaryDistributionDistributionKind>;
  distributionParameter?: InputMaybe<BeneficiaryDistributionDistributionParameter>;
  /** The age at which the beneficiary will receive the distribution. Only valid when distribution_parameter is UPON_REACHING_AGE */
  distributionParameterUponReachingAge?: InputMaybe<Scalars['Int']['input']>;
  /** The percentage of the distribution. Only valid when distribution_kind is PERCENTAGE */
  distributionPercentage?: InputMaybe<Scalars['Percent']['input']>;
  removeDistributionParameterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateBeneficiaryDistributionParameterInput is used for update BeneficiaryDistributionParameter object.
 * Input was generated by ent.
 */
export type UpdateBeneficiaryDistributionParameterInput = {
  /** The kind of distribution parameter for this distribution */
  kind?: InputMaybe<BeneficiaryDistributionParameterKind>;
};

/**
 * UpdateBeneficiaryInput is used for update Beneficiary object.
 * Input was generated by ent.
 */
export type UpdateBeneficiaryInput = {
  addAccessParameterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDistributionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addScheduledDistributionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  beneficiaryGratID?: InputMaybe<Scalars['ID']['input']>;
  clearAccessParameters?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaryGrat?: InputMaybe<Scalars['Boolean']['input']>;
  clearDistributions?: InputMaybe<Scalars['Boolean']['input']>;
  clearLevel?: InputMaybe<Scalars['Boolean']['input']>;
  clearNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearPowerOfAppointment?: InputMaybe<Scalars['Boolean']['input']>;
  clearScheduledDistributions?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<BeneficiaryLevel>;
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  powerOfAppointmentID?: InputMaybe<Scalars['ID']['input']>;
  removeAccessParameterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDistributionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeScheduledDistributionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateBeneficiaryPowerOfAppointmentInput is used for update BeneficiaryPowerOfAppointment object.
 * Input was generated by ent.
 */
export type UpdateBeneficiaryPowerOfAppointmentInput = {
  beneficiaryID?: InputMaybe<Scalars['ID']['input']>;
  clearBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  clearPower?: InputMaybe<Scalars['Boolean']['input']>;
  clearPowerOtherNote?: InputMaybe<Scalars['Boolean']['input']>;
  /** The power of appointment for the beneficiary */
  power?: InputMaybe<BeneficiaryPowerOfAppointmentPower>;
  /** Notes for when other is an access parameter */
  powerOtherNote?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateCCorpBusinessEntityInput is used for update CCorpBusinessEntity object.
 * Input was generated by ent.
 */
export type UpdateCCorpBusinessEntityInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearDoingBusinessAsName?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearFinCenID?: InputMaybe<Scalars['Boolean']['input']>;
  clearFormationJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeopleNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnershipAsOfDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearRequiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxID?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * UpdateCLTProposalInput is used for update CLTProposal object.
 * Input was generated by ent.
 */
export type UpdateCltProposalInput = {
  addCharitableIncomeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDonorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addRemainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The amount of the annuity payout. Only valid when payout_kind is ANNUITY */
  annuityPayoutAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The cost basis of assets proposed to be transferred to this CRT */
  assetCostBasis?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** Notes about the assets proposed to be transferred to this CRT */
  assetNotes?: InputMaybe<Scalars['String']['input']>;
  /** The total value of assets proposed to be transferred to this CRT */
  assetValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  calculationMethod?: InputMaybe<CharitableTrustCalculationMethod>;
  clearAnnuityPayoutAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearAssetNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearCalculationMethod?: InputMaybe<Scalars['Boolean']['input']>;
  clearCharitableIncomeBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearOptimizationTarget?: InputMaybe<Scalars['Boolean']['input']>;
  clearPreTaxReturnPercentageHigh?: InputMaybe<Scalars['Boolean']['input']>;
  clearPreTaxReturnPercentageLow?: InputMaybe<Scalars['Boolean']['input']>;
  clearPreTaxReturnPercentageMedium?: InputMaybe<Scalars['Boolean']['input']>;
  clearRate7520?: InputMaybe<Scalars['Boolean']['input']>;
  clearRemainderBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDragPercentageHigh?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDragPercentageLow?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDragPercentageMedium?: InputMaybe<Scalars['Boolean']['input']>;
  clearUnitrustKind?: InputMaybe<Scalars['Boolean']['input']>;
  clearUnitrustPayoutPercent?: InputMaybe<Scalars['Boolean']['input']>;
  clearYearOfAnalysis?: InputMaybe<Scalars['Boolean']['input']>;
  optimizationTarget?: InputMaybe<CharitableTrustOptimizationTarget>;
  /** The frequency of payouts for this charitable trust. */
  payoutFrequency?: InputMaybe<CharitableTrustPayoutFrequency>;
  payoutKind?: InputMaybe<CharitableTrustPayoutKind>;
  /** The high end of the pre-tax return percentage assumption */
  preTaxReturnPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The low end of the pre-tax return assumption */
  preTaxReturnPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The medium pre-tax return percentage assumption */
  preTaxReturnPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  rate7520?: InputMaybe<Scalars['Percent']['input']>;
  removeCharitableIncomeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDonorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeRemainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The associated tax drag for the high pre-tax return */
  taxDragPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the low pre-tax return */
  taxDragPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the medium pre-tax return */
  taxDragPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  /** Tax status for this trust */
  taxStatus?: InputMaybe<TrustTaxStatus>;
  termKind?: InputMaybe<CharitableTrustTermKind>;
  /** The number of years the CRT will pay out for. Only valid if term_kind is FIXED */
  termYears?: InputMaybe<Scalars['Int']['input']>;
  /** The kind of unitrust. Only valid when payout_kind is UNITRUST; ignored otherwise. */
  unitrustKind?: InputMaybe<CharitableTrustUnitrustKind>;
  /** The percent of the unitrust payout. Only valid when payout_kind is UNITRUST */
  unitrustPayoutPercent?: InputMaybe<Scalars['Percent']['input']>;
  /** This is the year of analysis. If not specified, it will either default to the actuarial tables or the term_years */
  yearOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * UpdateCLTTrustInput is used for update CLTTrust object.
 * Input was generated by ent.
 */
export type UpdateCltTrustInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addLifetimeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addRemainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Taxable deduction that was recorded for funding this CLT */
  charitableDeduction?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearCharitableDeduction?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearLifetimeBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearOfficialInterestRatePercent?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearRemainderBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearTermDurationYears?: InputMaybe<Scalars['Boolean']['input']>;
  clearTerminationDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeLifetimeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeRemainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** If true, term_duration_years will be ignored */
  termDurationLifetime?: InputMaybe<Scalars['Boolean']['input']>;
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  terminationDate?: InputMaybe<Scalars['Time']['input']>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateCRTProposalInput is used for update CRTProposal object.
 * Input was generated by ent.
 */
export type UpdateCrtProposalInput = {
  addCharitableRemainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDonorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addIncomeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The amount of the annuity payout. Only valid when payout_kind is ANNUITY */
  annuityPayoutAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The cost basis of assets proposed to be transferred to this CRT */
  assetCostBasis?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** Notes about the assets proposed to be transferred to this CRT */
  assetNotes?: InputMaybe<Scalars['String']['input']>;
  /** The total value of assets proposed to be transferred to this CRT */
  assetValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  calculationMethod?: InputMaybe<CharitableTrustCalculationMethod>;
  clearAnnuityPayoutAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearAssetNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearCalculationMethod?: InputMaybe<Scalars['Boolean']['input']>;
  clearCharitableRemainderBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearIncomeBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearOptimizationTarget?: InputMaybe<Scalars['Boolean']['input']>;
  clearPreTaxReturnPercentageHigh?: InputMaybe<Scalars['Boolean']['input']>;
  clearPreTaxReturnPercentageLow?: InputMaybe<Scalars['Boolean']['input']>;
  clearPreTaxReturnPercentageMedium?: InputMaybe<Scalars['Boolean']['input']>;
  clearRate7520?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDragPercentageHigh?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDragPercentageLow?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDragPercentageMedium?: InputMaybe<Scalars['Boolean']['input']>;
  clearUnitrustKind?: InputMaybe<Scalars['Boolean']['input']>;
  clearUnitrustPayoutPercent?: InputMaybe<Scalars['Boolean']['input']>;
  clearYearOfAnalysis?: InputMaybe<Scalars['Boolean']['input']>;
  optimizationTarget?: InputMaybe<CharitableTrustOptimizationTarget>;
  /** The frequency of payouts for this charitable trust. */
  payoutFrequency?: InputMaybe<CharitableTrustPayoutFrequency>;
  payoutKind?: InputMaybe<CharitableTrustPayoutKind>;
  /** The high end of the pre-tax return percentage assumption */
  preTaxReturnPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The low end of the pre-tax return assumption */
  preTaxReturnPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The medium pre-tax return percentage assumption */
  preTaxReturnPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  rate7520?: InputMaybe<Scalars['Percent']['input']>;
  removeCharitableRemainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDonorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeIncomeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The associated tax drag for the high pre-tax return */
  taxDragPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the low pre-tax return */
  taxDragPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the medium pre-tax return */
  taxDragPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  termKind?: InputMaybe<CharitableTrustTermKind>;
  /** The number of years the CRT will pay out for. Only valid if term_kind is FIXED; ignored otherwise. */
  termYears?: InputMaybe<Scalars['Int']['input']>;
  /** The kind of unitrust. Only valid when payout_kind is UNITRUST; ignored otherwise. */
  unitrustKind?: InputMaybe<CharitableTrustUnitrustKind>;
  /** The percent of the unitrust payout. Only valid when payout_kind is UNITRUST */
  unitrustPayoutPercent?: InputMaybe<Scalars['Percent']['input']>;
  /** This is the year of analysis. If not specified, it will either default to the actuarial tables or the term_years */
  yearOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * UpdateCRTTrustInput is used for update CRTTrust object.
 * Input was generated by ent.
 */
export type UpdateCrtTrustInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addLifetimeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addRemainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Taxable deduction that was recorded for funding this CRT */
  charitableDeduction?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearCharitableDeduction?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearLifetimeBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearOfficialInterestRatePercent?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearRemainderBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearTermDurationYears?: InputMaybe<Scalars['Boolean']['input']>;
  clearTerminationDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeLifetimeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeRemainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** If true, term_duration_years will be ignored */
  termDurationLifetime?: InputMaybe<Scalars['Boolean']['input']>;
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  terminationDate?: InputMaybe<Scalars['Time']['input']>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateCashflowInput is used for update Cashflow object.
 * Input was generated by ent.
 */
export type UpdateCashflowInput = {
  /** This dynamically sets the end date of this transfer to the year when both individuals die. It superceeds end_year and end_at_clients_death. Ignored if start_year is not set.  */
  afterBothDeaths?: InputMaybe<Scalars['Boolean']['input']>;
  clearAfterBothDeaths?: InputMaybe<Scalars['Boolean']['input']>;
  clearEndAtClientsDeath?: InputMaybe<Scalars['Boolean']['input']>;
  clearEndYear?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrowthPercent?: InputMaybe<Scalars['Boolean']['input']>;
  clearIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  clearStartYear?: InputMaybe<Scalars['Boolean']['input']>;
  clearTransferValue?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  endAtClientsDeathID?: InputMaybe<Scalars['ID']['input']>;
  /** The year this transfer ends. Ignored if start_year is not set. */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The yearly growth percent of this transfer. Can be negative. */
  growthPercent?: InputMaybe<Scalars['Percent']['input']>;
  individualID?: InputMaybe<Scalars['ID']['input']>;
  /** The year this transfer starts. If start_year is not set, it is assumed to be a one time transfer happening in the current year. If start_year is set, this is a yearly recurring transfer until end_year is reached. */
  startYear?: InputMaybe<Scalars['Int']['input']>;
  /** Value of this transfer. Positive for income, negative for expense. */
  transferValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
};

/**
 * UpdateClientOrganizationInput is used for update ClientOrganization object.
 * Input was generated by ent.
 */
export type UpdateClientOrganizationInput = {
  addSuggestedClientOrganizationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addressID?: InputMaybe<Scalars['ID']['input']>;
  clearAddress?: InputMaybe<Scalars['Boolean']['input']>;
  clearKind?: InputMaybe<Scalars['Boolean']['input']>;
  clearNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearPointOfContactEmail?: InputMaybe<Scalars['Boolean']['input']>;
  clearPointOfContactLastName?: InputMaybe<Scalars['Boolean']['input']>;
  clearPointOfContactName?: InputMaybe<Scalars['Boolean']['input']>;
  clearPointOfContactPhone?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuggestedClientOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  isBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  isTrustee?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<ClientOrganizationKind>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  pointOfContactEmail?: InputMaybe<Scalars['String']['input']>;
  pointOfContactLastName?: InputMaybe<Scalars['String']['input']>;
  pointOfContactName?: InputMaybe<Scalars['String']['input']>;
  pointOfContactPhone?: InputMaybe<Scalars['String']['input']>;
  removeSuggestedClientOrganizationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateClientPresentationInput is used for update ClientPresentation object.
 * Input was generated by ent.
 */
export type UpdateClientPresentationInput = {
  appendEntityOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
  appendEstateWaterfallOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
  appendSectionOrder?: InputMaybe<Array<ClientPresentationSection>>;
  clearCoverSlideDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearCoverSlideTitle?: InputMaybe<Scalars['Boolean']['input']>;
  coverSlideDescription?: InputMaybe<Scalars['String']['input']>;
  coverSlideTitle?: InputMaybe<Scalars['String']['input']>;
  /** The order that the entities should be shown in the presentation */
  entityOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The order that the estate waterfalls should be shown in the presentation */
  estateWaterfallOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** if true, show all entities in the presentation. if false, only show the entities defined in entity_order */
  includeAllEntities?: InputMaybe<Scalars['Boolean']['input']>;
  /** if true, show all waterfalls in the presentation. if false, only show the waterfalls defined in entity_order */
  includeAllEstateWaterfalls?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** the ordered list of included sections in the presentation */
  sectionOrder?: InputMaybe<Array<ClientPresentationSection>>;
  /** if true, include the cover slide in the presentation */
  showCoverSlide?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * UpdateClientProfileInput is used for update ClientProfile object.
 * Input was generated by ent.
 */
export type UpdateClientProfileInput = {
  addCashflowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addEstateWaterfallFilterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addLifetimeExclusionEventIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addOwnedOwnershipStakeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addProfessionalTeamRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addRelationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSuggestedClientProfileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTestamentaryEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<UpdateAddressInput>;
  addressID?: InputMaybe<Scalars['ID']['input']>;
  clearAddeparEntityID?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddress?: InputMaybe<Scalars['Boolean']['input']>;
  clearCashflows?: InputMaybe<Scalars['Boolean']['input']>;
  clearDateOfBirth?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEmail?: InputMaybe<Scalars['Boolean']['input']>;
  clearEstateWaterfallFilters?: InputMaybe<Scalars['Boolean']['input']>;
  clearHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearIntegrationClient?: InputMaybe<Scalars['Boolean']['input']>;
  clearLifetimeExclusionEvents?: InputMaybe<Scalars['Boolean']['input']>;
  clearMiddleName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnedOwnershipStakes?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearProfessionalTeamRoles?: InputMaybe<Scalars['Boolean']['input']>;
  clearRelations?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuffix?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuggestedClientProfile?: InputMaybe<Scalars['Boolean']['input']>;
  clearTestamentaryEntities?: InputMaybe<Scalars['Boolean']['input']>;
  clearYearOfBirth?: InputMaybe<Scalars['Boolean']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Time']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  integrationClientID?: InputMaybe<Scalars['ID']['input']>;
  /** Whether this client profile should be an eligible beneficiary for entities and gifts. */
  isBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether this client profile should be an eligible grantor/owner/other principal for entities. */
  isGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether this client_profile is one of the (at most) two primary clients on this household. */
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether this client profile should be an eligible trustee for entities. */
  isTrustee?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  removeCashflowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeEstateWaterfallFilterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeLifetimeExclusionEventIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeOwnedOwnershipStakeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeProfessionalTeamRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeRelationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSuggestedClientProfileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTestamentaryEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  suffix?: InputMaybe<Scalars['String']['input']>;
  updateRelationships?: InputMaybe<Array<UpdateClientProfileRelationship>>;
  /** DEPRECATED: Use date_of_birth instead */
  yearOfBirth?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateClientProfileRelationship = {
  clearRelationship?: InputMaybe<Scalars['Boolean']['input']>;
  fromClientProfileID: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  relationship?: InputMaybe<UpdateClientProfileRelationshipInput>;
  toClientProfileID: Scalars['ID']['input'];
};

/**
 * UpdateClientProfileRelationshipInput is used for update ClientProfileRelationship object.
 * Input was generated by ent.
 */
export type UpdateClientProfileRelationshipInput = {
  clearNotes?: InputMaybe<Scalars['Boolean']['input']>;
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** the relationship between the 'from' ClientProfile and the 'to' ClientProfile */
  type?: InputMaybe<ClientProfileRelationshipType>;
};

export type UpdateCollaboratorByIdInput = {
  id: Scalars['ID']['input'];
  update: UpdateCollaboratorInput;
};

/**
 * UpdateCollaboratorInput is used for update Collaborator object.
 * Input was generated by ent.
 */
export type UpdateCollaboratorInput = {
  addHouseholdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearHouseholds?: InputMaybe<Scalars['Boolean']['input']>;
  clearOrganizationName?: InputMaybe<Scalars['Boolean']['input']>;
  clearTitle?: InputMaybe<Scalars['Boolean']['input']>;
  clearUser?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The role of the collaborator, for display purposes only */
  kind?: InputMaybe<CollaboratorKind>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** The name of the organization this collaborator works for */
  organizationName?: InputMaybe<Scalars['String']['input']>;
  removeHouseholdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The professional title of the collaborator, e.g. 'Associate' */
  title?: InputMaybe<Scalars['String']['input']>;
  userID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateControllingPartyInput is used for update ControllingParty object.
 * Input was generated by ent.
 */
export type UpdateControllingPartyInput = {
  clearNotes?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateCustodialPersonalAccountInput is used for update CustodialPersonalAccount object.
 * Input was generated by ent.
 */
export type UpdateCustodialPersonalAccountInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparEntityID?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwner?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  ownerID?: InputMaybe<Scalars['ID']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * UpdateDispositionScenarioInput is used for update DispositionScenario object.
 * Input was generated by ent.
 */
export type UpdateDispositionScenarioInput = {
  addDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSecondDeathDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  associatedHypotheticalWaterfallID?: InputMaybe<Scalars['ID']['input']>;
  clearAssociatedHypotheticalWaterfall?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositiveProvisions?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositiveProvisionsTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  clearReviewedAt?: InputMaybe<Scalars['Boolean']['input']>;
  clearSecondDeathDispositiveProvisions?: InputMaybe<Scalars['Boolean']['input']>;
  clearSecondDeathDispositiveProvisionsTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  dispositiveProvisionsTemplateID?: InputMaybe<Scalars['ID']['input']>;
  firstGrantorDeathID?: InputMaybe<Scalars['ID']['input']>;
  removeDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSecondDeathDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The time at which the scenario was last reviewed. This can be used to indicate that a user has looked at these provisions and they are correct, even if they are empty. */
  reviewedAt?: InputMaybe<Scalars['Time']['input']>;
  secondDeathDispositiveProvisionsTemplateID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateDispositiveProvisionInput is used for update DispositiveProvision object.
 * Input was generated by ent.
 */
export type UpdateDispositiveProvisionInput = {
  associatedHypotheticalWaterfallID?: InputMaybe<Scalars['ID']['input']>;
  clearAssociatedHypotheticalWaterfall?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  clearNotes?: InputMaybe<Scalars['Boolean']['input']>;
  /** The AMOUNT kind of disposition. Only valid when disposition_kind is AMOUNT */
  dispositionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  dispositionKind?: InputMaybe<DispositiveProvisionDispositionKind>;
  /** The order of the disposition in the list of dispositions */
  dispositionOrder?: InputMaybe<Scalars['Int']['input']>;
  /** The percentage of the disposition. Only valid when disposition_kind is PERCENTAGE */
  dispositionPercentage?: InputMaybe<Scalars['Percent']['input']>;
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  transferTaxKind?: InputMaybe<DispositiveProvisionTransferTaxKind>;
};

/**
 * UpdateDispositiveProvisionTemplateInput is used for update DispositiveProvisionTemplate object.
 * Input was generated by ent.
 */
export type UpdateDispositiveProvisionTemplateInput = {
  addDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearDispositiveProvisions?: InputMaybe<Scalars['Boolean']['input']>;
  clearDocument?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the template */
  displayName?: InputMaybe<Scalars['String']['input']>;
  documentID?: InputMaybe<Scalars['ID']['input']>;
  removeDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The sub kind of dispositive provision tempalte */
  subKind?: InputMaybe<DispositiveProvisionTemplateSubKind>;
};

/**
 * UpdateDocumentInput is used for update Document object.
 * Input was generated by ent.
 */
export type UpdateDocumentInput = {
  addLifetimeExclusionEventIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The precedence that the suggestions from this document should take when extracting data. If this is not explicitly set, suggestions order will be inferred by uploaded date (most recent first). Only applicable for documents associated with entities. */
  aiSuggestionOrder?: InputMaybe<Scalars['Int']['input']>;
  clearAiSuggestionOrder?: InputMaybe<Scalars['Boolean']['input']>;
  clearDefaultDocumentOfEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearDocumentSummary?: InputMaybe<Scalars['Boolean']['input']>;
  clearEnableAiSuggestions?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearLifetimeExclusionEvent?: InputMaybe<Scalars['Boolean']['input']>;
  clearParsingStatus?: InputMaybe<Scalars['Boolean']['input']>;
  defaultDocumentOfEntityID?: InputMaybe<Scalars['ID']['input']>;
  documentSummaryID?: InputMaybe<Scalars['ID']['input']>;
  /** Whether this document should be used for AI suggestions. Only applicable for documents associated with entities. */
  enableAiSuggestions?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  fileID?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** DEPRECATED: This is a spillover concept from the initial POC. */
  parsingStatus?: InputMaybe<DocumentParsingStatus>;
  removeLifetimeExclusionEventIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<DocumentType>;
};

/**
 * UpdateDocumentSummaryInput is used for update DocumentSummary object.
 * Input was generated by ent.
 */
export type UpdateDocumentSummaryInput = {
  /** The user's feedback on the AI-generated summary. Ignore if entry_mode is USER */
  aiSummaryFeedback?: InputMaybe<Scalars['String']['input']>;
  /** The user's rating of the AI-generated summary. Ignore if entry_mode is USER */
  aiSummaryRating?: InputMaybe<DocumentSummaryAiSummaryRating>;
  clearAiSummaryFeedback?: InputMaybe<Scalars['Boolean']['input']>;
  clearAiSummaryRating?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntryMode?: InputMaybe<Scalars['Boolean']['input']>;
  clearSummaryFormat?: InputMaybe<Scalars['Boolean']['input']>;
  /** Indicates if the summary content was updated by the user or generated by AI. Default: AI_AUTO */
  entryMode?: InputMaybe<DocumentSummaryEntryMode>;
  /** The most recently-edited summary for the document */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** The format of the summary string when displaying it to the user on the frontend. Default: MARKDOWN */
  summaryFormat?: InputMaybe<DocumentSummarySummaryFormat>;
};

/**
 * UpdateDonorAdvisedFundInput is used for update DonorAdvisedFund object.
 * Input was generated by ent.
 */
export type UpdateDonorAdvisedFundInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDonorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparEntityID?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearDonors?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDonorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * UpdateEmployeeInput is used for update Employee object.
 * Input was generated by ent.
 */
export type UpdateEmployeeInput = {
  addAssignedHouseholdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTeamIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTeamOwnerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAssignedHouseholds?: InputMaybe<Scalars['Boolean']['input']>;
  clearIsAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  clearRoles?: InputMaybe<Scalars['Boolean']['input']>;
  clearTeamOwner?: InputMaybe<Scalars['Boolean']['input']>;
  clearTeams?: InputMaybe<Scalars['Boolean']['input']>;
  isAdmin?: InputMaybe<Scalars['Boolean']['input']>;
  removeAssignedHouseholdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTeamIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTeamOwnerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  user?: InputMaybe<UpdateUserInput>;
  userID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateEntityGraphViewInput is used for update EntityGraphView object.
 * Input was generated by ent.
 */
export type UpdateEntityGraphViewInput = {
  appendNodeConfigurations?: InputMaybe<Array<EntityGraphNodeConfigurationInput>>;
  clearOrientation?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  /** the definitions of the nodes in the graph */
  nodeConfigurations?: InputMaybe<Array<EntityGraphNodeConfigurationInput>>;
  orientation?: InputMaybe<EntityGraphViewOrientation>;
};

/**
 * UpdateEntityInput is used for update Entity object.
 * Input was generated by ent.
 */
export type UpdateEntityInput = {
  addActivityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addCashflowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addEmailNotificationRecordIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addEntityProposalIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addEstateWaterfallFilterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addIntegrationEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addLiabilitiesOwedIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addOwnedByStakeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addOwnedOwnershipStakeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addStateTaxisIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTaskIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['Int']['input']>;
  /** when true, indicates that the addepar entity this is linked to isn't owned by the grantor on addepar. this could be because the grantor on addepar is deceased or not present on Luminary. */
  addeparLinkedToNongrantorEntity?: InputMaybe<Scalars['Boolean']['input']>;
  /** how the entity got created */
  attributionSource?: InputMaybe<EntityAttributionSource>;
  clearActivities?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparEntityID?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparLinkedToNongrantorEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearAttributionSource?: InputMaybe<Scalars['Boolean']['input']>;
  clearCashflows?: InputMaybe<Scalars['Boolean']['input']>;
  clearDefaultDocument?: InputMaybe<Scalars['Boolean']['input']>;
  clearDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  clearEmailNotificationRecords?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntityProposals?: InputMaybe<Scalars['Boolean']['input']>;
  clearEstateWaterfallFilters?: InputMaybe<Scalars['Boolean']['input']>;
  clearIntegrationEntities?: InputMaybe<Scalars['Boolean']['input']>;
  clearLiabilitiesOwed?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnedByStakes?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnedOwnershipStakes?: InputMaybe<Scalars['Boolean']['input']>;
  clearStateTaxes?: InputMaybe<Scalars['Boolean']['input']>;
  clearTasks?: InputMaybe<Scalars['Boolean']['input']>;
  defaultDocumentID?: InputMaybe<Scalars['ID']['input']>;
  removeActivityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeCashflowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeEmailNotificationRecordIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeEntityProposalIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeEstateWaterfallFilterIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeIntegrationEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeLiabilitiesOwedIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeOwnedByStakeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeOwnedOwnershipStakeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeStateTaxisIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTaskIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** the lifecycle stage of the entity. not all stages are applicable to all entities; most go directly into ACTIVE */
  stage?: InputMaybe<EntityStage>;
};

/**
 * UpdateEntityProposalInput is used for update EntityProposal object.
 * Input was generated by ent.
 */
export type UpdateEntityProposalInput = {
  addProposalScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  assetValuationProjectionType?: InputMaybe<EntityProposalAssetValuationProjectionType>;
  clearProposalScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDrag?: InputMaybe<Scalars['Boolean']['input']>;
  removeProposalScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** the percent value for tax drag for the entity */
  taxDrag?: InputMaybe<Scalars['Percent']['input']>;
};

/**
 * UpdateEntityStateTaxInput is used for update EntityStateTax object.
 * Input was generated by ent.
 */
export type UpdateEntityStateTaxInput = {
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  stateCode?: InputMaybe<StateCode>;
};

/**
 * UpdateEntityTaskInput is used for update EntityTask object.
 * Input was generated by ent.
 */
export type UpdateEntityTaskInput = {
  addReminderEmailNotificationRecordIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTaskDependencyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTaskDependentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  assignedByID?: InputMaybe<Scalars['ID']['input']>;
  assignedToID?: InputMaybe<Scalars['ID']['input']>;
  /** the reason this task was cancelled. if present, the task status is computed as canceled */
  cancelledReason?: InputMaybe<Scalars['String']['input']>;
  clearAssignedBy?: InputMaybe<Scalars['Boolean']['input']>;
  clearAssignedTo?: InputMaybe<Scalars['Boolean']['input']>;
  clearCancelledReason?: InputMaybe<Scalars['Boolean']['input']>;
  clearCompletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  clearCompletedBy?: InputMaybe<Scalars['Boolean']['input']>;
  clearReminderEmailNotificationRecord?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaskDependencies?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaskDependents?: InputMaybe<Scalars['Boolean']['input']>;
  /** the timestamp at which this task was marked as complete */
  completedAt?: InputMaybe<Scalars['Time']['input']>;
  completedByID?: InputMaybe<Scalars['ID']['input']>;
  /** the date at which this task is due */
  dueAt?: InputMaybe<Scalars['Time']['input']>;
  removeReminderEmailNotificationRecordIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTaskDependencyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTaskDependentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<EntityTaskType>;
};

/**
 * UpdateEstateTaxScenarioInput is used for update EstateTaxScenario object.
 * Input was generated by ent.
 */
export type UpdateEstateTaxScenarioInput = {
  appendFirstDeathPayoutOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
  appendSecondDeathPayoutOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearSecondDeathPayoutOrder?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The order of payout for the first death. Includes Entities and Testamentary Entities. */
  firstDeathPayoutOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
  firstGrantorDeathID?: InputMaybe<Scalars['ID']['input']>;
  isExemptionPortable?: InputMaybe<Scalars['Boolean']['input']>;
  payoutKind?: InputMaybe<EstateTaxScenarioPayoutKind>;
  /** The order of payout for the second death, only valid when there are multiple grantors. Includes Entities and Testamentary Entities. */
  secondDeathPayoutOrder?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateEstateWaterfallHypotheticalTransferInput is used for update EstateWaterfallHypotheticalTransfer object.
 * Input was generated by ent.
 */
export type UpdateEstateWaterfallHypotheticalTransferInput = {
  addGiftingGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Assume transfers will grow/shrink by this percentage each year. Only valid for recurring transfers. */
  assetGrowthReturn?: InputMaybe<Scalars['Percent']['input']>;
  clearAssetGrowthReturn?: InputMaybe<Scalars['Boolean']['input']>;
  clearDestinationEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearDestinationIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  clearDestinationOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  clearEndYear?: InputMaybe<Scalars['Boolean']['input']>;
  clearGiftingGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceInsurancePolicy?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceOwnedBusinessEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceOwnedEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceOwnershipStake?: InputMaybe<Scalars['Boolean']['input']>;
  clearStartYear?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxableValue?: InputMaybe<Scalars['Boolean']['input']>;
  clearTransferValue?: InputMaybe<Scalars['Boolean']['input']>;
  destinationEntityID?: InputMaybe<Scalars['ID']['input']>;
  destinationIndividualID?: InputMaybe<Scalars['ID']['input']>;
  /** The kind of destination for this transfer. */
  destinationKind?: InputMaybe<EstateWaterfallHypotheticalTransferDestinationKind>;
  destinationOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  /** The year this transfer ends. Ignored if start_year is not set. */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  /** If true, this is a GST gift. Only valid when transfer_tax_kind is GRANTOR_TAXABLE_GIFT. */
  isGstGift?: InputMaybe<Scalars['Boolean']['input']>;
  removeGiftingGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourceEntityID?: InputMaybe<Scalars['ID']['input']>;
  sourceIndividualID?: InputMaybe<Scalars['ID']['input']>;
  sourceInsurancePolicyID?: InputMaybe<Scalars['ID']['input']>;
  /** The kind of the source for this transfer. */
  sourceKind?: InputMaybe<EstateWaterfallHypotheticalTransferSourceKind>;
  sourceOwnedBusinessEntityID?: InputMaybe<Scalars['ID']['input']>;
  sourceOwnedEntityID?: InputMaybe<Scalars['ID']['input']>;
  sourceOwnershipStakeID?: InputMaybe<Scalars['ID']['input']>;
  /** The year this transfer starts. If start_year is not set, it is assumed to be a one time transfer happening in the current year. If start_year is set, this is a yearly recurring transfer until end_year is reached. */
  startYear?: InputMaybe<Scalars['Int']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  /** Taxable value of the transfer. If nil, it will be calculated. */
  taxableValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The order of this transfer in the list of transfers for a given source. */
  transferOrder?: InputMaybe<Scalars['Int']['input']>;
  transferTaxKind?: InputMaybe<EstateWaterfallHypotheticalTransferTransferTaxKind>;
  /** Value of this transfer. */
  transferValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
};

/**
 * UpdateEstateWaterfallInput is used for update EstateWaterfall object.
 * Input was generated by ent.
 */
export type UpdateEstateWaterfallInput = {
  addHypotheticalScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalTransferIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addVisualizationGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  appendNodeConfigurations?: InputMaybe<Array<EntityGraphNodeConfigurationInput>>;
  /** Assume assets will grow/shrink by this percentage each year. */
  assetGrowthReturn?: InputMaybe<Scalars['Percent']['input']>;
  clearAssetGrowthReturn?: InputMaybe<Scalars['Boolean']['input']>;
  clearExemptionGrowthRate?: InputMaybe<Scalars['Boolean']['input']>;
  clearFilter?: InputMaybe<Scalars['Boolean']['input']>;
  clearFirstGrantorDeathYear?: InputMaybe<Scalars['Boolean']['input']>;
  clearHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalTransfers?: InputMaybe<Scalars['Boolean']['input']>;
  clearParent?: InputMaybe<Scalars['Boolean']['input']>;
  clearSecondGrantorDeathYear?: InputMaybe<Scalars['Boolean']['input']>;
  clearVisualizationConfig?: InputMaybe<Scalars['Boolean']['input']>;
  clearVisualizationGroups?: InputMaybe<Scalars['Boolean']['input']>;
  clearWillExemptionSunset?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Lifetime exemption grows by this around each year. */
  exemptionGrowthRate?: InputMaybe<Scalars['Percent']['input']>;
  filterID?: InputMaybe<Scalars['ID']['input']>;
  /** Projected year that the first grantor dies. */
  firstGrantorDeathYear?: InputMaybe<Scalars['Int']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  /** the definitions of the nodes in the graph */
  nodeConfigurations?: InputMaybe<Array<EntityGraphNodeConfigurationInput>>;
  parentID?: InputMaybe<Scalars['ID']['input']>;
  removeHypotheticalScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalTransferIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeVisualizationGroupIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Projected year that the second grantor dies. Only applicable when there are two grantors. */
  secondGrantorDeathYear?: InputMaybe<Scalars['Int']['input']>;
  visualizationConfigID?: InputMaybe<Scalars['ID']['input']>;
  /** If true, exemption amount is halved at the end of 2025, and continues growing at the same rate. */
  willExemptionSunset?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * UpdateEstateWaterfallVisualizationConfigInput is used for update EstateWaterfallVisualizationConfig object.
 * Input was generated by ent.
 */
export type UpdateEstateWaterfallVisualizationConfigInput = {
  /** If true, always shows edge labels. */
  alwaysShowEdgeLabels?: InputMaybe<Scalars['Boolean']['input']>;
  clearWaterfall?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, hides edges. */
  hideEdges?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, hides list of entities in a group. */
  hideEntitiesListInGroups?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, hides dollar values on the entity tile. */
  hideEntityValues?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, hides all tax tiles. */
  hideTaxTiles?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, shows zero dollar tiles. */
  showZeroDollarTiles?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, uses brand colors instead of luminary defaults. */
  useBrandColors?: InputMaybe<Scalars['Boolean']['input']>;
  waterfallID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateEstateWaterfallVisualizationFilterInput is used for update EstateWaterfallVisualizationFilter object.
 * Input was generated by ent.
 */
export type UpdateEstateWaterfallVisualizationFilterInput = {
  addIncludedClientIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addIncludedEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearIncludedClients?: InputMaybe<Scalars['Boolean']['input']>;
  clearIncludedEntities?: InputMaybe<Scalars['Boolean']['input']>;
  removeIncludedClientIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeIncludedEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  waterfallID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateFeatureFlagInput is used for update FeatureFlag object.
 * Input was generated by ent.
 */
export type UpdateFeatureFlagInput = {
  addQueryRuleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearFlagDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearQueryRules?: InputMaybe<Scalars['Boolean']['input']>;
  flagDescription?: InputMaybe<Scalars['String']['input']>;
  flagStatus?: InputMaybe<FeatureFlagFlagStatus>;
  ownedBy?: InputMaybe<Scalars['String']['input']>;
  removeQueryRuleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateFeatureFlagQueryRuleInput is used for update FeatureFlagQueryRule object.
 * Input was generated by ent.
 */
export type UpdateFeatureFlagQueryRuleInput = {
  clearKind?: InputMaybe<Scalars['Boolean']['input']>;
  featureFlagID?: InputMaybe<Scalars['ID']['input']>;
  flagQuery?: InputMaybe<Scalars['String']['input']>;
  flagVariationName?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<FeatureFlagQueryRuleKind>;
};

/**
 * UpdateGPBusinessEntityInput is used for update GPBusinessEntity object.
 * Input was generated by ent.
 */
export type UpdateGpBusinessEntityInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearDoingBusinessAsName?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearFinCenID?: InputMaybe<Scalars['Boolean']['input']>;
  clearFormationJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeopleNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnershipAsOfDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearRequiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxID?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * UpdateGRATAnnuityV2Input is used for update GRATAnnuityV2 object.
 * Input was generated by ent.
 */
export type UpdateGratAnnuityV2Input = {
  associatedAssetValuationID?: InputMaybe<Scalars['ID']['input']>;
  associatedTaskID?: InputMaybe<Scalars['ID']['input']>;
  clearAssociatedAssetValuation?: InputMaybe<Scalars['Boolean']['input']>;
  clearAssociatedTask?: InputMaybe<Scalars['Boolean']['input']>;
  clearFailedAnnuityPaymentAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearPaidOn?: InputMaybe<Scalars['Boolean']['input']>;
  /** if the annuity payment failed, this is the amount that was actually paid */
  failedAnnuityPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** the percent of the initial funding value due to be paid with this annuity */
  initialFundingValuePercent?: InputMaybe<Scalars['Percent']['input']>;
  /** the date the annuity was paid on */
  paidOn?: InputMaybe<Scalars['Time']['input']>;
  /** the term duration of the GRAT that this annuity is associated with */
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  /** which 1-indexed annuity payment this represents; if a 2, represents the second annuity payment */
  yearOfTerm?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * UpdateGRATProposalScenarioInput is used for update GRATProposalScenario object.
 * Input was generated by ent.
 */
export type UpdateGratProposalScenarioInput = {
  clearInTermValuationType?: InputMaybe<Scalars['Boolean']['input']>;
  clearProjectedAnnualReturnAfterTerm?: InputMaybe<Scalars['Boolean']['input']>;
  clearProjectedAnnualReturnThroughTerm?: InputMaybe<Scalars['Boolean']['input']>;
  clearProjectedMarketValueAtTerm?: InputMaybe<Scalars['Boolean']['input']>;
  inTermValuationType?: InputMaybe<GratProposalScenarioInTermValuationType>;
  /** the percent value for projected return after the lifetime of the GRAT */
  projectedAnnualReturnAfterTerm?: InputMaybe<Scalars['Percent']['input']>;
  /** the percent value for projected return during the lifetime of the GRAT */
  projectedAnnualReturnThroughTerm?: InputMaybe<Scalars['Percent']['input']>;
  projectedMarketValueAtTerm?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** the default selected time period for this projection */
  projectionTimePeriodYears?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * UpdateGRATTrustInput is used for update GRATTrust object.
 * Input was generated by ent.
 */
export type UpdateGratTrustInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addAnnuityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** the percent by which the annuity should increase each year in a variable annuity amount scenario */
  annuityAnnualIncreasePercent?: InputMaybe<Scalars['Percent']['input']>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearAnnuities?: InputMaybe<Scalars['Boolean']['input']>;
  clearAnnuityAnnualIncreasePercent?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearDistributionAssetValuation?: InputMaybe<Scalars['Boolean']['input']>;
  clearDistributionDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearDownsideThresholdPercent?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearImplementationNotesToAttorney?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOfficialInterestRatePercent?: InputMaybe<Scalars['Boolean']['input']>;
  clearProjectedMarketValue?: InputMaybe<Scalars['Boolean']['input']>;
  clearProjectedRateOfReturn?: InputMaybe<Scalars['Boolean']['input']>;
  clearProjectedSharePrice?: InputMaybe<Scalars['Boolean']['input']>;
  clearRollingPeriodYears?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  clearTargetTaxableGift?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxableGift?: InputMaybe<Scalars['Boolean']['input']>;
  clearTermDurationYears?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  clearUpsideThresholdPercent?: InputMaybe<Scalars['Boolean']['input']>;
  clearUpsideThresholdValue?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  distributionAssetValuationID?: InputMaybe<Scalars['ID']['input']>;
  /** the date that the trust remainder was distributed */
  distributionDate?: InputMaybe<Scalars['Time']['input']>;
  downsideThresholdPercent?: InputMaybe<Scalars['Percent']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  grantorID?: InputMaybe<Scalars['ID']['input']>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  /** notes from the advisor team to the implementing attorney */
  implementationNotesToAttorney?: InputMaybe<Scalars['String']['input']>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  projectedMarketValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  projectedRateOfReturn?: InputMaybe<Scalars['Percent']['input']>;
  projectedSharePrice?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeAnnuityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  rollingPeriodYears?: InputMaybe<Scalars['Int']['input']>;
  /** taxable gift that the advisor is trying to achieve; should be used as an input for annuities calcs */
  targetTaxableGift?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** The actual taxable gift that was recorded for this GRAT */
  taxableGift?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  /** If true, the upside threshold value is used for the GRAT substitution threshold instead of the upside threshold percent. */
  upsideAsTargetSurplus?: InputMaybe<Scalars['Boolean']['input']>;
  upsideThresholdPercent?: InputMaybe<Scalars['Percent']['input']>;
  upsideThresholdValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
};

/**
 * UpdateGiftingProposalCashFlowInput is used for update GiftingProposalCashFlow object.
 * Input was generated by ent.
 */
export type UpdateGiftingProposalCashFlowInput = {
  /** Cash flow amount */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** Whether this cash flow occurs every year or only once */
  annuallyRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  /** Whether the cash flow is an income or expense */
  cashFlowType?: InputMaybe<GiftingProposalCashFlowCashFlowType>;
  clearAnnuallyRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  clearEndYear?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrowthPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  /** the user-friendly name of this cash flow */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** If recurring, the year this cash flow ends. If not recurring, this field is not set. */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  /** If recurring, the percentage that the cash flow amount grows each year */
  growthPercentage?: InputMaybe<Scalars['Percent']['input']>;
  /** The order to display cash flows in the UI */
  order?: InputMaybe<Scalars['Int']['input']>;
  /** If recurring, the year this cash flow starts. If not recurring, the year this cash flow occurs. */
  startYear?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * UpdateGiftingProposalGiftInput is used for update GiftingProposalGift object.
 * Input was generated by ent.
 */
export type UpdateGiftingProposalGiftInput = {
  addSenderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Gift amount */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** Whether this gift occurs every year or only once */
  annuallyRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  clearAnnuallyRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  clearDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDiscountPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  clearEndYear?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrowthPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  clearNonTaxableGiftType?: InputMaybe<Scalars['Boolean']['input']>;
  clearRecipientClientOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  clearRecipientClientProfile?: InputMaybe<Scalars['Boolean']['input']>;
  clearRecipientKind?: InputMaybe<Scalars['Boolean']['input']>;
  clearRecipientPortfolio?: InputMaybe<Scalars['Boolean']['input']>;
  clearSenders?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, gift amount is discounted for gift tax purposes */
  discount?: InputMaybe<Scalars['Boolean']['input']>;
  /** If discount is true, the discount percentage applied to the gift amount */
  discountPercentage?: InputMaybe<Scalars['Percent']['input']>;
  /** the user-friendly name of this gifting scenario */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** If recurring, the year this gift ends. If not recurring, this field is not set. */
  endYear?: InputMaybe<Scalars['Int']['input']>;
  /** If recurring, the percentage that the gift amount grows each year */
  growthPercentage?: InputMaybe<Scalars['Percent']['input']>;
  /** If true, gift is taxable, and sender will be one or both grantors. If false, can be annual exclusion, or charitable. */
  isTaxable?: InputMaybe<Scalars['Boolean']['input']>;
  /** Will be null (or ignored) if gift is taxable. */
  nonTaxableGiftType?: InputMaybe<GiftingProposalGiftNonTaxableGiftType>;
  /** The order to display, and process the gifts when simulating the proposal scenarios */
  order?: InputMaybe<Scalars['Int']['input']>;
  recipientClientOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  recipientClientProfileID?: InputMaybe<Scalars['ID']['input']>;
  /** The kind of recipient for this gift. Either one of the modeled out-of-estate portfolios, an individual, or an organization. */
  recipientKind?: InputMaybe<GiftingProposalGiftRecipientKind>;
  recipientPortfolioID?: InputMaybe<Scalars['ID']['input']>;
  removeSenderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** If recurring, the year this gift starts. If not recurring, the year this gift occurs. */
  startYear?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * UpdateGiftingProposalGiftSenderInput is used for update GiftingProposalGiftSender object.
 * Input was generated by ent.
 */
export type UpdateGiftingProposalGiftSenderInput = {
  grantorID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateGiftingProposalInput is used for update GiftingProposal object.
 * Input was generated by ent.
 */
export type UpdateGiftingProposalInput = {
  addBaseCashFlowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addGiftingScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addOutOfEstatePortfolioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearBaseCashFlows?: InputMaybe<Scalars['Boolean']['input']>;
  clearDefaultGiftingScenario?: InputMaybe<Scalars['Boolean']['input']>;
  clearExemptionGrowthRate?: InputMaybe<Scalars['Boolean']['input']>;
  clearExemptionSunsets?: InputMaybe<Scalars['Boolean']['input']>;
  clearGiftingScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearOutOfEstatePortfolios?: InputMaybe<Scalars['Boolean']['input']>;
  clearPreTaxReturnPercentageHigh?: InputMaybe<Scalars['Boolean']['input']>;
  clearPreTaxReturnPercentageLow?: InputMaybe<Scalars['Boolean']['input']>;
  clearPreTaxReturnPercentageMedium?: InputMaybe<Scalars['Boolean']['input']>;
  clearSelectedPreTaxReturnCategory?: InputMaybe<Scalars['Boolean']['input']>;
  clearSelectedYearOfAnalysis?: InputMaybe<Scalars['Boolean']['input']>;
  clearShowAfterEstateTax?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDragPercentageHigh?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDragPercentageLow?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDragPercentageMedium?: InputMaybe<Scalars['Boolean']['input']>;
  defaultGiftingScenarioID?: InputMaybe<Scalars['ID']['input']>;
  /** Gift exemption grows by this amount each year. */
  exemptionGrowthRate?: InputMaybe<Scalars['Percent']['input']>;
  /** If true, exemption amount is halved at the end of 2025, then continues growing */
  exemptionSunsets?: InputMaybe<Scalars['Boolean']['input']>;
  /** The total value of all assets in the client's estate */
  inEstatePortfolioValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** number of years to run the gifting analysis */
  lengthOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
  /** The high end of the pre-tax return percentage assumption */
  preTaxReturnPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The low end of the pre-tax return assumption */
  preTaxReturnPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The medium pre-tax return percentage assumption */
  preTaxReturnPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
  removeBaseCashFlowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeGiftingScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeOutOfEstatePortfolioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The selected pre-tax return (and tax drag) category. Used when computing projections for scenarios. */
  selectedPreTaxReturnCategory?: InputMaybe<GiftingProposalSelectedPreTaxReturnCategory>;
  /** The selected year of analysis. Defaults to end year. Used when computing projections for scenarios. */
  selectedYearOfAnalysis?: InputMaybe<Scalars['Int']['input']>;
  /** If true, show after estate tax */
  showAfterEstateTax?: InputMaybe<Scalars['Boolean']['input']>;
  /** Status of the gifting proposal */
  status?: InputMaybe<GiftingProposalStatus>;
  /** The associated tax drag for the high pre-tax return */
  taxDragPercentageHigh?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the low pre-tax return */
  taxDragPercentageLow?: InputMaybe<Scalars['Percent']['input']>;
  /** The associated tax drag for the medium pre-tax return */
  taxDragPercentageMedium?: InputMaybe<Scalars['Percent']['input']>;
};

/**
 * UpdateGiftingProposalPortfolioInput is used for update GiftingProposalPortfolio object.
 * Input was generated by ent.
 */
export type UpdateGiftingProposalPortfolioInput = {
  addRecipientOfIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearRecipientOf?: InputMaybe<Scalars['Boolean']['input']>;
  /** the user-friendly name of the out-of-estate portfolio */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The order to display portfolios in the UI */
  order?: InputMaybe<Scalars['Int']['input']>;
  /** Portfolio type */
  portfolioType?: InputMaybe<GiftingProposalPortfolioPortfolioType>;
  removeRecipientOfIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The starting value of the out-of-estate portfolio */
  startingAssetValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
};

/**
 * UpdateGiftingProposalScenarioInput is used for update GiftingProposalScenario object.
 * Input was generated by ent.
 */
export type UpdateGiftingProposalScenarioInput = {
  addScenarioCashFlowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addScenarioGiftIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearScenarioCashFlows?: InputMaybe<Scalars['Boolean']['input']>;
  clearScenarioGifts?: InputMaybe<Scalars['Boolean']['input']>;
  /** the user-friendly name of this gifting scenario */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The order to display scenarios in the UI */
  order?: InputMaybe<Scalars['Int']['input']>;
  removeScenarioCashFlowIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeScenarioGiftIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateGraphVisualizationGroupInput is used for update GraphVisualizationGroup object.
 * Input was generated by ent.
 */
export type UpdateGraphVisualizationGroupInput = {
  appendNodeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDisplayName?: InputMaybe<Scalars['Boolean']['input']>;
  clearNamespace?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** An optional namespace for this group. Can correspond to sections in the Graph Visualization. */
  namespace?: InputMaybe<Scalars['String']['input']>;
  /** The IDs of the nodes in this group. */
  nodeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateHouseholdInput is used for update Household object.
 * Input was generated by ent.
 */
export type UpdateHouseholdInput = {
  addActivityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addAssignedEmployeeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addAssignedTeamIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addClientOrganizationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addClientProfileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addCollaboratorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addEntityGraphViewIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addEstateTaxScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addEstateWaterfallIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addLiabilityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addOnboardingKgRootIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPresentationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addProposalIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTestamentaryEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  balanceSheetViewConfigurationID?: InputMaybe<Scalars['ID']['input']>;
  /** whether this client is billable or not. if not BILLABLE, this client's entities should be excluding from customer billing */
  billableKind?: InputMaybe<HouseholdBillableKind>;
  clearActivities?: InputMaybe<Scalars['Boolean']['input']>;
  clearAssignedEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  clearAssignedTeams?: InputMaybe<Scalars['Boolean']['input']>;
  clearBalanceSheetViewConfiguration?: InputMaybe<Scalars['Boolean']['input']>;
  clearClientOrganizations?: InputMaybe<Scalars['Boolean']['input']>;
  clearClientProfiles?: InputMaybe<Scalars['Boolean']['input']>;
  clearCollaborators?: InputMaybe<Scalars['Boolean']['input']>;
  clearDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntities?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntityGraphViews?: InputMaybe<Scalars['Boolean']['input']>;
  clearEstateTaxScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEstateWaterfalls?: InputMaybe<Scalars['Boolean']['input']>;
  clearIntegrationClient?: InputMaybe<Scalars['Boolean']['input']>;
  clearLiabilities?: InputMaybe<Scalars['Boolean']['input']>;
  clearNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  clearOnboardingKgRoots?: InputMaybe<Scalars['Boolean']['input']>;
  clearPresentations?: InputMaybe<Scalars['Boolean']['input']>;
  clearProposals?: InputMaybe<Scalars['Boolean']['input']>;
  clearSubBrand?: InputMaybe<Scalars['Boolean']['input']>;
  clearTestamentaryEntities?: InputMaybe<Scalars['Boolean']['input']>;
  integrationClientID?: InputMaybe<Scalars['ID']['input']>;
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  onboardingID?: InputMaybe<Scalars['ID']['input']>;
  primaryRelationshipOwnerID?: InputMaybe<Scalars['ID']['input']>;
  removeActivityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeAssignedEmployeeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeAssignedTeamIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeClientOrganizationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeClientProfileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeCollaboratorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeEntityGraphViewIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeEstateTaxScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeEstateWaterfallIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeLiabilityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeOnboardingKgRootIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePresentationIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeProposalIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTestamentaryEntityIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  subBrandID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateHouseholdOnboardingInput is used for update HouseholdOnboarding object.
 * Input was generated by ent.
 */
export type UpdateHouseholdOnboardingInput = {
  addStepIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearCompletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  clearCompletionMethod?: InputMaybe<Scalars['Boolean']['input']>;
  clearSteps?: InputMaybe<Scalars['Boolean']['input']>;
  /** the time at which the onboarding was completed or dismissed */
  completedAt?: InputMaybe<Scalars['Time']['input']>;
  /** the method by which the onboarding was completed */
  completionMethod?: InputMaybe<HouseholdOnboardingCompletionMethod>;
  removeStepIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateHouseholdOnboardingStepInput is used for update HouseholdOnboardingStep object.
 * Input was generated by ent.
 */
export type UpdateHouseholdOnboardingStepInput = {
  clearCompletedAt?: InputMaybe<Scalars['Boolean']['input']>;
  /** the time at which the onboarding was completed or dismissed */
  completedAt?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * UpdateHypotheticalWaterfallDispositiveProvisionTemplateInput is used for update HypotheticalWaterfallDispositiveProvisionTemplate object.
 * Input was generated by ent.
 */
export type UpdateHypotheticalWaterfallDispositiveProvisionTemplateInput = {
  clearTestamentaryEntity?: InputMaybe<Scalars['Boolean']['input']>;
  dispositiveProvisionTemplateID?: InputMaybe<Scalars['ID']['input']>;
  testamentaryEntityID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateILITCrummeyLetterInput is used for update ILITCrummeyLetter object.
 * Input was generated by ent.
 */
export type UpdateIlitCrummeyLetterInput = {
  addDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  clearSendBefore?: InputMaybe<Scalars['Boolean']['input']>;
  clearSentOn?: InputMaybe<Scalars['Boolean']['input']>;
  crummeyWithdrawalPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  removeDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  sendBefore?: InputMaybe<Scalars['Time']['input']>;
  sentOn?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * UpdateILITTrustInput is used for update ILITTrust object.
 * Input was generated by ent.
 */
export type UpdateIlitTrustInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearCrummeyWithdrawalPeriodDays?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  crummeyWithdrawalPeriodDays?: InputMaybe<Scalars['Int']['input']>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateIndividualPersonalAccountInput is used for update IndividualPersonalAccount object.
 * Input was generated by ent.
 */
export type UpdateIndividualPersonalAccountInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparEntityID?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwner?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  ownerID?: InputMaybe<Scalars['ID']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * UpdateInferredFieldEvidenceInput is used for update InferredFieldEvidence object.
 * Input was generated by ent.
 */
export type UpdateInferredFieldEvidenceInput = {
  appendPolygon?: InputMaybe<Array<CoordinateInput>>;
  clearInferredField?: InputMaybe<Scalars['Boolean']['input']>;
  inferredFieldID?: InputMaybe<Scalars['ID']['input']>;
  /** The page (in the file) that this evidence is for */
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  /** the polygon that was drawn to infer the field */
  polygon?: InputMaybe<Array<CoordinateInput>>;
};

/**
 * UpdateInferredFieldInput is used for update InferredField object.
 * Input was generated by ent.
 */
export type UpdateInferredFieldInput = {
  /** Whether the user has accepted or rejected the inferred field */
  acceptanceStatus?: InputMaybe<InferredFieldAcceptanceStatus>;
  /** When the user set the acceptance status */
  acceptanceStatusSetAt?: InputMaybe<Scalars['Time']['input']>;
  addEvidenceIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAcceptanceStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearAcceptanceStatusSetAt?: InputMaybe<Scalars['Boolean']['input']>;
  clearEvidence?: InputMaybe<Scalars['Boolean']['input']>;
  clearFieldName?: InputMaybe<Scalars['Boolean']['input']>;
  /** The name of the field (db column) that we inferred for the `target_id` object. If absent, evidence is for the entire object. */
  fieldName?: InputMaybe<Scalars['String']['input']>;
  fileID?: InputMaybe<Scalars['ID']['input']>;
  removeEvidenceIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The object we inferred the field for */
  targetID?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateInsurancePersonalAccountInput is used for update InsurancePersonalAccount object.
 * Input was generated by ent.
 */
export type UpdateInsurancePersonalAccountInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addOwnerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparEntityID?: InputMaybe<Scalars['Boolean']['input']>;
  clearControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwners?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeOwnerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * UpdateInsurancePolicyInput is used for update InsurancePolicy object.
 * Input was generated by ent.
 */
export type UpdateInsurancePolicyInput = {
  addDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyHolderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  carrierName?: InputMaybe<Scalars['String']['input']>;
  /** Only applicable for non-term policies */
  cashValue?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  cashValueDate?: InputMaybe<Scalars['Time']['input']>;
  clearCarrierName?: InputMaybe<Scalars['Boolean']['input']>;
  clearCashValue?: InputMaybe<Scalars['Boolean']['input']>;
  clearCashValueDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearConversionDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  clearInitialPremiumDueDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearLoanBalanceOutstanding?: InputMaybe<Scalars['Boolean']['input']>;
  clearNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicyHolders?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicyNumber?: InputMaybe<Scalars['Boolean']['input']>;
  clearPremiumAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearPremiumFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  clearTermDurationYears?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only applicable for term policies */
  conversionDate?: InputMaybe<Scalars['Time']['input']>;
  deathBenefitAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  initialPremiumDueDate?: InputMaybe<Scalars['Time']['input']>;
  kind?: InputMaybe<InsurancePolicyKind>;
  /** Only applicable for non-term policies */
  loanBalanceOutstanding?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  premiumAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  premiumFrequency?: InputMaybe<InsurancePolicyPremiumFrequency>;
  removeDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyHolderIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  /** Only applicable for non-term policies. Allows multiple holders. */
  survivorship?: InputMaybe<Scalars['Boolean']['input']>;
  /** Only applicable for term policies */
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * UpdateInsurancePremiumPaymentInput is used for update InsurancePremiumPayment object.
 * Input was generated by ent.
 */
export type UpdateInsurancePremiumPaymentInput = {
  addDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  clearDueOn?: InputMaybe<Scalars['Boolean']['input']>;
  clearPaidOn?: InputMaybe<Scalars['Boolean']['input']>;
  dueOn?: InputMaybe<Scalars['Time']['input']>;
  paidOn?: InputMaybe<Scalars['Time']['input']>;
  premiumAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  removeDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateIntegrationAssetClassInput is used for update IntegrationAssetClass object.
 * Input was generated by ent.
 */
export type UpdateIntegrationAssetClassInput = {
  assetClassID?: InputMaybe<Scalars['ID']['input']>;
  clearAssetClass?: InputMaybe<Scalars['Boolean']['input']>;
  /** We use this to map this integration_asset_class to the provider's asset class name. This is imperfect, because if someone updates a name then we will make a new asset class and reconnect it. */
  integrationName?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<IntegrationAssetClassProvider>;
};

/**
 * UpdateIntegrationConfigurationInput is used for update IntegrationConfiguration object.
 * Input was generated by ent.
 */
export type UpdateIntegrationConfigurationInput = {
  /** The client ID for the Addepar OAuth app. */
  addeparClientID?: InputMaybe<Scalars['String']['input']>;
  /** The client Secret for the Addepar OAuth app. */
  addeparClientSecret?: InputMaybe<Scalars['String']['input']>;
  addeparClientType?: InputMaybe<IntegrationConfigurationAddeparClientType>;
  /** ID for Addepar account. */
  addeparFirmID?: InputMaybe<Scalars['String']['input']>;
  /** The subdomain of the Addepar instance to connect to. */
  addeparSubdomain?: InputMaybe<Scalars['String']['input']>;
  addeparTokenID?: InputMaybe<Scalars['ID']['input']>;
  /** Whether the tenant expects to use Black Diamond. We use this to expose prompts to users to authenticate to Black Diamond. */
  blackDiamondEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparClientID?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparClientSecret?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparFirmID?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparSubdomain?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparToken?: InputMaybe<Scalars['Boolean']['input']>;
  clearCsvValuationImportName?: InputMaybe<Scalars['Boolean']['input']>;
  /** The user-defined name for the source of the file valuation import. Exposed alongside the valuation to show where it came from. */
  csvValuationImportName?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateIrrevocableTrustInput is used for update IrrevocableTrust object.
 * Input was generated by ent.
 */
export type UpdateIrrevocableTrustInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateJointPersonalAccountInput is used for update JointPersonalAccount object.
 * Input was generated by ent.
 */
export type UpdateJointPersonalAccountInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addOwnerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparEntityID?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwners?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearType?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeOwnerIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  type?: InputMaybe<JointPersonalAccountType>;
};

/**
 * UpdateKGRootInput is used for update KGRoot object.
 * Input was generated by ent.
 */
export type UpdateKgRootInput = {
  addAiSuggestionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addFileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addNodeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAiSuggestions?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearFiles?: InputMaybe<Scalars['Boolean']['input']>;
  clearNodes?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  removeAiSuggestionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeFileIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeNodeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The status of the construction of this KGRoot. It will be COMPLETE when the KGNodes have all been generated for the associated files and merged into the root. */
  status?: InputMaybe<KgRootStatus>;
};

/**
 * UpdateKeyPersonInput is used for update KeyPerson object.
 * Input was generated by ent.
 */
export type UpdateKeyPersonInput = {
  addRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearRoles?: InputMaybe<Scalars['Boolean']['input']>;
  removeRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateKeyPersonRoleInput is used for update KeyPersonRole object.
 * Input was generated by ent.
 */
export type UpdateKeyPersonRoleInput = {
  clearKind?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<KeyPersonRoleKind>;
};

/**
 * UpdateLLCBusinessEntityInput is used for update LLCBusinessEntity object.
 * Input was generated by ent.
 */
export type UpdateLlcBusinessEntityInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearDoingBusinessAsName?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearFinCenID?: InputMaybe<Scalars['Boolean']['input']>;
  clearFormationJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeopleNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnershipAsOfDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearRequiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxID?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * UpdateLPBusinessEntityInput is used for update LPBusinessEntity object.
 * Input was generated by ent.
 */
export type UpdateLpBusinessEntityInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearDoingBusinessAsName?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearFinCenID?: InputMaybe<Scalars['Boolean']['input']>;
  clearFormationJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeopleNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnershipAsOfDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearRequiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxID?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * UpdateLiabilityInput is used for update Liability object.
 * Input was generated by ent.
 */
export type UpdateLiabilityInput = {
  addPaymentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearCurrentAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearCurrentAmountAsOfDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearFinalPaymentAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  clearInitialAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearInterestPaid?: InputMaybe<Scalars['Boolean']['input']>;
  clearInterestRate?: InputMaybe<Scalars['Boolean']['input']>;
  clearPaymentFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  clearPaymentKind?: InputMaybe<Scalars['Boolean']['input']>;
  clearPayments?: InputMaybe<Scalars['Boolean']['input']>;
  clearRecipientEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearRecurringPaymentAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceClientOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceClientProfile?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearTermEnd?: InputMaybe<Scalars['Boolean']['input']>;
  clearTermStart?: InputMaybe<Scalars['Boolean']['input']>;
  currentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  currentAmountAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  finalPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  initialAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestPaid?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  interestRate?: InputMaybe<Scalars['Percent']['input']>;
  paymentFrequency?: InputMaybe<LiabilityPaymentFrequency>;
  paymentKind?: InputMaybe<LiabilityPaymentKind>;
  recipientEntityID?: InputMaybe<Scalars['ID']['input']>;
  recurringPaymentAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  removePaymentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourceClientOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  sourceClientProfileID?: InputMaybe<Scalars['ID']['input']>;
  sourceEntityID?: InputMaybe<Scalars['ID']['input']>;
  termEnd?: InputMaybe<Scalars['Time']['input']>;
  termStart?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * UpdateLifetimeExclusionEventInput is used for update LifetimeExclusionEvent object.
 * Input was generated by ent.
 */
export type UpdateLifetimeExclusionEventInput = {
  addDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The amount of annual exclusion this gift used */
  annualExclusionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  benefitOfID?: InputMaybe<Scalars['ID']['input']>;
  clearAnnualExclusionAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearBenefitOf?: InputMaybe<Scalars['Boolean']['input']>;
  clearDiscountAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  clearGiftAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearGiftCategory?: InputMaybe<Scalars['Boolean']['input']>;
  clearNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearRecipient?: InputMaybe<Scalars['Boolean']['input']>;
  /** Amount asset was discounted for exclusion */
  discountAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The date of gifting or when the adjustment was recorded */
  eventDate?: InputMaybe<Scalars['Time']['input']>;
  /** The amount gifted to the recipient */
  giftAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  giftCategory?: InputMaybe<LifetimeExclusionEventGiftCategory>;
  grantorID?: InputMaybe<Scalars['ID']['input']>;
  /** The amount of GST exclusion this gift used */
  gstExclusionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** The amount of lifetime exclusion this gift used */
  lifetimeExclusionAmount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  /** Free-form notes */
  notes?: InputMaybe<Scalars['String']['input']>;
  recipientID?: InputMaybe<Scalars['ID']['input']>;
  removeDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateLoggedTransferInput is used for update LoggedTransfer object.
 * Input was generated by ent.
 */
export type UpdateLoggedTransferInput = {
  addDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addLifetimeExclusionEventIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The amount of logged transfer. */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  clearDocuments?: InputMaybe<Scalars['Boolean']['input']>;
  clearLifetimeExclusionEvents?: InputMaybe<Scalars['Boolean']['input']>;
  clearOtherPurposeDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearPurpose?: InputMaybe<Scalars['Boolean']['input']>;
  clearReceivingEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearReceivingGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  clearReceivingOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  clearSourceOrganization?: InputMaybe<Scalars['Boolean']['input']>;
  /** Custom purpose if "Other" purpose is selected. */
  otherPurposeDescription?: InputMaybe<Scalars['String']['input']>;
  /** Purpose of transfer */
  purpose?: InputMaybe<LoggedTransferPurpose>;
  receivingEntityID?: InputMaybe<Scalars['ID']['input']>;
  receivingGrantorID?: InputMaybe<Scalars['ID']['input']>;
  receivingOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  removeDocumentIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeLifetimeExclusionEventIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  sourceEntityID?: InputMaybe<Scalars['ID']['input']>;
  sourceGrantorID?: InputMaybe<Scalars['ID']['input']>;
  sourceOrganizationID?: InputMaybe<Scalars['ID']['input']>;
  transactionDate?: InputMaybe<Scalars['Time']['input']>;
};

/**
 * UpdateOwnershipStakeInput is used for update OwnershipStake object.
 * Input was generated by ent.
 */
export type UpdateOwnershipStakeInput = {
  clearOwnedEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnershipPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  ownedEntityID?: InputMaybe<Scalars['ID']['input']>;
  /** The percentage ownership of the underlying entity. */
  ownershipPercentage?: InputMaybe<Scalars['Percent']['input']>;
};

/**
 * UpdatePrincipalInput is used for update Principal object.
 * Input was generated by ent.
 */
export type UpdatePrincipalInput = {
  clearOwnershipPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * The percentage ownership of the underlying entity, funded by these principals.
   * 			Typically only valid for multiple ownership, non-trust non-charitable entities (eg: LLC, LP, ...)
   */
  ownershipPercentage?: InputMaybe<Scalars['Percent']['input']>;
};

/**
 * UpdatePrivateFoundationInput is used for update PrivateFoundation object.
 * Input was generated by ent.
 */
export type UpdatePrivateFoundationInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDonorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparEntityID?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearDonors?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDonorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * UpdateProfessionalTeamRoleInput is used for update ProfessionalTeamRole object.
 * Input was generated by ent.
 */
export type UpdateProfessionalTeamRoleInput = {
  associatedClientIndividualID?: InputMaybe<Scalars['ID']['input']>;
  clearAssociatedClientIndividual?: InputMaybe<Scalars['Boolean']['input']>;
  clearOtherRoleName?: InputMaybe<Scalars['Boolean']['input']>;
  clearPowerOfAttorneyKind?: InputMaybe<Scalars['Boolean']['input']>;
  kind?: InputMaybe<ProfessionalTeamRoleKind>;
  /** If the kind is OTHER, this is the custom role name */
  otherRoleName?: InputMaybe<Scalars['String']['input']>;
  /** If the kind is POWER_OF_ATTORNEY, this is the type of power of attorney */
  powerOfAttorneyKind?: InputMaybe<ProfessionalTeamRolePowerOfAttorneyKind>;
};

/**
 * UpdateProposalInput is used for update Proposal object.
 * Input was generated by ent.
 */
export type UpdateProposalInput = {
  addEntityProposalIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearCltProposal?: InputMaybe<Scalars['Boolean']['input']>;
  clearCrtProposal?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntityProposals?: InputMaybe<Scalars['Boolean']['input']>;
  clearGiftingProposal?: InputMaybe<Scalars['Boolean']['input']>;
  /** notes to the client that accompany the proposal */
  clientNotes?: InputMaybe<Scalars['String']['input']>;
  cltProposalID?: InputMaybe<Scalars['ID']['input']>;
  crtProposalID?: InputMaybe<Scalars['ID']['input']>;
  /** the user-friendly name of the proposal */
  displayName?: InputMaybe<Scalars['String']['input']>;
  giftingProposalID?: InputMaybe<Scalars['ID']['input']>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  /** whether or not to include a "cumulative view" tab on the proposal */
  includeCumulativeView?: InputMaybe<Scalars['Boolean']['input']>;
  removeEntityProposalIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateProposalScenarioInput is used for update ProposalScenario object.
 * Input was generated by ent.
 */
export type UpdateProposalScenarioInput = {
  clearGratProposalScenario?: InputMaybe<Scalars['Boolean']['input']>;
  gratProposalScenarioID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateQPRTTrustInput is used for update QPRTTrust object.
 * Input was generated by ent.
 */
export type UpdateQprtTrustInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOfficialInterestRatePercent?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxableGift?: InputMaybe<Scalars['Boolean']['input']>;
  clearTermDurationYears?: InputMaybe<Scalars['Boolean']['input']>;
  clearTerminationDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  officialInterestRatePercent?: InputMaybe<Scalars['Percent']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** The actual taxable gift that was recorded for this QPRT */
  taxableGift?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  termDurationYears?: InputMaybe<Scalars['Int']['input']>;
  terminationDate?: InputMaybe<Scalars['Time']['input']>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateQualifiedTuitionPersonalAccountInput is used for update QualifiedTuitionPersonalAccount object.
 * Input was generated by ent.
 */
export type UpdateQualifiedTuitionPersonalAccountInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparEntityID?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwner?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  ownerID?: InputMaybe<Scalars['ID']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * UpdateRetirementPersonalAccountInput is used for update RetirementPersonalAccount object.
 * Input was generated by ent.
 */
export type UpdateRetirementPersonalAccountInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addeparEntityID?: InputMaybe<Scalars['String']['input']>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearAddeparEntityID?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwner?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearType?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this personal account */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  ownerID?: InputMaybe<Scalars['ID']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
  type?: InputMaybe<RetirementPersonalAccountType>;
};

/**
 * UpdateRevocableTrustInput is used for update RevocableTrust object.
 * Input was generated by ent.
 */
export type UpdateRevocableTrustInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrantors?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeGrantorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateSCorpBusinessEntityInput is used for update SCorpBusinessEntity object.
 * Input was generated by ent.
 */
export type UpdateSCorpBusinessEntityInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearDoingBusinessAsName?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearFinCenID?: InputMaybe<Scalars['Boolean']['input']>;
  clearFormationJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeopleNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnershipAsOfDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearRequiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxID?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * UpdateSLATTrustInput is used for update SLATTrust object.
 * Input was generated by ent.
 */
export type UpdateSlatTrustInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addLifetimeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addPolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addRemainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearGrantor?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearLifetimeBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearPolicies?: InputMaybe<Scalars['Boolean']['input']>;
  clearRemainderBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this trust */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  /** Display name */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  grantorID?: InputMaybe<Scalars['ID']['input']>;
  /** GST status for this trust. Only valid when in_estate_status is OUT_OF_ESTATE */
  gstStatus?: InputMaybe<EntityGstStatus>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeLifetimeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removePolicyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeRemainderBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxStatus?: InputMaybe<TrustTaxStatus>;
  /** State code for the jurisdiction of this trust */
  trustJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateSSOConnectionInput is used for update SSOConnection object.
 * Input was generated by ent.
 */
export type UpdateSsoConnectionInput = {
  addConfiguredTenantIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  appendBoundEmailDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  /** A list of email domains that are allowed to use this SSO connection. Do not include the @ symbol. */
  boundEmailDomains?: InputMaybe<Array<Scalars['String']['input']>>;
  clearConfiguredTenants?: InputMaybe<Scalars['Boolean']['input']>;
  removeConfiguredTenantIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The ID of the WorkOS organization this SSO connection corresponds to. */
  workosOrganizationID?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateScheduledDistributionInput is used for update ScheduledDistribution object.
 * Input was generated by ent.
 */
export type UpdateScheduledDistributionInput = {
  /** The end of the age requirement for this distribution */
  ageRequirementEnd?: InputMaybe<Scalars['Int']['input']>;
  /** The start of the age requirement for this distribution */
  ageRequirementStart?: InputMaybe<Scalars['Int']['input']>;
  /** The AMOUNT kind of distribution. Only valid when kind is AMOUNT */
  amount?: InputMaybe<Scalars['CurrencyUSD']['input']>;
  beneficiaryID?: InputMaybe<Scalars['ID']['input']>;
  clearAgeRequirementEnd?: InputMaybe<Scalars['Boolean']['input']>;
  clearAgeRequirementStart?: InputMaybe<Scalars['Boolean']['input']>;
  clearAmount?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiary?: InputMaybe<Scalars['Boolean']['input']>;
  clearFrequency?: InputMaybe<Scalars['Boolean']['input']>;
  clearPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  clearScheduledDistributionNotes?: InputMaybe<Scalars['Boolean']['input']>;
  /** The frequency of the distribution to beneficiaries */
  frequency?: InputMaybe<ScheduledDistributionFrequency>;
  /** The kind of scheduled distribution */
  kind?: InputMaybe<ScheduledDistributionKind>;
  /** The percentage of the distribution. Only valid when kind is PERCENTAGE */
  percentage?: InputMaybe<Scalars['Percent']['input']>;
  /** The notes for the scheduled distribution */
  scheduledDistributionNotes?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateSoleProprietorshipBusinessEntityInput is used for update SoleProprietorshipBusinessEntity object.
 * Input was generated by ent.
 */
export type UpdateSoleProprietorshipBusinessEntityInput = {
  addAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAccounts?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDesignerAccount?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearDoingBusinessAsName?: InputMaybe<Scalars['Boolean']['input']>;
  clearEffectiveDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearEntity?: InputMaybe<Scalars['Boolean']['input']>;
  clearFinCenID?: InputMaybe<Scalars['Boolean']['input']>;
  clearFormationJurisdictionStateCode?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeople?: InputMaybe<Scalars['Boolean']['input']>;
  clearKeyPeopleNotes?: InputMaybe<Scalars['Boolean']['input']>;
  clearLegalName?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwnershipAsOfDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearRequiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxDiscount?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxID?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this business entity */
  description?: InputMaybe<Scalars['String']['input']>;
  designerAccountID?: InputMaybe<Scalars['ID']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** The name with which the entity is doing business as. */
  doingBusinessAsName?: InputMaybe<Scalars['String']['input']>;
  /** Effective date. */
  effectiveDate?: InputMaybe<Scalars['Time']['input']>;
  /** Whether the entity has already been created and is in effect */
  entityExists?: InputMaybe<Scalars['Boolean']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  finCenID?: InputMaybe<Scalars['String']['input']>;
  /** State code for the formation jurisdiction of this business entity */
  formationJurisdictionStateCode?: InputMaybe<Scalars['String']['input']>;
  /** Notes for Key People associated with this business entity */
  keyPeopleNotes?: InputMaybe<Scalars['String']['input']>;
  /** Legal name */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Ownership as-of date. */
  ownershipAsOfDate?: InputMaybe<Scalars['Time']['input']>;
  removeAccountIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeKeyPersonIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The Corporate Transparency Act (CTA) is a law passed in 2021 that requires certain companies to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN). */
  requiresCtaReporting?: InputMaybe<Scalars['Boolean']['input']>;
  /** The percentage by which assets are discounted for tax purposes. */
  taxDiscount?: InputMaybe<Scalars['Percent']['input']>;
  taxID?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<BusinessEntityTaxStatus>;
};

/**
 * UpdateSubBrandInput is used for update SubBrand object.
 * Input was generated by ent.
 */
export type UpdateSubBrandInput = {
  addHouseholdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  buttonsLinkColor?: InputMaybe<Scalars['String']['input']>;
  clearDarkSquareLogoURL?: InputMaybe<Scalars['Boolean']['input']>;
  clearDarkWideLogoURL?: InputMaybe<Scalars['Boolean']['input']>;
  clearDataVisualizationNegativeColor?: InputMaybe<Scalars['Boolean']['input']>;
  clearDataVisualizationTertiaryColor?: InputMaybe<Scalars['Boolean']['input']>;
  clearHouseholds?: InputMaybe<Scalars['Boolean']['input']>;
  darkSquareLogoURL?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURL?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColor?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  logoURL?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  removeHouseholdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
};

/**
 * UpdateTeamInput is used for update Team object.
 * Input was generated by ent.
 */
export type UpdateTeamInput = {
  addAssignedHouseholdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addEmployeeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAssignedHouseholds?: InputMaybe<Scalars['Boolean']['input']>;
  clearEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  clearOwner?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerID?: InputMaybe<Scalars['ID']['input']>;
  removeAssignedHouseholdIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeEmployeeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateTenantApplicationConfigurationInput is used for update TenantApplicationConfiguration object.
 * Input was generated by ent.
 */
export type UpdateTenantApplicationConfigurationInput = {
  /** If true, disables all AI capabilities for this tenant. */
  disableAiCapabilities?: InputMaybe<Scalars['Boolean']['input']>;
  /** User Sessions expire after a period of inactivity. This field controls the timeout period. */
  userSessionInactivityTimeout?: InputMaybe<TenantApplicationConfigurationUserSessionInactivityTimeout>;
};

/**
 * UpdateTenantBrandingInput is used for update TenantBranding object.
 * Input was generated by ent.
 */
export type UpdateTenantBrandingInput = {
  buttonsLinkColor?: InputMaybe<Scalars['String']['input']>;
  clearDarkSquareLogoURL?: InputMaybe<Scalars['Boolean']['input']>;
  clearDarkWideLogoURL?: InputMaybe<Scalars['Boolean']['input']>;
  clearDataVisualizationNegativeColor?: InputMaybe<Scalars['Boolean']['input']>;
  clearDataVisualizationTertiaryColor?: InputMaybe<Scalars['Boolean']['input']>;
  darkSquareLogoURL?: InputMaybe<Scalars['String']['input']>;
  darkWideLogoURL?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationNegativeColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationPrimaryColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationSecondaryColor?: InputMaybe<Scalars['String']['input']>;
  dataVisualizationTertiaryColor?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  logoURL?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
  tenantID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateTenantDisclaimerConfigurationInput is used for update TenantDisclaimerConfiguration object.
 * Input was generated by ent.
 */
export type UpdateTenantDisclaimerConfigurationInput = {
  clearPresentationCoverDisclaimerBody?: InputMaybe<Scalars['Boolean']['input']>;
  clearPresentationCoverDisclaimerTitle?: InputMaybe<Scalars['Boolean']['input']>;
  clearPresentationFooterDisclaimerBody?: InputMaybe<Scalars['Boolean']['input']>;
  clearPresentationFooterDisclaimerTitle?: InputMaybe<Scalars['Boolean']['input']>;
  clearPresentationStandaloneDisclaimerBody?: InputMaybe<Scalars['Boolean']['input']>;
  clearPresentationStandaloneDisclaimerTitle?: InputMaybe<Scalars['Boolean']['input']>;
  /** If true, include the default Luminary company disclosures in the presentation */
  includeLuminaryDisclaimer?: InputMaybe<Scalars['Boolean']['input']>;
  presentationCoverDisclaimerBody?: InputMaybe<Scalars['String']['input']>;
  presentationCoverDisclaimerTitle?: InputMaybe<Scalars['String']['input']>;
  presentationFooterDisclaimerBody?: InputMaybe<Scalars['String']['input']>;
  presentationFooterDisclaimerTitle?: InputMaybe<Scalars['String']['input']>;
  presentationStandaloneDisclaimerBody?: InputMaybe<Scalars['String']['input']>;
  presentationStandaloneDisclaimerTitle?: InputMaybe<Scalars['String']['input']>;
  tenantID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateTenantInput is used for update Tenant object.
 * Input was generated by ent.
 */
export type UpdateTenantInput = {
  addSSOConnectionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  brandingID?: InputMaybe<Scalars['ID']['input']>;
  clearBranding?: InputMaybe<Scalars['Boolean']['input']>;
  clearContractStartDate?: InputMaybe<Scalars['Boolean']['input']>;
  clearSSOConnections?: InputMaybe<Scalars['Boolean']['input']>;
  /** WARNING: FOR ANALYTICS/REPORTING ONLY. NOT FOR BUSINESS LOGIC. The date the contract started. */
  contractStartDate?: InputMaybe<Scalars['Time']['input']>;
  kind?: InputMaybe<TenantKind>;
  removeSSOConnectionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** WARNING: FOR ANALYTICS/REPORTING ONLY. NOT FOR BUSINESS LOGIC. This is the bucket that the tenant falls into for usage tracking. */
  usageBucket?: InputMaybe<TenantUsageBucket>;
};

/**
 * UpdateTenantReminderEmailsConfigurationInput is used for update TenantReminderEmailsConfiguration object.
 * Input was generated by ent.
 */
export type UpdateTenantReminderEmailsConfigurationInput = {
  /** If true, do NOT send periodic reminder emails for tasks. */
  reminderEmailsDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * UpdateTenantSSOConnectionInput is used for update TenantSSOConnection object.
 * Input was generated by ent.
 */
export type UpdateTenantSsoConnectionInput = {
  clearJitEnabledAt?: InputMaybe<Scalars['Boolean']['input']>;
  enableJIT?: InputMaybe<Scalars['Boolean']['input']>;
  /** The time at which JIT was enabled for this tenant. If null, JIT is disabled. JIT means that users will be created on the fly when they sign in with SSO. */
  jitEnabledAt?: InputMaybe<Scalars['Time']['input']>;
  ssoConnectionID?: InputMaybe<Scalars['ID']['input']>;
  tenantID?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * UpdateTestamentaryEntityInput is used for update TestamentaryEntity object.
 * Input was generated by ent.
 */
export type UpdateTestamentaryEntityInput = {
  addBeneficiariesAfterBothGrantorDeathIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addBenefitsFromIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addHypotheticalDispositiveProvisionsTemplateIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearBeneficiaries?: InputMaybe<Scalars['Boolean']['input']>;
  clearBeneficiariesAfterBothGrantorDeaths?: InputMaybe<Scalars['Boolean']['input']>;
  clearBenefitsFrom?: InputMaybe<Scalars['Boolean']['input']>;
  clearControllingParties?: InputMaybe<Scalars['Boolean']['input']>;
  clearDescription?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositiveProvisions?: InputMaybe<Scalars['Boolean']['input']>;
  clearDispositiveProvisionsTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  clearGstStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearHousehold?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositionScenarios?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositiveProvisions?: InputMaybe<Scalars['Boolean']['input']>;
  clearHypotheticalDispositiveProvisionsTemplates?: InputMaybe<Scalars['Boolean']['input']>;
  clearInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearReviewedAt?: InputMaybe<Scalars['Boolean']['input']>;
  clearStateTax?: InputMaybe<Scalars['Boolean']['input']>;
  clearSuccessorTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  clearSurvivingSpouseStateInEstateStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearTaxStatus?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustAdvisors?: InputMaybe<Scalars['Boolean']['input']>;
  clearTrustees?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description for this testamentary entity */
  description?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  dispositiveProvisionsTemplateID?: InputMaybe<Scalars['ID']['input']>;
  gstStatus?: InputMaybe<EntityGstStatus>;
  householdID?: InputMaybe<Scalars['ID']['input']>;
  inEstateStatus?: InputMaybe<EntityInEstateStatus>;
  /** Type of testamentary entity. */
  kind?: InputMaybe<TestamentaryEntityKind>;
  removeBeneficiariesAfterBothGrantorDeathIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBeneficiaryIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeBenefitsFromIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeControllingPartyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositionScenarioIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositiveProvisionIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeHypotheticalDispositiveProvisionsTemplateIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeSuccessorTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrustAdvisorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeTrusteeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** The time at which the scenario was last reviewed. This can be used to indicate that a user has looked at these provisions and they are correct, even if they are empty. */
  reviewedAt?: InputMaybe<Scalars['Time']['input']>;
  stateTaxID?: InputMaybe<Scalars['ID']['input']>;
  survivingSpouseStateInEstateStatus?: InputMaybe<EntityInEstateStatus>;
  taxStatus?: InputMaybe<NonTrustEntityTaxStatus>;
};

/**
 * UpdateTrustAdvisorInput is used for update TrustAdvisor object.
 * Input was generated by ent.
 */
export type UpdateTrustAdvisorInput = {
  addRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearNote?: InputMaybe<Scalars['Boolean']['input']>;
  clearRoles?: InputMaybe<Scalars['Boolean']['input']>;
  /** The level of this trust advisor. */
  level?: InputMaybe<TrustAdvisorLevel>;
  /** A Note about this trust advisor. Only relevant if the level is "OTHER". */
  note?: InputMaybe<Scalars['String']['input']>;
  removeRoleIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateTrustAdvisorRoleInput is used for update TrustAdvisorRole object.
 * Input was generated by ent.
 */
export type UpdateTrustAdvisorRoleInput = {
  /** The role of this trust advisor. */
  kind?: InputMaybe<TrustAdvisorRoleKind>;
};

/**
 * UpdateTrusteeDutyInput is used for update TrusteeDuty object.
 * Input was generated by ent.
 */
export type UpdateTrusteeDutyInput = {
  /** The kind of duty for this trustee */
  kind?: InputMaybe<TrusteeDutyKind>;
};

/**
 * UpdateTrusteeInput is used for update Trustee object.
 * Input was generated by ent.
 */
export type UpdateTrusteeInput = {
  addDutyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearDuties?: InputMaybe<Scalars['Boolean']['input']>;
  clearLevel?: InputMaybe<Scalars['Boolean']['input']>;
  clearNotes?: InputMaybe<Scalars['Boolean']['input']>;
  level?: InputMaybe<TrusteeLevel>;
  notes?: InputMaybe<Scalars['String']['input']>;
  removeDutyIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateUserInput is used for update User object.
 * Input was generated by ent.
 */
export type UpdateUserInput = {
  addAssignedTaskIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addAsyncJobIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addCollaboratorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  addEmployeeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  clearAssignedTasks?: InputMaybe<Scalars['Boolean']['input']>;
  clearAsyncJobs?: InputMaybe<Scalars['Boolean']['input']>;
  clearCollaborators?: InputMaybe<Scalars['Boolean']['input']>;
  clearEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  removeAssignedTaskIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeAsyncJobIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeCollaboratorIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
  removeEmployeeIDs?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/**
 * UpdateUserNotificationConfigurationInput is used for update UserNotificationConfiguration object.
 * Input was generated by ent.
 */
export type UpdateUserNotificationConfigurationInput = {
  /** Whether or not the user has enabled receiving reminder email notifications. */
  allRemainderEmailsDisabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type User = Node & {
  __typename?: 'User';
  assignedTasks: EntityTaskConnection;
  asyncJobs: AsyncJobConnection;
  collaborators?: Maybe<Array<Collaborator>>;
  createdAt: Scalars['Time']['output'];
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  employees?: Maybe<Array<Employee>>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  initials: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  localMostRecentlyViewedEntityMap?: Maybe<MostRecentlyViewedEntityMap>;
  localMostRecentlyViewedWaterfall?: Maybe<MostRecentlyViewedWaterfall>;
  nameWithLastInitial: Scalars['String']['output'];
  /** this field is populated with 'first_name + last_name' and is used for search purposes */
  searchName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Time']['output'];
};


export type UserAssignedTasksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EntityTaskOrder>>;
  where?: InputMaybe<EntityTaskWhereInput>;
};


export type UserAsyncJobsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AsyncJobOrder>>;
  where?: InputMaybe<AsyncJobWhereInput>;
};


export type UserLocalMostRecentlyViewedEntityMapArgs = {
  where?: InputMaybe<MostRecentlyViewedEntityMapWhereInput>;
};


export type UserLocalMostRecentlyViewedWaterfallArgs = {
  where?: InputMaybe<LastViewedWaterfallWhereInput>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

/**
 * UserKind is a description of the class of user currently interacting with the application, which
 * governs the behavior of some parts of the experience.
 */
export enum UserKind {
  /** ANONYMOUS: A user who is not logged in, possibly using an issued token for access */
  Anonymous = 'ANONYMOUS',
  /** DATADOG_INTERNAL: Elevated access to Dev Tools for synthetics */
  DatadogInternal = 'DATADOG_INTERNAL',
  /** EMPLOYEE: Access is fully controlled by the assigned roles and related permissions. */
  Employee = 'EMPLOYEE',
  /** LUMINARY_INTERNAL: Elevated access to things like Dev Tools and other advanced functionality */
  LuminaryInternal = 'LUMINARY_INTERNAL',
  /**
   * THIRD_PARTY: An external user, so we guarantee that some sections of the application like the
   * employee homepage and admin sections are never available regardless of permissions.
   */
  ThirdParty = 'THIRD_PARTY'
}

export type UserNotificationConfiguration = Node & {
  __typename?: 'UserNotificationConfiguration';
  /** Whether or not the user has enabled receiving reminder email notifications. */
  allRemainderEmailsDisabled: Scalars['Boolean']['output'];
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  /** The user for whom this notification configuration is for. */
  receivingUser: User;
  tenant: Tenant;
  tenantID: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
};

/** A connection to a list of items. */
export type UserNotificationConfigurationConnection = {
  __typename?: 'UserNotificationConfigurationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserNotificationConfigurationEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type UserNotificationConfigurationEdge = {
  __typename?: 'UserNotificationConfigurationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The item at the end of the edge. */
  node?: Maybe<UserNotificationConfiguration>;
};

/** Ordering options for UserNotificationConfiguration connections */
export type UserNotificationConfigurationOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order UserNotificationConfigurations. */
  field: UserNotificationConfigurationOrderField;
};

/** Properties by which UserNotificationConfiguration connections can be ordered. */
export enum UserNotificationConfigurationOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * UserNotificationConfigurationWhereInput is used for filtering UserNotificationConfiguration objects.
 * Input was generated by ent.
 */
export type UserNotificationConfigurationWhereInput = {
  /** all_remainder_emails_disabled field predicates */
  allRemainderEmailsDisabled?: InputMaybe<Scalars['Boolean']['input']>;
  allRemainderEmailsDisabledNEQ?: InputMaybe<Scalars['Boolean']['input']>;
  and?: InputMaybe<Array<UserNotificationConfigurationWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** receiving_user edge predicates */
  hasReceivingUser?: InputMaybe<Scalars['Boolean']['input']>;
  hasReceivingUserWith?: InputMaybe<Array<UserWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  not?: InputMaybe<UserNotificationConfigurationWhereInput>;
  or?: InputMaybe<Array<UserNotificationConfigurationWhereInput>>;
  /** tenant_id field predicates */
  tenantID?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContains?: InputMaybe<Scalars['ID']['input']>;
  tenantIDContainsFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDEqualFold?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDGTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasPrefix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDHasSuffix?: InputMaybe<Scalars['ID']['input']>;
  tenantIDIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  tenantIDLT?: InputMaybe<Scalars['ID']['input']>;
  tenantIDLTE?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNEQ?: InputMaybe<Scalars['ID']['input']>;
  tenantIDNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

/** Ordering options for User connections */
export type UserOrder = {
  /** The ordering direction. */
  direction?: OrderDirection;
  /** The field by which to order Users. */
  field: UserOrderField;
};

/** Properties by which User connections can be ordered. */
export enum UserOrderField {
  CreatedAt = 'created_at',
  UpdatedAt = 'updated_at'
}

/**
 * UserWhereInput is used for filtering User objects.
 * Input was generated by ent.
 */
export type UserWhereInput = {
  and?: InputMaybe<Array<UserWhereInput>>;
  /** created_at field predicates */
  createdAt?: InputMaybe<Scalars['Time']['input']>;
  createdAtGT?: InputMaybe<Scalars['Time']['input']>;
  createdAtGTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  createdAtLT?: InputMaybe<Scalars['Time']['input']>;
  createdAtLTE?: InputMaybe<Scalars['Time']['input']>;
  createdAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  createdAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  /** email field predicates */
  email?: InputMaybe<Scalars['String']['input']>;
  emailContains?: InputMaybe<Scalars['String']['input']>;
  emailContainsFold?: InputMaybe<Scalars['String']['input']>;
  emailEqualFold?: InputMaybe<Scalars['String']['input']>;
  emailGT?: InputMaybe<Scalars['String']['input']>;
  emailGTE?: InputMaybe<Scalars['String']['input']>;
  emailHasPrefix?: InputMaybe<Scalars['String']['input']>;
  emailHasSuffix?: InputMaybe<Scalars['String']['input']>;
  emailIn?: InputMaybe<Array<Scalars['String']['input']>>;
  emailLT?: InputMaybe<Scalars['String']['input']>;
  emailLTE?: InputMaybe<Scalars['String']['input']>;
  emailNEQ?: InputMaybe<Scalars['String']['input']>;
  emailNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** first_name field predicates */
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstNameContains?: InputMaybe<Scalars['String']['input']>;
  firstNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  firstNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  firstNameGT?: InputMaybe<Scalars['String']['input']>;
  firstNameGTE?: InputMaybe<Scalars['String']['input']>;
  firstNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  firstNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  firstNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  firstNameLT?: InputMaybe<Scalars['String']['input']>;
  firstNameLTE?: InputMaybe<Scalars['String']['input']>;
  firstNameNEQ?: InputMaybe<Scalars['String']['input']>;
  firstNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** assigned_tasks edge predicates */
  hasAssignedTasks?: InputMaybe<Scalars['Boolean']['input']>;
  hasAssignedTasksWith?: InputMaybe<Array<EntityTaskWhereInput>>;
  /** async_jobs edge predicates */
  hasAsyncJobs?: InputMaybe<Scalars['Boolean']['input']>;
  hasAsyncJobsWith?: InputMaybe<Array<AsyncJobWhereInput>>;
  /** collaborators edge predicates */
  hasCollaborators?: InputMaybe<Scalars['Boolean']['input']>;
  hasCollaboratorsWith?: InputMaybe<Array<CollaboratorWhereInput>>;
  /** employees edge predicates */
  hasEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  hasEmployeesWith?: InputMaybe<Array<EmployeeWhereInput>>;
  /** id field predicates */
  id?: InputMaybe<Scalars['ID']['input']>;
  idGT?: InputMaybe<Scalars['ID']['input']>;
  idGTE?: InputMaybe<Scalars['ID']['input']>;
  idIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  idLT?: InputMaybe<Scalars['ID']['input']>;
  idLTE?: InputMaybe<Scalars['ID']['input']>;
  idNEQ?: InputMaybe<Scalars['ID']['input']>;
  idNotIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** last_name field predicates */
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastNameContains?: InputMaybe<Scalars['String']['input']>;
  lastNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  lastNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  lastNameGT?: InputMaybe<Scalars['String']['input']>;
  lastNameGTE?: InputMaybe<Scalars['String']['input']>;
  lastNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  lastNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  lastNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  lastNameLT?: InputMaybe<Scalars['String']['input']>;
  lastNameLTE?: InputMaybe<Scalars['String']['input']>;
  lastNameNEQ?: InputMaybe<Scalars['String']['input']>;
  lastNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  not?: InputMaybe<UserWhereInput>;
  or?: InputMaybe<Array<UserWhereInput>>;
  /** search_name field predicates */
  searchName?: InputMaybe<Scalars['String']['input']>;
  searchNameContains?: InputMaybe<Scalars['String']['input']>;
  searchNameContainsFold?: InputMaybe<Scalars['String']['input']>;
  searchNameEqualFold?: InputMaybe<Scalars['String']['input']>;
  searchNameGT?: InputMaybe<Scalars['String']['input']>;
  searchNameGTE?: InputMaybe<Scalars['String']['input']>;
  searchNameHasPrefix?: InputMaybe<Scalars['String']['input']>;
  searchNameHasSuffix?: InputMaybe<Scalars['String']['input']>;
  searchNameIn?: InputMaybe<Array<Scalars['String']['input']>>;
  searchNameIsNil?: InputMaybe<Scalars['Boolean']['input']>;
  searchNameLT?: InputMaybe<Scalars['String']['input']>;
  searchNameLTE?: InputMaybe<Scalars['String']['input']>;
  searchNameNEQ?: InputMaybe<Scalars['String']['input']>;
  searchNameNotIn?: InputMaybe<Array<Scalars['String']['input']>>;
  searchNameNotNil?: InputMaybe<Scalars['Boolean']['input']>;
  /** updated_at field predicates */
  updatedAt?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtGTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtIn?: InputMaybe<Array<Scalars['Time']['input']>>;
  updatedAtLT?: InputMaybe<Scalars['Time']['input']>;
  updatedAtLTE?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNEQ?: InputMaybe<Scalars['Time']['input']>;
  updatedAtNotIn?: InputMaybe<Array<Scalars['Time']['input']>>;
};

export type YearProjectionOption = {
  __typename?: 'YearProjectionOption';
  display: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};
